import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserType } from '../../../utils/authUtils';
import Header from '../../shared/TailwindComponents/Header';
import Table from '../../shared/TailwindComponents/Table';
import Pagination from '../../shared/TailwindComponents/Pagination';
import { fetchAllTeams } from '../../../store/actions/organization/teamActions';
import { useHistory } from 'react-router-dom';
import { usePermission } from '../../../hooks/userPermissions';
import ApiLoader from '../../ApiLoader';
import AddTeamForm from './AddTeamForm';
import { useTranslation } from 'react-i18next';

interface TeamsListProps {
  setIsAddingTeam?: (isAdding: boolean) => void; 
}

const TeamsList: React.FC<TeamsListProps> = ({ setIsAddingTeam }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { teams, loading, error } = useSelector((state: RootState) => state.teams);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddingTeam, setIsAddingTeamLocal] = useState(false); 
  const teamsPerPage = 10;
  const userType = getUserType();
  const { hasAccess, isReadOnly } = usePermission('admin');
  const {t} = useTranslation()

  useEffect(() => {
    if (!isAddingTeam) {
      dispatch(fetchAllTeams());
    }
  }, [dispatch, isAddingTeam]);

  const handleAddTeam = () => {
    setIsAddingTeamLocal(true);
    if (setIsAddingTeam) setIsAddingTeam(true); // Notify parent if prop exists
  };

  const handleCloseAddTeam = () => {
    setIsAddingTeamLocal(false);
    if (setIsAddingTeam) setIsAddingTeam(false); // Notify parent if prop exists
  };

  // Pagination logic
  const indexOfLastTeam = currentPage * teamsPerPage;
  const indexOfFirstTeam = indexOfLastTeam - teamsPerPage;
  const currentTeams = teams.slice(indexOfFirstTeam, indexOfLastTeam);
  const totalPages = Math.ceil(teams.length / teamsPerPage);

  // Table columns
  const columns = ['Team Name', 'Description', 'Members'];

  // Data for the table
  const data = currentTeams.map((team) => ({
    id: team.LegalTeamID,
    'Team Name': team.TeamName,
    Description: team.Description || 'N/A',
    Members: team.Members.length,
  }));

  if (userType !== 'BusinessAdmin' && userType !== 'LawFirmAdmin') {
    return <div className="p-4 text-gray-800 dark:text-white">{t("You do not have permission to view this page.")}</div>;
  }

  return (
    <div className="p-4">
      {isAddingTeam ? (
        <AddTeamForm onClose={handleCloseAddTeam} />
      ) : (
        <>
          <Header
            title="Teams"
            subtitle="Manage your teams"
            actionLabel="Add Team"
            onActionClick={handleAddTeam}
          />
          {loading ? (
            <ApiLoader />
          ) : error ? (
            <div className="text-red-600">Error: {error}</div>
          ) : (
            <>
              <Table
                columns={columns}
                data={data}
                onRowClick={(row) => {
                  const team = teams.find((t) => t.LegalTeamID === row.id);
                  if (team) {
                    history.push(`/management/teams/${team.LegalTeamID}`);
                  }
                }}
              />
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TeamsList;