// src/components/SettingsModal.tsx
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AISettingsComponent from '../Settings/AccountSettings/AISettings';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import ThemeToggle from '../shared/TailwindComponents/ThemeToggle';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { ThemeContext } from '../../contexts/ThemeContext';
import { changeLanguage } from '../../i18n/i18n';
import { updatePreferences } from '../../store/actions/DataAction';
import { getUserType, isBetaEnv } from '../../utils/authUtils';
import DocumentSettings from './DocumentSettings';
import { getWhiteLabelSettings } from '../../store/actions/organization/WhiteLabelActions';
import { getUserID } from '../../store/actions/ChatAction';
import {
  PiGearLight,
  PiRobotLight,
  PiFileTextLight,
  PiBellLight,
  PiBuildingLight,
  PiUserCheckLight,
  PiShieldCheckLight,
  PiSignatureLight,
  PiListChecksLight,
  PiSparkleLight,
  PiPlugLight,
} from 'react-icons/pi';
import SignatureModal from './Security/DigitalSignature';
import IdentityVerificationTab from './SettingsTabs/IDVerification';
import LoginSecurityTab from './SettingsTabs/LoginSecurityTab';
import WhiteLabelSettings from './WhiteLabelSettings';
import NotificationSettings from './SettingsTabs/NotificationsTab';
import TaskSettings from './SettingsTabs/TaskSettings';
import IntegrationsTab from './SettingsTabs/IntegrationsTab';

type TabType =
  | 'general'
  | 'ai'
  | 'doc'
  | 'signature'
  | 'identity'
  | 'loginSecurity'
  | 'notifications'
  | 'branding'
  | 'tasks'
  | 'integrations';

const SettingsModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TabType>('general');
  const platformLanguages = useSelector((state: RootState) => state.dropdown.platformLanguages);
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const whitelabelSettings = useSelector((state: RootState) => state.whiteLabel.settings);
  const { theme } = useContext(ThemeContext);

  const handleLanguageChange = (selectedValues: string[] | string) => {
    const selectedId = Array.isArray(selectedValues) ? selectedValues[0] : selectedValues;
    const selectedOption = platformLanguages.find((option) => option.value === selectedId);
    if (selectedOption) {
      changeLanguage(selectedOption.value);
      dispatch(
        updatePreferences({
          UserID: userInfo.UserID,
          UserType: getUserType(),
          Language: selectedOption.value,
          ThemeColor: theme,
        })
      );
    }
  };

  const refreshSettings = () => {
    const userId = getUserID();
    if (
      userInfo.UserTypeName === 'LawFirmAdmin' ||
      userInfo.UserTypeName === 'BusinessAdmin' ||
      userInfo.UserTypeName === 'IndependentLawyer'
    ) {
      dispatch(getWhiteLabelSettings(userId));
    }
  };

  return (
    <div className="flex flex-col relative min-h-[600px] md:w-[750px] sm:w-full rounded-lg animate-fade-in">
      <div className="w-full p-4 border-b border-gray-200 dark:border-gray-700">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">{t('Settings')}</h2>
      </div>
      <div className="flex h-full relative flex-wrap">
        <div className="w-full md:w-1/4 p-4 border-r border-gray-200 dark:border-gray-700">
          <div className="gap-2 flex flex-wrap">
            <button
              className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                activeTab === 'general'
                  ? 'bg-primary-100 dark:bg-primary-700'
                  : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
              onClick={() => setActiveTab('general')}
            >
              <PiGearLight className="mr-2" size={20} />
              {t('General')}
            </button>
            <button
              className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                activeTab === 'ai'
                  ? 'bg-primary-100 dark:bg-primary-700'
                  : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
              onClick={() => setActiveTab('ai')}
            >
              <PiSparkleLight className="mr-2" size={20} />
              {t('AI')}
            </button>
            <button
              className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                activeTab === 'doc'
                  ? 'bg-primary-100 dark:bg-primary-700'
                  : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
              onClick={() => setActiveTab('doc')}
            >
              <PiFileTextLight className="mr-2" size={20} />
              {t('Document')}
            </button>
            <button
              className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                activeTab === 'signature'
                  ? 'bg-primary-100 dark:bg-primary-700'
                  : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
              onClick={() => setActiveTab('signature')}
            >
              <PiSignatureLight className="mr-2" size={20} />
              {t('Signature')}
            </button>
            <button
              className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                activeTab === 'identity'
                  ? 'bg-primary-100 dark:bg-primary-700'
                  : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
              onClick={() => setActiveTab('identity')}
            >
              <PiUserCheckLight className="mr-2" size={20} />
              {t('ID Verification')}
            </button>
            <button
              className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                activeTab === 'loginSecurity'
                  ? 'bg-primary-100 dark:bg-primary-700'
                  : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
              onClick={() => setActiveTab('loginSecurity')}
            >
              <PiShieldCheckLight className="mr-2" size={20} />
              {t('Login Security')}
            </button>
            {!isBetaEnv && (
              <>
                <button
                  className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                    activeTab === 'notifications'
                      ? 'bg-primary-100 dark:bg-primary-700'
                      : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
                  onClick={() => setActiveTab('notifications')}
                >
                  <PiBellLight className="mr-2" size={20} />
                  {t('Notifications')}
                </button>
                <button
                  className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                    activeTab === 'tasks'
                      ? 'bg-primary-100 dark:bg-primary-700'
                      : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
                  onClick={() => setActiveTab('tasks')}
                >
                  <PiListChecksLight className="mr-2" size={20} />
                  {t('Tasks')}
                </button>
                <button
                  className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                    activeTab === 'branding'
                      ? 'bg-primary-100 dark:bg-primary-700'
                      : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
                  onClick={() => setActiveTab('branding')}
                >
                  <PiBuildingLight className="mr-2" size={20} />
                  {t('Branding')}
                </button>
              </>
            )}
            <button
                  className={`w-full flex items-center p-2 rounded text-sm transition-all ${
                    activeTab === 'integrations'
                      ? 'bg-primary-100 dark:bg-primary-700'
                      : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
                  onClick={() => setActiveTab('integrations')}
                >
                  <PiPlugLight className="mr-2" size={20} />
                  {t('Integrations')}
                </button>
          </div>
        </div>

        <div className="w-full md:w-3/4 p-6 overflow-y-auto h-[520px] bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900">
          {activeTab === 'general' && (
            <div className="space-y-6">
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                  {t('General Settings')}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t('Customize your platform experience with these settings.')}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                    {t('Language')}
                  </label>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {t('Select your preferred language.')}
                  </p>
                </div>
                <Dropdown
                  options={platformLanguages}
                  value={preferences?.Language || 'en'}
                  placeholder={t('Select Language')}
                  onChange={handleLanguageChange}
                  className="min-w-48"
                />
              </div>
              <hr className="my-4 border-gray-200 dark:border-gray-700" />
              <div className="flex justify-between items-center">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                    {t('Theme')}
                  </label>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {t('Toggle between light and dark mode.')}
                  </p>
                </div>
                <ThemeToggle />
              </div>
              <hr className="my-4 border-gray-200 dark:border-gray-700" />
              <div className="flex justify-between items-center">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                    {t('Currency')}
                  </label>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {t('Select your preferred currency (coming soon).')}
                  </p>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-300 opacity-50">
                  {t('Coming soon')}
                </p>
              </div>
            </div>
          )}

          {activeTab === 'ai' && <AISettingsComponent />}
          {activeTab === 'doc' && (
            <DocumentSettings
              fontType={whitelabelSettings?.FontType || ''}
              documentLogoUrl={whitelabelSettings?.DocumentLogo || null}
              headerLogoUrl={whitelabelSettings?.HeaderLogo || null}
              onUpdate={refreshSettings}
            />
          )}
          {activeTab === 'signature' && <SignatureModal onSave={() => {}} onConfirm={() => {}} />}
          {activeTab === 'identity' && <IdentityVerificationTab />}
          {activeTab === 'loginSecurity' && <LoginSecurityTab />}
          {!isBetaEnv && (
            <>
              {activeTab === 'notifications' && <NotificationSettings />}
              {activeTab === 'branding' && <WhiteLabelSettings />}
              {activeTab === 'tasks' && <TaskSettings />}
              {activeTab === 'integrations' && <IntegrationsTab />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;