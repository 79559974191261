import { useTranslation } from 'react-i18next';
import { FaTimesCircle } from 'react-icons/fa';

const FailedPage = () => {
    const {t} = useTranslation()
    return (
        <div className="flex flex-center justify-center items-center">
            <div className="w-96 h-80  bg-red-100 dark:bg-red-900 p-6 rounded-lg shadow-lg flex flex-col items-center  animate-slide-up">
                <div className="animate-bounce-in bg-red-500 rounded-full p-4 shadow-lg">
                    <FaTimesCircle className="text-white text-6xl" />
                </div>

                <h1 className="text-2xl font-bold  text-red-800 dark:text-red-300">{t("Payment Failed")}</h1>
                <p className="text-gray-700 dark:text-gray-300 text-center">
                    {t("We couldn't process your payment. Please try again later.")}
                </p>

            </div>

            {/* Tailwind Animations */}
            <style>{`
                @keyframes slide-up {
                    from {
                        transform: translateY(100%);
                        opacity: 0;
                    }
                    to {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                .animate-slide-up {
                    animation: slide-up 0.7s ease-out forwards;
                }

                @keyframes bounce-in {
                    0% {
                        transform: scale(0);
                    }
                    50% {
                        transform: scale(1.2);
                    }
                    100% {
                        transform: scale(1);
                    }
                }

                .animate-bounce-in {
                    animation: bounce-in 0.5s ease-out forwards;
                }
            `}</style>
        </div>

    );
};

export default FailedPage;
