import React, { useRef } from 'react';
import FileInputWithPreview from './FileInputWithPreview';
import GoogleDrivePickerButton from '../../../Globals/GoogleDrivePickerButton';
import OneDrivePickerButton from '../../../Globals/OneDrivePickerButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store';
import { showModal } from '../../../store/actions/modalActions';
import { PiCloudArrowUpLight, PiVaultLight } from "react-icons/pi";
import { VaultFile } from './VaultPicker';

interface FileUploaderProps {
  files: File[];
  onFilesChange: (files: File[]) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ files, onFilesChange }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleImportFromVault = () => {
    dispatch(
      showModal({
        type: 'vaultFilePicker',
        onConfirm: async (selectedItems: VaultFile[]) => {
          // selectedItems = array of { id, name, url }
          // We'll fetch each file from vault and push into newFileList
          const newFileList = [...files];

          for (const vaultFile of selectedItems) {
            if (!vaultFile.url) continue;
            try {
              const response = await fetch(vaultFile.url);
              const blob = await response.blob();
              const mimeType = response.headers.get('Content-Type') || 'application/octet-stream';
              const newFile = new File([blob], vaultFile.name, { type: mimeType });
              newFileList.push(newFile);
            } catch (err) {
              console.error('Error fetching vault file:', err);
            }
          }

          // Once done, we call onFilesChange with the updated list
          onFilesChange(newFileList);
        },
        showModal: true,
        message: ''
      })
    );
  };

  const handleOpenFileDialog = () => {
    const input = containerRef.current?.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
      input.click();
    }
  };

  return (
    <div
      ref={containerRef}
      className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-4 
                 transition-all duration-300 hover:border-primary-500"
    >
      <FileInputWithPreview files={files} onFilesChange={onFilesChange} />

      {/* File Picker Buttons */}
      <div className="flex flex-wrap justify-center gap-2 mt-4">
        <button
          type="button"
          className="flex items-center justify-center w-32 px-3 py-1.5 
                     text-xs font-medium text-white bg-primary-500 
                     rounded-md hover:bg-primary-600 transition-colors"
          onClick={handleOpenFileDialog}
        >
          {t('Upload')}
          <PiCloudArrowUpLight className="ml-2" size={16} />
        </button>
        <button
          type="button"
          className="flex items-center justify-center w-32 px-3 py-1.5 
                     text-xs font-medium text-gray-700 dark:text-gray-200 
                     bg-neutral-100 dark:bg-neutral-800 
                     rounded-md hover:bg-neutral-200 dark:hover:bg-neutral-900
                     transition-colors"
          onClick={handleImportFromVault}
        >
          {t('Vault')}
          <PiVaultLight className="ml-2" size={16} />
        </button>
        <GoogleDrivePickerButton onFilePicked={(file: File) => onFilesChange([...files, file])} />
        <OneDrivePickerButton onFileSelected={(file: File) => onFilesChange([...files, file])} />
      </div>
    </div>
  );
};

export default FileUploader;
