import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { createTicket, fetchAllUserTickets } from '../../store/actions/HelpAction';
import { Formik, Form, Field, ErrorMessage, FieldInputProps } from 'formik';
import * as Yup from 'yup';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { getUserType } from '../../store/actions/ChatAction';
import { PiEnvelopeLight } from 'react-icons/pi';

interface CreateTicketFormProps {
  onCancel: () => void;
  searchQuery: string;
  selectedFilters: { [key: string]: string[] };
  sortValue: string;
}

const CreateTicketForm: React.FC<CreateTicketFormProps> = ({ onCancel, searchQuery, selectedFilters, sortValue }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const categories = useSelector((state: RootState) => state.help.categories);
  const userType = getUserType();
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Updated initialValues without Subject
  const initialValues = {
    Description: '',
    Category: '',
  };

  // Updated validationSchema without Subject
  const validationSchema = Yup.object({
    Description: Yup.string().required(t('Description is required')),
    Category: Yup.string().required(t('Category is required')),
  });

  const onSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    console.log('Form submitted with values:', values);
    console.log('Files:', files);
    const ticketData = {
      UserType: userType,
      Description: values.Description,
      Category: values.Category,
      Files: files,
    };
    console.log('Ticket data to dispatch:', ticketData);
    await dispatch(createTicket(ticketData));
    console.log('createTicket dispatched successfully');
    dispatch(
      fetchAllUserTickets({
        searchTerm: searchQuery,
        filters: selectedFilters,
        sortBy: sortValue,
      })
    );
    resetForm();
    setFiles([]);
    onCancel();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files).filter((file) => file.type.startsWith('image/'));
      setFiles((prev) => [...prev, ...selectedFiles]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-2xl mx-auto mt-5 animate-fade-in-up">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, setFieldValue }) => (
          <Form className="space-y-6">
            <div className="sticky pb-4 mb-4 top-0 z-10 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4 flex items-center gap-2">
                <PiEnvelopeLight size={28} className="text-primary-500" />
                {t('Create New Ticket')}
              </h2>
              <div className="flex gap-2">
                <Button variant="neutral" size="small" onClick={onCancel}>
                  {t('Cancel')}
                </Button>
                <Button variant="primary" size="small" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? t('Submitting...') : t('Submit Ticket')}
                </Button>
              </div>
            </div>

            {/* Category Dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                {t('Category')}
              </label>
              <Dropdown
                options={
                  categories
                    ? categories.map((cat: DropdownOption) => ({ label: t(cat.label), value: cat.value }))
                    : []
                }
                onChange={(value) => setFieldValue('Category', value)}
                placeholder={t('Select Category')}
              />
              <ErrorMessage name="Category" component="p" className="text-red-500 text-sm" />
            </div>

            {/* Description Field */}
            <Field name="Description">
              {({ field }: { field: FieldInputProps<string> }) => (
                <Textarea
                  label={t('Description')}
                  placeholder={t('Describe your issue')}
                  id=""
                  {...field}
                />
              )}
            </Field>
            <ErrorMessage name="Description" component="p" className="text-red-500 text-sm" />

            {/* Attach Images Section */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                {t('Attach Images (Optional)')}
              </label>
              <div
                className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer hover:border-primary-500 transition-colors duration-300"
                onClick={() => fileInputRef.current?.click()}
              >
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    {t('Click to upload images or drag and drop')}
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {t('PNG, JPG, GIF up to 10MB')}
                  </p>
                  <input
                    ref={fileInputRef}
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </div>
              </div>
              {files.length > 0 && (
                <div className="mt-4 grid grid-cols-3 gap-4">
                  {files.map((file, index) => (
                    <div key={index} className="relative group">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className="object-cover rounded-md shadow-md max-h-48 max-w-48"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="absolute top-1 right-1 bg-red-500 text-white rounded px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-end gap-4">
              <Button type="submit" size="small" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? t('Submitting...') : t('Submit Ticket')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateTicketForm;