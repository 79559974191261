import React, { useContext } from 'react';
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ThemeContext } from '../../contexts/ThemeContext';

interface CustomDateTimePickerProps {
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
  minDateTime?: Dayjs;
  dateOnly?: boolean;
  label?: string;
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({
  value,
  onChange,
  minDateTime,
  dateOnly = false,
  label
}) => {
  const { theme } = useContext(ThemeContext);

  const muiTheme = createTheme({
    palette: {
      mode: theme === 'dark' ? 'dark' : 'light',
      primary: {
        main: '#1379F3', // Your primary color
      },
    },
  });

  return (
    <div className='block w-full'>
      {label && (
        <p className='text-sm text-gray-900 dark:text-gray-100'>{label}</p>
      )}
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {dateOnly ? (
          <DatePicker
            value={value}
            onChange={onChange}
            minDate={minDateTime}
            slotProps={{
              textField: { fullWidth: true },
            }}
          />
        ) : (
          <DateTimePicker
            value={value}
            onChange={onChange}
            minDateTime={minDateTime}
            ampm={false}
            slotProps={{
              textField: { fullWidth: true },
            }}
          />
        )}
      </LocalizationProvider>
    </ThemeProvider>
    </div>
  );
};

export default CustomDateTimePicker;
