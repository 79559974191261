import React, { useEffect, useState } from 'react';
import EmployeeList from '../components/Management/Employees/EmployeeList';
import TeamsList from '../components/Management/Teams/TeamList';
import { getUserType } from '../utils/authUtils';
import Tabs from '../components/shared/TailwindComponents/Tabs';
import { useLocation, useHistory } from 'react-router-dom';
import { useBreadcrumbs } from '../contexts/BreadcrumbsContext';

const ManagementPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const userType = getUserType();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [isAddingEmployee, setIsAddingEmployee] = useState(false);
  const [isAddingTeam, setIsAddingTeam] = useState(false);

  const path = location.pathname;
  const activeTab = path.includes('/management/teams') ? 'teams' : 'employees';

  useEffect(() => {
    const breadcrumbs = [
      { name: 'Management', href: '/management', current: false },
      { name: activeTab === 'employees' ? 'Employees' : 'Teams', href: `/management/${activeTab}`, current: true },
    ];
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs, activeTab]);

  const handleTabClick = (tab: string) => {
    if (!isAddingEmployee && !isAddingTeam) {
      if (tab === 'employees') {
        history.push('/management/employees');
      } else if (tab === 'teams') {
        history.push('/management/teams');
      }
    }
  };

  const tabs = [
    { label: 'Employees', value: 'employees' },
    { label: 'Teams', value: 'teams' },
  ];

  return (
    <div className="px-4">
      {userType === 'LawFirmAdmin' && !isAddingEmployee && !isAddingTeam && (
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
      )}
      {activeTab === 'employees' ? (
        <EmployeeList
          isAddingEmployee={isAddingEmployee}
          setIsAddingEmployee={setIsAddingEmployee}
        />
      ) : (
        <TeamsList setIsAddingTeam={setIsAddingTeam} />      
        )}
    </div>
  );
};

export default ManagementPage;