import { Menu, Transition } from "@headlessui/react";
import React, { useState, useEffect, Fragment } from "react";
import { GoChevronDown } from "react-icons/go";
import { PiCheckCircle } from "react-icons/pi";

interface Region {
  name: string;
  code: string;
  flag: string;
  apiUrl: string;
}

const RegionSwitcher: React.FC = () => {
  const [regions, setRegions] = useState<Region[]>([
    { name: "EU", code: "EU", flag: "fi fi-eu", apiUrl: "" },
    { name: "US", code: "US", flag: "fi fi-us", apiUrl: "" },
  ]);

  const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Load the previously selected region from localStorage
  useEffect(() => {
    const storedRegionCode = localStorage.getItem("selectedRegion");
    if (storedRegionCode) {
      const storedRegion = regions.find((region) => region.code === storedRegionCode);
      if (storedRegion) setSelectedRegion(storedRegion);
    } else {
      setSelectedRegion(regions[0]); // Default to EU if no stored region
    }
  }, [regions]);

  const handleRegionChange = async (regionCode: string) => {
    setLoading(true);
    try {
      const response = await fetch(`http://127.0.0.1:8000/validate-region/${regionCode}`);
      if (!response.ok) throw new Error("Failed to fetch region URLs");

      const data = await response.json();
      if (data && data.url) {
        const selected = regions.find((region) => region.code === regionCode);
        if (selected) {
          setSelectedRegion(selected);
          localStorage.setItem("selectedRegion", regionCode);
          window.location.href = data.url; // Redirect to region URL
        }
      } else {
        alert("Region URL not available. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching region URLs:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative w-full flex justify-end">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="flex items-center px-3 py-2 text-gray-700 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-white dark:bg-gray-800 hover:bg-gradient-to-r hover:from-gray-50 hover:to-gray-100 dark:hover:from-gray-700 dark:hover:to-gray-800 transition-all duration-200">
          {selectedRegion ? (
            <>
              <span className={`${selectedRegion.flag} w-5 h-5 mr-2`}></span>
              {selectedRegion.name}
            </>
          ) : (
            <span>🌍 Select Region</span>
          )}
          <GoChevronDown className="ml-2 text-sm" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-150"
          enterFrom="transform opacity-0 scale-95 translate-y-1"
          enterTo="transform opacity-100 scale-100 translate-y-0"
          leave="transition ease-in duration-100"
          leaveFrom="transform opacity-100 scale-100 translate-y-0"
          leaveTo="transform opacity-0 scale-95 translate-y-1"
        >
          <Menu.Items className="absolute right-0 mt-2 w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg focus:outline-none z-10">
            {regions.map(({ code, flag, name }) => (
              <Menu.Item key={code}>
                {({ active }) => (
                  <button
                    className={`flex items-center w-full px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-150 ${
                      selectedRegion?.code === code ? "font-bold bg-gray-200" : ""
                    }`}
                    disabled={selectedRegion?.code === code}
                    onClick={() => handleRegionChange(code)}
                  >
                    <span className={`${flag} w-5 h-5 mr-2`}></span>
                    {name}
                    {/* {selectedRegion?.code === code && (
                      <PiCheckCircle className="ml-auto text-green-500" size={18} />
                    )} */}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default RegionSwitcher;