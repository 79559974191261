// src/components/Settings/IntegrationsTab.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PiGoogleDriveLogoLight,  PiPlugLight, PiWindowsLogoLight } from 'react-icons/pi';
import Button from '../../shared/TailwindComponents/Button';

const IntegrationsTab: React.FC = () => {
  const { t } = useTranslation();

  const integrations = [
    {
      name: 'Google Drive',
      description: t('Connect your Google Drive to access and manage files.'),
      icon: <PiGoogleDriveLogoLight className="text-4xl text-primary-500" />,
    },
    {
      name: 'Microsoft OneDrive',
      description: t('Connect your OneDrive to access and manage files.'),
      icon: <PiWindowsLogoLight className="text-4xl text-primary-500" />,
    },
  ];

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
        {t('Integrations')}
      </h3>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {t('Manage your third-party integrations.')}
      </p>
      <div className="space-y-4">
        {integrations.map((integration, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg shadow-light-md dark:shadow-dark-md"
          >
            <div className="flex items-center space-x-4">
              {integration.icon}
              <div>
                <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                  {integration.name}
                </h4>
                <p className="text-xs text-gray-500 dark:text-gray-400 w-4/5">
                  {integration.description}
                </p>
              </div>
            </div>
            <Button variant="primary" size="small" onClick={() => { /* Placeholder */ }}>
              {t('Connect')}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationsTab;