import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { PiCheckCircleLight, PiWarningLight, PiXCircleLight, PiInfoLight, PiBellLight } from 'react-icons/pi';

interface AlertProps {
  type: 'success' | 'warning' | 'error' | 'info' | 'neutral';
  message: string;
  subMessage?: string;
  onClose?: () => void;
  actions?: Array<{
    label: string;
    onClick: () => void;
    variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'error';
  }>;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ type, message, subMessage, onClose, actions }, ref) => {
    const iconComponents = {
      success: <PiCheckCircleLight className="text-success-500 dark:text-success-300" />,
      warning: <PiWarningLight className="text-warning-500 dark:text-warning-300" />,
      error: <PiXCircleLight className="text-danger-500 dark:text-danger-300" />,
      info: <PiInfoLight className="text-info-500 dark:text-info-300" />,
      neutral: <PiBellLight className="text-neutral-500 dark:text-neutral-300" />,
    };

    return (
      <div
        ref={ref}
        className={clsx(
          'alert',
          'bg-gray-100/50 dark:bg-gray-800/50 hover:bg-gray-100/80 dark:hover:bg-gray-800/80',
          'text-gray-800 dark:text-gray-200',
          'border-2',
          {
            'border-success-200/80 dark:border-success-800/80': type === 'success',
            'border-warning-200/80 dark:border-warning-800/80': type === 'warning',
            'border-danger-200/80 dark:border-danger-800/80': type === 'error',
            'border-info-200/80 dark:border-info-800/80': type === 'info',
            'border-neutral-200/80 dark:border-neutral-800/80': type === 'neutral',
          },
          'rounded-xl px-3 py-2 shadow-lg backdrop-blur-sm'
        )}
        role="alert"
      >
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center items-center">
          <div className="flex items-center">
            <div className="flex-shrink-0 text-xl mr-2">{iconComponents[type]}</div>
            <div>
              <p className="font-medium text-sm">{message}</p>
              {subMessage && <p className="mt-1 text-xs">{subMessage}</p>}
            </div>
          </div>
          <div className="mt-2 sm:mt-0 sm:ml-3 flex sm:flex-shrink-0">
            <div className="flex space-x-1">
              {actions?.map((action, index) => (
                <button
                  key={index}
                  onClick={action.onClick}
                  className={clsx(
                    'px-2 py-1 rounded-md text-xs font-medium',
                    {
                      'bg-primary-500 text-white hover:bg-primary-600': action.variant === 'primary' || !action.variant,
                      'bg-secondary-500 text-white hover:bg-secondary-600': action.variant === 'secondary',
                      'bg-success-500 text-white hover:bg-success-600': action.variant === 'success',
                      'bg-warning-500 text-white hover:bg-warning-600': action.variant === 'warning',
                      'bg-danger-500 text-white hover:bg-danger-600': action.variant === 'error',
                    }
                  )}
                >
                  {action.label}
                </button>
              ))}
              {onClose && (
                <button
                  onClick={onClose}
                  className="ml-2 inline-flex rounded-md p-1 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white focus:outline-none"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  ✕
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

Alert.displayName = 'Alert';

export default Alert;