import React from 'react';

const SkeletonLoader: React.FC = () => {
  return (
    <div className="custom-loader">
      <div className="bg-white dark:bg-gray-900 rounded-xl p-6 shadow-md">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-3">
            <div className="h-6 w-6 bg-gray-300 dark:bg-gray-600 rounded-full"></div>
            <div className="h-6 w-32 bg-gray-300 dark:bg-gray-600 rounded"></div>
          </div>
          <div className="flex gap-2">
            <div className="h-8 w-8 bg-gray-300 dark:bg-gray-600 rounded"></div>
            <div className="h-8 w-8 bg-gray-300 dark:bg-gray-600 rounded"></div>
            <div className="h-8 w-8 bg-gray-300 dark:bg-gray-600 rounded"></div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="h-4 w-24 bg-gray-300 dark:bg-gray-600 rounded"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="h-10 bg-gray-300 dark:bg-gray-600 rounded"></div>
            <div className="h-10 bg-gray-300 dark:bg-gray-600 rounded"></div>
            <div className="h-10 bg-gray-300 dark:bg-gray-600 rounded"></div>
            <div className="h-10 bg-gray-300 dark:bg-gray-600 rounded"></div>
          </div>
          <div className="h-4 w-24 bg-gray-300 dark:bg-gray-600 rounded"></div>
          <div className="space-y-2">
            <div className="h-6 w-full bg-gray-300 dark:bg-gray-600 rounded"></div>
            <div className="h-6 w-full bg-gray-300 dark:bg-gray-600 rounded"></div>
          </div>
          <div className="h-4 w-24 bg-gray-300 dark:bg-gray-600 rounded"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
              <div className="h-4 w-32 bg-gray-300 dark:bg-gray-600 rounded"></div>
              <div className="mt-2 h-2 w-full bg-gray-300 dark:bg-gray-600 rounded"></div>
              <div className="mt-1 h-4 w-16 bg-gray-300 dark:bg-gray-600 rounded"></div>
            </div>
            <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
              <div className="h-4 w-32 bg-gray-300 dark:bg-gray-600 rounded"></div>
              <div className="mt-2 h-2 w-full bg-gray-300 dark:bg-gray-600 rounded"></div>
              <div className="mt-1 h-4 w-16 bg-gray-300 dark:bg-gray-600 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;