import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import SingleFileInput from '../shared/TailwindComponents/SingleFileInput';
import Button from '../shared/TailwindComponents/Button';
import { useFormik } from 'formik';
import { getWhiteLabelSettings, updateWhiteLabelSettings } from '../../store/actions/organization/WhiteLabelActions';
import { RootState, useAppDispatch } from '../../store';
import SignatureModal from './Security/DigitalSignature';
import { getUserID } from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';

interface DocumentSettingsProps {
  fontType: string;
  documentLogoUrl: string | null;
  headerLogoUrl: string | null;
  onUpdate: () => void;
}

const DocumentSettings: React.FC<DocumentSettingsProps> = ({
  fontType,
  documentLogoUrl,
  headerLogoUrl,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  useEffect(() => {
    dispatch(getWhiteLabelSettings(userId))
  }, [userId])

  const formik = useFormik({
    initialValues: {
      FontType: fontType || '',
      DocumentLogo: null as File | null,
      HeaderLogo: null as File | null,
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      if (values.FontType) formData.append('FontType', values.FontType);
      if (values.DocumentLogo) formData.append('DocumentLogo', values.DocumentLogo);
      if (values.HeaderLogo) formData.append('HeaderLogo', values.HeaderLogo);
      await dispatch(updateWhiteLabelSettings(formData));
      onUpdate(); // Trigger refresh of settings
      formik.resetForm(); // Reset form after submission
    },
    enableReinitialize: true, // Reinitialize form when props change
  });

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6">
      {/* Header and Sub-Description */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">{t('Document')}</h3>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {t('Customize the appearance and branding of your documents.')}
        </p>
      </div>
      <hr className="my-4 border-gray-200 dark:border-gray-700" />

      {(userInfo.UserTypeName === 'LawFirmAdmin' ||
        userInfo.UserTypeName === 'BusinessAdmin' ||
        userInfo.UserTypeName === 'IndependentLawyer') && (
        <>
          {/* Document Logo */}
          <SingleFileInput
            label={t('Document Logo')}
            accept="image/*"
            file={formik.values.DocumentLogo}
            setFile={(file) => formik.setFieldValue('DocumentLogo', file)}
            existingFileUrl={documentLogoUrl as string}
            desc={t('Upload or change the logo displayed on your documents.')}
          />
          <hr className="my-4 border-gray-200 dark:border-gray-700" />

          {/* Document Header Logo */}
          <SingleFileInput
            label={t('Document Header Logo')}
            accept="image/*"
            file={formik.values.HeaderLogo}
            setFile={(file) => formik.setFieldValue('HeaderLogo', file)}
            existingFileUrl={headerLogoUrl as string}
            desc={t('Upload or change the logo displayed in document headers.')}
          />
          <hr className="my-4 border-gray-200 dark:border-gray-700" />

          {/* Font Type */}
          <div className="flex justify-between items-center">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                {t('Font Type')}
              </label>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                {t('Choose a font for use in documents and platform communications.')}
              </p>
            </div>
            <Dropdown
              id="FontType"
              options={[
                { value: '', label: t('Select Font') },
                { value: 'Poppins', label: 'Poppins' },
                { value: 'Mulish', label: 'Mulish' },
              ]}
              value={formik.values.FontType}
              onChange={(value) => formik.setFieldValue('FontType', value)}
              className="min-w-48"
            />
          </div>
          <hr className="my-4 border-gray-200 dark:border-gray-700" />

          {/* Signature */}
          {/* <div className="flex justify-between items-center">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                {t('Signature')}
              </label>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                {t('Manage your digital signature for documents.')}
              </p>
            </div>
            <SignatureModal
              onSave={() => {}}
              userId={userId}
              onConfirm={() => {}}
            />
            </div> 
            <hr className="my-4 border-gray-200 dark:border-gray-700" />
*/}
          {/* Save Button */}
          <div className="mt-8 flex gap-x-4 justify-end">
            <Button size="small" variant="primary" type="submit" disabled={!formik.dirty}>
              {t('Save Document Settings')}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default DocumentSettings;