import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchAllClients } from '../../store/actions/ClientAction';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import Badge from '../shared/TailwindComponents/Badge';
import AddClientForm from './AddClientForm';
import Table from '../shared/TailwindComponents/Table';
import { PiGridFourLight, PiTableLight } from 'react-icons/pi';
import Button from '../shared/TailwindComponents/Button';
import ApiLoader from '../ApiLoader';

const Clients: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const allClients = useSelector((state: RootState) => state.client.allClients || []);

  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [isAddingClient, setIsAddingClient] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('name_asc');
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');

  const clientListRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = () => {
    const scrollPosition = clientListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    const listElem = clientListRef.current;
    if (listElem) {
      listElem.addEventListener('scroll', handleScroll);
      return () => listElem.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (sortValue === 'name_asc') {
      sortBy = 'name';
      sortOrder = 'asc';
    } else if (sortValue === 'name_desc') {
      sortBy = 'name';
      sortOrder = 'desc';
    }

    setIsClientsLoading(true);
    dispatch(
      fetchAllClients({
        searchTerm: searchQuery,
        filters: selectedFilters,
        sortBy,
        sortOrder,
      })
    ).finally(() => {
      setIsClientsLoading(false);
    });
  }, [searchQuery, selectedFilters, sortValue, dispatch]);

  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'grid' ? 'table' : 'grid'));
  };

  const handleAddClientClick = () => setIsAddingClient(true);
  const handleAddClientClose = () => setIsAddingClient(false);
  const handleClientClick = (client: any) => history.push(`/my-clients/${client.ClientID}`);
  const handleSearchChange = (query: string) => setSearchQuery(query);
  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };
  const handleSortChange = (value: string) => setSortValue(value);

  const filters = [
    {
      id: 'IDVerificationStatus',
      name: 'Verification Status',
      options: [
        { value: 'Pending', label: 'Pending' },
        { value: 'Verified', label: 'Verified' },
      ],
    },
    {
      id: 'ClientType',
      name: 'Client Type',
      options: [
        { value: 'individual', label: 'Individual' },
        { value: 'business', label: 'Business' },
      ],
    },
  ];

  const [sortOptions] = useState([
    { value: 'name_asc', label: 'Name Ascending', current: true },
    { value: 'name_desc', label: 'Name Descending', current: false },
  ]);

  const shrinkThreshold = 150;
  const isShrunk = scrollTop >= shrinkThreshold;

  const columns = ['Name', 'Verification', 'Client Type', 'Case Count'];
  const tableData = allClients.map((client: any) => {
    const verificationStatus = client.IDVerificationStatus;
    let verificationLabel = 'Not Verified';
    if (verificationStatus === 'Verified') verificationLabel = 'Verified';
    else if (verificationStatus === 'Pending') verificationLabel = 'Pending Verification';

    const displayName =
      client.UserTypeName.includes('Business')
        ? client.BusinessName || 'No name provided'
        : `${client.FirstName} ${client.LastName}`.trim() || 'No name provided';

    return {
      id: client.ClientID,
      Name: displayName,
      Verification: verificationLabel,
      'Client Type': client.UserTypeName.includes('Business') ? 'Business' : 'Individual',
      // Email: client.Email || 'N/A',
      'Case Count': client.CaseCount.toString(),
    };
  });

  const gridData = useMemo(() => {
    return allClients.map((client: any) => {
      const displayName =
        client.UserTypeName.includes('Business')
          ? client.BusinessName || t('No name provided')
          : `${client.FirstName} ${client.LastName}`.trim() || t('No name provided');
  
      const verification = {
        status: client.IDVerificationStatus === 'Verified'
          ? 'Verified'
          : client.IDVerificationStatus === 'Pending'
          ? 'Pending'
          : 'Not Verified',
        tooltip: client.IDVerificationStatus === 'Verified'
          ? 'Client is verified'
          : client.IDVerificationStatus === 'Pending'
          ? 'Verification pending'
          : 'Client must verify',
      };
  
      const details = [
        { label: 'Case Count', value: client.CaseCount.toString() },
        // { label: 'Verification', value: verification.status },
        {
          label: 'Client Type',
          value: client.UserTypeName.includes('Business') ? 'Business Client' : 'Individual Client',
        },
        // Uncomment if you want to add Email back
        // { label: 'Email', value: client.Email || 'N/A' },
      ];
  
      return (
        <ListItem
          key={client.ClientID}
          title={displayName}
          details={details}
          verification={verification as any}
          onClick={() => handleClientClick(client)}
          link={`/my-clients/${client.ClientID}`}
        />
      );
    });
  }, [allClients, t]);

  return (
    <div className="bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      <div
        className={`${
          isShrunk && !isAddingClient ? 'sticky top-[80px] z-20' : ''
        } transition-all duration-200 bg-background-light dark:bg-background-dark`}
        >

        {!isAddingClient && (
          <div>
          <Header
          title={isAddingClient ? t('Add New Client') : t('Clients')}
          subtitle={
            isAddingClient
            ? t('Fill in the details below to add a new client.')
            : t('Manage your client relationships and cases.')
          }
          actionLabel={isAddingClient ? t('Cancel Adding Client') : t('Add Client')}
          onActionClick={isAddingClient ? handleAddClientClose : handleAddClientClick}
          />
          <FilterSearchBar
          filters={filters}
          sortOptions={sortOptions}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
            sortValue={sortValue}
            extraControls={
              <Button
                variant="tertiary"
                onClick={toggleViewMode}
                size="small"
                tooltip={viewMode === 'grid' ? t('Switch to Table View') : t('Switch to Grid View')}
              >
                {viewMode === 'grid' ? (
                  <PiTableLight className="h-5 w-5" />
                ) : (
                  <PiGridFourLight className="h-5 w-5" />
                )}
              </Button>
            }
          />
        </div>
        )}
      </div>

      <div className="flex-grow overflow-y-auto h-full" ref={clientListRef}>
        {isAddingClient ? (
          <div className="w-2/3 mx-auto">
            <AddClientForm onClose={handleAddClientClose} />
          </div>
        ) : isClientsLoading ? (
          <ApiLoader />
        ) : (
          <>
            {viewMode === 'grid' ? (
              <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {gridData}
              </div>
            ) : (
              <div className="p-4">
                {allClients.length > 0 ? (
                  <Table
                    columns={columns}
                    data={tableData}
                    onRowClick={(row) => history.push(`/my-clients/${row.id}`)}
                  />
                ) : (
                  <div className="flex justify-center items-center h-[calc(100vh-200px)]">
                    <Badge color="gray">{t('No clients available')}</Badge>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Clients;