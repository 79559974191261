import React, { useState } from 'react';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';
import Input from '../../shared/TailwindComponents/Input';
import { PiUsersLight } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

interface DepartmentInputProps {
  label: string;
  departments: DropdownOption[];
  value: string;
  onChange: (value: string) => void;
  description?: string;
  placeholder?: string;
  error?: string;
}

const DepartmentInput: React.FC<DepartmentInputProps> = ({
  label,
  departments,
  value,
  onChange,
  description,
  placeholder,
  error,
}) => {
  const [isCustom, setIsCustom] = useState(false);
  const {t} = useTranslation()

  const handleDropdownChange = (selectedValue: string | string[]) => {
    onChange(selectedValue as string);
    setIsCustom(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium">{label}</label>
      <div className="flex items-center space-x-2">
        <Dropdown
          options={departments}
          value={isCustom ? '' : value}
          onChange={handleDropdownChange}
          placeholder={placeholder}
          className={isCustom ? 'hidden' : ''}
        />
        <Input
          value={isCustom ? value : ''}
          onChange={handleInputChange}
          placeholder={t("Enter custom department")}
          type="text"
          className={!isCustom ? 'hidden' : ''}
          icon={<PiUsersLight size={20} className="text-gray-500" />}
        />
        <button
          type="button"
          onClick={() => setIsCustom(!isCustom)}
          className="text-sm text-primary-500 hover:underline"
        >
          {isCustom ? t('Select from list') : t('Enter custom')}
        </button>
      </div>
      {description && <p className="text-sm text-gray-500">{description}</p>}
      {error && <p className="text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default DepartmentInput;