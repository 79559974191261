import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { addTeam } from '../../../store/actions/organization/teamActions';
import Input from '../../shared/TailwindComponents/Input';
import Button from '../../shared/TailwindComponents/Button';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';
import { fetchAllEmployees } from '../../../store/actions/organization/employeeActions';
import { fetchLegalSubjects } from '../../../store/actions/DropdownActions';
import { Tooltip } from '@mui/material';
import Badge from '../../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';
import { PiUsersLight, PiBriefcaseLight, PiScalesLight, PiInfoLight } from 'react-icons/pi';

interface AddTeamFormProps {
  onClose: () => void;
}

interface LegalSubjectOption extends DropdownOption {
  category: string;
  areas: string;
}

interface FormValues {
  TeamName: string;
  Description: string;
  Members: string[];
  TeamLeadUserID: string;
  LegalSubjects: string[];
}

const AddTeamForm: React.FC<AddTeamFormProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const employees = useSelector((state: RootState) => state.employees.employees);
  const { legalSubjects } = useSelector((state: RootState) => state.dropdown);
  const [legalSubjectOptions, setLegalSubjectOptions] = useState<LegalSubjectOption[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchAllEmployees());
    dispatch(fetchLegalSubjects());
  }, [dispatch]);

  useEffect(() => {
    const options = legalSubjects.map((subject: any) => ({
      value: subject.value,
      label: subject.label,
      category: subject.category,
      areas: subject.areas,
    }));
    setLegalSubjectOptions(options);
  }, [legalSubjects]);

  const handleSelectLegalSubject = (value: string) => {
    const selectedSubjects = formik.values.LegalSubjects;
    if (selectedSubjects.includes(value)) {
      formik.setFieldValue('LegalSubjects', selectedSubjects.filter((id) => id !== value));
    } else {
      formik.setFieldValue('LegalSubjects', [...selectedSubjects, value]);
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      TeamName: '',
      Description: '',
      Members: [],
      TeamLeadUserID: '',
      LegalSubjects: [],
    },
    validationSchema: Yup.object({
      TeamName: Yup.string().required('Team name is required'),
      Members: Yup.array().min(2, 'At least 2 members are required').required('Team members are required'),
      TeamLeadUserID: Yup.string()
        .required('Team Lead is required')
        .test('is-member', 'Team Lead must be one of the selected members', function (value) {
          const { Members } = this.parent;
          return Members.includes(value);
        }),
      LegalSubjects: Yup.array().of(Yup.string()).min(1, 'At least one legal subject must be selected').required('Legal subjects are required'),
    }),
    onSubmit: async (values) => {
      try {
        await dispatch(addTeam(values));
        onClose();
      } catch (error) {
        console.error('Error adding team:', error);
      }
    },
  });

  const handleMemberSelection = (employeeId: string) => {
    const { Members } = formik.values;
    if (Members.includes(employeeId)) {
      formik.setFieldValue('Members', Members.filter((id) => id !== employeeId));
    } else {
      formik.setFieldValue('Members', [...Members, employeeId]);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="px-6 pb-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-2xl mx-auto mt-5 h-[calc(100vh-125px)] flex flex-col overflow-y-auto">
      {/* Header */}
      <div className="sticky top-0 z-10 pt-6 bg-white dark:bg-gray-900 pb-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center gap-2">
          <PiUsersLight size={28} className="text-primary-500" />
          {t('Add New Team')}
        </h2>
        <div className="flex gap-2">
          <Button variant="neutral" size="small" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" type="submit" form="add-team-form" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? t('Adding...') : t('Create Team')}
          </Button>
        </div>
      </div>
      <form id="add-team-form" onSubmit={formik.handleSubmit} className="space-y-6 mt-4 flex-1">
        <Input
          label='Team Name'
          name="TeamName"
          value={formik.values.TeamName}
          onChange={formik.handleChange}
          error={formik.touched.TeamName ? formik.errors.TeamName : undefined}
          description='Name of the team for easy identification.'
          placeholder='Team A'
          type="text"
          icon={<PiBriefcaseLight size={20} className="text-gray-500" />}
        />
        <Input
          label='Description (Optional)'
          name="Description"
          value={formik.values.Description}
          onChange={formik.handleChange}
          description="Brief description of the team's focus or responsibilities."
          placeholder='Team A is...'
          type="text"
          icon={<PiInfoLight size={20} className="text-gray-500" />}
        />
        {/* Members Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex items-center gap-2">
            <PiUsersLight size={20} className="text-primary-500" />
            {t('Team Members')}
          </label>
          <p className="text-sm text-gray-500 dark:text-gray-300 mb-2">{t('Add team members to collaborate effectively.')}</p>
          <div className="max-h-40 overflow-y-scroll border rounded p-2 bg-gray-50 dark:bg-gray-800">
            {employees
              .filter((employee) => employee.UserID && employee.FirstName && employee.LastName)
              .map((employee) => (
                <div key={employee.UserID} className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    checked={formik.values.Members.includes(employee.UserID)}
                    onChange={() => handleMemberSelection(employee.UserID)}
                    className="mr-2 h-4 w-4 text-primary-600 border-gray-300 rounded"
                  />
                  <span className="text-gray-800 dark:text-gray-200">{`${employee.FirstName} ${employee.LastName}`}</span>
                </div>
              ))}
          </div>
          {formik.touched.Members && formik.errors.Members && (
            <p className="mt-1 text-sm text-red-600">{formik.errors.Members}</p>
          )}
        </div>
        {/* Team Lead Selection */}
        <div>
          <Dropdown
            label={t('Select Team Lead')}
            options={formik.values.Members.map((memberId) => {
              const employee = employees.find((emp) => emp.UserID === memberId);
              return {
                value: memberId,
                label: employee ? `${employee.FirstName} ${employee.LastName}` : 'N/A',
              };
            })}
            description={t('Assign a team lead to manage and guide the team.')}
            onSelect={(option) => formik.setFieldValue('TeamLeadUserID', option.value)}
            value={formik.values.TeamLeadUserID}
            placeholder={t('Select a team lead')}
            error={formik.touched.TeamLeadUserID ? formik.errors.TeamLeadUserID : undefined}
          />
        </div>
        {/* Legal Subjects Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex items-center gap-2">
            <PiScalesLight size={20} className="text-primary-500" />
            {t('Select Legal Subjects')}
          </label>
          <p className="text-sm text-gray-500 dark:text-gray-300 mb-2">{t('Choose the legal areas the team will specialize in.')}</p>
          <div className="relative">
            <div
              className="cursor-pointer w-full flex justify-between bg-gray-50 dark:bg-gray-800 p-2 rounded border border-gray-300 dark:border-gray-600"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <span className="text-sm text-gray-800 dark:text-gray-200">
                {formik.values.LegalSubjects.length > 0
                  ? legalSubjectOptions
                      .filter((option) => formik.values.LegalSubjects.includes(option.value))
                      .map((option) => t(option.label))
                      .join(', ')
                  : t('Select legal subjects')}
              </span>
              <i className={`text-gray-500 fas ${isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </div>
            {isDropdownOpen && (
              <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border rounded shadow-lg max-h-60 overflow-auto">
                <ul>
                  {legalSubjectOptions.map((option) => (
                    <Tooltip title={option.areas} placement="top" key={option.value}>
                      <li
                        className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 p-2 flex items-center"
                        onClick={() => handleSelectLegalSubject(option.value)}
                      >
                        <input
                          type="checkbox"
                          checked={formik.values.LegalSubjects.includes(option.value)}
                          readOnly
                          className="h-4 w-4 text-primary-600 border-gray-300 rounded mr-2"
                        />
                        <div className="flex-1">
                          <div className="flex flex-col">
                            <span className="text-xs font-medium text-gray-800 dark:text-gray-200">{t(option.label)}</span>
                            <Badge color="blue" className="w-fit">
                              {t(option.category)}
                            </Badge>
                          </div>
                        </div>
                      </li>
                    </Tooltip>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {formik.touched.LegalSubjects && formik.errors.LegalSubjects && (
            <p className="mt-1 text-sm text-red-600">{formik.errors.LegalSubjects}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddTeamForm;