import React from 'react';

interface TableSkeletonLoaderProps {
  columns: number; 
  rows?: number;  
}

const TableSkeletonLoader: React.FC<TableSkeletonLoaderProps> = ({ columns, rows = 5 }) => {
  return (
    <div className="overflow-x-auto mt-4 rounded-xl shadow-md dark:shadow-lg animate-pulseFade">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className="bg-gray-50 dark:bg-gray-800">
          <tr>
            {[...Array(columns)].map((_, idx) => (
              <th key={idx} className="px-6 py-4">
                <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-24"></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
          {[...Array(rows)].map((_, rowIdx) => (
            <tr
              key={rowIdx}
              className={rowIdx % 2 === 0 ? 'bg-white dark:bg-gray-900' : 'bg-gray-50 dark:bg-gray-800'}
            >
              {[...Array(columns)].map((_, colIdx) => (
                <td key={colIdx} className="px-6 py-4">
                  <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-full"></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeletonLoader;