import React, { useRef, useState, useEffect } from 'react';
import PlusIcon from '../icons/PlusIcon';
import { useTranslation } from 'react-i18next';
import Badge from './Badge';

interface SingleFileInputProps {
  label?: string;
  accept?: string;
  file: File | null;
  setFile: (file: File | null) => void;
  subMsg?: string;
  existingFileUrl?: string;
  desc?: string;
  disabled?: boolean;
}

const SingleFileInput: React.FC<SingleFileInputProps> = ({
  label,
  accept,
  file,
  setFile,
  subMsg,
  existingFileUrl,
  desc,
  disabled,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const isImageType = accept && accept.includes('image');

  // Update preview URL based on file or existingFileUrl
  useEffect(() => {
    if (file) {
      if (isImageType) {
        const objectUrl = URL.createObjectURL(file);
        setPreviewUrl(objectUrl);
        return () => {
          if (objectUrl.startsWith('blob:')) {
            URL.revokeObjectURL(objectUrl);
          }
        };
      } else {
        setPreviewUrl(null);
      }
    } else if (existingFileUrl && isImageType) {
      setPreviewUrl(existingFileUrl);
    } else {
      setPreviewUrl(null);
    }
  }, [file, existingFileUrl, isImageType]);

  const handleAddFile = () => {
    if (!disabled && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    if (disabled) return;
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const getFileName = (): string => {
    if (file) return file.name;
    if (existingFileUrl) {
      const parts = existingFileUrl.split('/');
      return parts[parts.length - 1] || 'Existing file';
    }
    return '';
  };

  return (
    <div className='flex justify-between'>
      <div>
      {label && <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{t(label)}</label>}
      {desc && <p className="text-xs text-gray-500 dark:text-gray-400 text-left italic">{t(desc)}</p>}
      {subMsg && <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t(subMsg)}</p>}
      </div>
      <div className="mt-2 flex items-center">
        {isImageType ? (
          <div
            className={`relative w-48 h-24 flex items-center justify-center cursor-pointer ${
              disabled ? 'cursor-not-allowed opacity-50' : ''
            } ${isDragging ? 'border-2 border-dashed border-blue-500' : ''}`}
            onClick={handleAddFile}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={() => setIsDragging(true)}
            onDragLeave={() => setIsDragging(false)}
            onDrop={handleDrop}
          >
            {previewUrl ? (
              <img
                src={previewUrl}
                alt="Preview"
                className="max-w-full max-h-full object-contain"
              />
            ) : (
              <PlusIcon className="w-6 h-6 text-gray-500" />
            )}
            {previewUrl && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 hover:opacity-100 transition-opacity">
                {t('Change Image')}
              </div>
            )}
          </div>
        ) : (
          <div
            className={`p-2 border border-dashed border-gray-300 rounded ${
              isDragging ? 'border-blue-500' : ''
            } ${disabled ? 'opacity-50' : ''}`}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={() => setIsDragging(true)}
            onDragLeave={() => setIsDragging(false)}
            onDrop={handleDrop}
          >
            <div
              onClick={handleAddFile}
              className={`cursor-pointer flex items-center space-x-2 ${
                disabled ? 'cursor-not-allowed' : ''
              }`}
            >
              {file || existingFileUrl ? (
                <Badge
                  color="indigo"
                  className="max-w-40 truncate"
                  tooltip={t('Click to change')}
                  disabled={disabled}
                >
                  {getFileName()}
                </Badge>
              ) : (
                <div className="text-blue-600 hover:underline dark:text-blue-400 flex items-center">
                  <i className="fas fa-file-upload mr-2"></i>
                  <span>{t('Choose a file')}</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
        disabled={disabled}
      />
    </div>
  );
};

export default SingleFileInput;