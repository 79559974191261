import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { RootState } from '../../../store';
import Button from '../../shared/TailwindComponents/Button';
import VerifyButton from '../Security/StripeVerificationButton';
import { PiUserCheckLight } from 'react-icons/pi';

const stripeKey = process.env.REACT_APP_STRIPE_KEY || '';
const stripePromise = loadStripe(stripeKey);

const IdentityVerificationTab: React.FC = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
        {t('Identity Verification')}
      </h3>
      <p className="text-sm text-gray-500 dark:text-gray-400">
        {t(
          'Verify your identity to meet compliance standards and ensure secure access to the platform. Powered by Stripe, we ensure a seamless and secure process.'
        )}
      </p>
      <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg shadow-light-md dark:shadow-dark-md">
        <div className="flex items-center space-x-4">
          <PiUserCheckLight className="text-4xl text-primary-500" />
          <div>
            <h4 className="text-sm font-medium text-gray-900 dark:text-white">
              {t('Identity Verification')}
            </h4>
            <p className="text-xs text-gray-500 dark:text-gray-400 w-4/5">
              {t('Verify your identity to meet compliance standards and ensure secure access to the platform.')}
            </p>
          </div>
        </div>
        {userInfo.IsVerified ? (
          <Button variant="primary" size="small">
            {t('Verified')}
          </Button>
        ) : (
          <VerifyButton stripePromise={stripePromise} />
        )}
      </div>
    </div>
  );
};

export default IdentityVerificationTab;