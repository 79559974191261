import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { RootState, useAppDispatch } from '../../store';
import { hideModal } from '../../store/actions/modalActions';
import { Citation, getUserID} from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import SignatureModal from '../Settings/Security/DigitalSignature';
import AddTask from '../Tasks/AddTaskComponent';
import BankFormItems from '../Settings/Security/BankFormItems';
import AnonymizeDocumentModal from '../Documents/AnonymizeDocumentModal';
import ShareContractModal from '../Documents/ShareContractModal';
import TranslationModal from '../Documents/TranslationModal';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';
import Textarea from '../shared/TailwindComponents/Textarea';
import { CheckIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import NewOtpInput from '../Auth/NewOtpInput';
import ReactMarkdown from 'react-markdown';
import VaultPicker from './TailwindComponents/VaultPicker';
import TimeStopModalContent from '../TimeTracker/TimeStopModalContent';
import SuggestedTasksModal from '../Tasks/SuggestedTasksModal';
import AttachCaseToClientModalContent from '../Clients/AttachCaseToClientModalContent';
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa";
import AISettingsComponent from '../Settings/AccountSettings/AISettings';
import SettingsModal from '../Settings/SettingsModal';
import WorkflowModal from '../Cases/LegalServices/Workflows/WorkflowModal';

const GlobalModal: React.FC = () => {
  const modalState = useSelector((state: RootState) => state.modal);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const userId = getUserID();

  const [inputData, setInputData] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [completeTask, setCompleteTask] = useState(false);
  const [comment, setComment] = useState("");

  // Function to close the modal
  const handleClose = useCallback(() => {
    setInputData('');
    setCompleteTask(false);
    dispatch(hideModal());
    if (modalState.onCancel) {
      modalState.onCancel();
    }
  },[dispatch,modalState])

  // Function to confirm folder creation
  const handleConfirmCreateFolder = () => {
    if (modalState.type === 'create' && modalState.onConfirm) {
      modalState.onConfirm(inputData);
    }
    handleClose();
  };

  // Function to handle confirmation actions based on modal type
  const handleConfirm = useCallback(() => {
    if (modalState.onConfirm) {
      if (modalState.type === 'timeStop') {
        modalState.onConfirm(inputData, completeTask);
      } else if (modalState.type === 'addSignature') {
        const signatureBlob = new Blob([inputData], { type: 'image/svg+xml' });
        const signatureFile = new File([signatureBlob], 'signature.svg', { type: 'image/svg+xml' });
        modalState.onConfirm(signatureFile);
      } else if (['share', 'sign', 'password', 'promptfeedback', 'confirmation'].includes(modalState.type)) {
        modalState.onConfirm(inputData);
      } else if (modalState.type === 'forgotPassword') {
        modalState.onConfirm(modalState.inputData ? modalState.inputData : email);
        setEmail('');
      } else if (modalState.type === '2fa-qrcode') {
        modalState.onConfirm(verificationCode);
        setVerificationCode('');
      } else if (modalState.type === 'alert') {
        modalState.onConfirm(modalState.inputData ? modalState.inputData : '');
      } else if (modalState.type === 'confirm-upload') {
        modalState.onConfirm(inputData);
      }
    }

    if (modalState.type !== '2fa-qrcode') {
      handleClose();
    }
  },[completeTask,email,handleClose,inputData,modalState,verificationCode])

  useEffect(() => {
    if (!modalState.showModal) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const target = event.target as HTMLElement;
        const isTextarea = target.tagName === 'TEXTAREA';
        if (!isTextarea) {
          handleConfirm();
        }
      } else if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalState.showModal, handleConfirm, handleClose]);


  // Function to get the appropriate icon based on modal type
  const getIcon = () => {
    switch (modalState.type) {
      case 'success':
        return (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 dark:bg-green-800">
            <CheckIcon className="h-6 w-6 text-green-600 dark:text-green-100" aria-hidden="true" />
          </div>
        );
      case 'error':
        return (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100 dark:bg-red-800">
            <XCircleIcon className="h-6 w-6 text-red-600 dark:text-red-100" aria-hidden="true" />
          </div>
        );
      case 'warning':
        return (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100 dark:bg-yellow-800">
            <ExclamationCircleIcon className="h-6 w-6 text-yellow-600 dark:text-yellow-100" aria-hidden="true" />
          </div>
        );
      default:
        return null;
    }
  };

  const handleVaultFilesSelected = (selectedFiles: { id: string; name: string; url?: string }[]) => {
    if (modalState.onConfirm) {
      modalState.onConfirm(selectedFiles);
    }
    handleClose();
  };

  // Main return
  return (
    <Transition.Root show={modalState.showModal} as={Fragment}>
      <Dialog as="div" className="relative z-[300000000]" onClose={handleClose}>
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Modal Content */}
        <div className="fixed inset-0 z-[3000] overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            {/* Modal Panel */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-lg md:max-w-2/3 sm:max-w-6xl sm:p-6">
                {/* Close Button */}
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none"
                    onClick={handleClose}
                  >
                    <span className="sr-only">{t("Close")}</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Icon */}
                {getIcon()}

                {/* Title and Subtitle */}
                <div className="mt-3 text-center sm:mt-5">
                  {modalState.message && (
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                      {t(modalState.message)}
                    </Dialog.Title>
                  )}

                  {/* Title for Citations */}
                  {modalState.type === 'citations' && (
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                      {t('Citations')}
                    </Dialog.Title>
                  )}
                  {modalState.subMsg && (
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: t(modalState.subMsg) }}></p>
                    </div>
                  )}
                </div>

                {/* Modal Content Based on Type */}
                <div className="mt-5 sm:mt-6">
                  {/* Create Folder */}
                  {modalState.type === 'create' && (
                    <div className='max-w-md mx-auto'>
                      <Input
                        type="text"
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                        placeholder={t('Folder name')}
                        className="w-full mb-4" id={''} />
                      <Button variant="primary" size='small' onClick={handleConfirmCreateFolder} className="w-full">
                        {t('Create')}
                      </Button>
                      </div>
                  )}

                  {modalState.type === 'attachExistingCaseToClient' && (
                    <AttachCaseToClientModalContent
                      clientId={modalState.clientId as string}
                      onClose={handleClose}
                    />
                  )}

                  {/* Forgot Password */}
                  {modalState.type === 'forgotPassword' && (
                    <>
                      <Input
                        type="email"
                        placeholder={t('Enter your email')}
                        value={modalState.inputData ? modalState.inputData : email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full mb-4" id={''} />
                      <Button variant="primary" size='small' onClick={handleConfirm} className="w-full">
                        {t('Send Reset Link')}
                      </Button>
                    </>
                  )}


                  {/* Tips */}
                  {modalState.type === 'tips' && (
                    <>
                      <p className="text-sm text-gray-500 dark:text-gray-300">{modalState.info}</p>
                      <Button variant="primary" size='small' onClick={handleClose} className="mt-4 w-full">
                        {t('Close')}
                      </Button>
                    </>
                  )}

                  {/* Info */}
                  {modalState.type === 'info' && (
                    <>
                      <h1 className="text-lg font-medium text-gray-900 dark:text-gray-100">{modalState.title}</h1>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: modalState.info! }}></p>
                      </div>
                    </>
                  )}

                  {/* Add Task */}
                  {modalState.type === 'addTask' && (
                    <AddTask />
                  )}

                  {modalState.type === 'suggestedTasks' && (
                    <SuggestedTasksModal
                      tasks={modalState.tasks || []}
                      onConfirm={modalState.onConfirm}
                      onCancel={handleClose}
                    />
                  )}

                  {/* Password */}
                  {modalState.type === 'password' && (
                    <>
                      <Input
                        type="password"
                        placeholder={t('Enter your password')}
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                        className="w-full mb-4" id={''} />
                      <Button variant="primary" size='small' onClick={handleConfirm} className="w-full">
                        {t('Save Changes')}
                      </Button>
                    </>
                  )}

                  {/* Prompt Feedback */}
                  {modalState.type === 'promptfeedback' && (
                    <>
                      <p className="text-sm text-gray-500 dark:text-gray-300">{t('Please let us know what went wrong.')}</p>
                      <Textarea
                        placeholder={t('Please provide a feedback on what went wrong')}
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                        id={''}
                        label={'Feedback'}
                      />
                      <Button variant="primary" size='small' onClick={handleConfirm} className="w-full">
                        {t('Send feedback')}
                      </Button>
                    </>
                  )}

                  {/* Share or Sign */}
                  {(modalState.type === 'share' || modalState.type === 'sign') && (
                    <ShareContractModal />
                  )}

                  {/* Confirmation */}
                  {modalState.type === 'confirmation' && (
                    <div className="flex justify-between space-x-4">
                      <Button variant="destructive" size='small' onClick={handleClose} className="w-1/2">
                        {t('No')}
                      </Button>
                      <Button variant="primary" size='small' onClick={() => modalState.onConfirm && modalState.onConfirm(inputData)} className="w-1/2">
                        {t('Yes')}
                      </Button>
                    </div>
                  )}

                  {/* Success, Error, Warning, Feedback */}
                  {['success', 'error', 'warning', 'feedback'].includes(modalState.type) && (
                    <div className="mt-5 sm:mt-6">
                      {/* Additional content if needed */}
                    </div>
                  )}

                  {/* Alert */}
                  {modalState.type === 'alert' && (
                    <div className="mt-5 sm:mt-6 flex justify-end space-x-4">
                      <Button size='small' variant="destructive" onClick={handleClose}>
                        {t('Cancel')}
                      </Button>
                      <Button size='small' variant="primary" onClick={handleConfirm}>
                        {t('Yes, Sign out')}
                      </Button>
                    </div>
                  )}

                  {/* OTP Verification */}
                  {modalState.type === 'otp-verification' && (
                    <>
                      <NewOtpInput
                        onSubmit={modalState.onConfirm ? (data: File | string) => modalState.onConfirm!(data) : () => { }}
                        handleClose={handleClose}
                        method={modalState.inputData}
                      />
                    </>
                  )}

                  {/* Subscription Modal */}
                  {/* {modalState.type === 'subscription-modal' && (
                    <PricingPage />
                  )} */}

                  {/* Subscription Modal */}
                  {modalState.type === 'ai-settings' && (
                    <AISettingsComponent />
                  )}

                    {modalState.type === 'settings' && (
                      <SettingsModal />
                    )}
                  {/* 2FA QR Code */}
                  {modalState.type === '2fa-qrcode' && (
                    <>
                      <div className="mt-4 flex space-x-2 max-w-4xl">
                        <div>
                          <p className='text-md text-gray-800 dark:text-gray-300'>
                            {t('What is 2FA')}
                          </p>
                          <p className="text-sm text-gray-700 dark:text-gray-300 mb-2">
                            {t('2FA adds an extra layer of security by requiring a verification code in addition to your password, ensuring only you can access your account.')}
                          </p>
                          <p className='text-md text-gray-800 dark:text-gray-300'>
                            {t('How to Enable 2FA')}
                          </p>
                          <ol className="list-decimal list-inside text-sm text-gray-500 dark:text-gray-300">
                            <li>{t("Download an authenticator app (e.g., Microsoft Authenticator, Google Authenticator).")}</li>
                            <li>{t("Open the app and scan the QR code displayed on your screen.")}</li>
                            <li>{t("Enter the generated code from the app into the input field.")}</li>
                            <li>{t("Click")} <strong>{('Enable')}</strong></li>
                          </ol>
                        </div>
                        {modalState.inputData && (
                          <img src={`data:image/png;base64,${modalState.inputData}`} alt={t("QR Code")} className="mx-auto max-w-48" />
                        )}
                      </div>
                      <div className='my-4'>
                        <Input
                          type="text"
                          name="verification-code"
                          id="verification-code"
                          placeholder={t('Code')}
                          value={verificationCode}
                          onChange={(e) => setVerificationCode(e.target.value)}
                          className='w-48 max-w-48'
                        />
                      </div>
                      <div className="flex justify-end space-x-4">
                        <Button variant="destructive" size='small' onClick={handleClose}>
                          {t('Cancel')}
                        </Button>
                        <Button variant="primary" size='small' onClick={handleConfirm}>
                          {t('Enable')}
                        </Button>
                      </div>
                    </>
                  )}



                  {/* Anonymize Document */}
                  {modalState.type === 'anonymize' && (
                    <AnonymizeDocumentModal fileID={modalState.fileID} />
                  )}

                  {/* Translate Document */}
                  {modalState.type === 'translate' && (
                    <TranslationModal fileID={modalState.fileID} />
                  )}

                  {modalState.type === 'confirm-upload' && (
                    <div className="flex flex-col space-y-4">
                      <Button variant="primary" size='small' onClick={() => modalState.onConfirm && modalState.onConfirm('append')}>
                        {t('Append')}
                      </Button>
                      <Button variant="primary" size='small' onClick={() => modalState.onConfirm && modalState.onConfirm('replace')}>
                        {t('Replace')}
                      </Button>
                    </div>
                  )}

                  {modalState.type === 'custom' && modalState.content && (
                    <div>{modalState.content}</div>
                  )}

                  {/* Citations Modal */}
                  {modalState.type === 'citations' && modalState.citations && (
                    <div className="space-y-4">
                      {modalState.citations.map((citation: Citation, index: number) => (
                        <div key={index} className="border-b border-gray-200 dark:border-gray-700 pb-4">
                          <div className="flex justify-between items-center">
                            <span className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                              {citation.id}
                            </span>
                          </div>
                          <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                            {citation.snippet}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Citation Modal */}
                  {modalState.type === 'citation' && modalState.citation && (
                    <div className='max-w-2xl'>
                      <h4 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                        {modalState.citation.id}
                      </h4>
                      <hr className="my-2 border-gray-300 dark:border-gray-700" />
                      <ReactMarkdown
                        className="markdown-content"
                        components={{
                          p: ({ node, ...props }) => <p className="mb-4" {...props} />,
                        }}
                      >
                        {modalState.citation.snippet}
                      </ReactMarkdown>
                    </div>
                  )}

                  {/* Vault Picker */}
                  {modalState.type === 'vaultFilePicker' && (
                    <VaultPicker onFilesSelected={handleVaultFilesSelected} />
                  )}

                  {/* Workflows */}
                  {modalState.type === 'workflows' && (
                    <WorkflowModal caseId={modalState.caseId as string} onClose={handleClose}onSave={modalState.onSave || (() => {})} />
                  )}
                  
                  {/* Time Stop */}
                  {modalState.type === 'timeStop' && (
                    <TimeStopModalContent
                      onConfirm={(data) => {
                        if (modalState.onConfirm) {
                          modalState.onConfirm(data);
                        }
                      }}
                      onClose={handleClose}
                    />
                  )}

                  {
                    modalState.type === 'sign_doc' && (
                      <>
                        <SignatureModal
                          onSave={() => { }}
                          onConfirm={() => { }}
                        />
                        <div className="w-full flex justify-end space-x-4 mt-4">
                          <Button variant="primary" size='small' onClick={() => modalState.onConfirm && modalState.onConfirm(modalState.fileID)}>
                            {t('Sign')}
                          </Button>
                          <Button variant="secondary" size='small' onClick={() => handleClose()}>
                            {t('Close')}
                          </Button>
                        </div>
                      </>
                    )
                  }


                  {/* Translate Document */}
                  {modalState.type === 'redirect' && (
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
                      <div className="bg-white dark:bg-gray-800 dark:text-gray-200 rounded-lg shadow-lg p-6 w-full max-w-md transition-all">

                        {/* Header */}
                        <div className="flex justify-between items-center border-b pb-3 dark:border-gray-700">
                          <h2 className="text-lg font-semibold flex items-center gap-2">
                            <AiOutlineInfoCircle className="text-blue-600 dark:text-blue-400" />
                            {t("Redirect Notice")}
                          </h2>
                          <button
                            onClick={() => handleClose()}
                            className="text-gray-500 hover:text-red-500 transition"
                          >
                            <AiOutlineClose className="w-5 h-5" />
                          </button>
                        </div>

                        {/* Body */}
                        <div className="mt-4">
                          <p className="text-gray-700 dark:text-gray-300">{t(modalState.message)}</p>
                          <div className="mt-3 p-3 bg-gray-100 dark:bg-gray-700 rounded-md text-sm">
                            <span className="text-gray-600 dark:text-gray-300">{t("Redirecting to:")}</span>
                            <p className="break-all font-medium text-blue-600 dark:text-blue-400">{modalState.inputData}</p>
                          </div>
                        </div>

                        {/* Footer */}
                        <div className="mt-5 flex justify-end gap-2">
                          <button
                            onClick={() => handleClose()}
                            className="px-4 py-2 rounded-md bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-400 text-gray-900 dark:text-gray-200 transition"
                          >
                            {t("Cancel")}
                          </button>
                          <button
                            className="px-4 py-2 rounded-md bg-blue-600 text-white flex items-center gap-2 hover:bg-blue-700 transition"
                            onClick={() => {
                              modalState.inputData && window.location.assign(modalState.inputData);
                              handleClose()
                            }}
                          >
                            {t("Continue")} <FaArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Receiver Options */}
                  {modalState.type === 'receiver-options' && (
                    <BankFormItems />
                  )}

                  {modalState.type === 'addTaskComment' && (
                    <div className='w-96'>
                      <Textarea
                        placeholder={t("Write your comment here...")}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)} 
                        id={''} 
                        label={t('Comment')}
                          />

                      <div className="flex justify-end mt-4 space-x-2">
                        <Button
                        variant='neutral'
                        size='small'
                          onClick={handleClose}
                        >
                          {t("Cancel")}
                        </Button>
                        <Button
                          className={`${comment.trim() ? "" : "bg-gray-300 cursor-not-allowed"
                            }`}
                            variant='primary'
                            size='small'
                          onClick={() => modalState.onConfirm && modalState.onConfirm(comment.trim())}
                          disabled={!comment.trim()}
                        >
                          {t("Submit")}
                        </Button>
                      </div>
                    </div>

                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GlobalModal;
