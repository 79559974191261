import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchAllUserTickets, fetchCategories } from '../../store/actions/HelpAction';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../shared/TailwindComponents/Header';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Table from '../shared/TailwindComponents/Table';
import ApiLoader from '../ApiLoader';
import Badge from '../shared/TailwindComponents/Badge';
import { format } from 'date-fns';
import CreateTicketForm from './CreateTicketForm';
import { TicketItem } from '../Feedback/SupportTicketSystem';
import { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';

const SupportCenter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userTickets = useSelector((state: RootState) => state.help.allUserTickets);
  const categories = useSelector((state: RootState) => state.help.categories);
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);
  const [isTicketsLoading, setIsTicketsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('created_at_desc');
  const { setBreadcrumbs } = useBreadcrumbs();

useEffect(() => {
  setBreadcrumbs([{ name: 'Support', href: '/support', current: true }]);
  return () => {
    setBreadcrumbs([]);
  };
}, [setBreadcrumbs]);

  useEffect(() => {
    dispatch(fetchCategories());
    setIsTicketsLoading(true);
    dispatch(
      fetchAllUserTickets({
        searchTerm: searchQuery,
        filters: selectedFilters,
        sortBy: sortValue,
      })
    ).finally(() => setIsTicketsLoading(false));
  }, [dispatch, searchQuery, selectedFilters, sortValue]);

  const handleCreateTicketClick = () => setIsCreatingTicket(true);
  const handleCreateTicketClose = () => setIsCreatingTicket(false);
  const handleSearchChange = (query: string) => setSearchQuery(query);
  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({ ...prev, [filterName]: selectedValues }));
  };
  const handleSortChange = (value: string) => setSortValue(value);

  const filters = [
    {
      id: 'Category',
      name: t('Category'),
      options: categories ? categories.map((cat: DropdownOption) => ({ value: cat.value, label: t(cat.label) })) : [],
    },
    {
      id: 'Status',
      name: t('Status'),
      options: [
        { value: 'Open', label: t('Open') },
        { value: 'Resolved', label: t('Resolved') },
      ],
    },
  ];

  const sortOptions = [
    { value: 'created_at_desc', label: t('Created At Descending'), current: true },
    { value: 'created_at_asc', label: t('Created At Ascending'), current: false },
    { value: 'updated_at_desc', label: t('Updated At Descending'), current: false },
    { value: 'updated_at_asc', label: t('Updated At Ascending'), current: false },
  ];

  // Updated table configuration to use SupportID instead of Subject
  const columns = [t('Ticket ID'), t('Category'), t('Status'), t('Resolved'), t('Created At')];
  const tableData = userTickets
    ? userTickets.map((ticket: TicketItem) => ({
        id: ticket.TicketID,
        [t('Ticket ID')]: `#${String(ticket.SupportID).padStart(6, '0')}`, 
        [t('Category')]: t(ticket.Category),
        [t('Status')]: ticket.Status,
        [t('Resolved')]: ticket.Resolved ? t('Yes') : t('No'),
        [t('Created At')]: format(new Date(ticket.CreatedAt), 'yyyy-MM-dd HH:mm'),
      }))
    : [];

  return (
    <div className="bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 flex flex-col overflow-hidden px-4">
      {!isCreatingTicket && (
        <div className="sticky z-20 bg-background-light dark:bg-background-dark">
          <Header
            title={isCreatingTicket ? t('Create New Ticket') : t('Support Tickets')}
            subtitle={
              isCreatingTicket
                ? t('Fill in the details below to create a new support ticket.')
                : t('View and manage your support tickets.')
            }
            actionLabel={isCreatingTicket ? t('Cancel') : t('Create New Ticket')}
            onActionClick={isCreatingTicket ? handleCreateTicketClose : handleCreateTicketClick}
          />
          {!isCreatingTicket && (
            <FilterSearchBar
              filters={filters}
              sortOptions={sortOptions}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              onSortChange={handleSortChange}
              onSearchChange={handleSearchChange}
              sortValue={sortValue}
            />
          )}
        </div>
      )}
      <div className="flex-grow overflow-y-auto h-full">
        {isCreatingTicket ? (
          <CreateTicketForm
            onCancel={handleCreateTicketClose}
            searchQuery={searchQuery}
            selectedFilters={selectedFilters}
            sortValue={sortValue}
          />
        ) : isTicketsLoading ? (
          <ApiLoader />
        ) : userTickets && userTickets.length > 0 ? (
          <div className="p-4">
            <Table
              columns={columns}
              data={tableData}
              onRowClick={(row) => history.push(`/support/${row.id}`)}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center h-[calc(100vh-200px)]">
            <Badge color="gray">{t('No tickets found. Click "Create New Ticket" to get started.')}</Badge>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportCenter;