import {
  ADD_DOCUMENT_APPROVER, ADD_DOCUMENT_ASSIGNEES, ADD_DOCUMENT_DEADLINE, ADD_DOCUMENT_SHARED_ACCESS, ADD_SIGNATURE_FAILURE, ADD_SIGNATURE_REQUEST, ADD_SIGNATURE_SUCCESS,
  ANONYMIZE_DOCUMENT_REQUEST, ANONYMIZE_DOCUMENT_SUCCESS, AppThunk, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE, DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_SUCCESS,
  FETCH_ANONYMIZATION_DATASETS_REQUEST, FETCH_ANONYMIZATION_DATASETS_SUCCESS, FETCH_DOCUMENT_VERSIONS_REQUEST, FETCH_DOCUMENT_VERSIONS_SUCCESS, FETCH_DOCUMENTS_FAILURE, FETCH_DOCUMENTS_REQUEST, FETCH_DOCUMENTS_SUCCESS,
  FETCH_FILE_DETAILS_REQUEST, FETCH_FILE_DETAILS_SUCCESS, FETCH_RECOMMENDED_DOCUMENTS_FAILURE, FETCH_RECOMMENDED_DOCUMENTS_REQUEST, FETCH_RECOMMENDED_DOCUMENTS_SUCCESS,
  FETCH_TRANSLATION_LANGUAGES_REQUEST, FETCH_TRANSLATION_LANGUAGES_SUCCESS, RESET_SELECTED_DOCUMENT, SELECT_DOCUMENT_FOR_EDITING, UPDATE_FOLDERS_WITH_DOCUMENTS
} from '../types';
import { getUserID, getUserType } from './ChatAction';
import apis from '../../utils/apis';
import { Dispatch, AnyAction } from 'redux';
import { showFeedbackModal } from './UserFeedbackActions';
import { FileProps, FolderProps } from '../../components/Documents/types';
import { handleApiError } from './utils/utils';



export const addSignatureRequest = () => ({
  type: ADD_SIGNATURE_REQUEST,
});

export const addSignatureSuccess = (signature: string) => ({
  type: ADD_SIGNATURE_SUCCESS,
  payload: signature,
});

export const addSignatureFailure = (error: string) => ({
  type: ADD_SIGNATURE_FAILURE,
  payload: error,
});


export const deleteDocumentRequest = (): { type: string } => ({
  type: DELETE_DOCUMENT_REQUEST,
});

export const deleteDocumentSuccess = (documentId: string): { type: string; payload: string } => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: documentId,
});

export const deleteDocumentFailure = (error: string): { type: string; payload: string } => ({
  type: DELETE_DOCUMENT_FAILURE,
  payload: error,
});

export const createDocumentRequest = (): { type: string } => ({
  type: CREATE_DOCUMENT_REQUEST,
});

export const createDocumentSuccess = (document: FileProps): { type: string; payload: FileProps } => ({
  type: CREATE_DOCUMENT_SUCCESS,
  payload: document,
});

export const createDocumentFailure = (error: string): { type: string; payload: string } => ({
  type: CREATE_DOCUMENT_FAILURE,
  payload: error,
});


export const selectDocumentForEditing = (document: FileProps) => ({
  type: SELECT_DOCUMENT_FOR_EDITING,
  payload: document,
});

export const resetSelectedDocument = () => ({
  type: RESET_SELECTED_DOCUMENT,
});


export const fetchDocumentsRequest = (): { type: string } => ({
  type: FETCH_DOCUMENTS_REQUEST,
});

export const fetchDocumentsSuccess = (documents: FileProps[]): { type: string; payload: FileProps[] } => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  payload: documents,
});

export const fetchDocumentsFailure = (error: string): { type: string; payload: string } => ({
  type: FETCH_DOCUMENTS_FAILURE,
  payload: error,
});

export const fetchRecommendedDocumentsRequest = (): { type: string } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_REQUEST,
});

export const fetchRecommendedDocumentsSuccess = (recommendedDocuments: any[]): { type: string; payload: FileProps[] } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_SUCCESS,
  payload: recommendedDocuments,
});

export const fetchRecommendedDocumentsFailure = (error: string): { type: string; payload: string } => ({
  type: FETCH_RECOMMENDED_DOCUMENTS_FAILURE,
  payload: error,
});

export const updateFoldersWithDocuments = (folders: FolderProps[]): { type: string; payload: FolderProps[] } => ({
  type: UPDATE_FOLDERS_WITH_DOCUMENTS,
  payload: folders
});

export const fetchRecommendedDocuments = () => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  dispatch(fetchRecommendedDocumentsRequest())
  try {
    const response = await apis({
      method: "GET",
      url: "/files/recommended_files",
    });

    if (response && response.status === 200) {
      dispatch(fetchRecommendedDocumentsSuccess(response.data.RecommendedFiles));
      return Promise.resolve(response.data.RecommendedFiles);
    } else {
      handleApiError(response, dispatch)
      return Promise.reject(new Error('Failed to fetch documents'));
    }
  } catch (error) {
    handleApiError(error, dispatch)
    return Promise.reject(error);
  }
};



export const createNewDocument = (
  FileCategory: string,
  FolderID: string,
  file: File | undefined,
  additionalFields: { [key: string]: string | string[] |  { email: string, permission: string }[] }
): AppThunk => async (dispatch) => {
  dispatch(createDocumentRequest());
  const UserID = getUserID();
  const UserType = getUserType();

  try {
    const formData = new FormData();
    formData.append('UserID', UserID);
    formData.append('UserType', UserType);
    formData.append('FileCategory', FileCategory);
    formData.append('FolderID', FolderID);

    if (file) {
      formData.append('UploadedFile', file);
    }

    Object.keys(additionalFields).forEach((key) => {
      const feildValues = additionalFields[key]
      if (Array.isArray(feildValues)) {
        if (feildValues.length === 0) {
          formData.append(key, JSON.stringify(feildValues));
        } else {
          formData.append(key, JSON.stringify(feildValues));
          // (additionalFields[key] as string[]).forEach((value, index) => {
          // });
        }

      } else {
        formData.append(key, additionalFields[key] as string);
      }
    });

    const response = await apis({
      method: 'POST',
      url: '/files/create_file',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response && response.status === 200) {
      const newDocument = response.data;
      dispatch(createDocumentSuccess(newDocument));
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Document created',
          modalType: 'success',
          duration: 3000,
        })
      );
      return response.data
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error) {
    handleApiError(error, dispatch)
  }
};


export const deleteDocument = (fileId: string, callback?: () => void): AppThunk => async (dispatch) => {

  try {
    const response = await apis({
      method: 'POST',
      url: "/files/delete_file",
      data: { FileID: fileId }
    });

    if (response && response.status === 200) {
      dispatch(deleteDocumentSuccess(fileId));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Document deleted',
        modalType: 'success',
        duration: 3000
      }));
      if (callback) callback();
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error) {
    handleApiError(error, dispatch)
  }
};


export const editDocument = (FileID: string, Title: string, Content: any): AppThunk => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("FileID", FileID);
    formData.append("FileName", Title);
    formData.append("Content", Content);

    let response = await apis({
      method: 'POST',
      url: "/files/edit_file",
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: 'File updated',
        showModal: true,
        duration: 3000
      }))
      return true
    } else {
      handleApiError(response, dispatch)
    }

  } catch (err) {
    handleApiError(err, dispatch)
  }
}


export const fetchDocumentContent = (
  FileID: string,
): AppThunk<Promise<string>> => async (dispatch) => {
  try {
    let response = await apis({
      method: 'POST',
      url: 'files/get_file_html_content',
      headers: { 'Content-Type': 'application/json' },
      data: { FileID },
    });

    if (response.status === 200) {
      return response.data.html_content;
    } else {
      handleApiError(response, dispatch)
    }

  } catch (error) {
    handleApiError(error, dispatch)
  }
}

export const renameFile = (
  fileId: string,
  newFileName: string
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const response = await apis({
      method: 'POST',
      url: '/files/rename_file',
      data: { FileID: fileId, NewFileName: newFileName },
    });

    if (response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'File renamed',
        modalType: 'success',
        duration: 3000,
      }));
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error: any) {
    handleApiError(error, dispatch)
  }
}

export const callAddSignatureAPI = async (formData: FormData): Promise<any> => {
  try {
    const response = await apis({
      method: 'POST',
      url: 'users/upload_signature',
      data: formData
    });
    return response.data;
  } catch (error) {
    console.error('Error calling Add Signature API:', error);
    throw error;
  }
};

export const addSignature = (signature: File) => {
  return async (dispatch: any) => {
    dispatch(addSignatureRequest());
    const formData = new FormData();
    formData.append('SignatureFile', signature);

    try {
      const response = await apis({
        method: 'POST',
        url: 'users/upload_signature',
        data: formData
      });

      if (response && response.status === 200) {
        dispatch(addSignatureSuccess(response.data.signature));
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Signature added',
          modalType: 'success',
          duration: 3000
        }));

      } else {
        handleApiError(response, dispatch)
      }
    } catch (error: any) {
      dispatch(addSignatureFailure(error.message));
      handleApiError(error, dispatch)
    }
  };
}
  ;
export const getSignature = () => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: 'GET',
        url: 'users/get_user_signature',
      });
      if (response && response.status === 200) {
        return response.data.SignatureSASUrl;

      } else {
        handleApiError(response, dispatch)
        return null
      }
    } catch (error: any) {
      handleApiError(error, dispatch)
    }
  };
};



export const updateDocumentOrder = (documentsWithNewOrder: any) => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: "POST",
        url: "/files/file-order",
        data: documentsWithNewOrder
      })
      if (response && response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: response.data.message || 'Files Order Updated Successfully.',
          modalType: 'success',
          duration: 3000
        }));
      } else {
        handleApiError(response, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }
  };
};

export const updateFolderOrder = (updateFolderOrder: any) => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: "POST",
        url: "/files/folder-order",
        data: updateFolderOrder
      })
      if (response && response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: response.data.message || 'Folder Order Updated Successfully.',
          modalType: 'success',
          duration: 3000
        }));
      } else {
        handleApiError(response, dispatch)
      }

    } catch (err) {
      handleApiError(err, dispatch)
    }
  };
};
export const fetchDocumentVersion = (FileID: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_DOCUMENT_VERSIONS_REQUEST });
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/files/get-file-versions",
        data: { FileID }
      });

      if (resp && resp.status === 200) {
        dispatch({
          type: FETCH_DOCUMENT_VERSIONS_SUCCESS,
          payload: resp.data
        });
        return resp.data;
      } else {
        handleApiError(resp, dispatch)
        return null;
      }
    } catch (err: any) {
      handleApiError(err, dispatch)
      return null;
    }
  }
}

export const translateDocument = (fileID: string, desiredLanguage: string | string[]) => {
  return async (dispatch: any) => {
    try {
      const payload = {
        FileID: fileID,
        TargetLanguage: desiredLanguage
      };

      const resp: any = await apis({
        method: "POST",
        url: "/files/translate_file",
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (resp && resp.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: `The document has been translated and added to files. Filename: ${resp.data.filename}, URL: ${resp.data.url}`,
          modalType: 'info',
          duration: 6000
        }));
        // alert(`The document has been translated and added to files. Filename: ${resp.data.filename}, URL: ${resp.data.url}`);
        return resp.data;
      } else {
        handleApiError(resp, dispatch)
      }

    } catch (err) {
      handleApiError(err, dispatch)
    }
  };
};

export const fetchTranslationLanguages = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_TRANSLATION_LANGUAGES_REQUEST });

    try {
      const resp = await apis({
        method: 'GET',
        url: '/dropdown/translation_codes'
      });

      if (resp.status === 200) {
        dispatch({
          type: FETCH_TRANSLATION_LANGUAGES_SUCCESS,
          payload: resp.data,
        });
        return { payload: resp.data };
      } else {
        handleApiError(resp, dispatch)
        return { payload: null };
      }
    } catch (error: any) {
      handleApiError(error, dispatch)
      return { payload: null };
    }
  };
};


export const fetchAnonymizationDatasets = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ANONYMIZATION_DATASETS_REQUEST });
    try {
      const resp = await apis({
        method: "GET",
        url: "/anonymize/get_choices",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (resp && resp.status === 200) {
        dispatch({
          type: FETCH_ANONYMIZATION_DATASETS_SUCCESS,
          payload: resp.data,
        });
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }
  };
};

export const anonymizeDocument = (fileID: string, anonymizationOptions: string[]) => {
  return async (dispatch: any) => {
    dispatch({ type: ANONYMIZE_DOCUMENT_REQUEST });
    try {
      const resp = await apis({
        method: "POST",
        url: "/files/anonymize_file",
        data: JSON.stringify({
          AnonymizationOptions: anonymizationOptions,
          FileID: fileID,
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (resp && resp.status === 200) {
        dispatch({
          type: ANONYMIZE_DOCUMENT_SUCCESS,
          payload: resp.data,
        });

        dispatch(showFeedbackModal({
          showModal: true,
          message: (resp.data.message || 'Document anonymized'),
          modalType: 'success',
          duration: 3000,
        }));
      } else {
        handleApiError(resp, dispatch)
      }

    } catch (err) {
      handleApiError(err, dispatch)
    }
  };
};

export const editDocumentAssignees = (fileId: string, assignees: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    const sanitizedAssignees = assignees.map(email => email.replace(/^['"]|['"]+$/g, '').trim());
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/add_signatories',
        data: { FileID: fileId, Signatories: sanitizedAssignees },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_DOCUMENT_ASSIGNEES, payload: { fileId, assignees } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Assignees updated',
            modalType: 'success',
            duration: 3000,
          }) as any
        );
        return true;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (error) {
      handleApiError(error, dispatch)
    }
  };
};

// Add Deadline to a Document
export const addDocumentDeadline = (fileId: string, deadline: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/set_deadline',
        data: { FileID: fileId, Expiry: deadline },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_DOCUMENT_DEADLINE, payload: { fileId, deadline } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Deadline updated',
            modalType: 'success',
            duration: 3000,
          }) as any
        );
        return true;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (error) {
      handleApiError(error, dispatch)
    }
  };
};

// Add Shared Access to a Document
export const editDocumentSharedAccess = (fileId: string, sharedAccess: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/edit_shared_access',
        data: { FileID: fileId, SharedWith: sharedAccess },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_DOCUMENT_SHARED_ACCESS, payload: { fileId, sharedAccess } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Access updated',
            modalType: 'success',
            duration: 3000,
          }) as any
        );
        return true;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (error) {
      handleApiError(error, dispatch)
    }
  };
};

// Add Shared Access to a Document
export const editDocumentApprover = (fileId: string, approvers: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/edit_approvers',
        data: { FileID: fileId, Approvers: approvers },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_DOCUMENT_APPROVER, payload: { fileId, approvers } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Approvers updated',
            modalType: 'success',
            duration: 3000,
          }) as any
        );
        return true;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (error) {
      handleApiError(error, dispatch)
    }
  };
};

export const uploadAndProcessFile = (
  uploadedFile: File,
  action: 'append' | 'replace',
  targetFileId: string
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('UploadedFile', uploadedFile);
    formData.append('Action', action);
    formData.append('TargetFileID', targetFileId);

    const response = await apis({
      method: 'POST',
      url: '/files/upload_and_process_file',
      data: formData,
    });

    if (response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'File processed successfully',
        modalType: 'success',
        duration: 3000,
      }));
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error: any) {
    handleApiError(error, dispatch)
  }
};

export const fetchFileDetails = (fileId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_FILE_DETAILS_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/files/get-file',
        data: { FileID: fileId },
      });

      if (resp && resp.status === 200) {
        const fileData = resp.data;
        dispatch({ type: FETCH_FILE_DETAILS_SUCCESS, payload: fileData });
        return fileData;
      } else {
        handleApiError(resp, dispatch)
        return null;
      }
    } catch (error: any) {
      handleApiError(error, dispatch)
    }
  };
};


export const downloadFile = (fileId: string): AppThunk => async (dispatch) => {
  try {
    // API endpoint to generate the SAS token
    const response = await apis({
      method: 'POST',
      url: '/files/download_file',
      data: { FileID: fileId }
    });

    if (response && response.status === 200) {
      const downloadUrl = response.data.SAS_URL[1] || response.data.SAS_URL[1]; // Adjust according to API response
      const fileName = response.data.SAS_URL[0] || 'document'; // Optionally get the filename from the response

      // Create a temporary anchor element to initiate the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName; // Set the default filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Optionally, dispatch a success action
      dispatch({ type: 'DOWNLOAD_DOCUMENT_SUCCESS' });
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error: any) {
    handleApiError(error, dispatch)
  }
};


export const updateSharedWithForFile = (FileID: string, updatedSharedWith: any[]): AppThunk => async (dispatch) => {
  try {

    let response = await apis({
      method: 'POST',
      url: "/files/update_file_sharedwith",
      data: {
        FileID,
        updatedSharedWith
      },
    })

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: 'File updated',
        showModal: true,
        duration: 3000
      }))
      return true
    } else {
      handleApiError(response, dispatch)
    }

  } catch (err) {
    handleApiError(err, dispatch)
  }
}