// src/components/settings/tabs/DeleteAccountTab.tsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiTrashLight } from 'react-icons/pi';
import { deleteUser } from '../../../../store/actions/SecurityActions';
import { useAppDispatch } from '../../../../store';
import { showModal } from '../../../../store/actions/modalActions';
import Button from '../../../shared/TailwindComponents/Button';

const DeleteAccountTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAccount = () => {
    setIsDeleting(true);
    dispatch(
      showModal({
        type: 'alert',
        message: t(
          'Are you sure you want to delete your account? This action is irreversible and will permanently delete your data.'
        ),
        onConfirm: async () => {
          await dispatch(deleteUser());
          setIsDeleting(false);
        },
        onCancel: () => setIsDeleting(false),
        showModal: true,
      })
    );
  };

  return (
    <div className="space-y-6 animate-fade-in-up">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
        {t('Delete Account')}
      </h2>
      <div className="bg-danger-100 dark:bg-danger-900 p-6 rounded-lg shadow-light-md dark:shadow-dark-md space-y-4 w-fit">
        <p className="text-sm text-danger-700 dark:text-danger-200">
          {t(
            'No longer want to use our service? Deleting your account is permanent and cannot be undone. All your data will be lost.'
          )}
        </p>
        <Button
          variant="danger"
          size="small"
          onClick={handleDeleteAccount}
          disabled={isDeleting}
        >
          <PiTrashLight className="mr-2" />
          {isDeleting ? t('Deleting...') : t('Yes, delete my account')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteAccountTab;