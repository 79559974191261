import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import Textarea from '../shared/TailwindComponents/Textarea';
import LawyerFilesSection, { LawyerFile } from './LawyerFilesSection';
import ApiLoader from '../ApiLoader';
import { PiCalendarLight, PiUsersLight, PiTagLight } from 'react-icons/pi';

interface CaseInformationProps {
  summary: any;
  loadingSummary: boolean;
  userType: string;
  isEditingCaseInfo: boolean;
  setIsEditingCaseInfo: (val: boolean) => void;
  editedCaseFields: any;
  setEditedCaseFields: (fields: any) => void;
  handleEditCaseInfo: () => void;
  handleCancelEditCaseInfo: () => void;
  handleSaveCaseInfo: () => void;
  handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  dispatch: any;
  showExtractedContentSidePanel: (title: string, content: string) => void;
  showAttachClientToCaseSidePanel: (caseId: string, callback: () => void) => void;
}

const FIELD_LABELS: Record<string, string> = {
  AI_Description: 'Description',
  bulk_files: 'Files',
};

const SKIP_FIELDS = ['ClientUserType'];

// Icon mapping for common metadata labels
const getIconForLabel = (label: string) => {
  switch (label.toLowerCase()) {
    case 'date':
    case 'contract date':
      return <PiCalendarLight className="h-4 w-4 text-gray-500 mr-2" />;
    case 'parties':
    case 'parties involved':
      return <PiUsersLight className="h-4 w-4 text-gray-500 mr-2" />;
    default:
      return <PiTagLight className="h-4 w-4 text-gray-500 mr-2" />;
  }
};

const CaseInformation: React.FC<CaseInformationProps> = ({
  summary,
  loadingSummary,
  userType,
  isEditingCaseInfo,
  editedCaseFields,
  setEditedCaseFields,
  handleCancelEditCaseInfo,
  handleSaveCaseInfo,
  handleFieldChange,
  dispatch,
  showExtractedContentSidePanel,
}) => {
  const { t } = useTranslation();

  if (loadingSummary) {
    return <ApiLoader />;
  }

  if (!summary) {
    return <div className="p-4"><p className="text-gray-500">{t('No summary data available')}</p></div>;
  }

  const descriptionKey = Object.keys(editedCaseFields).find((k) => k === 'AI_Description');
  const filesKey = Object.keys(editedCaseFields).find((k) => k === 'bulk_files');
  const otherFieldEntries = Object.entries(editedCaseFields).filter(([key]) => {
    if (SKIP_FIELDS.includes(key)) return false;
    if (key === 'AI_Description' || key === 'bulk_files') return false;
    return true;
  });

  const renderFieldValue = (key: string, val: any) => {
    if (isEditingCaseInfo && typeof val === 'string') {
      return (
        <Textarea
          id={key}
          name={key}
          value={val}
          onChange={handleFieldChange}
          rows={4}
          label=""
          className="w-full bg-gray-50 dark:bg-gray-700 border-gray-300 dark:border-gray-600"
        />
      );
    }
    if (val && typeof val === 'object' && 'label' in val && 'content' in val) {
      return (
        <Button
          variant="tertiary"
          size="small"
          onClick={() => showExtractedContentSidePanel(val.label, val.content)}
        >
          {val.label}
        </Button>
      );
    }
    if (Array.isArray(val)) {
      return val.map((item, i) => {
        if (item && typeof item === 'object' && 'label' in item && 'content' in item) {
          return (
            <Button
              key={i}
              variant="tertiary"
              size="small"
              className="mb-2 "
              onClick={() => dispatch(showExtractedContentSidePanel(item.label, item.content))}
            >
              {item.label}
            </Button>
          );
        }
        if (item && typeof item === 'object' && 'extracted_text' in item && 'filename' in item) {
          return (
            <Button
              key={i}
              variant="tertiary"
              className="mb-2"
              size="small"
              onClick={() => showExtractedContentSidePanel(item.filename, item.extracted_text)}
            >
              {item.filename}
            </Button>
          );
        }
        return <p key={i} className="text-sm whitespace-pre-wrap">{String(item)}</p>;
      });
    }
    return <p className="text-sm whitespace-pre-wrap">{String(val)}</p>;
  };

  return (
    <div className="mt-4 space-y-6">
      <div className="px-2 transition-all duration-300">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-6">
          {t('Case Information')}
        </h3>

        <div className='flex gap-2 pb-1 mb-2 border-b border-gray-200 dark:border-gray-700'>
        {/* Description Section */}
        {descriptionKey && (
          <div className="mb-8 w-1/2">
            <p className="text-sm font-medium text-gray-500 mb-2">{t('Description')}</p>
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-md">
              {renderFieldValue(descriptionKey, editedCaseFields[descriptionKey])}
            </div>
          </div>
        )}

        {/* AI-Generated Metadata Section */}
        {summary.ai_metadata && summary.ai_metadata.length > 0 && (
          <div className="mb-8 w-1/2">
            <p className="text-sm font-medium text-gray-500 mb-3">{t('Metadata')}</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {summary.ai_metadata.map((item: any, index: number) => (
                <div
                  key={index}
                  className="p-2 bg-gray-200 dark:bg-gray-700 rounded-md shadow-sm hover:shadow-md transition-shadow duration-200"
                >
                  <div className="flex items-center">
                    {getIconForLabel(item.label)}
                    <p className="text-xs font-medium text-gray-600 dark:text-gray-300">{item.label}</p>
                  </div>
                  <p className="text-xs text-gray-900 dark:text-gray-100 mt-1">{item.value}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        </div>

        {/* Files Section */}
        {filesKey && (
          <div className="mb-8">
            <p className="text-sm font-medium text-gray-500 mb-2">{t('Files')}</p>
            <div className="">
              {renderFieldValue(filesKey, editedCaseFields[filesKey])}
            </div>
          </div>
        )}

        {/* Other Fields Section */}
        {otherFieldEntries.length > 0 && (
          <div className="mb-8">
            <p className="text-sm font-medium text-gray-500 mb-2">{t('Additional Details')}</p>
            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-md space-y-4">
              {otherFieldEntries.map(([key, val]) => {
                const label = FIELD_LABELS[key] || key.replace(/_/g, ' ');
                return (
                  <div key={key}>
                    <p className="text-sm font-medium text-gray-600 dark:text-gray-300">{t(label)}</p>
                    <div className="mt-1">{renderFieldValue(key, val)}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Lawyer Files (if applicable) */}
        {userType.includes('Law') && summary.IsMarketplaceCase && (
          <div className="mb-8">
            <LawyerFilesSection
              initialFiles={editedCaseFields.lawyer_files || []}
              onFilesUpdate={(files: LawyerFile[]) =>
                setEditedCaseFields((prev: any) => ({ ...prev, lawyer_files: files }))
              }
            />
          </div>
        )}

        {/* Edit Controls */}
        {isEditingCaseInfo && (
          <div className="flex items-center gap-4 mt-6">
            <Button
              variant="primary"
              onClick={handleSaveCaseInfo}
            >
              {t('Save')}
            </Button>
            <Button
              variant="neutral"
              onClick={handleCancelEditCaseInfo}
            >
              {t('Cancel')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseInformation;