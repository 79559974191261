import React, { useEffect, useState } from 'react';
import { AdditionalDataItem, listChronologyData, removeChronologyDataItem } from '../../../../store/actions/LegalServices/chronologyActions';
import { useAppDispatch } from '../../../../store';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/TailwindComponents/Button';
import { PiTrashLight } from 'react-icons/pi';
import { showModal, hideModal } from '../../../../store/actions/modalActions';
import { showChronologyFileContentSidePanel } from '../../../../store/actions/sidePanelActions';
import { Tooltip } from '@mui/material';
import ApiLoader from '../../../ApiLoader';

interface AdditionalDataListProps {
  caseId: string;
}

const AdditionalDataList: React.FC<AdditionalDataListProps> = ({ caseId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [items, setItems] = useState<AdditionalDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    const data = await dispatch(listChronologyData(caseId));
    if (data) {
      setItems(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  const handleRemove = (itemId: string) => {
    // Show a confirmation modal before removing
    dispatch(showModal({
      showModal: true,
      type: 'confirmation',
      message: t('Are you sure you want to remove this item?'),
      onConfirm: async () => {
        const success = await dispatch(removeChronologyDataItem(caseId, itemId));
        if (success) {
          await loadData();
        }
        dispatch(hideModal());
      },
    }));
  };

  const handleViewFileContent = (content: string, label: string) => {
    // Open the side panel to display full file content
    dispatch(showChronologyFileContentSidePanel(content, label));
  };

  return (
    <div className="space-y-4">
      {isLoading && <ApiLoader />}

      {!isLoading && items.length === 0 && (
        <p className="text-sm text-gray-600">{t('No additional data added yet.')}</p>
      )}

      {items.map((item) => (
        <div key={item.id} className="border-b rounded p-3 relative">
          {/* Remove / trash icon */}
          <Tooltip title={t('Remove content')}>
          <button
            type="button"
            onClick={() => handleRemove(item.id)}
            className="absolute top-2 right-2 text-gray-400 hover:text-red-600"
          >
            <PiTrashLight size={18} />
          </button>
          </Tooltip>

          {item.type === 'file' ? (
            // File item => show the filename as a Tertiary button
            <div className="flex flex-col">
              <Button
                variant="tertiary"
                onClick={() => handleViewFileContent(item.file_content || '', item.filename || '')}
                className="p-0 mt-1 w-fit"
                size="small"
              >
                {item.filename}
              </Button>
            </div>
          ) : (
            <p className="whitespace-pre-wrap">
              {item.text}
            </p>
          )}

          {/* Uploaded date */}
          <p className="mt-2 text-xs text-gray-400">
            {t('Uploaded at')}: {new Date(item.uploadedAt).toLocaleString()}
          </p>
        </div>
      ))}
    </div>
  );
};

export default AdditionalDataList;
