import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store';
import { getUserServiceUsage, getActiveSubscription } from '../../../store/actions/SubscriptionActions';
import Table from '../../shared/TailwindComponents/Table';
import Button from '../../shared/TailwindComponents/Button';
import { PiDatabaseLight, PiTagLight, PiGaugeLight, PiCurrencyDollarLight, PiCalendarLight, PiCheckCircleLight } from 'react-icons/pi';
import ClipLoader from 'react-spinners/ClipLoader';
import TopUpWidget from './TopUpWidget'; // Import the new widget
import { isBetaEnv } from '../../../utils/authUtils';

const Queries: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [serviceUsage, setServiceUsage] = useState<any[]>([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch country (similar to LicensesPage)
        const countryResponse = await fetch('https://ipapi.co/json/');
        const countryData = await countryResponse.json();
        setCountry(countryData.country_code); // Use country code (e.g., 'US')

        const usage = await dispatch(getUserServiceUsage());
        const subscription = await dispatch(getActiveSubscription());
        if (usage) setServiceUsage(usage);
        if (subscription) setSubscriptionDetails(subscription);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader color="#fff" size={50} />
      </div>
    );
  }

  const columns = ['Service Name', 'Base Query Cost', 'Usage Count', 'Total Cost'];

  const data = serviceUsage.map((service) => ({
    'Service Name': service.ServiceName,
    'Base Query Cost': `${service.BaseQueryCost.toFixed(0)}`,
    'Usage Count': service.UsageCount,
    'Total Cost': `${service.TotalCost.toFixed(0)}`,
  }));

  return (
    <div className="flex flex-col md:flex-row sm:flex-col space-y-6 md:space-y-0 md:space-x-6 p-6">
      {/* Left Side: Service Usage Table */}
      <div className="w-full md:w-2/3">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
          {t('Service Pricing and Usage')}
        </h2>
        <Table columns={columns} data={data} />
      </div>

      {/* Right Side: Subscription Details */}
      <div className="w-full md:w-1/3">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
          {t('Subscription Details')}
        </h2>
        <div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-light-md dark:shadow-dark-md">
            <div className="space-y-4">
                <div className='flex justify-between items-center'>
              <div className="flex items-center space-x-2">
                <PiCheckCircleLight className="text-primary-500" size={20} />
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  {subscriptionDetails.license_name}
                </span>
              </div>
              <Button
                variant="primary"
                size="small"
                onClick={() => console.log('Upgrade Subscription clicked')}
                disabled={isBetaEnv}
              >
                {t('Upgrade')}
              </Button>
              </div>
              <div className="flex items-center space-x-2">
                <PiDatabaseLight className="text-primary-500" size={20} />
                <div className='flex justify-between w-full'>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {t('Total Queries')}
                </span>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                {subscriptionDetails.total_queries}
                </span>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiCalendarLight className="text-primary-500" size={20} />
              <div className='flex justify-between w-full'>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {t('Start Date')}
                </span>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                    {subscriptionDetails.start_date}
                    </span>
              </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiCalendarLight className="text-primary-500" size={20} />
                <div className='flex justify-between w-full'>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {t('End Date')}
                </span>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                {subscriptionDetails.end_date}
                </span>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiCurrencyDollarLight className="text-primary-500" size={20} />
                <div className='flex justify-between w-full'>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {t('Currency')}
                </span>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {subscriptionDetails.currency}
                </span>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiCurrencyDollarLight className="text-primary-500" size={20} />
              <div className='flex justify-between w-full'>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {t('Price')}
                </span>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {subscriptionDetails.price.toFixed(2)}
                </span>
              </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiCheckCircleLight className="text-primary-500" size={20} />
                <div className='flex justify-between w-full'>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {t('Status')}
                </span>
                <span className="text-sm text-gray-700 dark:text-gray-200">
                  {subscriptionDetails.status ? 'Active' : 'Inactive'}
                </span>
                </div>
              </div>
            </div>
          </div>
      <TopUpWidget country  ={country} currentQueries={subscriptionDetails?.total_queries} />
        </div>
      </div>

    </div>
  );
};

export default Queries;