import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ExtractedContentSidePanelProps {
  filename: string;
  content: string;
  onClose: () => void;
}

const ExtractedContentSidePanel: React.FC<ExtractedContentSidePanelProps> = ({
  filename,
  content,
  onClose,
}) => {

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex justify-between items-center mb-4 p-4 border-b border-gray-200 dark:border-gray-600">
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
          {filename}
        </h2>
      </div>

      {/* Content Wrapper */}
      <div className="p-4 flex-1 overflow-y-auto custom-scrollbar">
        {/* Actual Content Container */}
        <div
          className="
            prose dark:prose-invert
            bg-white dark:bg-gray-800
            p-6 rounded-md
            shadow-md dark:shadow-dark-md
            max-w-none
            text-sm leading-relaxed
          "
          style={{ fontFamily: 'Garamond, serif' }}
        >
          {/* React Markdown with GFM support */}
          <ReactMarkdown
            className="prose dark:prose-invert max-w-none text-xs"
            remarkPlugins={[remarkGfm]}
          >
            {content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default ExtractedContentSidePanel;
