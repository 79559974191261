// src/store/actions/LegalServices/chronologyActions.ts

import { Dispatch } from 'redux';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';
import { handleApiError } from '../utils/utils';
import { fetchCaseSummary } from '../marketplace2/caseActions';
import { AppThunk } from '../../types';

export interface ChronologyEvent {
  date: string;
  description: string;
  files?: File[];
}

export interface ChronologyData {
  Chronology: any[];  
  GeneratedChronology: string;  
  ChronologyDocUrl?: string;
}

export interface AdditionalDataItem {
  id: string;
  type: 'file' | 'text';
  filename?: string;
  file_content?: string;
  text?: string;
  uploadedAt: string;
}

// This is your existing "getChronology" 
export const getChronology = (caseId: string): AppThunk<Promise<ChronologyData | null>> => {
  return async dispatch => {
    try {
      const response = await apis.post('/cases/get_chronology', { CaseID: caseId });
      if (response.status === 200) {
        const chronoData: ChronologyData = {
          Chronology: response.data.Chronology || [],
          GeneratedChronology: response.data.GeneratedChronology || '',
        };
        // Possibly store in Redux if you want:
        dispatch({
          type: 'UPDATE_CASE_CHRONOLOGY',
          payload: { caseId, chronology: chronoData.Chronology },
        });
        dispatch({
          type: 'UPDATE_CASE_GENERATED_CHRONOLOGY',
          payload: { caseId, generatedChronology: chronoData.GeneratedChronology },
        });
        return chronoData;
      } else {
        handleApiError(response, dispatch);
        return null;
      }
    } catch (err) {
      handleApiError(err, dispatch);
      return null;
    }
  };
};


// ------------------------------------------------------------------
// NEW: Additional Data Endpoints
// ------------------------------------------------------------------

// Add data (text or multiple files)
export const addChronologyData = (
  caseId: string,
  textData?: string,
  files?: File[]
): AppThunk<Promise<boolean>> => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append('CaseID', caseId);
      if (textData) formData.append('TextData', textData);
      if (files) {
        files.forEach((file) => {
          formData.append('files', file);
        });
      }

      const response = await apis.post('/ai/chronology_add_data', formData);
      if (response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Data added successfully',
          modalType: 'success',
          duration: 3000,
        }));
        return true;
      } else {
        handleApiError(response, dispatch);
        return false;
      }
    } catch (error) {
      handleApiError(error, dispatch);
      return false;
    }
  };
};

export const listChronologyData = (
  caseId: string
): AppThunk<Promise<AdditionalDataItem[] | null>> => {
  return async dispatch => {
    try {
      const response = await apis.post('/ai/chronology_list_data', { CaseID: caseId });
      if (response.status === 200 && response.data?.AdditionalData) {
        return response.data.AdditionalData as AdditionalDataItem[];
      } else {
        handleApiError(response, dispatch);
        return null;
      }
    } catch (error) {
      handleApiError(error, dispatch);
      return null;
    }
  };
};

export const removeChronologyDataItem = (
  caseId: string,
  itemId: string
): AppThunk<Promise<boolean>> => {
  return async dispatch => {
    try {
      const response = await apis.post('/ai/chronology_remove_data', {
        CaseID: caseId,
        ItemID: itemId,
      });
      if (response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Data item removed',
          modalType: 'success',
          duration: 3000,
        }));
        return true;
      } else {
        handleApiError(response, dispatch);
        return false;
      }
    } catch (error) {
      handleApiError(error, dispatch);
      return false;
    }
  };
};

// ------------------------------------------------------------------
// Existing Chronology Generation
// ------------------------------------------------------------------
export const generateChronology = (caseId: string): AppThunk<Promise<any>> => {
  return async dispatch => {
    try {
      const response = await apis.post('/ai/chronology_generate', { CaseID: caseId });
      if (response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Chronology generated successfully',
          modalType: 'success',
          duration: 3000,
        }));
        return response.data; // Return the response data including doc_url
      } else {
        handleApiError(response, dispatch);
        return null;
      }
    } catch (error) {
      handleApiError(error, dispatch);
      return null;
    }
  };
};

// ------------------------------------------------------------------
// Versions, if you want a dropdown of older GPT results
// ------------------------------------------------------------------
interface ChronologyVersion {
  versionId: string;
  generatedText: string;
  dataHash?: string;
  dateCreated?: string;
  editedText?: string;
  dateEdited?: string;
}

export const fetchChronologyVersions = (caseId: string): AppThunk<Promise<ChronologyVersion[] | null>> => {
  return async dispatch => {
    try {
      const response = await apis.post('/ai/chronology_versions', { CaseID: caseId });
      if (response.status === 200 && response.data?.ChronologyVersions) {
        return response.data.ChronologyVersions as ChronologyVersion[];
      } else {
        handleApiError(response, dispatch);
        return null;
      }
    } catch (error) {
      handleApiError(error, dispatch);
      return null;
    }
  };
};

export const restoreChronologyVersion = (caseId: string, versionId: string): AppThunk<Promise<boolean>> => {
  return async dispatch => {
    try {
      const response = await apis.post('/ai/chronology_restore_version', {
        CaseID: caseId,
        VersionID: versionId,
      });
      if (response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Version restored successfully',
          modalType: 'success',
          duration: 3000,
        }));
        return true;
      } else {
        handleApiError(response, dispatch);
        return false;
      }
    } catch (error) {
      handleApiError(error, dispatch);
      return false;
    }
  };
};