import React from 'react';
import { useMicrosoftGraph } from '../contexts/MicrosoftGraphContext';
import clsx from 'clsx';
import { CgMicrosoft } from "react-icons/cg";

const baseBtnClasses = "flex items-center justify-center w-32 px-3 py-1.5 text-xs font-medium rounded-md transition-colors";
const oneDriveBtnClasses = "bg-blue-100 hover:bg-blue-200 text-blue-800";

interface OneDrivePickerButtonProps {
  onFileSelected: (file: any) => void;
}

const OneDrivePickerButton: React.FC<OneDrivePickerButtonProps> = ({ onFileSelected }) => {
  const { handleOpenMicrosoftPicker } = useMicrosoftGraph();

  return (
    <button
      type="button"
      onClick={() => handleOpenMicrosoftPicker(onFileSelected)}
      className={clsx(baseBtnClasses, oneDriveBtnClasses)}
    >
      {'OneDrive'} {/* Shortened from "Microsoft OneDrive" */}
      <CgMicrosoft className="ml-2" size={16} />
    </button>
  );
};

export default OneDrivePickerButton;