import React, { useState, FormEvent, useEffect } from "react";
import { useStripe, useElements, IbanElement, CardElement } from "@stripe/react-stripe-js";
import { FiCreditCard, FiCheckCircle, FiLoader } from "react-icons/fi";
import { FaBuilding, FaGlobe } from "react-icons/fa";
import { AiOutlineBank } from "react-icons/ai";
import { useAppDispatch } from "../../../store";
import { createConnectedAccount } from "../../../store/actions/StripeActions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { currencyList, getPreferences } from "../../../store/actions/DataAction";

const BankForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<"card" | "iban" | "account">("card");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector((state: any) => state.user.userInfo);
  const currencies = useSelector((state: any) => state.lists.currencies);
  const preferences = useSelector((state: any) => state.lists.preferences);

  const [selectedCurrency, setSelectedCurrency] = useState({ code: "USD", name: "" });
  const [accountDetails, setAccountDetails] = useState({
    routingNumber: "",
    accountNumber: "",
    accountHolderName: userInfo.FirstName + " " + userInfo.LastName,
  });

  useEffect(() => {
    dispatch(getPreferences());
    // dispatch(currencyList());
  }, [dispatch]);

  useEffect(() => {
    if (preferences) {
      setSelectedCurrency({ code: preferences.Currency, name: preferences.CurrencyName });
    }
  }, [preferences]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe) return setLoading(false);

    let token;
    let error;

    if (paymentMethod === "card") {
      const cardNumberElement = elements?.getElement(CardElement);
      ({ token, error } = await stripe.createToken(cardNumberElement!));
    } else if (paymentMethod === "iban") {
      const ibanElement = elements?.getElement(IbanElement);
      ({ token, error } = await stripe.createToken(ibanElement!, {
        currency: 'eur',
        account_holder_name: accountDetails.accountHolderName,
        account_holder_type: "individual",
      }));
    } else if (paymentMethod === "account") {
      ({ token, error } = await stripe.createToken("bank_account", {
        country: "NO",
        currency: selectedCurrency.code,
        routing_number: accountDetails.routingNumber,
        account_number: accountDetails.accountNumber,
        account_holder_name: accountDetails.accountHolderName,
        account_holder_type: "individual",
      }));
    }

    if (error) {
      setLoading(false);
      return;
    }

    const response = await dispatch(createConnectedAccount(token?.id));

    if (response) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-full max-w-5xl bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 border border-gray-300 dark:border-gray-700">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center gap-2 mb-4">
          <AiOutlineBank className="text-blue-500" /> {t("Add Payment Method")}
        </h2>

        {/* Payment Method Selection */}
        <div className="flex gap-3 mb-6">
          {[
            { type: "card", icon: <FiCreditCard size={20} />, label: "Card" },
            { type: "iban", icon: <FaGlobe size={20} />, label: "IBAN" },
            { type: "account", icon: <FaBuilding size={20} />, label: "Bank Account" },
          ].map(({ type, icon, label }) => (
            <button
              key={type}
              onClick={() => setPaymentMethod(type as "card" | "iban" | "account")}
              className={`flex-1 flex items-center gap-2 justify-center px-4 py-2 rounded-lg border transition ${
                paymentMethod === type
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-300"
              }`}
            >
              {icon} {t(label)}
            </button>
          ))}
        </div>

        {/* Payment Input Fields */}
        <form onSubmit={handleSubmit}>
          {paymentMethod === "card" && (
            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-200">{t("Card Number")}</label>
              <div className="border rounded-lg p-3 bg-gray-100 dark:bg-gray-700">
                <CardElement />
              </div>
            </div>
          )}

          {paymentMethod === "iban" && (
            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-200">{t("IBAN")}</label>
              <div className="border rounded-lg p-3 bg-gray-100 dark:bg-gray-700">
                <IbanElement options={{ supportedCountries: ["SEPA"] }} />
              </div>
            </div>
          )}

          {paymentMethod === "account" && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-200">{t("Routing Number")}</label>
                <input
                  type="text"
                  value={accountDetails.routingNumber}
                  onChange={(e) => setAccountDetails({ ...accountDetails, routingNumber: e.target.value })}
                  className="w-full p-2 border rounded-lg bg-gray-100 dark:bg-gray-700 dark:text-white"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-200">{t("Account Number")}</label>
                <input
                  type="text"
                  value={accountDetails.accountNumber}
                  onChange={(e) => setAccountDetails({ ...accountDetails, accountNumber: e.target.value })}
                  className="w-full p-2 border rounded-lg bg-gray-100 dark:bg-gray-700 dark:text-white"
                />
              </div>
            </>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className={`w-full flex items-center justify-center gap-2 text-white py-2 px-4 rounded-lg ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-600"
            }`}
            disabled={loading}
          >
            {loading ? <FiLoader className="animate-spin" /> : <FiCheckCircle />}
            {loading ? t("Processing...") : t("Submit")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BankForm;
