import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

interface MemoDisplayProps {
  memo: string;
}

const parseMemo = (memoString: string) => {
  const jsonMatch = memoString.match(/```json\n([\s\S]*?)\n```/);
  if (!jsonMatch) return null;
  const jsonStr = jsonMatch[1];
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    console.error('Failed to parse memo JSON', e);
    return null;
  }
};

const MemoDisplay: React.FC<MemoDisplayProps> = ({ memo }) => {
  const { t } = useTranslation();
  const parsedMemo = parseMemo(memo);

  if (!parsedMemo) {
    return <div className="text-red-600">{t('Error parsing memo')}</div>;
  }

  const { memo: memoData, metadata } = parsedMemo;
  const files = metadata.files || [];

  const processText = (text: string) => {
    return text.replace(/\[Doc (\d+)\]/g, (match, num) => {
      const index = parseInt(num, 10);
      if (index > 0 && index <= files.length) {
        return `<sup><a href="#citation-${index}" class="text-blue-600 hover:underline">${index}</a></sup>`;
      }
      return match;
    });
  };

  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      {/* Header */}
      <div className="text-left mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
          {t('Legal Memorandum')}
        </h1>
      </div>

      {/* Jurisdiction and Timestamp Tags */}
      <div className="flex justify-end gap-4 mb-6">
        <Tooltip title={t('Jurisdiction')}>
        <span className="inline-flex items-center px-3 py-1 text-sm text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-full">
           {metadata.jurisdiction}
        </span>
        </Tooltip>
        <Tooltip title={t('Date')}>
        <span className="inline-flex items-center px-3 py-1 text-sm text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-full">
        {new Date(metadata.timestamp).toLocaleDateString()}
        </span>
          </Tooltip>
      </div>

      {/* Memo Sections */}
      {Object.entries(memoData).map(([key, value]) => {
        if (typeof value !== 'string' || value.trim() === '') return null;
        const sectionTitle = key
          .replace(/_/g, ' ')
          .replace(/\b\w/g, (char) => char.toUpperCase());
        return (
          <div key={key} className="mb-8">
            <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-3">
              {t(sectionTitle)}
            </h2>
            <div className="prose prose-sm dark:prose-invert text-gray-700 dark:text-gray-300">
              <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} className={'markdown-content'}>
                {processText(value)}
              </ReactMarkdown>
            </div>
          </div>
        );
      })}

      {/* Citations */}
      {files.length > 0 && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-3">
            {t('Citations')}
          </h2>
          <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
            {files.map((file: any, index: number) => (
              <li key={index} id={`citation-${index + 1}`} className="mb-2">
                {file}
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

export default MemoDisplay;