import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../shared/TailwindComponents/Button';
import { PiPlusLight, PiMinusLight } from 'react-icons/pi';
import Badge from '../TailwindComponents/Badge';
import { isBetaEnv } from '../../../utils/authUtils';

interface TopUpWidgetProps {
  country: string | null; // Country code for checkout
  currentQueries?: number | string; // Optional: Current query balance
}

const TopUpWidget: React.FC<TopUpWidgetProps> = ({ country, currentQueries }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { t } = useTranslation();
  const history = useHistory();

  const QUERIES_PER_UNIT = 1000;
  const PRICE_PER_UNIT = 50;
  const TOP_UP_LICENSE_ID = 'GFKerkmgtUt69zhipy0KBg';

  const handlePlus = () => setQuantity((prev) => prev + 1);
  const handleMinus = () => setQuantity((prev) => (prev > 1 ? prev - 1 : 1));

  const handleCheckout = () => {
    if (!country) {
      console.error('Country is required for checkout');
      return;
    }
    history.push(`/plans/checkout?licenseId=${TOP_UP_LICENSE_ID}&country=${country}&quantity=${quantity}`);
    setIsExpanded(false); 
  };

  return (
    <div className="relative mt-4">
      {/* {!isExpanded ? (
        <button
          onClick={() => setIsExpanded(true)}
          className="bg-primary-500 text-white p-3 rounded-full shadow-lg hover:bg-primary-600 transition flex items-center justify-center"
          aria-label={t('Top Up Queries')}
        >
          <PiPlusLight size={24} />
        </button>
      ) : ( */}
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg animate-fade-in space-y-4">
            <div className='flex items-center justify-between mb-3'>
            {isBetaEnv && (
                  <div className="absolute inset-0 bg-gray-200 bg-opacity-75 flex items-center justify-center z-10">
                    <Badge color='gray'>{t('Coming soon')}</Badge>
                  </div>
                )}
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 ">
            {t('Top Up Queries')}
          </h3>
          <Button
              variant="primary"
              size="small"
              onClick={handleCheckout}
              disabled={!country}
            >
              {t('Proceed to Checkout')}
            </Button>
            </div>
          {currentQueries !== undefined && (
            <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
              {t('Current Queries')}: {currentQueries}
            </p>
          )}
          <div className="flex items-center justify-between mb-3">
            <button
              onClick={handleMinus}
              className={`p-2 rounded-full ${
                quantity <= 1
                  ? 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
                  : 'bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600'
              }`}
              disabled={quantity <= 1}
              aria-label={t('Decrease Quantity')}
            >
              <PiMinusLight size={16} />
            </button>
            <span className="text-lg font-medium text-gray-900 dark:text-gray-100">
              {quantity}
            </span>
            <button
              onClick={handlePlus}
              className="bg-gray-200 dark:bg-gray-700 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600"
              aria-label={t('Increase Quantity')}
            >
              <PiPlusLight size={16} />
            </button>
          </div>
          <div className='flex justify-between items-center'>
          <p className="text-sm text-gray-700 dark:text-gray-200 mb-2">
            {t('Queries to Add')}
          </p>
          <Badge color='indigo'>{quantity * QUERIES_PER_UNIT}</Badge>
          </div>
          <div className='flex justify-between items-center'>
          <p className="text-sm text-gray-700 dark:text-gray-200 mb-4">
            {t('Total Cost')}
          </p>
          <Badge color='green'>${quantity * PRICE_PER_UNIT}</Badge>
          </div>
          <div className="flex justify-end gap-2">
            {/* <Button
              variant="neutral"
              size="small"
              onClick={() => setIsExpanded(false)}
            >
              {t('Close')}
            </Button> */}
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default TopUpWidget;