import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';

interface ChronologyFileContentSidePanelProps {
  content: string;
  label: string;
  onClose: () => void;
}

const ChronologyFileContentSidePanel: React.FC<ChronologyFileContentSidePanelProps> = ({
  content,
  label,
  // onClose,
}) => {
    const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex justify-between items-center mb-4 p-4 border-b border-gray-200 dark:border-gray-600">
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
          {t(label)}
        </h2>
      </div>
      <div className="p-4 flex-1 overflow-y-auto custom-scrollbar">

      <div 
      className="
      prose dark:prose-invert
      bg-white dark:bg-gray-800
      p-6 rounded-md
      shadow-md dark:shadow-dark-md
      max-w-none
      text-sm leading-relaxed
    "
    >
      <ReactMarkdown className='markdown-content' remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      </div>
      </div>
      {/* <Button variant="secondary" onClick={onClose} className="mt-4">
        {t('Close')}
      </Button> */}
    </div>
  );
};

export default ChronologyFileContentSidePanel;
