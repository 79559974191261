import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store';
import {
  fetchCaseWorkflows,
  fetchWorkflows,
  suggestWorkflow,
  updateCaseWorkflows,
} from '../../../../store/actions/LegalServices/workflowActions';
import { showFeedbackModal } from '../../../../store/actions/UserFeedbackActions';
import Button from '../../../shared/TailwindComponents/Button';
import Input from '../../../shared/TailwindComponents/Input';
import Textarea from '../../../shared/TailwindComponents/Textarea';
import {
  PiPlusLight,
  PiMinusLight,
  PiLightbulbLight,
  PiClockLight,
  PiFileTextLight,
  PiNoteLight,
  PiScalesLight,
  PiGavelLight,
} from 'react-icons/pi';

// Skeleton Loader Component
const SkeletonLoader: React.FC = () => (
  <div className="space-y-3">
    {[...Array(3)].map((_, i) => (
      <div key={i} className="h-10 bg-gray-200 dark:bg-gray-700 rounded-lg animate-fadePulse"></div>
    ))}
  </div>
);

// Workflow Icon Mapping
const getWorkflowIcon = (name: string) => {
  switch (name) {
    case 'Chronology':
      return <PiClockLight className="text-blue-500" />;
    case 'Case Analysis':
      return <PiFileTextLight className="text-green-500" />;
    case 'Memo':
      return <PiNoteLight className="text-yellow-500" />;
    case 'Legal Outline':
      return <PiScalesLight className="text-purple-500" />;
    case 'Plea Draft':
      return <PiGavelLight className="text-red-500" />;
    default:
      return <PiLightbulbLight className="text-gray-500" />;
  }
};

const WorkflowModal: React.FC<{
  caseId: string;
  onClose: () => void;
  onSave: () => void;
}> = ({ caseId, onClose, onSave }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // State Management
  const [allWorkflows, setAllWorkflows] = useState<any[]>([]);
  const [selectedWorkflowIds, setSelectedWorkflowIds] = useState<number[]>([]);
  const [initialSelectedWorkflowIds, setInitialSelectedWorkflowIds] = useState<number[]>([]);
  const [suggestion, setSuggestion] = useState({ name: '', description: '' });
  const [showSuggestionForm, setShowSuggestionForm] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch Data on Mount
  useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchWorkflows()).then((data) => setAllWorkflows(data)),
      dispatch(fetchCaseWorkflows(caseId)).then((data) => {
        const activeWorkflowIds = data.filter((w: any) => w.IsActive).map((w: any) => w.WorkflowID);
        setSelectedWorkflowIds(activeWorkflowIds);
        setInitialSelectedWorkflowIds(activeWorkflowIds);
      }),
    ]).finally(() => setLoading(false));
  }, [caseId, dispatch]);

  // Memoized Workflow Lists
  const availableWorkflows = useMemo(
    () => allWorkflows.filter((w) => !selectedWorkflowIds.includes(w.WorkflowID)),
    [allWorkflows, selectedWorkflowIds]
  );
  const selectedWorkflows = useMemo(
    () => allWorkflows.filter((w) => selectedWorkflowIds.includes(w.WorkflowID)),
    [allWorkflows, selectedWorkflowIds]
  );

  // Workflow Action Handlers
  const handleAddWorkflow = (workflowId: number) => {
    setSelectedWorkflowIds((prev) => [...prev, workflowId]);
  };

  const handleRemoveWorkflow = (workflowId: number) => {
    setSelectedWorkflowIds((prev) => prev.filter((id) => id !== workflowId));
  };

  // Save Handler
  const handleSave = () => {
    dispatch(updateCaseWorkflows(caseId, selectedWorkflowIds))
      .then(() => {
        if (onSave) onSave(); 
        onClose();
      })
      .catch(() => {});
  };

  // Suggestion Handler
  const handleSuggest = () => {
    if (!suggestion.name) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Please enter a name for your suggestion.'),
          modalType: 'error',
          duration: 3000,
        })
      );
      return;
    }
    dispatch(suggestWorkflow(suggestion))
      .then(() => {
        setSuggestion({ name: '', description: '' });
        setShowSuggestionForm(false); // Hide form after successful submission
      })
      .catch(() => {});
  };

  // Check for Changes
  const hasChanges = useMemo(
    () =>
      JSON.stringify(selectedWorkflowIds.sort()) !==
      JSON.stringify(initialSelectedWorkflowIds.sort()),
    [selectedWorkflowIds, initialSelectedWorkflowIds]
  );

  return (
    <div className="md:w-[800px] sm:w-full">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
        {t('Customize Workflows')}
      </h2>

      {/* Workflow Lists */}
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <SkeletonLoader />
          <SkeletonLoader />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Available Workflows */}
          <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg shadow-inner">
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-3">
              {t('Available Workflows')}
            </h3>
            <ul className="space-y-3 max-h-72 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600">
              {availableWorkflows.map((w) => (
                <li
                  key={w.WorkflowID}
                  className="flex items-center justify-between p-3 bg-white dark:bg-gray-700 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 ease-in-out transform hover:-translate-y-0.5"
                >
                  <div className="flex items-center gap-3">
                    {getWorkflowIcon(w.Name)}
                    <span className="text-sm font-medium text-gray-800 dark:text-gray-100">
                      {w.Name}
                    </span>
                    {w.Status !== 'active' && (
                      <span className="ml-2 px-2 py-1 text-xs font-medium text-blue-800 bg-blue-100 rounded-full">
                        {t('Coming soon')}
                      </span>
                    )}
                  </div>
                  <Button
                    onClick={() => handleAddWorkflow(w.WorkflowID)}
                    disabled={w.Status !== 'active'}
                    variant="neutral"
                    size="small"
                    icon={<PiPlusLight/>}
                    children=""
                    title={w.Status !== 'active' ? t('Coming soon') : ''} // Optional tooltip
                  />
                </li>
              ))}
            </ul>
          </div>

          {/* Selected Workflows */}
          <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg shadow-inner">
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-3">
              {t('Selected Workflows')}
            </h3>
            <ul className="space-y-3 max-h-72 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600">
              {selectedWorkflows.map((w) => (
                <li
                  key={w.WorkflowID}
                  className="flex items-center justify-between p-3 bg-green-50 dark:bg-green-800 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 ease-in-out transform hover:-translate-y-0.5"
                >
                  <div className="flex items-center gap-3">
                    {getWorkflowIcon(w.Name)}
                    <span className="text-sm font-medium text-gray-800 dark:text-gray-100">
                      {w.Name}
                    </span>
                  </div>
                  <Button
                    onClick={() => handleRemoveWorkflow(w.WorkflowID)}
                    variant="neutral"
                    size="small"
                    icon={<PiMinusLight />}
                    children=""
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Suggestion Section */}
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {t('Suggest a Workflow')}
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {t('Suggest a workflow and earn query incentives if it’s implemented!')}
            </p>
          </div>
          <Button
            onClick={() => setShowSuggestionForm(!showSuggestionForm)}
            variant="tertiary"
            size="small"
            className="text-blue-500 border-blue-500 hover:bg-blue-50 dark:hover:bg-blue-900 transition-colors"
          >
            {showSuggestionForm ? t('Hide Form') : t('Suggest Workflow')}
          </Button>
        </div>
        {showSuggestionForm && (
          <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-inner">
            <Input
              type="text"
              placeholder={t('Workflow Name')}
              value={suggestion.name}
              onChange={(e) => setSuggestion({ ...suggestion, name: e.target.value })}
              className="mb-3 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            <Textarea
              placeholder={t('Description (optional)')}
              value={suggestion.description}
              onChange={(e) => setSuggestion({ ...suggestion, description: e.target.value })}
              rows={2}
              id=""
              label=""
            />
            <div className="mt-2 flex justify-end">
            <Button
              onClick={handleSuggest}
              variant="primary"
              size="small"
              icon={<PiLightbulbLight size={14} className='ml-1' />}
            >
              {t('Submit')}
            </Button>
            </div>
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end gap-3">
        <Button
          onClick={handleSave}
          variant="primary"
          size="small"
          disabled={!hasChanges}
          className={`${
            hasChanges ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'
          } text-white transition-colors`}
        >
          {t('Save')}
        </Button>
        {/* <Button
          onClick={onClose}
          variant="neutral"
          size="small"
          className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
        >
          {t('Close')}
        </Button> */}
      </div>
    </div>
  );
};

export default WorkflowModal;