import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserType } from '../../../store/actions/ChatAction';
import { fetchAllEmployees } from '../../../store/actions/organization/employeeActions';
import Header from '../../shared/TailwindComponents/Header';
import Table from '../../shared/TailwindComponents/Table';
import Pagination from '../../shared/TailwindComponents/Pagination';
import { fetchUserTypes } from '../../../store/actions/DataAction';
import { useHistory } from 'react-router-dom';
import { usePermission } from '../../../hooks/userPermissions';
import { useTranslation } from 'react-i18next';
import FilterSearchBar from '../../shared/TailwindComponents/FilterSearchBar';
import ApiLoader from '../../ApiLoader';
import Onboarding from './Onboarding';
import { PiUserCircleLight } from 'react-icons/pi';

interface EmployeeRow {
  id: string;
  Name: React.ReactNode;
  Email: string;
  Department: string;
  Status: string;
  JoinDate: string;
}

interface EmployeeListProps {
  isAddingEmployee: boolean;
  setIsAddingEmployee: (value: boolean) => void;
}

const EmployeeList: React.FC<EmployeeListProps> = ({ isAddingEmployee, setIsAddingEmployee }) => {
  const dispatch = useAppDispatch();
  const { employees, loading, totalPages } = useSelector((state: RootState) => state.employees);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('name_asc');
  const [page, setPage] = useState(1);
  const [departmentOptions, setDepartmentOptions] = useState<string[]>([]);
  const userType = getUserType();
  const history = useHistory();
  const { isReadOnly } = usePermission('admin');
  const { t } = useTranslation();
  const employeesPerPage = 5;

  // Dynamically generate department options from employee data
  useEffect(() => {
    if (employees.length > 0) {
      const uniqueDepartments = Array.from(
        new Set(employees.map(emp => emp.Department).filter(dept => dept))
      );
      setDepartmentOptions(uniqueDepartments);
    }
  }, [employees]);

  const filters = [
    {
      id: 'Department',
      name: 'Department',
      options: departmentOptions.map(dept => ({ value: dept, label: dept })),
    },
  ];

  const sortOptions = [
    { value: 'name_asc', label: 'Name Ascending', current: true },
    { value: 'name_desc', label: 'Name Descending', current: false },
    { value: 'join_date_asc', label: 'Join Date Ascending', current: false },
    { value: 'join_date_desc', label: 'Join Date Descending', current: false },
    { value: 'case_count_asc', label: 'Case Count Ascending', current: false },
    { value: 'case_count_desc', label: 'Case Count Descending', current: false },
  ];

  const getQueryParams = useCallback(() => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (sortValue === 'name_asc') {
      sortBy = 'name';
      sortOrder = 'asc';
    } else if (sortValue === 'name_desc') {
      sortBy = 'name';
      sortOrder = 'desc';
    } else if (sortValue === 'join_date_asc') {
      sortBy = 'join_date';
      sortOrder = 'asc';
    } else if (sortValue === 'join_date_desc') {
      sortBy = 'join_date';
      sortOrder = 'desc';
    } else if (sortValue === 'case_count_asc') {
      sortBy = 'case_count';
      sortOrder = 'asc';
    } else if (sortValue === 'case_count_desc') {
      sortBy = 'case_count';
      sortOrder = 'desc';
    }
    const filtersObj: { [key: string]: string[] } = {};
    if (selectedFilters.Department && selectedFilters.Department.length > 0) {
      filtersObj.Department = selectedFilters.Department;
    }
    return { searchTerm, filters: filtersObj, sortBy, sortOrder, page, limit: employeesPerPage };
  }, [sortValue, selectedFilters, searchTerm, page, employeesPerPage]);

  const refetchEmployees = useCallback(async () => {
    const { searchTerm, filters, sortBy, sortOrder, page, limit } = getQueryParams();
    await dispatch(fetchAllEmployees({ searchTerm, filters, sortBy, sortOrder, page, limit }));
  }, [dispatch, getQueryParams]);

  useEffect(() => {
    dispatch(fetchUserTypes());
  }, [dispatch]);

  useEffect(() => {
    if (!isAddingEmployee) {
      refetchEmployees();
    }
  }, [refetchEmployees, isAddingEmployee]);

  const handleAddEmployee = () => {
    setIsAddingEmployee(true);
  };

  const handleCloseOnboarding = () => {
    setIsAddingEmployee(false);
  };

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({ ...prev, [filterName]: selectedValues }));
    setPage(1);
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
    setPage(1);
  };

  const handleSearchChange = (query: string) => {
    setSearchTerm(query);
    setPage(1);
  };

  const columns = [
    'Name',
    'Email',
    'Department',
    'Status',
    'Join Date',
  ];

  
  const data: EmployeeRow[] = employees
  .filter((employee) => {
    const matchesDesignation = employee.Designation !== 'Admin';
    const matchesDepartment =
      !selectedFilters.Department?.length ||
      selectedFilters.Department.includes(employee.Department);
    return matchesDesignation && matchesDepartment;
  })
  .map((employee): EmployeeRow | null => {
    if (!employee) {
      console.warn('Employee is undefined:', employee);
      return null;
    }
    return {
      id: employee.UserID,
      Name: (
        <div className="flex items-center space-x-2">
          {employee.ProfilePicture ? (
            <img
              src={employee.ProfilePicture}
              alt={`${employee.FirstName} ${employee.LastName}`}
              className="h-8 w-8 rounded-full object-cover"
            />
          ) : (
            <PiUserCircleLight className="h-8 w-8 text-gray-500" />
          )}
          <span>{`${employee.FirstName} ${employee.LastName}`}</span>
        </div>
      ),
      Email: employee.Email,
      Department: employee.Department || 'N/A',
      Status: t(employee.Status),
      JoinDate: employee.JoinDate ? new Date(employee.JoinDate).toLocaleDateString() : 'N/A',
    };
  })
  .filter((item): item is EmployeeRow => item !== null);
  if (userType !== 'BusinessAdmin' && userType !== 'LawFirmAdmin') {
    return <div className="p-4 text-gray-800 dark:text-white">{t('You do not have permission to view this page.')}</div>;
  }

  return (
    <div className="px-4">
      {isAddingEmployee ? (
        <Onboarding onClose={handleCloseOnboarding} />
      ) : (
        <>
          <Header
            title={t('Employees')}
            subtitle={t('Manage your employees')}
            actionLabel={t('Add Employee')}
            onActionClick={handleAddEmployee}
          />
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
            sortValue={sortValue}
          />
          <div className="mt-4 relative">
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60 dark:bg-gray-800 dark:bg-opacity-60 z-10">
                <ApiLoader />
              </div>
            )}
            <Table
              columns={columns}
              data={data}
              onRowClick={(row) => {
                const employee = employees.find((emp) => emp.UserID === row.id);
                if (employee) {
                  history.push(`/management/employees/${employee.UserID}`);
                }
              }}
            />
          </div>
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(newPage) => setPage(newPage)}
          />
        </>
      )}
    </div>
  );
};

export default EmployeeList;