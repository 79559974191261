import apis from "../../utils/apis"
import { showFeedbackModal } from "./UserFeedbackActions"
import { hideModal } from "./modalActions"
import { SET_SESSIONS } from "../types"
import { handleApiError } from "./utils/utils"
import { signOut } from "./SignInAction"


export const setSessions = (sessions: any[] | []) => {
    return {
        type: SET_SESSIONS,
        sessions: sessions
    };
};

export const generateCode = (method: string, t: any) => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "security/generateCode",
                data: {
                    method: method,
                    Email: t.Email,
                    FirstName: t.FirstName,
                    LastName: t.LastName,
                    PhoneNumber: t.PhoneNumber
                }
            })
            if (response && response.status === 200) {

                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (response.data.message),
                    modalType: 'success',
                    duration: 3000
                }));
                return response.data

            } else {
                handleApiError(response, dispatch)
                return null
            }

        } catch (err) {
            handleApiError(err, dispatch)
            return null
        }
    }
}


export const VerifyCode = (Token: string, Code: string) => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "security/email/verifyCode",
                data: {
                    Token,
                    Code
                }
            })
            if (response && response.status === 200) {
                dispatch(hideModal())
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: response.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
                return response.data
            } else {
                handleApiError(response, dispatch)
                return null
            }

        } catch (err) {
            handleApiError(err, dispatch)
            return null
        }
    }
}

export const getActiveSessions = () => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'GET',
                url: "/security/fetch_all_sessions",
            })
            if (response && response.status === 200) {
                dispatch(setSessions(response.data.session_list))
            } else {
                handleApiError(response, dispatch)
            }

        } catch (err) {
            handleApiError(err, dispatch)
        }
    }
}


// / ✅ Permanently Delete a User & Refresh Active Sessions
export const deleteUser = () => {
    return async (dispatch: any) => {
        try {
            const response = await apis({
                method: "DELETE",
                url: `/account/delete_account`,
            });

            if (response && response.status === 200) {
                // console.log("User deleted successfully:", response.data.message);
                dispatch(
                    showFeedbackModal({
                        modalType: 'success',
                        message: response.data.message || 'Account deleted successfully.',
                        showModal: true,
                        duration: 3000,
                    })
                );
                dispatch(signOut())
            } else {
                handleApiError(response, dispatch);
            }
        } catch (err) {
            handleApiError(err, dispatch);
        }
    };
};