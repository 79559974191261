import { useTranslation } from "react-i18next";
import Badge from "../components/shared/TailwindComponents/Badge";
import ConnectedAccountInfo from "../components/shared/Licenses/AccoundDetails";

function FinancePage() {
    const { t } = useTranslation();

    return (

              <ConnectedAccountInfo />
        
    )
}

export default FinancePage;