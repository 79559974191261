import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DynamicAnalyticsCharts, { ChartDataInterface } from "../components/Reports/DynamicAnalyticsChart";
import apis from "../utils/apis";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../components/shared/TailwindComponents/Header";
import ApiLoader from "../components/ApiLoader";
import { PiChartPieLight } from "react-icons/pi";
import Dropdown, { DropdownOption } from "../components/shared/TailwindComponents/Dropdown";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import FilterSearchBar from "../components/shared/TailwindComponents/FilterSearchBar";

const ReportsPage: React.FC = () => {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState<ChartDataInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const today = new Date();
    const oneYearAgoStart = new Date(today.getFullYear() - 1, today.getMonth(), 1);
    const [startDate, setStartDate] = useState<Date | null>(oneYearAgoStart);
    const [endDate, setEndDate] = useState<Date | null>(today);
    const [shortChartData, setShortChartData] = useState<ChartDataInterface[]>([]);
    const [employeeID, setEmployeeID] = useState<string>("")
    const employees = useSelector((state: RootState) => state.employees.employees);
    const [analyticsSummary, setAnalyticsSummary] = useState<
        { field: string; count: number }[]
    >([]);
    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({ ChartFilters: ['All'] });
    const ALL_CHART_FILTER_OPTIONS = ['Files', 'Tasks', 'Finance', 'AIServices', 'Clients', 'Cases', 'Employees'];

    const AnalyticFilters = [
        {
            id: 'ChartFilters',
            name: 'Chart Filters',
            options: [
                { value: "All", label: "All" },
                { value: 'Files', label: 'Files' },
                { value: 'Tasks', label: 'Tasks' },
                { value: 'Finance', label: 'Finance' },
                { value: 'AIServices', label: 'AI Services' },
                { value: 'Clients', label: 'Clients' },
                { value: 'Cases', label: 'Cases' },
                { value: 'Employees', label: 'Employees' },
            ],
        },

    ];


    const employeeOptions: DropdownOption[] = employees.map((emp) => ({
        value: emp.UserID,
        label: `${emp.FirstName} ${emp.LastName}`,
    }));

    employeeOptions.push({
        value: "",
        label: `Admin`,
    })

    useEffect(() => {
        fetchData();
    }, [startDate, endDate, employeeID, selectedFilters]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const params: any = {};
            if (startDate) params.start_date = startDate.toISOString().split("T")[0];
            if (endDate) params.end_date = endDate.toISOString().split("T")[0];
            if (employeeID) params.employeeID = employeeID
            if (selectedFilters.ChartFilters?.length > 0) params.ChartFilters = selectedFilters.ChartFilters.includes("All") ? ALL_CHART_FILTER_OPTIONS : selectedFilters.ChartFilters

            const response = await apis.get("/analytics/get_analytics", { params });
            if (!response) throw new Error("Failed to fetch analytics data");

            const { chartData, static_fields = [], shortChartData } = response.data;

            setChartData(chartData);
            setShortChartData(shortChartData)
            setAnalyticsSummary(static_fields);
        } catch (err) {
            console.error("Error fetching analytics:", err);
        } finally {
            setLoading(false);
        }
    };
    const handleSearchChange = (query: string) => ''
    const handleFilterChange = (filterName: string, selectedValues: string[]) => {
        if (filterName === 'ChartFilters') {
            if (selectedValues.includes('All')) {
                // Select all available chart filters
                setSelectedFilters((prev) => ({
                    ...prev,
                    ChartFilters: ["All"],
                }));
            } else {
                setSelectedFilters((prev) => ({
                    ...prev,
                    ChartFilters: selectedValues,
                }));
            }
        }
    };
    return (
        <div className="h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
            {/* Header & Filters */}
            <div className="sticky top-[10px] z-20 bg-white dark:bg-gray-900 pb-4">
                <Header
                    title={t("Analytics Dashboard")}
                    subtitle={t("Track service usage, case metrics, client metrics, task progress, and more.")}
                    icon={<PiChartPieLight className="text-primary-500" size={24} />}
                    actionLabel={t("Refresh")}
                />
                <FilterSearchBar
                    filters={AnalyticFilters}
                    sortOptions={[]}
                    selectedFilters={selectedFilters}
                    onFilterChange={handleFilterChange}
                    onSortChange={() => { }}
                    onSearchChange={handleSearchChange}
                    // sortValue={sortValue}
                    extraControls={
                        <div className="flex items-center justify-between gap-2">
                            <span className="text-gray-700 dark:text-gray-300 text-xs">{t("Start Date")}</span>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="border rounded px-2 py-1 text-gray-700 dark:text-white dark:bg-gray-800 dark:border-gray-600 focus:ring-2 focus:ring-primary-500 w-36 text-xs"
                                calendarClassName="bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white"
                            />
                            <span className="text-gray-700 dark:text-gray-300 text-xs">{t("End Date")}</span>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                className="border rounded px-2 py-1 text-gray-700 dark:text-white dark:bg-gray-800 dark:border-gray-600 focus:ring-2 focus:ring-primary-500 w-36 text-xs"
                                calendarClassName="bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white"
                            />
                            <Dropdown
                                options={employeeOptions}
                                placeholder={t('Select Employee')}
                                onSelect={(option) => setEmployeeID(option.value)}
                                className="py-1"
                            />
                        </div>
                    }
                />
            </div>

            {/* Main Grid */}
            <div className="flex-grow mt-6 overflow-y-auto">
                {loading ? (
                    <div className="flex-grow flex justify-center items-center mt-6">
                        <ApiLoader />
                    </div>
                ) : (
                    <div className="flex flex-col gap-3">
                        <div className="grid grid-cols-1 gap-4  m-1">
                            <div className="flex flex-wrap gap-4 order-1">
                                {analyticsSummary.map((item: any, index: any) => {
                                    const colorSchemes = [
                                        { bg: "bg-blue-100 dark:bg-blue-900", text: "text-blue-800 dark:text-blue-200" },
                                        { bg: "bg-yellow-100 dark:bg-yellow-900", text: "text-yellow-800 dark:text-yellow-200" },
                                        { bg: "bg-green-100 dark:bg-green-900", text: "text-green-800 dark:text-green-200" },
                                        { bg: "bg-purple-100 dark:bg-purple-900", text: "text-purple-800 dark:text-purple-200" },
                                        { bg: "bg-pink-100 dark:bg-pink-900", text: "text-pink-800 dark:text-pink-200" },
                                        { bg: "bg-indigo-100 dark:bg-indigo-900", text: "text-indigo-800 dark:text-indigo-200" },
                                    ];
                                    const colors = colorSchemes[index % colorSchemes.length];

                                    const formattedLabel = item.field
                                        .replace(/_/g, " ")
                                        .replace(/\b\w/g, (char: string) => char.toUpperCase());

                                    return (
                                        <div
                                            key={index}
                                            className={`rounded-xl p-4 shadow-sm ${colors.bg} ${colors.text} transition-all duration-200 text-center flex flex-col justify-center items-center w-40`}
                                        >
                                            <h4 className="text-sm font-medium mb-1">{formattedLabel}</h4>
                                            <p className="text-2xl font-bold">{item.count}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="lg:col-span-2 xl:col-span-3 order-2">
                                <DynamicAnalyticsCharts chartsData={shortChartData} />
                            </div>
                        </div>

                        <div className="lg:col-span-3 xl:col-span-3 order-3">
                            <DynamicAnalyticsCharts chartsData={chartData} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportsPage;
