import React, { useCallback, useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../store';
import { GetNotifications, updateNotification } from '../../store/actions/Notifications';
import { useHistory } from 'react-router-dom';
import Badge from '../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import SocketServices from '../../utils/SocketServices';
import { useSelector } from 'react-redux';
import { PiBellLight } from "react-icons/pi";

interface Notification {
  notification_id: string;
  user_id: string;
  content: string;
  link: string;
  is_read: boolean;
  created_at: string;
}

interface NotificationsProps {
  isOpen: boolean;
  onToggle: () => void;
  onClose: () => void;
  disabled?: boolean; // Already in props, ensure it’s used
}

const Notifications: React.FC<NotificationsProps> = ({
  isOpen,
  onToggle,
  onClose,
  disabled = false, // Default to false if not provided
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await dispatch(GetNotifications());
      if (response.data.length > 0) {
        setNotifications(response.data);
        setUnreadCount(response.data.filter((n: any) => !n.is_read).length);
      }
    } catch (error) {
      console.error('Error fetching notifications', error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!disabled) {
      fetchNotifications();
    }
  }, [userInfo.UserUID, fetchNotifications, disabled]);

  const receiveNewNotification = useCallback((data: any) => {
    console.log("Data received", data);
    setNotifications((prevNotifications: any[]) => [data, ...prevNotifications]);
    setUnreadCount((prevUnreadCount) => prevUnreadCount + (!data.is_read ? 1 : 0));
  }, []);

  useEffect(() => {
    if (!disabled) {
      SocketServices.on('new_notification', receiveNewNotification);
      return () => {
        SocketServices.removeListener('new_notification');
      };
    }
  }, [receiveNewNotification, disabled]);

  const handleNotificationClick = async (notification: Notification) => {
    if (disabled) return; // Prevent interaction if disabled
    try {
      await dispatch(updateNotification(notification.notification_id));
      fetchNotifications();
      history.push(notification.link);
      onClose();
    } catch (error) {
      console.error('Error marking notification as read', error);
    }
  };

  const handleToggle = () => {
    if (!disabled) {
      onToggle();
    }
  };

  return (
    <div className="relative">
      {/* Bell Icon */}
      {/* <Tooltip title={disabled ? t('Coming Soon') : t('Notifications')}> */}
        <div
          className={clsx(
            'relative rounded-full transition-colors duration-300',
            disabled ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed' : 'cursor-pointer text-gray-600 dark:text-gray-300',
            isOpen && !disabled ? 'bg-primary-500 text-white' : ''
          )}
          onClick={handleToggle}
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
            />
          </svg> */}
          <PiBellLight size={20} />
          {unreadCount > 0 && !disabled && (
            <span className="absolute -top-3 -right-1 bg-red-500 text-white text-xs rounded-full px-1">
              {unreadCount}
            </span>
          )}
        </div>
      {/* </Tooltip> */}
      {/* Dropdown - Only show if not disabled */}
      {isOpen && !disabled && (
        <div className="absolute right-0 w-64 mt-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-lg rounded-lg overflow-hidden z-10">
          <div className="p-4 bg-gray-100 text-sm dark:bg-gray-700 text-gray-700 dark:text-gray-200">
            {t('Notifications')}
          </div>
          <ul className="divide-y divide-gray-200 dark:divide-gray-700 max-h-96 overflow-y-auto">
            {notifications.map((notification) => (
              <li
                key={notification.notification_id}
                className={clsx(
                  'p-3 cursor-pointer transition-colors',
                  notification.is_read
                    ? 'bg-gray-100 text-gray-500 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-500'
                    : 'bg-white text-black dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-900'
                )}
                onClick={() => handleNotificationClick(notification)}
              >
                <p className="text-sm dark:text-white font-medium">{notification.content}</p>
                <span className="text-xs dark:text-gray-200 text-gray-400">
                  {new Date(notification.created_at).toLocaleString()}
                </span>
              </li>
            ))}
          </ul>
          {notifications.length === 0 && (
            <div className="flex justify-center">
              <Badge color="gray" className="my-4">
                {t('No notifications')}
              </Badge>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;