import {
    FETCH_USER_SUBSCRIPTION_REQUEST,
    FETCH_USER_SUBSCRIPTION_SUCCESS,
    FETCH_USER_SUBSCRIPTION_FAILURE
  } from "../types";
  
  interface SubscriptionState {
    subscriptionActive: boolean;
    loading: boolean;
  }
  
  const initialState: SubscriptionState = {
    subscriptionActive: false,
    loading: false
  };
  
  export const subscriptionReducer = (state = initialState, action: any): SubscriptionState => {
    switch (action.type) {
      case FETCH_USER_SUBSCRIPTION_REQUEST:
        return { ...state, loading: true };
  
      case FETCH_USER_SUBSCRIPTION_SUCCESS:
        return { subscriptionActive: action.payload, loading: false };
  
      case FETCH_USER_SUBSCRIPTION_FAILURE:
        return { ...state, loading: false };
  
      default:
        return state;
    }
  };
  