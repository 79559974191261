import { AnyAction, Dispatch } from 'redux';
import { SidePanelState } from '../reducer/sidePanelReducer';
import { Description, Section } from '../../components/Help/types';
import { IAdminCaseItem } from '../../components/Marketplace/shared/Interfaces/IAdminCaseItem';
import { IProject } from '../../components/Projects/ProjectsInterfaces';
import { FileProps, FolderProps } from '../../components/Documents/types';

export const showSidePanel = (payload: SidePanelState): AnyAction => ({
  type: 'SHOW_SIDE_PANEL',
  payload,
});

export const hideSidePanel = (): AnyAction => ({
  type: 'HIDE_SIDE_PANEL',
});

export const expandSidePanel = (): AnyAction => ({
  type: 'EXPAND_SIDE_PANEL',
});

export const collapseSidePanel = (): AnyAction => ({
  type: 'COLLAPSE_SIDE_PANEL',
});

export const updateSidePanel = (payload: Partial<SidePanelState>): AnyAction => ({
  type: 'UPDATE_SIDE_PANEL',
  payload,
});

export const showAddTaskSidePanel = () => {
  return (dispatch: Dispatch) => {
    dispatch(showSidePanel({
      type: 'addTask',
      showSidePanel: true,
      width: '50%',
      onConfirm: () => { },
      onCancel: () => { },
    }));
  };
};

export const showAddFileSidePanel = (
  folders: FolderProps[],
  folderId?: string,
  onFileCreated?: (newFile: FileProps) => void
) => {
  return (dispatch: Dispatch) => {
    dispatch(showSidePanel({
      type: 'addFile',
      showSidePanel: true,
      width: '50%',
      onConfirm: () => {},
      onCancel: () => {},
      folders: folders,
      preSelectedFolderId: folderId,
      onFileCreated, 
    }));
  };
};

export const showSupportTicketSidePanel = () => {
  return (dispatch: Dispatch) => {
    dispatch(showSidePanel({
      type: 'supportTicket',
      showSidePanel: true,
      isExpanded: false,
      width: '50%',
      onConfirm: () => { },
      onCancel: () => { },
    }));
  };
};

export const showHelpContentSidePanel = (section: Section, description: Description) => {
  return (dispatch: Dispatch) => {
    dispatch(showSidePanel({
      type: 'helpContent',
      showSidePanel: true,
      width: '50%',
      content: { section, description },
      onConfirm: () => { },
      onCancel: () => { },
    }));
  };
};


export const showCaseViewSidePanel = (caseItem: IAdminCaseItem) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'caseView',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
        caseItem,
      })
    );
  };
};

export const showAddClientSidePanel = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'addClient',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
      })
    );
  };
};


export const showTimekeepingSidePanel = (day: string | null, data: any, isLoading: boolean, stats: any) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'timekeepingView',
        showSidePanel: true,
        width: '50%',
        isExpanded: false,
        onCancel: () => dispatch(hideSidePanel()),
        day,
        data,
        isLoading,
        stats
      })
    );
  };
};

export const showCreateLCMCaseSidePanel = () => ({
  type: 'SHOW_SIDE_PANEL',
  payload: {
    type: 'createLCMCase',
    width: '50%',
    isExpanded: false,
  },
});

export const showProjectViewSidePanel = (project: IProject) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'projectView',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
        project,
      })
    );
  };
};

export const showCreateProjectSidePanel = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'createProject',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
      })
    );
  };
};

export const showOnboardingSidePanel = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'onboarding',
        showSidePanel: true,
        width: '50%',
        onConfirm: () => { },
        onCancel: () => { },
      })
    );
  };
};


export const showAddCaseSidePanel = (clientId?: string, clientUserType?: string) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'addCase',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
        clientId,
        clientUserType,
      })
    );
  };
};

export const showPublishCaseSidePanel = (caseItem: any) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'publishCase',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
        caseItem,
      })
    );
  };
};


export const showAddTeamSidePanel = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'addTeam',
        showSidePanel: true,
        width: '50%',
        onConfirm: () => { },
        onCancel: () => dispatch(hideSidePanel()),
      })
    );
  };
};

export const showPlaceBidSidePanel = (engagement: any) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'placeBid',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
        engagement,
      })
    );
  };
};

export const showProposeCaseSidePanel = (lawyerId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'proposeCase',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
        lawyerId,
      })
    );
  };
};


export const showSupportTicketFormSidePanel = () => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'supportTicketForm',
        showSidePanel: true,
        width: '50%',
        onCancel: () => dispatch(hideSidePanel()),
      })
    );
  };
};

export const showFileReaderSidePanel = (fileUrl: string) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'fileReader',
        showSidePanel: true,
        width: '50%',
        fileUrl,
        onCancel: () => dispatch(hideSidePanel()),
      })
    );
  };
};


export const showLegalOutlineContentSidePanel = (
  sectionName: string,
  content: string,
  fieldData: { text: string; files: File[] },
  onSave: (newContent: string | null, newFiles: File[]) => void
) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'legalOutlineContent',
        showSidePanel: true,
        width: '50%',
        sectionName,
        content,
        fieldData,
        onSave,
        onCancel: () => dispatch(hideSidePanel()),
      })
    );
  };
};

export const showLegalOutlineGeneratedContentSidePanel = (
  sectionName: string,
  content: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'legalOutlineGeneratedContent',
        showSidePanel: true,
        width: '50%',
        sectionName,
        content,
        onCancel: () => dispatch(hideSidePanel()),
      })
    );
  };
};

export const showChronologyFileContentSidePanel = (content: string, label: string) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'chronologyFileContent',
        showSidePanel: true,
        width: '50%',
        content,
        onCancel: () => dispatch(hideSidePanel()),
        label
      })
    );
  };
};

export const showExtractedContentSidePanel = (filename: string, extractedText: string) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'extractedContent',
        showSidePanel: true,
        width: '50%',
        filename,
        content: extractedText,
        onCancel: () => dispatch(hideSidePanel()),
      })
    );
  };
};
export const showAttachClientToCaseSidePanel = (caseId: string, onSuccess?: () => void) => {
  return (dispatch: Dispatch) => {
    dispatch(
      showSidePanel({
        type: 'attachClient',
        showSidePanel: true,
        width: '50%',
        caseId,
        onCancel: () => dispatch(hideSidePanel()),
        onSuccess,
      })
    )
  }
};


export const ShowTransactionDetailsPanel = (transaction_id: string) => {
  return (dispatch: Dispatch) => {
    dispatch(showSidePanel({
      type: 'transactionDetails',
      showSidePanel: true,
      width: '50%',
      onConfirm: () => { },
      onCancel: () => { },
      transaction_id: transaction_id,
    }));
  }
};
