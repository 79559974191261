import React from 'react';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaUserShield, FaUserFriends } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



type LicenseCardProps = {
  LicenseID: number;
  LicenseName: string;
  Description?: string;
  DurationInDays: number;
  FinalPrice: number;
  IsSubscription: boolean;
  IsOneTimePurchase: boolean;
  country: string | null
  MonthlyPriceEstimate?: number;
  SavingsFor4MonthsPercent?: number | null;
  SavingsFor6MonthsPercent?: number | null;
  SavingsFor12MonthsPercent?: number | null;
  SavingsComparedToShortestSub?: number | null;
  TotalQueries: number;
  selectedDuration?: number; // In days (e.g., 30, 90, 180, 365)

  // NEW FIELDS from your API (optional)
  KeyBenefits?: string[];
  RecommendedUseCases?: string[];
  SupportLevel?: string;
  MinimumUsers?: number;
  MinimumQuantity?: number;
};

const LicenseCard: React.FC<LicenseCardProps> = ({
  LicenseName,
  Description,
  DurationInDays,
  FinalPrice,
  IsSubscription,
  IsOneTimePurchase,
  MonthlyPriceEstimate,
  SavingsFor4MonthsPercent,
  SavingsFor6MonthsPercent,
  SavingsFor12MonthsPercent,
  SavingsComparedToShortestSub,
  TotalQueries,
  MinimumUsers,
  selectedDuration,

  // Newly added fields
  KeyBenefits,
  RecommendedUseCases,
  SupportLevel,
  LicenseID,
  country,
  MinimumQuantity
}) => {
  // --------------------
  // Price & Savings Logic
  // --------------------
  const {t} = useTranslation()
  let displayedPrice = FinalPrice;
  let displayedSavings = 0;

  if (IsSubscription && MonthlyPriceEstimate && selectedDuration) {
    switch (selectedDuration) {
      case 90: // 3 months
        displayedPrice = MonthlyPriceEstimate * 3;
        displayedSavings = SavingsFor4MonthsPercent || 0;
        break;
      case 180: // 6 months
        displayedPrice = MonthlyPriceEstimate * 6;
        displayedSavings = SavingsFor6MonthsPercent || 0;
        break;
      case 365: // 12 months
        displayedPrice = MonthlyPriceEstimate * 12;
        displayedSavings = SavingsFor12MonthsPercent || 0;
        break;
      default:
        // 1 month or other durations
        displayedPrice = MonthlyPriceEstimate;
        displayedSavings = 0;
        break;
    }
  }

  const hasSavings = displayedSavings > 0;
  const compareSavings = SavingsComparedToShortestSub ?? 0;
  const history = useHistory();


  // Helper to show months from DurationInDays
  const months = Math.round(DurationInDays / 30);
  const monthsLabel = months === 1 ? t('Month') : t('Months');

  // --------------------
  // checkout logic
  // --------------------

  const handleCardClick = () => {
    // Option 1: Navigate with URL param
    history.push(`/plans/checkout?licenseId=${LicenseID}&&country=${country}`);

  
  };

  // --------------------
  // Render
  // --------------------
  return (
    <motion.div
      className={`
        relative flex flex-col rounded-lg p-5 transition duration-300 
        border-l-4 ${IsSubscription ? 'border-indigo-500' : 'border-green-500'}
        bg-white dark:bg-gray-800
        hover:bg-gradient-to-r hover:from-indigo-50 dark:hover:from-gray-700
        hover:to-white dark:hover:to-gray-800
        shadow-md hover:shadow-xl cursor-pointer
      `}
      whileHover={{ scale: 1.02 }}
      onClick={handleCardClick}
    >
      {/* If there's additional savings vs. the shortest subscription plan */}
      {compareSavings > 0 && (
        <div className="absolute top-2 right-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow">
          {t("Save Extra")} {compareSavings.toFixed(1)}%
        </div>
      )}

      {/* Title / Name */}
      <div className="flex items-center gap-2 mb-2">
        <AiOutlineInfoCircle className="text-indigo-500 text-xl dark:text-indigo-300" />
        <h3 className="text-lg font-bold dark:text-gray-100">{LicenseName}</h3>
      </div>

      {/* Description */}
      {Description && (
        <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">
          {Description}
        </p>
      )}

      {/* Basic Details */}
      <div className="grid grid-cols-1 gap-2 text-sm mb-4">
        <div className="flex items-center gap-2 dark:text-gray-200">
          <FaUserShield className="text-indigo-500 dark:text-indigo-300" />
          <span>
            <strong>{t("Duration")}:</strong> {months} {monthsLabel}
          </span>
        </div>
        <div className="flex items-center gap-2 dark:text-gray-200">
          {
            MinimumUsers ? (
              <>
                <FaUserFriends className="text-indigo-500 dark:text-indigo-300" />
                <span>
                  <strong>{t("Min. Users")}  :</strong> {MinimumUsers}
                </span>
              </>
            ) : (
              <>
                <FaUserFriends className="text-indigo-500 dark:text-indigo-300" />
                <span>
                  <strong>{t("Min. Quantity")}  :</strong> {MinimumQuantity}
                </span>
              </>
            )
          }

        </div>
        <div className="flex items-center gap-2 dark:text-gray-200">
          <FaCheckCircle className="text-indigo-500 dark:text-indigo-300" />
          <span>
            <strong>{t("Total Queries")} :  </strong> {TotalQueries}
          </span>
        </div>
      </div>

      {/* Key Benefits */}
      {KeyBenefits && KeyBenefits.length > 0 && (
        <div className="mb-4">
          <h4 className="text-sm font-semibold dark:text-gray-100 flex items-center gap-1">
            <FaCheckCircle className="text-green-500" />
            {t("Key Benefits")}
          </h4>
          <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-300 mt-1 ml-5">
            {KeyBenefits.map((benefit, idx) => (
              <li key={idx}>{benefit}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Recommended Use Cases */}
      {RecommendedUseCases && RecommendedUseCases.length > 0 && (
        <div className="mb-4">
          <h4 className="text-sm font-semibold dark:text-gray-100 flex items-center gap-1">
            <FaCheckCircle className="text-blue-500" />
            {t("Recommended For")}
          </h4>
          <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-300 mt-1 ml-5">
            {RecommendedUseCases.map((useCase, idx) => (
              <li key={idx}>{useCase}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Support Level */}
      {SupportLevel && (
        <p className="text-xs mb-4 font-medium text-gray-700 dark:text-gray-200">
          {t("Support Level")}:{' '}
          <span className="text-indigo-600 dark:text-indigo-400">{SupportLevel}</span>
        </p>
      )}

      {/* Price & Savings */}
      <div className="flex items-center justify-between mt-auto">
        <div>
          <span className="text-xl font-bold dark:text-gray-100">
            ${Math.round(displayedPrice).toFixed(2)}
          </span>
          {hasSavings && (
            <span className="ml-2 px-2 py-1 text-xs font-medium bg-green-100 text-green-700 dark:bg-green-600 dark:text-white rounded-full">
              {t("Save")} {displayedSavings.toFixed(1)}%
            </span>
          )}
        </div>
        <p
          className={`text-xs px-2 py-1 rounded-full font-medium ${IsSubscription
            ? 'bg-indigo-100 text-indigo-700 dark:bg-indigo-600 dark:text-white'
            : 'bg-green-100 text-green-700 dark:bg-green-600 dark:text-white'
            }`}
        >
          {IsSubscription ? t('Subscription') : t('One-Time')}
        </p>
      </div>
    </motion.div>
  );
};

export default LicenseCard;
