import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store';
import {
  generateChronology,
  getChronology,
  fetchChronologyVersions,
  restoreChronologyVersion,
  ChronologyData,
} from '../../../../store/actions/LegalServices/chronologyActions';
import { showSidePanel } from '../../../../store/actions/sidePanelActions';
import { LoadingToCheckmark } from '../../../shared/animations/LoadingToCheckmark';
import Button from '../../../shared/TailwindComponents/Button';
import Badge from '../../../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Tooltip } from '@mui/material';
import {
  PiSparkleLight,
  PiFilesLight,
  PiUsersLight,
  PiFileLight,
  PiTextTLight,
  PiWarningLight,
  PiGearLight,
  PiTagLight,
} from 'react-icons/pi';
import ChronologyVersionsDropdown from './ChronologyVersionsDropdown';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';

interface ChronologyContainerProps {
  caseId: string;
  generatedChronology: string;
  refreshChronology?: () => void;
  loadCaseSummary: () => void;
}

const getCategoryStyle = (category: string) => {
  switch (category) {
    case 'Contract':
      return 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200';
    case 'Communication':
      return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200';
    case 'Filing':
    case 'Legal Action':
      return 'bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200';
    case 'Contract breach':
      return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200';
    default:
      return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200';
  }
};

const ChronologyContainer: React.FC<ChronologyContainerProps> = ({
  caseId,
  generatedChronology,
  refreshChronology,
  loadCaseSummary,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isGenerating, setIsGenerating] = useState(false);
  const [showChronology, setShowChronology] = useState<boolean>(!!generatedChronology);
  const [displayedText, setDisplayedText] = useState<string>(generatedChronology || '');
  const [timelineData, setTimelineData] = useState<any>(null);
  const [docUrl, setDocUrl] = useState<string | null>(null);

  useEffect(() => {
    if (generatedChronology) {
      setDisplayedText(generatedChronology);
      setShowChronology(true);
    }
  }, [generatedChronology]);

  useEffect(() => {
    console.log('Displayed text updated:', displayedText);
    if (displayedText) {
      const parsed = parseChronologyContent(displayedText);
      console.log('Parsed timeline data:', parsed);
      setTimelineData(parsed);
      if (parsed?.doc_url && !docUrl) {
        setDocUrl(parsed.doc_url);
      }
    } else {
      setTimelineData(null);
      setDocUrl(null);
    }
  }, [displayedText]);

  const parseChronologyContent = (input: string): any => {
    try {
      let jsonString = input.trim();
      if (jsonString.startsWith('```json') && jsonString.endsWith('```')) {
        jsonString = jsonString.slice(7, -3).trim();
      } else if (jsonString.startsWith('```') && jsonString.endsWith('```')) {
        jsonString = jsonString.slice(3, -3).trim();
      }
      return JSON.parse(jsonString);
    } catch (error) {
      console.warn('Failed to parse as JSON, treating as markdown:', error);
      return input;
    }
  };

  const handleFinalAnimationDone = () => {
    setShowChronology(true);
    setIsGenerating(false);
  };

  const runFullGenerateFlow = async () => {
    setShowChronology(false);
    setIsGenerating(true);
    const generateResult = await dispatch(generateChronology(caseId));
    if (generateResult?.doc_url) {
      setDocUrl(generateResult.doc_url);
    }
    const chronoResult = await dispatch(getChronology(caseId));
    if (chronoResult?.GeneratedChronology) {
      setDisplayedText(chronoResult.GeneratedChronology);
    }
    await dispatch(fetchChronologyVersions(caseId));
    await loadCaseSummary();
    setIsGenerating(false);
  };

  const handleGenerateChronology = async () => {
    await runFullGenerateFlow();
    refreshChronology?.();
  };

  const runFullRestoreFlow = async (versionId: string) => {
    setShowChronology(false);
    setIsGenerating(true);
    await dispatch(restoreChronologyVersion(caseId, versionId));
    const chronoResult = await dispatch(getChronology(caseId)) as ChronologyData | null;
    if (chronoResult?.GeneratedChronology) {
      setDisplayedText(chronoResult.GeneratedChronology);
      setDocUrl(chronoResult.ChronologyDocUrl || null);
    }
    await dispatch(fetchChronologyVersions(caseId));
    await loadCaseSummary();
    setIsGenerating(false);
  };

  const handleSelectVersionText = (text: string) => {
    console.log('Setting displayed text to:', text);
    setDisplayedText(text);
  };

  const handleRestoreVersion = async (versionId: string) => {
    await runFullRestoreFlow(versionId);
    refreshChronology?.();
  };

  const handleOpenAddDataSidePanel = () => {
    dispatch(
      showSidePanel({
        type: 'addChronologyEvent',
        showSidePanel: true,
        width: '50%',
        caseId,
        onSuccess: async () => {
          await refreshChronology?.();
          const chronoResult = await dispatch(getChronology(caseId));
          if (chronoResult?.GeneratedChronology) {
            setDisplayedText(chronoResult.GeneratedChronology);
          }
          await dispatch(fetchChronologyVersions(caseId));
        },
      })
    );
  };

  return (
    <div className="mt-4 max-w-4xl mx-auto space-y-6">
      {/* Actions and Version Dropdown */}
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{t('Chronology')}</h3>
        <div className="flex items-center gap-3">
          <Button
            variant="primary"
            size="small"
            onClick={handleGenerateChronology}
            disabled={isGenerating}
            aria-label={t('Run Chronology')}
            tooltip={t('Run Chronology')}
          >
            <PiSparkleLight size={16} />
          </Button>
          <Button
            variant="primary"
            size="small"
            onClick={handleOpenAddDataSidePanel}
            aria-label={t('Add Data')}
            tooltip={t('Add Data')}
          >
            <PiFilesLight size={16} />
          </Button>
          <ChronologyVersionsDropdown
            caseId={caseId}
            onSelectVersionText={handleSelectVersionText}
            currentVersionText={displayedText}
            onRestoreVersion={handleRestoreVersion}
          />
        </div>
      </div>

      {/* Chronology Output */}
      <div>
        {isGenerating ? (
          <div className="flex justify-center">
            <LoadingToCheckmark
              isLoading={true}
              spinnerDuration={800}
              postCheckmarkDelay={1200}
              onAnimationDone={handleFinalAnimationDone}
              serviceName={t('Chronology Generation')}
            />
          </div>
        ) : showChronology && displayedText ? (
          timelineData ? (
            typeof timelineData === 'string' ? (
              timelineData.trim() ? (
                <div className="prose dark:prose-invert">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{timelineData}</ReactMarkdown>
                </div>
              ) : (
                <Badge color="gray">{t('No chronology content available.')}</Badge>
              )
            ) : timelineData.timeline ? (
              timelineData.timeline.length === 0 ? (
                <Badge color="gray">{t('No events in the chronology.')}</Badge>
              ) : (
                <div className="space-y-6">
                  {timelineData?.metadata && (
                    <div className="mt-4 flex flex-wrap gap-2">
                      {Object.entries(timelineData.metadata)
                        .filter(([key]) => key !== 'prompt' && key !== 'files')
                        .map(([key, value]) => (
                          <Badge key={key} color="gray" tooltip={`${key.charAt(0).toUpperCase() + key.slice(1)}`}>
                            {`${value}`}
                          </Badge>
                        ))}
                    </div>
                  )}
                  {timelineData.timeline.map((event: any, index: number) => (
                    <Disclosure key={index}>
                      {({ open }) => (
                        <div className="border-b border-gray-200 dark:border-gray-700">
                          <Disclosure.Button className="flex w-full items-center justify-between px-4 py-3 text-left text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                            <div className="flex items-center">
                              <span className="font-medium text-sm">{event.date}</span>
                              <span className="ml-4 text-sm">{event.event}</span>
                            </div>
                            <ChevronUpIcon
                              className={`h-5 w-5 text-gray-500 transition-transform ${open ? 'rotate-180' : ''}`}
                            />
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 -translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-1"
                          >
                            <Disclosure.Panel className="px-4 py-3 text-sm text-gray-600 dark:text-gray-400">
                              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {['parties', 'source', 'description', 'implications', 'actions', 'category'].map(
                                  (key) =>
                                    event[key] && (
                                      <div key={key} className="flex items-start">
                                        <Tooltip title={t(key.charAt(0).toUpperCase() + key.slice(1))}>
                                          <div>
                                            {key === 'parties' && <PiUsersLight className="h-5 w-5 text-gray-500 mr-2 mt-1" />}
                                            {key === 'source' && <PiFileLight className="h-5 w-5 text-gray-500 mr-2 mt-1" />}
                                            {key === 'description' && <PiTextTLight className="h-5 w-5 text-gray-500 mr-2 mt-1" />}
                                            {key === 'implications' && <PiWarningLight className="h-5 w-5 text-gray-500 mr-2 mt-1" />}
                                            {key === 'actions' && <PiGearLight className="h-5 w-5 text-gray-500 mr-2 mt-1" />}
                                            {key === 'category' && <PiTagLight className="h-5 w-5 text-gray-500 mr-2 mt-1" />}
                                          </div>
                                        </Tooltip>
                                        <div>
                                          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                            {t(key.charAt(0).toUpperCase() + key.slice(1))}
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                                            {key === 'category' ? (
                                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium ${getCategoryStyle(event.category)}`}>
                                                {event.category}
                                              </span>
                                            ) : (
                                              event[key]
                                            )}
                                          </dd>
                                        </div>
                                      </div>
                                    )
                                )}
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </div>
                      )}
                    </Disclosure>
                  ))}
                </div>
              )
            ) : (
              <Badge color="red">{t('Error parsing chronology data')}</Badge>
            )
          ) : (
            <Badge color="gray">{t('No chronology content available.')}</Badge>
          )
        ) : (
          <Badge color="gray">{t('No generated chronology yet.')}</Badge>
        )}
        {docUrl && (
          <div className="mt-4">
            <a href={docUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
              {t('Download Chronology Document')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChronologyContainer;