import React from 'react';
import * as Yup from 'yup';
import 'yup-phone';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkKickboxEmail, SignUp } from '../../store/actions/SignUpAction';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';


// Import Global Components
import Button from '../shared/TailwindComponents/Button';
import Input from '../shared/TailwindComponents/Input';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import { generateCode, VerifyCode } from '../../store/actions/SecurityActions';
import { hideModal, showModal } from '../../store/actions/modalActions';
import RegionSwitcher from './RegionSwitcher';

declare module 'yup' {
  interface StringSchema {
    phone(countryCode?: string | null, errorMessage?: string): StringSchema;
  }
}

interface SignUpSectionProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  toggleAuthMode: () => void;
}

const SignUpSection: React.FC<SignUpSectionProps> = ({
  isLoading,
  setIsLoading,
  toggleAuthMode,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<any, undefined, AnyAction>>();
  const { t } = useTranslation();

  const userTypeOptions = [
    { value: 'IndividualClient', label: 'Individual Client' },
    { value: 'IndependentLawyer', label: 'Independent Lawyer' },
    { value: 'LawFirmAdmin', label: 'Law Firm' },
    { value: 'BusinessAdmin', label: 'Business' },
  ];

  const euCountryCodes = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU',
    'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
  ];

  // Add India and United States
  const allowedCountryCodes = [...euCountryCodes, 'IN', 'US'];

  // Domains where validation should be skipped
  const skipValidationDomains = ['localhost', 'dev.pons.io'];

  const getCurrentDomain = () => window.location.hostname;
  const currentDomain = getCurrentDomain();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('First name is required'))
      .max(50, t('First name must be at most 50 characters')),
    lastName: Yup.string()
      .required(t('Last name is required'))
      .max(50, t('Last name must be at most 50 characters')),
    email: Yup.string()
      .email(t('Please enter a valid email ID'))
      .required(t('Email is required'))
      .test(
        'is-valid-email',
        'Temporary emails are not allowed',
        async function (email: string) {
          // ✅ Allow temp emails only for localhost and dev.pons.io
          if (skipValidationDomains.includes(currentDomain)) {
            return true;
          }

          // ❌ Block temp emails for other domains
          const is_email_valid: boolean = await checkKickboxEmail(email)
          if (is_email_valid) {
            return true
          } else {
            return false
          }
          // return !tempEmailDomains.includes(domain);
        }
      ),
    phoneNumber: Yup.string()
      .test('domain-specific-phone-validation', 'Invalid phone number for this domain', function (value) {


        // Skip validation for specific domains
        if (skipValidationDomains.includes(currentDomain)) {
          return true;
        }

        // Check for valid phone numbers from allowed countries
        return allowedCountryCodes.some((countryCode) =>
          Yup.string().phone(countryCode).isValidSync(value)
        );
      })
      .required('Phone number is required'),
    userType: Yup.string().required(t('User type is required')),
    password: Yup.string()
      .min(
        8,
        t('8+ characters, uppercase, lowercase, number & special (!?&..)')
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
        t('8+ characters, uppercase, lowercase, number & special (!?&..)')
      )
      .required(t('Password is required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], t('Passwords must match'))
      .required(t('Confirm Password is required')),
    AgreeToTerms: Yup.bool().oneOf(
      [true],
      t('You must agree to the terms and conditions')
    ),
  });


  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      userType: '',
      organizationName: '',
      password: '',
      confirmPassword: '',
      AgreeToTerms: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const sendValue: any = {
          FirstName: values.firstName,
          LastName: values.lastName,
          Email: values.email,
          PhoneNumber: values.phoneNumber,
          Password: values.password,
          ConfirmPassword: values.confirmPassword,
          UserTypeID: getUserTypeID(values.userType),
          AgreeToTerms: values.AgreeToTerms ? 'yes' : 'no',
          IsTestUser: 1
        };

        if (values.organizationName) {
          sendValue.OrganizationName = values.organizationName;
        }

        const resp: any = await dispatch(generateCode('email', sendValue));
        if (resp) {
          dispatch(showModal({
            showModal: true,
            type: 'otp-verification',
            message: '',
            onConfirm: async (verificationCode: File | string) => {
              const verification_response: any = await dispatch(VerifyCode(resp.verification_token, verificationCode as string))
              if (verification_response) {
                // sendValue.isEmailVerified = verification_response.isEmailVerified
                await dispatch(SignUp(sendValue, values.userType));
                setIsLoading(false);
                history.push('/dashboard');
              }
              // dispatch(userInformation());
            },
            onCancel: () => hideModal(),
            inputData: "Email"
          }));

        }
        
      } catch (error) {
        setIsLoading(false);
        console.error('Error during sign-up:', error);
      }
    },
  });


  const getUserTypeID = (userType: string) => {
    switch (userType) {
      case 'IndividualClient':
        return 1;
      case 'BusinessAdmin':
        return 2;
      case 'IndependentLawyer':
        return 6;
      case 'LawFirmAdmin':
        return 4;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-background-light dark:bg-background-dark transition-colors duration-300">
      <div className="max-w-md w-full bg-white dark:bg-gray-800 p-8 rounded-lg shadow-light-md dark:shadow-dark-md transition-shadow duration-300">
        <RegionSwitcher />
        {/* Header */}
        <div className="flex flex-col items-center mb-6">
          <i className="fa-kit fa-logo text-4xl dark:text-sky-400/100 text-primary-500 mb-2"></i>
          <h2 className="text-2xl font-heading text-gray-800 dark:text-gray-100">
            {t('Create an account')}
          </h2>
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
            <span>{t('Already have an account?')}</span>{' '}
            <button
              onClick={toggleAuthMode}
              className="text-primary-500 hover:underline"
            >
              {t('Log in')}
            </button>
          </p>
        </div>

        {/* Sign-Up Form */}
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input
              type="text"
              id="firstName"
              label={t('First Name')}
              placeholder={t('Enter your first name')}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              className={clsx(
                formik.errors.firstName && formik.touched.firstName
                  ? 'border-red-500'
                  : 'border-gray-300'
              )}
            />
            <Input
              type="text"
              id="lastName"
              label={t('Last Name')}
              placeholder={t('Enter your last name')}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              className={clsx(
                formik.errors.lastName && formik.touched.lastName
                  ? 'border-red-500'
                  : 'border-gray-300'
              )}
            />
            <div>
              <Input
                type="email"
                id="email"
                label={t('Email Address')}
                placeholder={t('you@example.com')}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                className={
                  `w-full p-2 rounded-md border focus:outline-none focus:ring-2 transition-all 
                  ${formik.errors.email && formik.touched.email
                    ? 'border-red-500 focus:ring-red-500'
                    : 'border-gray-300 focus:ring-blue-500 dark:border-gray-600 dark:focus:ring-blue-400'}`
                }
              />
              {
                formik.errors.email && formik.touched.email && <p className={clsx(
                  'text-sm mt-1',
                  formik.errors.email ? 'text-red-500' : 'text-green-500'
                )}>{formik.errors.email}</p>
              }
            </div>
            <div>
              <Input
                type="text"
                id="phoneNumber"
                label={t('Phone Number')}
                placeholder={t('Enter your phone number')}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                className={clsx(
                  formik.errors.phoneNumber && formik.touched.phoneNumber
                    ? 'border-red-500'
                    : 'border-gray-300'
                )}
              />
              {
                formik.errors.phoneNumber && formik.touched.phoneNumber && <p className={clsx(
                  'text-sm mt-1',
                  formik.errors.phoneNumber ? 'text-red-500' : 'text-green-500'
                )}>{formik.errors.phoneNumber}</p>
              }

            </div>
          </div>

          <Dropdown
            id="userType"
            label={t('Select User Type')}
            options={userTypeOptions}
            value={formik.values.userType}
            onChange={(value) => formik.setFieldValue('userType', value)}
            placeholder={t('Select User Type')}
            error={formik.touched.userType ? formik.errors.userType : undefined}
          />

          {(formik.values.userType === 'LawFirmAdmin' ||
            formik.values.userType === 'BusinessAdmin') && (
              <Input
                type="text"
                id="organizationName"
                label={t('Organization Name')}
                placeholder={t('Enter your organization name')}
                value={formik.values.organizationName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                className={clsx(
                  formik.errors.organizationName &&
                    formik.touched.organizationName
                    ? 'border-red-500'
                    : 'border-gray-300'
                )}
              />
            )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input
              type="password"
              id="password"
              label={t('Password')}
              placeholder={t('********')}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              className={clsx(
                formik.errors.password && formik.touched.password
                  ? 'border-red-500'
                  : 'border-gray-300'
              )}
            />
            <Input
              type="password"
              id="confirmPassword"
              label={t('Confirm Password')}
              placeholder={t('********')}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              className={clsx(
                formik.errors.confirmPassword && formik.touched.confirmPassword
                  ? 'border-red-500'
                  : 'border-gray-300'
              )}
            />
          </div>

          {/* Agree to Terms */}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="AgreeToTerms"
              onChange={formik.handleChange}
              checked={formik.values.AgreeToTerms}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label
              htmlFor="AgreeToTerms"
              className="ml-2 text-sm text-gray-700 dark:text-gray-300"
            >
              <Trans i18nKey="agreementText">
                I agree to the{' '}
                <a
                  href="https://pons.io/legal/terms-conditions"
                  className="text-primary-500 hover:underline"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href="https://pons.io/legal/privacy-policy"
                  className="text-primary-500 hover:underline"
                >
                  Privacy Policy
                </a>
                .
              </Trans>
            </label>
          </div>
          {formik.errors.AgreeToTerms && formik.touched.AgreeToTerms && (
            <p className="text-sm text-red-600">{formik.errors.AgreeToTerms}</p>
          )}

          {/* Submit Button */}
          <Button
            type="submit"
            variant="primary"
            disabled={isLoading}
            ariaLabel={isLoading ? t('Signing up...') : t('Sign Up')}
            className={clsx(
              isLoading ? 'opacity-50 cursor-not-allowed' : '',
              'flex items-center justify-center w-full'
            )}
          >
            {isLoading ? t('Signing up...') : t('Sign Up')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SignUpSection;
