import { t } from "i18next"; 

export const convertLongDateWithTranslation = (dateInput: string ): string => {
    // Replace the month with translated text
    return dateInput.replace(
        /\b(January|February|March|April|May|June|July|August|September|October|November|December)\b/g,
        (match) => t(match)
    );
};


export const convertShortDateWithTranslation = (dateInput: string ): string => {
    // Replace the month with translated text
    return dateInput.replace(
        /\b(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\b/g,
        (match) => t(match)
      );
};

export const convertLongShortDateWithTranslation = (dateInput: string ): string => {
    // Replace the month with translated text
    return dateInput.replace(
        /\b(January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\b/g,
        (match) => t(match)
      );
};