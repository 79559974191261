import React, { createContext, useContext, useState, useEffect } from "react";
import {
    PublicClientApplication,
    InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import { useAppDispatch } from "../store";
import { showFeedbackModal } from "../store/actions/UserFeedbackActions";
// import { accessToken } from './GoogleContext';

const CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID || "";
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || window.location.origin;

const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        redirectUri: REDIRECT_URI,
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Context interface
interface MicrosoftGraphContextProps {
    accessToken: string | null;
    isSignedIn: boolean;
    MSALsignIn: () => Promise<any>;
    signOut: () => void;
    uploadFileToOneDrive: (fileName: string, fileContent: Blob, SharedWith: any[]) => Promise<any>;
    getEditLink: (fileId: string) => Promise<string>;
    downloadFileFromOneDrive: (fileId: string) => Promise<Blob>;
    deleteFileFromOneDrive: (fileId: string) => Promise<void>;
    restrictSharing: (fileId: string) => Promise<void>;
    fetchCurrentUserDetails: () => Promise<any>;
    handleOpenMicrosoftPicker: (file: any) => Promise<void>;
    isMsalInitialized: boolean;
    unlockFileOnOneDrive: (fileId: string) => Promise<void>;
    // shareAccess: (fileId: string, Email: any) => Promise<void>
}

const defaultContext: MicrosoftGraphContextProps = {
    accessToken: null,
    isSignedIn: false,
    MSALsignIn: async () => { },
    signOut: () => { },
    uploadFileToOneDrive: async () => { },
    getEditLink: async () => "",
    downloadFileFromOneDrive: async () => new Blob(),
    deleteFileFromOneDrive: async () => { },
    restrictSharing: async () => { },
    fetchCurrentUserDetails: async () => { },
    handleOpenMicrosoftPicker: async () => { },
    isMsalInitialized: false,
    unlockFileOnOneDrive: async () => { },
    // shareAccess: async () => {} 
};
let localAccessToken: any = null
const MicrosoftGraphContext = createContext<MicrosoftGraphContextProps>(defaultContext);

export const MicrosoftGraphProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [account, setAccount] = useState<any>(null);
    const [isMsalInitialized, setIsMsalInitialized] = useState(false);
    const dispatch = useAppDispatch()

    useEffect(() => {
        const initialize = async () => {
            await msalInstance.initialize();
            setIsMsalInitialized(true);
            const accounts = msalInstance.getAllAccounts();
            if (accounts && accounts.length > 0) {
                setAccount(accounts[0]);
                setIsSignedIn(true);
                const token = await acquireToken(["Files.ReadWrite.All", "User.Read"], accounts[0]);
                setAccessToken(token);
                // accessToken = token
            }
        };
        initialize();
    }, []);

    const MSALsignIn = async () => {
        try {

            if (!isMsalInitialized) {
                await msalInstance.initialize();
                setIsMsalInitialized(true);
            }

            const loginResponse = await msalInstance.loginPopup({
                scopes: ["Files.ReadWrite.All", "User.Read"],
            });

            const account = loginResponse.account;
            setAccount(account);
            setIsSignedIn(true);

            const token = await acquireToken(["Files.ReadWrite.All", "User.Read"], account);
            setAccessToken(token);
            localAccessToken = token

            return { loginResponse, token }
        } catch (error: any) {

            let message = "An unexpected error occurred. Please try again later.";

            if (error.name === "BrowserAuthError" ||  error.errorMessage.includes("popup_window_error")) {
                message = "Sign-in process was canceled.";
            }
            else if (error.errorMessage && error.errorMessage.includes("AADB2C90091")) {
                message = "Access denied. You do not have the necessary permissions.";
            }
            else if (error.name === "NetworkError") {
                message = "Network error occurred. Please check your connection and try again.";
            }
            else if  (error.name === "ServerError"){
                message = error.errorMessage;
            }

            dispatch(showFeedbackModal({
                showModal: true,
                message: message,
                modalType: 'error',
                duration: 3000,
            }));
            return { "loginResponse": null, "token":null };
        }
    };

    const signOut = () => {
        msalInstance.logoutPopup();
        setAccessToken(null);
        // accessToken = null
        setIsSignedIn(false);
        setAccount(null);
    };

    const acquireToken = async (scopes: string[], account: any) => {
        try {

            const response = await msalInstance.acquireTokenSilent({
                scopes: scopes,
                account: account,
            });
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                const response = await msalInstance.acquireTokenPopup({
                    scopes: scopes,
                    account: account,
                });
                return response.accessToken;
            } else {
                throw error;
            }
        }
    };

    const getGraphClient = async () => {
        if (!accessToken) {
            return Client.init({
                authProvider: (done: any) => {
                    done(null, localAccessToken);
                },
            });
            // throw new Error("");
        } else {
            return Client.init({
                authProvider: (done: any) => {
                    done(null, accessToken);
                },
            });

        }
    };

    const uploadFileToOneDrive = async (fileName: string, fileContent: Blob, SharedWith: any[]): Promise<any> => {
        try {
            const client = await getGraphClient();
            let updatedSharedWith = null

            try {
                const response = await client.api(`/me/drive/root:/${fileName}`).get();
                if (response && response.id) {
                    return { fileID: response.id, updatedSharedWith: updatedSharedWith,webURL: response.webUrl };
                }
            } catch (error: any) {
                if (error.statusCode === 404) {
                    const response = await client
                        .api(`/me/drive/root:/${fileName}:/content?@microsoft.graph.conflictBehavior=replace`)
                        .put(fileContent);

                    if (!response || !response.id) {
                        throw new Error("Failed to upload file: No response or missing file ID.");
                    }
                    const mappedEmails = SharedWith.map(({ Email, Permission }) => ({
                        email: Email,
                        roles: [Permission]
                    })) || [];
                    if (mappedEmails.length > 0) {
                        const { sharedLinks } = await shareAccess(response.id, mappedEmails)

                        // ✅ Map `sharedLinks` back into `SharedWith` by matching email
                        updatedSharedWith = SharedWith.map(user => {
                            const sharedLinkEntry = sharedLinks.find(link => link.email === user.Email);
                            return {
                                ...user,
                                SharedURL: sharedLinkEntry ? sharedLinkEntry.link : null, // Add link if found
                            };
                        });

                    }

                    return { fileID: response.id, updatedSharedWith: updatedSharedWith , webURL: null};
                } else {
                    console.error(`❌ Error checking file existence:`, error);
                    throw error;
                }
            }
        } catch (error) {
            console.error("Error uploading file to OneDrive:", error);
            throw error;
        }
    };

    const getEditLink = async (fileId: string): Promise<string> => {
        const client = await getGraphClient();
        // const url = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/createLink`;
        const response = await client.api(`/me/drive/items/${fileId}/createLink`).post({
            type: 'edit',
            scope: 'anonymous' // or 'organization'
        });
        if (!response) {
            const error = await response;
            throw new Error(`Error creating link: ${error.error}`);
        }

        const data = await response;

        return data.link.webUrl;
    };

    const downloadFileFromOneDrive = async (fileId: string): Promise<Blob> => {
        try {
            const fileContent = await fetchFileContent(fileId, accessToken)

            return fileContent;
        } catch (error) {
            console.error("Error downloading file from OneDrive:", error);
            throw error;
        }
    };

    const deleteFileFromOneDrive = async (fileId: string) => {
        try {
            const url = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}`;
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({})
            });

            if (response.status === 204) {
            } else {
                const errorDetails = await response.json();
                console.error("Failed to delete file:", errorDetails.error.message);
                throw new Error(errorDetails.error.message);
            }
        } catch (error) {
            console.error("Error deleting file:", error);
            throw error;
        }
    };
    const restrictSharing = async (fileId: string): Promise<void> => {
        const client = await getGraphClient();
        const permissions = await client.api(`/me/drive/items/${fileId}/permissions`).get();

        for (const permission of permissions.value) {
            await client.api(`/me/drive/items/${fileId}/permissions/${permission.id}`).delete();
        }

    };

    const fetchCurrentUserDetails = async (): Promise<any> => {
        const client = await getGraphClient();
        const user = await client.api('/me').get();
        return user;
    };

    const fetchFileContent = async (fileId: string, token: any): Promise<Blob> => {
        try {
            const response = await fetch(
                `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken ? accessToken : localAccessToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`Failed to fetch file content: ${response.statusText}`);
            }

            const blob = await response.blob();
            return blob;
        } catch (error) {
            console.error("Error fetching file content:", error);
            throw error;
        }
    };


    const handleOpenMicrosoftPicker = async (onFileSelected: any) => {
        try {
            let allAccounts = msalInstance.getAllAccounts();
            let activeAccount = allAccounts.length > 0 ? allAccounts[0] : null;
            let activeToken: any = null;
    
            if (!activeAccount) {
                try {
                    const { loginResponse, token } = await MSALsignIn();
                    if (loginResponse) {
                        setAccount(loginResponse.account);
                        activeAccount = loginResponse.account;
                        activeToken = token;
                    }
                } catch (error) {
                    console.error("Error during MSAL sign-in:", error);
                    return;
                }
            } else {
                activeToken = accessToken;
            }
    
            if (!activeToken) {
                try {
                    activeToken = await acquireToken(["Files.ReadWrite.All"], activeAccount || account);
                    setAccessToken(activeToken);
                } catch (error) {
                    return
                }
            }
    
            // Define OneDrive Picker options
            const odOptions = {
                clientId: CLIENT_ID,
                action: "query",
                multiSelect: true,
                advanced: {
                    accessToken: activeToken,
                    queryParameters: "select=id,name,size,file,folder,photo,@microsoft.graph.downloadUrl,webUrl",
                    filter: "folder,.docx",
                    createLinkParameters: { type: "edit", scope: "organization" },
                },
                success: async (files: any) => {
                    try {
                        const selectedFile = files.value[0];
                        if (!selectedFile) {
                            console.error("No file selected");
                            // alert("No file was selected.");
                            return;
                        }
    
                        const fileContent = await fetchFileContent(selectedFile.id, activeToken);
                        const fileAsFileType = new File([fileContent], selectedFile.name, {
                            type: selectedFile.file.mimeType || "application/octet-stream",
                            lastModified: Date.now(),
                        });
    
                        onFileSelected(fileAsFileType);
                    } catch (fetchError) {
                        console.error("Error fetching file content:", fetchError);
                        alert("Error retrieving file content. Please try again.");
                    }
                },
                cancel: () => {
                    console.log("User canceled file selection.");
                },
                error: (pickerError: any) => {
                    console.error("Microsoft Picker error:", pickerError);
                    // alert("Error using Microsoft Picker. Please try again.");
                },
            };
    
            OneDrive.open(odOptions);
        } catch (generalError) {
            console.error("Unexpected error:", generalError);
            // alert("An unexpected error occurred. Please refresh and try again.");
        }
    };

    const unlockFileOnOneDrive = async (fileId: string) => {
        const url = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/unlock`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });

        if (!response.ok) {
            throw new Error(`Failed to unlock file: ${response.statusText}`);
        }

    };

    const shareAccess = async (
        fileID: string,
        emails: { email: string; roles: ("write" | "read" | "delete" | "rename")[] }[]
    ) => {
        try {
            const client = await getGraphClient();

            // Role mappings (REMOVED "share" permission to prevent resharing)
            const roleMappings: Record<string, string[]> = {
                write: ["write"], // Allows editing but not sharing
                read: ["read"], // View-only access
                delete: ["write"], // No direct delete permission, but "write" allows file modifications
                rename: ["write"], // Renaming requires write access
            };

            let sharedUsersWithLinks: { email: string; link: string }[] = [];

            for (const { email, roles } of emails) {
                let graphRoles: string[] = [];

                roles.forEach((role) => {
                    if (roleMappings[role]) {
                        graphRoles = [...new Set([...graphRoles, ...roleMappings[role]])];
                    }
                });

                // 1️⃣ Share the file with the user
                await client.api(`/me/drive/items/${fileID}/invite`).post({
                    requireSignIn: true,
                    sendInvitation: true,
                    roles: graphRoles,
                    recipients: [{ email }],
                });

                // // 2️⃣ Create a direct access link for the shared file
                const linkType = roles.includes("write") ? "edit" : "view"; // Dynamic selection
                const linkResponse = await client.api(`/me/drive/items/${fileID}/createLink`).post({
                    type: linkType, // Choose between "edit" and "view"
                    scope: "anonymous", // Ensure access is restricted within your org
                });

                if (linkResponse?.link?.webUrl) {
                    sharedUsersWithLinks.push({ email, link: linkResponse.link.webUrl });
                }
            }

            return {
                message: "File sharing completed successfully!",
                sharedLinks: sharedUsersWithLinks,
            };
        } catch (error) {
            console.error("❌ Share Error:", error);
            throw error;
        }
    };



    return (
        <MicrosoftGraphContext.Provider
            value={{
                accessToken,
                isSignedIn,
                MSALsignIn,
                signOut,
                uploadFileToOneDrive,
                getEditLink,
                downloadFileFromOneDrive,
                deleteFileFromOneDrive,
                restrictSharing,
                fetchCurrentUserDetails,
                handleOpenMicrosoftPicker,
                isMsalInitialized,
                unlockFileOnOneDrive
            }}
        >
            {children}
        </MicrosoftGraphContext.Provider>
    );
};

export const useMicrosoftGraph = () => useContext(MicrosoftGraphContext);

