// src/components/Cases/CaseAnalysis/ProgressStepper.tsx

import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MiniLoadingToCheckmark from '../shared/animations/MiniLoadingToCheckmark';
import { PiHourglassLight } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

interface ProgressStep {
  step: string;
  status: 'Pending' | 'In Progress' | 'Completed' | string; 
  messages?: string[];
}

interface ProgressStepperProps {
  steps: ProgressStep[];
}

const ProgressStepper: React.FC<ProgressStepperProps> = ({ steps }) => {
  // Optional: reorder if you do something special for "Initial Overview"
  const reorderedSteps = reorderInitialOverview(steps);

  const totalSteps = reorderedSteps.length;
  const completedSteps = reorderedSteps.filter((s) => s.status === 'Completed').length;
  const progressPercentage = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;
  const  {t} = useTranslation()

  return (
    <div className="w-full p-4">
      {/* Simple progress bar */}
      <div className="mb-6">
        <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200 dark:bg-gray-700">
          <div
            style={{ width: `${progressPercentage}%`, transition: 'width 0.5s ease-in-out' }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"
          />
        </div>
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
          {completedSteps} {t("of")} {totalSteps} {t("steps completed")}
        </p>
      </div>

      {/* Step List */}
      <div className="space-y-4">
        {reorderedSteps.map((step, index) => {
          const icon = renderStepIcon(step.status);

          // e.g. "applicable_laws" => "applicable laws"
          const stepLabel = step.step.replaceAll('_', ' ');

          return (
            <div key={index} className="flex flex-col">
              <div className="flex items-center ml-10">
                {icon}
                <p className="ml-4 text-sm font-medium capitalize">{t(stepLabel)}</p>
              </div>

              {step.messages && step.messages.length > 0 && (
                <StepMessageList messages={step.messages} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
 * Decide which icon to render for each step status.
 */
function renderStepIcon(status: string) {
  switch (status) {
    case 'Pending':
      // Yellow exclamation
      return <PiHourglassLight className="h-5 w-5 text-yellow-500" />;
    case 'In Progress':
      // Our mini spinner => check. Always pass isLoading={true}
      return (
        <MiniLoadingToCheckmark
          isLoading={true}
          spinnerSize={16}
          checkSize={16}
          transitionDuration={200} // smaller = snappier
        />
      );
    case 'Completed':
      // Our mini spinner => check, but isLoading={false}, so it shows the check
      return (
        <MiniLoadingToCheckmark
          isLoading={false}
          spinnerSize={16}
          checkSize={16}
          transitionDuration={200}
        />
      );
    default:
      // Some fallback or "Pending" if unknown
      return <PiHourglassLight className="h-5 w-5 text-yellow-500" />;
  }
}

/**
 * If you want "CaseAnalysisStart" or "Initial Overview" forced as first step, do that here.
 */
function reorderInitialOverview(steps: ProgressStep[]): ProgressStep[] {
  // Example logic: find step "caseanalysisstart" => rename => unshift
  const copy = [...steps];
  const idx = copy.findIndex((s) => s.step.toLowerCase() === 'caseanalysisstart');
  if (idx > -1) {
    copy[idx].step = 'Initial Overview';
    const [overview] = copy.splice(idx, 1);
    copy.unshift(overview);
  }
  return copy;
}

interface StepMessageListProps {
  messages: string[];
}
const StepMessageList: React.FC<StepMessageListProps> = ({ messages }) => {
  return (
    <div className="flex flex-col space-y-1 ml-16 mt-1">
      {messages.map((msg, i) => (
        <StepMessage key={i} message={msg} />
      ))}
    </div>
  );
};

interface StepMessageProps {
  message: string;
}
const StepMessage: React.FC<StepMessageProps> = ({ message }) => {
  const [displayMessage, setDisplayMessage] = useState(message);
  const {t} = useTranslation()
  useEffect(() => {
    setDisplayMessage(message);
  }, [message]);

  return (
    <Transition
      appear
      show
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div className="text-xs text-gray-500 dark:text-gray-300 transition-opacity duration-500 prose dark:!prose-invert">
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{t(displayMessage)}</ReactMarkdown>
      </div>
    </Transition>
  );
};

export default ProgressStepper;
