

import { useEffect, useState, useRef } from "react";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

interface UseMicrophoneProps {
  speechToken: string | null; // ✅ Renamed for clarity
  serviceEndpoint: string | null; // ✅ Using custom endpoint instead of region
  language: string;
}

const useMicrophone = ({
  speechToken,
  serviceEndpoint,
  language,
}: UseMicrophoneProps) => {
  const [finalTranscript, setFinalTranscript] = useState("");
  const [interimTranscript, setInterimTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recognizerRef = useRef<SpeechSDK.SpeechRecognizer | null>(null);
  console.log(speechToken)

  useEffect(() => {
    if (!speechToken) {
      setError("Azure Speech SDK token or endpoint is missing.");
      return;
    }
    else{
      setError(null)
    }
    // ✅ Cleanup any existing recognizer before reinitializing
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync();
      recognizerRef.current.close();
      recognizerRef.current = null;
    }

    try {
      const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
        speechToken,
        "custom"
      );

      // ✅ Set correct Speech-to-Text endpoint
      speechConfig.setProperty(
        SpeechSDK.PropertyId.SpeechServiceConnection_Endpoint,
        `https://ponsspeech.cognitiveservices.azure.com/speech/recognition/conversation/cognitiveservices/v1`
      );

      speechConfig.speechRecognitionLanguage = language;
      speechConfig.enableDictation(); // Improves punctuation and sentence structure

      const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
      recognizerRef.current = recognizer;

      recognizer.recognizing = (s, e) => {
        setInterimTranscript(e.result.text);
      };

      recognizer.recognized = (s, e) => {
        if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
          setFinalTranscript((prev) => `${prev} ${e.result.text}`);
          setInterimTranscript(""); // Clear interim transcript on final recognition
        } else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
          console.log("No speech recognized.");
        }
      };

      recognizer.canceled = (s, e) => {
        console.error(`Canceled: Reason=${e.reason}`);
        if (e.reason === SpeechSDK.CancellationReason.Error) {
          console.error(`Error details: ${e.errorDetails}`);
          setError(`Speech recognition error: ${e.errorDetails}`);
        }
        stopListening(); // ✅ Ensure the recognizer stops on error
      };

      recognizer.sessionStopped = (s, e) => {
        stopListening(); // ✅ Properly clean up session
      };
    } catch (err) {
      console.error("Error initializing SpeechRecognizer:", err);
      setError("Failed to initialize Speech Recognition.");
    }

    return () => {
      if (recognizerRef.current) {
        recognizerRef.current.close();
        recognizerRef.current = null;
      }
    };
  }, [speechToken, serviceEndpoint, language]); // 🔄 Reinitialize on token/endpoint change

  const startListening = () => {
    if (error) {
      console.warn("❌ Cannot start listening due to an error:", error);
      return;
    }

    setFinalTranscript("");
    setInterimTranscript("");
    setError(null);
    setIsListening(true);

    if (recognizerRef.current) {
      recognizerRef.current.startContinuousRecognitionAsync(
        () => console.log("🎤 Listening started..."),
        (err) => {
          console.error("❌ Failed to start recognition.", err);
          setError("Failed to start speech recognition.");
          setIsListening(false);
        }
      );
    } else {
      setError("Speech recognizer not initialized.");
    }
  };

  const stopListening = () => {
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync(
        () => {
          setIsListening(false);
          setInterimTranscript("");
          console.log("🎤 Listening stopped.");
        },
        (err) => {
          console.error("❌ Failed to stop recognition.", err);
          setError("Failed to stop speech recognition.");
          setIsListening(false);
        }
      );
    }
  };

  return {
    finalTranscript,
    interimTranscript,
    isListening,
    startListening,
    stopListening,
    error,
  };
};

export default useMicrophone;