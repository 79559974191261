import apis from "../../utils/apis";
import { AppThunk, SET_SPEECH_CONFIG, SET_VOICE } from "../types";
import { handleApiError } from "./utils/utils";


interface SetVoiceAction {
  type: typeof SET_VOICE;
  payload: { voice: string };
}

interface SetSpeechConfigAction {
  type: typeof SET_SPEECH_CONFIG;
  payload: { speechKey: string | null; serviceRegion: string; voice: string };
}

export type SpeechActions = SetVoiceAction | SetSpeechConfigAction;

export const setVoice = (voice: string): SetVoiceAction => ({
  type: SET_VOICE,
  payload: { voice },
});

export const setSpeechConfig = (config: { speechKey: string | null; serviceRegion: string; voice: string }): SetSpeechConfigAction => ({
  type: SET_SPEECH_CONFIG,
  payload: config,
});


export const getSpeechToken = (): AppThunk => async (dispatch) => {
  try {
    const response = await apis.get("/get-speech-token");
    if (response && response.status === 200) {
     return response.data
    }else{
      handleApiError(response, dispatch)
      return null
    }
  } catch (error) {
    console.error("Error completing task", error);
    handleApiError(error, dispatch)
    return null
  }
}

