import React, { useEffect, useState } from 'react';
import Button from '../../../shared/TailwindComponents/Button';
import Badge from '../../../shared/TailwindComponents/Badge';
import { generateMemo } from '../../../../store/actions/LegalServices/memoActions';
import { useAppDispatch } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { PiSparkleLight } from 'react-icons/pi';
import MemoDisplay from './MemoDisplay';
import { LoadingToCheckmark } from '../../../shared/animations/LoadingToCheckmark';

interface MemoProps {
  caseId: string;
  localMemo: string;
  isAnalysisCompleted?: boolean | null;
  setGeneratingMemo: (val: boolean) => void;
  loadCaseSummary: () => void;
  loadMemo: () => void;
}

const Memo: React.FC<MemoProps> = ({
  caseId,
  localMemo,
  isAnalysisCompleted,
  setGeneratingMemo,
  loadCaseSummary,
  loadMemo,
}) => {
  const dispatch = useAppDispatch();
  const [memo, setMemo] = useState(localMemo || '');
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setMemo(localMemo);
  }, [localMemo]);

  const handleGenerateMemo = async () => {
    setIsGenerating(true);
    setGeneratingMemo(true);
    await dispatch(generateMemo(caseId));
    await loadMemo();
    await loadCaseSummary();
    setIsGenerating(false);
    setGeneratingMemo(false);
  };

  if (!isAnalysisCompleted) {
    return (
      <div className="mt-4 flex justify-center">
        <Badge color="gray">
          {t('Please complete the case analysis before generating the Memo.')}
        </Badge>
      </div>
    );
  }

  if (isGenerating) {
    return (
      <div className="mt-4 flex justify-center">
        <LoadingToCheckmark
          isLoading={isGenerating}
          spinnerDuration={1500}
          postCheckmarkDelay={1200}
          onAnimationDone={() => setIsGenerating(false)}
          serviceName={t('Memo Generation')}
        />
      </div>
    );
  }

  if (memo) {
    return <MemoDisplay memo={memo} />;
  }

  return (
    <div className="mt-4 flex flex-col items-center gap-4">
      <Badge color="gray">{t('No memo has been generated for this case.')}</Badge>
      <Button
        onClick={handleGenerateMemo}
        size="small"
        variant="primary"
      >
        {t('Generate Memo')}
        <PiSparkleLight size={16} className="ml-2" />
      </Button>
    </div>
  );
};

export default Memo;