// src/components/Settings/TaskSettings.tsx
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTaskPreferences, updateTaskPreferences } from '../../../store/actions/TasksActions';
import { useAppDispatch } from '../../../store';
import Toggle from '../../shared/TailwindComponents/Toggle';

const TaskSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [preferences, setPreferences] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPreferences = async () => {
      const prefs = await dispatch(getTaskPreferences());
      if (prefs) setPreferences(prefs);
      setLoading(false);
    };
    fetchPreferences();
  }, [dispatch]);

  const handleToggle = async (type: string, newValue: boolean) => {
    setPreferences((prev) => ({ ...prev, [type]: newValue }));
    const updatedPreferences = { [type]: newValue };
    const response = await dispatch(updateTaskPreferences(updatedPreferences));
    if (!response) {
      setPreferences((prev) => ({ ...prev, [type]: !newValue }));
      console.error(`Failed to update preference for ${type}`);
    }
  };

  if (loading) {
    return (
      <div className="space-y-4">
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className="flex items-center justify-between">
            <div className="w-1/3 h-4 bg-gray-200 dark:bg-gray-700 rounded animate-fadePulse"></div>
            <div className="w-11 h-6 bg-gray-200 dark:bg-gray-700 rounded-full animate-fadePulse"></div>
          </div>
        ))}
      </div>
    );
  }

  const taskTypes = ['CaseTasks', 'ClientTasks', 'CompanyTasks', 'ProjectTasks', 'TeamTasks'];

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
        {t('Task Settings')}
      </h3>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {t('Manage your AI task generation preferences for different categories.')}
      </p>
      <div className="space-y-4">
        {taskTypes.map((type) => (
          <div key={type} className="flex items-center justify-between">
            <span className="text-sm text-gray-700 dark:text-gray-200">{t(type)}</span>
            <Toggle
              enabled={preferences[type] ?? true}
              onToggle={() => handleToggle(type, !preferences[type])} // Toggle the current value
              value={preferences[type] ?? true} // Reflect the current state
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskSettings;