import { useTranslation } from 'react-i18next';
import { FaBan } from 'react-icons/fa';

const CancelPage = () => {
    const {t} = useTranslation()
    return (
        <div className="flex flex-center justify-center items-center">
            <div className="w-96 h-80 bg-yellow-100 dark:bg-yellow-900 p-6 rounded-lg shadow-lg flex flex-col items-center  animate-slide-up">
                <div className="animate-bounce-in bg-yellow-500 rounded-full p-4 shadow-lg">
                    <FaBan className="text-white text-6xl" />
                </div>

                <h1 className="text-2xl font-bold text-yellow-800 dark:text-yellow-300">{t("Payment Canceled")}</h1>
                <p className="text-gray-700 dark:text-gray-300 text-center">
                    {t("Your payment was canceled. Please try again if needed.")}
                </p>
            </div>

            {/* Tailwind Animations */}
            <style>{`
                @keyframes slide-up {
                    from {
                        transform: translateY(100%);
                        opacity: 0;
                    }
                    to {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                .animate-slide-up {
                    animation: slide-up 0.7s ease-out forwards;
                }

                @keyframes bounce-in {
                    0% {
                        transform: scale(0);
                    }
                    50% {
                        transform: scale(1.2);
                    }
                    100% {
                        transform: scale(1);
                    }
                }

                .animate-bounce-in {
                    animation: bounce-in 0.5s ease-out forwards;
                }
            `}</style>
        </div>


    );
};

export default CancelPage;
