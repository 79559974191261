import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AIHeader from './AIHeader';
import AISidebar from './AISidebar';
import ChatBot from './ChatBot';
import PromptBar from './PromptBar';
import { ChatHistoryItem, ChatHistoryResponse, Message } from './types';
import {
  addChatGroup,
  callServiceAPI,
  chatGroupApi,
  ChatGroupData,
  chatHistoryApi,
  Citation,
  deleteChatGroup,
  getAiModels,
  getUserID,
  getUserType,
  setChatGroupIDStore,
  stopOrchestratorStream,
  uploadFiles,
} from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { FileProps } from '../Documents/types';
import { fetchDocumentContent } from '../../store/actions/DocumentsAction';
import DocumentViewer from './DocumentViewer';
import { hideModal, showModal } from '../../store/actions/modalActions';
import { fetchAILanguages } from '../../store/actions/DropdownActions';
import { getAISettings } from '../../store/actions/DataAction';
import { config } from '../../utils/config';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { chatWithIndex, fetchIndexes } from '../../store/actions/AIStudioAction';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import debounce from 'lodash.debounce';
import CitationDocumentViewer from './CitationDocumentViewer';
import clsx from 'clsx';
import ChatSkeleton from './ChatSkeleton';


function addStepIfNew(progress: string[], newStep: string) {
  if (progress.includes(newStep)) return progress;
  return [...progress, newStep];
}

const getWelcomeMessage = (t: (key: string) => string, service: 'research' | 'fileAnalysis' | 'myAgents'): string => {
  switch (service) {
    case 'research':
      return t('Any legal queries?');
    case 'fileAnalysis':
      return t('Any files to upload?');
    case 'myAgents':
      return t('Select your custom agent');
    default:
      return t('How can I help you today?');
  }
};

interface FinalResponse {
  final_response: string;
  chat_name?: string;
  chat_group_id?: string;
  citations?: Citation[];
  chat_id?: string;
}

interface SSEData {
  init?: { chat_group_id: string; chat_id?: string };
  step?: string;
  final?: string | FinalResponse;
}

// Memoized components
const MemoizedChatBot = React.memo(ChatBot);
const MemoizedPromptBar = React.memo(PromptBar);

const ChatInterface: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [chatName, setChatName] = useState('');
  const [shouldTypeChatName, setShouldTypeChatName] = useState(false);
  const speechToken = config.azure.speechKey;
  const serviceRegion = config.azure.speechRegion;

  type ServiceType = 'research' | 'fileAnalysis' | 'myAgents';
  const [selectedService, setSelectedService] = useState<ServiceType>('research');
  const [selectedIndexModelId, setSelectedIndexModelId] = useState<string | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [files, setFiles] = useState<(File | null)[]>([]);
  const [hasSentFiles, setHasSentFiles] = useState(false);
  const [currentView, setCurrentView] = useState<'Folders' | 'Projects' | 'ChatHistory' | 'AISettings'>('ChatHistory');
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const [pendingUrl, setPendingUrl] = useState<string | null>(null);
  const { setBreadcrumbs } = useBreadcrumbs();

  const chatGroupID = useSelector((state: RootState) => state.model.chatGroupID);
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const userId = getUserID();
  const userType = getUserType();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [myCustomIndexes, setMyCustomIndexes] = useState<any[]>([]);
  const { chatGroupId: chatGroupIdFromUrl } = useParams<{ chatGroupId?: string }>();
  const history = useHistory();
  const isSwitchingChat = useRef(false);
  const [isInitializingChat, setIsInitializingChat] = useState(false);
  const [targetGroupId, setTargetGroupId] = useState<string | null>(null);
  const [isCitationViewerOpen, setIsCitationViewerOpen] = useState(false);
  const [selectedCitationUrl, setSelectedCitationUrl] = useState<string | null>(null);
  const [isStreamStopped, setIsStreamStopped] = useState(false);
  const eventSourceRef = useRef<EventSourcePolyfill | null>(null);
  const currentChatId = useRef<string | null>(null); 
  const [isStopping, setIsStopping] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const getGreeting = (t: (key: string) => string): string => {
    const hour = new Date().getHours();
    if (hour < 12) return t(`Good Morning, ${userInfo.FirstName || ''}`);
    if (hour < 17) return t(`Good Afternoon, ${userInfo.FirstName || ''}`);
    if (hour < 20) return t(`Good Evening, ${userInfo.FirstName || ''}`);
    return t(`Good Night, ${userInfo.FirstName || ''}`);
  };

  const fixHeaders = (text: string) => {
    text = text.replace(/(^|\n)(#+)(?!\s)/g, '$1$2 ');
    text = text.replace(/(^|\n)(#+ [^\n]+)([^\n])/g, '$1$2\n$3');
    return text;
  };

  useEffect(() => {
    dispatch(fetchAILanguages());
    dispatch(getAISettings());
    dispatch(fetchIndexes());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setBreadcrumbs([]); 
    };
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (isSwitchingChat.current || isInitializingChat) return;
    if (chatGroupIdFromUrl && chatGroupIdFromUrl !== chatGroupID) {
      debouncedHandleSelectChatGroup(chatGroupIdFromUrl);
    } else if (!chatGroupIdFromUrl && chatGroupID) {
      dispatch(setChatGroupIDStore(''));
      setMessages([]);
      setChatName('');
      setFiles([]);
      setHasSentFiles(false);
      setBreadcrumbs([{ name: 'AI Assistant', href: '/ai-assistant', current: true }]);
    }
  }, [chatGroupIdFromUrl, chatGroupID, dispatch, messages.length, isInitializingChat]);

  useEffect(() => {
    if (isSidebarOpen && isCitationViewerOpen) setIsCitationViewerOpen(false);
  }, [isSidebarOpen]);

  useEffect(() => {
    if (pendingUrl) {
      history.push(pendingUrl);
      setPendingUrl(null);
    }
  }, [pendingUrl, history]);

  const handleToggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleDocumentSelect = (index: number, file: File | null) => {
    setFiles((prev) => {
      const newFiles = [...prev];
      newFiles[index] = file;
      return newFiles;
    });
  };

const handleSelectChatGroup = async (grpId: string) => {
  if (grpId === chatGroupID || isSwitchingChat.current) return;
  isSwitchingChat.current = true;
  setIsLoadingHistory(true); 
  dispatch(setChatGroupIDStore(grpId));
  setIsTyping(false);
  setMessages([]);

  const abortController = new AbortController();
  const signal = abortController.signal;

  try {
    const response: ChatHistoryResponse = await dispatch(chatHistoryApi('Orchestrator', grpId, signal));
    let newMessages: Message[] = [];
    let newChatName = 'Untitled Chat';

    if (response && response.History && response.History.length > 0) {
      newMessages = response.History.flatMap((item: any) => {
        const messages: Message[] = [];
        if (item.Prompt) {
          messages.push({
            id: item.ChatID + '_user',
            text: item.Prompt,
            sender: 'user',
            timestamp: new Date(`${item.Date}T${item.Time}`),
            disableTypingEffect: true,
            attachedFiles: item.AttachedFiles || [],
          });
        }
        const replies = item.Replies && item.Replies.length > 0 ? item.Replies : [{
          Reply: item.Reply || '',
          Citations: item.Citations || [],
          Timestamp: `${item.Date}T${item.Time}`
        }];
        const sortedReplies = replies.sort((a: any, b: any) => 
          new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime()
        );
        const latestReply = sortedReplies[sortedReplies.length - 1];
        messages.push({
          id: item.ChatID + '_assistant',
          text: latestReply.Reply || '',
          sender: 'assistant',
          timestamp: new Date(latestReply.Timestamp),
          disableTypingEffect: true,
          citations: latestReply.Citations || [],
          isFlowDone: true,
          responses: sortedReplies.map((r: any) => ({
            text: r.Reply,
            citations: r.Citations,
            timestamp: r.Timestamp
          })),
          currentResponseIndex: sortedReplies.length - 1,
        });
        return messages;
      });
      newChatName = response.History[0].ChatTitle || 'Untitled Chat';
    }

    setMessages(newMessages);
    setChatName(newChatName);
    setBreadcrumbs([
      { name: 'AI Assistant', href: '/ai-assistant', current: false },
      { name: newChatName, href: `/ai-assistant/${grpId}`, current: true },
    ]);
    history.push(`/ai-assistant/${grpId}`);
  } catch (error) {
    console.error('Error fetching chat group history:', error);
    setMessages([]);
    setChatName('');
    history.push('/ai-assistant');
    setBreadcrumbs([{ name: 'AI Assistant', href: '/ai-assistant', current: true }]);
  } finally {
    isSwitchingChat.current = false;
    setIsLoadingHistory(false); 
  }
};

  const debouncedHandleSelectChatGroup = useCallback(debounce(handleSelectChatGroup, 100), [
    chatGroupID,
    dispatch,
    setMessages,
    setChatName,
    setBreadcrumbs,
    setIsTyping,
  ]);

  const handleDeleteChat = (grpId: string) => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this chat?'),
        subMsg: t('This action cannot be undone.'),
        onConfirm: async () => {
          try {
            dispatch(hideModal());
            await dispatch(deleteChatGroup(grpId));
            setMessages([]);
            dispatch(setChatGroupIDStore(''));
            setPendingUrl('/ai-assistant');
            setBreadcrumbs([{ name: 'AI Assistant', href: '/ai-assistant', current: true }]);
            dispatch(chatGroupApi('Orchestrator'));
          } catch (err) {
            console.error('Failed to delete chat group:', err);
          }
        },
        onCancel: () => dispatch(hideModal()),
        showModal: true,
      })
    );
  };

  const handleNewChat = () => {
    setMessages([]);
    dispatch(setChatGroupIDStore(''));
    setHasSentFiles(false);
    setFiles([]);
    setChatName('');
    history.replace('/ai-assistant');
    setBreadcrumbs([{ name: 'AI Assistant', href: '/ai-assistant', current: true }]);
  };

  const handleFileSelect = async (f: FileProps) => {
    try {
      const content = await dispatch(fetchDocumentContent(f.FileID));
      if (content) setSelectedFile({ ...f, content });
    } catch (error) {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Could not load file content. Please contact support.'),
          showModal: true,
          duration: 3000,
        })
      );
    }
  };

  const handleSendMessage = async (text: string) => {
    if (!text.trim()) return;
    const userMsgId = Date.now().toString();
    const userMsg: Message = {
      id: userMsgId,
      text,
      sender: 'user',
      timestamp: new Date(),
      attachedFiles: [],
    };
    setMessages((prev) => [...prev, userMsg]);
    setIsTyping(true);

    try {
      if (selectedService === 'research') {
        handleSendMessageOrchestrator(text, (Date.now() + 1).toString(), userMsgId);
      } else if (selectedService === 'fileAnalysis') {
        const response = await callServiceAPI(
          'ContractAdvisor',
          { Prompt: text },
          hasSentFiles ? [] : files.filter((f) => f !== null) as File[],
          chatGroupID
        );
        let finalText = response?.UnifiedResponse || response?.ContractAnalysisResponse || 'No response';
        const citations = response?.LawCitations || response?.Citations || [];
        if (response?.ChatGroupID && !chatGroupID) {
          dispatch(setChatGroupIDStore(response.ChatGroupID));
          setPendingUrl(`/ai-assistant/${response.ChatGroupID}`);
          dispatch(
            addChatGroup({
              ChatGroupID: response.ChatGroupID,
              ChatTitle: 'File Analysis Chat',
              AIType: 'ContractAnalysis',
              CreationDate: '',
              LastModified: '',
              UserID: userId,
              id: response.ChatID || '',
              status: '',
            })
          );
          setBreadcrumbs([
            { name: 'AI Assistant', href: '/ai-assistant', current: false },
            { name: 'File Analysis Chat', href: `/ai-assistant/${response.ChatGroupID}`, current: true },
          ]);
        }
        setMessages((prev) =>
          prev.map((m) =>
            m.id === (Date.now() + 1).toString()
              ? { ...m, text: finalText, isTyping: false, isFlowDone: true, citations }
              : m
          )
        );
        setHasSentFiles(true);
        setFiles([]);
        setIsTyping(false);
      } else if (selectedService === 'myAgents') {
        if (!selectedIndexModelId) {
          setMessages((prev) =>
            prev.map((m) =>
              m.id === (Date.now() + 1).toString()
                ? {
                    ...m,
                    text: 'No custom agent selected. Please pick an agent from My Agents.',
                    isTyping: false,
                    isFlowDone: true,
                  }
                : m
            )
          );
          setIsTyping(false);
          return;
        }
        const chatResponse = await dispatch(chatWithIndex(selectedIndexModelId, text));
        const finalText = chatResponse || t('No response');
        setMessages((prev) =>
          prev.map((m) =>
            m.id === (Date.now() + 1).toString() ? { ...m, text: finalText, isTyping: false, isFlowDone: true } : m
          )
        );
        setIsTyping(false);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prev) =>
        prev.map((m) =>
          m.id === (Date.now() + 1).toString() ? { ...m, text: 'An error occurred. Please try again.', isTyping: false } : m
        )
      );
      setIsTyping(false);
    }
  };

  const handleSendMessageOrchestrator = (
    prompt: string,
    placeholderId: string,
    userMsgId: string,
    regenerateChatId?: string
  ) => {
    setIsInitializingChat(true);
    const tokenItem = localStorage.getItem('token') || sessionStorage.getItem('token');
    const token = tokenItem ? JSON.parse(tokenItem).token : '';
    const groupParam = chatGroupID ? `&GroupID=${chatGroupID}` : '';
    const regenerateParam = regenerateChatId ? `&regenerate_chat_id=${regenerateChatId}` : '';
    const sseUrl = `${config.baseUrl}/ai/orchestrator/sse?Prompt=${encodeURIComponent(prompt)}${groupParam}${regenerateParam}`;

if (!regenerateChatId) {
    const placeholderMsg: Message = {
      id: placeholderId,
      text: '',
      sender: 'assistant',
      timestamp: new Date(),
      isTyping: true,
      progress: ['Connecting to AI service...'],
      citations: [],
      isFlowDone: false,
    };
    setMessages((prev) => [...prev, placeholderMsg]);
  } else {
    setMessages((prev) => {
      const filteredMessages = prev.filter((m) => m.id !== `${regenerateChatId}_assistant`);
      const newPlaceholderMsg: Message = {
        id: placeholderId,
        text: '',
        sender: 'assistant',
        timestamp: new Date(),
        isTyping: true,
        progress: ['Regenerating response...'],
        citations: [],
        isFlowDone: false,
      };
      return [...filteredMessages, newPlaceholderMsg];
    });
  }

    const es = new EventSourcePolyfill(sseUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });
    eventSourceRef.current = es;

    es.onmessage = (evt) => {
      if (isStopping) return; 
    
      let data: SSEData;
      try {
        data = JSON.parse(evt.data) as SSEData;
      } catch (e) {
        console.error('Failed to parse SSE event data:', evt.data, e);
        setMessages((prev) =>
          prev.map((m) =>
            m.id === (regenerateChatId ? `${regenerateChatId}_assistant` : placeholderId)
              ? { ...m, text: 'Error: Invalid response format from server.', isTyping: false, isFlowDone: true }
              : m
          )
        );
        return;
      }

      if (data.init) {
        const chatGroupId = data.init.chat_group_id;
        const chatId = data.init.chat_id;
        currentChatId.current = chatId as string;
        dispatch(setChatGroupIDStore(chatGroupId));
        history.push(`/ai-assistant/${chatGroupId}`);
        setMessages((prev) =>
          prev.map((m) =>
            m.id === placeholderId ? { ...m, id: `${chatId}_assistant` } : m
          )
        );
        const validFiles = files.filter((f) => f !== null) as File[];
        if (validFiles.length > 0) {
          (dispatch(uploadFiles(validFiles, chatGroupId)) as unknown as Promise<any>).then((response) => {
            console.log('Files uploaded successfully:', response);
            const attachedFiles = validFiles.map((file) => ({
              filename: file.name,
              blob_path: `${userId}/${chatGroupId}/${file.name}`,
            }));
            setMessages((prev) =>
              prev.map((m) =>
                m.id === userMsgId ? { ...m, attachedFiles } : m
              )
            );
            fetch(`${config.baseUrl}/ai/attach_files`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({ chatId, files: attachedFiles }),
            })
              .then(() => console.log('Files attached to message in backend'))
              .catch((error) => console.error('Error attaching files to message:', error));
            setFiles([]);
          }).catch((error: any) => {
            console.error('Error uploading files:', error);
            setMessages((prev) =>
              prev.map((m) =>
                m.id === (regenerateChatId ? `${regenerateChatId}_assistant` : `${chatId}_assistant`)
                  ? { ...m, text: `Error uploading files: ${error.message}`, isTyping: false, isFlowDone: true }
                  : m
              )
            );
          });
        }
      } else if (data.step) {
        const stepText = data.step as string;
        const targetId = regenerateChatId ? `${regenerateChatId}_assistant` : `${currentChatId.current || placeholderId}_assistant`;
    
            if (stepText.toLowerCase().includes('process stopped')) {
              setMessages((prev: any) =>
                prev.map((m: any) =>
                  m.id === targetId
                    ? { ...m, isTyping: false, isFlowDone: true, isStopped: true, text: m.text || '' }
                    : m
                )
              );
          es.close();
          eventSourceRef.current = null;
          setIsInitializingChat(false);
          setIsTyping(false);
          setIsStopping(false);
        } else {
          setMessages((prev) =>
            prev.map((m) =>
              m.id === targetId
                ? { ...m, progress: addStepIfNew(m.progress || [], stepText) }
                : m
            )
          );
        }
      } else if (data.final) {
        let finalData = typeof data.final === 'string' ? JSON.parse(data.final) : data.final;
        let { final_response, chat_name, chat_group_id, chat_id, citations } = finalData;
        const assistantMsgId = chat_id ? `${chat_id}_assistant` : placeholderId;
        final_response = final_response
            .replace(/```json\s*/g, '')
            .replace(/```/g, '')
            .trim();
    
        setMessages((prev) =>
          prev.map((m) =>
            m.id === assistantMsgId
              ? { ...m, text: final_response, citations: citations || [], isTyping: false, isFlowDone: true }
              : m
          )
        );
    
        if (chat_group_id) {
          const newChatName = chat_name && chat_name !== "Orchestrator (Placeholder)" 
            ? chat_name 
            : "Untitled Chat"; // Fallback if placeholder is received
          setChatName(newChatName);
          setShouldTypeChatName(true);
          setBreadcrumbs([
            { name: "Legal Agents", href: "/ai-assistant", current: false },
            { name: newChatName, href: `/ai-assistant/${chat_group_id}`, current: true },
          ]);
        }
        dispatch(chatGroupApi('Orchestrator'));
        dispatch(getAiModels());
        setIsInitializingChat(false);
        setIsTyping(false);
      }
    };

    es.addEventListener('done', () => {
      setIsInitializingChat(false);
      es.close();
      eventSourceRef.current = null;
    });

    es.onerror = (err) => {
      console.error('SSE error:', err);
      setMessages((prev) =>
        prev.map((m) =>
          m.id === (regenerateChatId ? `${regenerateChatId}_assistant` : placeholderId)
            ? { ...m, text: 'Error receiving SSE. Please try again.', isTyping: false, isFlowDone: true }
            : m
        )
      );
      setIsInitializingChat(false);
      setIsTyping(false);
      es.close();
      eventSourceRef.current = null;
    };
  };

const handleStopStream = async () => {
  setIsStopping(true); 
  setIsTyping(false);  
  try {
    await dispatch(stopOrchestratorStream({ group_id: chatGroupID }));
    console.log("Stream stopped successfully");
    setMessages((prev: any) => {
      const lastAssistantMsg = prev.findLast((m: any) => m.sender === 'assistant' && m.isTyping);
      if (lastAssistantMsg) {
        return prev.map((m: any) =>
          m.id === lastAssistantMsg.id
            ? { ...m, isTyping: false, isFlowDone: true, isStopped: true, text: m.text || '' }
            : m
        );
      }
      return prev;
    });
    setIsStopping(false); 
  } catch (error) {
    console.error('Error stopping stream:', error);
    setIsStopping(false); 
  }
};

  const handleOpenCitation = (url: string) => {
    setSelectedCitationUrl(url);
    setIsCitationViewerOpen(true);
  };

  const debouncedHandleSendMessage = useCallback(debounce(handleSendMessage, 300), [
    selectedService,
    chatGroupID,
    files,
    dispatch,
    history,
    setMessages,
    setIsTyping,
  ]);

  const handleRegenerate = (assistantMsgId: string) => {
    const chatId = assistantMsgId.split('_')[0];
    const assistantMsgIndex = messages.findIndex((m) => m.id === assistantMsgId);
    if (assistantMsgIndex > 0) {
      const userMsg = messages[assistantMsgIndex - 1];
      if (userMsg.sender === 'user') {
        const newPlaceholderId = Date.now().toString(); 
        handleSendMessageOrchestrator(userMsg.text, newPlaceholderId, userMsg.id as string, chatId);
      }
    }
  };

  const isShowingSkeleton = isLoadingHistory && messages.length === 0;
  const noMessagesYet = messages.length === 0 && !isTyping && !isLoadingHistory && !isInitializingChat;
return (
  <div className="flex overflow-hidden h-full w-full relative bg-white dark:bg-black">
    <div className="flex flex-col flex-1 w-full relative">
      <AIHeader
        onToggleSidebar={handleToggleSidebar}
        currentView={'ChatHistory'}
        setCurrentView={() => {}}
        onDeleteChat={handleDeleteChat}
        onNewChat={handleNewChat}
        selectedService={selectedService}
        chatGroupID={chatGroupID || ''}
        chatName={chatName}
        shouldType={shouldTypeChatName}
      />
      {isShowingSkeleton ? (
        <div className="flex flex-1 overflow-hidden">
          <div className="flex flex-1 flex-col overflow-hidden">
            <ChatSkeleton />
            <div className="w-2/3 self-center rounded-full">
              <MemoizedPromptBar
                onSend={debouncedHandleSendMessage}
                speechToken={speechToken}
                serviceRegion={serviceRegion}
                aiSettings={aiSettings}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                myCustomIndexes={myCustomIndexes}
                selectedIndexModelId={selectedIndexModelId}
                setSelectedIndexModelId={setSelectedIndexModelId}
                chatGroupID={chatGroupID || ''}
                files={files}
                onFileSelect={handleDocumentSelect}
                hasSentFiles={hasSentFiles}
                setHasSentFiles={setHasSentFiles}
                setFiles={setFiles}
                isTyping={isTyping || isInitializingChat || isStopping}
                onStop={handleStopStream}
              />
            </div>
          </div>
        </div>
      ) : noMessagesYet ? (
        <div className="flex-1 flex flex-col items-center justify-center">
          <h1 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">{getGreeting(t)}</h1>
          <h2 className="text-2xl mb-2 font-semibold text-gray-500 dark:text-gray-300">{getWelcomeMessage(t, selectedService)}</h2>
          <div className="w-full max-w-xl rounded-full mt-5">
            <MemoizedPromptBar
              onSend={debouncedHandleSendMessage}
              speechToken={speechToken}
              serviceRegion={serviceRegion}
              aiSettings={aiSettings}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              myCustomIndexes={myCustomIndexes}
              selectedIndexModelId={selectedIndexModelId}
              setSelectedIndexModelId={setSelectedIndexModelId}
              chatGroupID={chatGroupID || ''}
              files={files}
              onFileSelect={handleDocumentSelect}
              hasSentFiles={hasSentFiles}
              setHasSentFiles={setHasSentFiles}
              setFiles={setFiles}
              isTyping={isTyping || isInitializingChat || isStopping}
              onStop={handleStopStream}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-1 overflow-hidden">
          <div className="flex flex-1 flex-col overflow-hidden">
            <MemoizedChatBot
              messages={messages}
              setMessages={setMessages}
              isTyping={isTyping}
              showPrompts={false}
              onOpenCitation={handleOpenCitation}
              onSendMessage={debouncedHandleSendMessage}
              onFileSelect={() => {}}
              files={files}
              speechToken={speechToken}
              serviceRegion={serviceRegion}
              aiSettings={aiSettings}
              className="flex-1 overflow-hidden"
              onRegenerate={handleRegenerate}
            />
            <div className="w-2/3 self-center rounded-full mb-1">
              <MemoizedPromptBar
                onSend={debouncedHandleSendMessage}
                speechToken={speechToken}
                serviceRegion={serviceRegion}
                aiSettings={aiSettings}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                myCustomIndexes={myCustomIndexes}
                selectedIndexModelId={selectedIndexModelId}
                setSelectedIndexModelId={setSelectedIndexModelId}
                chatGroupID={chatGroupID || ''}
                files={files}
                onFileSelect={handleDocumentSelect}
                hasSentFiles={hasSentFiles}
                setHasSentFiles={setHasSentFiles}
                setFiles={setFiles}
                isTyping={isTyping || isInitializingChat || isStopping}
                onStop={handleStopStream}
              />
            </div>
          </div>
          {selectedFile && (
            <DocumentViewer file={selectedFile} onClose={() => setSelectedFile(null)} onUpdateFile={(upd) => setSelectedFile(upd)} />
          )}
          {isCitationViewerOpen && selectedCitationUrl && (
            <div
              className={clsx(
                'w-1/2 h-full border-l border-gray-200 dark:border-gray-700 transition-transform duration-300 ease-in-out',
                isCitationViewerOpen ? 'translate-x-0' : 'translate-x-full'
              )}
            >
              <CitationDocumentViewer
                url={selectedCitationUrl}
                onClose={() => setIsCitationViewerOpen(false)}
              />
            </div>
          )}
        </div>
      )}
      <p className="text-xs text-gray-400 dark:text-gray-500 mt-2 self-center my-1">
        {t('PONS AI V1.7 - Built to support, not supplant, professional judgment. Confirm critical findings.')}
      </p>
    </div>
    {isSidebarOpen && (
      <AISidebar
        onFileSelect={handleFileSelect}
        currentView={currentView}
        setCurrentView={setCurrentView}
        onSelectChatGroup={debouncedHandleSelectChatGroup}
        handleDeleteChat={handleDeleteChat}
        removeSpaces={(str) => str.replace(/\s+/g, '')}
        onProjectSelect={() => {}}
        onNewChat={handleNewChat}
      />
    )}
  </div>
);
};

export default ChatInterface;