import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { Message } from './types';
import MessageBubble from './MessageBubble';
import PromptList from './PromptList'; // you may remove if not used
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { Citation, submitChatFeedback } from '../../store/actions/ChatAction';
import { showCitationModal, ShowPromptFeedback } from '../../store/actions/modalActions';

interface ChatBotProps {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  isTyping: boolean;
  showPrompts: boolean;    // Now likely always false
  onSendMessage: (text: string) => void;
  onFileSelect: (index: number, file: File | null) => void;
  files: (File | null)[];
  className?: string;
  speechToken: string | null;
  serviceRegion: string | null;
  aiSettings: any; 
  onOpenCitation: (url: string) => void;
  onRegenerate?: (messageId: string) => void;
}

const ChatBot: React.FC<ChatBotProps> = ({
  messages,
  setMessages,
  isTyping,
  showPrompts,
  onSendMessage,
  onFileSelect,
  files,
  className,
  speechToken,
  serviceRegion,
  aiSettings,
  onOpenCitation,
  onRegenerate
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const userName = `${userInfo.FirstName} ${userInfo.LastName}`;
  const [isAutoScroll, setIsAutoScroll] = useState(true);
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);

  const messageListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isAutoScroll && messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsAutoScroll(atBottom);
      setShowScrollDownButton(!atBottom);
    }
  };

  // Example feedback
  const handleLike = (messageId: string) => {
    setMessages((prev) =>
      prev.map((m) =>
        m.id === messageId ? { ...m, liked: !m.liked, disliked: false } : m
      )
    );
    const msg = messages.find((m) => m.id === messageId);
    if (msg) {
      dispatch(ShowPromptFeedback()).then((comment: string) => {
        dispatch(submitChatFeedback(messageId, msg.ChatGroupID || '', 'yes', '', comment));
      });
    }
  };

  const handleDislike = (messageId: string) => {
    setMessages((prev) =>
      prev.map((m) =>
        m.id === messageId ? { ...m, disliked: !m.disliked, liked: false } : m
      )
    );
    const msg = messages.find((m) => m.id === messageId);
    if (msg) {
      dispatch(ShowPromptFeedback()).then((comment: string) => {
        dispatch(submitChatFeedback(messageId, msg.ChatGroupID || '', 'no', '', comment));
      });
    }
  };

  const handleCopy = (messageId: string) => {
    // The actual copy is in the bubble. We might handle state here if needed.
  };

  const scrollToBottom = (force: boolean = false) => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo({
        top: messageListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
    if (force) {
      setIsAutoScroll(true);
    }
  };

  const handleShowCitation = (citation: Citation) => {
    dispatch(showCitationModal(citation));
  };

  if (messages.length === 0) {
    // If no messages, show a minimal welcome. Or do nothing if you prefer.
    return (
      <div className="flex flex-col justify-center items-center text-gray-600 dark:text-gray-300 mb-10">
        <h2 className="text-xl">{t('Legal questions? PONS delivers answers.')}</h2>
        {/* <p>{t('Select a service and type your first prompt.')}</p> */}
      </div>
    );
  }

  return (
    <div className={`relative flex flex-col flex-1 overflow-hidden ${className}`}>
      {/* MESSAGES */}
      <div
        className="p-2 flex flex-col flex-1 overflow-y-auto"
        ref={messageListRef}
        onScroll={handleScroll}
      >
        {messages.map((message) => (
          <MessageBubble
            key={message.id}
            message={message}
            onDislike={handleDislike}
            onLike={handleLike}
            onCopy={handleCopy}
            userName={userName}
            isAutoScroll={isAutoScroll}
            scrollToBottom={scrollToBottom}
            speechToken={speechToken}
            serviceRegion={serviceRegion}
            aiSettings={aiSettings}
            onShowCitation={handleShowCitation}
            onOpenCitation={onOpenCitation} 
            onRegenerate={onRegenerate}
            setMessages={setMessages}
          />
        ))}
      </div>

      {showScrollDownButton && (
        <button
          className="absolute bottom-5 right-5 bg-neutral-400 text-white p-2 rounded-full shadow-md hover:bg-neutral-300 transition"
          onClick={() => scrollToBottom(true)}
        >
          <AiOutlineArrowDown size={18} />
        </button>
      )}
    </div>
  );
};

export default ChatBot;
