import FilesExplorer from '../components/Files/FilesExplorer';

function FilesPage() {


  return (
    <div className="flex h-[calc(100vh-80px)] max-h-[calc(100vh-90px)]">
      <FilesExplorer />
    </div>
  );
}

export default FilesPage;
