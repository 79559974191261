import React, { useState, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import Input from '../shared/TailwindComponents/Input';
import Badge from '../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';

interface EmailTagsInputProps {
  value: string[];
  placeholder?: string;
  onChange: (tags: string[]) => void;
  label?: string;
}

const EmailTagsInput: React.FC<EmailTagsInputProps> = ({ value, placeholder, onChange, label }) => {
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<string[]>(value || []);
  const {t} = useTranslation()

  const isValidEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const addEmailToTags = () => {
    const trimmedValue = inputValue.trim();
    if (isValidEmail(trimmedValue)) {
      const newTags = [...tags, trimmedValue];
      setTags(newTags);
      onChange(newTags);
    }
    setInputValue('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      addEmailToTags();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleRemoveTag = (index: number) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    onChange(newTags);
  };

  useEffect(() => {
    setTags(value || []);
  }, [value]);

  return (
    <div className="w-full">
      {label && (
        <p className='text-sm text-gray-900 dark:text-gray-100'>{t(label)}</p>
      )}
      <label className="block text-xs text-gray-500 dark:text-gray-300 mb-1">
        {t("Enter email addresses, press space, enter, or comma to add")}
      </label>
      <div className="flex flex-wrap gap-2 mb-2">
        {tags.map((tag, index) => (
          <Badge
            key={index}
            color="blue"
            className="flex items-center"
            onClose={() => handleRemoveTag(index)}
          >
            {tag}
          </Badge>
        ))}
      </div>
      <Input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        id={`email-tags-input-${Math.random().toString(36).substr(2, 9)}`} 
      />
    </div>
  );
};

export default EmailTagsInput;
