import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { disable2FA, generateQr, verify2faQr } from '../../../store/actions/SignInAction';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../shared/TailwindComponents/Button';
import { PiShieldCheckLight, PiEnvelopeLight } from 'react-icons/pi';

const LoginSecurityTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [loading2FA, setLoading2FA] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const handleDisable2FA = () => {
    dispatch(disable2FA(userInfo.UserID, userInfo.UserType));
    dispatch(
      showFeedbackModal({
        modalType: 'success',
        message: t('Two-Factor Authentication disabled successfully.'),
        showModal: true,
        duration: 3000,
      })
    );
  };

  const handleEnable2FA = async () => {
    setLoading2FA(true);
    try {
      const response: any = await dispatch(generateQr(userInfo.UserID, userInfo.UserType, userInfo.Email));
      if (response) {
        setLoading2FA(false);
        dispatch(
          showModal({
            type: '2fa-qrcode',
            message: t('Enable Two-Factor Authentication (2FA)'),
            onConfirm: async (verificationCode: string) => {
              if (verificationCode) {
                const resp: any = await dispatch(
                  verify2faQr(verificationCode, userInfo.UserID, userInfo.UserType)
                );
                if (resp) {
                  dispatch(
                    showFeedbackModal({
                      modalType: 'success',
                      message: t('Two-Factor Authentication enabled successfully.'),
                      showModal: true,
                      duration: 3000,
                    })
                  );
                }
              } else {
                dispatch(
                  showFeedbackModal({
                    modalType: 'error',
                    message: t('Please enter verification code to enable Two-Factor Authentication (2FA).'),
                    showModal: true,
                    duration: 3000,
                  })
                );
              }
            },
            onCancel: () => dispatch(hideModal()),
            showModal: true,
            inputData: response.QR,
          })
        );
      }
    } finally {
      setLoading2FA(false);
    }
  };

  const handleEmailVerification = async () => {
    setEmailLoading(true);
    try {
      // Placeholder for email verification logic
      dispatch(
        showFeedbackModal({
          modalType: 'success',
          message: t('Verification code sent to your email.'),
          showModal: true,
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Failed to send verification code.'),
          showModal: true,
          duration: 3000,
        })
      );
    }
    setEmailLoading(false);
  };

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">{t('Login Security')}</h3>
      <p className="text-sm text-gray-500 dark:text-gray-400">
        {t('Manage your two-factor authentication and email verification settings.')}
      </p>

      {/* 2FA Card */}
      <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg shadow-light-md dark:shadow-dark-md">
        <div className="flex items-center space-x-4">
          <PiShieldCheckLight className="text-4xl text-primary-500" />
          <div>
            <h4 className="text-sm font-medium text-gray-900 dark:text-white">
              {t('Two-Factor Authentication')}
            </h4>
            <p className="text-xs text-gray-500 dark:text-gray-400 w-4/5">
              {t('Activate 2FA to safeguard your account with secure, multi-step verification.')}
            </p>
          </div>
        </div>
        {userInfo.Enable2FA === 'Yes' ? (
          <Button size="small" variant="danger" onClick={handleDisable2FA} disabled={loading2FA}>
            {t('Disable')}
            {loading2FA && <ClipLoader color="#fff" size={10} className="ml-2" />}
          </Button>
        ) : (
          <Button size="small" variant="primary" onClick={handleEnable2FA} disabled={loading2FA}>
            {t('Enable')}
            {loading2FA && <ClipLoader color="#fff" size={10} className="ml-2" />}
          </Button>
        )}
      </div>

      {/* Email Verification Card */}
      <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg shadow-light-md dark:shadow-dark-md">
        <div className="flex items-center space-x-4">
          <PiEnvelopeLight className="text-4xl text-primary-500" />
          <div>
            <h4 className="text-sm font-medium text-gray-900 dark:text-white">
              {t('Email Verification')}
            </h4>
            <p className="text-xs text-gray-500 dark:text-gray-400 w-4/5">
              {t('Confirm your email address to enable account recovery and receive important updates.')}
            </p>
          </div>
        </div>
        {userInfo.isEmailVerified ? (
          <Button size="small" variant="primary">{t('Verified')}</Button>
        ) : (
          <Button variant="primary" size="small" onClick={handleEmailVerification} disabled={emailLoading}>
            {t('Verify')}
            {emailLoading && <ClipLoader color="#fff" size={10} className="ml-2" />}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LoginSecurityTab;