// src/components/shared/animations/LoadingToCheckmark.tsx
import React, { useEffect, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

/**
 * Props:
 * - isLoading: if true => show spinner; if false => transition to check
 * - spinnerDuration: total ms to keep spinner *after* isLoading becomes false
 * - postCheckmarkDelay: how long the checkmark remains visible
 * - onAnimationDone: callback after everything finishes
 * - serviceName: optional label
 */
interface LoadingToCheckmarkProps {
  isLoading: boolean;
  spinnerDuration?: number;  // time to stay spinning *after* isLoading changes to false
  postCheckmarkDelay?: number;
  onAnimationDone?: () => void;
  serviceName?: string; 
}

export const LoadingToCheckmark: React.FC<LoadingToCheckmarkProps> = ({
  isLoading,
  spinnerDuration = 800,
  postCheckmarkDelay = 1200,
  onAnimationDone,
  serviceName = 'Operation',
}) => {
    const [phase, setPhase] = useState<'SPINNING' | 'CHECKMARK' | 'DONE'>(
        isLoading ? 'SPINNING' : 'CHECKMARK'
      );
      const [fullyHidden, setFullyHidden] = useState(false);
      const {t}  = useTranslation()

      useEffect(() => {
        if (isLoading) {
          setPhase('SPINNING');
          setFullyHidden(false);
          return;
        }
        let timer: NodeJS.Timeout | null = setTimeout(() => {
          setPhase('CHECKMARK');
          timer = setTimeout(() => {
            setPhase('DONE');
          }, postCheckmarkDelay);
        }, spinnerDuration);
    
        return () => {
          if (timer) clearTimeout(timer);
        };
      }, [isLoading]);
    
      useEffect(() => {
        if (phase === 'DONE') {
          const fadeTimer = setTimeout(() => {
            setFullyHidden(true);
            if (onAnimationDone) onAnimationDone();
          }, 300); 
          return () => clearTimeout(fadeTimer);
        }
      }, [phase, onAnimationDone]);
    
      if (fullyHidden) return null; 
    

  /**
   * Our final rendered states:
   * - SPINNING => spinner icon
   * - CHECKMARK => check icon
   * - DONE => fade out everything or remain?
   */
  return (
    <div
      className={`
        flex flex-col items-center justify-center py-6 select-none
        transition-opacity duration-300
        ${phase === 'DONE' ? 'opacity-0' : 'opacity-100'}
      `}
    >      {phase !== 'DONE' && (
        <div className="relative flex items-center justify-center mb-2 w-10 h-10">
          {/* Spinner */}
          <div
            className={`${
              phase === 'SPINNING' ? 'opacity-100' : 'opacity-0 scale-0'
            } absolute transition-all duration-300 transform`}
          >
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-600" />
          </div>

          {/* Checkmark */}
          <CheckCircleIcon
            className={`h-10 w-10 text-green-500 transition-all duration-300 transform ${
              phase === 'CHECKMARK'
                ? 'opacity-100 scale-100'
                : 'opacity-0 scale-0'
            }`}
          />
        </div>
      )}

      {/* Display text below, e.g. “Operation in progress” or “Done!” */}
      {phase === 'SPINNING' && (
        <p className="text-sm text-gray-700 dark:text-gray-200 transition-opacity duration-200">
         {t(serviceName)} {t("in progress...")}
        </p>
      )}
      {phase === 'CHECKMARK' && (
        <p className="text-sm text-gray-700 dark:text-gray-200 transition-all duration-500">
          {t(serviceName)} {t("complete!")}
        </p>
      )}
      {/* If you want to hide text altogether after done, omit “DONE” state. */}
      {phase === 'DONE' && null /* or omit */}
    </div>
  );
};
