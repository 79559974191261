import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { fetchTaskDetails, updateTask, assignTask, completeTask, deleteTask } from '../../store/actions/TasksActions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import { getUserType } from '../../store/actions/ChatAction';
import dayjs from 'dayjs';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import ApiLoader from '../ApiLoader';
import { hideModal, showModal } from '../../store/actions/modalActions';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { PiBriefcase, PiBuildingOffice, PiCalendarBlank, PiCheckCircle, PiGavel, PiUser } from "react-icons/pi";
import { convertLongDateWithTranslation, convertShortDateWithTranslation } from '../shared/DateConverter';

interface TaskDetailParams {
  taskId: string;
}

const TaskDetail: React.FC = () => {
  const { t } = useTranslation();
  const { taskId } = useParams<TaskDetailParams>();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const { setBreadcrumbs } = useBreadcrumbs();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const history = useHistory();

  const [isEditing, setIsEditing] = useState(false);
  const [taskDetails, setTaskDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const cases = useSelector((state: RootState) => state.casesM.cases);
  const employees = useSelector((state: RootState) => state.employees.employees);

  const employeeOptions: DropdownOption[] = employees.map((emp) => ({
    value: emp.Email,
    label: `${emp.FirstName} ${emp.LastName}`,
  }));

  const caseOptions: DropdownOption[] = cases.map((caseItem) => ({
    value: caseItem.CaseID,
    label: caseItem.Title || caseItem.CaseName || `${t('Case')} ${caseItem.CaseID}`,
  }));

  const [reassignEmail, setReassignEmail] = useState<string>('');
  const [selectedCaseId, setSelectedCaseId] = useState<string>('');

  const fetchDetails = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await dispatch(fetchTaskDetails(taskId));
      if (response && response.TaskDetails) {
        setTaskDetails(response);
      } else {
        setError(t('taskDetailsNotFound'));
        setTaskDetails(null);
      }
    } catch (err) {
      setError(t('fetchTaskDetailsError'));
      setTaskDetails(null);
      console.error(t('fetchError'), err);
    } finally {
      setLoading(false);
    }
  }, [dispatch, taskId, t]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  useEffect(() => {
    if (taskDetails) {
      setBreadcrumbs([
        { name: t('Tasks'), href: '/tasks', current: false },
        { name: taskDetails.TaskDetails?.Title || t('Task Details'), href: `/tasks/${taskId}`, current: true },
      ]);
    } else {
      setBreadcrumbs([
        { name: t('Tasks'), href: '/tasks', current: false },
        { name: t('Loading...'), href: `/tasks/${taskId}`, current: true },
      ]);
    }
    return () => setBreadcrumbs([]);
  }, [taskDetails, taskId, setBreadcrumbs, t]);

  useEffect(() => {
    dispatch(fetchCases());
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Title: taskDetails?.TaskDetails?.Title || '',
      Description: taskDetails?.TaskDetails?.Description || '',
      TaskDeadline: taskDetails?.TaskDetails?.TaskDeadline ? dayjs(taskDetails.TaskDetails.TaskDeadline).toISOString() : '',
      CaseID: taskDetails?.TaskDetails?.CaseID || '',
    },
    validationSchema: Yup.object({
      Title: Yup.string().required(t('titleRequired')),
      Description: Yup.string().required(t('descriptionRequired')),
      TaskDeadline: Yup.date().required(t('deadlineRequired')),
    }),
    onSubmit: async (values) => {
      try {
        await dispatch(
          updateTask({
            TaskID: taskId,
            Title: values.Title,
            Description: values.Description,
            TaskDeadline: values.TaskDeadline,
            CaseID: values.CaseID || null,
          })
        );
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: t('taskUpdatedSuccessfully'),
            showModal: true,
            duration: 3000,
          })
        );
        fetchDetails();
        setIsEditing(false);
      } catch (error) {
        console.error(t('errorUpdatingTask'), error);
      }
    },
  });

  const getStatusBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'completed': return 'green';
      case 'active': return 'blue';
      case 'pending': return 'yellow';
      case 'deleted': return 'red';
      default: return 'gray';
    }
  };

  const getCaseStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'open': return 'green';
      case 'closed': return 'red';
      case 'in progress': return 'blue';
      case 'draft': return 'gray';
      default: return 'gray';
    }
  };

  const handleReassign = async () => {
    if (!reassignEmail.trim()) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('selectEmployeeError'),
          modalType: 'error',
          duration: 3000,
        })
      );
      return;
    }
    try {
      await dispatch(assignTask(taskId, [reassignEmail], '', taskDetails.TaskDetails.CaseID));
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('taskReassignedSuccessfully'),
          modalType: 'success',
          duration: 3000,
        })
      );
      setReassignEmail('');
      fetchDetails();
    } catch (error) {
      console.error(t('errorReassigningTask'), error);
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('errorReassigningTask'),
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  };

  const handleChangeCase = async () => {
    if (!selectedCaseId) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('selectCaseError'),
          modalType: 'error',
          duration: 3000,
        })
      );
      return;
    }
    try {
      await dispatch(updateTask({ TaskID: taskId, CaseID: selectedCaseId }));
      dispatch(
        showFeedbackModal({
          modalType: 'success',
          message: t('caseChangedSuccessfully'),
          showModal: true,
          duration: 3000,
        })
      );
      setSelectedCaseId('');
      fetchDetails();
    } catch (error) {
      console.error(t('errorChangingCase'), error);
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('errorChangingCase'),
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  };

  const handleComplete = async () => {
    try {
      dispatch(
        showModal({
          type: 'addTaskComment',
          message: t('Provide Comment'),
          onConfirm: async (comment: string) => {
            await dispatch(completeTask(taskId, comment));
            fetchDetails();
            dispatch(hideModal());
          },
          showModal: true,
          onCancel: () => dispatch(hideModal()),
          duration: 3000,
        })
      );
    } catch (error) {
      console.error(t('errorCompletingTask'), error);
    }
  };

  const handleDelete = async () => {
    try {
      dispatch(
        showModal({
          showModal: true,
          type: 'confirmation',
          onConfirm: () => {
            dispatch(deleteTask(taskId));
            fetchDetails();
          },
          message: t('confirmDeleteTask'),
          subMsg: t('noUndoAction'),
        })
      );
    } catch (error) {
      console.error(t('errorDeletingTask'), error);
    }
  };

  if (loading) {
    return <ApiLoader />;
  }
  if (error) {
    return <div className="text-red-500 p-6">{error}</div>;
  }
  if (!taskDetails || !taskDetails.TaskDetails) {
    return <div className="text-gray-500 p-6">{t('taskNotFound')}</div>;
  }

  const isAdmin = userType === 'LawFirmAdmin' || userType === 'BusinessAdmin';
  const isCreator = taskDetails.TaskDetails?.UserID === userInfo.UserID && taskDetails.TaskDetails?.AssignedBy === userInfo.UserID;
  const canEdit = isAdmin || isCreator;
  const isAssignedToSelf = taskDetails.Assignee?.UserID === userInfo.UserID;
  const hasAssignee = !!taskDetails.Assignee;

  const reassignButtonText = hasAssignee
    ? isAssignedToSelf
      ? t('Reassign to Someone Else')
      : t('Reassign Task')
    : t('Assign Task');

  return (
    <div className="p-6 mx-2 bg-white dark:bg-gray-800 rounded-lg shadow-light-md dark:shadow-dark-md transition-colors duration-300 flex flex-col gap-8 animate-fade-in-up">
      {/* Header with Back Arrow and Title */}
      <div className="flex items-center mb-4 justify-between">
        <div className="flex items-center">
          <ChevronLeftIcon
            className="h-6 w-6 text-gray-500 dark:text-gray-300 cursor-pointer hover:text-primary-500 transition-colors"
            onClick={() => history.goBack()}
          />
          <div className="flex ml-2 items-center gap-1">
            <h1 className="text-lg font-bold text-gray-800 dark:text-gray-100">
              {taskDetails?.TaskDetails?.Title || t('Task Details')}
            </h1>
            <Badge className="w-fit" color={getStatusBadgeColor(taskDetails.TaskDetails.Status)}>
              {t(taskDetails.TaskDetails.Status)}
            </Badge>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {taskDetails.TaskDetails.Status === 'Active' && (
            <Button variant="primary" size="small" onClick={handleComplete}>
              {t('Complete Task')}
            </Button>
          )}
          {taskDetails.TaskDetails.Status === 'Completed' && taskDetails.TaskDetails.Comment && (
            <Badge color="purple">{taskDetails.TaskDetails.Comment}</Badge>
          )}
          {(isAdmin || isCreator) && taskDetails.TaskDetails.Status !== 'Deleted' && (
            <Button variant="neutral" size="small" onClick={handleDelete}>
              {t('Delete Task')}
            </Button>
          )}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        {/* Main Section: Task Info */}
        <div className="flex-1 space-y-6">
          {isEditing ? (
            <form onSubmit={formik.handleSubmit} className="space-y-6">
              <Input
                label={t('Title')}
                name="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                placeholder={t('enterTaskTitle')}
                type="text"
                className="transition-all duration-300"
              />
              {formik.touched.Title && formik.errors.Title && (
                <p className="text-sm text-danger-500">{formik.errors.Title as string}</p>
              )}
              <Textarea
                label={t('Description')}
                name="Description"
                value={formik.values.Description}
                onChange={formik.handleChange}
                placeholder={t('enterTaskDescription')}
                id="Description"
              />
              {formik.touched.Description && formik.errors.Description && (
                <p className="text-sm text-danger-500">{formik.errors.Description as string}</p>
              )}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Deadline')}
                </label>
                <CustomDateTimePicker
                  value={formik.values.TaskDeadline ? dayjs(formik.values.TaskDeadline) : null}
                  onChange={(newValue: dayjs.Dayjs | null) =>
                    formik.setFieldValue('TaskDeadline', newValue ? newValue.toISOString() : '')
                  }
                  minDateTime={dayjs()}
                />
                {formik.touched.TaskDeadline && formik.errors.TaskDeadline && (
                  <p className="mt-2 text-sm text-danger-500">{formik.errors.TaskDeadline}</p>
                )}
              </div>
              <div className="flex justify-end space-x-4 pt-4">
                <Button type="submit" size="small" variant="primary">
                  {t('Save Changes')}
                </Button>
                <Button type="button" size="small" variant="neutral" onClick={() => setIsEditing(false)}>
                  {t('Cancel')}
                </Button>
              </div>
            </form>
          ) : (
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">{t('Task Details')}</h2>
                {canEdit && (
                  <Button variant="primary" size="small" onClick={() => setIsEditing(true)}>
                    {t('Edit')}
                  </Button>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col space-y-2">
                  <span className="font-medium text-gray-700 dark:text-gray-300">{t('Creation Date')}</span>
                  <Badge className="w-fit" color="purple">
                    {convertLongDateWithTranslation(dayjs(taskDetails.TaskDetails.CreationDate).format('MMMM D, YYYY h:mm A'))}
                  </Badge>
                </div>
                <div className="space-y-2">
                  <span className="font-medium text-gray-700 dark:text-gray-300">{t('Deadline')}</span>
                  <p className="text-gray-600 dark:text-gray-400">
                    {convertLongDateWithTranslation(dayjs(taskDetails.TaskDetails.TaskDeadline).format('MMMM D, YYYY h:mm A'))}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {userType.includes('Employee') && (
                  <div className="flex flex-col space-y-2">
                    <span className="font-medium text-gray-700 dark:text-gray-300">{t('Assigned By')}</span>
                    <Badge className="w-fit" color="indigo">
                      {taskDetails.Assigner.Name}
                    </Badge>
                  </div>
                )}
                {userType.includes('Admin') && (
                  <div className="flex flex-col space-y-2">
                    <span className="font-medium text-gray-700 dark:text-gray-300">{t('Assignee')}</span>
                    <Badge className="w-fit" color="blue">
                      {taskDetails.Assignee.Name}
                    </Badge>
                  </div>
                )}
              </div>
              <div className="space-y-2">
                <span className="font-medium text-gray-700 dark:text-gray-300">{t('Description')}</span>
                <p className="text-gray-600 dark:text-gray-400">{taskDetails.TaskDetails.Description}</p>
              </div>
            </div>
          )}
        </div>

        {/* Right Section: Actions */}
        <div className="w-full lg:w-1/3 bg-gray-50 dark:bg-gray-700 rounded-md p-4 flex flex-col space-y-6 h-fit shadow-light-sm dark:shadow-dark-sm">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">{t('Actions')}</h3>
          {isAdmin && (
            <div className="space-y-4">
              <h4 className="text-md font-semibold text-gray-800 dark:text-gray-200">{reassignButtonText}</h4>
              <Dropdown
                options={employeeOptions}
                placeholder={t('Select Employee')}
                onSelect={(option) => setReassignEmail(option.value)}
              />
              <Button variant="primary" size="small" onClick={handleReassign}>
                {reassignButtonText}
              </Button>
            </div>
          )}
          {canEdit && (
            <div className="space-y-4">
              <h4 className="text-md font-semibold text-gray-800 dark:text-gray-200">
                {taskDetails.Case ? t('Change Case') : t('Assign Case')}
              </h4>
              <Dropdown
                options={caseOptions}
                placeholder={t('Select Case')}
                onSelect={(option) => setSelectedCaseId(option.value)}
              />
              <Button variant="primary" size="small" onClick={handleChangeCase}>
                {taskDetails.Case ? t('Change Case') : t('Assign Case')}
              </Button>
            </div>
          )}
          {!(isAdmin || isCreator) && (
            <p className="text-sm text-gray-500 dark:text-gray-300">{t('noPermissionToModify')}</p>
          )}
        </div>
      </div>
      <div className='h-[1px] w-full bg-gray-300 rounded my-4'></div>
      {/* Related Items Section */}
      <div>
        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">{t('Related Items')}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Case Card */}
          {taskDetails.Case && (
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-light-sm dark:shadow-dark-sm overflow-hidden transition-all duration-200 hover:shadow-lg">
              <div className="bg-gradient-to-r from-blue-100 to-blue-200 dark:from-gray-600 dark:to-gray-700 p-3 flex items-center">
                <PiBriefcase className="h-5 w-5 text-blue-600 dark:text-blue-300 mr-2" />
                <h4 className="font-semibold text-gray-800 dark:text-gray-200 text-lg">{t('Case')}</h4>
              </div>
              <div className="p-4 space-y-3">
                <p className="text-gray-800 dark:text-gray-200 font-medium text-base">{taskDetails.Case.CaseName}</p>
                <div className="space-y-2">
                  <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                    <PiBriefcase className="h-4 w-4 text-gray-500 mr-2" />
                    <span><strong>{t('Type')}:</strong> {taskDetails.Case.CaseType}</span>
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                    <PiUser className="h-4 w-4 text-gray-500 mr-2" />
                    <span><strong>{t('Client')}:</strong> {taskDetails.Case.ClientName}</span>
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                    <PiGavel className="h-4 w-4 text-gray-500 mr-2" />
                    <span><strong>{t('Lawyer')}:</strong> {taskDetails.Case.LawyerName}</span>
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                    <PiBuildingOffice className="h-4 w-4 text-gray-500 mr-2" />
                    <span><strong>{t('Organization')}:</strong> {taskDetails.Case.OrganizationName}</span>
                  </p>
                  <div className="flex items-center">
                    <PiCheckCircle className="h-4 w-4 text-gray-500 mr-2" />
                    <strong className="text-sm text-gray-600 dark:text-gray-400 mr-1">{t('Status')}:</strong>
                    <Badge color={getCaseStatusColor(taskDetails.Case.ProgressStatus)}>
                      {t(taskDetails.Case.ProgressStatus)}
                    </Badge>
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center">
                    <PiCalendarBlank className="h-4 w-4 text-gray-500 mr-2" />
                    <span>
                      <strong>{t('Created')}:</strong>
                      {convertShortDateWithTranslation(dayjs(taskDetails.Case.created_at)
                        .format('MMM D, YYYY'))
                      }
                    </span>
                  </p>
                </div>
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => history.push(`/cases/${taskDetails.Case.CaseID}`)}
                  className="mt-3 w-full"
                >
                  {t('View Case')}
                </Button>
              </div>
            </div>
          )}
          {/* Assignee Card */}
          {/* {taskDetails.Assignee && (
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-light-sm dark:shadow-dark-sm overflow-hidden transition-all duration-200 hover:shadow-lg">
              <div className="bg-gradient-to-r from-green-100 to-green-200 dark:from-gray-600 dark:to-gray-700 p-3 flex items-center">
                <UserIcon className="h-5 w-5 text-green-600 dark:text-green-300 mr-2" />
                <h4 className="font-semibold text-gray-800 dark:text-gray-200 text-lg">{t('Assignee')}</h4>
              </div>
              <div className="p-4">
                <p className="text-gray-800 dark:text-gray-200 font-medium text-base">{taskDetails.Assignee.Name}</p>
                <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                  <strong>{t('Role')}:</strong> {taskDetails.Assignee.UserType}
                </p>
              </div>
            </div>
          )}
          {/* Assigner Card 
          {taskDetails.Assigner && (
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-light-sm dark:shadow-dark-sm overflow-hidden transition-all duration-200 hover:shadow-lg">
              <div className="bg-gradient-to-r from-purple-100 to-purple-200 dark:from-gray-600 dark:to-gray-700 p-3 flex items-center">
                <UsersIcon className="h-5 w-5 text-purple-600 dark:text-purple-300 mr-2" />
                <h4 className="font-semibold text-gray-800 dark:text-gray-200 text-lg">{t('Assigner')}</h4>
              </div>
              <div className="p-4">
                <p className="text-gray-800 dark:text-gray-200 font-medium text-base">{taskDetails.Assigner.Name}</p>
                <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                  <strong>{t('Role')}:</strong> {taskDetails.Assigner.UserType}
                </p>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TaskDetail;