import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { signOut } from '../../store/actions/SignInAction';
import { RootState, useAppDispatch } from '../../store';
import RoleDropdown from './RoleDropdown';
import { getUserType, AIModel, getAiModels } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../TimeTracker/TimeTracker';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { getIsAdmin, isBetaEnv } from '../../utils/authUtils';
import { Transition } from '@headlessui/react';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import Notifications from './Notifications';
import { PiBookOpenTextLight, PiBuildingOfficeLight, PiCaretRightLight, PiChatsCircle, PiChatsCircleLight, PiChatTeardropDotsLight, PiChatTeardropTextLight, PiClock, PiClockLight, PiGearLight, PiHouseLight, PiQuestionLight, PiSignOutLight, PiSparkleLight, PiUserLight } from "react-icons/pi";
import { GoOrganization, GoShieldLock } from "react-icons/go";
import { CiLogout } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import Badge from '../shared/TailwindComponents/Badge';
import { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import { changeLanguage } from '../../i18n/i18n';
import { fetchPlatformLanguages, fetchPlatformCurrencies } from '../../store/actions/DropdownActions';
import { getPreferences, updatePreferences } from '../../store/actions/DataAction';
import { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { showModal } from '../../store/actions/modalActions';
import SocketServices from '../../utils/SocketServices';
import ProfileImage from './ProfileImage';
import { fetchUserSubscriptionStatus } from '../../store/actions/SubscriptionActions';

type HeaderItemKey = 'messageCenter' | 'timeTracker' | 'notifications';

interface HeaderItemConfig {
  disabledInBeta: boolean;
  hidden: boolean;
}

type HeaderProps = {
  activeView: string;
  setActiveView: (activeView: string) => void;
  isLoadingQueries?: boolean;
};

// Config for disabling/hiding header items
const headerItemConfig: Record<HeaderItemKey, HeaderItemConfig> = {
  messageCenter: { disabledInBeta: true, hidden: false },
  timeTracker: { disabledInBeta: true, hidden: false },
  notifications: { disabledInBeta: true, hidden: false },
};
const Header: React.FC<HeaderProps> = ({ isLoadingQueries }) => {
  const [openDropdown, setOpenDropdown] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const userType = getUserType();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAdmin = getIsAdmin();
  const { breadcrumbs } = useBreadcrumbs();
  const available_queries = useSelector((state: RootState) => state.model.total_available_queries);
  const platformLanguages = useSelector((state: RootState) => state.dropdown.platformLanguages) as DropdownOption[];
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const { theme, setTheme } = useContext(ThemeContext);
  const { subscriptionActive, loading } = useSelector((state: RootState) => state.subscription);

  const [total_queries, setTotalQueries] = useState<string | number>(0);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');

  // Function to handle clicks outside the dropdown
  const handleClickOutside: any = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOpenDropdown(""); // Close dropdown
    }
  };

  useEffect(() => {
    if (openDropdown !== '') {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openDropdown]);

  useEffect(() => {
    setTotalQueries(available_queries);
  }, [available_queries]);

  useEffect(() => {
    dispatch(fetchPlatformLanguages());
    dispatch(fetchPlatformCurrencies());
    dispatch(getPreferences());
    dispatch(fetchUserSubscriptionStatus())
  }, [dispatch]);

  useEffect(() => {
    if (preferences && platformLanguages.length > 0) {
      const language = platformLanguages.find((lang) => lang.value === preferences.Language) || { value: 'en' };
      setSelectedLanguage(language.value);
      changeLanguage(language.value);
      setTheme(preferences.ThemeColor || 'light');
    }
  }, [preferences, platformLanguages, setTheme]);

  const toggleDropdown = (dropdownName: string) => {
    setOpenDropdown(openDropdown === dropdownName ? '' : dropdownName);
  };

  const handleLogout = async () => {
    history.push('/');
    await dispatch(signOut());
  };

  const getInitials = (firstName = '', lastName = '') => {
    const firstInitial = firstName.length > 0 ? firstName[0] : 'U';
    const lastInitial = lastName.length > 0 ? lastName[0] : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const userInitials = getInitials(userInfo.FirstName, userInfo.LastName);

  const getProfileImage = () => (
    <ProfileImage
      profilePicture={userInfo.ProfilePicture}
      email={userInfo.Email}
      initials={userInitials}
    />
  );

  const getBreadcrumbName = (segment: string) => {
    const lowerSegment = segment.toLowerCase();
    const nameMap: { [key: string]: string } = {
      'dashboard': 'Dashboard',
      'documents': 'Documents',
      'ai-assistant': 'AI Assistant',
      'cases': 'Cases',
      'analytics': 'Analytics',
      'projects': 'Projects',
      'marketplace': 'Marketplace',
      'marketplace-settings': 'Marketplace Settings',
      'message-center': 'Message Center',
      'clients': 'Clients',
      'help': 'Help',
      'user-manual': 'User Manual',
      'feedback': 'Feedback',
      'support': 'Support',
      'settings': 'Settings',
      'account': 'Account',
      'security': 'Security',
      'platform-preferences': 'Platform Preferences',
      'ai-studio': 'AI Studio',
      'organization': 'Organization',
      'payment-result': 'Payment Result',
      'design-system': 'Design System',
      'plans': 'Plans',
      'management': 'Management',
      'lawyers': 'Lawyers',
      'finance': 'Finance',
      'time-tracking': 'Time Tracking',
      'time-keeping': 'Time Keeping',
      'subscriptions': 'Subscriptions',
      'queries': 'Queries',
      'not-found': 'Page Not Found',
      '*': 'Page Not Found',
    };
    return nameMap[lowerSegment] || lowerSegment.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const defaultBreadcrumbs = React.useMemo(() => {
    const pathname = location.pathname;
    const pathnames = pathname.split('/').filter((x) => x);
    return pathnames.map((value, index) => {
      const href = `/${pathnames.slice(0, index + 1).join('/')}`;
      const name = getBreadcrumbName(value);
      return { name, href, current: index === pathnames.length - 1 };
    });
  }, [location.pathname]);

  const breadcrumbItems = breadcrumbs.length > 0 ? breadcrumbs : defaultBreadcrumbs;

  const recieve_new_notification = useCallback((data: any) => {
    console.log("Data recieved", data)
    // setNotifications((prevNotifications: any[]) => [data, ...prevNotifications]);

    // setUnreadCount((prevUnreadCount) => prevUnreadCount + (!data.is_read ? 1 : 0));
  }, [])

  const license_revoked = useCallback((data: any) => {
    console.log("License Revoked", data)
    dispatch(getAiModels())
    dispatch(fetchUserSubscriptionStatus())
    // setNotifications((prevNotifications: any[]) => [data, ...prevNotifications]);

    // setUnreadCount((prevUnreadCount) => prevUnreadCount + (!data.is_read ? 1 : 0));
  }, [dispatch])

  const manual_query_update = useCallback((data: any) => {
    console.log("query updated", data)
    dispatch(getAiModels())

  }, [dispatch])

  useEffect(() => {
    SocketServices.on('new_notification', recieve_new_notification)
    SocketServices.on('license_revoked', license_revoked)
    SocketServices.on('manual_query_update', manual_query_update)
    return () => {
      SocketServices.removeListener('new_notification')
      SocketServices.removeListener('license_revoked')
      SocketServices.removeListener('manual_query_update')
    }
  }, [recieve_new_notification, license_revoked,manual_query_update])

  const handleLanguageChange = (selectedValues: string[] | string) => {
    const selectedId = Array.isArray(selectedValues) ? selectedValues[0] : selectedValues;
    const selectedOption = platformLanguages.find((option) => option.value === selectedId);
    if (selectedOption) {
      setSelectedLanguage(selectedOption.value);
      changeLanguage(selectedOption.value);
      dispatch(updatePreferences({ UserID: userInfo.UserID, UserType: userType, Language: selectedOption.value, ThemeColor: theme }));
    }
  };

  const handleSettingsClick = () => {
    dispatch(showModal({
      type: 'settings',
      message: '',
      showModal: true,
      onConfirm: () => { },
      onCancel: () => { },
    }));
  };

  const renderHeaderItem = (
    key: HeaderItemKey,
    content: JSX.Element,
    tooltip: string,
    onClick?: () => void
  ) => {
    const { disabledInBeta, hidden } = headerItemConfig[key];
    const isDisabled = isBetaEnv && disabledInBeta;
    if (hidden) return null;

    return (
      <Tooltip title={isDisabled ? t('Coming Soon') : t(tooltip)}>
        <div
          onClick={isDisabled ? undefined : onClick}
          className={clsx(
            'py-2 px-2 rounded-full transition-colors duration-300',
            isDisabled
              ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed'
              : 'text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
          )}
        >
          {content}
        </div>
      </Tooltip>
    );
  };
  return (
    <header className="relative h-18 flex items-center justify-between px-5 bg-white dark:bg-gray-900 shadow-sm mb-2">
      <div className="flex items-center w-full gap-20">
        <div className="flex items-center justify-start rounded w-fit h-fit my-2">
          <NavLink to="/dashboard" className="flex items-center space-x-2">
            <i className="fa-kit dark:text-sky-400/100 text-primary-500 fa-logo" style={{ fontSize: 26 }}></i>
            <h1 style={{ fontSize: 30 }} className="text-grey-50 transition-opacity duration-300">
              PONS
            </h1>
          </NavLink>
        </div>
        <nav aria-label="Breadcrumb" className="flex-1 min-w-0">
          <ol className="flex items-center space-x-4 truncate">
            <li>
              <NavLink to="/" className="text-gray-400 hover:text-gray-500">
                <PiHouseLight aria-hidden="true" className="h-4 w-4 flex-shrink-0" />
                <span className="sr-only">{t('Home')}</span>
              </NavLink>
            </li>
            {breadcrumbItems.map((breadcrumb) => (
              <li key={breadcrumb.href} className="flex items-center truncate">
                <PiCaretRightLight aria-hidden="true" className="h-4 w-4 flex-shrink-0 text-gray-400" />
                <NavLink
                  to={breadcrumb.href}
                  aria-current={breadcrumb.current ? 'page' : undefined}
                  className="ml-4 text-xs font-regular text-gray-500 hover:text-gray-700 truncate"
                >
                  {t(breadcrumb.name)}
                </NavLink>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      

      <div className="flex items-center gap-3" ref={dropdownRef}>
        <Tooltip title={!subscriptionActive ? t('Buy Premium') : t('Available Queries')}>
          <NavLink
            data-tour-id="subscriptions"
            to="/queries"
            className="py-2 px-2 rounded-full text-gray-600 dark:text-gray-300"
          >
            <Badge className="hover:bg-indigo-200 dark:hover:bg-indigo-800 transition-colors duration-300 " color="indigo">
              <PiSparkleLight />
              {isLoadingQueries ? (
                <span className="custom-loader bg-indigo-300 dark:bg-indigo-600 h-4 w-12 rounded inline-block ml-1 opacity-50"></span>
              ) : (
                <span className='inline-block ml-1'>
                  {
                    !subscriptionActive ? (
                      // 'You don’t have any more queries'
                      'Buy Premium'
                    ) : (
                      total_queries
                    )
                  }
                </span>
              )}
            </Badge>
          </NavLink>
        </Tooltip>

        {renderHeaderItem(
          'messageCenter',
          <PiChatsCircleLight size={20} />,
          'Message Center',
          () => history.push('/message-center')
        )}

        {(userType === 'LawFirmEmployee' || userType === 'LawFirmAdmin') &&
          renderHeaderItem(
            'timeTracker',
            <PiClockLight size={20} />,
            'Time Tracker',
            () => toggleDropdown('timeTracker')
          )}
        {openDropdown === 'timeTracker' && !headerItemConfig.timeTracker.disabledInBeta && (
          <Transition
            show={openDropdown === 'timeTracker'}
            enter="transition ease-out duration-200 transform"
            enterFrom="opacity-0 translate-y-[-10px]"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-[-10px]"
          >
            <div className="absolute right-0 mt-2 w-96 bg-background-light dark:bg-background-dark border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg p-4 z-50">
              <TimeTracker />
              <div className="mt-4">
                <NavLink
                  to={userType === 'LawFirmAdmin' ? '/time-tracking' : '/time-keeping'}
                  className="text-primary-500 hover:text-primary-600 transition-colors duration-300"
                  onClick={() => setOpenDropdown('')}
                >
                  {t('View Logs')}
                </NavLink>
              </div>
            </div>
          </Transition>
        )}

        {renderHeaderItem(
          'notifications',
          <Notifications
            isOpen={openDropdown === 'notifications'}
            onToggle={() => toggleDropdown('notifications')}
            onClose={() => setOpenDropdown('')}
            disabled={isBetaEnv && headerItemConfig.notifications.disabledInBeta} // Pass disabled prop
          />,
          'Notifications'
        )}
        {isAdmin && <RoleDropdown />}

        <Tooltip title={t('Settings')}>
          <div
            onClick={handleSettingsClick}
            className="cursor-pointer py-2 px-2 rounded-full text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300"
          >
            <PiGearLight size={20} />
          </div>
        </Tooltip>

        {/* Profile Dropdown - remove language and theme */}
        <div className="relative">
          <button
            data-tour-id="profileIcon"
            onClick={() => toggleDropdown('profile')}
            className={clsx(
              'flex items-center justify-center w-10 h-10 rounded-full transition-colors duration-300 focus:outline-none hover:bg-primary-100 dark:hover:bg-primary-800',
              openDropdown === 'profile' ? 'bg-primary-100 dark:bg-primary-800' : 'bg-primary-100 dark:bg-primary-700'
            )}
          >
            {getProfileImage()}
          </button>
          <Transition
            show={openDropdown === 'profile'}
            enter="transition ease-out duration-200 transform"
            enterFrom="opacity-0 translate-y-[-10px]"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-[-10px]"
          >
            <div className="absolute right-0 mt-2 min-w-48 bg-background-light dark:bg-background-dark border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
              <div className="pt-2">
                <NavLink
                  to="/account"
                  className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => setOpenDropdown('')}
                >
                  <PiUserLight size={20} style={{ marginRight: 10 }} />
                  <span className="text-sm">{t('Account')}</span>
                </NavLink>
              </div>

              {(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') && (
                <div className="">
                  <NavLink
                    to="/organization"
                    className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                    onClick={() => setOpenDropdown('')}
                  >
                    <PiBuildingOfficeLight size={20} style={{ marginRight: 10 }} />
                    <span className="text-sm">{t('Organization')}</span>
                  </NavLink>
                </div>
              )}

              <div className="border-t border-gray-300 dark:border-gray-700 my-2"></div>
              <button
                onClick={handleLogout}
                className="flex items-center w-full text-left p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
              >
                <PiSignOutLight size={20} style={{ marginRight: 10 }} />
                <span className="text-sm">{t('Sign out')}</span>
              </button>
            </div>
          </Transition>
        </div>

        <div className="relative">
          <Tooltip title={t('Help')}>
            <div
              data-tour-id="helpIcon"
              onClick={() => toggleDropdown('help')}
              className={clsx(
                'cursor-pointer py-2 px-2 rounded-full transition-colors duration-300',
                openDropdown === 'help' ? 'bg-primary-500 text-white' : 'text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
              )}
            >
              <PiQuestionLight size={20} />
            </div>
          </Tooltip>
          <Transition
            show={openDropdown === 'help'}
            enter="transition ease-out duration-200 transform"
            enterFrom="opacity-0 translate-y-[-10px]"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-[-10px]"
          >
            <div className="absolute right-0 mt-2 min-w-48 bg-background-light dark:bg-background-dark border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
              <NavLink
                to="/user-manual"
                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                onClick={() => setOpenDropdown('')}
              >
                <PiBookOpenTextLight size={20} style={{ marginRight: 10 }} />
                <span className="text-sm">{t('User Manual')}</span>
              </NavLink>
              <NavLink
                to="/feedback"
                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                onClick={() => setOpenDropdown('')}
              >
                <PiChatTeardropDotsLight size={20} style={{ marginRight: 10 }} />
                <span className="text-sm">{t('Feedback')}</span>
              </NavLink>
              <NavLink
                to="/support"
                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300"
                onClick={() => setOpenDropdown('')}
              >
                <PiChatTeardropTextLight size={20} style={{ marginRight: 10 }} />
                <span className="text-sm">{t('Support')}</span>
              </NavLink>
            </div>
          </Transition>
        </div>
      </div>
    </header>
  );
};

export default Header;