import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../store';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import Button from '../../shared/TailwindComponents/Button';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import {
  acceptEngagement,
  acceptRequest,
  declineAccessRequest,
  declineEngagement,
  getEngagementDetails,
  getLawyerProfile,
  revokeAccess,
  getEngagementTerms,
} from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';
import Textarea from '../../shared/TailwindComponents/Textarea';
import { sendMessage } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import ActivityFeed from '../shared/ActivityFeed';
import CaseInformation from '../Lawyer/CaseInformation';
import Tabs from '../../shared/TailwindComponents/Tabs';
import TabStatusBar from '../../shared/TailwindComponents/TabStatusBar';
import { useTranslation } from 'react-i18next';
import LawyerProfileComponent from './LawyerProfile';
import ApiLoader from '../../ApiLoader';
import { fetchCaseSummary } from '../../../store/actions/marketplace2/caseActions';
import { fetchCaseAnalysisData } from '../../../store/actions/marketplace2/caseAnalysisActions';
import { EngagementStatus } from '../shared/engagementStatus'; 
import { getTabStatuses as getEngagementTabStatuses } from '../shared/functions';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface RouteParams {
  engagementId: string;
}

interface LocationState {
  engagement: any;
}

// Helper functions to decide which actions to show.
const isAccessPending = (status: string): boolean =>
  [EngagementStatus.AccessRequested, EngagementStatus.Invited, EngagementStatus.AccessDeclined].includes(status as EngagementStatus);

const isAccessGranted = (status: string): boolean =>
  [EngagementStatus.InvitationAccepted, EngagementStatus.AccessApproved].includes(status as EngagementStatus);

const isTermsPending = (status: string): boolean =>
  status === 'TermsSubmitted'; // This should be set by the backend when lawyer sets terms.

const isEngagementActive = (status: string): boolean =>
  status === EngagementStatus.EngagementOngoing;

const ClientEngagementDetails: React.FC = () => {
  const { engagementId } = useParams<RouteParams>();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.userInfo);
  const fullName = `${user.FirstName} ${user.LastName}`;

  // Local state
  const [engagement, setEngagement] = useState<any>(location.state?.engagement || null);
  const [lawyerProfile, setLawyerProfile] = useState<any>(null);
  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [analysisData, setAnalysisData] = useState<any>(null);
  const [engagementTerms, setEngagementTerms] = useState<any>(null);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(!engagement);
  // For client view we use these four tabs:
  const [activeTab, setActiveTab] = useState<string>('engagement');

  // Set breadcrumbs on mount.
  useEffect(() => {
    setBreadcrumbs([
      { name: t('Marketplace'), href: '/marketplace', current: false },
      { name: t('Engagement Details'), href: `/marketplace/engagement/${engagementId}`, current: true },
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs, engagementId, t]);

  // Fetch engagement details.
  useEffect(() => {
    fetchEngagement();
  }, [engagementId, dispatch]);

  const fetchEngagement = async () => {
    setIsLoading(true);
    try {
      const fetchedEngagement = await dispatch(getEngagementDetails(engagementId));
      if (fetchedEngagement) {
        setEngagement(fetchedEngagement);
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to load engagement details.'),
            modalType: 'error',
            duration: 3000,
          })
        );
        history.push('/marketplace?tab=engagements');
      }
    } catch (error) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Failed to load engagement details.'),
          modalType: 'error',
          duration: 3000,
        })
      );
      history.push('/marketplace?tab=engagements');
    } finally {
      setIsLoading(false);
    }
  };

  // Once engagement is available, fetch related data.
  useEffect(() => {
    if (engagement) {
      dispatch(getLawyerProfile(engagement.LawyerID.toString())).then((profile: any) => {
        setLawyerProfile(profile);
      });
      if (engagement.CaseID) {
        dispatch(fetchCaseSummary(engagement.CaseID)).then((response: any) => {
          setCaseDetails(response);
        });
      }
      if (engagement.AdditionalTerms !== null) {
        dispatch(getEngagementTerms(engagement.EngagementID)).then((terms: any) => {
          setEngagementTerms(terms);
        });
      }
    }
  }, [engagement, dispatch]);

  // Fetch analysis data when "analysis" tab is active.
  useEffect(() => {
    if (activeTab === 'analysis' && engagement && engagement.CaseID) {
      dispatch(fetchCaseAnalysisData(engagement.CaseID)).then((data: any) => {
        setAnalysisData(data);
      });
    }
  }, [activeTab, engagement, dispatch]);

  // Messaging handler.
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    setIsSending(true);
    try {
      await dispatch(sendMessage(engagement.EngagementID, newMessage));
      const newMsg = {
        MessageID: Math.random().toString(36).substr(2, 9),
        EngagementID: engagement.EngagementID,
        SenderID: user.UserID,
        SenderName: fullName,
        Message: newMessage,
        CreatedAt: new Date().toISOString(),
      };
      setEngagement({
        ...engagement,
        Messages: [...(engagement.Messages || []), newMsg],
      });
      setNewMessage('');
    } catch (error) {
      // Optionally handle error.
    } finally {
      setIsSending(false);
    }
  };

  // Handler to revoke access (for granted engagements).
  const handleRevokeAccess = async () => {
    const response = await dispatch(revokeAccess(engagement.EngagementID));
    if (response && response.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Access revoked successfully.'),
          modalType: 'success',
          duration: 3000,
        })
      );
      fetchEngagement();
    }
  };

  // Action handlers invoked via confirmation modals.
  const handleAcceptRequest = async () => {
    const resp = await dispatch(acceptRequest({ EngagementID: engagement.EngagementID }));
    if (resp && resp.status === 200) {
      setEngagement({ ...engagement, Status: EngagementStatus.InvitationAccepted });
    }
    fetchEngagement();
  };

  const handleDeclineRequest = async () => {
    const resp = await dispatch(declineAccessRequest({ EngagementID: engagement.EngagementID }));
    if (resp && resp.status === 200) {
      setEngagement({ ...engagement, Status: EngagementStatus.AccessDeclined });
    }
    fetchEngagement();
  };

  const handleAcceptEngagementTerms = async () => {
    const resp = await dispatch(acceptEngagement({ EngagementID: engagement.EngagementID, AcceptedTerms: true }));
    if (resp && resp.status === 200) {
      setEngagement({ ...engagement, Status: EngagementStatus.EngagementOngoing });
    }
    fetchEngagement();
  };

  const handleDeclineEngagementTerms = async () => {
    const resp = await dispatch(declineEngagement({ EngagementID: engagement.EngagementID }));
    if (resp && resp.status === 200) {
      setEngagement({ ...engagement, Status: EngagementStatus.ProposalDeclined });
    }
    fetchEngagement();
  };

  // openModal to show a confirmation modal before calling the handler.
  const openModal = (action: 'acceptRequest' | 'declineRequest' | 'acceptEngagement' | 'declineEngagement') => {
    const actionMap: Record<string, { title: string; message: string; onConfirm: () => Promise<void> }> = {
      acceptRequest: {
        title: t('Accept Request'),
        message: t('Are you sure you want to accept this access request?'),
        onConfirm: handleAcceptRequest,
      },
      declineRequest: {
        title: t('Decline Request'),
        message: t('Are you sure you want to decline this access request?'),
        onConfirm: handleDeclineRequest,
      },
      acceptEngagement: {
        title: t('Accept Engagement Terms'),
        message: t('Are you sure you want to accept these engagement terms?'),
        onConfirm: handleAcceptEngagementTerms,
      },
      declineEngagement: {
        title: t('Decline Engagement Terms'),
        message: t('Are you sure you want to decline these engagement terms?'),
        onConfirm: handleDeclineEngagementTerms,
      },
    };
    const modalDetails = actionMap[action];
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        title: modalDetails.title,
        message: modalDetails.message,
        onConfirm: async () => {
          await modalDetails.onConfirm();
          dispatch(hideModal());
        },
        onCancel: () => dispatch(hideModal()),
      })
    );
  };

  // Define tabs for client view.
  const tabs = [
    { label: 'Access Info', value: 'engagement' },
    { label: 'Lawyer Profile', value: 'lawyer-profile' },
    { label: 'Engagement Terms', value: 'engagementTerms' },
    { label: 'Messages', value: 'messages', rightAligned: true },
  ];

  // Render Access Info view.
  // If the access is still pending, show Accept/Decline buttons.
  // If access is already granted, show a Revoke Access button.
  const renderEngagementInfo = () => {
    return (
      <div className="mt-4">
        {engagement ? (
          <>
            <CaseInformation engagement={engagement} />
            {isAccessPending(engagement.Status) && (
              <div className="mt-4 flex justify-end space-x-4">
                <Button variant="primary" onClick={() => openModal('acceptRequest')}>
                  {t('Accept Request')}
                </Button>
                <Button variant="neutral" onClick={() => openModal('declineRequest')}>
                  {t('Decline Request')}
                </Button>
              </div>
            )}
            {isAccessGranted(engagement.Status) && (
              <div className="mt-4 flex justify-end">
                <Button variant="neutral" onClick={handleRevokeAccess}>
                  {t('Revoke Access')}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="text-center text-gray-500">{t('Access info is not available yet.')}</div>
        )}
      </div>
    );
  };

  // Render Lawyer Profile view.
  const renderLawyerProfile = () => {
    return lawyerProfile ? (
      <LawyerProfileComponent suppressBreadcrumbs={true} lawyerProfile={lawyerProfile} />
    ) : (
      <div className="text-center text-gray-500 mt-4">{t('No lawyer profile available')}</div>
    );
  };
  const renderEngagementTerms = () => {
    if (!engagementTerms) {
      // The backend returned nothing, or you never called getEngagementTerms
      return (
        <div className="mt-4 text-gray-500">
          {t('No engagement terms have been set yet.')}
        </div>
      );
    }
  
    // 1) If the entire data is just a string (e.g., raw text or partial JSON that couldn’t parse):
    if (typeof engagementTerms === 'string') {
      return (
        <div className="mt-4 space-y-4 bg-white dark:bg-gray-800 p-4 rounded-md shadow">
          <h2 className="text-xl font-semibold mb-2">{t('Engagement Terms')}</h2>
          <div className="border-t pt-4 mt-4">
            <h3 className="text-lg font-semibold mb-2">{t('Full Engagement Details')}</h3>
            <div className="mt-2 prose dark:prose-invert">
              {/* You can try to parse as Markdown or just show raw text */}
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {engagementTerms}
              </ReactMarkdown>
            </div>
          </div>
  
          {/* If waiting on client acceptance, show buttons */}
          {engagement.Status === 'TermsSubmitted' && (
            <div className="mt-4 flex justify-end space-x-4">
              <Button variant="primary" onClick={() => openModal('acceptEngagement')}>
                {t('Accept Terms')}
              </Button>
              <Button variant="neutral" onClick={() => openModal('declineEngagement')}>
                {t('Decline Terms')}
              </Button>
            </div>
          )}
        </div>
      );
    }
  
    // 2) If engagementTerms is an object but with a nested 'engagementLetter' property:
    let doc = null;
    if (engagementTerms.engagementLetter) {
      // e.g. { engagementLetter: {... fields...} }
      doc = engagementTerms.engagementLetter;
    } else {
      // 3) Possibly the entire object *is* the letter
      doc = engagementTerms;
    }
  
    // If after that, doc is not an object or missing keys, we display a fallback or partial display.
    if (typeof doc !== 'object' || Array.isArray(doc)) {
      // Means it’s still not in the structure we expect
      return (
        <div className="mt-4 text-gray-500">
          {t('Invalid engagement terms format.')}
        </div>
      );
    }
  
    // Now we have doc in an object shape.
    const {
      clientName,
      lawyerName,
      jurisdiction,
      subject,
      estimatedTimeline,
      paymentDetails,
      engagementDetails,
      caseTermInfo
    } = doc;
  
    return (
      <div className="mt-4 space-y-4 bg-white dark:bg-gray-800 p-4 rounded-md shadow">
        <h2 className="text-xl font-semibold mb-2">{t('Engagement Terms')}</h2>
  
        {/* Top grid: Basic metadata (excluding any IDs) */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  
          {/* Just showing CaseType, not CaseID */}
          {caseTermInfo && caseTermInfo.CaseType && (
            <div>
              <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t('Case Type')}:
              </strong>
              <div className="text-gray-900 dark:text-gray-100">
                {caseTermInfo.CaseType}
              </div>
            </div>
          )}
  
          <div>
            <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {t('Client Name')}:
            </strong>
            <div className="text-gray-900 dark:text-gray-100">
              {clientName || '-'}
            </div>
          </div>
  
          <div>
            <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {t('Lawyer Name')}:
            </strong>
            <div className="text-gray-900 dark:text-gray-100">
              {lawyerName || '-'}
            </div>
          </div>
  
          <div>
            <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {t('Jurisdiction')}:
            </strong>
            <div className="text-gray-900 dark:text-gray-100">
              {jurisdiction || '-'}
            </div>
          </div>
  
          <div>
            <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {t('Subject')}:
            </strong>
            <div className="text-gray-900 dark:text-gray-100">
              {subject || '-'}
            </div>
          </div>
  
          {estimatedTimeline && (
            <div>
              <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t('Estimated Timeline')}:
              </strong>
              <div className="text-gray-900 dark:text-gray-100">
                {estimatedTimeline}
              </div>
            </div>
          )}
        </div>
  
        {/* Payment details, if any */}
        {paymentDetails && typeof paymentDetails === 'object' && !Array.isArray(paymentDetails) && (
          <div className="border-t pt-4 mt-4">
            <h3 className="text-lg font-semibold mb-2">{t('Payment Details')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  
              <div>
                <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Billing Method')}:
                </strong>
                <div className="text-gray-900 dark:text-gray-100">
                  {paymentDetails.BillingMethod || '-'}
                </div>
              </div>
  
              <div>
                <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Hourly Rate')}:
                </strong>
                <div className="text-gray-900 dark:text-gray-100">
                  {paymentDetails.HourlyRate || '-'}
                </div>
              </div>
  
              <div>
                <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Agreed Fee')}:
                </strong>
                <div className="text-gray-900 dark:text-gray-100">
                  {paymentDetails.AgreedFee || '-'}
                </div>
              </div>
  
              <div>
                <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Deposit')}:
                </strong>
                <div className="text-gray-900 dark:text-gray-100">
                  {String(paymentDetails.Deposit ?? '-')}
                </div>
              </div>
  
              {/* Optionally show more fields: PaymentMethod, PaymentInterval, etc. */}
              {paymentDetails.PaymentInterval && (
                <div>
                  <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {t('Payment Interval')}:
                  </strong>
                  <div className="text-gray-900 dark:text-gray-100">
                    {paymentDetails.PaymentInterval}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
  
        {/* The big engagementDetails text rendered via Markdown */}
        {engagementDetails && (
          <div className="border-t pt-4 mt-4">
            <h3 className="text-lg font-semibold mb-2">{t('Full Engagement Details')}</h3>
            <div className="mt-2 prose dark:prose-invert">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {engagementDetails}
              </ReactMarkdown>
            </div>
          </div>
        )}
  
        {/* Approve/Decline buttons (only if the status is waiting on acceptance) */}
        {engagement.Status === 'TermsSubmitted' && (
          <div className="mt-4 flex justify-end space-x-4">
            <Button variant="primary" onClick={() => openModal('acceptEngagement')}>
              {t('Accept Terms')}
            </Button>
            <Button variant="neutral" onClick={() => openModal('declineEngagement')}>
              {t('Decline Terms')}
            </Button>
          </div>
        )}
      </div>
    );
  };
  

  // Render Messages view.
  const renderMessages = () => {
    const messages = engagement.Messages || [];
    return (
      <div>
        {messages.length === 0 ? (
          <div className="text-center text-gray-500 mt-4">{t('No messages yet')}</div>
        ) : (
          <ActivityFeed messages={messages} />
        )}
        <div className="mt-6 flex gap-x-3">
          <img
            alt=""
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(fullName)}&background=random`}
            className="h-10 w-10 flex-none rounded-full bg-gray-50"
          />
          <div className="relative flex-auto">
            <Textarea
              name="newMessage"
              placeholder={t('Add your message...')}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              rows={2}
              id=""
              label=""
            />
            <div className="absolute right-0 bottom-0 flex items-center space-x-2 p-2">
              <Button type="button" onClick={handleSendMessage} disabled={isSending || newMessage.trim() === ''} variant="primary">
                {isSending ? t('Sending...') : t('Send')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <ApiLoader />;
  }
  if (!engagement) return null;

  return (
    <div className="px-4 py-6">
      <div className="px-4">
        <TabStatusBar tabs={getEngagementTabStatuses(engagement.Status)} />
        <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
      </div>
      {activeTab === 'engagement' && renderEngagementInfo()}
      {activeTab === 'lawyer-profile' && renderLawyerProfile()}
      {activeTab === 'engagementTerms' && renderEngagementTerms()}
      {activeTab === 'messages' && renderMessages()}
    </div>
  );
};

export default ClientEngagementDetails;
