import { Dispatch } from 'redux';
import apis from '../../utils/apis';
import { handleApiError } from './utils/utils';
import { showFeedbackModal } from './UserFeedbackActions';
export const SET_INDEXES = 'SET_INDEXES';

// Action to set indexes in the Redux store
export const setIndexes = (indexes: any[]) => ({
  type: SET_INDEXES,
  payload: indexes,
});

/**
 * Fetch list of indexes and store them in Redux.
 */
export const fetchIndexes = (params: { searchTerm?: string; filters?: { [key: string]: string[] }; sortBy?: string; sortOrder?: string } = {}) => async (dispatch: Dispatch<any>) => {
  try {
    const { searchTerm = '', filters = {}, sortBy = '', sortOrder = 'asc' } = params;
    const filtersJson = JSON.stringify(filters);

    const resp = await apis({
      method: 'POST',
      url: '/ai/aistudio/list_indexes',
      data: { searchTerm, filters: filtersJson, sortBy, sortOrder },
    });

    if (resp.status === 200) {
      dispatch(setIndexes(resp.data.data.indexes || []));
    } else {
      handleApiError(resp, dispatch);
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

/**
 * Create a new index with initial data (files).
 * indexName: string
 * files: File[]
 */
export const createIndex = (indexName: string, files: File[], agentType: string, systemInstruction?: string) => async (dispatch: Dispatch<any>) => {
    try {
      const formData = new FormData();
      formData.append('IndexName', indexName);
      formData.append('AgentType', agentType);
      if (systemInstruction) {
        formData.append('SystemInstruction', systemInstruction);
      }
      files.forEach((file) => {
        formData.append('File', file);
      });
  
      const resp = await apis({
        method: 'POST',
        url: '/ai/aistudio/create_index',
        data: formData,
      });
  
      if (resp.status === 200 && resp.data.status === 'success') {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message,
            modalType: 'success',
            duration: 3000,
          })
        );
        // Refresh indexes
        dispatch(fetchIndexes());
        return resp.data;
      } else {
        throw resp;
      }
    } catch (error) {
      handleApiError(error, dispatch);
    }
  };

/**
 * Add data to an existing index.
 * indexName: string
 * files: File[]
 */
export const addDataToIndex = (indexName: string, files: File[]) => async (dispatch: Dispatch<any>) => {
    try {
      const formData = new FormData();
      formData.append('IndexName', indexName);
      files.forEach((file) => {
        formData.append('File', file);
      });
  
      const resp = await apis({
        method: 'POST',
        url: '/ai/aistudio/add_data',
        data: formData,
      });
  
      if (resp.status === 200 && resp.data.status === 'success') {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message,
            modalType: 'success',
            duration: 3000,
          })
        );
        return resp.data;
      } else {
        throw resp;
      }
    } catch (error) {
      handleApiError(error, dispatch);
    }
  };
  
/**
 * Get info about a specific index.
 * indexName: string
 */
export const getIndexInfo = (indexName: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/ai/aistudio/index_info',
      data: { IndexName: indexName },
    });

    if (resp.status === 200) {
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

/**
 * List files in a given index.
 * indexName: string
 */
export const listFilesInIndex = (indexName: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/ai/aistudio/list_files',
      data: { IndexName: indexName },
    });

    if (resp.status === 200 && resp.data.status === 'success') {
      return resp.data || [];
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

/**
 * Delete a single file from an index.
 * indexName: string
 * fileName: string
 */
export const deleteFileFromIndex = (indexName: string, fileName: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/ai/aistudio/delete_file',
      data: { IndexName: indexName, FileName: fileName },
    });

    if (resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message,
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

/**
 * Delete an index completely.
 * indexName: string
 */
export const deleteIndex = (indexName: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/ai/aistudio/delete_index',
      data: { IndexName: indexName },
    });

    if (resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message,
          modalType: 'success',
          duration: 3000,
        })
      );
      // Refresh indexes list after deletion
      dispatch(fetchIndexes());
      return resp.data;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

/**
 * Chat with a given index.
 * indexName: string
 * prompt: string
 */
export const chatWithIndex = (indexName: string, prompt: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/ai/aistudio/chat',
      data: { IndexName: indexName, Prompt: prompt },
    });

    if (resp.status === 200) {
      return resp.data.data.ChatResponse;
    } else {
      throw resp;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const updateSystemInstruction = (indexName: string, systemInstruction: string) => async (dispatch: Dispatch<any>) => {
    try {
      const resp = await apis({
        method: 'POST',
        url: '/ai/aistudio/update_system_instruction',
        data: { IndexName: indexName, SystemInstruction: systemInstruction },
      });
  
      if (resp.status === 200) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message,
            modalType: 'success',
            duration: 3000,
          })
        );
        return resp.data;
      } else {
        throw resp;
      }
    } catch (error) {
      handleApiError(error, dispatch);
    }
  };
