import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { RootState, useAppDispatch } from "../../store";
import { createTicket, fetchAllUserTickets, fetchCategories } from "../../store/actions/HelpAction";
import { getUserType } from "../../store/actions/ChatAction";
import Input from "../shared/TailwindComponents/Input";
import Textarea from "../shared/TailwindComponents/Textarea";
import Button from "../shared/TailwindComponents/Button";
import Dropdown from "../shared/TailwindComponents/Dropdown";
import { hideSidePanel } from "../../store/actions/sidePanelActions";

const SupportTicketForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const categories = useSelector((state: RootState) => state.help.categories);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]); // Multiple image files
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsCategoriesLoading(true);
    dispatch(fetchCategories()).finally(() => setIsCategoriesLoading(false));
  }, [dispatch]);

  const initialValues = {
    Subject: "",
    Description: "",
    Category: "",
  };

  const validationSchema = Yup.object({
    Subject: Yup.string().required(t("Subject is required")),
    Description: Yup.string().required(t("Description is required")),
    Category: Yup.string().required(t("Category is required")),
  });

  const onSubmit = async (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: () => void }
  ) => {
    const ticketData = {
      UserType: userType,
      ...values,
      Files: files, // Attach selected images (optional)
    };

    await dispatch(createTicket(ticketData))
      .then(() => {
        resetForm();
        setFiles([]); // Clear uploaded images after submission
        dispatch(hideSidePanel());
      })
      .finally(() => {
        // dispatch(fetchAllUserTickets())
        setSubmitting(false)});
  };

  // ✅ Allowed image file types
  const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif", "image/webp", "image/bmp"];

  // ✅ Handle File Selection (Only Images Allowed)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);

      const validFiles = selectedFiles.filter((file) => allowedImageTypes.includes(file.type));

      if (validFiles.length < selectedFiles.length) {
        alert(t("Only image files (JPG, PNG, GIF, WebP, BMP) are allowed."));
      }

      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  // ✅ Handle Drag & Drop (Only Images Allowed)
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files) {
      const droppedFiles = Array.from(event.dataTransfer.files);

      const validFiles = droppedFiles.filter((file) => allowedImageTypes.includes(file.type));

      if (validFiles.length < droppedFiles.length) {
        alert(t("Only image files (JPG, PNG, GIF, WebP, BMP) are allowed."));
      }

      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  // ✅ Prevent Default Drag Events
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // ✅ Remove a Specific File
  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mt-2">{t("Submit a Support Ticket")}</h2>
      <p className="text-gray-600 dark:text-gray-300 text-sm mb-4">
        {t("Submit your support ticket and we will get back to you within 48 hours.")}
      </p>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4 w-full">
            <Input
              type="text"
              id="Subject"
              name="Subject"
              label={t("Subject")}
              value={values.Subject}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.Subject && touched.Subject ? errors.Subject : ""}
            />
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t("Category")}
              </label>
              <Dropdown
                options={categories || []}
                onChange={(selectedValue) => setFieldValue("Category", selectedValue)}
                value={values.Category}
                placeholder={t("Select a category")}
              />
              {errors.Category && touched.Category && (
                <p className="mt-2 text-sm text-danger-500">{t(errors.Category)}</p>
              )}
            </div>
            <Textarea
              id="Description"
              name="Description"
              label={t("Description")}
              value={values.Description}
              onChange={handleChange}
              error={errors.Description && touched.Description ? t(errors.Description) : ""}
            />

            {/* ✅ Optional Image Upload */}
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t("Upload Screenshots (Optional)")}
              <input
                ref={fileInputRef}
                id="fileUploadInput"
                type="file"
                multiple // ✅ Allows multiple image selection
                accept="image/png, image/jpeg, image/jpg, image/gif, image/webp, image/bmp"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <div
                className="mt-2 flex flex-col items-center justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md cursor-pointer dark:border-gray-600"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  fileInputRef.current?.click();
                }}
              >
                <p className="text-sm text-gray-600 dark:text-gray-200">
                  {t("Drag and drop images here or click to upload")}
                </p>
              </div>
            </label>

            {/* ✅ Display Selected Images */}
            {files.length > 0 && (
              <ul className="mt-2 border p-2 rounded-md bg-gray-50 dark:bg-gray-700">
                {files.map((file, index) => (
                  <li key={index} className="flex justify-between items-center p-1 text-sm">
                    <span className="truncate">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveFile(index)}
                      className="text-red-500 ml-2"
                    >
                      ❌
                    </button>
                  </li>
                ))}
              </ul>
            )}

            <Button size='small' type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? t("Submitting...") : t("Submit Ticket")}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SupportTicketForm;
