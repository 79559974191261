import React, { useState, useEffect } from 'react';
import apis from '../../../utils/apis';
import LicenseCard from './LicenseCards';
import { useTranslation } from 'react-i18next';

interface LicenseType {
  LicenseID: number;
  LicenseName: string;
  Description?: string;
  DurationInDays: number;
  FinalPrice: number;
  IsSubscription: boolean;
  IsOneTimePurchase: boolean;
  MonthlyPriceEstimate?: number;
  SavingsFor4MonthsPercent?: number | null;
  SavingsFor6MonthsPercent?: number | null;
  SavingsFor12MonthsPercent?: number | null;
  SavingsComparedToShortestSub?: number | null;
  TotalQueries: number;
  MinimumUsers: number;

  // NEW FIELDS
  KeyBenefits?: string[];
  RecommendedUseCases?: string[];
  SupportLevel?: string;
  MinimumQuantity: number;
  isAddOn: boolean;
}

interface ApiResponse {
  availableDurations: number[];
  groupedLicenses: Record<number, LicenseType[]>;
  oneTimePurchases: Record<number, LicenseType[]>;
}

const LicensesPage: React.FC = () => {
  const [country, setCountry] = useState(null);
  const [availableDurations, setAvailableDurations] = useState<number[]>([]);
  const [selectedDuration, setSelectedDuration] = useState<number | null>(null);
  const [groupedLicenses, setGroupedLicenses] = useState<ApiResponse['groupedLicenses']>({});
  const [oneTimePurchases, setOneTimePurchases] = useState<ApiResponse['oneTimePurchases']>({});
  const [loading, setLoading] = useState<boolean>(true);
  const {t} = useTranslation()

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setCountry(data.country_name); // Fetch country name (e.g., United States)
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    };

    fetchCountry();
  }, []);

  useEffect(() => {
    if(country){
      fetchLicenses(country);
    }
  }, [country]);

  async function fetchLicenses(ctry: string) {
    setLoading(true);
    try {
      const response = await apis({
        method: 'GET',
        url: `/licenses?country=${ctry}`,
      });

      if (response && response.status === 200) {
        const data: ApiResponse = response.data;
        setAvailableDurations(data.availableDurations);
        setGroupedLicenses(data.groupedLicenses);
        setOneTimePurchases(data.oneTimePurchases);

        // Default: pick first duration if available
        if (data.availableDurations.length > 0) {
          setSelectedDuration(data.availableDurations[0]);
        } else {
          setSelectedDuration(null);
        }
      }
    } catch (error) {
      console.error('Error fetching licenses:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleDurationChange = (duration: number) => {
    setSelectedDuration(duration);
  };

  // Subscriptions for whichever duration is selected
  const subscriptionsForSelectedDuration: LicenseType[] = selectedDuration
    ? groupedLicenses[selectedDuration] || []
    : [];

  const otpForSelectedDuration: LicenseType[] = selectedDuration
    ? oneTimePurchases[selectedDuration] || []
    : [];

  return (
    <div className='dark:bg-gray-900 dark:text-gray-100 bg-gray-100 text-gray-900 min-h-screen'>
      <header className="p-4 flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold">{t("Licenses")}</h1>
          <p className="text-sm text-gray-600 dark:text-gray-300">{t("Professional Packages")}</p>
        </div>
      </header>

      <main className="p-4 max-w-7xl mx-auto">
        {loading ? (
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
          </div>
        ) : (
          <>
            {/* Duration Toggle */}
            <div className="flex justify-center  gap-2 mb-6 flex-wrap">
              {availableDurations.map((dur) => (
                <button
                  key={dur}
                  onClick={() => handleDurationChange(dur)}
                  className={`px-4 py-2 rounded-full border ${selectedDuration === dur
                      ? 'bg-indigo-500 text-white border-indigo-500'
                      : 'bg-white border-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600'
                    } transition duration-200 hover:shadow`}
                >
                  {Math.round(dur / 30)} {Math.round(dur / 30) === 1 ? t('Month') : t('Months')}
                </button>
              ))}
            </div>

            {/* Subscriptions for selected duration */}
            {selectedDuration && subscriptionsForSelectedDuration.length > 0 ? (
              <>
                <h2 className="text-xl font-semibold mb-3">
                  {t("Subscription Plans")} ({Math.round(selectedDuration / 30)}{' '}
                  {Math.round(selectedDuration / 30) === 1 ? t('Month') : t('Months')})
                </h2>
                <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {subscriptionsForSelectedDuration.map((license) => (
                    <LicenseCard key={license.LicenseID} {...license} selectedDuration={selectedDuration} country={country} />
                  ))}
                </div>
              </>
            ) : (
              selectedDuration && (
                <p className="text-gray-600 dark:text-gray-300 text-center mt-4">
                  {t("No subscription licenses available for this duration.")}
                </p>
              )
            )}

            {selectedDuration && otpForSelectedDuration.length > 0 && (
              <>
                <h2 className="text-xl font-semibold mt-6 mb-3">{t("One-Time Purchase Plans")}</h2>
                <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {otpForSelectedDuration.map((license) => (
                    <LicenseCard key={license.LicenseID} {...license} selectedDuration={selectedDuration} country={country} />
                  ))}
                </div>
              </>
            )
            }
          </>
        )}
      </main>
    </div>
  );
};

export default LicensesPage;
