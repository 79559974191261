import { Task } from "../../components/Tasks/types";
import apis from "../../utils/apis";
import {
  FETCH_TASKS,
  FETCH_TASK_DETAILS,
  CREATE_TASK_SUCCESS,
  ASSIGN_TASK_SUCCESS,
  UPDATE_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  COMPLETE_TASK_SUCCESS,
  FETCH_TASK_PREFERENCES,
  UPDATE_TASK_PREFERENCES,
} from "../types";
import { AppThunk } from "../types";
import { showFeedbackModal } from "./UserFeedbackActions";
import { handleApiError } from "./utils/utils";

// Fetch all tasks
export const fetchAllTasks = (params: {
  searchTerm?: string;
  filters?: { [key: string]: any }; 
  sortBy?: string;
  sortOrder?: string;
} = {}): AppThunk => async (dispatch) => {
  try {
    const { searchTerm = '', filters = {}, sortBy = '', sortOrder = 'asc' } = params;
    const filtersJson = JSON.stringify(filters);

    const response = await apis({
      method: "GET",
      url: "/tasks/get_all_tasks",
      params: {
        searchTerm,
        filters: filtersJson,
        sortBy,
        sortOrder
      }
    });

    if (response && response.status === 200) {
      let returnedTasks: any[] = [];
      if ('data' in response.data) {
        returnedTasks = Array.isArray(response.data.data) ? response.data.data : [];
      } else if ('tasks' in response.data) {
        returnedTasks = Array.isArray(response.data.tasks) ? response.data.tasks : [];
      }

      dispatch({
        type: FETCH_TASKS,
        payload: returnedTasks,
      });
    }else{
      handleApiError(response, dispatch)
    }
  } catch (error) {
    console.error("Error fetching tasks", error);
    handleApiError(error, dispatch)
  }
};

// Fetch task details
export const fetchTaskDetails = (taskId: string) => {
  return async (dispatch: any) => {
    try {
      const response = await apis.post("/tasks/get_task", { TaskID: taskId });
      if (response && response.status === 200) {
        dispatch({
          type: FETCH_TASK_DETAILS,
          payload: response.data.TaskDetails,
        });
        return response.data
      }else{
        handleApiError(response, dispatch)
      }
    } catch (error) {
      console.error("Error fetching task details", error);
      handleApiError(error, dispatch)
      return null
    }
  }
};

export const createTask = (data: {
  TaskTitle: string;
  TaskDescription: string;
  TaskDeadline: string;
  CaseID?: string;
  ProjectID?: string;
  TeamID?: string;
}): AppThunk<Promise<any>> => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/create_task", data);
    if (response && response.status === 200) {
      dispatch({
        type: CREATE_TASK_SUCCESS,
        payload: response.data,
      });
      return response.data;
    }else{
      handleApiError(response, dispatch)
    }
  } catch (error) {
    console.error("Error creating task", error);
    handleApiError(error, dispatch)
    throw error;
  }
};


// Assign a task
export const assignTask = (
  taskId: string,
  assignees: string[],
  projectId?: string,
  caseId?: string
): AppThunk => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/assign_task", {
      TaskID: taskId,
      Assignees: assignees,
      ...(projectId && { ProjectID: projectId }),
      ...(caseId && { CaseID: caseId }),
    });
    if (response && response.status === 200) {
      dispatch({
        type: ASSIGN_TASK_SUCCESS,
        payload: response.data,
      });
    }else{
      handleApiError(response, dispatch)
    }
  } catch (error) {
    console.error("Error assigning task", error);
    handleApiError(error, dispatch)
    throw error;
  }
};

// Update a task
export const updateTask = (data: Partial<Task>): AppThunk => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/update_task", data);
    if (response && response.status === 200) {
      dispatch({
        type: UPDATE_TASK_SUCCESS,
        payload: response.data,
      });
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: response.data.message || "Task updated successfully.",
        showModal: true,
        duration: 3000
      }));
    }else{
      handleApiError(response, dispatch)
    }
  } catch (error) {
    console.error("Error updating task", error);
    handleApiError(error, dispatch)
  }
};

// Complete a task (sets status to "Completed")
export const completeTask = (taskId: string, comment?:string): AppThunk => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/update_task", {
      TaskID: taskId,
      Status: "Completed",
      Comment: comment
    });
    if (response && response.status === 200) {
      dispatch({
        type: COMPLETE_TASK_SUCCESS,
        payload: response.data,
      });
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: "Task completed",
        showModal: true,
        duration: 3000

      }));
    }else{
      handleApiError(response, dispatch)
    }
  } catch (error) {
    console.error("Error completing task", error);
    handleApiError(error, dispatch)
  }
};

// Delete a task
export const deleteTask = (taskId: string): AppThunk => async (dispatch) => {
  try {
    const response = await apis.post("/tasks/delete_task", { TaskID: taskId });
    if (response && response.status === 200) {
      dispatch({
        type: DELETE_TASK_SUCCESS,
        payload: taskId,
      });
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: response.data.message || "Task deleted",
        showModal: true,
        duration: 3000

      }));
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error) {
    console.error("Error deleting task", error);
    handleApiError(error, dispatch)
  }
};


export const getTaskPreferences = (): AppThunk<Promise<any>> => async (dispatch) => {
  try {
    const response = await apis.get("/task-preferences");
    if (response && response.status === 200) {
      dispatch({
        type: FETCH_TASK_PREFERENCES,
        payload: response.data,
      });
      return response.data;
    } else {
      handleApiError(response, dispatch);
    }
  } catch (error) {
    console.error("Error fetching task preferences", error);
    handleApiError(error, dispatch);
    return null;
  }
};

export const updateTaskPreferences = (preferences: Record<string, boolean>): AppThunk<Promise<any>> => async (dispatch) => {
  try {
    const response = await apis.post("/task-preferences", { preferences });
    if (response && response.status === 200) {
      dispatch({
        type: UPDATE_TASK_PREFERENCES,
        payload: preferences,
      });
      return response.data;
    } else {
      handleApiError(response, dispatch);
    }
  } catch (error) {
    console.error("Error updating task preferences", error);
    handleApiError(error, dispatch);
    return null;
  }
};
