import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface TextareaProps {
  id: string;
  name?: string; 
  label: string;
  placeholder?: string;
  value?: string;
  rows?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean; 
  error?: string | Boolean; 
  description?: string;
  italicTxt?: string;
  h?: string;
  icon?: React.ReactNode;
  className?: string;
}

const Textarea: React.FC<TextareaProps> = ({
  id,
  name,
  label,
  placeholder,
  value,
  rows = 4,
  onChange,
  disabled,
  error,
  description,
  italicTxt,
  className,
  h,
  icon
}) => {
  const { t } = useTranslation();

  return (
    <div className='w-full'>
      {label && (
        <label htmlFor={id} className="block text-gray-700 dark:text-gray-300 mb-2 font-medium text-sm">
        {t(label)}
      </label>
      )}
      {italicTxt || description && (
        <div className='flex flex-col space-y-1 mb-2'>
      {italicTxt && (
        <p className='text-xs text-gray-500 dark:text-gray-300 text-left italic'>{t(italicTxt)}</p>
      )}
      {description && (
        <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t(description)}</p>
      )}
            </div>
      )}
      <div className="relative">
        {icon && <div className="absolute left-3 top-4">{icon}</div>}
        <textarea
          id={id}
          name={name}
          className={clsx(
            `block text-xs w-full px-3 py-2 bg-gray-50 dark:bg-gray-800 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 transition duration-300 input ${h}`,
            icon ? 'pl-10' : 'pl-3',
            h || 'h-24',
            className
          )}
          placeholder={placeholder}
          value={value}
          rows={rows}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
            {error && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">{error as string}</p>
        )}
    </div>
  );
};

export default Textarea;
