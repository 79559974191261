import axios from 'axios';
import { config, isTokenExpired } from "./config";
import SocketServices from './SocketServices';
import store from '../store';
import { showFeedbackModal } from '../store/actions/UserFeedbackActions';

SocketServices.initializeSocket();

const apis = axios.create({
    baseURL: `${config.baseUrl}`,
    // timeout: 15000,  // Set timeout to 15 seconds
});

export default apis;

// Time tracking variables
let lastApiCallTime = Date.now();
const CHECK_INTERVAL = 1 * 60 * 60 * 1000;  // 1 hour

// Function to check backend health before making requests
const checkBackendStatus = async () => {
    try {
        console.log("[Health Check] Checking backend connection...");
        const response = await axios.get(`${config.baseUrl}/health-check`, { timeout: 10000 });
        return response.status === 200;
    } catch (error: any) {
        console.error("Backend connection error:", error.message);
        return false;
    }
};

// Function to determine if health check is needed
const shouldPerformHealthCheck = () => {
    const currentTime = Date.now();
    const timeSinceLastCall = currentTime - lastApiCallTime;
    return timeSinceLastCall >= CHECK_INTERVAL; // True if no API calls in the last 5 minutes
};

// Request Interceptor
apis.interceptors.request.use(async (config) => {
    if (shouldPerformHealthCheck()) {
        const isBackendAvailable = await checkBackendStatus();

        if (!isBackendAvailable) {
            store.dispatch(showFeedbackModal({
                showModal: true,
                message: "Server is currently unavailable. Please try again later.",
                modalType: 'error',
                duration: 5000
            }));
            return Promise.reject(new Error("Backend is unreachable"));
        }
    }

    lastApiCallTime = Date.now(); // Update last API call timestamp

    const auth_token = localStorage.getItem("token") || sessionStorage.getItem("token");
    let accessToken = "";

    if (auth_token) {
        accessToken = JSON.parse(auth_token).token;
    }

    if (accessToken) {
        if (!isTokenExpired(accessToken)) {
            Object.assign(config.headers, {
                Authorization: `Bearer ${accessToken}`,
            });
        }
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

// Response Interceptor
apis.interceptors.response.use(
    (response) => {
        lastApiCallTime = Date.now(); // Update last API call timestamp
        return response;
    },
    (error) => {
        const dispatch = store.dispatch;
        if (!error.response) {
            dispatch(showFeedbackModal({
                showModal: true,
                message: "Network error or server unreachable. Please check your connection.",
                modalType: 'error',
                duration: 5000
            }));
            return Promise.reject(error);
        }

        const { status, data } = error.response;

        switch (status) {
            case 500:
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: data?.message || 'Internal Server Error. Please try again later.',
                    modalType: 'error',
                    duration: 3000
                }));
                break;

            case 403:
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: "Unauthorized request. Login with valid credentials.",
                    modalType: 'error',
                    duration: 3000
                }));
                break;

            case 401:
                console.warn("401 Unauthorized - Redirecting to login");
                // localStorage.clear();
                // sessionStorage.clear();
                // window.location.href = "/login";
                break;

            default:
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: data?.message || 'An unexpected error occurred. Please try again.',
                    modalType: 'error',
                    duration: 3000
                }));
                break;
        }

        return Promise.reject(error);
    }
);
