import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RootState } from '../../store';
import {
  SignIn,
  forgotPassword,
  loginGoogle,
  loginMsal,
  signIn2Fa,
} from '../../store/actions/SignInAction';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../msalConfig';
import { hideModal, showModal } from '../../store/actions/modalActions';
import {
  isMobile,
  isTablet,
  isDesktop,
  browserName,
  browserVersion,
  engineName,
  engineVersion,
  getUA,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';
import SocketServices from '../../utils/SocketServices';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Import Global Components
import Button from '../shared/TailwindComponents/Button';
import Input from '../shared/TailwindComponents/Input';
import Divider from '../shared/TailwindComponents/Divider';
import { isBetaEnv } from '../../utils/authUtils';
import RegionSwitcher from './RegionSwitcher';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''
interface LoginSectionProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  toggleAuthMode: () => void;
}

const LoginSection: React.FC<LoginSectionProps> = ({
  isLoading,
  setIsLoading,
  toggleAuthMode,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const { instance } = useMsal();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Please enter a valid email ID'))
      .required(t('Email is required')),
    password: Yup.string()
      .required(t('Password is required')),
  });

  const formik = useFormik({
    initialValues: { email: '', password: '', RememberMe: false },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const sendValue: any = {
          Email: values.email,
          Password: values.password,
          RememberMe: values.RememberMe ? 1 : 0,
        };
        const signInResp: any = await dispatch(SignIn(sendValue));
        setIsLoading(false);

        if (signInResp) {
          let data: any = getDeviceInfo();
          if (signInResp.Enable2FA === "Yes") {
            dispatch(showModal({
              type: 'otp-verification',
              message: "",
              onConfirm: async (otp: string) => {
                const response: any = await dispatch(signIn2Fa(otp, sendValue.Email, sendValue.Password, sendValue.RememberMe));
                if (response && response.status === 200) {
                  dispatch(hideModal());
                  SocketServices.emit('session_created', {
                    data: data,
                    userId: signInResp.UserUID,
                    userType: signInResp.UserTypeName,
                  });
                }
                setIsLoading(false);
              },
              onCancel: () => { dispatch(hideModal()); setIsLoading(false); },
              showModal: true
            }));
            // }
          } else if (signInResp.MustChangePassword) {
            window.location.href = `/reset-password/${signInResp?.token}`;
          }
          else {
            SocketServices.emit('session_created', {
              data: data,
              userId: signInResp.UserUID,
              userType: signInResp.UserTypeName,
            });
          }


        }
        // }
      } catch (error) {
        setIsLoading(false);
        console.error('Error during login:', error);
      }
    },
  });

  const getDeviceInfo = () => {
    const deviceInfo = {
      deviceType: isMobile
        ? 'Mobile'
        : isTablet
          ? 'Tablet'
          : isDesktop
            ? 'Desktop'
            : 'Unknown',
      userAgent: getUA,
      browser: browserName,
      browserVersion: browserVersion,
      engine: engineName,
      engineVersion: engineVersion,
      os: osName,
      osVersion: osVersion,
      mobileVendor: mobileVendor || 'Unknown Vendor',
      mobileModel: mobileModel || 'Unknown Model',
    };

    const uniqueId = btoa(JSON.stringify(deviceInfo));
    return {
      ...deviceInfo,
      uniqueId,
    };
  };

  const handleForgotPasswordClick = () => {
    dispatch(
      showModal({
        type: 'forgotPassword',
        message: t('Forgot password?'),
        onConfirm: (email: string) => confirmEmailAndSendLink(email),
        showModal: false,
        subMsg: t('Please enter your email address to receive a reset link.'),
      })
    );
  };

  const confirmEmailAndSendLink = async (email: string) => {
    await dispatch(forgotPassword(email));
    // Optional: Show a success message
    dispatch(hideModal());
  };

  const responseGoogle = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ('tokenId' in response) {
      const tokenBlob = new Blob(
        [JSON.stringify({ token: response.tokenId }, null, 2)],
        { type: 'application/json' }
      );
      let resp = await dispatch(loginGoogle(tokenBlob));
      if (resp) {
        history.push('/tasks');
      }
    }
  };

  const handleLoginMSAL = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        let token = response.idToken;
        dispatch(loginMsal(token));
      })
      .catch((error) => {
        console.error('Login error:', error);
      });
  };


  return (
    <div className="min-h-screen flex items-center justify-center bg-background-light dark:bg-background-dark transition-colors duration-300">
      <div className="max-w-md w-full bg-white dark:bg-gray-800 p-8 rounded-lg shadow-light-md dark:shadow-dark-md transition-shadow duration-300">
        <RegionSwitcher />
        {/* Header */}
        <div className="flex flex-col items-center mb-6">
          <i className="fa-kit fa-logo text-4xl dark:text-sky-400/100 text-primary-500 mb-4"></i>
          <h2 className="text-2xl font-heading text-gray-800 dark:text-gray-100">
            {t('Welcome')}
          </h2>
          {!isBetaEnv && (
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
              <span>{t("Don't have an account?")}</span>{' '}
              <button
                onClick={toggleAuthMode}
                className="text-primary-500 hover:underline"
              >
                {t('Sign up now')}
              </button>
            </p>
          )}
        </div>

        {/* Login Form */}
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <Input
            type="email"
            id="email"
            label={t('Email Address')}
            placeholder={t('you@example.com')}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            className={clsx(
              formik.errors.email && formik.touched.email
                ? 'border-red-500'
                : 'border-gray-300'
            )}
          />
          {formik.errors.email && formik.touched.email && (
            <p className="text-sm text-red-600">{formik.errors.email}</p>
          )}

          <Input
            type="password"
            id="password"
            label={t('Password')}
            placeholder={t('********')}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            className={clsx(
              formik.errors.password && formik.touched.password
                ? 'border-red-500'
                : 'border-gray-300'
            )}
          />
          {formik.errors.password && formik.touched.password && (
            <p className="text-sm text-red-600">{formik.errors.password}</p>
          )}

          {/* Remember Me and Forgot Password */}
          <div className="flex items-center justify-between">
            <label className="flex items-center text-sm text-gray-700 dark:text-gray-300">
              <input
                type="checkbox"
                name="RememberMe"
                onChange={formik.handleChange}
                checked={formik.values.RememberMe}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
              />
              <span className="ml-2">{t('Keep me signed in on this device.')}</span>
            </label>
            <button
              type="button"
              onClick={handleForgotPasswordClick}
              className="text-xs text-primary-600 hover:text-primary-500"
            >
              {t('Forgot password?')}
            </button>
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            variant="primary"
            disabled={isLoading}
            ariaLabel={isLoading ? t('Logging in...') : t('Log In')}
            className={clsx(
              isLoading ? 'opacity-50 cursor-not-allowed' : '',
              'flex items-center justify-center w-full'
            )}
          >
            {isLoading ? t('Logging in...') : t('Log In')}
          </Button>



          {!isBetaEnv && (
            <>
              {/* Divider */}
              <Divider message={t('or continue with')} />

              <div className="flex justify-center space-x-4">
                {/* Google Login */}
                <GoogleLogin
                  clientId={CLIENT_ID}
                  buttonText={t('Google')}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy="single_host_origin"
                  scope="profile email"
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      // disabled={renderProps.disabled}
                      className="inline-flex justify-between items-center px-3 py-2 font-medium rounded-md transition text-sm bg-gray-200 hover:bg-gray-300 text-gray-900 w-full"
                    >
                      {t('Google')}
                      <i className="fab fa-google mr-2"></i>
                    </button>
                  )}
                />

                {/* Microsoft Login */}
                <button
                  onClick={handleLoginMSAL}
                  className="inline-flex justify-between items-center px-3 py-2 font-medium rounded-md transition text-sm bg-gray-200 hover:bg-gray-300 text-gray-800 w-full"
                // variant='secondary'
                >
                  {t('Microsoft')}
                  <i className="fab fa-microsoft mr-2"></i>
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginSection;
