import React, { useEffect, useState } from 'react';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchPriority, fetchProjectCategory, fetchProjectStatus } from '../../store/actions/DropdownActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { addProject } from '../../store/actions/ProjectActions';
import { Employee } from '../Management/Employees/types';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';
import { getAiModels } from '../../store/actions/ChatAction';
import { Dayjs } from 'dayjs';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import { PiPlusCircle, PiTrash } from 'react-icons/pi';
import { GoProjectRoadmap } from 'react-icons/go';

interface ProjectCreateProps {
  onCancel: () => void;
  onSuccess: () => void;
}

const ProjectCreate: React.FC<ProjectCreateProps> = ({ onCancel, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    projectName: '',
    projectDescription: '',
    projectDeadline: null as Dayjs | null,
    projectStartDate: null as Dayjs | null,
    category: '',
    priority: '',
    status: '',
    projectMembers: [] as string[],
    projectLead: '',
    projectApprover: '',
    services: [] as { ServiceType: string; ServiceAmount: number; total_available_queries: number | 'Unlimited' }[],
  });

  const categoriesData = useSelector((state: RootState) => state.dropdown.projectCategory) || [];
  const prioritiesData = useSelector((state: RootState) => state.dropdown.priority) || [];
  const employees = useSelector((state: RootState) => state.employees.employees) || {};
  const employeeArray = Object.values(employees);

  // Fetch services
  const [servicesData, setServicesData] = useState([]);
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await dispatch(getAiModels());
        if (response) setServicesData(response);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    fetchServices();
    dispatch(fetchAllEmployees());
    dispatch(fetchProjectCategory());
    dispatch(fetchPriority());
    dispatch(fetchProjectStatus());
  }, [dispatch]);

  const allowedServices = ['Law Research', 'Tax Research', 'Contract Analysis', 'Legal Case Mapping'];
  const serviceOptions = servicesData
    .filter((s: any) => {
      if (s.model_display_name === 'Legal Case Mapping') return s.model_name === 'CaseAnalysisBusiness';
      return allowedServices.includes(s.model_display_name);
    })
    .map((s: any) => ({
      value: s.model_id, 
      label: `${s.model_display_name} (${s.total_available_queries})`,
      total_available_queries: s.total_available_queries,
    }));

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.projectName) newErrors.projectName = t('Project Name is required');
    if (!formData.projectDescription) newErrors.projectDescription = t('Project Description is required');
    if (formData.projectMembers.length === 0) newErrors.projectMembers = t('At least one project member is required');
    if (!formData.projectLead) newErrors.projectLead = t('Project Lead is required');
    formData.services.forEach((service, index) => {
      const totalAvailable = service.total_available_queries;
      if (!service.ServiceType) newErrors[`service_${index}_type`] = t('Service type is required');
      if (!service.ServiceAmount) newErrors[`service_${index}_amount`] = t('Service amount is required');
      if (totalAvailable !== 'Unlimited' && service.ServiceAmount > parseInt(totalAvailable.toString(), 10)) {
        newErrors[`service_${index}_amount`] = `${t("Cannot allocate more than")} ${totalAvailable} ${t("queries for this service")}`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelect = (field: string, option: DropdownOption) => {
    setFormData((prev) => ({ ...prev, [field]: option.value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    const data = {
      ProjectName: formData.projectName,
      ProjectDescription: formData.projectDescription,
      ProjectLeadID: formData.projectLead,
      ProjectApproverID: formData.projectApprover || formData.projectLead,
      CategoryType: formData.category,
      PriorityLevel: formData.priority,
      StartDate: formData.projectStartDate?.toISOString(),
      EndDate: formData.projectDeadline?.toISOString(),
      ServiceAllocation: formData.services,
      UserType: 'BusinessAdmin',
      ProjectMembers: formData.projectMembers,
    };
  
    console.log('Data to be sent:', JSON.stringify(data, null, 2));
  
    try {
      const project = await dispatch(addProject(data));
      if (project) {
        dispatch(showFeedbackModal({ showModal: true, message: t('New Project Created!'), modalType: 'success', duration: 3000 }));
        setFormData({
          projectName: '',
          projectDescription: '',
          projectDeadline: null,
          projectStartDate: null,
          category: '',
          priority: '',
          status: '',
          projectMembers: [],
          projectLead: '',
          projectApprover: '',
          services: [],
        });
        onSuccess();
      }
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const handleAddService = () => {
    setFormData((prev) => ({
      ...prev,
      services: [...prev.services, { ServiceType: '', ServiceAmount: 0, total_available_queries: 0 }],
    }));
  };

  const handleServiceSelect = (index: number, option: DropdownOption) => {
    const selectedService = serviceOptions.find((s) => s.value === option.value);
    const updatedServices = [...formData.services];
    updatedServices[index].ServiceType = option.value; 
    updatedServices[index].total_available_queries = selectedService?.total_available_queries || 'Unlimited';
    setFormData((prev) => ({ ...prev, services: updatedServices }));
  };

  const handleServiceAmountChange = (index: number, amount: number) => {
    const updatedServices = [...formData.services];
    const totalAvailable = updatedServices[index].total_available_queries;
    if (totalAvailable !== 'Unlimited' && amount > parseInt(totalAvailable.toString(), 10)) {
      dispatch(showFeedbackModal({ showModal: true, message: `${t("Cannot allocate more than")} ${totalAvailable} ${t("queries for this service")}`, modalType: 'error', duration: 3000 }));
      return;
    }
    updatedServices[index].ServiceAmount = amount;
    setFormData((prev) => ({ ...prev, services: updatedServices }));
  };

  const handleRemoveService = (index: number) => {
    const updatedServices = [...formData.services];
    updatedServices.splice(index, 1);
    setFormData((prev) => ({ ...prev, services: updatedServices }));
  };

  const handleDateChange = (field: 'projectStartDate' | 'projectDeadline', date: Dayjs | null) => {
    setFormData((prev) => ({ ...prev, [field]: date }));
  };

  const employeeOptions = employeeArray
  .filter((employee) => employee && employee.UserUID && employee.FirstName && employee.LastName)
  .map((employee: Employee) => ({
    value: employee.UserUID,  
    label: `${employee.FirstName} ${employee.LastName}`,
  }));

  return (
    <div className="px-6 pb-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-3xl w-full mx-auto mt-5 h-[calc(100vh-125px)] flex flex-col overflow-y-auto">
      {/* Sticky Header */}
      <div className="sticky top-0 z-10 pt-6 bg-white dark:bg-gray-900 pb-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center gap-2">
          <GoProjectRoadmap size={28} className="text-primary-500" />
          {t('Create New Project')}
        </h2>
        <div className="flex gap-2">
          <Button variant="neutral" size="small" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" type="submit" form="new-project-form">
            {t('Create')}
          </Button>
        </div>
      </div>

      {/* Form */}
      <form id="new-project-form" onSubmit={handleSubmit} className="space-y-6 mt-4 flex-1">
        <Input
          label={t('Project Name')}
          type="text"
          name="projectName"
          value={formData.projectName}
          onChange={handleChange}
          placeholder={t('Enter project name')}
          error={errors.projectName}
          className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
        />

        <Textarea
          id="projectDescription"
          name="projectDescription"
          label={t('Project Description')}
          value={formData.projectDescription}
          onChange={handleChange}
          placeholder={t('Enter project description')}
          error={errors.projectDescription}
        />

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t('Start Date')}
            </label>
            <CustomDateTimePicker
              value={formData.projectStartDate}
              onChange={(date) => handleDateChange('projectStartDate', date)}
              dateOnly={true}
            />
            {errors.projectStartDate && (
              <span className="text-red-500 text-sm">{errors.projectStartDate}</span>
            )}
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t('Deadline')}
            </label>
            <CustomDateTimePicker
              value={formData.projectDeadline}
              onChange={(date) => handleDateChange('projectDeadline', date)}
              dateOnly={true}
              minDateTime={formData.projectStartDate as Dayjs}
            />
            {errors.projectDeadline && (
              <span className="text-red-500 text-sm">{errors.projectDeadline}</span>
            )}
          </div>
        </div>

        <Dropdown
          label={t('Category')}
          options={categoriesData}
          onSelect={(option) => handleSelect('category', option)}
          placeholder={t('Select Category')}
          error={errors.category}
          className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
        />

        <Dropdown
          label={t('Priority')}
          options={prioritiesData}
          onSelect={(option) => handleSelect('priority', option)}
          placeholder={t('Select Priority')}
          error={errors.priority}
          className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
        />

        <Dropdown
          label={t('Project Members')}
          options={employeeOptions.filter((option) => !formData.projectMembers.includes(option.value))}
          onSelect={(option) => setFormData((prev) => ({ ...prev, projectMembers: [...prev.projectMembers, option.value] }))}
          placeholder={t('Select Project Members')}
          error={errors.projectMembers}
          className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
        />
        {formData.projectMembers.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {formData.projectMembers.map((memberId) => {
              const member = employeeOptions.find((opt) => opt.value === memberId);
              return (
                <div key={memberId} className="flex items-center bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded">
                  <span className="text-sm text-gray-700 dark:text-gray-200">{member?.label || 'Unknown'}</span>
                  <button
                    type="button"
                    onClick={() => setFormData((prev) => ({ ...prev, projectMembers: prev.projectMembers.filter((id) => id !== memberId) }))}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    <PiTrash size={16} />
                  </button>
                </div>
              );
            })}
          </div>
        )}

        <Dropdown
          label={t('Project Lead')}
          options={formData.projectMembers.map((memberId) => {
            const member = employeeOptions.find((opt) => opt.value === memberId);
            return { value: memberId, label: member?.label || 'Unknown' };
          })}
          onSelect={(option) => handleSelect('projectLead', option)}
          placeholder={t('Select Project Lead')}
          error={errors.projectLead}
          className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
        />

        <Dropdown
          label={t('Project Approver')}
          options={formData.projectMembers.map((memberId) => {
            const member = employeeOptions.find((opt) => opt.value === memberId);
            return { value: memberId, label: member?.label || 'Unknown' };
          })}
          onSelect={(option) => handleSelect('projectApprover', option)}
          placeholder={t('Select Project Approver')}
          error={errors.projectApprover}
          className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
        />

        <div className="space-y-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
          <h3 className="text-md text-gray-900 dark:text-gray-100">{t('Service Allocation')}</h3>
          {formData.services.map((service, index) => (
            <div key={index} className="flex items-center space-x-4">
              <Dropdown
                options={serviceOptions}
                onSelect={(option) => handleServiceSelect(index, option)}
                value={service.ServiceType}
                placeholder={t('Select Service')}
                error={errors[`service_${index}_type`]}
                className="flex-1 bg-white dark:bg-gray-800 rounded-lg"
              />
              <Input
                type="number"
                value={service.ServiceAmount}
                onChange={(e) => handleServiceAmountChange(index, parseInt(e.target.value))}
                placeholder={t('Amount')}
                min={0}
                error={errors[`service_${index}_amount`]}
                className="w-24 bg-white dark:bg-gray-800 rounded-lg"
              />
              <button
                type="button"
                onClick={() => handleRemoveService(index)}
                className="text-red-500 hover:text-red-700"
              >
                <PiTrash size={20} />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddService}
            className="flex items-center space-x-2 text-primary-500 hover:text-primary-700"
          >
            <PiPlusCircle size={16} />
            <span className="text-xs">{t('Add Service')}</span>
          </button>
        </div>

        <div className="flex justify-end gap-2">
          <Button variant="primary" size="small" type="submit">
            {t('Create')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProjectCreate;