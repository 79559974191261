import React, { useEffect, useState } from 'react';
import { useAppDispatch, RootState } from '../../store';
import { useSelector } from 'react-redux';
import { attachExistingCaseToClient } from '../../store/actions/ClientAction';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { fetchUnassignedCases } from '../../store/actions/marketplace2/caseActions';
import { useTranslation } from 'react-i18next';

interface AttachCaseToClientProps {
  clientId: string; 
  onClose: () => void;
}

const AttachCaseToClientModalContent: React.FC<AttachCaseToClientProps> = ({
  clientId,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const unassignedCases = useSelector((state: RootState) => state.casesM.unassignedCases);
  const [selectedCaseId, setSelectedCaseId] = useState<string>('');
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(fetchUnassignedCases());
  }, [dispatch]);

  // Convert unassignedCases to dropdown options
  const caseOptions = unassignedCases.map((c: any) => ({
    value: c.CaseID,
    label: c.CaseName || 'Untitled Case'
  }));

  const handleAttach = async () => {
    if (!selectedCaseId) {
      return; // or show an error
    }
    const success = await dispatch(attachExistingCaseToClient(clientId, selectedCaseId));
    if (success) {
      // close modal
      onClose();
    }
  };

  return (
    <div className="space-y-4">
      <p className="text-sm text-gray-700 dark:text-gray-200">
        {t("Select one of your unassigned cases to attach it to this client:")}
      </p>
      <Dropdown
        label={t("Unassigned Cases")}
        placeholder={t("Choose a case")}
        options={caseOptions}
        value={selectedCaseId}
        onChange={(val) => setSelectedCaseId(val as string)}
      />
      <div className="flex justify-end space-x-2 mt-4">
        <Button variant="neutral" onClick={onClose}>{t("Cancel")}</Button>
        <Button variant="primary" onClick={handleAttach}>{t("Attach Case")}</Button>
      </div>
    </div>
  );
};

export default AttachCaseToClientModalContent;
