import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store';
import { getNotificationPreferences, updateNotificationPreferences } from '../../../store/actions/Notifications';
import Toggle from '../../shared/TailwindComponents/Toggle';

const NotificationSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [preferences, setPreferences] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(true);

  // Fetch preferences on mount
  useEffect(() => {
    const fetchPreferences = async () => {
      const prefs = await dispatch(getNotificationPreferences());
      if (prefs) {
        setPreferences(prefs);
      }
      setLoading(false);
    };
    fetchPreferences();
  }, [dispatch]);

  // Handle toggle changes and update immediately
  const handleToggle = async (type: string, newValue: boolean) => {
    // Optimistically update local state
    setPreferences((prev) => ({ ...prev, [type]: newValue }));

    // Update the backend immediately
    const updatedPreferences = { [type]: newValue };
    const response = await dispatch(updateNotificationPreferences(updatedPreferences));
    if (!response) {
      // Revert state if API call fails (optional error handling)
      setPreferences((prev) => ({ ...prev, [type]: !newValue }));
      console.error(`Failed to update preference for ${type}`);
    }
  };

  // Skeleton loader during loading state
  if (loading) {
    return (
      <div className="space-y-4">
        {Array.from({ length: 9 }).map((_, index) => (
          <div key={index} className="flex items-center justify-between">
            <div className="w-1/3 h-4 bg-gray-200 dark:bg-gray-700 rounded animate-fadePulse"></div>
            <div className="w-11 h-6 bg-gray-200 dark:bg-gray-700 rounded-full animate-fadePulse"></div>
          </div>
        ))}
      </div>
    );
  }

  const notificationTypes = [
    'Cases',
    'Clients',
    'Updates',
    'Tasks',
    'Analytics',
    'TimeKeeping',
    'Subscription',
    'Marketplace',
  ];

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
        {t('Notification Settings')}
      </h3>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {t('Manage your notification preferences for different categories.')}
      </p>
      <div className="space-y-4">
        {notificationTypes.map((type) => (
          <div key={type} className="flex items-center justify-between">
            <span className="text-sm text-gray-700 dark:text-gray-200">{t(type)}</span>
            <Toggle
              enabled={preferences[type] ?? true}
              onToggle={(newValue) => handleToggle(type, newValue)}
              value={!preferences[type]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationSettings;