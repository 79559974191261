import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchTicket } from '../../store/actions/HelpAction';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import TicketDetailsSkeleton from './TicketDetailSkeleton';
import TicketDetailsContent from './TicketDetailsContent';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';

const TicketDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { ticketId } = useParams<{ ticketId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { ticket, isLoading, error } = useSelector((state: RootState) => state.help);
  const { setBreadcrumbs } = useBreadcrumbs();

  // Fetch ticket data when ticketId changes
  useEffect(() => {
    dispatch(fetchTicket(ticketId));
  }, [dispatch, ticketId]);

  // Set breadcrumbs using SupportID when ticket data is available, reset on unmount
  useEffect(() => {
    if (ticket?.SupportID) {
      const formattedSupportId = String(ticket.SupportID).padStart(6, '0');
      setBreadcrumbs([
        { name: 'Support', href: '/support', current: false },
        { name: `Ticket #${formattedSupportId}`, href: `/support/${ticketId}`, current: true },
      ]);
    } else {
      setBreadcrumbs([
        { name: 'Support', href: '/support', current: false },
        { name: t('Loading...'), href: `/support/${ticketId}`, current: true },
      ]);
    }
  
    return () => {
      setBreadcrumbs([{ name: 'Support', href: '/support', current: true }]);
    };
  }, [ticket, ticketId, setBreadcrumbs, t]);

  // Handle "Back to Tickets" navigation
  const handleBackClick = () => {
    setBreadcrumbs([{ name: 'Support', href: '/support', current: true }]);
    history.push('/support');
  };

  if (isLoading) {
    return <TicketDetailsSkeleton />;
  }

  if (error) {
    return (
      <div className="text-center text-red-500 mt-10">
        <p>{error}</p>
        <Button variant="neutral" onClick={handleBackClick} className="mt-4">
          {t('Back to Tickets')}
        </Button>
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="text-center mt-10">
        <p>{t('Ticket not found')}</p>
        <Button variant="neutral" onClick={handleBackClick} className="mt-4">
          {t('Back to Tickets')}
        </Button>
      </div>
    );
  }

  return (
    <div className="px-4 bg-background-light dark:bg-background-dark min-h-screen">
      <div className="mx-auto">
        <div className="flex items-center space-x-2 my-4">
          <button
            onClick={handleBackClick}
            className="text-primary-500 hover:text-primary-600"
          >
            <ChevronLeftIcon className="h-6 w-6 text-primary-500" />
          </button>
          <h1 className="text-gray-800 dark:text-white bg-clip-text bg-gradient-to-r from-primary-500 to-primary-700">
            {t('Back to Tickets')}
          </h1>
        </div>
        <TicketDetailsContent ticket={ticket} />
      </div>
    </div>
  );
};

export default TicketDetailsPage;