import { AnyAction } from 'redux';
import { SWITCH_USER_REQUEST } from '../types';
import apis from '../../utils/apis';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../types';
import { handleApiError } from './utils/utils';

export const switchUser = (role: string) => async (
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>
) => {
  dispatch({ type: SWITCH_USER_REQUEST });

  const dataToSend = { Role: role };

  try {
    const response = await apis.post('/users/switch_role', dataToSend);

    if (response && response.status === 200) {
      const token = response.data?.AccessToken;
      const userType = response.data?.UserTypeName;
      const userId = response.data?.UserID || '';
      const refreshToken = response.data?.RefreshToken;
      const isAdmin = response.data?.IsAdmin;
      const roles = response.data?.Roles;

      const userData = {
        token,
        userType,
        userId,
        refreshToken,
        isAdmin,
        roles,
      };

      // Store the new token and user info in sessionStorage
      sessionStorage.setItem('token', JSON.stringify(userData));
      // Redirect to dashboard or appropriate page
      window.location.href = '/tasks';
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error) {
    handleApiError(error, dispatch)
  }

};
