import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserType } from '../../../utils/authUtils';
import { updateAISettings, getAISettings } from '../../../store/actions/DataAction';
import { setVoice } from '../../../store/actions/SpeechAction';
import { useTranslation } from 'react-i18next';
import { AISettings } from '../../../store/reducer/DataReducer';
import {
  fetchAIVoices,
  fetchAIJurisdictions,
  fetchAILanguages,
} from '../../../store/actions/DropdownActions';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';
import { getUserID } from '../../../store/actions/ChatAction';
import { LayoutContext } from '../../../contexts/LayoutContext';
import { PiGenderFemaleLight, PiGenderMaleLight } from 'react-icons/pi';

const AISettingsComponent: React.FC<{ style?: CSSProperties; backgroundColor?: string }> = ({
  style,
  backgroundColor,
}) => {
  const dispatch = useAppDispatch();
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const aiVoices = useSelector((state: RootState) => state.dropdown.aiVoices) as DropdownOption[];
  const aiJurisdictions = useSelector((state: RootState) => state.dropdown.aiJurisdictions) as DropdownOption[];
  const aiLanguages = useSelector((state: RootState) => state.dropdown.aiLanguages) as DropdownOption[];
  const { t } = useTranslation();
  const [selectedAILanguage, setSelectedAILanguage] = useState<string>('');
  const [selectedVoice, setSelectedVoiceState] = useState<string>('');
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>('Spain');
  const { setIsContentScrollable } = useContext(LayoutContext);

  const jurisdictionOptions = [
    { label: 'Denmark', value: 'Denmark', flag: 'DK' },
    { label: 'Greece', value: 'Greece', flag: 'GR' },
    { label: 'Poland', value: 'Poland', flag: 'PL' },
    { label: 'Portugal', value: 'Portugal', flag: 'PT' },
    { label: 'Spain', value: 'Spain', flag: 'ES' },
    { label: 'Switzerland', value: 'Switzerland', flag: 'CH' },
    { label: 'United Kingdom', value: 'UK', flag: 'GB' },
    { label: 'United States', value: 'USA', flag: 'US' },
  ];

  useEffect(() => {
    setIsContentScrollable(false);
    return () => setIsContentScrollable(true);
  }, [setIsContentScrollable]);

  useEffect(() => {
    dispatch(getAISettings());
    dispatch(fetchAIJurisdictions());
    dispatch(fetchAILanguages());
  }, [dispatch]);

  useEffect(() => {
    if (aiSettings) {
      setSelectedAILanguage(aiSettings.AILanguageResponse);
      setSelectedVoiceState(aiSettings.AIVoice);
      setSelectedJurisdiction(aiSettings.CountryOfJurisdiction || '');
    }
  }, [aiSettings]);

  const handleSelectAILanguage = (selectedValue: string | string[]) => {
    const selectedOption = processedLanguages.find((option) => option.value === selectedValue);
    if (selectedOption) {
      setSelectedAILanguage(selectedOption.value);
      dispatch(fetchAIVoices(selectedOption.label)); // Fetch voices based on language name
      updateAISettingsOnServer({ AILanguageResponse: selectedOption.value });
    }
  };

  const handleSelectAIVoice = (selectedValue: string | string[]) => {
    const selectedOption = voiceOptions.find((option) => option.value === selectedValue);
    if (selectedOption) {
      setSelectedVoiceState(selectedOption.value);
      dispatch(setVoice(selectedOption.value));
      updateAISettingsOnServer({ AIVoice: selectedOption.value });
    }
  };

  const handleSelectJurisdiction = (selectedValue: string | string[]) => {
    const selectedOption = aiJurisdictions.find((option) => option.label === selectedValue);
    if (selectedOption) {
      setSelectedJurisdiction(selectedOption.label);
      updateAISettingsOnServer({ CountryOfJurisdiction: selectedOption.label });
    }
  };

  const updateAISettingsOnServer = async (updatedFields: Partial<AISettings>) => {
    const updatedAISettings: AISettings = {
      UserID: getUserID(),
      UserType: getUserType(),
      AIVoice: updatedFields.AIVoice || selectedVoice,
      AILanguageResponse: updatedFields.AILanguageResponse || selectedAILanguage,
      CountryOfJurisdiction: updatedFields.CountryOfJurisdiction || selectedJurisdiction,
      ThemeColor: 'dark',
      FontFamily: '"Poppins", sans-serif',
      FontSize: ''
    };
    await dispatch(updateAISettings(updatedAISettings));
    dispatch(getAISettings());
  };

  // Process languages to remove country names and add flags
  const processedLanguages = aiLanguages.map((lang) => {
    const languageName = lang.label.split(' (')[0]; // e.g., "English"
    const countryCode = lang.value.split('-')[1] || '';   // e.g., "US"
    return {
      value: lang.value,
      label: languageName,
      flag: countryCode,
    };
  });

  // Add gender icons to voice options (assuming gender property exists)
  const voiceOptions = aiVoices.map((voice) => ({
    ...voice,
    icon: voice.label.includes('Female') ? <PiGenderFemaleLight /> : voice.label.includes('Male') ? <PiGenderMaleLight /> : null,
  }));

  return (
    <div className="space-y-6 px-2 py-4" style={style}>
      {/* Header and Sub-Description */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">{t('AI Settings')}</h3>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {t('Customize AI preferences for a personalized and efficient experience.')}
        </p>
      </div>

      {/* Jurisdiction Dropdown */}
      <div className="flex justify-between items-center">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            {t('Jurisdiction')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-400">{t('Select your legal framework.')}</p>
        </div>
        <Dropdown
          options={jurisdictionOptions}
          value={selectedJurisdiction}
          placeholder={t('Select Jurisdiction')}
          onChange={handleSelectJurisdiction}
          showFlags={true}
        />
      </div>
      <hr className="my-4 border-gray-200 dark:border-gray-700" />

      {/* Language Dropdown */}
      <div className="flex justify-between items-center">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            {t('Language')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {t('Set the language for AI communication.')}
          </p>
        </div>
        <Dropdown
          options={processedLanguages}
          value={selectedAILanguage}
          placeholder={t('Select Language')}
          onChange={handleSelectAILanguage}
          showFlags={true}
        />
      </div>
      <hr className="my-4 border-gray-200 dark:border-gray-700" />

      {/* Voice Dropdown */}
      <div className="flex justify-between items-center">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            {t('Speaker Voice')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {t('Choose a voice tailored to your preferences.')}
          </p>
        </div>
        <Dropdown
          options={voiceOptions}
          value={selectedVoice}
          placeholder={t('Select Voice')}
          onChange={handleSelectAIVoice}
          showFlags={false}
        />
      </div>
    </div>
  );
};

export default AISettingsComponent;