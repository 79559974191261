import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { toggleSidebar } from '../../store/actions/SidebarActions';
import { GoWorkflow } from "react-icons/go";
import { PiBriefcaseLight, PiChartLineLight, PiCheckSquareOffsetLight, PiCoinsLight, PiFileLight, PiGavelLight, PiScalesLight, PiSparkleLight, PiSquaresFourLight, PiUserCircleCheckLight, PiUserGearLight, PiUserListLight } from "react-icons/pi";
import { isBetaEnv } from '../../utils/authUtils';

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const userType = getUserType();
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  // Define disabled nav items for beta environments
  const disabledNavItems = isBetaEnv ? ['dashboard', 'marketplace', 'finance', 'analytics'] : [];

  const getNavItemClass = (path: string, isActive: boolean) => {
    const isDisabled = disabledNavItems.includes(path.replace('/', ''));
    return clsx(
      'flex items-center px-2 py-1 text-sm font-medium rounded-md transition-colors duration-300',
      isActive && !isDisabled ? 'bg-primary-500 text-white' : '',
      !isActive && !isDisabled ? 'hover:bg-gray-200 dark:hover:bg-gray-700' : '',
      isDisabled ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed' : '',
      !isOpen && 'justify-center'
    );
  };

  // Dynamic icon size based on sidebar state
  const iconSize = isOpen ? 20 : 16;

  const renderNavLink = (to: string, label: string, icon: JSX.Element, section: string) => {
    const isDisabled = disabledNavItems.includes(to.replace('/', ''));
    return (
      <li className={clsx(!isOpen && section && '')}>
        <Tooltip title={isDisabled ? t('Coming Soon') : (!isOpen ? t(label) : '')} placement="right">
          {isDisabled ? (
            <div className={getNavItemClass(to, false)}>
              {React.cloneElement(icon, { size: iconSize })}
              {isOpen && <span className="ml-3">{t(label)}</span>}
            </div>
          ) : (
            <NavLink
              exact={to === '/dashboard'}
              to={to}
              activeClassName="bg-primary-500 text-white"
              className={getNavItemClass(to, location.pathname === to)}
            >
              {React.cloneElement(icon, { size: iconSize })}
              {isOpen && <span className="ml-3">{t(label)}</span>}
            </NavLink>
          )}
        </Tooltip>
      </li>
    );
  };

  return (
    <div
      className={clsx(
        'flex flex-col bg-background-light shadow-lg dark:shadow-dark-md dark:bg-background-dark text-text-light dark:text-text-dark transition-all duration-300 ease-in-out pt-4 mt-2',
        isOpen ? 'w-52' : 'w-15'
      )}
    >
      <nav className="flex-1 px-2 space-y-2 overflow-y-auto">
        <ul className="space-y-1">
          {/* Dashboard */}
          {renderNavLink('/dashboard', 'Dashboard', <PiSquaresFourLight />, 'dashboard')}

          {/* Tasks */}
          {renderNavLink('/tasks', 'Tasks', <PiCheckSquareOffsetLight />, 'tasks')}

          {/* Vault Section */}
          {isOpen && <p className="py-1 text-gray-500 dark:text-gray-200 text-xs">{t('Vault')}</p>}
          {!isOpen && <div className="border-t border-gray-200 dark:border-gray-700 my-1"></div>}

          {(userType === 'LawFirmAdmin' || userType === 'LawFirmEmployee' || userType === 'IndependentLawyer') &&
            renderNavLink('/my-clients', 'My Clients', <PiUserListLight />, 'vault')}

          {(userType === 'BusinessAdmin' || userType === 'BusinessEmployee' || userType === 'IndividualClient') &&
            renderNavLink('/my-lawyers', 'My Lawyers', <PiUserCircleCheckLight />, 'vault')}

          {(userType === 'BusinessAdmin' || userType === 'BusinessEmployee') &&
            renderNavLink('/projects', 'Projects', <GoWorkflow />, 'vault')}

          {renderNavLink('/cases', 'Cases', <PiBriefcaseLight />, 'vault')}
          {renderNavLink('/files', 'Files', <PiFileLight />, 'vault')}

          {/* Tools Section */}
          {isOpen && <p className="py-1 text-gray-500 dark:text-gray-200 text-xs">{t('Tools')}</p>}
          {!isOpen && <div className="border-t border-gray-200 dark:border-gray-700 my-1"></div>}

          {renderNavLink('/ai-assistant', 'AI Assistant', <PiGavelLight />, 'tools')}
          {renderNavLink('/marketplace', 'Marketplace', <PiScalesLight />, 'tools')}
          {renderNavLink('/analytics', 'Analytics', <PiChartLineLight />, 'tools')}
          {!userType.includes('Client') &&
            renderNavLink('/ai-studio', 'AI Studio', <PiSparkleLight />, 'tools')}

          {/* Operations Section */}
          {(isOpen && userType.includes('Admin')) && (
            <p className="py-1 text-gray-500 dark:text-gray-200 text-xs">{t('Operations')}</p>
          )}
          {!isOpen && userType.includes('Admin') && (
            <div className="border-t border-gray-200 dark:border-gray-700 my-1"></div>
          )}

          {(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') && (
            <>
              {renderNavLink('/management', 'Management', <PiUserGearLight />, 'operations')}
              {renderNavLink('/finance', 'Finance', <PiCoinsLight />, 'operations')}
            </>
          )}
        </ul>
      </nav>

      {/* Toggle Button */}
      <div
        className="transform self-end -translate-y-1/2 w-1 h-12 bg-gray-500 dark:bg-gray-200 rounded hover:dark:bg-transparent hover:bg-transparent cursor-pointer flex items-center justify-center group"
        onClick={handleToggleSidebar}
      >
        <i
          className={clsx(
            'fas',
            isOpen ? 'fa-chevron-left' : 'fa-chevron-right',
            'text-gray-500 dark:text-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-300'
          )}
        ></i>
      </div>
    </div>
  );
};

export default Sidebar;