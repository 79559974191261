// src/components/settings/tabs/BillingTab.tsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PiCreditCardLight, PiDownloadSimpleLight } from 'react-icons/pi';
import ApiLoader from '../../../ApiLoader';
import Button from '../../../shared/TailwindComponents/Button';
import PaymentTransactions from '../../../shared/Subscriptions/PaymentTransactions';
import ActiveSubscriptions from '../../../shared/Subscriptions/ActiveSubscriptions';

const BillingTab: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [billingData, setBillingData] = useState({
    plan: 'Professional',
    nextBilling: '2024-11-15',
    amount: '$99.00',
    paymentMethods: [{ type: 'Visa', lastFour: '1234' }],
    history: [
      { date: '2024-10-15', amount: '$99.00', status: 'Paid' },
      { date: '2024-09-15', amount: '$99.00', status: 'Paid' },
    ],
  });

  useEffect(() => {
    // Simulate API call
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  if (isLoading) {
    return <ApiLoader />;
  }

  return (
    <div className="space-y-6 animate-fade-in-up">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
        {t('Billing')}
      </h2>

      {/* Subscription Details */}
      <div className='grid sm:grid-cols-1 md:grid-cols-2 grid-cols-2 gap-4'>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-light-md dark:shadow-dark-md">
        {/* <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          {t('Subscription')}
        </h3>
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
          {t('Current Plan')}: {billingData.plan}
        </p>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {t('Next Billing')}: {billingData.nextBilling} ({billingData.amount})
        </p>
        <Button variant="primary" size="small" className="mt-4">
          {t('Upgrade Plan')}
        </Button> */}
        <ActiveSubscriptions />
      </div>

      {/* Payment Methods */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-light-md dark:shadow-dark-md">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
          {t('Payment Methods')}
        </h3>
        {billingData.paymentMethods.map((method, index) => (
          <div
            key={index}
            className="flex items-center justify-between mt-4 border-b border-gray-200 dark:border-gray-700 pb-2"
          >
            <div className="flex items-center space-x-2">
              <PiCreditCardLight className="text-2xl text-primary-500" />
              <span className="text-gray-900 dark:text-white">
                {method.type} ending in {method.lastFour}
              </span>
            </div>
            <Button variant="secondary" size="small">
              {t('Remove')}
            </Button>
          </div>
        ))}
        <Button variant="success" size="small" className="mt-4">
          {t('Add Payment Method')}
        </Button>
      </div>
      </div>
      {/* Billing History */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-light-md dark:shadow-dark-md">
        <PaymentTransactions />
      </div>
    </div>
  );
};

export default BillingTab;