import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface TabItem {
  label: string;
  value: string;
  rightAligned?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

interface TabsProps {
  tabs: TabItem[];
  activeTab: string;
  onTabClick: (tabValue: string) => void;
}

const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ tabs, activeTab, onTabClick }, ref) => {
    const { t } = useTranslation();
    return (
      <div ref={ref} className="mb-6 overflow-x-auto">
        <div className="flex space-x-6 border-b border-gray-200 dark:border-gray-700 pb-2">
          {tabs.map((tab) => {
            const isActive = activeTab === tab.value;
            const tabClasses = isActive
              ? 'text-primary-500 dark:text-primary-300 bg-primary-50 dark:bg-primary-900'
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800';
            const disabledClasses = tab.disabled ? 'opacity-50 cursor-not-allowed' : '';
            const alignmentClasses = tab.rightAligned ? 'ml-auto' : '';

            return (
              <button
                key={tab.value}
                onClick={() => !tab.disabled && onTabClick(tab.value)}
                disabled={tab.disabled}
                title={tab.disabled ? tab.tooltip : ''}
                className={`relative text-sm font-medium transition-all duration-300 px-2 py-1 rounded-md ${tabClasses} ${disabledClasses} ${alignmentClasses}`}
              >
                {t(tab.label)}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
);

export default Tabs;