import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface CitationDocumentViewerProps {
  url: string;
  onClose: () => void;
}

const CitationDocumentViewer: React.FC<CitationDocumentViewerProps> = ({ url, onClose }) => {
  const { t } = useTranslation();
  const [isPdf, setIsPdf] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const [canUseIframe, setCanUseIframe] = useState(false);

  useEffect(() => {
    setIsChecking(true);
    setCanUseIframe(false);
    setIsPdf(url.toLowerCase().endsWith('.pdf'));

    const checkFrameOptions = async () => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        const xFrameOptions = response.headers.get('X-Frame-Options');
        if (xFrameOptions && (xFrameOptions.toUpperCase() === 'SAMEORIGIN' || xFrameOptions.toUpperCase() === 'DENY')) {
          window.open(url, '_blank');
          onClose(); // Close the viewer
        } else {
          setCanUseIframe(true);
        }
      } catch (error) {
        // Fallback to new tab if HEAD request fails (e.g., CORS)
        window.open(url, '_blank');
        onClose(); // Close the viewer
      } finally {
        setIsChecking(false);
      }
    };

    checkFrameOptions();
  }, [url, onClose]);

  return (
    <div className="w-full h-full bg-white dark:bg-gray-800 shadow-lg flex flex-col">
      {/* Header */}
      <div className="flex justify-between items-center p-2 border-b border-gray-200 dark:border-gray-700">
        <h2 className="text-lg font-semibold">{t('Document Viewer')}</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          {t('Close')}
        </button>
      </div>

      {/* Document Content */}
      <div className="flex-1 p-4 overflow-hidden">
        {isChecking ? (
          <div className="flex items-center justify-center h-full">
            <p>{t('Checking document compatibility...')}</p>
          </div>
        ) : canUseIframe ? (
          isPdf ? (
            <div className="w-[93%] h-[88vh] mx-auto bg-gray-200 border rounded-lg shadow-lg">
              <iframe
                src={url}
                className="w-full h-full border-none"
                title={t('PDF Viewer')}
              />
            </div>
          ) : (
            <iframe
              src={url}
              className="w-full h-full border-none rounded-lg"
              title={t('Document Viewer')}
            />
          )
        ) : (
          <div className="flex items-center justify-center h-full">
            <p>{t('Document opened in a new tab.')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CitationDocumentViewer;