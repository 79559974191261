import React from 'react';
import { useHistory } from 'react-router-dom';
import Badge from './Badge';
import { BadgeItem } from './types/badgeTypes';
import { useTranslation } from 'react-i18next';
import { PiBriefcaseLight, PiUserLight, PiCalendarLight, PiClockLight, PiCheckCircleLight, PiBuildingLight, PiEnvelopeLight, PiHourglassLight, PiXCircleLight, PiTagLight, PiFileTextLight, PiDatabaseLight, PiGaugeLight } from 'react-icons/pi';
import Tooltip from '@mui/material/Tooltip';
import { GoHistory } from 'react-icons/go';

type ListItemProps = {
  title: string;
  subtitle?: string;
  details?: { label: string; value: string }[];
  badges?: BadgeItem[];
  link: string;
  onClick?: () => void;
  verification?: {
    status: 'Verified' | 'Pending' | 'Not Verified';
    tooltip: string;
  };
  icon?: React.ReactNode; 
};

const ListItem: React.FC<ListItemProps> = ({
  title,
  subtitle,
  details,
  badges,
  link,
  onClick,
  verification,
  icon
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    onClick ? onClick() : history.push(link);
  };

  const iconMap: Record<string, React.ReactNode> = {
    'Case Type': <PiBriefcaseLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Client Name': <PiUserLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Created At': <PiCalendarLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Deadline': <PiClockLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Organization': <PiBuildingLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Case Count': <PiBriefcaseLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Verification': <PiCheckCircleLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Client Type': <PiUserLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Email': <PiEnvelopeLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Assignee': <PiUserLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Due Date': <PiClockLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Task Status': <PiTagLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Documents': <PiFileTextLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Size': <PiDatabaseLight className="text-primary-500 dark:text-primary-300" size={18} />,
    'Last Indexed': <GoHistory className="text-primary-500 dark:text-primary-300" size={18} />,
    'Usage': <PiGaugeLight className="text-primary-500 dark:text-primary-300" size={18} />,
};

  const verificationIconMap: Record<string, React.ReactNode> = {
    'Verified': <PiCheckCircleLight className="text-green-500" size={20} />,
    'Pending': <PiHourglassLight className="text-yellow-500" size={20} />,
    'Not Verified': <PiXCircleLight className="text-red-500" size={20} />,
  };

  const statusBadge = badges?.find((badge) =>
    ['Draft', 'Active', 'EngagementOngoing', 'Completed', 'Overdue', 'Closed'].includes(badge.label)
  );
  const otherBadges = badges?.filter((badge) =>
    !['Draft', 'Active', 'EngagementOngoing', 'Completed', 'Overdue', 'Closed'].includes(badge.label)
  ) || [];

  return (
    <div
      onClick={handleClick}
      className="
        relative cursor-pointer
        bg-primary-50 dark:bg-primary-900
        border border-gray-200 dark:border-gray-700
        rounded-xl p-6
        shadow-md hover:shadow-lg
        transition-all duration-300
        hover:-translate-y-1 hover:border-primary-400
        hover:bg-primary-100
        dark:hover:bg-primary-800
        animate-[fadeInUp_0.5s_ease-out]
        flex flex-col gap-4
      "
    >
      {verification && (
        <div className="absolute top-4 right-4">
          <Tooltip title={verification.tooltip} arrow>
            <div className="cursor-help">
              {verificationIconMap[verification.status]}
            </div>
          </Tooltip>
        </div>
      )}

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {icon || <PiUserLight className="text-primary-500 dark:text-primary-300" size={18} />}
          <h3 className="text-md font-bold text-gray-900 dark:text-white leading-tight">
            {t(title)}
          </h3>
        </div>
        {statusBadge && (
          <Badge color={statusBadge.color} tooltip={statusBadge.tooltip}>
            <PiCheckCircleLight className="text-current" size={14} />
            {t(statusBadge.label === 'EngagementOngoing' ? 'Active' : statusBadge.label)}
          </Badge>
        )}
      </div>

      {otherBadges.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {otherBadges.map((badge, index) => (
            <Badge
              key={index}
              color={badge.color}
              tooltip={badge.tooltip}
              className="shadow-sm transition-all duration-200 hover:shadow-md"
            >
              {badge.label === 'No Client' ? (
                <span className="flex items-center gap-1">
                  <PiUserLight className="text-current" size={14} />
                  {t(badge.label)}
                </span>
              ) : badge.label === 'New' ? (
                <span className="flex items-center gap-1">
                  <PiCalendarLight className="text-current" size={14} />
                  {t(badge.label)}
                </span>
              ) : (
                t(badge.label)
              )}
            </Badge>
          ))}
        </div>
      )}

      <div className="h-px bg-primary-300 dark:bg-primary-700" />

      <div className="flex flex-col gap-4">
        {subtitle && (
          <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-3">
            {t(subtitle)}
          </p>
        )}
        {details && (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {details.map((detail) => (
              <div key={detail.label} className="flex items-start gap-2">
                {iconMap[detail.label] || <PiUserLight className="text-primary-500 dark:text-primary-300" size={18} />}
                <div className="flex-1">
                  <dt className="text-xs font-medium text-gray-500 dark:text-gray-400">
                    {t(detail.label)}
                  </dt>
                  <dd className="text-sm text-gray-900 dark:text-white truncate">
                    {t(detail.value)}
                  </dd>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListItem;