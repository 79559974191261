import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { fetchProjectDetails } from '../../store/actions/ProjectActions';
import ProjectView from './ProjectView';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import SkeletonLoader from './SkeletonLoader'; 
import { useAppDispatch } from '../../store';

const ProjectDetailsPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [projectDetails, setProjectDetails] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchDetails = useCallback(async () => {
    setLoading(true);
    const details = await dispatch(fetchProjectDetails({ ProjectID: projectId }));
    if (details) {
      setProjectDetails(details);
      setLoading(false);
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  const handleClose = () => {
    history.push('/projects');
  };

  useEffect(() => {
    if (projectDetails) {
      setBreadcrumbs([
        { name: 'Projects', href: '/projects', current: false },
        { name: projectDetails.ProjectName || `Project ${projectId}`, href: `/projects/${projectId}`, current: true },
      ]);
    } else {
      setBreadcrumbs([
        { name: 'Projects', href: '/projects', current: false },
        { name: 'Loading...', href: `/projects/${projectId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [projectDetails, projectId, setBreadcrumbs]);

  if (loading || !projectDetails) {
    return <SkeletonLoader />;
  }

  return (
    <div className="px-4">
      <ProjectView project={projectDetails} onClose={handleClose} />
    </div>
  );
};

export default ProjectDetailsPage;