import React, { useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { PiSparkleLight } from 'react-icons/pi';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ReactQuill from 'react-quill';
import Badge from '../shared/TailwindComponents/Badge';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import ApiLoader from '../ApiLoader';
import Button from '../shared/TailwindComponents/Button';
import { LoadingToCheckmark } from '../shared/animations/LoadingToCheckmark';
import ProgressStepper from './ProgressStepper';
// import { isBetaEnv } from '../../utils/authUtils';

// Existing parsing functions (unchanged)
interface ParsedReference {
  docNumber: string;
  docLabel: string;
  docUrl?: string;
}

function parseDocReferencesFromText(originalText: string): {
  mainText: string;
  references: ParsedReference[];
} {
  const refSectionRegex = /(\n|\r)?\*\*?references?\:\*?\*?/i;
  const match = originalText.match(refSectionRegex);
  if (!match) {
    return { mainText: originalText, references: [] };
  }

  const splitIndex = match.index || 0;
  const mainText = originalText.slice(0, splitIndex).trim();
  const possibleRefBlock = originalText.slice(splitIndex);

  const lines = possibleRefBlock.split('\n').map((l) => l.trim());
  const references: ParsedReference[] = [];

  const docRegex = /^-\s*\[doc\s*(\d+)\]\s*(.+)?/i;

  for (const line of lines) {
    const docMatch = line.match(docRegex);
    if (docMatch) {
      const docNumber = docMatch[1];
      let docLabel = docMatch[2]?.trim() || '';
      let docUrl: string | undefined;

      const urlRegex = /URL:\s*\[?(https?:\/\/[^\]\s]+)\]?/i;
      const urlMatch = docLabel.match(urlRegex);
      if (urlMatch) {
        docUrl = urlMatch[1];
        docLabel = docLabel.replace(urlRegex, '').trim();
      }

      references.push({ docNumber, docLabel, docUrl });
    }
  }

  return { mainText, references };
}

function linkifyDocReferencesInText(mainText: string, references: ParsedReference[]): string {
  if (!references.length) return mainText;

  const refMap: Record<string, ParsedReference> = {};
  references.forEach((ref) => {
    refMap[ref.docNumber] = ref;
  });

  const inlineDocRegex = /\[Doc\s+(\d+)\]/gi;

  return mainText.replace(inlineDocRegex, (match, docNumberStr) => {
    const docRef = refMap[docNumberStr];
    if (docRef) {
      const link = docRef.docUrl
        ? docRef.docUrl
        : `/docs/${encodeURIComponent(docRef.docLabel)}`;
      return `[Doc ${docNumberStr}](${link})`;
    }
    return match;
  });
}

// Existing interfaces and helper functions (unchanged)
interface CaseAnalysisProps {
  t: any;
  userType: string;
  summary: any;
  analysisState: any;
  analysisLoading: boolean;
  finalizingAnalysis: boolean;
  showFinalAnalysis: boolean;
  handleFinalAnimationDone: () => void;
  handleRunAnalysis: () => void;
  handleRunAnalysisLawyerSSE: () => void;
  handleRunAnalysisWithIndexes: () => void;
  setActiveTab: (val: string) => void;
  dispatch: any;
  caseId: string;
  indexOptionsWithNone: Array<{ value: string; label: string }>;
  selectedApplicableLawsIndex: string;
  setSelectedApplicableLawsIndex: (val: string) => void;
  selectedRelevantCasesIndex: string;
  setSelectedRelevantCasesIndex: (val: string) => void;
}

const analysisStepsLaw = [
  { key: 'applicable_laws_summary', label: 'Applicable Laws' },
  { key: 'relevant_cases_summary', label: 'Relevant Cases' },
  { key: 'adversarial_debate', label: 'Adversarial Debate' },
  { key: 'legal_analysis', label: 'Legal Analysis' },
  { key: 'risk_assessment', label: 'Risk Assessment' },
  { key: 'legal_strategy', label: 'Legal Strategy' },
];

const analysisStepsClient = [
  { key: 'applicable_laws', label: 'Applicable Laws' },
  { key: 'relevant_cases', label: 'Relevant Cases' },
  { key: 'interpretation', label: 'Interpretation' },
  { key: 'risk_assessment', label: 'Risk Assessment' },
  { key: 'recommended_strategy', label: 'Recommended Strategy' },
];

function getAnalysisStepsForUserType(userType: string, view: 'lawyer' | 'client') {
  if (userType.includes('Law')) {
    return view === 'client' ? analysisStepsClient : analysisStepsLaw;
  }
  return analysisStepsClient;
}

function getFilteredProgressSteps(steps: any[], view: 'client' | 'lawyer'): any[] {
  const lawSteps = [
    'initial_overview',
    'legal_data_retrieval',
    'applicable_laws_summary',
    'relevant_cases_summary',
    'adversarial_debate',
    'legal_analysis',
    'risk_assessment',
    'legal_strategy',
  ];
  const clientSteps = [
    'initial_overview',
    'applicable_laws',
    'relevant_cases',
    'interpretation',
    'risk_assessment',
    'recommended_strategy',
  ];
  const allowedSteps = view === 'lawyer' ? lawSteps : clientSteps;
  return steps
    .map((step) => ({
      ...step,
      step: step.step.toLowerCase().replace(/\s+/g, '_'),
    }))
    .filter((step) => allowedSteps.includes(step.step));
}

function normalizeAnalysisKeys(analysisData: any, view: 'lawyer' | 'client'): Record<string, string> {
  if (!analysisData) return {};

  if (view === 'lawyer') {
    return {
      applicable_laws_summary: analysisData.lawyerAnalysis?.applicable_laws_summary || '',
      relevant_cases_summary: analysisData.lawyerAnalysis?.relevant_cases_summary || '',
      adversarial_debate: analysisData.lawyerAnalysis?.adversarial_debate || '',
      legal_analysis: analysisData.lawyerAnalysis?.legal_analysis || '',
      risk_assessment: analysisData.lawyerAnalysis?.risk_assessment || '',
      legal_strategy: analysisData.lawyerAnalysis?.legal_strategy || '',
    };
  }
  return {
    applicable_laws: analysisData.clientAnalysis?.applicable_laws || '',
    relevant_cases: analysisData.clientAnalysis?.relevant_cases || '',
    interpretation: analysisData.clientAnalysis?.interpretation || '',
    risk_assessment: analysisData.clientAnalysis?.risk_assessment || '',
    recommended_strategy: analysisData.clientAnalysis?.recommended_strategy || '',
  };
}

function RenderAnalysisInEditMode({
  steps,
  currentData,
  handleSave,
  handleCancel,
  editedAnalysisData,
  setEditedAnalysisData,
  t,
}: {
  steps: { key: string; label: string }[];
  currentData: Record<string, string>;
  handleSave: () => void;
  handleCancel: () => void;
  editedAnalysisData: Record<string, string>;
  setEditedAnalysisData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  t: any;
}) {
  return (
    <div className="mt-4 space-y-6">
      {steps.map((step) => {
        const val = editedAnalysisData[step.key] || currentData[step.key] || '';
        return (
          <div key={step.key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h4 className="text-lg font-medium mb-2">{step.label}</h4>
            <ReactQuill
              value={val}
              onChange={(newVal) =>
                setEditedAnalysisData((prev) => ({ ...prev, [step.key]: newVal }))
              }
              theme="snow"
            />
          </div>
        );
      })}
      <div className="flex space-x-4">
        <Button variant="primary" onClick={handleSave}>
          {t('Save')}
        </Button>
        <Button variant="destructive" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
      </div>
    </div>
  );
}

function RenderNoAnalysisFallback({
  t,
  userType,
  handleRunAnalysisWithIndexes,
  analysisState,
  summary,
}: {
  t: any;
  userType: string;
  handleRunAnalysisWithIndexes: () => void;
  analysisState: any;
  indexOptionsWithNone: { value: string; label: string }[];
  selectedApplicableLawsIndex: string;
  setSelectedApplicableLawsIndex: (val: string) => void;
  selectedRelevantCasesIndex: string;
  setSelectedRelevantCasesIndex: (val: string) => void;
  summary: any;
}) {
  const isLawyer = userType.includes('Law');
  const noChrono = isLawyer && summary && !summary.chronologyGenerated;

  return (
    <div className="flex flex-col items-center justify-center mt-8 space-y-4">
      <Badge color="gray">{t('No analysis has been run for this case')}</Badge>
      <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-light-sm dark:shadow-dark-sm w-full max-w-md space-y-4">
        <h3 className="text-lg font-heading">{t('Run Case Analysis')}</h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          {t('Click below to analyze your case with the default PONS Agent. This process will:')}
        </p>
        <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-300">
          <li>{t('Retrieve relevant laws and cases from your jurisdiction')}</li>
          <li>{t('Summarize applicable laws and precedents')}</li>
          <li>{t('Conduct an adversarial debate')}</li>
          <li>{t('Provide a legal analysis, risk assessment, and strategy')}</li>
          <li>{t('Compile a professional document for legal use')}</li>
        </ul>
        {noChrono && (
          <div className="text-sm text-yellow-700 dark:text-yellow-300 bg-yellow-50 dark:bg-yellow-900 p-2 rounded">
            {t('No chronology found. You can proceed, but generating it is recommended for better results.')}
          </div>
        )}
        <div className="flex justify-end space-x-2">
          <Button
            variant="primary"
            onClick={handleRunAnalysisWithIndexes}
            disabled={analysisState.isRunning}
            size="small"
          >
            <span>{t('Run Analysis')}</span>
            <PiSparkleLight size={16} className="ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
}

// Main CaseAnalysis component with enhanced UI
const CaseAnalysis: React.FC<CaseAnalysisProps> = ({
  t,
  userType,
  summary,
  analysisState,
  analysisLoading,
  finalizingAnalysis,
  showFinalAnalysis,
  handleFinalAnimationDone,
  handleRunAnalysis,
  handleRunAnalysisLawyerSSE,
  handleRunAnalysisWithIndexes,
  setActiveTab,
  dispatch,
  caseId,
  indexOptionsWithNone,
  selectedApplicableLawsIndex,
  setSelectedApplicableLawsIndex,
  selectedRelevantCasesIndex,
  setSelectedRelevantCasesIndex,
}) => {
  const [isEditingAnalysis, setIsEditingAnalysis] = useState(false);
  const [editedAnalysisData, setEditedAnalysisData] = useState<Record<string, string>>({});
  const [selectedAnalysisView, setSelectedAnalysisView] = useState<'lawyer' | 'client'>(
    userType.includes('Law') ? 'lawyer' : 'client'
  );
  const { isRunning, analysisFinalizing, analysisData, error } = analysisState;

  // if (isBetaEnv) {
  //   return (
  //     <div className="mt-4 p-2 bg-yellow-100 dark:bg-yellow-900 rounded-lg">
  //       <p className="text-yellow-700 dark:text-yellow-300 text-center">
  //         {t('This feature is currently under configuration and will be available in the next release.')}
  //       </p>
  //     </div>
  //   );
  // }

  if (isRunning) {
    const filteredSteps = getFilteredProgressSteps(analysisState.progressSteps, selectedAnalysisView);
    return (
      <div className="mt-4">
        <ProgressStepper steps={filteredSteps} />
      </div>
    );
  }

  if (analysisLoading && !analysisState.analysisData) {
    return <ApiLoader />;
  }

  if (analysisFinalizing) {
    return (
      <LoadingToCheckmark
        isLoading={analysisFinalizing}
        spinnerDuration={1500}
        postCheckmarkDelay={1200}
        onAnimationDone={() => {
          console.log('Completed');
        }}
        serviceName="Analysis"
      />
    );
  }

  if (error) {
    return (
      <div className="mt-4">
        <p className="text-red-600">{analysisState.error}</p>
        <Button onClick={handleRunAnalysis}>{t('Retry Analysis')}</Button>
      </div>
    );
  }

  if (analysisData) {
    const showToggle = userType.includes('Law') && summary?.clientAnalysisComplete;
    const analysisView = userType.includes('Law') ? selectedAnalysisView : 'client';
    const normalized = normalizeAnalysisKeys(analysisData, analysisView);
    const stepsForUser = getAnalysisStepsForUserType(userType, analysisView);
    const anyFieldNonEmpty = stepsForUser.some((s) => normalized[s.key]?.trim() !== '');

    const handleEditAnalysis = () => {
      const viewData = normalizeAnalysisKeys(analysisData, analysisView);
      setEditedAnalysisData(viewData);
      setIsEditingAnalysis(true);
    };
    const handleCancelEditAnalysis = () => {
      const viewData = normalizeAnalysisKeys(analysisData, analysisView);
      setEditedAnalysisData(viewData);
      setIsEditingAnalysis(false);
    };
    const handleSaveAnalysis = async () => {
      setIsEditingAnalysis(false);
    };

    const renderAnalysisToggle = () => {
      return (
        <div className="flex flex-col items-end my-4">
          <div className="inline-flex rounded overflow-hidden border border-gray-300">
            <button
              onClick={() => setSelectedAnalysisView('lawyer')}
              className={`px-6 py-2 text-sm font-medium transition-colors duration-300 ${
                selectedAnalysisView === 'lawyer'
                  ? 'bg-blue-600 text-white'
                  : 'bg-white text-gray-800 hover:bg-gray-100'
              }`}
            >
              {t('My Analysis')}
            </button>
            <button
              onClick={() => setSelectedAnalysisView('client')}
              className={`px-6 py-2 text-sm font-medium transition-colors duration-300 ${
                selectedAnalysisView === 'client'
                  ? 'bg-blue-600 text-white'
                  : 'bg-white text-gray-800 hover:bg-gray-100'
              }`}
            >
              {t('Client Analysis')}
            </button>
          </div>
          {selectedAnalysisView === 'lawyer' &&
            (!analysisData?.lawyerAnalysis || Object.keys(analysisData.lawyerAnalysis).length === 0) && (
              <p className="mt-2 text-xs text-gray-500">
                {t('No My Analysis available. Please run your analysis.')}
              </p>
            )}
          {selectedAnalysisView === 'client' &&
            (!analysisData?.clientAnalysis || Object.keys(analysisData.clientAnalysis).length === 0) && (
              <p className="mt-2 text-xs text-gray-500">
                {t('No Client Analysis available.')}
              </p>
            )}
        </div>
      );
    };

    if (isEditingAnalysis) {
      return (
        <RenderAnalysisInEditMode
          steps={stepsForUser}
          currentData={normalized}
          handleSave={handleSaveAnalysis}
          handleCancel={handleCancelEditAnalysis}
          editedAnalysisData={editedAnalysisData}
          setEditedAnalysisData={setEditedAnalysisData}
          t={t}
        />
      );
    }

    if (!anyFieldNonEmpty) {
      return (
        <RenderNoAnalysisFallback
          t={t}
          userType={userType}
          handleRunAnalysisWithIndexes={handleRunAnalysisWithIndexes}
          analysisState={analysisState}
          indexOptionsWithNone={indexOptionsWithNone}
          selectedApplicableLawsIndex={selectedApplicableLawsIndex}
          setSelectedApplicableLawsIndex={setSelectedApplicableLawsIndex}
          selectedRelevantCasesIndex={selectedRelevantCasesIndex}
          setSelectedRelevantCasesIndex={setSelectedRelevantCasesIndex}
          summary={summary}
        />
      );
    }

    return (
      <div className="mt-4 max-w-3xl mx-auto px-6">
        {showToggle && renderAnalysisToggle()}
        <div className="space-y-2">
          {stepsForUser.map((step) => {
            const rawContent = normalized[step.key]?.trim() || '';
            const { mainText, references } = parseDocReferencesFromText(rawContent);
            const linkifiedText = linkifyDocReferencesInText(mainText, references);

            return (
              <Disclosure key={step.key}>
                {({ open }) => (
                  <div className="border-b border-gray-200 dark:border-gray-700">
                    <Disclosure.Button
                      className={`flex w-full items-center justify-between px-4 py-2 text-left text-sm font-medium ${
                        open ? 'text-blue-600 dark:text-blue-400' : 'text-gray-700 dark:text-gray-300'
                      } hover:bg-gray-100 dark:hover:bg-gray-700`}
                    >
                      <span>{t(step.label)}</span>
                      <ChevronUpIcon
                        className={`h-5 w-5 text-gray-500 transition-transform ${
                          open ? 'rotate-180' : ''
                        }`}
                      />
                    </Disclosure.Button>
                    <Transition
                      show={open}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Disclosure.Panel className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400">
                        {linkifiedText ? (
                          <div className="prose-sm dark:!prose-invert">
                            <ReactMarkdown className="markdown-content" remarkPlugins={[remarkGfm]}>
                              {linkifiedText}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          <Badge color="gray">{t('No data')}</Badge>
                        )}
                        {references.length > 0 && (
                          <div className="mt-4">
                            <h5 className="text-sm font-semibold mb-2">{t('References')}</h5>
                            <ul className="list-disc list-inside text-xs space-y-1">
                              {references.map((ref, i) => {
                                const link = ref.docUrl
                                  ? ref.docUrl
                                  : `/docs/${encodeURIComponent(ref.docLabel)}`;
                                return (
                                  <li key={`ref-${ref.docNumber}-${i}`}>
                                    <strong>[Doc {ref.docNumber}]</strong>{' '}
                                    {ref.docLabel.replace(/(^Title:\s*)|(^URL:\s*)/gi, '')}{' '}
                                    <a
                                      href={link}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="text-blue-600 hover:underline"
                                    >
                                      {ref.docUrl ? t('(Open Link)') : t('(Open Document)')}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </Disclosure.Panel>
                    </Transition>
                  </div>
                )}
              </Disclosure>
            );
          })}
        </div>
        {userType.includes('Law') && (
          <div className="flex justify-end mt-4">
            <Button variant="tertiary" size="small" onClick={handleEditAnalysis}>
              {t('Edit Analysis')}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <RenderNoAnalysisFallback
      t={t}
      userType={userType}
      handleRunAnalysisWithIndexes={handleRunAnalysisWithIndexes}
      analysisState={analysisState}
      indexOptionsWithNone={indexOptionsWithNone}
      selectedApplicableLawsIndex={selectedApplicableLawsIndex}
      setSelectedApplicableLawsIndex={setSelectedApplicableLawsIndex}
      selectedRelevantCasesIndex={selectedRelevantCasesIndex}
      setSelectedRelevantCasesIndex={setSelectedRelevantCasesIndex}
      summary={summary}
    />
  );
};

export default CaseAnalysis;