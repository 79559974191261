import React, { useState, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { FaGlobe, FaImage } from 'react-icons/fa';
import useLinkPreview from '../../hooks/useLinkPreview';

interface ShortLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  maxLength?: number;
  tooltipDelay?: number;
}

const ShortLinkWithTooltip: React.FC<ShortLinkProps> = ({
  href,
  children,
  maxLength = 50,
  tooltipDelay = 500,
  ...rest
}) => {
  // 1) We always compute shortLabel outside conditions
  const shortLabel = React.useMemo(() => {
    if (!href) return 'N/A';
    if (href.length <= maxLength) return href;
    return href.slice(0, maxLength - 3) + '...';
  }, [href, maxLength]);

  // 2) Link preview logic
  const { data, error, isLoading, fetchPreview } = useLinkPreview();

  // 3) State for showing/hiding the tooltip
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const tooltipHeightGuess = 200; // a rough guess for positioning
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // 4) Always define finalLink & truncatedUrl unconditionally, even if data is undefined
  const finalLink = data?.url ?? '';
  const truncatedUrl = React.useMemo(() => {
    if (finalLink.length > 60) {
      return finalLink.slice(0, 57) + '...';
    }
    return finalLink;
  }, [finalLink]);

  // Mouse handlers
  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (!href) return;

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // position above the link
      const rect = e.currentTarget.getBoundingClientRect();
      const x = rect.left;
      const y = rect.top - tooltipHeightGuess - 8;
      setPosition({ x, y });

      timerRef.current = setTimeout(() => {
        // only fetch if we have no data, no error, not loading
        // if (!data && !isLoading && !error) {
        //   fetchPreview(href);
        // }
        setShowTooltip(true);
      }, tooltipDelay);
    },
    [data, isLoading, error, fetchPreview, href, tooltipDelay]
  );

  const handleMouseLeave = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setShowTooltip(false);
  }, []);

  // The dynamic tooltip classes
  const tooltipClass = clsx(
    'absolute z-50 w-72 max-w-xs p-3',
    'bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg',
    'transition-opacity duration-200 transform',
    showTooltip ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
  );

  // 5) Build the tooltip’s content
  let content: JSX.Element;
  if (isLoading) {
    content = (
      <div className="animate-pulse flex flex-col space-y-2">
        <div className="h-4 bg-gray-200 dark:bg-gray-600 w-3/4 rounded" />
        <div className="h-3 bg-gray-200 dark:bg-gray-600 w-full rounded" />
        <div className="h-3 bg-gray-200 dark:bg-gray-600 w-2/3 rounded" />
      </div>
    );
  } else if (error) {
    content = <div className="text-red-500 text-sm">Error: {error}</div>;
  } else if (data) {
    const { title, description, imageUrl, siteName, iconUrl } = data;

    content = (
      <div className="text-gray-800 dark:text-gray-100 text-sm space-y-2">
        {/* Row: icon + title */}
        <div className="flex items-center space-x-2">
          {iconUrl ? (
            <img
              src={iconUrl}
              alt="icon"
              className="w-5 h-5 object-contain"
            />
          ) : (
            <FaGlobe className="text-gray-400 w-5 h-5" />
          )}
          <span className="font-semibold text-sm">
            {title || '(No Title)'}
          </span>
        </div>

        {/* Link + siteName */}
        <div className="flex flex-col text-xs border-l border-gray-300 dark:border-gray-600 pl-2">
          {siteName && (
            <div className="text-gray-600 dark:text-gray-300">
              {siteName}
            </div>
          )}
          <div
            className="truncate text-blue-600 dark:text-blue-400 max-w-[200px]"
            title={finalLink}
          >
            {truncatedUrl}
          </div>
        </div>

        {/* Image or fallback */}
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="preview"
            className="max-h-24 border border-gray-200 dark:border-gray-600 rounded object-contain"
          />
        ) : (
          <div className="border border-gray-200 dark:border-gray-700 rounded h-24 flex items-center justify-center text-gray-400">
            <FaImage className="w-8 h-8" />
          </div>
        )}

        {/* Description */}
        {description && (
          <div className="text-xs text-gray-600 dark:text-gray-300 leading-snug whitespace-pre-wrap">
            {description}
          </div>
        )}
      </div>
    );
  } else {
    // No data yet => fallback
    content = (
      <div className="text-xs text-gray-700 dark:text-gray-300">
        {href}
      </div>
    );
  }

  return (
    <div className='relative'>
      {/* The link */}
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="text-blue-600 dark:text-blue-400 hover:underline"
        {...rest}
      >
        {children && children !== href ? children : shortLabel}
      </a>

      {/* The tooltip */}
      {/* <div
        className={tooltipClass}
        style={{ top: position.y, left: position.x }}
      >
        <div className="relative">
          {content}
          {/* arrow pointing downward from the tooltip’s bottom 
          <div className="absolute bottom-[-8px] left-6 w-0 h-0 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-white dark:border-t-gray-800" />
        </div>
      </div> */}
    </div>
  );
};

export default ShortLinkWithTooltip;
