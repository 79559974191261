import React, { useState } from 'react';
import { useAppDispatch } from '../../../store';
import { createIndex, fetchIndexes } from '../../../store/actions/AIStudioAction';
import { useTranslation } from 'react-i18next';
import Input from '../../shared/TailwindComponents/Input';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Dropdown from '../../shared/TailwindComponents/Dropdown';
import FileUploader from '../../shared/TailwindComponents/FileUploader2';
import Button from '../../shared/TailwindComponents/Button';
import Badge from '../../shared/TailwindComponents/Badge';
import { getUserType } from '../../../store/actions/ChatAction';
import { PiUserLight, PiMagicWandLight } from "react-icons/pi";

interface CreateAgentFormProps {
  onCancel: () => void;
}

const CreateAgentForm: React.FC<CreateAgentFormProps> = ({ onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userTypeName = getUserType();

  const [newIndexName, setNewIndexName] = useState('');
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [agentType, setAgentType] = useState<'CustomAgent' | 'CompanyAgent'>('CustomAgent');
  const [systemInstruction, setSystemInstruction] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const canCreateCompanyAgent = userTypeName === 'LawFirmAdmin' || userTypeName === 'BusinessAdmin';

  const handleCreateIndex = async () => {
    if (!newIndexName || newFiles.length === 0) {
      // Add feedback here if needed (e.g., toast notification)
      return;
    }
    setIsCreating(true);
    await dispatch(createIndex(newIndexName, newFiles, agentType, systemInstruction) as any);
    dispatch(fetchIndexes());
    setIsCreating(false);
    onCancel(); // Return to list view
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-2xl mx-auto mt-5">
              <div className="sticky pb-4 mb-4 top-0 z-10 bg-white dark:bg-gray-900  border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center gap-2">
        <PiMagicWandLight size={28} className="text-primary-500" />
        {t('Create New Agent')}
      </h2>
      <div className="flex gap-2">
          <Button variant="neutral" size="small" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" type="submit" form="add-case-form"             disabled={isCreating || (!canCreateCompanyAgent && agentType === 'CompanyAgent') || !newIndexName || newFiles.length === 0}
          >
            {t('Create Case')}
          </Button>
        </div>
        </div>
      <form onSubmit={(e) => { e.preventDefault(); handleCreateIndex(); }} className="space-y-6">
        <Input
          label={t('Agent Name')}
          name="newIndexName"
          value={newIndexName}
          onChange={(e) => setNewIndexName(e.target.value)}
          placeholder={t('Enter agent name')}
          type="text"
          icon={<PiUserLight size={20} className="text-gray-500" />}
          required
        />
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">{t('Agent Type')}</label>
          <Dropdown
            options={[
              { label: t('Custom Agent'), value: 'CustomAgent' },
              { label: t('Company Agent'), value: 'CompanyAgent', active: canCreateCompanyAgent },
            ]}
            value={agentType}
            onSelect={(option) => setAgentType(option.value as 'CustomAgent' | 'CompanyAgent')}
            placeholder={t('Select Agent Type')}
          />
          {!canCreateCompanyAgent && agentType === 'CompanyAgent' && (
            <p className="text-sm text-red-600 mt-1">{t('You do not have permission to create a company agent.')}</p>
          )}
        </div>
        <Textarea
                  label={t('User Context (Optional)')}
                  name="systemInstruction"
                  value={systemInstruction}
                  onChange={(e) => setSystemInstruction(e.target.value)}
                  placeholder={t('Enter a context for the agent')} 
                  id={''}        />
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">{t('Files')}</label>
          <FileUploader files={newFiles} onFilesChange={setNewFiles} />
          {newFiles.length > 0 && (
            <div className="mt-2 flex flex-wrap gap-2">
              {newFiles.map((file, idx) => (
                <Badge key={idx} color="blue">{file.name}</Badge>
              ))}
            </div>
          )}
        </div>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            type="submit"
            variant="primary"
            size="small"
            disabled={isCreating || (!canCreateCompanyAgent && agentType === 'CompanyAgent') || !newIndexName || newFiles.length === 0}
          >
            {isCreating ? t('Creating...') : t('Create Agent')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateAgentForm;