import React from 'react';
import dayjs from 'dayjs';
import Table from '../shared/TailwindComponents/Table';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import { Task } from './types';
import { PiTrashLight } from "react-icons/pi";
import { CheckIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface TaskTableProps {
  tasks: Task[];
  onRowClick?: (taskID: string) => void;
  onCompleteTask: (taskID: string) => void;
  onDeleteTask: (taskID: string) => void;
  userType: string;
  userID: string;
}

/**
 * Renders a tasks table reusing the global <Table> component,
 * with columns: [Task Name, Status, Due Date, Actions].
 * Uses a custom renderRow to match global styling and add action buttons.
 */
const TaskTable: React.FC<TaskTableProps> = ({
  tasks,
  onRowClick,
  onCompleteTask,
  onDeleteTask,
  userType,
  userID,
}) => {
  // Updated columns to match columnIconMap keys
  const columns = ["Task Name", "Status", "Due Date", "Actions"];
  const {t} = useTranslation()

  // Convert tasks into row data
  const data = tasks.map((task, rowIdx) => ({
    id: task.TaskID,
    "Task Name": task.Title,
    Status: task.Status,
    "Due Date": task.TaskDeadline
      ? dayjs(task.TaskDeadline).format('YYYY-MM-DD HH:mm')
      : 'N/A',
    Actions: '',
    rawTask: task,
    rowIdx, // Add rowIdx for styling
  }));

  // Helper to determine if delete/complete actions are allowed
  function canDeleteOrComplete(task: Task) {
    if (
      (userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') ||
      (task.UserID === userID && task.AssignedBy === userID)
    ) {
      return task.Status === 'Active';
    }
    return false;
  }

  // Custom row renderer with global Table styling
  const renderRow = (row: any, rowIndex: number) => {
    const { id, "Task Name": taskName, Status, "Due Date": dueDate, rawTask } = row;

    const handleRowClick = () => {
      if (onRowClick) onRowClick(id);
    };

    const actionsCell = (
      <div
        className="flex items-center gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        {canDeleteOrComplete(rawTask) && (
          <Button
            variant="neutral"
            size="small"
            tooltip="Delete Task"
            onClick={() => onDeleteTask(id)}
          >
            <PiTrashLight className="h-4 w-4" />
          </Button>
        )}
        {rawTask.Status === 'Active' && (
          <Button
            variant="primary"
            size="small"
            tooltip="Complete Task"
            onClick={() => onCompleteTask(id)}
          >
            <CheckIcon className="h-4 w-4" />
          </Button>
        )}
      </div>
    );

    return (
      <tr
        key={id}
        className={clsx(
          'cursor-pointer transition-all duration-300',
          rowIndex % 2 === 0
            ? 'bg-primary-50 dark:bg-primary-900'
            : 'bg-primary-100 dark:bg-primary-800',
          'hover:bg-primary-200 dark:hover:bg-primary-700'
        )}
        onClick={handleRowClick}
      >
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
          {taskName}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm">
          {Status && Status !== 'N/A' ? (
            <Badge
              color={
                Status === 'Active'
                  ? 'blue'
                  : Status === 'Completed'
                  ? 'green'
                  : Status === 'Deleted'
                  ? 'red'
                  : 'gray'
              }
            >
              {t(Status)}
            </Badge>
          ) : (
            'N/A'
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
          {dueDate}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
          {actionsCell}
        </td>
      </tr>
    );
  };

  return (
    <div className="overflow-x-auto h-full">
      <Table
        columns={columns}
        data={data}
        renderRow={renderRow}
      />
    </div>
  );
};

export default TaskTable;