import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {useHistory, useLocation } from 'react-router-dom';
import {
  FaArrowLeft,
  FaCheckCircle,
  FaClipboardCheck,
} from 'react-icons/fa';
import {
  AiOutlineLoading3Quarters,
  AiOutlineCreditCard,
} from 'react-icons/ai';
import { useAppDispatch } from '../../../store';
import { getLicenseDetails } from '../../../store/actions/LicenseAction';
import { getAiModels } from '../../../store/actions/ChatAction';
import { createCheckoutSession } from '../../../store/actions/StripeActions';
import { FiLoader } from 'react-icons/fi';
import SuccessPage from './SuccessPage';
import CancelPage from './CancelPage';
import { useTranslation } from 'react-i18next';

interface LicenseData {
  LicenseID: string;
  LicenseName: string;
  Description?: string;
  FinalPrice: number;
  DurationInDays: number;
  IsSubscription: boolean;
  IsOneTimePurchase: boolean;
  TotalQueries: number;
  MinimumUsers?: number;
  KeyBenefits?: string[];
  RecommendedUseCases?: string[];
  SupportLevel?: string;
  MinimumQuantity?: number
}

const CheckoutPage: React.FC = () => {
  // const { licenseId } = useParams<{ licenseId: string }>();
  const history = useHistory();

  const [license, setLicense] = useState<LicenseData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const dispatch = useAppDispatch()
  const [paymentStarted, setPaymentStarted] = useState(false);
  const {t} = useTranslation()

  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get('country');
  const licenseId = queryParams.get('licenseId');
  const quantity = parseInt(queryParams.get('quantity') || '1', 10);
  const success = queryParams.get('success');
  const canceled = queryParams.get('canceled');
  const failed = queryParams.get('failed');
  // We only do Stripe here

  const fetchLicenseDetails = useCallback(async (id: string, country: string) => {
    try {
      setLoading(true);
      const response = await dispatch(getLicenseDetails(id, country))

      if (response) {
        setLicense(response);
      } else {
        setError(t('License not found.'));
      }
    } catch (err) {
      console.error('Error fetching license:', err);
      setError('Something went wrong.');
    } finally {
      setLoading(false);
    }
  }, [dispatch])


  useEffect(() => {
    if (licenseId && country) {

      fetchLicenseDetails(licenseId, country);
    }


    // Handle URL query params for payment result

    if (success || canceled || failed) {
      setTimeout(() => {
        dispatch(getAiModels())
        history.push('/subscriptions')
      }, 10000); // ✅ Redirect after 10s
    }
  }, [licenseId, country, success, canceled, failed, fetchLicenseDetails,dispatch,history]);


  const handlePackageChange = () => {
    history.push('/plans');
  };

  const handleCheckout = async () => {
    if (!license) return;

    try {
      setPaymentStarted(true);
      const response: any = await dispatch(createCheckoutSession(license.LicenseID, country, quantity));
      if (response) {
        window.location.assign(response.session_url);
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
    } finally {
      setPaymentStarted(false);
    }
  };
  
  const licenseDetails = useMemo(() => {
    if (!license) return null;
    const months = Math.round(license.DurationInDays / 30);
    const monthsLabel = months === 1 ? t('Month') : t('Months');
    const totalPrice = license.FinalPrice * quantity;
    const totalQueries = license.TotalQueries * quantity;

    return {
      ...license,
      months,
      monthsLabel,
      formattedPrice: `$${totalPrice.toFixed(2)}`,
      TotalQueries: totalQueries,
      Quantity: quantity,
    };
  }, [license, quantity, t]);
  

  // -----------------------
  // RENDER LOGIC
  // -----------------------
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen dark:bg-gray-900">
        <AiOutlineLoading3Quarters className="animate-spin text-blue-500 text-4xl" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center dark:bg-gray-900">
        <p className="text-center text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  if(success){
    return (
      <div className="flex justify-center items-center min-h-screen dark:bg-gray-900">
        <SuccessPage />
      </div>
    )
  }

  if(canceled){
    return (
      <div className="flex justify-center items-center min-h-screen dark:bg-gray-900">
        <CancelPage />
      </div>
    )
  }

  if (!licenseDetails) {
    return (
      <div className="min-h-screen flex items-center justify-center dark:bg-gray-900">
        <p className="text-center text-gray-700 dark:text-gray-300 text-lg">
          {t("No license selected or not found.")}
        </p>
      </div>
    );
  }


  // -----------------------
  // MAIN UI
  // -----------------------
  return (
    <div className="min-h-screen flex flex-col dark:bg-gray-900 transition-colors duration-200">
      {/* HERO / HEADER */}
      <header className="relative bg-gradient-to-r from-indigo-500 to-blue-500 text-white p-6">
        <div className="max-w-6xl mx-auto flex items-center gap-3">
          <AiOutlineCreditCard className="text-3xl" />
          <h1 className="text-2xl font-bold">{t("Secure Checkout")}</h1>
        </div>
      </header>

      <main className="flex-grow max-w-6xl mx-auto w-full p-4 md:p-8">
        {/* LICENSE DETAILS CARD */}
        <div className="mt-6 bg-white dark:bg-gray-800 rounded shadow-lg p-6">
          {/* LICENSE TITLE */}
          <h2 className="text-xl font-semibold mb-2 dark:text-gray-100">
            {licenseDetails.LicenseName}
          </h2>
          {/* DESCRIPTION */}
          {licenseDetails.Description && (
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              {licenseDetails.Description}
            </p>
          )}

          {/* LICENSE INFO GRID */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {t("Price")}
              </span>
              <div className='flex flex-row gap-2'>
                <span className="text-2xl font-bold dark:text-gray-100">
                  ${licenseDetails.FinalPrice.toFixed(2)}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {t("per month")}
                </span>

              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {t("Duration")}
              </span>
              <span className="text-lg dark:text-gray-100">
                {licenseDetails.months} {licenseDetails.monthsLabel}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {t("Total Queries")}
              </span>
              <span className="text-lg dark:text-gray-100">
                {licenseDetails.TotalQueries}
              </span>
            </div>
            <div className="flex flex-col">
              {
                licenseDetails.MinimumUsers ? (
                  <>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {t("Minimum Users")}
                    </span>
                    <span className="text-lg dark:text-gray-100">
                      {licenseDetails.MinimumUsers}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                     {t("Minimum Qnantity")}
                    </span>
                    <span className="text-lg dark:text-gray-100">
                      {licenseDetails.MinimumQuantity}
                    </span>
                  </>
                )
              }

            </div>
          </div>

          {/* SUPPORT LEVEL */}
          {licenseDetails.SupportLevel && (
            <p className="mt-2 text-sm dark:text-gray-200">
              <strong>{t("Support Level")}:</strong> {licenseDetails.SupportLevel}
            </p>
          )}

          {/* KEY BENEFITS */}
          {licenseDetails.KeyBenefits && licenseDetails.KeyBenefits.length > 0 && (
            <div className="mt-4">
              <h4 className="text-sm font-semibold dark:text-gray-100 mb-2">
                {t("Key Benefits")}
              </h4>
              <ul className="space-y-1">
                {licenseDetails.KeyBenefits.map((benefit, idx) => (
                  <li key={idx} className="flex items-start gap-2">
                    <FaCheckCircle className="text-green-500 mt-1" />
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      {benefit}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* NEXT STEPS INFO */}
        <div className="mt-6 bg-gray-50 dark:bg-gray-700 p-6 rounded shadow space-y-2">
          <h3 className="text-lg font-semibold dark:text-gray-100 mb-2 flex items-center gap-2">
            <FaClipboardCheck className="text-green-500" />
            {t("Next Steps")}
          </h3>
          <p className="text-gray-600 dark:text-gray-300 text-sm">
            1. {t("You’ll be redirected to our secure Stripe payment page.")}
          </p>
          <p className="text-gray-600 dark:text-gray-300 text-sm">
            2. {t("After a successful payment, you’ll return here for final activation.")}
          </p>
          <p className="text-gray-600 dark:text-gray-300 text-sm">
            3. {t("Need help? Our support team is ready to assist you.")}
          </p>
        </div>

        {/* ACTION BUTTONS */}
        <div className="mt-8 flex flex-col justify-space-between sm:flex-row gap-4">
          <button
            onClick={handlePackageChange}
            className="flex items-center justify-center gap-2 px-5 py-3 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-600 dark:text-gray-100 transition-all"
          >
            <FaArrowLeft />
            <span>{t("Change Package")}</span>
          </button>
          <button
            onClick={handleCheckout}
            disabled={loading}
            className="flex items-center justify-center gap-2 px-5 py-3 rounded-md bg-gradient-to-r from-indigo-500 to-blue-500 text-white hover:opacity-90 transition-all"
          >
            {paymentStarted ? (
              <div
                className="flex items-center justify-center gap-2"
              >
                <FiLoader className="animate-spin" />
                <span>{t("Please wait....")}</span>
              </div>
            ) : (
             t('Proceed to Payment')
            )}
          </button>
        </div>
      </main >

      {/* FOOTER */}
      < footer className="bg-white dark:bg-gray-800 py-4 mt-6" >
        <div className="max-w-6xl mx-auto text-center text-xs text-gray-400 dark:text-gray-500">
          &copy; {new Date().getFullYear()} PONS LABS. {t("All rights reserved.")}
        </div>
      </footer >
    </div >
  );
};

export default CheckoutPage;
