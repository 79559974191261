import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, FieldInputProps, FormikProps } from 'formik';
import * as Yup from 'yup';
import { IEditProjectPayload, IProject } from './ProjectsInterfaces';
import { RootState, useAppDispatch } from '../../store';
import {
  fetchProjectCategory,
  fetchPriority,
  fetchProjectStatus,
} from '../../store/actions/DropdownActions';
import {
  editProject,
  deleteProject,
  approveProject,
  fetchProjectDetails,
} from '../../store/actions/ProjectActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';
import { getAiModels, getUserID, getUserType } from '../../store/actions/ChatAction';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import { LinearProgress, Tooltip } from '@mui/material';
import {
  PiGearLight,
  PiUsersLight,
  PiBriefcaseLight,
  PiPencilSimpleLight,
  PiCheckCircleLight,
  PiXCircleLight,
  PiTrashLight,
  PiCalendarLight,
  PiClockLight,
  PiHourglassLight,
  PiRankingLight,
  PiAlignBottomLight,
  PiCirclesThreePlusLight,
  PiUserLight,
  PiClipboardTextLight,
} from 'react-icons/pi';
import { motion, AnimatePresence } from 'framer-motion';
import dayjs from 'dayjs';
import { hideModal, showModal } from '../../store/actions/modalActions';
import Badge from '../shared/TailwindComponents/Badge';
import Button from '../shared/TailwindComponents/Button'; // Updated import
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';

interface ProjectViewProps {
  project: IProject;
  onClose: () => void;
}

const CustomAvatar: React.FC<{ name: string; role: string }> = ({ name, role }) => {
  return (
    <Tooltip title={`${name} (${role})`}>
      <PiUserLight className="text-gray-500" size={24} />
    </Tooltip>
  );
};

const ProjectView: React.FC<ProjectViewProps> = ({ project, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userType = getUserType();
  const userId = getUserID();
  const [localProjectDetails, setLocalProjectDetails] = useState<IProject | null>(null);

  const categoriesData = useSelector((state: RootState) => state.dropdown.projectCategory) || [];
  const prioritiesData = useSelector((state: RootState) => state.dropdown.priority) || [];
  const projectStatus = useSelector((state: RootState) => state.dropdown.projectStatus) || [];
  const employees = useSelector((state: RootState) => Object.values(state.employees.employees)) || [];
  const servicesData = useSelector((state: RootState) => state.model.services) || [];

  const isProjectLead = localProjectDetails?.ProjectLead?.ProjectLeadID === userId;
  const isAdmin = userType === 'BusinessAdmin';
  const canEdit = isAdmin || isProjectLead;
  const canApprove = isAdmin || isProjectLead;
  const canDelete = isAdmin;

  useEffect(() => {
    if (project) {
      setLocalProjectDetails(project);
      dispatch(fetchProjectDetails({ ProjectID: project.ProjectID }));
    }
  }, [dispatch, project]);

  useEffect(() => {
    if (!categoriesData.length) dispatch(fetchProjectCategory());
    if (!prioritiesData.length) dispatch(fetchPriority());
    if (!projectStatus.length) dispatch(fetchProjectStatus());
    if (!employees.length) dispatch(fetchAllEmployees());
    if (!servicesData.length) dispatch(getAiModels());
  }, [dispatch, categoriesData.length, prioritiesData.length, projectStatus.length, employees.length, servicesData.length]);

  const validationSchema = Yup.object({
    ProjectName: Yup.string().required(t('Project Name is required')),
    ProjectDescription: Yup.string(),
    StartDate: Yup.date().required(t('Start Date is required')),
    EndDate: Yup.date().min(Yup.ref('StartDate'), t('End Date must be after Start Date')),
  });

  const handleSaveChanges = async (values: IProject) => {
    if (!canEdit || !localProjectDetails) return;
    setIsLoading(true);
    const data: IEditProjectPayload = {
      ProjectID: localProjectDetails.ProjectID,
      UserID: localProjectDetails.UserID || 0,
      UserType: userType,
      ServiceAllocations: localProjectDetails.AllocatedServices,
      ProjectDescription: values.ProjectDescription || '',
      CategoryType: values.CategoryType || '',
      CategoryName: values.CategoryName || '',
      EndDate: values.EndDate || '',
      PriorityLevel: values.PriorityLevel || '',
      PriorityName: values.PriorityName || '',
      Status: values.Status || '',
      StatusID: values.StatusID || '',
      ProjectApproverID: localProjectDetails.ProjectApprover?.ProjectApproverID || '',
      ProjectLeadID: localProjectDetails.ProjectLead?.ProjectLeadID || '',
      ProjectMembers: localProjectDetails.ProjectMembers,
      ProjectName: values.ProjectName || '',
      StartDate: values.StartDate || '',
      OrganizationID: localProjectDetails.OrganizationID || 0,
    };
    try {
      const result = await dispatch(editProject(data));
      if (result) {
        setIsEditing(false);
        const updatedDetails = await dispatch(fetchProjectDetails({ ProjectID: localProjectDetails.ProjectID }));
        setLocalProjectDetails(updatedDetails);
        dispatch(showFeedbackModal({ message: t('Project updated successfully!'), modalType: 'success', showModal: true, duration: 2000 }));
      }
    } catch (error) {
      dispatch(showFeedbackModal({ message: t('Failed to update project'), modalType: 'error', showModal: true, duration: 2000 }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProject = async () => {
    if (!canDelete) return;
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to delete this project?'),
        onConfirm: async () => {
          dispatch(hideModal());
          setIsLoading(true);
          const success = await dispatch(deleteProject({ ProjectID: project.ProjectID }));
          setIsLoading(false);
          if (success) {
            dispatch(showFeedbackModal({ message: t('Project deleted successfully!'), modalType: 'success', showModal: true, duration: 2000 }));
            onClose();
          }
        },
        onCancel: () => dispatch(hideModal()),
      })
    );
  };

  const handleApproveProject = async () => {
    if (!canApprove) return;
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to approve this project?'),
        onConfirm: async () => {
          dispatch(hideModal());
          setIsLoading(true);
          const success = await dispatch(approveProject({ ProjectID: project.ProjectID }));
          setIsLoading(false);
          if (success) {
            dispatch(showFeedbackModal({ message: t('Project approved successfully!'), modalType: 'success', showModal: true, duration: 2000 }));
            dispatch(fetchProjectDetails({ ProjectID: project.ProjectID }));
          }
        },
        onCancel: () => dispatch(hideModal()),
      })
    );
  };

  const handleRemoveMember = (memberID: string) => {
    if (!canEdit || !localProjectDetails) return;
    setLocalProjectDetails({
      ...localProjectDetails,
      ProjectMembers: localProjectDetails.ProjectMembers.filter((m) => m.MemberID !== memberID),
    });
  };

  const handleServiceAmountChange = (index: number, amount: number) => {
    if (!canEdit || !localProjectDetails) return;
    const updatedServices = [...localProjectDetails.AllocatedServices];
    updatedServices[index].AddQueries = amount;
    setLocalProjectDetails({ ...localProjectDetails, AllocatedServices: updatedServices });
  };

  const calculateDaysLeft = () => {
    const endDate = dayjs(localProjectDetails?.EndDate);
    const today = dayjs();
    const daysLeft = endDate.diff(today, 'day');
    let color = 'text-green-500';
    if (daysLeft < 0) {
      color = 'text-red-500';
    } else if (daysLeft <= 7) {
      color = 'text-orange-500';
    }
    return { daysLeft, color };
  };

  if (!localProjectDetails) {
    return (
      <div className="p-4 text-center text-gray-500 dark:text-gray-400 animate-pulse">
        {t('Loading...')}
      </div>
    );
  }

  const { daysLeft, color } = calculateDaysLeft();

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      className="relative bg-white dark:bg-gray-900 rounded-xl p-6 shadow-md hover:shadow-lg transition-all duration-300 hover:-translate-y-1 mx-auto text-gray-900 dark:text-white"
    >
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-50">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
            className="h-6 w-6 border-2 border-t-transparent border-primary-500 rounded-full"
          />
        </div>
      )}

      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-3">
          <PiBriefcaseLight className="text-blue-500 dark:text-blue-300" size={18} />
          {isEditing ? (
            <h2 className="text-base font-semibold text-gray-900 dark:text-white">
              {t('Edit Project')}: {localProjectDetails.ProjectName}
            </h2>
          ) : (
            <h2 className="text-base text-lg font-semibold text-gray-900 dark:text-white">{localProjectDetails.ProjectName}</h2>
          )}
          {!isEditing && (
            <Badge color={localProjectDetails.Status === 'Active' ? 'green' : 'gray'} tooltip={t(localProjectDetails.Status)}>
              {t(localProjectDetails.Status)}
            </Badge>
          )}
        </div>
        <div className="flex gap-2">
          {canEdit && (
            <Button
              variant="tertiary"
              size="small"
              isLoading={isLoading}
              onClick={() => setIsEditing(!isEditing)}            >
              {isEditing ? <PiCheckCircleLight size={18} /> : <PiPencilSimpleLight size={18} />}
            </Button>
          )}
          {canDelete && (
            <Button
              variant="destructive"
              size="small"
              isLoading={isLoading}
              onClick={handleDeleteProject}
            >
              <PiTrashLight size={18} />
            </Button>
          )}
          <Button
            variant="neutral"
            size="small"
            isLoading={isLoading}
            onClick={onClose}
          >
            <PiXCircleLight size={18} />
          </Button>
        </div>
      </div>

      <AnimatePresence mode="wait">
        {isEditing ? (
          <motion.div
            key="edit"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="space-y-4"
          >
            <Formik initialValues={localProjectDetails} validationSchema={validationSchema} onSubmit={handleSaveChanges}>
              {({ setFieldValue, values }) => (
                <Form className="space-y-4">
                  <div className="space-y-3">
                    <h3 className="text-base font-semibold text-gray-700 dark:text-gray-300">{t('Details')}</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="flex items-center gap-2">
                      <PiBriefcaseLight className="text-blue-500" size={18} />
                      <div className="flex-1">
                        <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Project Name')}</label>
                        <Field name="ProjectName">
                          {({ field, form }: { field: FieldInputProps<any>; form: FormikProps<any> }) => (
                            <Input
                              type="text"
                              id="ProjectName"
                              {...field}
                              className="w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded px-3 py-1.5 text-sm focus:ring-1 focus:ring-primary-500"
                              error={form.touched.ProjectName && !!form.errors.ProjectName}
                              helperText={form.touched.ProjectName ? form.errors.ProjectName as string : undefined}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                      <div className="flex items-center gap-2">
                        <PiGearLight className="text-purple-500" size={18} />
                        <div className="flex-1">
                          <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Status')}</label>
                          <Dropdown
                            options={projectStatus}
                            value={values.StatusID}
                            onSelect={(option) => setFieldValue('StatusID', option.value)}
                            placeholder={t('Select Status')}
                            className="bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-600 text-sm py-1.5"
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <PiRankingLight className="text-blue-500" size={18} />
                        <div className="flex-1">
                          <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Priority')}</label>
                          <Dropdown
                            options={prioritiesData}
                            value={values.PriorityLevel}
                            onSelect={(option) => setFieldValue('PriorityLevel', option.value)}
                            placeholder={t('Select Priority')}
                            className="bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-600 text-sm py-1.5"
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <PiCirclesThreePlusLight className="text-green-500" size={18} />
                        <div className="flex-1">
                          <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Category')}</label>
                          <Dropdown
                            options={categoriesData}
                            value={values.CategoryType}
                            onSelect={(option) => setFieldValue('CategoryType', option.value)}
                            placeholder={t('Select Category')}
                            className="bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-600 text-sm py-1.5"
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <PiCalendarLight className="text-red-500" size={18} />
                        <div className="flex-1">
                          <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Start Date')}</label>
                          <CustomDateTimePicker
                            value={dayjs(values.StartDate)}
                            onChange={(date) => setFieldValue('StartDate', date?.toISOString())}
                            dateOnly
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <PiClockLight className="text-orange-500" size={18} />
                        <div className="flex-1">
                          <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('End Date')}</label>
                          <CustomDateTimePicker
                            value={dayjs(values.EndDate)}
                            onChange={(date) => setFieldValue('EndDate', date?.toISOString())}
                            dateOnly
                          />
                        </div>
                      </div>
                      <div className="col-span-2 flex items-center gap-2">
                        <PiClipboardTextLight className="text-teal-500" size={18} />
                        <div className="flex-1">
                          <label className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Description')}</label>
                          <Field name="ProjectDescription">
                            {({ field, form }: { field: FieldInputProps<any>; form: FormikProps<any> }) => (
                              <Textarea
                                label={''} id="ProjectDescription"
                                {...field}
                                error={form.touched.ProjectDescription && form.errors.ProjectDescription
                                  ? form.errors.ProjectDescription as string
                                  : undefined}                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-3">
                    <h3 className="text-base font-semibold text-gray-700 dark:text-gray-300">{t('Members')}</h3>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <CustomAvatar name={localProjectDetails.ProjectLead?.ProjectLeadName as string} role="Lead" />
                        <div className="text-sm">
                          <span className="font-medium text-gray-500 dark:text-gray-400">{t('Lead')}: </span>
                          <span className="text-gray-900 dark:text-white">{localProjectDetails.ProjectLead?.ProjectLeadName}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <CustomAvatar name={localProjectDetails.ProjectApprover?.ProjectApproverName as string} role="Approver" />
                        <div className="text-sm">
                          <span className="font-medium text-gray-500 dark:text-gray-400">{t('Approver')}: </span>
                          <span className="text-gray-900 dark:text-white">{localProjectDetails.ProjectApprover?.ProjectApproverName}</span>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center gap-2">
                          <PiUsersLight className="text-gray-500" size={18} />
                          <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Members')}</span>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {localProjectDetails.ProjectMembers.filter((m) => m.Role === 'Member').map((member) => (
                            <div
                              key={member.MemberID}
                              className="flex items-center gap-2 bg-gray-100 dark:bg-gray-700 p-1.5 rounded"
                            >
                              <CustomAvatar name={member.MemberName} role={member.Role} />
                              <span className="text-sm text-gray-900 dark:text-white">{member.MemberName}</span>
                              <Button
                                variant="neutral"
                                size="small"
                                isLoading={isLoading}
                                onClick={() => handleRemoveMember(member.MemberID)}
                                className="bg-transparent p-1 text-gray-500 hover:text-red-500"
                              >
                                <PiTrashLight size={12} />
                              </Button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-3">
                    <h3 className="text-base font-semibold text-gray-700 dark:text-gray-300">{t('Services')}</h3>
                    {localProjectDetails.AllocatedServices.length > 0 ? (
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                        {localProjectDetails.AllocatedServices.map((service, index) => (
                          <div key={index} className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                            <div className="flex items-center gap-2">
                              <PiBriefcaseLight className="text-blue-500" size={18} />
                              <span className="text-sm font-medium text-gray-900 dark:text-white">{service.ServiceName}</span>
                            </div>
                            <div className="mt-2">
                              <LinearProgress
                                value={(service.RemainingQueries / service.AllocatedQueries) * 100}
                                variant="determinate"
                                sx={{ height: 6, borderRadius: 2, bgcolor: 'gray', '& .MuiLinearProgress-bar': { bgcolor: '#00ccff' } }}
                              />
                              <p className="text-sm mt-1 text-gray-500 dark:text-gray-400">
                                {service.RemainingQueries}/{service.AllocatedQueries} {t('queries')}
                              </p>
                            </div>
                            <div className="mt-2">
                              <label className="text-sm text-gray-500 dark:text-gray-400">{t('Add Queries')}</label>
                              <input
                                type="number"
                                value={service.AddQueries || 0}
                                onChange={(e) => handleServiceAmountChange(index, parseInt(e.target.value) || 0)}
                                className="w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded px-2 py-1 text-sm focus:ring-1 focus:ring-primary-500"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-sm text-gray-500 dark:text-gray-400">{t('No services allocated')}</p>
                    )}
                  </div>

                  <div className="flex justify-end gap-2 mt-4">
                    <Button
                      variant="neutral"
                      size="medium"
                      isLoading={isLoading}
                      onClick={() => setIsEditing(false)}
                      className="px-3 py-1 text-sm text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      variant="primary"
                      size="medium"
                      isLoading={isLoading}
                      onClick={() => document.querySelector('form')?.requestSubmit()}
                      className="px-3 py-1 text-sm text-white bg-primary-500 rounded hover:bg-primary-600"
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </motion.div>
        ) : (
          <motion.div
            key="view"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="space-y-4"
          >
            <div className="flex items-center gap-2">
              <PiHourglassLight className={color} size={18} />
              <span className={`text-sm font-medium ${color}`}>
                {daysLeft} {t('days left')}
              </span>
            </div>

            <div className="space-y-3">
              <h3 className="text-base font-semibold text-gray-700 dark:text-gray-300">{t('Details')}</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex items-center gap-2">
                  <PiAlignBottomLight className="text-blue-500" size={18} />
                  <div className="flex-1">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Priority')}</span>
                    <p className="text-sm text-gray-900 dark:text-white">{t(`${localProjectDetails.PriorityName}`)}</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <PiCirclesThreePlusLight className="text-green-500" size={18} />
                  <div className="flex-1">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Category')}</span>
                    <p className="text-sm text-gray-900 dark:text-white">{t(`${localProjectDetails.CategoryName}`)}</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <PiCalendarLight className="text-red-500" size={18} />
                  <div className="flex-1">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Start Date')}</span>
                    <p className="text-sm text-gray-900 dark:text-white">{dayjs(localProjectDetails.StartDate).format('YYYY-MM-DD')}</p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <PiClockLight className="text-orange-500" size={18} />
                  <div className="flex-1">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('End Date')}</span>
                    <p className="text-sm text-gray-900 dark:text-white">{dayjs(localProjectDetails.EndDate).format('YYYY-MM-DD')}</p>
                  </div>
                </div>
                <div className="col-span-2 flex items-center gap-2">
                  <PiBriefcaseLight className="text-teal-500" size={18} />
                  <div className="flex-1">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Description')}</span>
                    <p className="text-sm text-gray-600 dark:text-gray-300">{localProjectDetails.ProjectDescription || 'N/A'}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <h3 className="text-base font-semibold text-gray-700 dark:text-gray-300">{t('Members')}</h3>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <CustomAvatar name={localProjectDetails.ProjectLead?.ProjectLeadName as string} role="Lead" />
                  <div className="text-sm">
                    <span className="font-medium text-gray-500 dark:text-gray-400">{t('Lead')}: </span>
                    <span className="text-gray-900 dark:text-white">{localProjectDetails.ProjectLead?.ProjectLeadName}</span>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <CustomAvatar name={localProjectDetails.ProjectApprover?.ProjectApproverName as string} role="Approver" />
                  <div className="text-sm">
                    <span className="font-medium text-gray-500 dark:text-gray-400">{t('Approver')}: </span>
                    <span className="text-gray-900 dark:text-white">{localProjectDetails.ProjectApprover?.ProjectApproverName}</span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <PiUsersLight className="text-gray-500" size={18} />
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('Members')}</span>
                  </div>
                  <div className="flex flex-wrap gap-2 mt-1">
                    {localProjectDetails.ProjectMembers.filter((m) => m.Role === 'Member').map((member) => (
                      <Tooltip key={member.MemberID} title={`${member.MemberName} (${t(member.Role)})`}>
                        <motion.div
                          whileHover={{ scale: 1.05 }}
                          className="flex items-center gap-2 bg-gray-100 dark:bg-gray-700 p-1.5 rounded"
                        >
                          <CustomAvatar name={member.MemberName} role={t(member.Role)} />
                          <span className="text-xs text-gray-900 dark:text-white">{member.MemberName}</span>
                        </motion.div>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <h3 className="text-base font-semibold text-gray-700 dark:text-gray-300">{t('Services')}</h3>
              {localProjectDetails.AllocatedServices.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  {localProjectDetails.AllocatedServices.map((service, index) => (
                    <div key={index} className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                      <div className="flex items-center gap-2">
                        <PiBriefcaseLight className="text-blue-500" size={18} />
                        <span className="text-sm font-medium text-gray-900 dark:text-white">{service.ServiceName}</span>
                      </div>
                      <div className="mt-2">
                        <LinearProgress
                          value={(service.RemainingQueries / service.AllocatedQueries) * 100}
                          variant="determinate"
                          sx={{ height: 6, borderRadius: 2, bgcolor: 'gray', '& .MuiLinearProgress-bar': { bgcolor: '#00ccff' } }}
                        />
                        <p className="text-sm mt-1 text-gray-500 dark:text-gray-400">
                          {service.RemainingQueries}/{service.AllocatedQueries} {t('queries')}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-sm text-gray-500 dark:text-gray-400">{t('No services allocated')}</p>
              )}
            </div>

            {canApprove && (
              <div className="flex justify-end mt-4">
                <Button
                  variant="primary"
                  size="small"
                  isLoading={isLoading}
                  onClick={handleApproveProject}
                  loaderColor="border-white"
                >
                  <PiCheckCircleLight size={16} className="mr-2" />
                  {t('Approve')}
                </Button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ProjectView;