import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SuccessPage from './SuccessPage';
import CancelPage from './CancelPage';
import FailedPage from './FailedPage';
import { useAppDispatch } from '../../../store';
import { getAiModels } from '../../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';

const PaymentResults: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const {t} = useTranslation()
    const success = query.get('success');
    const canceled = query.get('canceled');
    const failed = query.get('failed');
    const dispatch = useAppDispatch()

    useEffect(() => {
        const handleRedirect = async () => {
            setTimeout(() => {
                dispatch(getAiModels())
                history.push('/subscriptions')
            }, 10000); // ✅ Redirect after 10s
        };

        handleRedirect();
    }, [dispatch,history]);

    if (success) return <SuccessPage />;
    if (canceled) return <CancelPage />;
    if (failed) return <FailedPage />;

    return <h1 className="text-center text-gray-700 dark:text-gray-300">{t("Unknown Payment State")}</h1>;
};

export default PaymentResults;
