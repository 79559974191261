import React, { useEffect, useRef, useState, useContext } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { addSignature, getSignature } from '../../../store/actions/DocumentsAction';
import { useAppDispatch } from '../../../store';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/TailwindComponents/Button';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { getUserID } from '../../../store/actions/ChatAction';

interface SignatureModalProps {
  /** Called with the raw SVG markup whenever user clicks "Save" */
  onSave: (signature: string) => void;
  /** Called with a File object (SVG) whenever user clicks "Save" */
  onConfirm: (signatureFile: File) => void;
}

/**
 * A digital signature component:
 * - Displays existing signature if found (view mode).
 * - Allows user to switch to edit mode, sign on a canvas, clear, and save.
 * - Optional skeleton displayed while loading.
 */
const SignatureModal: React.FC<SignatureModalProps> = ({
  onSave,
  onConfirm,
}) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [signatureSVGContent, setSignatureSVGContent] = useState<string | null>(null);
  const userId = getUserID();

  // Loading states
  const [isLoadingSignature, setIsLoadingSignature] = useState<boolean>(true);
  const [errorLoading, setErrorLoading] = useState(false);

  // Dark/Light mode
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  // ─────────────────────────────────────────────────────────────────────────────
  //  Load existing signature (if any)
  // ─────────────────────────────────────────────────────────────────────────────
  const loadImage = async () => {
    try {
      setIsLoadingSignature(true);
      const sign_url = await dispatch(getSignature());
      if (!sign_url) {
        // No signature URL => user must create new
        setIsEditing(true);
        setSignatureSVGContent(null);
      } else {
        const response = await fetch(sign_url, {
          headers: {
            Accept: 'image/svg+xml',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const svgText = await response.text();
        if (svgText) {
          setSignatureSVGContent(svgText);
          setIsEditing(false);
        } else {
          setSignatureSVGContent(null);
          setIsEditing(true);
        }
      }
    } catch (error) {
      console.error('Error loading signature:', error);
      setErrorLoading(true);
      setSignatureSVGContent(null);
      setIsEditing(true);
    } finally {
      setIsLoadingSignature(false);
    }
  };

  useEffect(() => {
    loadImage();
  }, []);

  // ─────────────────────────────────────────────────────────────────────────────
  //  Signature actions
  // ─────────────────────────────────────────────────────────────────────────────
  const handleClear = () => {
    signatureCanvasRef.current?.clear();
  };

  const handleSave = async () => {
    if (!signatureCanvasRef.current) {
      alert('Signature pad not initialized.');
      return;
    }
    if (signatureCanvasRef.current.isEmpty()) {
      alert(t('Please provide a signature first.'));
      return;
    }

    const signaturePad = signatureCanvasRef.current.getSignaturePad();
    const signatureData = signaturePad.toData();
    const canvas = signatureCanvasRef.current.getCanvas();

    // Convert signature paths => SVG data
    const signatureSVG = signatureDataToSVG(signatureData, {
      width: canvas.width,
      height: canvas.height,
      penColor: signaturePad.penColor || (isDarkMode ? 'white' : 'black'),
    });

    // Convert string => File
    const blob = new Blob([signatureSVG], { type: 'image/svg+xml' });
    const signatureFile = new File([blob], `${userId}_signature.svg`, {
      type: 'image/svg+xml',
    });

    // Fire callbacks
    onSave(signatureSVG);
    onConfirm(signatureFile);
    localStorage.setItem('userSignature', signatureSVG);

    // Upload to backend
    try {
      await dispatch(addSignature(signatureFile));
      setIsEditing(false);
      loadImage(); // re-fetch to confirm
    } catch (error) {
      console.error('Dispatch Error:', error);
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  //  UI Rendering
  // ─────────────────────────────────────────────────────────────────────────────
  return (
    <div className="flex flex-col h-full justify-start text-left rounded-lg space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-md font-semibold">{t('Digital Signature')}</h2>
        {/** If not editing, show "Edit Signature" button */}
        {!isLoadingSignature && signatureSVGContent && !isEditing && (
          <Button variant="primary" size="small" onClick={() => setIsEditing(true)}>
            {t('Edit Signature')}
          </Button>
        )}
      </div>

      <span className="text-xs text-gray-600 dark:text-gray-300">
        {t('Set your digital signature for e-signing documents and forms.')}
      </span>

      {/* Main content area (signature preview or signature pad) */}
      <div className="w-[500px] h-[200px] relative">
        {/* 1) Skeleton while loading */}
        {isLoadingSignature && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full h-full bg-gray-200 dark:bg-gray-700 animate-pulseFade rounded-md" />
          </div>
        )}

        {/* 2) Error or no existing signature => user must draw new */}
        {!isLoadingSignature && errorLoading && (
          <div className="flex items-center justify-center w-full h-full bg-red-100 text-red-600 p-2 rounded-md">
            {t('Failed to load signature. Please create a new one.')}
          </div>
        )}

        {/* 3) If loaded and not editing, show the existing signature */}
        {!isLoadingSignature && signatureSVGContent && !isEditing && (
          <div
            className="rounded-lg bg-white dark:bg-gray-800 border-1 border-gray-200 dark:border-gray-600 h-[200px] w-[500px] transition-all duration-200 flex items-center justify-center border border-gray-100 dark:border-gray-700 overflow-hidden"
            dangerouslySetInnerHTML={{ __html: signatureSVGContent }}
          />
        )}

        {/* 4) If editing => show signature pad */}
        {!isLoadingSignature && (isEditing || !signatureSVGContent) && (
          <SignatureCanvas
            penColor={'black'}
            ref={signatureCanvasRef}
            canvasProps={{
              width: 500,
              height: 200,
              className:
                'rounded-lg bg-white dark:bg-gray-700 border-1 border-gray-200 dark:border-gray-600 border border-gray-200 dark:border-gray-600 transition-all duration-200',
            }}
            minWidth={1}
            maxWidth={3}
            throttle={16}
          />
        )}
      </div>

      {/* Control Buttons */}
      {!isLoadingSignature && (isEditing || !signatureSVGContent) && (
        <div className="flex space-x-2">
          <Button variant="neutral" size="small" onClick={handleClear}>
            {t('Clear')}
          </Button>
          <Button variant="primary" size="small" onClick={handleSave}>
            {t('Save')}
          </Button>
        </div>
      )}
    </div>
  );
};

/** 
 * Convert signaturePad's internal data to an SVG path. 
 * signatureData is an array of arrays of points
 * e.g. [[{x:..., y:...},{x:..., y:...}], [...], ...]
 */
function signatureDataToSVG(
  signatureData: Array<Array<{ x: number; y: number }>>,
  options: { width: number; height: number; penColor: string }
): string {
  const { width, height, penColor } = options;
  let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">`;
  svgContent += `<g fill="none" stroke="${penColor}" stroke-width="2">`;

  signatureData.forEach((stroke) => {
    if (stroke.length > 0) {
      let pathData = `M ${stroke[0].x.toFixed(3)} ${stroke[0].y.toFixed(3)}`;
      for (let i = 1; i < stroke.length; i++) {
        pathData += ` L ${stroke[i].x.toFixed(3)} ${stroke[i].y.toFixed(3)}`;
      }
      svgContent += `<path d="${pathData}" />`;
    }
  });

  svgContent += '</g></svg>';
  return svgContent;
}

export default SignatureModal;
