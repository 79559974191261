import React from 'react';

interface ToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  description?: string;
}

const Toggle: React.FC<ToggleProps> = ({ checked, onChange, label, description }) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex items-center gap-2">
          {label}
        </label>
      )}
      {description && <p className="text-sm text-gray-500 dark:text-gray-300 mb-2">{description}</p>}
      <div className="flex items-center">
        <button
          type="button"
          onClick={() => onChange(!checked)}
          className={`relative inline-flex h-6 w-12 items-center rounded-full transition-all duration-300 ease-in-out shadow-inner ${
            checked ? 'bg-primary-600' : 'bg-gray-300 dark:bg-gray-600'
          }`}
        >
          <span
            className={`absolute h-7 w-7 rounded-full bg-white shadow-md border border-1 border-gray-200  transform transition-transform duration-300 ease-in-out ${
              checked ? 'translate-x-6' : 'translate-x-0'
            }`}
          />
        </button>
        {/* <span className="ml-3 text-sm text-gray-700 dark:text-gray-200">{checked ? 'On' : 'Off'}</span> */}
      </div>
    </div>
  );
};

export default Toggle;