import React, { useState, useEffect, useRef } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiOutlineCopy,
  AiFillLike,
  AiFillDislike,
  AiOutlineCheck,
  AiOutlineSound,
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineReload,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';

import { Message } from './types';
import { Citation } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import MiniLoadingToCheckmark from '../shared/animations/MiniLoadingToCheckmark';
import ShortLinkWithTooltip from '../shared/ShortLinkWithTooltip';
import { PiThumbsUpLight } from 'react-icons/pi';

/* 
   =====================
   CUSTOM FADE-IN-DOWN ANIMATION
   (Move to your global CSS / tailwind config as appropriate)
   =====================
*/
const FadeInDownStyles = `
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.fade-in {
  animation: fadeIn 0.5s ease forwards;
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.98);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
.slide-in {
  animation: slideIn 0.5s ease-out forwards;
}
.progress-container {
  width: 100%;
  max-height: 200px;
  overflow-y: hidden;
  position: relative;
  transition: max-height 0.5s ease-in-out; /* Smoother collapse */
  background: rgba(249, 250, 251, 0.8);
  border-radius: 8px;
  padding: 8px;
}
.progress-container.collapsed {
  max-height: 45px;
}
.progress-container .sticky {
  background: inherit;
}
.dark .progress-container {
  background: rgba(31, 41, 55, 0.8);
}
.progress-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to bottom, rgba(249, 250, 251, 1), rgba(249, 250, 251, 0));
  pointer-events: none;
  z-index: 1;
}
.progress-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to top, rgba(249, 250, 251, 1), rgba(249, 250, 251, 0));
  pointer-events: none;
  z-index: 1;
}
.dark .progress-container {
  background: rgba(31, 41, 55, 0.8);
}
.dark .progress-container::before {
  background: linear-gradient(to bottom, rgba(31, 41, 55, 1), rgba(31, 41, 55, 0));
}
.dark .progress-container::after {
  background: linear-gradient(to top, rgba(31, 41, 55, 1), rgba(31, 41, 55, 0));
}
`;

const isDocumentUrl = (url: string): boolean => {
  const documentExtensions = ['.pdf', '.docx', '.doc', '.txt'];
  return documentExtensions.some((ext) => url.toLowerCase().endsWith(ext));
};

function normalizeDocReferences(text: string, citations: Citation[]): string {
  const docRegex = /\[(\d+)\]/gi;
  return text.replace(docRegex, (match, docNumberStr) => {
    const docNumber = parseInt(docNumberStr, 10);
    const citation = citations.find((c) => c.number === docNumber);
    if (citation) {
      return `<sup><a href="#citation-${docNumber}" class="text-blue-600 hover:underline">[${docNumber}]</a></sup>`;
    }
    return match;
  });
}

/**
 * Converts [doc 1] references into markdown links using the citation array.
 */
function linkifyDocReferences(text: string, citations: Citation[]): string {
  const docRegex = /\[(\d+)\]/gi;  // Match [1], [2], etc.
  return text.replace(docRegex, (match, docNumberStr) => {
    const docNumber = parseInt(docNumberStr, 10);
    const citation = citations.find(c => c.number === docNumber);
    if (citation) {
      return `[${docNumber}](${citation.url || '#'})`;
    }
    return match;
  });
}

interface MessageBubbleProps {
  message: Message;
  onLike: (messageId: string) => void;
  onDislike: (messageId: string) => void;
  onCopy: (messageId: string) => void;
  userName: string;
  isAutoScroll: boolean;
  scrollToBottom: () => void;
  speechToken: string | null;
  serviceRegion: string  | null;
  aiSettings: any;
  onShowCitation?: (citation: Citation) => void;
  onOpenCitation: (url: string) => void;
  onRegenerate?: (messageId: string) => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

/**
 * Renders a single message bubble (user or assistant).
 * 
 * Enhancement Summary:
 * - SSE steps get a "breathing" animation (animate-pulse) and appear one by one.
 * - Steps auto-collapse once the assistant's final text starts showing.
 * - Assistant’s final text fades in line-by-line (instead of char-by-char).
 */
const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  onLike,
  onDislike,
  onCopy,
  userName,
  isAutoScroll,
  scrollToBottom,
  speechToken,
  serviceRegion,
  aiSettings,
  onShowCitation,
  onOpenCitation,
  onRegenerate,
  setMessages
}) => {
  const { t } = useTranslation();

  // Keeps track of auto-scroll preference in a ref
  const autoScrollRef = useRef(isAutoScroll);
  const prevMsgId = useRef<string | undefined>(undefined);
  const [showAllCitations, setShowAllCitations] = useState(false);
  // --- STATE ---
  // Steps to show (SSE steps)
  const [progressSteps, setProgressSteps] = useState<string[]>([]);

  // Show/hide the SSE steps panel
  const [showSteps, setShowSteps] = useState(true);

  // Whether the user has finished reading or if the text is fully revealed
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  // For copying content
  const [copied, setCopied] = useState(false);

  // TTS
  const [isSpeaking, setIsSpeaking] = useState(false);

  // The lines of text that have been revealed so far (assistant only)
  const [revealedLines, setRevealedLines] = useState<string[]>([]);
  const progressRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const animatedParagraphsRef = useRef(new Set<number>());
  const scheduledParagraphsRef = useRef(new Set<number>()); 

  // --- EFFECT: Keep updated isAutoScroll in a ref ---
  useEffect(() => {
    autoScrollRef.current = isAutoScroll;
  }, [isAutoScroll]);

  useEffect(() => {
    if (message.id !== prevMsgId.current) {
      prevMsgId.current = message.id;
      setProgressSteps([]);
      setIsCollapsed(false);
      setRevealedLines([]);
      setIsTypingComplete(false);
      animatedParagraphsRef.current.clear();
      scheduledParagraphsRef.current.clear(); // NEW: Reset scheduled paragraphs
    }
  }, [message.id]);

  useEffect(() => {
    if (!message.progress) return;
    const oldCount = progressSteps.length;
    const incoming = message.progress;
    if (incoming.length > oldCount) {
      const toAdd = incoming.slice(oldCount);
      let i = 0;
      const timer = setInterval(() => {
        setProgressSteps((prev) => [...prev, toAdd[i]]);
        if (progressRef.current) {
          progressRef.current.scrollTop = progressRef.current.scrollHeight;
        }
        i++;
        if (i >= toAdd.length) {
          clearInterval(timer);
        }
      }, 200);
      return () => clearInterval(timer);
    }
  }, [message.progress, progressSteps]);

  useEffect(() => {
    if (message.isFlowDone) {
      const timer = setTimeout(() => {
        setIsCollapsed(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [message.isFlowDone]);

  useEffect(() => {
    if (message.id !== prevMsgId.current) {
      prevMsgId.current = message.id;
      setProgressSteps([]);
      setIsCollapsed(false);
      setRevealedLines([]);
      setIsTypingComplete(false);
      animatedParagraphsRef.current.clear();
      scheduledParagraphsRef.current.clear();
    }
  
    if (message.sender === "user" || message.disableTypingEffect || !message.text) {
      if (message.text) {
        let normalizedText = normalizeDocReferences(message.text, message.citations || [])
          .replace(/\r\n/g, "\n") 
          .replace(/\n{2,}/g, "\n\n") 
          .trim();
  
        normalizedText = normalizedText.replace(/\n?(#+ )/g, "\n\n$1");
  
        const paragraphs = normalizedText.split("\n\n").filter((p) => p.trim().length > 0);
        setRevealedLines(paragraphs);
        paragraphs.forEach((_, idx) => animatedParagraphsRef.current.add(idx));
      }
      setIsTypingComplete(true);
      return;
    }
  
    if (message.sender === "assistant" && message.text) {
      setShowSteps(false);
      let normalizedText = normalizeDocReferences(message.text.trim(), message.citations || [])
        .replace(/\r\n/g, "\n")
        .replace(/\n{2,}/g, "\n\n")
        .replace(/([^\n])\n(?!\n)/g, "$1\n\n") 
        .trim();
  
      normalizedText = normalizedText.replace(/\n?(#+ )/g, "\n\n$1");
  
      const paragraphs = normalizedText.split("\n\n").filter((p) => p.trim().length > 0);
  
      const newParagraphs = paragraphs
        .slice(revealedLines.length)
        .filter((_, idx) => !scheduledParagraphsRef.current.has(revealedLines.length + idx));
  
      if (newParagraphs.length > 0) {
        newParagraphs.forEach((paragraph, idx) => {
          const paragraphIndex = revealedLines.length + idx;
          scheduledParagraphsRef.current.add(paragraphIndex);
          setTimeout(() => {
            setRevealedLines((prev) => {
              if (prev.length > paragraphIndex) return prev;
              const updatedRevealed = [...prev, paragraph];
              animatedParagraphsRef.current.add(paragraphIndex);
              if (autoScrollRef.current) scrollToBottom();
              return updatedRevealed;
            });
            if (paragraphIndex === paragraphs.length - 1) setIsTypingComplete(true);
          }, 150 * (idx + 1));
        });
      } else if (paragraphs.length === revealedLines.length) {
        setIsTypingComplete(true);
      }
    }
  }, [message.text, message.sender, message.disableTypingEffect, message.citations, scrollToBottom]);
  /**
   * SPEECH: text-to-speech for the final text.
   */
  const speakText = (txt: string) => {
    if (!serviceRegion || !speechToken || !txt) return;
    setIsSpeaking(true);

    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(speechToken, serviceRegion);
    speechConfig.speechSynthesisVoiceName = aiSettings?.AIVoiceID || 'en-US-JennyNeural';
    const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

    synthesizer.speakTextAsync(
      txt,
      () => {
        setIsSpeaking(false);
        synthesizer.close();
      },
      (err) => {
        console.error('TTS error:', err);
        setIsSpeaking(false);
        synthesizer.close();
      },
    );
  };

  /**
   * COPY text
   */
  const handleCopyClick = () => {
    if (message.text) {
      navigator.clipboard.writeText(message.text);
      setCopied(true);
      onCopy(message.id!);
      setTimeout(() => setCopied(false), 1500);
    }
  };

  /**
   * Render user avatar from name.
   */
  const getUserInitials = (name: string) => {
    const parts = name.trim().split(' ');
    return parts.map((p) => p[0]).join('').toUpperCase();
  };
  const isUser = message.sender === 'user';
  const fullRevealedText = revealedLines.join('\n');
  const isAssistantLoading = !isUser && revealedLines.length === 0 && !message.disableTypingEffect && !message.isFlowDone;
  const visibleCitations = message.citations ? message.citations.slice(0, 4) : [];
  const hiddenCitations = message.citations ? message.citations.slice(4) : [];
  const getFaviconUrl = (url?: string) => {
    if (!url || url === 'Unknown URL' || !url.startsWith('http')) {
      return 'https://www.google.com/s2/favicons?domain=unknown';
    }
    try {
      return `https://www.google.com/s2/favicons?domain=${new URL(url).hostname}`;
    } catch {
      return 'https://www.google.com/s2/favicons?domain=unknown';
    }
  };

  const isAssistant = message.sender === 'assistant';
  const isBlankResponse = isAssistant && message.text === '';
  const isFallbackResponse = isAssistant && message.text?.includes('could not detect a relevant domain');

  const handlePrevResponse = () => {
    if (message.responses && message.currentResponseIndex !== undefined) {
      const newIndex = (message.currentResponseIndex - 1 + message.responses.length) % message.responses.length;
      const newResponse = message.responses[newIndex];
      setMessages((prev) =>
        prev.map((m) =>
          m.id === message.id
            ? {
                ...m,
                text: newResponse.text,
                citations: newResponse.citations,
                currentResponseIndex: newIndex,
              }
            : m
        )
      );
    }
  };
  
  const handleNextResponse = () => {
    if (message.responses && message.currentResponseIndex !== undefined) {
      const newIndex = (message.currentResponseIndex + 1) % message.responses.length;
      const newResponse = message.responses[newIndex];
      setMessages((prev) =>
        prev.map((m) =>
          m.id === message.id
            ? {
                ...m,
                text: newResponse.text,
                citations: newResponse.citations,
                currentResponseIndex: newIndex,
              }
            : m
        )
      );
    }
  };

  return (
    <>
      <style>{FadeInDownStyles}</style>
      <div className={`flex mb-4 max-w-2/3 w-2/3 self-center ${isUser ? 'justify-end' : 'justify-start'}`}>
        <div
          className={clsx(
            'rounded-lg py-1 px-2',
            isUser
              ? 'max-w-2xl bg-gray-100 dark:bg-gray-800 dark:text-white px-3 text-black rounded-br-none shadow-sm dark:shadow-dark-sm'
              : 'text-gray-900 dark:text-gray-100 rounded-bl-none w-3xl max-w-3xl'
          )}
        >
        {!isUser && message.isTyping && (
          <div className="progress-container mb-3 relative w-full min-w-80">
            <div className="flex items-center mb-2 sticky top-0 bg-white dark:bg-gray-800 z-10 p-2">
              <MiniLoadingToCheckmark isLoading={!message.isFlowDone} />
              <span className="ml-2 text-sm text-gray-600 dark:text-gray-300">
                {message.isFlowDone ? t('Completed') : t('Thinking...')}
              </span>
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="ml-auto flex items-center text-xs text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
              >
                {isCollapsed ? t('Show Steps') : t('Hide Steps')}
                {isCollapsed ? <AiOutlineDown size={12} className="ml-1" /> : <AiOutlineUp size={12} className="ml-1" />}
              </button>
            </div>
            {!isCollapsed && (
              <div className="max-h-32 overflow-y-auto">
                {progressSteps.map((step, idx) => (
                  <ReactMarkdown
                    key={idx}
                    className="text-xs text-gray-600 dark:text-gray-300 mb-1 fade-in"                  >
                    {step}
                  </ReactMarkdown>
                ))}
              </div>
            )}
          </div>
        )}
          {/* {isAssistantLoading ? (
            <MiniLoadingToCheckmark isLoading />
          ) : ( */}
            <>
            {revealedLines.map((paragraph, idx) => (
                <div
                  key={idx}
                  className={clsx(
                    'mb-2',
                    !animatedParagraphsRef.current.has(idx) && 'slide-in'
                  )}
                  style={{ animationDelay: `${idx * 50}ms` }}
                >
                <ReactMarkdown
                  className="markdown-content"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    a({ node, href = '', children, ...props }) {
                      const child = Array.isArray(children) ? children[0] : children;
                      const text = typeof child === 'string' ? child : '';
                      const match = text.match(/\[(\d+)\]/);
                      if (match) {
                        const number = parseInt(match[1], 10);
                        const citation = message.citations?.find((c) => c.number === number);
                        if (citation) {
                          return (
                            <ShortLinkWithTooltip
                              href={citation.url || '#'}
                              onClick={(e) => {
                                if (isDocumentUrl(citation.url)) {
                                  e.preventDefault();
                                  onOpenCitation(citation.url);
                                }
                              }}
                              {...props}
                            >
                              <span className="text-blue-600 dark:text-blue-400 hover:underline">[{number}]</span>
                            </ShortLinkWithTooltip>
                          );
                        }
                      }
                      // Fallback for non-citation links
                      return <ShortLinkWithTooltip href={href} {...props}>{children}</ShortLinkWithTooltip>;
                    },
                  }}
                >
                  {paragraph}
                </ReactMarkdown>
                </div>
              ))}
              {isUser && message.attachedFiles && message.attachedFiles.length > 0 && (
                <div className="mt-3 pt-2 border-t border-gray-200 dark:border-gray-700 text-sm text-gray-600 dark:text-gray-300">
                  <strong className="block mb-2">{t('Attached Files')}</strong>
                  <div className="flex flex-wrap gap-2">
                    {message.attachedFiles.map((file, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-2 px-3 py-1 rounded-full bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800 border border-gray-300 dark:border-gray-600 shadow-sm hover:shadow-md"
                      >
                        <span className="text-gray-800 dark:text-gray-200">
                          {file.filename.length > 20 ? `${file.filename.slice(0, 17)}...` : file.filename}
                        </span>
                        <button
                          onClick={() => onOpenCitation(file.blob_path)}
                          className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 text-sm"
                        >
                          {t('View')}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
                {!isUser && message.citations && message.citations.length > 0 && message.isFlowDone && fullRevealedText && (
                  <div className="mt-3 pt-2 border-t border-gray-200 dark:border-gray-700 text-sm text-gray-600 dark:text-gray-300 relative">
                    <strong className="block mb-2">{t('References')}</strong>
                    <div className="flex flex-wrap gap-2 items-center">
                      {message.citations.map((c, i) => {
                        const truncatedTitle = c.title.length > 30 ? `${c.title.slice(0, 27)}...` : c.title;
                        const faviconUrl = getFaviconUrl(c.url);
                        const isDocument = c.url ? isDocumentUrl(c.url) : false;
                        return (
                          <div key={c.id || i} style={{ animationDelay: `${i * 100}ms` }} className="animate-fade-in">
                            <ShortLinkWithTooltip
                              href={c.url || '#'}
                              onClick={(e) => {
                                if (isDocument && c.url) {
                                  e.preventDefault();
                                  onOpenCitation(c.url);
                                }
                              }}
                              className={clsx(
                                'flex items-center space-x-2 px-3 py-1 rounded-full text-sm font-medium transition-all duration-300',
                                'bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800',
                                'border border-gray-300 dark:border-gray-600 shadow-sm hover:shadow-md',
                                'text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400'
                              )}
                            >
                              <span className="text-blue-600 dark:text-blue-400">[{c.number}]</span>
                              <img src={faviconUrl} alt="favicon" className="w-4 h-4 rounded-sm" />
                              <span>{truncatedTitle || `Citation ${c.number}`}</span>
                            </ShortLinkWithTooltip>
                          </div>
                        );
                      })}
                    {hiddenCitations.length > 0 && (
                      <button
                        onClick={() => setShowAllCitations(!showAllCitations)}
                        className={clsx(
                          'flex items-center space-x-2 px-3 py-1 rounded-full text-sm font-medium transition-all duration-300',
                          'bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800',
                          'border border-gray-300 dark:border-gray-600 shadow-sm hover:shadow-md',
                          'text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300'
                        )}
                      >
                        <span>+{hiddenCitations.length}</span>
                        {hiddenCitations.slice(0, 3).map((c, i) => (
                          <img
                            key={i}
                            src={getFaviconUrl(c.url)}
                            alt="favicon"
                            className="w-4 h-4 rounded-sm"
                          />
                        ))}
                      </button>
                    )}
                  </div>
                  {showAllCitations && hiddenCitations.length > 0 && (
                    <div className="absolute left-0 mt-2 w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg z-10 p-2 transition-all duration-300">
                      {hiddenCitations.map((c, i) => {
                        const truncatedTitle = c.title.length > 30 ? `${c.title.slice(0, 27)}...` : c.title;
                        const faviconUrl = getFaviconUrl(c.url);
                        return (
                          <ShortLinkWithTooltip
                            key={c.id}
                            href={c.url || '#'}
                            className={clsx(
                              'flex items-center space-x-2 px-3 py-1 rounded-full text-sm font-medium transition-all duration-300 mb-1',
                              'bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800',
                              'border border-gray-300 dark:border-gray-600 shadow-sm hover:shadow-md',
                              'text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400'
                            )}
                          >
                            <img src={faviconUrl} alt="favicon" className="w-4 h-4 rounded-sm" />
                            <span>{truncatedTitle || `Citation ${c.number}`}</span>
                          </ShortLinkWithTooltip>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              {!isUser && message.isFlowDone && (fullRevealedText || isBlankResponse) && (
                <div className="flex items-center mt-7 space-x-2">
                  <Tooltip title={t('Upvote') || ''} placement="top">
                    <button
                      className={clsx(
                        'text-gray-500 hover:text-gray-700 dark:hover:text-gray-300',
                        message.liked && 'text-blue-500'
                      )}
                      onClick={() => onLike(message.id!)}
                    >
                      {message.liked ? <PiThumbsUpLight size={16} /> : <AiOutlineLike size={16} />}
                    </button>
                  </Tooltip>
                  <Tooltip title={t('Downvote') || ''} placement="top">
                    <button
                      className={clsx(
                        'text-gray-500 hover:text-gray-700 dark:hover:text-gray-300',
                        message.disliked && 'text-red-500'
                      )}
                      onClick={() => onDislike(message.id!)}
                    >
                      {message.disliked ? <AiFillDislike size={16} /> : <AiOutlineDislike size={16} />}
                    </button>
                  </Tooltip>
                  <Tooltip title={copied ? t('Copied!') : t('Copy to clipboard')} placement="top">
                    <button
                      className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                      onClick={handleCopyClick}
                    >
                      {copied ? <AiOutlineCheck size={16} /> : <AiOutlineCopy size={16} />}
                    </button>
                  </Tooltip>
                  <Tooltip title={t('Listen') || ''} placement="top">
                    <button
                      className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                      onClick={() => speakText(message.text || '')}
                      disabled={!message.text}
                    >
                      <AiOutlineSound size={16} />
                    </button>
                  </Tooltip>
                  {onRegenerate && (
                    <Tooltip title={t('Regenerate response')} placement="top">
                      <button
                        className="group relative p-1 text-gray-500 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-300"
                        onClick={() => onRegenerate(message.id!)}
                      >
                        <AiOutlineReload
                          size={16}
                          className="transform group-hover:rotate-180 transition-transform duration-500"
                        />
                        <span className="absolute -top-1 -right-1 w-2 h-2 bg-blue-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                      </button>
                    </Tooltip>
                  )}
                </div>
              )}
              {!isUser && message.isFlowDone && message.responses && message.responses.length > 1 && (
                <div className="flex items-center mt-2 space-x-2">
                  <button
                    onClick={handlePrevResponse}
                    className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                    disabled={message.currentResponseIndex === 0}
                  >
                    <AiOutlineLeft size={16} />
                  </button>
                  <span className="text-xs text-gray-500">
                    {message.currentResponseIndex! + 1} / {message.responses.length}
                  </span>
                  <button
                    onClick={handleNextResponse}
                    className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                    disabled={message.currentResponseIndex === message.responses.length - 1}
                  >
                    <AiOutlineRight size={16} />
                  </button>
                </div>
              )}
            </>
          {/* )} */}
        </div>
        {isUser && (
          <Tooltip title={userName} placement="top">
            <div className="ml-2 w-8 h-8 bg-gray-100 dark:bg-gray-700 text-blue-500 dark:text-sky-300 rounded-full flex items-center justify-center select-none cursor-default p-5">
              {getUserInitials(userName)}
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default MessageBubble;
