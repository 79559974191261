import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';

import { fetchAllClients, attachClientToCase } from '../../store/actions/ClientAction';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';

import Button from '../shared/TailwindComponents/Button';
import AddClientForm from '../Clients/AddClientForm';

import { ClientData } from '../../store/reducer/ClientReducer';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import { useTranslation } from 'react-i18next';

interface AttachClientToCaseSidePanelProps {
  caseId: string;
  onClose?: () => void;
  onSuccess?: () => void;
}

const AttachClientToCaseSidePanel: React.FC<AttachClientToCaseSidePanelProps> = ({
  caseId,
  onClose,
  onSuccess
}) => {
  const dispatch = useAppDispatch();

  // All clients from the store
  const clients = useSelector((state: RootState) => state.client?.allClients || []);

  const [selectedClientId, setSelectedClientId] = useState<string>('');
  const [showAddClientForm, setShowAddClientForm] = useState<boolean>(false);
  const { t } = useTranslation()

  // On mount, fetch the clients
  useEffect(() => {
    dispatch(fetchAllClients());
  }, [dispatch]);

  // Convert client data => DropdownOption
  const clientOptions: DropdownOption[] = clients.map((c: ClientData) => ({
    value: c.ClientID || '',
    label:
      (c.FirstName || '') + (c.LastName ? ' ' + c.LastName : '') ||
      c.BusinessName ||
      'Unnamed Client',
    active: true,
  }));

  // Handle the user picking a client from the dropdown
  const handleSelectClient = (val: string | string[]) => {
    // Your dropdown default is single-select, so "val" should be a string
    setSelectedClientId(val as string);
  };

  const handleAttachClient = async () => {
    if (!caseId || !selectedClientId) {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Please select a client first'),
          duration: 3000,
          showModal: true,
        })
      );
      return;
    }


    // dispatch the attachClient action
    const success = await dispatch(attachClientToCase(caseId, selectedClientId));
    if (success) {
      if (onSuccess) onSuccess();

      if (onClose) onClose();
    }
  };

  const handleCreatedClient = async (newClientId: string) => {
    // Now we do the attach from inside the side panel
    const success = await dispatch(attachClientToCase(caseId, newClientId));
    if (success) {
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <div className="w-full h-full flex flex-col p-6">
      <h2 className="text-xl font-bold mb-4">{t('Attach Client to Case')}</h2>

      {!showAddClientForm ? (
        <>
          <Dropdown
            label={t("Select Existing Client")}
            placeholder={t("Choose a client")}
            options={clientOptions}
            value={selectedClientId}
            onChange={handleSelectClient}
          />

          <div className="mt-4 flex items-center gap-2">
            <Button variant="primary" onClick={handleAttachClient}>
              {t("Attach to Case")}
            </Button>
            <Button variant="tertiary" onClick={() => setShowAddClientForm(true)}>
              + {t("Add New Client")}
            </Button>
            <Button variant="neutral" onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </div>
        </>
      ) : (
        <>
          <AddClientForm
            onClose={() => setShowAddClientForm(false)}
            onClientCreated={(newClientId: string) => handleCreatedClient(newClientId)}
          />
          <Button variant="neutral" onClick={() => setShowAddClientForm(false)}>
            {t("Back")}
          </Button>
        </>
      )}
    </div>
  );
};

export default AttachClientToCaseSidePanel;
