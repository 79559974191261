import apis from "../../utils/apis";
import { FETCH_USER_SUBSCRIPTION_FAILURE, FETCH_USER_SUBSCRIPTION_REQUEST, FETCH_USER_SUBSCRIPTION_SUCCESS } from "../types";
import { showFeedbackModal } from "./UserFeedbackActions";
import { handleApiError } from "./utils/utils";



export const getActiveSubscription = () => {
    return async (dispatch: any) => {
        try {

            const resp = await apis({
                method: 'GET',
                url: "/subscription/details",
            })

            if (resp && resp.status === 200) {
                // dispatch(showFeedbackModal({
                //     showModal: true,
                //     message: resp.data.message,
                //     modalType: 'success',
                //     duration: 3000
                // }));
                return resp.data.subscriptionDetails

            } else {
                 handleApiError(resp, dispatch)
                 return null
            }
        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}


export const getAllTransactions = () => {
    return async (dispatch: any) => {
        try {

            const resp = await apis({
                method: 'GET',
                url: "/users/transactions",
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: resp.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
                return resp.data.transactions

            } else {
                 handleApiError(resp, dispatch)
                 return null
            }
        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}


export const getTransactionDetails = (transaction_id: string) => {
    return async (dispatch: any) => {
        try {

            const resp = await apis({
                method: 'GET',
                url: `/users/transactions/details/${transaction_id}`,
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: resp.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
                return resp.data.transaction_details

            } else {
                 handleApiError(resp, dispatch)
                 return null
            }
        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}

export const getServiceCosting = () => {
    return async (dispatch: any) => {
        try {

            const resp = await apis({
                method: 'GET',
                url: "/service/costing",
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: resp.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
                return resp.data.services

            } else {
                 handleApiError(resp, dispatch)
                 return null
            }
        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}


export const getUserServiceUsage = () => {
    return async (dispatch: any) => {
        try {

            const resp = await apis({
                method: 'GET',
                url: "user/service/usage",
            })

            if (resp && resp.status === 200) {
                // dispatch(showFeedbackModal({
                //     showModal: true,
                //     message: resp.data.message || "Service Usage fetched successfully.",
                //     modalType: 'success',
                //     duration: 3000
                // }));
                return resp.data.service_usage

            } else {
                 handleApiError(resp, dispatch)
                 return null
            }
        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}


export const fetchUserSubscriptionStatus = () => {
    return async (dispatch: any) => {
      dispatch({ type: FETCH_USER_SUBSCRIPTION_REQUEST });
  
      try {
        const resp = await apis.get('/license/check-subscription');
        if (resp.status === 200) {
          dispatch({
            type: FETCH_USER_SUBSCRIPTION_SUCCESS,
            payload: resp.data.subscription_active
          });
        } else {
          dispatch({ type: FETCH_USER_SUBSCRIPTION_FAILURE });
          handleApiError(resp, dispatch);
        }
      } catch (error) {
        dispatch({ type: FETCH_USER_SUBSCRIPTION_FAILURE });
        handleApiError(error, dispatch);
      }
    };
  };