import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { PiHourglassLight, PiGearLight, PiCheckCircleLight, PiXCircleLight, PiDownloadLight, PiXLight, PiTagSimpleLight } from 'react-icons/pi';
import { TicketItem } from '../Feedback/SupportTicketSystem';
import { Tooltip } from '@mui/material';

interface TicketDetailsContentProps {
  ticket: TicketItem;
}

const TicketDetailsContent: React.FC<TicketDetailsContentProps> = ({ ticket }) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const formattedSupportId = String(ticket.SupportID).padStart(6, '0');

  // Status icon based on ticket status
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'Open':
        return <PiHourglassLight className="text-yellow-500" size={20} />;
      case 'In Progress':
        return <PiGearLight className="text-blue-500" size={20} />;
      case 'Closed':
        return <PiCheckCircleLight className="text-green-500" size={20} />;
      default:
        return <PiHourglassLight className="text-gray-500" size={20} />;
    }
  };

  // Resolved icon based on resolved status
  const getResolvedIcon = (resolved: boolean) => {
    return resolved ? (
      <PiCheckCircleLight className="text-green-500" size={20} />
    ) : (
      <PiXCircleLight className="text-red-500" size={20} />
    );
  };

  // Handle image click for full-screen view
  const handleImageClick = (url: string) => {
    setSelectedImage(url);
  };

  // Close full-screen modal
  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  // Handle image download
  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop() || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="relative p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg transition-all duration-300">
      {/* Top Bar */}
      <div className="flex justify-between items-center mb-6 border-b border-gray-200 dark:border-gray-700 pb-2">
        <div className="flex items-center space-x-2">
          <PiTagSimpleLight className="text-primary-500" size={24} />
          <span className="text-2xl font-medium text-gray-800 dark:text-white">#{formattedSupportId}</span>
        </div>
        <div className="text-sm text-gray-500 dark:text-gray-400 space-y-1 text-right">
            <Tooltip title={t('Created At')}>
          <p>{format(new Date(ticket.CreatedAt), 'PPpp')}</p>
          </Tooltip>
          <Tooltip title={t('Updated At')}>
          <p>{format(new Date(ticket.UpdatedAt), 'PPpp')}</p>
          </Tooltip>
        </div>
      </div>

      {/* Main Content: Two Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Section: Ticket Information */}
        <div className='border-r border-1 border-gray-300 dark:border-gray-600'>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">{t('Ticket Information')}</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">{t('Category')}</label>
              <p className="text-gray-900 dark:text-gray-100">{ticket.Category}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">{t('Description')}</label>
              <p className="text-gray-700 dark:text-gray-300 leading-relaxed">{ticket.Description}</p>
            </div>
            {ticket.Files && ticket.Files.length > 0 && (
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">{t('Attachments')}</label>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-2">
                  {ticket.Files.map((fileUrl: string, index: number) => (
                    <div
                      key={index}
                      className="relative group cursor-pointer"
                      onClick={() => handleImageClick(fileUrl)}
                    >
                      <img
                        src={fileUrl}
                        alt={`Attachment ${index + 1}`}
                        className="w-full h-48 object-cover rounded-md shadow-md group-hover:shadow-xl transition-all duration-300"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all duration-300 rounded-md" />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Right Section: Status & Feedback */}
        <div>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">{t('Status & Feedback')}</h2>
          <div className="space-y-4">
            <div className='flex gap-2'>
            <Tooltip title={t('Status')}>
            <div className="flex items-center space-x-2">
              {getStatusIcon(ticket.Status)}
              <span className="text-gray-900 dark:text-gray-100">{ticket.Status}</span>
            </div>
            </Tooltip>
            <Tooltip title={t('Resolved?')}>
            <div className="flex items-center space-x-2">
              {getResolvedIcon(ticket.Resolved)}
              <span className="text-gray-900 dark:text-gray-100">{ticket.Resolved ? t('Yes') : t('No')}</span>
            </div>
            </Tooltip>
            </div>
            <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-md">
              <p className="text-sm text-gray-500 dark:text-gray-400">{t('Feedback from support will appear here.')}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Full-screen Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 animate-fade-in">
          <div className="relative max-w-4xl max-h-full p-4 bg-white dark:bg-gray-800 rounded-lg shadow-xl">
            <img
              src={selectedImage}
              alt="Full size"
              className="max-w-full max-h-[80vh] object-contain rounded-md"
            />
            <button
              onClick={handleCloseModal}
              className="absolute top-6 left-6 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all duration-200"
            >
              <PiXLight size={24} />
            </button>
            <button
              onClick={() => handleDownload(selectedImage)}
              className="absolute top-6 right-6 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all duration-200"
            >
              <PiDownloadLight size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketDetailsContent;