import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler,
    RadialLinearScale,

} from "chart.js";
import { Bar, Line, Pie, Doughnut, Radar } from "react-chartjs-2";
import ApexCharts from 'react-apexcharts';
// @ts-ignore
import GaugeChart from 'react-gauge-chart';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler,
    RadialLinearScale
);

export interface ChartDataInterface {
    chart_type: "bar" | "line" | "pie" | "doughnut" | "gauge" | "radar";
    datalabel_color?: string;
    chart_options?: {
        legend_status?: boolean;
        tooltip_text_color?: string;
        legend_position?: "top" | "left" | "bottom" | "right";
        chart_grid_lines?: boolean;
        tooltip_bg_color?: string;
    };
    chart_title?: string;
    x_values: string[];
    y_values?: (number | string)[];
    color_codes?: string[];
    datasets?: {
        label: string;
        data: number[];
        borderColor: string;
        fill: boolean;
        tension?: number;
    }[];
    // Only for gauge
    apex_options?: ApexCharts.ApexOptions;
    apex_series?: number[];
}

const buildChartData = (item: ChartDataInterface) => {
    const isMultiDataset = item.chart_type === "line" && item.datasets;
    const isLineChart = item.chart_type === "line";

    if (isMultiDataset) {
        return {
            labels: item.x_values,
            datasets: item.datasets!,
        };
    } else if (isLineChart) {
        return {
            labels: item.x_values,
            datasets: [
                {
                    label: item.chart_title ?? "Dataset",
                    data: item.y_values!,
                    borderColor: item.color_codes ? item.color_codes[0] : "#007BFF",
                    pointRadius: 0.5,
                    tension: 0.4,
                    fill: true,
                    backgroundColor:
                        item.color_codes && item.color_codes[0]
                            ? "rgba(0, 123, 255, 0.2)"
                            : "rgba(0, 123, 255, 0.2)",
                },
            ],
        };
    } else {
        return {
            labels: item.x_values,
            datasets: [
                {
                    label: item.chart_title ?? "Dataset",
                    data: item.y_values!,
                    backgroundColor: item.color_codes!,
                },
            ],
        };
    }
};

const buildChartOptions = (item: ChartDataInterface) => {
    const {
        legend_status = false,
        legend_position = "top",
        tooltip_bg_color = "#000000",
        tooltip_text_color = "#ffffff",
        chart_grid_lines = true,
    } = item.chart_options || {};

    return {
        responsive: true,
        maintainAspectRatio: item.chart_type === "pie" || item.chart_type === "doughnut" ? false : true,
        plugins: {
            legend: {
                display: legend_status,
                position: legend_position,
                labels: {
                    boxWidth: 12,
                    padding: 10,
                    font: { size: 12 },
                },
            },
            tooltip: {
                backgroundColor: tooltip_bg_color,
                titleColor: tooltip_text_color,
                bodyColor: tooltip_text_color,
            },
        },
        scales: item.chart_type === "pie" || item.chart_type === "doughnut"
            ? {}
            : {
                x: {
                    grid: {
                        display: chart_grid_lines,
                        color: "rgba(200, 200, 200, 0.1)",
                        drawBorder: false,
                    },
                    ticks: { color: "#333" },
                    border: { color: "rgba(200, 200, 200, 1)" },
                },
                y: {
                    grid: {
                        display: chart_grid_lines,
                        color: "rgba(200, 200, 200, 0.1)",
                        drawBorder: false,
                    },
                    ticks: { color: "#333" },
                    border: { color: "rgba(200, 200, 200, 1)" },
                },
            },
    };
};

const renderChart = (chartItem: ChartDataInterface, windowWidth: any) => {
    const chartData = buildChartData(chartItem);
    const chartOptions = buildChartOptions(chartItem);

    switch (chartItem.chart_type) {
        case "bar":
            return <Bar data={chartData} options={chartOptions} />;
        case "line":
            return <Line data={chartData} options={chartOptions} />;
        case "pie":
            return <Pie data={chartData} options={chartOptions} />;
        case "doughnut":
            return <Doughnut data={chartData} options={chartOptions} />;
        case "radar":
            return <Radar data={chartData} options={chartOptions} />;
        case "gauge":
            return (
                <div className="w-full max-w-md mx-auto flex justify-center items-center">
                    <div className="flex flex-col items-center text-center w-full h-full">
                        <GaugeChart
                            id="employee-gauge"
                            nrOfLevels={20}
                            percent={(chartItem.apex_series?.[0] ?? 0) / 100} // safely handle undefined
                            textColor="#ffffff"
                            formatTextValue={() => `${chartItem.apex_series?.[0] ?? 0}`}
                            arcsLength={[1]}
                            colors={["#36A2EB"]}
                            needleColor="#444"
                            needleBaseColor="#999"
                            animate={true}
                            arcPadding={0.02}
                            style={{ width: "100%", maxWidth: "300px", height: "auto" }}
                        />
                    </div>
                </div>
            );
        default:
            return <Bar data={chartData} options={chartOptions} />;
    }
};

interface DynamicAnalyticsChartsProps {
    chartsData: ChartDataInterface[];
}

const DynamicAnalyticsCharts: React.FC<DynamicAnalyticsChartsProps> = ({ chartsData }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 m-1">
            {chartsData.map((chartItem, index) => {
                const isPieOrDoughnut =
                    chartItem.chart_type === "pie" || chartItem.chart_type === "doughnut";

                return (
                    <div
                        key={index}
                        className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 shadow-sm bg-white dark:bg-gray-900"
                    >
                        <div className="text-lg font-semibold mb-3 text-center">
                            {chartItem.chart_title}
                        </div>
                        <div
                            className="mx-auto"
                            style={{
                                maxWidth: isPieOrDoughnut ? "350px" : windowWidth,
                                height: "auto",
                                position: "relative",
                            }}
                        >
                            {renderChart(chartItem, windowWidth)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default DynamicAnalyticsCharts;
