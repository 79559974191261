import React, { useContext } from 'react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { PiSunLight, PiMoonLight } from 'react-icons/pi';
import clsx from 'clsx';

interface ThemeToggleProps {
  onToggle?: () => void;
}

const ThemeToggle: React.FC<ThemeToggleProps> = ({ onToggle }) => {
  const { theme, isThemeLoading, toggleTheme } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  const handleToggle = async () => {
    await toggleTheme();
    if (onToggle) onToggle();
  };

  return (
    <div
      className={clsx(
        'relative flex items-center bg-gray-200 dark:bg-gray-700 rounded-full p-1 w-16 h-8 transition-colors duration-300',
        isThemeLoading && 'opacity-50 cursor-not-allowed'
      )}
    >
      <button
        onClick={handleToggle}
        disabled={isThemeLoading}
        className="flex items-center justify-between w-full h-full focus:outline-none"
        aria-label="Toggle theme"
      >
        {/* Light Mode Icon */}
        <div
          className={clsx(
            'flex items-center justify-center w-6 h-6 rounded-full transition-all duration-300',
            !isDark ? 'bg-white text-yellow-500 shadow-md' : 'text-gray-400 dark:text-gray-500'
          )}
        >
          <PiSunLight size={18} />
        </div>

        {/* Dark Mode Icon */}
        <div
          className={clsx(
            'flex items-center justify-center w-6 h-6 rounded-full transition-all duration-300',
            isDark ? 'bg-gray-800 text-gray-200 shadow-md' : 'text-gray-400 dark:text-gray-500'
          )}
        >
          <PiMoonLight size={18} />
        </div>
      </button>
    </div>
  );
};

export default ThemeToggle;