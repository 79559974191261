import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, RootState } from '../../store';
import { useSelector } from 'react-redux';
import {
  clearAnalysisData,
  fetchAnalysisProgress,
  runCaseAnalysis,
  runCaseAnalysisSSE,
  fetchCaseAnalysisData,
} from '../../store/actions/marketplace2/caseAnalysisActions';
import {
  deleteCase,
  editCase,
  fetchCaseSummary,
  fetchCaseCycle,
  generateDocument,
  getCaseFinancialData,
} from '../../store/actions/marketplace2/caseActions';
import { fetchIndexes } from '../../store/actions/AIStudioAction';
import { showModal, hideModal } from '../../store/actions/modalActions';
import {
  showAttachClientToCaseSidePanel,
  showExtractedContentSidePanel,
  showPublishCaseSidePanel,
} from '../../store/actions/sidePanelActions';
import { ChronologyData, getChronology } from '../../store/actions/LegalServices/chronologyActions';
import { getCaseMemo } from '../../store/actions/LegalServices/memoActions';
import { getCaseLegalOutline } from '../../store/actions/LegalOutlineActions';
import { fetchCaseWorkflows } from '../../store/actions/LegalServices/workflowActions';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { usePermission } from '../../hooks/userPermissions';
import { getUserType, isBetaEnv } from '../../utils/authUtils';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import ApiLoader from '../ApiLoader';
import TabStatusNav from '../shared/TailwindComponents/TabStatusNav';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import {
  PiBriefcaseLight,
  PiCalendarLight,
  PiFileTextLight,
  PiPencilSimpleLineLight,
  PiTrashLight,
  PiTreeStructureLight,
  PiUserCirclePlusLight,
  PiUserLight,
} from 'react-icons/pi';
import Chronology from './LegalServices/Chronology/Chronology';
import Memo from './LegalServices/Memo/Memo';
import LegalOutline from './LegalServices/LegalOutline/LegalOutline';
import FinancialDataSection from './FinancialDataSection';
import CaseCycle from './Cycle/CaseCycle';
import { LoadingToCheckmark } from '../shared/animations/LoadingToCheckmark';
import CaseInformation from './CaseInformation';
import CaseAnalysis from './CaseAnalysis';
import { getAiModels } from '../../store/actions/ChatAction';
import { convertLongDateWithTranslation } from '../shared/DateConverter';
import WorkflowModal from './LegalServices/Workflows/WorkflowModal';
import { Tooltip } from '@mui/material';

interface RouteParams {
  caseId: string;
}

const SkeletonLoader: React.FC = () => (
  <div className="animate-fadePulse">
    <div className="p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="h-6 w-6 bg-gray-300 dark:bg-gray-600 rounded-full mr-4"></div>
          <div>
            <div className="h-6 w-40 bg-gray-300 dark:bg-gray-600 rounded mb-2"></div>
            <div className="h-4 w-20 bg-gray-300 dark:bg-gray-600 rounded"></div>
          </div>
        </div>
        <div className="h-6 w-6 bg-gray-300 dark:bg-gray-600 rounded-full"></div>
      </div>
    </div>
    <div className="px-4 mt-4">
      <div className="h-12 bg-gray-300 dark:bg-gray-600 rounded"></div>
    </div>
    <div className="px-4 mt-4">
      <div className="h-64 bg-gray-300 dark:bg-gray-600 rounded"></div>
    </div>
  </div>
);

const CaseDetails: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { caseId } = useParams<RouteParams>();
  const { setBreadcrumbs } = useBreadcrumbs();
  const userType = getUserType();

  // Data from the store
  const analysisState = useSelector((state: RootState) => state.caseAnalysis);
  const indexes = useSelector((state: RootState) => state.aistudio.indexes);

  // Local states
  const [summary, setSummary] = useState<any | null>(null);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const [loadingInitialData, setLoadingInitialData] = useState<boolean>(true);
  const [chronology, setChronology] = useState('');
  const [loadingChronology, setLoadingChronology] = useState<boolean>(false);
  const [memoContent, setMemoContent] = useState<string>('');
  const [loadingMemo, setLoadingMemo] = useState<boolean>(false);
  const [legalOutlineData, setLegalOutlineData] = useState<any>(null);
  const [loadingOutline, setLoadingOutline] = useState<boolean>(false);
  const [financialData, setFinancialData] = useState<any>({});
  const [loadingFinancial, setLoadingFinancial] = useState<boolean>(false);
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);

  // Editing states for "Case Information"
  const [isEditingCaseInfo, setIsEditingCaseInfo] = useState(false);
  const [editedCaseFields, setEditedCaseFields] = useState<any>({});

  // Polling reference for clients’ analysis progress
  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  // SSE / Analysis finalizing flow
  const { analysisCompletedBannerVisible, analysisFinalizing } = useSelector(
    (state: RootState) => state.caseAnalysis
  );
  const [finalizingAnalysis, setFinalizingAnalysis] = useState(false);
  const [showFinalAnalysis, setShowFinalAnalysis] = useState(false);
  const [generatingMemo, setGeneratingMemo] = useState(false);

  // Tab selection from query param
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'Case Information';
  const [activeTab, setActiveTab] = useState<string>(initialTab);

  // Cycle data (only relevant for client userType)
  const [cycle, setCycle] = useState<any>({});

  // For indexing selection (Law users)
  const [selectedApplicableLawsIndex, setSelectedApplicableLawsIndex] = useState<string>('');
  const [selectedRelevantCasesIndex, setSelectedRelevantCasesIndex] = useState<string>('');

  const indexOptions = indexes.map((idx: any) => ({
    value: idx.NormalizedIndexName,
    label: idx.IndexName,
  }));
  const indexOptionsWithNone = [
    { value: 'Default PONS Agent', label: t('Default PONS Agent') },
    ...indexOptions,
  ];

  // Helpers derived from summary
  const caseName = summary?.CaseName || '';
  const isPublished = summary?.IsPublishedToMarketplace;
  const isCaseAnalysisCompleted = summary?.clientAnalysisComplete ?? false;

  // ### Optimized Data Loading Logic
  const getWorkflowStatus = (workflowName: string, summary: any): string => {
    if (!summary) return 'Not Started';
    switch (workflowName) {
      case 'Case Analysis':
        return summary.analysisCompleted ? 'Completed' : 'Not Started';
      case 'Chronology':
        return summary.chronologyGenerated ? 'Completed' : 'Not Started';
      case 'Memo':
        return summary.memoGenerated ? 'Completed' : 'Not Started';
      case 'Legal Outline':
        return summary.legalOutlineGenerated ? 'Completed' : 'Not Started';
      default:
        return 'Not Started';
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setLoadingInitialData(true);
      try {
        const summaryResult: any = await dispatch(fetchCaseSummary(caseId));
        if (summaryResult && summaryResult.summary) {
          setSummary(summaryResult.summary);
          setEditedCaseFields(summaryResult.fields || {});
          const workflowData = await dispatch(fetchCaseWorkflows(caseId));
          const activeWorkflows = workflowData.filter((w: any) => w.IsActive);
          const summaryWorkflows = [
            { Name: 'Case Analysis', status: getWorkflowStatus('Case Analysis', summaryResult.summary) },
            { Name: 'Chronology', status: getWorkflowStatus('Chronology', summaryResult.summary) },
            { Name: 'Memo', status: getWorkflowStatus('Memo', summaryResult.summary) },
            { Name: 'Legal Outline', status: getWorkflowStatus('Legal Outline', summaryResult.summary) },
          ];
          const updatedWorkflows = [
            ...activeWorkflows.map((w: any) => ({
              ...w,
              status: getWorkflowStatus(w.Name, summaryResult.summary),
            })),
            ...summaryWorkflows.filter(
              (sw) =>
                !activeWorkflows.some((aw: any) => aw.Name === sw.Name) && sw.status === 'Completed'
            ),
          ];
          setWorkflows(updatedWorkflows);
        }
      } catch (err) {
        console.error('Failed to load initial data:', err);
      } finally {
        setLoadingInitialData(false);
      }
    };
    loadInitialData();
    dispatch(fetchIndexes());

    return () => {
      setBreadcrumbs([]);
      dispatch(clearAnalysisData());
      if (pollingRef.current) clearInterval(pollingRef.current);
    };
  }, [caseId, dispatch, setBreadcrumbs]);

  // Update activeTab based on query params
  useEffect(() => {
    const currentTab = queryParams.get('tab') || 'Case Information';
    setActiveTab(currentTab);
  }, [location.search]);

  // Polling for client analysis progress
  useEffect(() => {
    if (!userType.includes('Law') && analysisState.isRunning && !pollingRef.current) {
      pollingRef.current = setInterval(() => {
        dispatch(fetchAnalysisProgress(caseId));
      }, 800);
    } else if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
  }, [analysisState.isRunning, userType, caseId, dispatch]);

  // Finalizing animation for analysis
  useEffect(() => {
    if (analysisCompletedBannerVisible) {
      setFinalizingAnalysis(true);
      const timer = setTimeout(() => setShowFinalAnalysis(true), 1500);
      return () => clearTimeout(timer);
    } else {
      setFinalizingAnalysis(false);
      setShowFinalAnalysis(false);
    }
  }, [analysisCompletedBannerVisible]);

  // Load workflow-specific data on demand
  useEffect(() => {
    if (loadingInitialData || !summary) return;

    const loadData = async () => {
      if (activeTab === 'Chronology' && userType.includes('Law') && chronology === '') {
        setLoadingChronology(true);
        try {
          const result = (await dispatch(getChronology(caseId))) as ChronologyData | null;
          setChronology(result?.GeneratedChronology || '');
        } catch (err) {
          console.error('Failed to load chronology:', err);
        } finally {
          setLoadingChronology(false);
        }
      } else if (activeTab === 'Memo' && memoContent === '') {
        setLoadingMemo(true);
        try {
          const result: any = await dispatch(getCaseMemo(caseId));
          setMemoContent(result?.memo || '');
        } catch (err) {
          console.error('Failed to load memo:', err);
        } finally {
          setLoadingMemo(false);
        }
      } else if (activeTab === 'Legal Outline' && !legalOutlineData) {
        setLoadingOutline(true);
        try {
          const result: any = await dispatch(getCaseLegalOutline(caseId));
          setLegalOutlineData(result?.LegalOutline || null);
        } catch (err) {
          console.error('Failed to load outline:', err);
        } finally {
          setLoadingOutline(false);
        }
      } else if (activeTab === 'Financial Data' && Object.keys(financialData).length === 0) {
        setLoadingFinancial(true);
        try {
          const result: any = await dispatch(getCaseFinancialData(caseId));
          setFinancialData(result?.FinancialData || {});
        } catch (err) {
          console.error('Failed to load financial data:', err);
        } finally {
          setLoadingFinancial(false);
        }
      } else if (
        activeTab === 'Case Analysis' &&
        !analysisState.isRunning &&
        !analysisState.analysisData
      ) {
        setAnalysisLoading(true);
        try {
          await dispatch(fetchCaseAnalysisData(caseId));
        } catch (err) {
          console.error('Failed to fetch existing analysis:', err);
        } finally {
          setAnalysisLoading(false);
        }
      } else if (activeTab === 'Cycle' && !userType.includes('Law')) {
        try {
          const res: any = await dispatch(fetchCaseCycle(caseId));
          setCycle(res || {});
        } catch (err) {
          console.error('Failed to load cycle data', err);
        }
      }
    };
    loadData();
  }, [activeTab, summary, loadingInitialData, userType, caseId, dispatch]);

  // Update breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { name: 'Cases', href: '/cases', current: false },
      {
        name: summary?.CaseName || t('Loading...'),
        href: `/cases/${caseId}`,
        current: true,
      },
    ]);
  }, [summary, caseId, setBreadcrumbs, t]);

  // ### Data Loading Methods
  const loadCaseSummary = async () => {
    setLoadingInitialData(true);
    try {
      const result = await dispatch(fetchCaseSummary(caseId));
      if (result && result.summary) {
        setSummary(result.summary);
        const workflowData = await dispatch(fetchCaseWorkflows(caseId));
        const activeWorkflows = workflowData.filter((w: any) => w.IsActive);
        const updatedWorkflows = activeWorkflows.map((w: any) => ({
          ...w,
          status: getWorkflowStatus(w.Name, result.summary),
        }));
        setWorkflows(updatedWorkflows);
      }
    } catch (err) {
      console.error('Failed to load summary:', err);
    } finally {
      setLoadingInitialData(false);
    }
  };

  const refreshChronology = async () => {
    setLoadingChronology(true);
    try {
      const result = (await dispatch(getChronology(caseId))) as ChronologyData | null;
      setChronology(result?.GeneratedChronology || '');
    } finally {
      setLoadingChronology(false);
    }
  };

  // ### Handlers
  const handleDeleteCase = () => {
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to delete this case?'),
        onConfirm: async () => {
          dispatch(hideModal());
          await dispatch(deleteCase(caseId));
          history.push('/cases');
        },
        onCancel: () => dispatch(hideModal()),
      })
    );
  };

  const handleEditCaseInfo = () => setIsEditingCaseInfo(true);
  const handleCancelEditCaseInfo = () => {
    setIsEditingCaseInfo(false);
    if (summary?.fields) setEditedCaseFields(summary.fields);
  };
  const handleSaveCaseInfo = async () => {
    if (!summary) return;
    const updated = { CaseID: caseId, fields: editedCaseFields };
    await dispatch(editCase(updated));
    await loadCaseSummary();
    setIsEditingCaseInfo(false);
  };
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedCaseFields((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleRunAnalysisLawyerSSE = async () => {
    const userIndexes: Record<string, string> = {};
    if (selectedApplicableLawsIndex) userIndexes['applicable_laws'] = selectedApplicableLawsIndex;
    if (selectedRelevantCasesIndex) userIndexes['relevant_cases'] = selectedRelevantCasesIndex;
    await dispatch(runCaseAnalysisSSE(caseId, userIndexes));
    setActiveTab('Case Analysis');
    await loadCaseSummary();
  };

  const handleRunAnalysis = async () => {
    const userIndexes: Record<string, string> = {};
    if (selectedApplicableLawsIndex) userIndexes['applicable_laws'] = selectedApplicableLawsIndex;
    if (selectedRelevantCasesIndex) userIndexes['relevant_cases'] = selectedRelevantCasesIndex;
    await dispatch(runCaseAnalysis(caseId, userIndexes));
    dispatch(getAiModels());
    setActiveTab('Case Analysis');
    await loadCaseSummary();
  };

  const handleRunAnalysisWithIndexes = async () => {
    if (userType.includes('Law')) await handleRunAnalysisLawyerSSE();
    else await handleRunAnalysis();
  };

  const handleGenerateDocument = () => dispatch(generateDocument(caseId));

  const openWorkflowModal = () => {
    dispatch(
      showModal({
        type: 'workflows',
        showModal: true,
        message: '',
        caseId: caseId,
        onSave: () => {
          dispatch(fetchCaseWorkflows(caseId))
            .then((data) => setWorkflows(data.filter((w: any) => w.IsActive)))
            .catch(() => {});
        },
      })
    );
  };

  // ### Tab Control
  const getTabStatuses = () => {
    if (loadingInitialData || !summary) {
      return [{ label: 'Case Information', value: 'Case Information', status: 'Not Started' }];
    }
    return [
      { label: 'Case Information', value: 'Case Information', status: 'Completed' },
      ...workflows.map((w) => ({
        label: w.Name,
        value: w.Name,
        status: w.status,
      })),
      { label: 'Financial Data', value: 'Financial Data', status: 'Completed', rightAligned: true },
    ];
  };

  const handleTabClick = (tabValue: string) => {
    setActiveTab(tabValue);
    queryParams.set('tab', tabValue);
    history.push({ search: queryParams.toString() });
  };

  const renderWorkflowContent = () => {
    const workflow = workflows.find((w) => w.Name === activeTab);
    if (!workflow) return null;

    // loadCaseSummary()

    const isAnalysisCompleted =
      workflows.find((w) => w.Name === 'Case Analysis')?.status === 'Completed';

    switch (workflow.Name) {
      case 'Chronology':
        return loadingChronology ? (
          <ApiLoader />
        ) : (
          <Chronology
            caseId={caseId}
            generatedChronology={chronology}
            refreshChronology={refreshChronology}
            loadCaseSummary={loadCaseSummary}
          />
        );
      case 'Case Analysis':
        return (
          <CaseAnalysis
            t={t}
            userType={userType}
            summary={summary}
            analysisState={analysisState}
            analysisLoading={analysisLoading}
            finalizingAnalysis={analysisFinalizing || finalizingAnalysis}
            showFinalAnalysis={showFinalAnalysis}
            handleFinalAnimationDone={() => setShowFinalAnalysis(true)}
            handleRunAnalysis={handleRunAnalysis}
            handleRunAnalysisLawyerSSE={handleRunAnalysisLawyerSSE}
            handleRunAnalysisWithIndexes={handleRunAnalysisWithIndexes}
            setActiveTab={setActiveTab}
            dispatch={dispatch}
            caseId={caseId}
            indexOptionsWithNone={indexOptionsWithNone}
            selectedApplicableLawsIndex={selectedApplicableLawsIndex}
            setSelectedApplicableLawsIndex={setSelectedApplicableLawsIndex}
            selectedRelevantCasesIndex={selectedRelevantCasesIndex}
            setSelectedRelevantCasesIndex={setSelectedRelevantCasesIndex}
          />
        );
      case 'Memo':
        return loadingMemo ? (
          <ApiLoader />
        ) : (
          <Memo
            caseId={caseId}
            localMemo={memoContent}
            isAnalysisCompleted={isAnalysisCompleted}
            setGeneratingMemo={setGeneratingMemo}
            loadCaseSummary={loadCaseSummary}
            loadMemo={async () => {
              setLoadingMemo(true);
              const result: any = await dispatch(getCaseMemo(caseId));
              setMemoContent(result?.memo || '');
              setLoadingMemo(false);
            }}
          />
        );
      case 'Legal Outline':
        return loadingOutline ? (
          <ApiLoader />
        ) : (
          <LegalOutline
            caseId={caseId}
            onUpdate={loadCaseSummary}
            legalOutlineData={legalOutlineData || {}}
          />
        );
      default:
        return <div>{workflow.Name} Content (Placeholder)</div>;
    }
  };

  // ### Main Render
  if (loadingInitialData) return <SkeletonLoader />;

  return (
    <div className="h-[calc(100vh-90px)] relative bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300">
      {/* Top Bar */}
      <div className="p-4 border-b border-gray-200 dark:border-gray-700 mx-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => history.push('/cases')}
              className="text-primary-500 hover:text-primary-600 transition-colors"
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
            <div>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-100">{caseName}</h1>
              <div className="flex items-center space-x-4 mt-2">
                <div className="flex items-center">
                  <PiBriefcaseLight className="h-5 w-5 text-gray-500 mr-2" />
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    {t(summary?.CaseType) || '-'}
                  </span>
                </div>
                <div className="flex items-center">
                  <PiFileTextLight className="h-5 w-5 text-gray-500 mr-2" />
                  <Badge color={summary?.case_status === 'open' ? 'green' : 'gray'}>
                    {t(summary?.case_status) || '-'}
                  </Badge>
                </div>
                <div className="flex items-center">
                  <PiCalendarLight className="h-5 w-5 text-gray-500 mr-2" />
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    {summary?.created_at
                      ? convertLongDateWithTranslation(
                          new Date(summary.created_at).toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        )
                      : '-'}
                  </span>
                </div>
                {userType.includes('Law') && (
                  <div className="flex items-center">
                    <PiUserLight className="h-5 w-5 text-gray-500 mr-2" />
                    <span className="text-sm text-gray-700 dark:text-gray-300">
                      {summary?.ClientName || t('No Client')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {!isBetaEnv &&
              !userType.includes('Law') &&
              !isPublished &&
              summary?.case_status !== 'EngagementOngoing' && (
                isCaseAnalysisCompleted ? (
                  <Button
                    onClick={() => dispatch(showPublishCaseSidePanel(summary))}
                    variant="primary"
                  >
                    {t('Publish Case')}
                  </Button>
                ) : (
                  <div className="flex flex-col items-end">
                    <Button variant="primary" disabled>
                      {t('Publish Case')}
                    </Button>
                    <p className="text-xs text-yellow-600 mt-1">
                      {t('Please complete the case analysis before publishing.')}
                    </p>
                  </div>
                )
              )}
            {summary?.IsMarketplaceCase && userType.includes('Law') && (
              <Badge color="blue" tooltip={t('Case from Marketplace')}>
                {t('Marketplace')}
              </Badge>
            )}
            {userType.includes('Law') && (
              <Tooltip title={t('Workflows')} placement="top">
                <button
                  onClick={openWorkflowModal}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                >
                  <PiTreeStructureLight className="h-6 w-6 text-gray-600 dark:text-gray-300" />
                </button>
              </Tooltip>
            )}
            <Menu as="div" className="relative inline-block text-left ml-2">
              <Menu.Button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors">
                <EllipsisVerticalIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            setActiveTab('Case Information');
                            handleEditCaseInfo();
                          }}
                          className={`${
                            active ? 'bg-gray-100 dark:bg-gray-700' : ''
                          } w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 flex items-center gap-3`}
                        >
                          <PiPencilSimpleLineLight />
                          {t('Edit Case')}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleDeleteCase}
                          className={`${
                            active ? 'bg-gray-100 dark:bg-gray-700' : ''
                          } w-full text-left px-4 py-2 text-sm text-red-700 dark:text-red-400 flex items-center gap-3`}
                        >
                          <PiTrashLight />
                          {t('Delete Case')}
                        </button>
                      )}
                    </Menu.Item>
                    {userType.includes('Law') && !summary?.ClientName && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() =>
                              dispatch(showAttachClientToCaseSidePanel(caseId, loadCaseSummary))
                            }
                            className={`${
                              active ? 'bg-gray-100 dark:bg-gray-700' : ''
                            } w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 flex items-center gap-3`}
                          >
                            <PiUserCirclePlusLight />
                            {t('Attach Client')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      {/* Tab Bar */}
      {!analysisState.isRunning && (
        <div className="px-4 pt-2 dark:bg-gray-800 w-full">
          <TabStatusNav tabs={getTabStatuses()} activeTab={activeTab} onTabClick={handleTabClick} />
        </div>
      )}

      {/* Tab Content */}
      <div className="px-4 pb-6 mt-4">
        {activeTab === 'Case Information' && (
          <CaseInformation
            summary={summary}
            loadingSummary={loadingInitialData}
            userType={userType}
            isEditingCaseInfo={isEditingCaseInfo}
            setIsEditingCaseInfo={setIsEditingCaseInfo}
            editedCaseFields={editedCaseFields}
            setEditedCaseFields={setEditedCaseFields}
            handleEditCaseInfo={handleEditCaseInfo}
            handleCancelEditCaseInfo={handleCancelEditCaseInfo}
            handleSaveCaseInfo={handleSaveCaseInfo}
            handleFieldChange={handleFieldChange}
            dispatch={dispatch}
            showExtractedContentSidePanel={showExtractedContentSidePanel}
            showAttachClientToCaseSidePanel={showAttachClientToCaseSidePanel}
          />
        )}
        {workflows.some((w) => w.Name === activeTab) && renderWorkflowContent()}
        {activeTab === 'Financial Data' && (
          <FinancialDataSection
            financialData={loadingFinancial ? {} : financialData}
            caseId={caseId}
          />
        )}
      </div>
    </div>
  );
};

export default CaseDetails;