import React, { useEffect, useState } from 'react';
import { PiCheckCircleLight } from 'react-icons/pi';

/**
 * This component renders a small spinner if isLoading=true,
 * then smoothly transitions to a check icon once isLoading=false.
 *
 * No "DONE" fade-out. The check remains visible once loaded.
 * 
 * Props:
 * - isLoading: whether the step is still running
 * - spinnerSize?: override spinner dimension (default 16px)
 * - checkSize?: override check icon dimension (default 16px)
 * - transitionDuration?: how quickly we switch states
 */
interface MiniLoadingToCheckmarkProps {
  isLoading: boolean;
  spinnerSize?: number;
  checkSize?: number;
  transitionDuration?: number; // in ms
}

const MiniLoadingToCheckmark: React.FC<MiniLoadingToCheckmarkProps> = ({
  isLoading,
  spinnerSize = 16,
  checkSize = 16,
  transitionDuration = 300,
}) => {
  const [phase, setPhase] = useState<'SPINNING' | 'CHECKMARK'>(
    isLoading ? 'SPINNING' : 'CHECKMARK'
  );

  useEffect(() => {
    if (isLoading) {
      // If we go back to loading, revert to spin
      setPhase('SPINNING');
    } else {
      // Once loading stops, wait a short moment (if desired) or instantly set check
      const timer = setTimeout(() => {
        setPhase('CHECKMARK');
      }, transitionDuration);

      return () => clearTimeout(timer);
    }
  }, [isLoading, transitionDuration]);

  return (
    <div className="relative flex items-center justify-center" style={{ width: checkSize, height: checkSize }}>
      {/* Spinner */}
      <div
        className={`
          ${phase === 'SPINNING' ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}
          absolute transition-all duration-300
          flex items-center justify-center
        `}
        style={{ width: spinnerSize, height: spinnerSize }}
      >
        <div
          className="animate-spin rounded-full border-t-2 border-b-2 border-blue-500"
          style={{ width: spinnerSize, height: spinnerSize }}
        />
      </div>

      {/* Check */}
      <PiCheckCircleLight
        className={`
          text-green-500 transition-all duration-300
          ${phase === 'CHECKMARK' ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}
        `}
        style={{ width: checkSize, height: checkSize }}
      />
    </div>
  );
};

export default MiniLoadingToCheckmark;
