import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineSetting } from 'react-icons/ai';
import { GoHistory } from 'react-icons/go';
import { PiNotePencilLight } from 'react-icons/pi';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import Badge from '../shared/TailwindComponents/Badge';
import { useAppDispatch } from '../../store';
import { handleAISettings, hideModal, showModal } from '../../store/actions/modalActions';

interface AIHeaderProps {
  onToggleSidebar: () => void;
  currentView: 'Folders' | 'Projects' | 'ChatHistory' | 'AISettings';
  setCurrentView: React.Dispatch<React.SetStateAction<'Folders' | 'Projects' | 'ChatHistory' | 'AISettings'>>;
  onDeleteChat: (chatGroupId: string) => void;
  onNewChat: () => void;
  selectedService: string;
  chatGroupID: string;
  chatName?: string;
  shouldType?: boolean;
}

function separateCamelCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

// CSS keyframes for fade-in and slide-up animation
const chatNameAnimationStyles = `
  @keyframes fadeInSlideUp {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .animate-chat-name {
    animation: fadeInSlideUp 0.5s ease-out forwards;
  }
`;

const AIHeader: React.FC<AIHeaderProps> = ({
  onToggleSidebar,
  currentView,
  setCurrentView,
  onDeleteChat,
  onNewChat,
  selectedService,
  chatGroupID,
  chatName,
  shouldType,
}) => {
  const { t } = useTranslation();
  const serviceLabel = separateCamelCase(selectedService);
  const dispatch = useAppDispatch();
  const [displayedChatName, setDisplayedChatName] = useState<string>('');

  // Handle chatName animation
  useEffect(() => {
    if (!chatName) {
      setDisplayedChatName('');
      return;
    }

    if (shouldType) {
      // Reset and animate when shouldType is true
      setDisplayedChatName(''); // Clear first to ensure animation retriggers
      setTimeout(() => {
        setDisplayedChatName(chatName); // Set full name after slight delay to trigger animation
      }, 50); // Small delay to allow CSS animation to reset
    } else {
      // No animation when shouldType is false
      setDisplayedChatName(chatName);
    }
  }, [chatName, shouldType]);

  const handleSettings = () => {
    dispatch(handleAISettings());
  };

  const handleNewChat = () => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to start a new chat?'),
        subMsg: t('This will clear the current chat.'),
        onConfirm: () => {
          dispatch(hideModal());
          onNewChat();
        },
        onCancel: () => {
          dispatch(hideModal());
        },
        showModal: true,
      })
    );
  };

  return (
    <>
      <style>{chatNameAnimationStyles}</style>
      <header className="flex items-center justify-between py-1 px-4">
        <div className="flex items-center space-x-3">
          <Badge color="indigo" className="capitalize">
            {t(serviceLabel)}
          </Badge>
          {/* {displayedChatName && (
            <span
              className={clsx(
                'text-sm text-gray-500 dark:text-gray-300 ml-2',
                shouldType && 'animate-chat-name' // Apply animation when shouldType is true
              )}
            >
              — {displayedChatName}
            </span>
          )} */}
        </div>

        <div className="flex items-center space-x-3">
          {chatGroupID && (
            <Tooltip title={t('New chat')}>
              <button
                onClick={handleNewChat}
                className="p-2 text-gray-700 dark:text-gray-50 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
              >
                <PiNotePencilLight size={20} />
              </button>
            </Tooltip>
          )}
          {/* <Tooltip title={t('AI Settings')}>
            <button
              onClick={handleSettings}
              className="p-2 text-gray-700 dark:text-gray-50 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
            >
              <AiOutlineSetting size={20} />
            </button>
          </Tooltip> */}
          <Tooltip title={t('History')}>
            <button
              onClick={onToggleSidebar}
              className="p-2 text-gray-700 dark:text-gray-50 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
            >
              <GoHistory size={20} />
            </button>
          </Tooltip>
        </div>
      </header>
    </>
  );
};

export default AIHeader;