import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineCalendar, AiOutlineFilePdf, AiOutlineInfoCircle } from 'react-icons/ai';
import { FaReceipt, FaCreditCard, FaMoneyCheckAlt } from 'react-icons/fa';
import { BsCurrencyDollar } from 'react-icons/bs';
import { useAppDispatch } from '../../../store';
import { getTransactionDetails } from '../../../store/actions/SubscriptionActions';
import ApiLoader from '../../ApiLoader';
import { useTranslation } from 'react-i18next';
import { convertLongDateWithTranslation } from '../DateConverter';

interface TransactionDetailsProps {
    AmountPaid: number;
    Currency: string;
    InvoiceID: string;
    InvoiceURL: string;
    InvoicePDF: string;
    PaymentID: string;
    PaymentStatus: string;
    PaymentMethod: {
        "Type": string,
        "Last4": string,
        "Brand": string
    };
    ReceiptURL: string;
    CreatedAt: string;
}

const TransactionDetails: React.FC<{ transactionId: string }> = ({ transactionId }) => {
    const [invoiceData, setInvoiceData] = useState<TransactionDetailsProps | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const fetchTransactionDetails = useCallback(async () => {
        setLoading(true);
        try {
            const response = await dispatch(getTransactionDetails(transactionId));
            if (response) {
                setInvoiceData(response);
            }
        } catch (err) {
            console.error("Failed to fetch transaction details", err);
        } finally {
            setLoading(false);
        }
    }, [dispatch, transactionId]);

    useEffect(() => {
        fetchTransactionDetails();
    }, [fetchTransactionDetails]);

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    if (loading && !invoiceData) {
        return <ApiLoader />;
    }

    return (
        <div className="w-full h-full mx-auto p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6 flex items-center gap-2">
                <AiOutlineInfoCircle className="text-blue-500" /> {t("Invoice Details")}
            </h2>

            <div className="space-y-4">
                {/* Amount Paid */}
                <div className="flex justify-between items-center border-b pb-2">
                    <span className="text-gray-700 dark:text-gray-300 flex items-center gap-1">
                        <BsCurrencyDollar className="text-green-500" /> {t("Amount Paid")}:
                    </span>
                    <span className="text-green-600 dark:text-green-400 font-semibold">
                        ${invoiceData?.AmountPaid} {invoiceData?.Currency}
                    </span>
                </div>

                {/* Payment Status */}
                <div className="flex justify-between items-center border-b pb-2">
                    <span className="text-gray-700 dark:text-gray-300 flex items-center gap-1">
                        <AiOutlineInfoCircle className="text-yellow-500" /> {t("Payment Status")}:
                    </span>
                    <span className={invoiceData?.PaymentStatus === 'succeeded' ? 'text-green-500 font-semibold' : 'text-red-500 font-semibold'}>
                        {invoiceData?.PaymentStatus ? t(invoiceData?.PaymentStatus).toUpperCase() : ""}
                    </span>
                </div>

                {/* Payment Method */}
                <div className="flex justify-between items-center border-b pb-2">
                    <span className="text-gray-700 dark:text-gray-300 flex items-center gap-1">
                        <FaCreditCard className="text-blue-500" /> {t("Payment Method")}:
                    </span>
                    {invoiceData?.PaymentMethod.Type === 'card' ? (
                        <span className="flex items-center gap-2 text-gray-900 dark:text-white">
                            {invoiceData?.PaymentMethod.Brand?.toUpperCase()} •••• {invoiceData?.PaymentMethod.Last4}
                        </span>
                    ) : (
                        <span className="text-gray-500">{t("N/A")}</span>
                    )}
                </div>

                {/* Created At */}
                <div className="flex justify-between items-center border-b pb-2">
                    <span className="text-gray-700 dark:text-gray-300 flex items-center gap-1">
                        <AiOutlineCalendar className="text-purple-500" /> {t("Created At")}:
                    </span>
                    <span className="text-gray-900 dark:text-white">{invoiceData?.CreatedAt && convertLongDateWithTranslation(formatDate(invoiceData?.CreatedAt))}</span>
                </div>

                {/* Invoice & Receipt Download Links */}
                <div className="mb-0 flex flex-col gap-3 mt-4">
                    <a
                        href={invoiceData?.InvoicePDF}
                        download={`Invoice_${invoiceData?.InvoiceID}.pdf`}
                        className="flex items-center justify-center gap-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                    >
                        <AiOutlineFilePdf /> {t("Download Invoice")}
                    </a>

                    <a
                        href={invoiceData?.ReceiptURL}
                        download={`Receipt_${invoiceData?.InvoiceID}.pdf`}
                        className="flex items-center justify-center gap-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
                    >
                        <FaReceipt /> {t("Download Receipt")}
                    </a>

                    {/* Invoice Link */}
                    {invoiceData?.InvoiceURL && (
                        <a
                            href={invoiceData?.InvoiceURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
                        >
                            <FaMoneyCheckAlt /> {t("View Invoice Online")}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TransactionDetails;
