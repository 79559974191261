
import { AIModel, ChatGroupData, FETCH_SERVICES_SUCCESS } from "../actions/ChatAction";
import {
  FETCH_CHATGROUPS_SUCCESS,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_TOTAL_AVAILABLE_QUERIES,
  SET_CHAT_GROUP_ID,
  SET_DOCUMENT_VISIBILITY,
  SET_FOLDER_VISIBILITY,
  SET_SELECTED_MODEL,
} from "../types";

interface Action {
  type: string;
  payload: any;
}

// Modify getInitialModel to return AIModel
const getInitialModel = (): AIModel => {
  const storedModel = localStorage.getItem('selectedModel');
  if (storedModel) {
    try {
      return JSON.parse(storedModel) as AIModel;
    } catch (error) {
      console.error("Error parsing stored model:", error);
    }
  }
  // If no stored model or error, return a default model (the first in your list or a hardcoded fallback)
  return {
    model_content: "Supports legal professionals in researching laws and precedents with AI assistance.",
    model_display_name: "Law Agent",
    model_id: 123456, // This should be a number that matches your data
    model_name: "LawAgent",
    model_role: "System",
    total_available_queries: "Unlimited",
  };
};

const getInitialChatGroupID = () => {
  // return localStorage.getItem('chatGroupID') || '';
};

const initialState = {
  selectedModel: getInitialModel(),
  chatGroupID: getInitialChatGroupID(),
  isFolderStructureVisible: true,
  isDocumentVisible: true,
  contracts: [],  
  chatGroups: [] as ChatGroupData[],
  services: [],
  total_available_queries: 0
};

const modelReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_SELECTED_MODEL:
      return {
        ...state,
        selectedModel: action.payload, // This is now an AIModel
      };
    case SET_CHAT_GROUP_ID:
      return {
        ...state,
        chatGroupID: action.payload,
      };
    case SET_FOLDER_VISIBILITY:
      return {
        ...state,
        isFolderStructureVisible: action.payload,
      };
    case SET_DOCUMENT_VISIBILITY:
      return {
        ...state,
        isDocumentVisible: action.payload,
      };
    case FETCH_CONTRACTS_SUCCESS: 
      return {
        ...state,
        contracts: action.payload
      };
    case FETCH_CHATGROUPS_SUCCESS: 
      return {
        ...state,
        chatGroups: action.payload
      };
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
      };
    case FETCH_TOTAL_AVAILABLE_QUERIES:
      return{
        ...state,
        total_available_queries: action.payload
      }
    case 'ADD_CHAT_GROUP':
      return {
        ...state,
        chatGroups: [action.payload, ...state.chatGroups],
      };
    default:
      return state;
  }
};

export default modelReducer;