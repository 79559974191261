import React, { useEffect, useState } from 'react';
import { fetchChronologyVersions } from '../../../../store/actions/LegalServices/chronologyActions';
import { useAppDispatch } from '../../../../store';
import { useTranslation } from 'react-i18next';
import Dropdown, { DropdownOption } from '../../../shared/TailwindComponents/Dropdown';
import Button from '../../../shared/TailwindComponents/Button';

export interface ChronologyVersion {
  versionId: string;         
  generatedText: string;
  dataHash?: string;
  dateCreated?: string;      
  editedText?: string;
  dateEdited?: string;
}

interface ChronologyVersionsDropdownProps {
  caseId: string;
  onSelectVersionText: (text: string) => void; 
  currentVersionText?: string;                 
  onRestoreVersion: (versionId: string) => void;
}

function formatDate(isoString?: string) {
  if (!isoString) return 'Invalid date';
  const dt = new Date(isoString); 
  if (isNaN(dt.getTime())) return 'Invalid date';

  const day = String(dt.getDate()).padStart(2, '0');
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const hours = String(dt.getHours()).padStart(2, '0');
  const minutes = String(dt.getMinutes()).padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const ChronologyVersionsDropdown: React.FC<ChronologyVersionsDropdownProps> = ({
  caseId,
  onSelectVersionText,
  currentVersionText,
  onRestoreVersion,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [versions, setVersions] = useState<ChronologyVersion[]>([]);
  const [selectedVersionId, setSelectedVersionId] = useState<string | null>(null);

  // 2) Load versions from the backend
  const loadVersions = async () => {
    // fetchChronologyVersions should return an array of ChronologyVersion
    const response = await dispatch(fetchChronologyVersions(caseId));
    if (!Array.isArray(response)) return; // maybe handle errors

    // Sort by dateCreated descending
    const sorted = [...response].sort((a, b) => {
      const dA = new Date(a.dateCreated || '').getTime();
      const dB = new Date(b.dateCreated || '').getTime();
      return dB - dA;
    });

    setVersions(sorted);

    // If no selection yet, select the newest version
    if (sorted.length > 0 && !selectedVersionId) {
      const latest = sorted[0];
      setSelectedVersionId(latest.versionId);
      // And tell the parent to display the newest text
      onSelectVersionText(latest.editedText || latest.generatedText);
    }
  };

  // 3) On mount or whenever caseId changes, reload
  useEffect(() => {
    loadVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  // 4) If the parent’s currentVersionText changes, reflect that in the dropdown
  useEffect(() => {
    if (!currentVersionText || versions.length === 0) return;
    const match = versions.find(
      (v) => (v.editedText || v.generatedText) === currentVersionText
    );
    if (match) {
      setSelectedVersionId(match.versionId);
    }
  }, [currentVersionText, versions]);

  // 5) Build your dropdown options
  const dropdownOptions: DropdownOption[] = versions.map((v) => {
    const labelDate = formatDate(v.dateCreated);
    const maybeEdited = v.editedText ? ` (${t('edited')})` : '';
    return {
      value: v.versionId,
      label: `${t('Version from')} ${labelDate}${maybeEdited}`,
    };
  });

  // 6) When user selects a version from the dropdown
  const handleSelect = (option: DropdownOption) => {
    setSelectedVersionId(option.value);
    const found = versions.find((v) => v.versionId === option.value);
    if (!found) return;
    // Tell parent to display this version’s text
    const text = found.editedText || found.generatedText;
    onSelectVersionText(text);
  };

  // 7) When user clicks "Restore", we call parent's restore function
  const handleRestoreClick = () => {
    if (selectedVersionId) onRestoreVersion(selectedVersionId);
  };

  const selectedVersion = versions.find((v) => v.versionId === selectedVersionId);
  const isCurrentVersion = selectedVersion
    && (selectedVersion.editedText || selectedVersion.generatedText) === currentVersionText;

  return (
    <div className="flex items-center gap-2">
      <div className="min-w-[240px]">
        <Dropdown
          placeholder={t('Select a version')}
          options={dropdownOptions}
          onSelect={handleSelect}
          // We bind the actual value in the dropdown to selectedVersionId
          value={selectedVersionId || ''}
        />
      </div>
      {/* Show the "Restore" button only if selectedVersion isn't the one currently displayed */}
      {selectedVersion && !isCurrentVersion && (
        <Button onClick={handleRestoreClick} variant="tertiary" size="small">
          {t('Restore')}
        </Button>
      )}
    </div>
  );
};

export default ChronologyVersionsDropdown;
