import React, { useCallback, useEffect, useState } from "react";
import { FaCheckCircle, FaTimesCircle, FaCalendarAlt, FaFileInvoiceDollar, FaClock } from "react-icons/fa";
import { BsCartFill, BsPlusCircle } from "react-icons/bs";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { getActiveSubscription } from "../../../store/actions/SubscriptionActions";
import Badge from "../TailwindComponents/Badge";
import ApiLoader from "../../ApiLoader";
import { useTranslation } from "react-i18next";
import { convertLongDateWithTranslation } from "../DateConverter";
import { PiCalendarBlankLight, PiInvoiceLight } from "react-icons/pi";
import Button from "../TailwindComponents/Button";

interface InvoiceLineItem {
    amount: number;
    currency: string;
    description: string;
    duration_days: number;
    is_addon: boolean;
    license_id: string | number;
    license_name: string;
    quantity: number;
}

interface SubscriptionDetails {
    currency: string;
    duration_days: number;
    end_date: string;
    invoice_line_items: InvoiceLineItem[];
    license_description: string;
    license_id: number;
    license_name: string;
    next_invoice: string | null;
    price: number;
    start_date: string;
    status: boolean;
    stripe_amount: number | null;
    stripe_status: string | null;
    stripe_subscription_id: string | null;
    total_queries: number;
    isTestLicense: boolean | false
}

interface Props {
    setActiveTab?: (tab: string) => void;
}

const ActiveSubscriptions: React.FC<Props> = ({ setActiveTab }) => {
    const history = useHistory();
    const dispatch: any = useAppDispatch();
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails | null>(null);
    const [loading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const purchasedItems = subscriptionDetails ? subscriptionDetails.invoice_line_items.filter((item: any) => !item.is_addon) : [];
    const addOns = subscriptionDetails ? subscriptionDetails.invoice_line_items.filter((item: any) => item.is_addon) : [];

    const fetchSubscriptionDetails = useCallback(async () => {
        setIsLoading(true);
        const resp = await dispatch(getActiveSubscription());
        setSubscriptionDetails(resp || null);
        setIsLoading(false);
    }, [dispatch]);

    useEffect(() => {
        fetchSubscriptionDetails();
    }, [fetchSubscriptionDetails]);

    if (loading) {
        return <ApiLoader />;
    }

    return (
        <div className="space-y-2 w-full mx-auto rounded-lg">
            {!subscriptionDetails ? (
                <div className="text-center py-6">
                    <p className="text-gray-600 dark:text-gray-300">{t("No active subscription found.")}</p>
                </div>
            ) : (
                <>
                    {/* Header with Upgrade/Buy Subscription Button */}
                    <div className="flex justify-between items-center border-b pb-4 mb-4">
                        <div className="flex flex-col gap-1">
                            <h2 className="text-lg font-medium text-gray-900 dark:text-white flex items-center gap-2">
                                <PiInvoiceLight className="text-blue-500" /> {t("Subscription Details")}
                            </h2>
                            {subscriptionDetails.stripe_subscription_id && (
                                <Badge color="gray" className="text-center">{subscriptionDetails.stripe_subscription_id}</Badge>
                            )}
                        </div>
                        <Button
                            onClick={() => history.push("/plans")}
                            color="primary"
                            size="small"
                            disabled={subscriptionDetails.isTestLicense}
                        >
                            {subscriptionDetails ? t("Upgrade") : t("Buy Subscription")}
                        </Button>
                    </div>

                    {/* License Info */}
                    <div className="">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-bold text-gray-900 dark:text-white flex items-center gap-2">
                                {subscriptionDetails.license_name}
                            </h3>
                            <span className={`px-3 py-1 text-sm rounded-full font-medium ${subscriptionDetails.status ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"}`}>
                                {subscriptionDetails.status ? (
                                    <span className="flex items-center gap-1">
                                        <FaCheckCircle className="text-green-500" /> {t("Active")}
                                    </span>
                                ) : (
                                    <span className="flex items-center gap-1">
                                        <FaTimesCircle className="text-red-500" /> {t("Inactive")}
                                    </span>
                                )}
                            </span>
                        </div>

                        {/* Plan Details */}
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <span className="text-gray-700 dark:text-gray-300">{t("Total Queries")}:</span>
                                <span className="text-gray-900 dark:text-white font-semibold">{subscriptionDetails.total_queries}</span>
                            </div>

                            <div className="flex justify-between items-center">
                                <span className="text-gray-700 dark:text-gray-300">{t("Price")}:</span>
                                <span className="text-gray-900 dark:text-white font-semibold">
                                    {subscriptionDetails.price === 0 ? 'N/A' : `$ ${subscriptionDetails.price} (${subscriptionDetails.currency.toUpperCase()})`}
                                </span>
                            </div>

                            <div className="flex justify-between items-center">
                                <span className="flex items-center gap-2 text-gray-700 dark:text-gray-300">
                                    <PiCalendarBlankLight className="text-blue-500" /> {t("Start Date")}:
                                </span>
                                <span className="text-gray-900 dark:text-white">{convertLongDateWithTranslation(formatDate(subscriptionDetails.start_date))}</span>
                            </div>

                            <div className="flex justify-between items-center">
                                <span className="flex items-center gap-2 text-gray-700 dark:text-gray-300">
                                    <PiCalendarBlankLight className="text-red-500" /> {t("Expiring Date")}:
                                </span>
                                <span className="text-gray-900 dark:text-white">{convertLongDateWithTranslation(formatDate(subscriptionDetails.end_date))}</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ActiveSubscriptions;
