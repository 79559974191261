import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideFeedbackModal } from '../../store/actions/UserFeedbackActions';
import Alert from './TailwindComponents/Alert';
import { RootState } from '../../store';
import { Transition } from '@headlessui/react';

const UserFeedbackModal: React.FC = () => {
  const dispatch = useDispatch();
  const userFeedbackModalState = useSelector((state: RootState) => state.userFeedback);
  const { showModal, modalType, message } = userFeedbackModalState;

  return (
    <Transition
      show={showModal}
      as={Fragment}
      appear={true}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0 -translate-y-10"
      enterTo="opacity-100 translate-y-0"
      leave="transform ease-in duration-200 transition"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-10"
    >
      <div className="fixed top-0 left-0 right-0 flex items-start justify-center z-[3000000000] mt-4">
        <Alert
          type={modalType || 'info'}
          message={message || ''}
          onClose={() => dispatch(hideFeedbackModal())}
        />
      </div>
    </Transition>
  );
};

export default UserFeedbackModal;