import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface Message {
  MessageID: string;
  EngagementID: string;
  SenderID: number;
  SenderName: string;
  Message: string;
  CreatedAt: string;
}

interface ActivityFeedProps {
  messages: Message[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ActivityFeed: React.FC<ActivityFeedProps> = ({ messages }) => {
  const {t} = useTranslation()
  // Sort messages from oldest to newest
  const sortedMessages = [...messages].sort(
    (a, b) => new Date(a.CreatedAt).getTime() - new Date(b.CreatedAt).getTime()
  );

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md mb-6">
      <h2 className="text-xl font-semibold mb-4">{t("Activity")}</h2>
      <ul className="space-y-6">
        {sortedMessages.map((message, index) => (
          <li key={message.MessageID} className="relative flex gap-x-4">
            {/* Vertical line */}
            <div
              className={classNames(
                index === sortedMessages.length - 1 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 flex w-6 justify-center'
              )}
            >
              <div className="w-px bg-gray-200 dark:bg-gray-700" />
            </div>
            {/* Avatar */}
            <div className="relative mt-3 h-6 w-6 flex-none rounded-full bg-white dark:bg-gray-800">
              <img
                alt=""
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                  message.SenderName
                )}&background=random`}
                className="h-6 w-6 rounded-full bg-gray-50"
              />
            </div>
            {/* Message Content */}
            <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 dark:ring-gray-700">
              <div className="flex justify-between gap-x-4">
                <div className="py-0.5 text-xs text-gray-500 dark:text-gray-300">
                  <span className="font-medium text-gray-900 dark:text-white">
                    {message.SenderName}
                  </span>
                </div>
                <time
                  dateTime={message.CreatedAt}
                  className="flex-none py-0.5 text-xs text-gray-500 dark:text-gray-400"
                >
                  {formatDistanceToNow(new Date(message.CreatedAt), {
                    addSuffix: true,
                  })}
                </time>
              </div>
              <p className="mt-2 text-sm text-gray-700 dark:text-gray-200">
                {message.Message}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActivityFeed;
