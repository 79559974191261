import React, { useCallback, useEffect, useState } from 'react';
import { useMicrosoftGraph } from '../../contexts/MicrosoftGraphContext';
import { FileProps } from './types';
import { RootState, useAppDispatch } from '../../store';
import { editDocument, deleteDocument, fetchFileDetails, updateSharedWithForFile } from '../../store/actions/DocumentsAction';
import DocumentPreview from './DocumentPreview';
import { signDocument } from '../../store/actions/documentSignActions';
import PDFViewer from './PDFViewer';
import { useSelector } from 'react-redux';
import { hideModal, showModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import ApiLoader from '../ApiLoader';

interface EditorProps {
  file: string;
  onClose: () => void;
  setEditorOpen: (value: boolean) => void;
  onFileDeleted: () => void;
}

const Editor: React.FC<EditorProps> = ({ file, onClose, setEditorOpen, onFileDeleted }) => {
  const dispatch = useAppDispatch();
  const { isSignedIn, MSALsignIn, uploadFileToOneDrive, getEditLink, downloadFileFromOneDrive, signOut } = useMicrosoftGraph();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");
  const [sasUrl, setSasUrl] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [isPdf, setIsPDF] = useState(false);
  const [isDocSigned, setIsDocSigned] = useState(false);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [fileData, setFileData] = useState<FileProps | null>(null);
  const [isDocSignatory, setIsDocSignatory] = useState(false);
  const { t } = useTranslation();
  const [shareFile, setShareFile] = useState<any>(null);
  const [isFullScreen, setIsFullScreen] = useState(false); 

  const getFileDetails = useCallback(async () => {
    setIsLoading(true);
    const fileData = await dispatch(fetchFileDetails(file));
    if (fileData?.Signers?.some((signer: any) => signer.UserID === userInfo.UserID)) {
      setIsDocSigned(true);
    }
    if (fileData?.Signatories?.some((signer: any) => signer.UserID === userInfo.UserID)) {
      setIsDocSignatory(true);
    }
    fileData?.SharedWith?.some((signer: any) => {
      if (signer.UserID === userInfo.UserID) {
        setShareFile(signer);
        return null;
      }
      return null;
    });
    if (fileData && fileData.FileSasURL) {
      setFileData(fileData);
      if (fileData.FileSasURL.includes('.pdf')) {
        setIsPDF(true);
        setSasUrl(fileData.FileSasURL);
      } else {
        setSasUrl(fileData.FileSasURL);
        setEmbedUrl(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileData.FileSasURL)}`);
      }
    } else {
      console.error('Failed to get FileSasURL from file data.');
    }
    setIsLoading(false);
  }, [dispatch, file, userInfo.UserID]);

  useEffect(() => {
    getFileDetails();
  }, [getFileDetails]);

  const initializeWordEditor = useCallback(async () => {
    try {
      if (!fileData || !fileData.FileSasURL) return;
    } catch (error) {
      console.error('Error initializing Word Editor:', error);
    }
  }, [fileData]);

  useEffect(() => {
    if (!isPdf) initializeWordEditor();
  }, [initializeWordEditor, isPdf]);

  const openEditor = async () => {
    if (!isSignedIn) {
      const { token } = await MSALsignIn();
      if (!token) return;
    }
    if (!fileData || !fileData.FileSasURL) {
      console.error('SAS URL is undefined or empty.');
      return;
    }
    const response = await fetch(fileData.FileSasURL);
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error fetching file:', errorText);
    }
    const fileContent = await response.blob();
    if (fileContent.type === 'text/html') {
      const errorText = await fileContent.text();
      console.error('Received HTML instead of file content:', errorText);
      throw new Error('Fetched content is not a valid file.');
    }
    const validWordBlob = new Blob([fileContent], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    const fileName = fileData.FileName.endsWith('.docx') ? fileData.FileName : `${fileData.FileName}.docx`;
    const { fileID, updatedSharedWith, webURL } = await uploadFileToOneDrive(fileName, validWordBlob, fileData.SharedWith);
    let editLink = webURL || (await getEditLink(fileID));
    const editorWindow = window.open(editLink, '_blank');
    if (updatedSharedWith) {
      await dispatch(updateSharedWithForFile(fileData.FileID, updatedSharedWith));
    }
    if (!editorWindow) {
      console.error('Failed to open the editor window.');
      return;
    }
    const interval = setInterval(() => {
      if (editorWindow.closed) {
        clearInterval(interval);
        handleSave(fileID);
      }
    }, 1000);
  };

  const handleSave = async (uploadedFileID: string) => {
    try {
      setIsSaving(true);
      const updatedFileContent = await downloadFileFromOneDrive(uploadedFileID);
      if (!fileData) return;
      await dispatch(editDocument(fileData.FileID, fileData.FileName, updatedFileContent));
      await getFileDetails();
    } catch (error) {
      console.error('Error saving file:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteDocument(file, () => onFileDeleted()));
      setEditorOpen(false);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleSignDocument = async () => {
    dispatch(
      showModal({
        type: 'sign_doc',
        message: t("You are gonna sign this document which won't be reverted back. Please ensure to check your signatures before you proceed."),
        onConfirm: async (fileID: string) => {
          await dispatch(signDocument(fileID, async () => {
            await getFileDetails();
          }));
          dispatch(hideModal());
        },
        onCancel: () => {},
        showModal: true,
        fileID: file,
      })
    );
  };

  const toggleFullScreen = () => setIsFullScreen(!isFullScreen); // Toggle function

  return (
    <div className={`editor-container flex flex-col h-screen flex-1 ${isFullScreen ? 'fixed inset-0 z-50 bg-white dark:bg-gray-800' : ''}`}>
      {isLoading ? (
        <div className="flex justify-center items-center flex-1">
          <ApiLoader />
        </div>
      ) : (
        <>
          <div className={`flex-1 ${isFullScreen ? 'overflow-auto' : ''}`}>
            {isPdf ? (
              <div className={`w-full h-full ${isFullScreen ? 'flex justify-center items-center' : 'w-[93%] h-[88vh] mx-auto bg-gray-200 border rounded-lg shadow-lg'}`}>
                <PDFViewer pdfUrl={sasUrl} />
              </div>
            ) : (
              <iframe
                src={embedUrl}
                frameBorder="0"
                style={{ width: '100%', height: '100%' }}
                title={fileData ? fileData.FileName : ""}
              ></iframe>
            )}
          </div>
          {isFullScreen && (
            <div className='absolute top-14 ml-2'>
            <Button onClick={toggleFullScreen} variant="neutral" size="small">
            {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
          </Button>
          </div>
            )}
          {!isFullScreen && (
          <div className="actions p-4 flex justify-between items-center bg-gray-100">
            <div className="flex space-x-2">
              {shareFile ? (
                <>
                  {shareFile.SharedURL && (
                    <Button
                      onClick={() => window.open(shareFile.SharedURL, '_blank')}
                      variant="primary"
                      size="small"
                    >
                      {t("Open")}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button onClick={() => handleDelete()} variant="destructive" size="small">
                    {t("Delete")}
                  </Button>
                  <Button onClick={() => openEditor()} disabled={isSaving || isPdf} variant="tertiary" size="small">
                    {isSaving ? 'Saving...' : 'Edit'}
                  </Button>
                  <Button onClick={() => signOut()} disabled={isSaving || isPdf} variant="neutral" size="small">
                    {t("Sign Out")}
                  </Button>
                  <Button onClick={toggleFullScreen} variant="neutral" size="small">
                    {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
                  </Button>
                </>
              )}
            </div>
            <div className="flex space-x-2">
              {((fileData && fileData.UserID === userInfo.UserID) || (isDocSignatory && !isDocSigned)) && (
                <Button onClick={() => handleSignDocument()} variant="primary" size="small">
                  {t("Sign Document")}
                </Button>
              )}
              <Button onClick={() => onClose()} variant="neutral" size="small">
                {t("Close")}
              </Button>
            </div>
          </div>
          )}
          {showPreview && (
            <div className="fixed inset-0 bg-white bg-opacity-90 flex justify-center items-center z-50">
              <div className="bg-white shadow-lg rounded-lg w-3/4 h-3/4">
                <DocumentPreview fileId={file} onClose={() => setShowPreview(false)} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Editor;