import { Action,applyMiddleware, combineReducers, createStore } from 'redux'
import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk'
import { composeWithDevTools } from "redux-devtools-extension";
import signUp from './reducer/signUp';
import signIn from './reducer/signIn';
import DataReducer from './reducer/DataReducer';
import { useDispatch } from 'react-redux';
import documentReducer from './reducer/documentsReducer';
import folderReducer from './reducer/folderReducer';
import modelReducer from './reducer/chatReducer';
import { userReducer } from './actions/DataAction';
import feedbackReducer from './reducer/feedbackReducer';
import { modalReducer } from './reducer/modalReducer';
import { taskReducer } from './reducer/TaskReducer';
import adminViewReducer from './reducer/organization/AdminReducer';
import { speechReducer } from './reducer/SpeechReducer';
import { fontSizeReducer } from './reducer/FontSizeReducer';
import pricingReducer from './reducer/pricingReducer';
import roleReducer from './reducer/settingsRoleReducer';
import { userFeedbackReducer } from './reducer/UserFeedbackReducer';
import sidebarReducer from './reducer/SidebarReducer';
import helpReducer from './reducer/HelpReducer';
import companyAgentReducer from './reducer/CompanyAgentReducer';
import otpReducer from './reducer/otpReducer';
import companyReducer from './reducer/organization/CompanyReducer';
import stripeReducer from './reducer/StripeReducer';
import { timeManagementReducer } from './reducer/TimeManagementReducer';
import { clientReducer } from './reducer/ClientReducer';
import SecurityReducer from './reducer/SecurityReducer';
import dropdownReducer from './reducer/DropdownReducer';
import customAgentReducer from './reducer/CustomAgentReducer';
import { sidePanelReducer } from './reducer/sidePanelReducer';
import { projectReducer } from './reducer/ProjectReducer';
import whiteLabelReducer from './reducer/organization/whiteLabelReducer';
import { employeeReducer } from './reducer/organization/employeeReducer';
import { marketplaceReducer } from './reducer/marketplace2/marketplaceReducer';
import caseAnalysisReducer from './reducer/marketplace2/caseAnalysisReducer';
import casesReducer from './reducer/marketplace2/caseReducer';
import { teamReducer } from './reducer/organization/teamReducer';
import { marketplaceRequestsReducer } from './reducer/marketplace2/marketplaceRequestsReducer';
import { marketplaceBidsReducer } from './reducer/marketplace2/marketplaceBidsRecucer';
import marketplaceSettingsReducer from './reducer/marketplace2/marketplaceSettingsReducer';
import marketplaceReceiverReducer from './reducer/marketplace2/MarketplaceReceiver/MarketplaceReceiverReducer';
import marketplaceProviderReducer from './reducer/marketplace2/MarketplaceProvider/MarketplaceProviderReducer';
import legalOutlineReducer from './reducer/LegalOutlineReducer';
import { aistudioReducer } from './reducer/AIStudioReducer';
import documentSignReducer from './reducer/documentSignReducer';
import { lawyerReducer } from './reducer/LawyerReducer';
import { subscriptionReducer } from './reducer/SubscriptionReducer';



const rootReducer = combineReducers({
    signUp: signUp,
    pricing: pricingReducer,
    auth: signIn,
    lists: DataReducer,
    documents: documentReducer,
    folders: folderReducer,
    model: modelReducer,
    user: userReducer,
    feedback: feedbackReducer,
    modal: modalReducer,
    tasks: taskReducer,
    adminView: adminViewReducer,
    // speechSettings: speechReducer,
    // fontSize: fontSizeReducer,
    role: roleReducer,
    userFeedback: userFeedbackReducer,
    sidebar: sidebarReducer,
    help: helpReducer,
    companyAgent: companyAgentReducer,
    otp: otpReducer,
    company: companyReducer,
    stripe: stripeReducer,
    time: timeManagementReducer,
    client: clientReducer,
    security: SecurityReducer,
    dropdown: dropdownReducer,
    customAgent: customAgentReducer,
    sidePanel: sidePanelReducer,
    projects: projectReducer,
    whiteLabel: whiteLabelReducer,
    employees: employeeReducer,
    marketplace: marketplaceReducer,
    caseAnalysis: caseAnalysisReducer,
    casesM: casesReducer,
    teams: teamReducer,
    marketplaceBids: marketplaceBidsReducer,
    marketplaceRequests: marketplaceRequestsReducer,
    marketplaceSettings: marketplaceSettingsReducer,
    marketplaceReceiver: marketplaceReceiverReducer,
    marketplaceProvider: marketplaceProviderReducer,
    legalOutline: legalOutlineReducer,
    aistudio: aistudioReducer,
    signdoc: documentSignReducer,
    lawyer: lawyerReducer,
    subscription: subscriptionReducer
})


export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<RootState, any, Action>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

const middleware = [thunk as ThunkMiddleware<RootState>];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

