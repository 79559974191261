import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { getUserType } from '../../utils/authUtils';
import { BadgeColor, BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';
import AddCaseForm from './AddCaseForm';
import { usePermission } from '../../hooks/userPermissions';
import Badge from '../shared/TailwindComponents/Badge';

// Import your shared Table component
import Table from '../shared/TailwindComponents/Table';

// Tailwind Heroicons
import Button from '../shared/TailwindComponents/Button';
import ApiLoader from '../ApiLoader';
import { PiBriefcase, PiGridFourLight, PiTableLight } from "react-icons/pi";
import { convertLongDateWithTranslation, convertShortDateWithTranslation } from '../shared/DateConverter';

const statusToColorMap: Record<string, BadgeColor> = {
  Draft: 'yellow',
  EngagementOngoing: 'blue',
  Active: 'blue',
  Completed: 'gray',
  Overdue: 'red',
  Closed: 'gray',
};

const Cases: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as any;

  // Redux store
  const allCases = useSelector((state: RootState) => state.casesM.cases || []);
  const userTypeName = getUserType();
  const totalClients = useSelector((state: RootState) => state.client.allClients.length);

  // Local state
  const [isCasesLoading, setIsCasesLoading] = useState(false);
  const [isAddingCase, setIsAddingCase] = useState(locationState?.isAddingCase || false);

  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');

  // Search, filters, sort
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('creation_desc');

  // Debounce reference for search
  const searchDebounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // Scroll handling for sticky header
  const caseListRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const shrinkThreshold = 150;
  const isShrunk = scrollTop >= shrinkThreshold;

  // Permission example
  const { hasAccess, isReadOnly, loading } = usePermission('case_management');

  // If you pass clientId / clientUserType from location
  const clientIdFromLocation = locationState?.clientId;
  const clientUserTypeFromLocation = locationState?.clientUserType;

  useEffect(() => {
    // Clear previous search debounce if any
    if (searchDebounceTimeout.current) {
      clearTimeout(searchDebounceTimeout.current);
    }

    const performFetch = () => {
      setIsCasesLoading(true);

      const { sortBy, sortOrder } = getSortParams(sortValue);

      dispatch(
        fetchCases({
          searchTerm: searchQuery,
          filters: selectedFilters,
          sortBy,
          sortOrder,
        })
      ).finally(() => {
        setIsCasesLoading(false);
      });
    };

    // Debounce search to avoid spamming the server
    searchDebounceTimeout.current = setTimeout(performFetch, 300);

    return () => {
      if (searchDebounceTimeout.current) {
        clearTimeout(searchDebounceTimeout.current);
      }
    };
  }, [searchQuery, selectedFilters, sortValue, dispatch]);

  // Helper to map sortValue -> { sortBy, sortOrder }
  const getSortParams = (value: string) => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (value.startsWith('creation')) {
      sortBy = 'creation';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('price')) {
      sortBy = 'price';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('last_interaction')) {
      sortBy = 'last_interaction';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('case_cycle_progression')) {
      sortBy = 'case_cycle_progression';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    }
    return { sortBy, sortOrder };
  };

  // Scroll event
  const handleScroll = () => {
    const scrollPosition = caseListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  // useEffect(() => {
  //   if (caseListRef.current) {
  //     caseListRef.current.addEventListener('scroll', handleScroll);
  //     return () => {
  //       caseListRef.current?.removeEventListener('scroll', handleScroll);
  //     };
  //   }
  // }, []);

  // FilterSearchBar callbacks
  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
    setSortOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        current: option.value === value,
      }))
    );
  };

  // ===== NEW: The function that toggles between Grid vs Table ====
  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'grid' ? 'table' : 'grid'));
  };

  // Handler for toggling the "Add Case" form
  const handleAddCaseClick = () => {
    setIsAddingCase(true);
  };
  const handleAddCaseClose = () => {
    setIsAddingCase(false);
  };

  const handleCaseClick = (caseItem: any) => {
    history.push(`/cases/${caseItem.CaseID}`);
  };

  // Define filters and sortOptions for the UI
  const filters = [
    {
      id: 'CaseStatus',
      name: 'Case Status',
      options: [
        { value: 'Draft', label: 'Draft' },
        { value: 'EngagementOngoing', label: 'Active' },
        { value: 'Completed', label: 'Completed' },
      ],
    },
  ];

  const [sortOptions, setSortOptions] = useState([
    { value: 'creation_asc', label: 'Creation (Oldest First)', current: true },
    { value: 'creation_desc', label: 'Creation (Newest First)', current: false },
    { value: 'price_asc', label: 'Price (Low to High)', current: false },
    { value: 'price_desc', label: 'Price (High to Low)', current: false },
    { value: 'last_interaction_asc', label: 'Last Interaction (Oldest First)', current: false },
    { value: 'last_interaction_desc', label: 'Last Interaction (Newest First)', current: false },
    // { value: 'case_cycle_progression_asc', label: 'Case Progression (Fewest Completed Steps)', current: false },
    // { value: 'case_cycle_progression_desc', label: 'Case Progression (Most Completed Steps)', current: false },
  ]);

  const statsData = [
    {
      name: 'Total Cases',
      stat: allCases.length.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
    {
      name: 'Total Clients',
      stat: totalClients.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
  ];

  const handleClearAllFilters = () => {
    setSelectedFilters({});
    setSearchQuery('');
    setSortValue('creation_asc');
  };

  const columns = useMemo(
    () => (userTypeName === 'IndividualClient' ? ['Case Name', 'Status', 'Created At', 'Lawyer Name'] : ['Case Name', 'Status', 'Created At', 'Client Name']),
    [userTypeName]
  );

  const tableData = useMemo(() => {
    const now = new Date();
    return allCases.map((caseItem: any) => {
      const createdDate = new Date(caseItem.created_at);
      const isNew = (now.getTime() - createdDate.getTime()) / 3600000 < 24;
      const createdAtBadge = (
        <Badge color="gray">
          {convertShortDateWithTranslation(new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'short', day: '2-digit' }).format(createdDate))}
        </Badge>
      );
      return {
        id: caseItem.CaseID,
        'Case Name': (
          <span className="flex items-center gap-2">
            {caseItem.CaseName || `Case ${caseItem.CaseID}`}
            {isNew && <Badge color="green">{t('New')}</Badge>}
          </span>
        ),
        Status: caseItem.case_status === 'EngagementOngoing' ? t('Active') : t(caseItem.case_status),
        'Created At': createdAtBadge,
        ...(userTypeName.includes('Law') && { 'Client Name': caseItem.ClientName || 'No Client' }),
        ...(!userTypeName.includes('Law') && { 'Lawyer Name': caseItem.LawyerName || 'No Lawyer' }),
      };
    });
  }, [allCases, t, userTypeName]);

  const gridData = useMemo(() => {
    return allCases.map((caseItem: any) => {
      const createdDate = new Date(caseItem.created_at);
      const isNew = (new Date().getTime() - createdDate.getTime()) / 3600000 < 24;
      const badges: BadgeItem[] = [
        {
          label: caseItem.case_status === 'EngagementOngoing' ? t('Active') : t(caseItem.case_status),
          color: statusToColorMap[caseItem.case_status] || 'gray',
        },
        {
          label: convertShortDateWithTranslation(new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'short', day: '2-digit' }).format(createdDate)),
          color: 'gray',
        },
        ...(userTypeName.includes('Law')
          ? [
            // {
            //   label: caseItem.ClientName || 'No Client' as string,
            //   color: caseItem.ClientName ? 'green' : 'red' as BadgeColor,
            // },
          ]
          : []),
      ];
      if (isNew) badges.push({ label: 'New', color: 'green' });

      const details = [
        { label: 'Case Type', value: t(caseItem.CaseType) || 'N/A' },
        { label: 'Client Name', value: caseItem.ClientName || 'No Client' },
        // { label: 'Organization', value: caseItem.OrganizationName || 'N/A' },
        ...(caseItem.MarketplaceData?.Deadline
          ? [
            {
              label: 'Deadline',
              value: convertLongDateWithTranslation(new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(
                new Date(caseItem.MarketplaceData.Deadline)
              )),
            },
          ]
          : []),
      ];

      return (
        <ListItem
          key={caseItem.CaseID}
          title={caseItem.CaseName || `Case ${caseItem.CaseID}`}
          subtitle={caseItem.AnonymizedDescription || ''}
          details={details}
          badges={badges}
          onClick={() => handleCaseClick(caseItem)}
          link={`/cases/${caseItem.CaseID}`}
        />
      );
    });
  }, [allCases, t, userTypeName]);
  return (
    <div className="h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      <div
        className={`
          ${isShrunk && !isAddingCase ? 'sticky top-[80px] z-20' : ''} 
        `}
      >
        {!isAddingCase && (
          <Header
            title={t('Cases')}
            subtitle={t('Manage your cases and track their progress.')}
            icon={<PiBriefcase className="text-primary-500" size={24} />}
            actionLabel={t('Add Case')}
            onActionClick={handleAddCaseClick}
          />
        )}
        {!isAddingCase && (
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
            sortValue={sortValue}
            onClearAllFilters={handleClearAllFilters}
            extraControls={
              <Button
                variant="tertiary"
                size="small"
                onClick={toggleViewMode}
                tooltip={viewMode === 'grid' ? t('Switch to Table View') : t('Switch to Grid View')}
                className="hover:bg-primary-100 dark:hover:bg-primary-700 transition-all duration-200"
              >
                {viewMode === 'grid' ? <PiTableLight className="h-5 w-5" /> : <PiGridFourLight className="h-5 w-5" />}
              </Button>
            }
          />
        )}
      </div>
      <div className="flex-grow overflow-y-auto h-full transition-all duration-300" ref={caseListRef}>
        {isAddingCase ? (
          <AddCaseForm
            setIsAddingCase={setIsAddingCase}
            onClose={handleAddCaseClose}
            clientId={locationState?.clientId}
            clientUserType={locationState?.clientUserType}
            setIsCaseLoading={setIsCasesLoading}
          />
        ) : isCasesLoading ? (
          <div className="flex justify-center items-center h-full">
            <ApiLoader />
          </div>
        ) : (
          <div className="p-4">
            {viewMode === 'grid' ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">{gridData}</div>
            ) : allCases.length > 0 ? (
              <Table columns={columns} data={tableData} onRowClick={(row) => history.push(`/cases/${row.id}`)} />
            ) : (
              <div className="flex justify-center items-center h-full">
                <Badge color="gray" className="text-lg">{t('No cases available')}</Badge>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cases;