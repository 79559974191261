// actions/caseActions.ts

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..'; // Adjust the path as needed
import { showFeedbackModal } from '../UserFeedbackActions';
import apis from '../../../utils/apis';
import { handleApiError } from '../utils/utils';
import { fetchCaseAnalysisData } from './caseAnalysisActions';

// Action Types
export const FETCH_CASES_REQUEST = 'FETCH_CASES_REQUEST';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_FAILURE = 'FETCH_CASES_FAILURE';
export const FETCH_CASE_REQUEST = 'FETCH_CASE_REQUEST';
export const FETCH_CASE_SUCCESS = 'FETCH_CASE_SUCCESS';
export const FETCH_CASE_FAILURE = 'FETCH_CASE_FAILURE';
export const GENERATE_DOCUMENT_REQUEST = 'GENERATE_DOCUMENT_REQUEST';
export const GENERATE_DOCUMENT_SUCCESS = 'GENERATE_DOCUMENT_SUCCESS';
export const GENERATE_DOCUMENT_FAILURE = 'GENERATE_DOCUMENT_FAILURE';
export const FETCH_MARKETPLACE_CASE_REQUEST = 'FETCH_MARKETPLACE_CASE_REQUEST';
export const FETCH_MARKETPLACE_CASE_SUCCESS = 'FETCH_MARKETPLACE_CASE_SUCCESS';
export const FETCH_MARKETPLACE_CASE_FAILURE = 'FETCH_MARKETPLACE_CASE_FAILURE';


// Action Creators
export const fetchCasesRequest = (): AnyAction => ({
  type: FETCH_CASES_REQUEST,
});

export const fetchCasesSuccess = (cases: any[]): AnyAction => ({
  type: FETCH_CASES_SUCCESS,
  payload: cases,
});

export const fetchCasesFailure = (error: string): AnyAction => ({
  type: FETCH_CASES_FAILURE,
  payload: error,
});

export const fetchCaseRequest = (): AnyAction => ({
    type: FETCH_CASE_REQUEST,
  });
  
  export const fetchCaseSuccess = (caseData: any): AnyAction => ({
    type: FETCH_CASE_SUCCESS,
    payload: caseData,
  });
  
  export const fetchCaseFailure = (error: string): AnyAction => ({
    type: FETCH_CASE_FAILURE,
    payload: error,
  });

  export const generateDocumentRequest = (): AnyAction => ({
    type: GENERATE_DOCUMENT_REQUEST,
  });
  
  export const generateDocumentSuccess = (data: any): AnyAction => ({
    type: GENERATE_DOCUMENT_SUCCESS,
    payload: data,
  });
  
  export const generateDocumentFailure = (error: string): AnyAction => ({
    type: GENERATE_DOCUMENT_FAILURE,
    payload: error,
  });

  export const fetchMarketplaceCaseRequest = (): AnyAction => ({
    type: FETCH_MARKETPLACE_CASE_REQUEST,
  });
  
  export const fetchMarketplaceCaseSuccess = (caseData: any): AnyAction => ({
    type: FETCH_MARKETPLACE_CASE_SUCCESS,
    payload: caseData,
  });
  
  export const fetchMarketplaceCaseFailure = (error: string): AnyAction => ({
    type: FETCH_MARKETPLACE_CASE_FAILURE,
    payload: error,
  });

// Thunk Action to Fetch Cases
interface FetchCasesParams {
  searchTerm?: string;
  filters?: { [key: string]: string[] };
  sortBy?: string;
  sortOrder?: string;
}

export const fetchCases = (params: FetchCasesParams = {}) => {
  return async (dispatch: any) => {
    dispatch(fetchCasesRequest());

    const { searchTerm = '', filters = {}, sortBy = '', sortOrder = 'asc' } = params;
    const filtersJson = JSON.stringify(filters);

    try {
      const response = await apis.get('/cases/view', {
        params: {
          searchTerm,
          filters: filtersJson,
          sortBy,
          sortOrder
        }
      });

      if (response && response.status === 200) {
        dispatch(fetchCasesSuccess(response.data.cases));
      } else {
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
    }
  };
};

// Thunk Action to Fetch Single Case
export const fetchCaseById = (caseId: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      dispatch(fetchCaseRequest());
      try {
        const response = await apis({
          method: 'POST',
          url: '/cases/get_case',
          data: { CaseID: caseId },
        });
  
        if (response && response.status === 200) {
          const caseData = response.data.case;
          dispatch(fetchCaseSuccess(caseData));
          return response.data.case;
        } else {
          handleApiError(response, dispatch);
        }
      } catch (error: any) {
        handleApiError(error, dispatch);
      }
    };
  };

  export const generateDocument = (caseId: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      dispatch(generateDocumentRequest());
  
      try {
        const response = await apis({
          method: 'POST',
          url: '/cases/generate_document',
          data: { CaseID: caseId },
        });
  
        if(response && response.status === 200){
          
          dispatch(generateDocumentSuccess(response.data));
        }else{
          handleApiError(response, dispatch);
        }
      } catch (error: any) {
        handleApiError(error, dispatch);
      }
    };
  };

  export const fetchMarketplaceCaseById = (caseId: string) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      dispatch(fetchMarketplaceCaseRequest());
      try {
        const response = await apis({
          method: 'POST',
          url: '/marketplace/get_case_details',
          data: { CaseID: caseId },
        });
  
        if (response && response.status === 200) {
          const caseData = response.data.case_details;
          dispatch(fetchMarketplaceCaseSuccess(caseData));
        } else {
          handleApiError(response, dispatch);
        }
      } catch (error: any) {
        handleApiError(error, dispatch);
      }
    };
  };


  export const fetchCaseSummary = (caseId: string) => {
    return async (dispatch: any) => {
      try {
        const response = await apis.post('/cases/get_case_summary', { CaseID: caseId });
        if (response && response.status === 200) {
          return response.data; 
        }else{
          handleApiError(response, dispatch);
        }
      } catch (error: any) {
        handleApiError(error, dispatch);
        return null;
      }
    };
  };
  
  export const fetchCaseCycle = (caseId: string) => {
    return async (dispatch: any) => {
      try {
        const response = await apis.post('/cases/get_case_cycle', { CaseID: caseId });
        if (response && response.status === 200) {
          return response.data; 
        }else{
          handleApiError(response, dispatch);
        }
      } catch (error: any) {
        handleApiError(error, dispatch);
        return null;
      }
    };
  };
  

  export const getCaseFinancialData = (caseId: string) => {
    return async (dispatch: any) => {
      try {
        const response = await apis.post('/cases/get_financial_data', { CaseID: caseId });
        if (response && response.status === 200) {
          return response.data.summary; 
        }else{
          handleApiError(response, dispatch);
        }
      } catch (error: any) {
        handleApiError(error, dispatch);
        return null;
      }
    };
  };

export const editCase = (caseData: any) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: 'EDIT_CASE_REQUEST' });
    try {
      const response = await apis({
        method: 'POST',
        url: '/cases/edit',
        data: caseData,
      });

      if (response && response.status === 200) {
        dispatch({ type: 'EDIT_CASE_SUCCESS', payload: response.data });
        dispatch(showFeedbackModal({
          showModal: true,
          message: response.data.message,
          modalType: 'success',
          duration: 3000,
        }));
        // Optionally refetch the case details
        dispatch(fetchCaseAnalysisData(caseData.CaseID));
      } else {
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
    }
  };
};

export const deleteCase = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: 'DELETE_CASE_REQUEST' });
    try {
      const response = await apis({
        method: 'POST',
        url: '/cases/delete',
        data: { CaseID: caseId },
      });

      if (response && response.status === 200) {
        dispatch({ type: 'DELETE_CASE_SUCCESS', payload: caseId });
        // Optionally refetch the list of cases
        dispatch(showFeedbackModal({
          showModal: true,
          message: response.data.message,
          modalType: 'success',
          duration: 3000,
        }));
        dispatch(fetchCases());
      } else {
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
    }
  };
};

export const fetchUnassignedCases = () => {
  return async (dispatch: any) => {
    try {
      dispatch(fetchCasesRequest());


      const filters = JSON.stringify({ missingClient: ["true"] });
      const response = await apis.get('/cases/view', {
        params: {
          filters,
          searchTerm: '',  
          sortBy: '',
          sortOrder: 'asc'
        }
      });

      if (response && response.status === 200) {
        dispatch({
          type: 'FETCH_UNASSIGNED_CASES_SUCCESS',
          payload: response.data.cases
        });
      } else {
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
    }
  };
};