import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { getUserID } from '../../store/actions/ChatAction';
import { getWhiteLabelSettings, updateWhiteLabelSettings } from '../../store/actions/organization/WhiteLabelActions';
import Button from '../shared/TailwindComponents/Button';
import SingleFileInput from '../shared/TailwindComponents/SingleFileInput';
import { motion } from 'framer-motion';
import { isBetaEnv } from '../../utils/authUtils';

interface CustomColors {
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
}

interface BrandingSettingsProps {
  customColors: CustomColors;
  sidePanelLogoLightUrl: string | null;
  sidePanelLogoDarkUrl: string | null;
  onUpdate: () => void;
}

const BrandingSettings: React.FC<BrandingSettingsProps> = ({
  customColors,
  sidePanelLogoLightUrl,
  sidePanelLogoDarkUrl,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = getUserID();

  useEffect(() => {
    dispatch(getWhiteLabelSettings(userId));
  }, [dispatch, userId]);

  const formik = useFormik({
    initialValues: {
      CustomColors: customColors || { primaryColor: '#000000', secondaryColor: '#000000', accentColor: '#000000' },
      SidePanelLogoLight: null as File | null,
      SidePanelLogoDark: null as File | null,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('UserID', userId);
      if (values.CustomColors) formData.append('CustomColors', JSON.stringify(values.CustomColors));
      if (values.SidePanelLogoLight) formData.append('SidePanelLogoLight', values.SidePanelLogoLight);
      if (values.SidePanelLogoDark) formData.append('SidePanelLogoDark', values.SidePanelLogoDark);
      await dispatch(updateWhiteLabelSettings(formData));
      onUpdate();
      formik.resetForm();
    },
  });

  return (
    <div className="relative">
      {/* Overlay for Beta Environment */}
      {isBetaEnv && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-10">
          <p className="text-white text-lg font-semibold">{t('Coming Soon')}</p>
        </div>
      )}

      <form onSubmit={formik.handleSubmit} className="space-y-6" style={{ pointerEvents: isBetaEnv ? 'none' : 'auto' }}>
        {/* Header and Sub-Description */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">{t('Branding')}</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {t('Customize the appearance and branding of your platform.')}
          </p>
        </div>
        <hr className="my-4 border-gray-200 dark:border-gray-700" />

        {/* Side Panel Logo (Light Mode) */}
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
          <SingleFileInput
            label={t('Side Panel Logo (Light Mode)')}
            accept="image/*"
            file={formik.values.SidePanelLogoLight}
            setFile={(file) => formik.setFieldValue('SidePanelLogoLight', file)}
            existingFileUrl={sidePanelLogoLightUrl as string}
            desc={t('Upload or change the logo for light mode side panels.')}
            disabled={isBetaEnv}
          />
        </motion.div>
        <hr className="my-4 border-gray-200 dark:border-gray-700" />

        {/* Side Panel Logo (Dark Mode) */}
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>
          <SingleFileInput
            label={t('Side Panel Logo (Dark Mode)')}
            accept="image/*"
            file={formik.values.SidePanelLogoDark}
            setFile={(file) => formik.setFieldValue('SidePanelLogoDark', file)}
            existingFileUrl={sidePanelLogoDarkUrl as string}
            desc={t('Upload or change the logo for dark mode side panels.')}
            disabled={isBetaEnv}
          />
        </motion.div>
        <hr className="my-4 border-gray-200 dark:border-gray-700" />

        {/* Custom Colors */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            {t('Custom Colors')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-400 mb-4">
            {t('Define the custom colors that reflect your brand.')}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {['primaryColor', 'secondaryColor', 'accentColor'].map((color, index) => (
              <motion.div
                key={color}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
                  {t(color.replace(/([A-Z])/g, ' $1').trim())}
                </label>
                <div className="relative inline-block">
                  <input
                    type="color"
                    value={(formik.values.CustomColors as any)[color]}
                    onChange={(e) => formik.setFieldValue(`CustomColors.${color}`, e.target.value)}
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    style={{ width: '40px', height: '40px' }}
                  />
                  <div
                    className="w-10 h-10 rounded-full border border-gray-300 cursor-pointer"
                    style={{ backgroundColor: (formik.values.CustomColors as any)[color] }}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
        <hr className="my-4 border-gray-200 dark:border-gray-700" />

        {/* Save Button */}
        <div className="mt-8 flex gap-x-4 justify-end">
          <Button size="small" variant="primary" type="submit" disabled={!formik.dirty || isBetaEnv}>
            {t('Save Branding Settings')}
          </Button>
        </div>
      </form>
    </div>
  );
};

// Parent component remains unchanged
const BrandingSettingsWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const whiteLabelSettings = useSelector((state: RootState) => state.whiteLabel.settings);

  const handleUpdate = () => {
    dispatch(getWhiteLabelSettings(getUserID()));
  };

  return (
    <BrandingSettings
      customColors={whiteLabelSettings?.CustomColors || { primaryColor: '#000000', secondaryColor: '#000000', accentColor: '#000000' }}
      sidePanelLogoLightUrl={whiteLabelSettings?.SidePanelLogoLight || null}
      sidePanelLogoDarkUrl={whiteLabelSettings?.SidePanelLogoDark || null}
      onUpdate={handleUpdate}
    />
  );
};

export default BrandingSettingsWrapper;