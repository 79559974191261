import { useState, useCallback } from 'react';
import { useAppDispatch } from '../store';
import { fetchLinkPreview } from '../store/actions/linkPreviewActions';

interface LinkPreviewData {
  url: string;
  title: string;
  description: string;
  imageUrl: string;
  siteName: string;
  iconUrl: string;
}

interface LinkPreviewResult {
  data?: LinkPreviewData;
  error?: string;
  isLoading: boolean;
  fetchPreview: (url: string) => void;
}

export default function useLinkPreview(): LinkPreviewResult {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<LinkPreviewData | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchPreview = useCallback(async (url: string) => {
    setIsLoading(true);
    setError(undefined);
    setData(undefined);

    try {
      const result = await dispatch(fetchLinkPreview(url)) as LinkPreviewData;
      setData(result);
    } catch (err: any) {
      setError(err.message ?? String(err));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  return {
    data,
    error,
    isLoading,
    fetchPreview,
  };
}
