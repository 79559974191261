import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import { handleApiError } from "./utils/utils";



export const getLicenseDetails = (license_id:string, country: string) => {
    return async (dispatch: any) => {
        try {

            const resp = await apis({
                method: 'GET',
                url: `/licenses/one?licenseID=${license_id}&country=${country}`,
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: resp.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
                return resp.data.license

            } else {
                 handleApiError(resp, dispatch)
                 return null
            }
        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}
