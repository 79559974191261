import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import dayjs from 'dayjs';
import { getUserType } from '../../store/actions/ChatAction';
import { completeTask, deleteTask, fetchAllTasks } from '../../store/actions/TasksActions';
import Badge from '../shared/TailwindComponents/Badge';
import Button from '../shared/TailwindComponents/Button';
import { Task } from './types';
import AddTask from './AddTaskComponent';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import { useHistory } from 'react-router-dom';
import { usePermission } from '../../hooks/userPermissions';
import CalendarView from './CalendarView';
import ToggleWide from '../shared/TailwindComponents/ToggleWide';
import ApiLoader from '../ApiLoader';
import {
  PiBriefcaseLight, // Updated to light version, no background
  PiCalendarDotsLight,
  PiClockLight, // Updated to light version
  PiEnvelopeSimpleLight, // Updated to light version
  PiGridFourLight,
  PiTableLight,
} from 'react-icons/pi';
import TaskTable from './TaskTableView';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';
import { Tooltip } from '@mui/material';
import { FiLock } from 'react-icons/fi';

const TaskDeadlineTracker: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const employees = useSelector((state: RootState) => state.employees.employees || []);
  const userType = getUserType();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const tasks = useSelector((state: RootState) => state.tasks.tasks || []);
  const [isTasksLoading, setIsTasksLoading] = useState(true);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const history = useHistory();
  // const { isReadOnly } = usePermission('dashboard');

  const [viewMode, setViewMode] = useState<'list' | 'calendar' | 'table'>('list');
  const [calendarViewMode, setCalendarViewMode] = useState<'day' | 'week' | 'month' | 'year'>('month');
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [calendarStart, setCalendarStart] = useState<Date | null>(null);
  const [calendarEnd, setCalendarEnd] = useState<Date | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({ Status: ['Active'] });
  const [searchQuery, setSearchQuery] = useState('');
  const [sortValue, setSortValue] = useState('deadline_asc');
  const { subscriptionActive, loading } = useSelector((state: RootState) => state.subscription);

  const taskFilters = [
    {
      id: 'Status',
      name: 'Status',
      options: [
        { value: 'Active', label: 'Active' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Deleted', label: 'Deleted' },
      ],
    },
    {
      id: 'TieType',
      name: 'Tie Type',
      options: [
        { value: 'Case', label: 'Case Tied' },
        { value: 'Project', label: 'Project Tied' },
        { value: 'Team', label: 'Team Tied' },
      ],
    },
  ];

  const taskSortOptions = [
    { value: 'deadline_asc', label: 'Deadline Ascending', current: true },
    { value: 'deadline_desc', label: 'Deadline Descending', current: false },
    { value: 'title_asc', label: 'Title Ascending', current: false },
    { value: 'title_desc', label: 'Title Descending', current: false },
  ];

  const handleOpenAddTask = () => setIsAddingTask(true);
  const handleCancelAddTask = () => setIsAddingTask(false);
  const handleTaskAdded = () => {
    setIsAddingTask(false);
    refetchTasks();
  };

  const handleCompleteTask = async (taskId: string) => {
    try {
      await dispatch(completeTask(taskId));
      refetchTasks();
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      await dispatch(deleteTask(taskId));
      await refetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleDetails = (taskId: string) => {
    dispatch(fetchAllEmployees());
    history.push(`/tasks/${taskId}`);
  };

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({ ...prev, [filterName]: selectedValues }));
  };

  const handleSortChange = (value: string) => setSortValue(value);
  const handleSearchChange = (query: string) => setSearchQuery(query);

  const queryParams = useMemo(() => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (sortValue === 'deadline_asc') {
      sortBy = 'deadline';
      sortOrder = 'asc';
    } else if (sortValue === 'deadline_desc') {
      sortBy = 'deadline';
      sortOrder = 'desc';
    } else if (sortValue === 'title_asc') {
      sortBy = 'title';
      sortOrder = 'asc';
    } else if (sortValue === 'title_desc') {
      sortBy = 'title';
      sortOrder = 'desc';
    }

    const filters: { [key: string]: any } = {};
    if (selectedFilters.Status?.length > 0) filters.Status = selectedFilters.Status;
    if (selectedFilters.TieType?.length > 0) filters.TieType = selectedFilters.TieType;
    if (viewMode === 'calendar' && calendarStart && calendarEnd) {
      filters.ViewMode = 'calendar';
      filters.StartDate = calendarStart.toISOString();
      filters.EndDate = calendarEnd.toISOString();
    }

    return { searchTerm: searchQuery, filters, sortBy, sortOrder };
  }, [sortValue, selectedFilters, searchQuery, viewMode, calendarStart, calendarEnd]);

  const refetchTasks = useCallback(async () => {
    setIsTasksLoading(true);
    const { searchTerm, filters, sortBy, sortOrder } = queryParams;
    await dispatch(fetchAllTasks({ searchTerm, filters, sortBy, sortOrder }));
    setIsTasksLoading(false);
  }, [dispatch, queryParams]);

  useEffect(() => {
    refetchTasks();
  }, [refetchTasks]);

  const displayedTasks = tasks || [];

  const handleCalendarRangeChange = (start: Date, end: Date) => {
    if ((!calendarStart || calendarStart.getTime() !== start.getTime()) || (!calendarEnd || calendarEnd.getTime() !== end.getTime())) {
      setCalendarStart(start);
      setCalendarEnd(end);
    }
  };

  const handleCalendarEventSelect = (taskId: string) => handleDetails(taskId);
  const handleCalendarViewModeChange = (mode: 'day' | 'week' | 'month' | 'year') => setCalendarViewMode(mode);
  const handleDateChange = (newDate: dayjs.Dayjs) => setCurrentDate(newDate);
  const handleAddTask = () => handleOpenAddTask();

  const getInitials = (userId: string) => {
    const employee = employees.find((emp) => emp.UserID === userId);
    return employee?.FirstName && employee?.LastName ? `${employee.FirstName[0]}${employee.LastName[0]}` : 'N/A';
  };

  const getDaysRemaining = (deadline: string) => Math.max(0, dayjs(deadline).diff(dayjs(), 'day'));

  if (loading) {
    return (
      <ApiLoader />
    )
  }

  return (
    <div className="p-2 mx-4 flex flex-col h-[calc(100vh-110px)]">
      {!isAddingTask && (
        <>
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 dark:bg-gray-800">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-3">
                <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">{t('Tasks')}</h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">{t('Track your tasks and deadlines.')}</p>
              </div>
              <div className="flex gap-2 items-center">
                {/* <Button variant="primary" size="small" onClick={handleOpenAddTask}>
                  {t('Add Task')}
                </Button> */}

                <Button
                  variant="primary"
                  size="small"
                  onClick={handleOpenAddTask}
                  className="relative"
                  disabled={!subscriptionActive}
                >
                  {t('Add Task')}

                  {!subscriptionActive && (
                    <FiLock
                      className="absolute -top-2 -right-2 text-yellow-500 bg-white dark:bg-gray-800 rounded-full shadow-md p-[2px]"
                      size={16}
                      title="Premium Feature"
                    />
                  )}
                </Button>
                <Button
                  variant={viewMode === 'list' ? 'tertiary' : 'neutral'}
                  onClick={() => setViewMode('list')}
                  tooltip={t('Switch to grid')}
                  size="small"
                >
                  <PiGridFourLight className="h-5 w-5" />
                </Button>
                <Button
                  size="small"
                  variant={viewMode === 'calendar' ? 'tertiary' : 'neutral'}
                  onClick={() => setViewMode('calendar')}
                  tooltip={t('Switch to calendar')}
                >
                  <PiCalendarDotsLight className="w-5 h-5" />
                </Button>
                <Button
                  size="small"
                  variant={viewMode === 'table' ? 'tertiary' : 'neutral'}
                  onClick={() => setViewMode('table')}
                  tooltip={t('Switch to table')}
                >
                  <PiTableLight className="h-5 w-5" />
                </Button>
              </div>
            </div>
          </div>

          {(viewMode === 'list' || viewMode === 'table') && (
            <section className="mb-5">
              <FilterSearchBar
                filters={taskFilters}
                sortOptions={taskSortOptions}
                selectedFilters={selectedFilters}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
                onSearchChange={handleSearchChange}
                sortValue={sortValue}
              />
            </section>
          )}
        </>
      )}

      {isAddingTask ? (
        <AddTask onCancel={handleCancelAddTask} onAdd={handleTaskAdded} />
      ) : (
        <div className="flex-1 overflow-hidden">
          {isTasksLoading ? (
            <ApiLoader />
          ) : viewMode === 'list' ? (
            displayedTasks.length > 0 ? (
              <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 overflow-auto transition-all duration-300 animate-fade-in-up h-full">
                {displayedTasks.map((task: Task) => {
                  const daysRemaining = getDaysRemaining(task.TaskDeadline);
                  const isUrgent = daysRemaining <= 3 && task.Status === 'Active';

                  return (
                    <li
                      key={task.TaskID}
                      className={`bg-info-50 dark:bg-info-700 p-4 rounded-md shadow-md cursor-pointer min-h-[200px] h-[200px] max-h-[300px] flex flex-col justify-between hover:bg-info-100 dark:hover:bg-info-800 transition-bg duration-300 ${task.Status !== 'Active' ? 'opacity-50' : ''
                        }`}
                      onClick={() => handleDetails(task.TaskID)}
                    >
                      {/* Header */}
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center">
                          <PiBriefcaseLight className="h-6 w-6 text-primary-500 dark:text-primary-300" />
                          <Badge color={task.Status === 'Active' ? 'blue' : task.Status === 'Completed' ? 'green' : 'red'} className="ml-2">
                            {t(`${task.Status}`)}
                          </Badge>
                        </div>
                        {task.Priority && (
                          <Badge color={task.Priority === 'High' ? 'red' : task.Priority === 'Medium' ? 'yellow' : 'green'}>
                            {task.Priority}
                          </Badge>
                        )}
                      </div>

                      {/* Title and Description */}
                      <div className="flex-1 overflow-hidden">
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-white truncate">{task.Title}</h3>
                        <p className="text-sm text-gray-600 dark:text-gray-300 mt-1 line-clamp-2">{task.Description}</p>
                      </div>

                      {/* Footer with Badges and Metadata */}
                      <div className="mt-4 flex flex-wrap justify-between items-center gap-2">
                        <div className="flex flex-wrap gap-2">
                          <Badge color="purple" className="rounded-full">{getInitials(task.AssignedBy as string)}</Badge>
                          {task.AssignedByUserType && <Badge color="indigo">{task.AssignedByUserType}</Badge>}
                          {isUrgent && <Badge color="red">{t("Urgent")}</Badge>}
                        </div>
                        <div className="flex items-center space-x-4 text-gray-500 dark:text-gray-400">
                          <div className="flex items-center">
                            <PiEnvelopeSimpleLight className="h-4 w-4 mr-1" />
                            <span className="text-sm">{task.Comment ? task.Comment.split(' ').length : 0}</span>
                          </div>
                          <Tooltip title={t('Days remaining')}>
                            <div className="flex items-center">
                              <PiClockLight className="h-4 w-4 mr-1" />
                              <span className="text-sm">{daysRemaining}</span>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="w-full justify-center items-center flex h-full">
                <Badge color="gray">{t('No Tasks')}</Badge>
              </div>
            )
          ) : viewMode === 'calendar' ? (
            <CalendarView
              tasks={displayedTasks}
              onRangeChange={handleCalendarRangeChange}
              onSelectEvent={handleCalendarEventSelect}
              calendarViewMode={calendarViewMode}
              onCalendarViewModeChange={handleCalendarViewModeChange}
              onAddTask={handleAddTask}
              currentDate={currentDate}
              onDateChange={handleDateChange}
            />
          ) : (
            <TaskTable
              tasks={displayedTasks}
              onRowClick={handleDetails}
              onDeleteTask={handleDeleteTask}
              onCompleteTask={handleCompleteTask}
              userType={userType}
              userID={userInfo.UserID}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TaskDeadlineTracker;