import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaMoneyBillWave } from "react-icons/fa";
import { useAppDispatch } from "../../../store";
import { getAllTransactions } from "../../../store/actions/SubscriptionActions";
import Table from "../TailwindComponents/Table";
import Badge from "../TailwindComponents/Badge";
import { ShowTransactionDetailsPanel } from "../../../store/actions/sidePanelActions";
import ApiLoader from "../../ApiLoader";
import { useTranslation } from "react-i18next";
import { PiMoneyLight } from "react-icons/pi";

interface Transaction {
    AmountPaid: number;
    InvoiceID: string;
    PaymentDescription: string;
    PaymentID: string;
    Receiver: string;
    Status: string;
    TransactionID: string;
}


const PaymentTransactions: React.FC = () => {
    const dispatch = useAppDispatch()
    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [loading, setIsLoading] = useState(false)
    const {t} = useTranslation()

    const fetchSubscriptionDetails = useCallback(async () => {
        setIsLoading(true)
        const resp = await dispatch(getAllTransactions())
        if (resp) {
            setTransactions(resp)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }

    }, [dispatch])

    useEffect(() => {
        fetchSubscriptionDetails()
    }, [fetchSubscriptionDetails])

    const columns = useMemo(() => {
        // Example columns. (You can rename them or reorder as you like)
        return ["Transaction ID", "Receiver", "Amount Paid", "Description", "Status", "Invoice"]
    }, []);

    // Prepare Data for Table
    const tableData = transactions.map((txn) => ({
        "Transaction ID": `txn_${txn.TransactionID}`,
        Receiver: txn.Receiver,
        "Amount Paid": txn.AmountPaid,
        Description: txn.PaymentDescription,
        Status: t(txn.Status),
        Invoice: txn.InvoiceID,
        TransactionID: txn.TransactionID
    }));

    if (loading) {
        return (
            <ApiLoader />
        )

    }


    return (
        <div className="h-full flex flex-col overflow-hidden px-4 mt-4">
            <h2 className="text-xl font-medium text-gray-900 dark:text-white mb-4 flex items-center gap-2">
                <PiMoneyLight size={24} className="text-green-500" /> {t("Billing History")}
            </h2>

            {transactions.length > 0 ? (
                <Table
                    columns={columns}
                    data={tableData}
                    onRowClick={(row) => dispatch(ShowTransactionDetailsPanel(row.TransactionID))}
                />
            ) : (
                <div className="flex justify-center items-center">
                    <Badge color="gray">{t("No transactions available.")}</Badge>
                </div>
            )}
        </div>
    );
};

export default PaymentTransactions;
