import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/TailwindComponents/Button';  // Or wherever your Button resides
import Badge from '../../shared/TailwindComponents/Badge';

interface Props {
  caseData: any;
  onRequestAccess?: () => void; // Callback for "Request Full Access"
}

const EmployeeCaseDetailPanel: React.FC<Props> = ({ caseData, onRequestAccess }) => {
  const { t } = useTranslation();

  if (!caseData) {
    return (
      <div className="p-4 text-gray-700 dark:text-gray-300">
        {t('No case data found.')}
      </div>
    );
  }

  // Example: handle the "Request Access" click
  const handleRequestAccess = () => {
    if (onRequestAccess) {
      onRequestAccess();
    } else {
      // If no callback passed, do something default or show a message
    }
  };

  // Prepare progress badges, if you want to show each step
  const progressArray = caseData?.progress || [];
  
  return (
    <div className="bg-white dark:bg-neutral-900 p-4 rounded shadow-md text-gray-700 dark:text-gray-200 h-full flex flex-col justify-between">
      {/* TOP SECTION */}
      <div>
        {/* CASE TITLE */}
        <h2 className="text-2xl font-bold mb-3">
          {caseData.CaseName || `${t('Case')} ${caseData.CaseID}`}
        </h2>

        {/* CASE TYPE & STATUS */}
        <div className="flex items-center gap-2 flex-wrap mb-4">
          {caseData.CaseType && (
            <Badge color="blue">
              {t(caseData.CaseType)}
            </Badge>
          )}
          {caseData.case_status && (
            <Badge color={caseData.case_status === 'open' ? 'green' : 'red'}>
              {t(caseData.case_status)}
            </Badge>
          )}
        </div>

        {/* BASIC INFO */}
        <div className="space-y-2 text-sm leading-relaxed">
          {/* Created At */}
          {caseData.created_at && (
            <div className="flex items-center gap-1">
              <span className="font-semibold">{t('Created')}</span>
              <span className="text-gray-600 dark:text-gray-300">
                {new Date(caseData.created_at).toLocaleString()}
              </span>
            </div>
          )}

          {/* Lawyer Hourly Rate (if relevant) */}
          {caseData.LawyerHourlyRate && (
            <div className="flex items-center gap-1">
              <span className="font-semibold">{t('Lawyer Rate')}</span>
              <span>
                ${caseData.LawyerHourlyRate.toFixed(2)}/hr
              </span>
            </div>
          )}

          {/* Client Name */}
          {caseData.ClientName && (
            <div className="flex items-center gap-1">
              <span className="font-semibold">{t('Client')}</span>
              <span>{caseData.ClientName}</span>
            </div>
          )}
        </div>

        {/* PROGRESS STEPS (if you want to show them) */}
        {progressArray.length > 0 && (
          <div className="mt-4">
            <h4 className="text-md font-semibold mb-2">
              {t('Case Progress')}
            </h4>
            <ul className="space-y-1">
              {progressArray.map((step: any, idx: number) => {
                const stepColor = step.status === 'Pending' ? 'gray' : 'green';
                return (
                  <li key={idx} className="flex items-center gap-2">
                    <Badge color={stepColor}>
                      {t(step.step)}
                    </Badge>
                    <span className="text-sm text-gray-600 dark:text-gray-300">
                      {t(step.status)}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {/* FIELDS / ISSUE DESCRIPTION */}
        {caseData.fields?.issue_description && (
          <div className="mt-4">
            <h4 className="text-md font-semibold mb-1">
              {t('Issue Description')}
            </h4>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              {caseData.fields.issue_description}
            </p>
          </div>
        )}

      </div>

      {/* BOTTOM SECTION: "Request Full Access" BUTTON */}
      <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
        <Button
          variant="primary"
          size="medium"
          className="w-full"
          onClick={handleRequestAccess}
        >
          {t('Request Full Access')}
        </Button>
      </div>
    </div>
  );
};

export default EmployeeCaseDetailPanel;
