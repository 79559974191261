import React, { ReactElement } from 'react';
import Button from './Button';
import { useTranslation } from 'react-i18next';

type Action = {
  label: string;
  onClick: () => void;
};

type HeaderProps = {
  title: string;
  subtitle?: string;
  actionLabel?: string;
  onActionClick?: () => void;
  additionalActions?: Action[];
  isReadOnly?: boolean 
  isAddingCase?: boolean;
  icon?: ReactElement;
};

const Header: React.FC<HeaderProps> = ({ title, subtitle, actionLabel, onActionClick, additionalActions,isReadOnly, isAddingCase, icon }) => {
  const {t} = useTranslation()
  return (
    <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 dark:bg-gray-800">
      <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-4">
          <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">{t(title)}</h3>
          {subtitle && (
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              {t(subtitle)}
            </p>
          )}
        </div>
        <div className="ml-4 mt-4 flex-shrink-0 flex space-x-2">
          {additionalActions && additionalActions.map((action, index) => (
            <Button key={index} variant='secondary' onClick={action.onClick} size='small'>
              <span>{t(action.label)}</span>
            </Button>
          ))}
          {actionLabel && onActionClick && (
            <Button disabled={isReadOnly || isAddingCase} variant='primary' size='small' onClick={onActionClick}>
              {t(actionLabel)}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
