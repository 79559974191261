import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';

// Redux actions
import {
  createFolder,
  createSubFolder,
  deleteFile,
  deleteFolder,
  fetchStructure,
  fetchSubFolderAndFiles,
  renameFolder
} from '../../store/actions/FolderAction';
import {
  fetchRecommendedDocuments,
  updateDocumentOrder,
  updateFolderOrder
} from '../../store/actions/DocumentsAction';
import { hideModal, showCreateFolderModal, showModal } from '../../store/actions/modalActions';

// Third-party
import { Menu, Transition } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroller';

// React Router
import { useHistory } from 'react-router-dom';

// Components & Contexts
import { LayoutContext } from '../../contexts/LayoutContext';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import WordEditor from '../Documents/WordEditor';
import NewFile from './NewFile';
import { usePermission } from '../../hooks/userPermissions';

// Icons
import {
  PiBriefcaseLight,
  PiFileLight,
  PiFileCloudLight,
  PiFileMagnifyingGlassLight,
  PiFilePlusLight,
  PiFilesLight,
  PiFileTextLight,
  PiFolderLight,
  PiFolderOpenLight,
  PiFolderPlusLight,
  PiFolderSimpleUserLight,
  PiFolderSimpleStarLight,
  PiGridNineLight,
  PiMagicWandLight,
  PiPlusLight,
  PiTableLight
} from 'react-icons/pi';

// Types
import { FileProps, FolderProps } from '../Documents/types';
import ApiLoader from '../ApiLoader';
import { convertLongDateWithTranslation } from '../shared/DateConverter';

/*---------------------------------------------
   SKELETON COMPONENTS FOR LOADING STATES
---------------------------------------------*/
const SkeletonGridItem: React.FC = () => (
  <div
    className="relative flex flex-col items-center p-3 bg-gray-100 dark:bg-gray-700 
               rounded-lg shadow-sm border border-gray-200 dark:border-gray-700
               h-[120px] w-full"
  >
    {/* Circle (icon placeholder) */}
    <div className="w-10 h-10 rounded-full bg-gray-300 dark:bg-gray-600 mb-3" />

    {/* Rectangle (title line) */}
    {/* <div className="w-3/4 h-3 rounded bg-gray-300 dark:bg-gray-600 mb-2" /> */}

    {/* Smaller line for subtitle */}
    <div className="w-1/2 h-3 rounded bg-gray-300 dark:bg-gray-600" />
  </div>
);

export const SkeletonTableRow: React.FC = () => (
  <tr className="animate-pulse bg-gray-100 dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 w-full" >
    {/* One <td> spanning all columns, then you can adjust the internal layout */}
    <td className="px-4 py-3" colSpan={3}>
      <div className="flex items-center space-x-3">
        {/* Circle placeholder (e.g., for an icon) */}
        <div className="w-7 h-7 rounded-full bg-gray-300 dark:bg-gray-600" />

        {/* A wide line (simulating main text) */}
        <div className="flex-1 h-3 rounded bg-gray-300 dark:bg-gray-600" />

        {/* A shorter line (e.g., for secondary text or date) */}
        <div className="w-16 h-3 rounded bg-gray-300 dark:bg-gray-600" />
      </div>
    </td>
  </tr>
);


/*---------------------------------------------
   ICON HELPERS
---------------------------------------------*/
export function getFileIconForGrid(file: FileProps, w: string, h: string) {
  const category = file.FileCategory?.toLowerCase() || '';
  if (category === 'general') return <PiFileLight className={`${w} ${h} text-green-500`} />;
  if (category === 'contract') return <PiFileTextLight className={`${w} ${h} text-red-500`} />;
  if (category === 'legal document') return <PiFileMagnifyingGlassLight className={`${w} ${h} text-blue-500`} />;
  if (category === 'template') return <PiFileCloudLight className={`${w} ${h} text-purple-500`} />;
  return <PiFileLight className={`${w} ${h} text-gray-500`} />;
}

export function getFolderIcon(folder: FolderProps, w: string, h: string) {
  const name = folder.FolderName?.toLowerCase() || '';
  if (name === 'clients') return <PiFolderSimpleUserLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
  if (name === 'cases') return <PiBriefcaseLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
  if (name.match(/[a-z]+_[a-z]+/i)) return <PiFolderSimpleUserLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
  if (name === 'marketplace files') return <PiFolderSimpleStarLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
  if (name === 'shared') return <PiFileCloudLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
  if (name === 'draft cases') return <PiBriefcaseLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
  if (name === 'employees') return <PiFolderSimpleUserLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
  if (name === 'general') return <PiFolderLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
  return <PiFolderLight className={`${w} ${h} text-gray-900 dark:text-white`} />;
}

/*---------------------------------------------
       THE MAIN EXPLORER COMPONENT
---------------------------------------------*/
const FilesExplorer: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { setIsContentScrollable } = useContext(LayoutContext);
  const { hasAccess, isReadOnly } = usePermission('documents');

  // Root-level folder structure from Redux
  const structure = useSelector((state: RootState) => state.folders.structure || []);
  const recommendedFiles = useSelector((state: RootState) => state.documents.recommendedDocuments || []);
  const flatFiles = useSelector((state: RootState) => state.folders.flatFiles || []);

  // Local State
  const [isLoading, setIsLoading] = useState(false);       // For initial load
  const [pathStack, setPathStack] = useState<FolderProps[]>([]);
  const [currentView, setCurrentView] = useState<'grid' | 'table'>('grid');
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const [editorOpen, setEditorOpen] = useState(false);

  // For right-click context menu
  const [contextMenu, setContextMenu] = useState<{ x: number; y: number; target?: FolderProps | FileProps; type?: 'file' | 'folder' | 'empty' }>({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  // Searching, Filters, Sorting
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('name_asc');
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  // Pagination & Infinite Scrolling
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // Recommended Toggle
  const [recommendedView, setRecommendedView] = useState(false);

  // File Creation
  const [newlyCreatedFile, setNewlyCreatedFile] = useState<FileProps | null>(null);
  const [isCreatingFile, setIsCreatingFile] = useState(false);

  // Items displayed in the UI
  const [displayedFolders, setDisplayedFolders] = useState<FolderProps[]>([]);
  const [displayedFiles, setDisplayedFiles] = useState<FileProps[]>([]);

  /*---------------------------------------------
              Utility Functions
  ---------------------------------------------*/
  useEffect(() => {
    setIsContentScrollable(true);
    return () => setIsContentScrollable(true);
  }, [setIsContentScrollable]);

  const getSortParams = (value: string) => {
    let sortBy = '';
    let sortOrder = 'asc';
    switch (value) {
      case 'default':
        sortBy = 'order'; sortOrder = 'asc'; break;
      case 'name_asc':
        sortBy = 'name'; sortOrder = 'asc'; break;
      case 'name_desc':
        sortBy = 'name'; sortOrder = 'desc'; break;
      case 'date_asc':
        sortBy = 'modified_at'; sortOrder = 'asc'; break;
      case 'date_desc':
        sortBy = 'modified_at'; sortOrder = 'desc'; break;
      default:
        break;
    }
    return { sortBy, sortOrder };
  };

  const getRecursiveFileCount = (folder: FolderProps) => {
    let count = folder.Files ? folder.Files.length : 0;
    if (folder.SubFolders && folder.SubFolders.length > 0) {
      folder.SubFolders.forEach((sub) => {
        count += getRecursiveFileCount(sub);
      });
    }
    return count;
  };

  /*---------------------------------------------
            Fetch Data (initial or refresh)
  ---------------------------------------------*/
  const fetchData = async () => {
    setIsLoading(true);
    const { sortBy, sortOrder } = getSortParams(sortValue);

    // 1) If recommended => fetch recommended docs
    if (recommendedView) {
      await dispatch(fetchRecommendedDocuments());
      setIsLoading(false);
      return;
    }

    // 2) If pathStack empty => root-level structure
    if (pathStack.length === 0) {
      await dispatch(fetchStructure({ filters, sortBy, sortOrder }));
      setIsLoading(false);
      return;
    }

    // 3) Otherwise, fetch subfolder for the last folder in the path
    try {
      const lastFolder = pathStack[pathStack.length - 1];
      const res = await dispatch(
        fetchSubFolderAndFiles({
          folderID: lastFolder.FolderID,
          filters,
          sortBy,
          sortOrder,
          limit,
          offset: 0,
          folderName: lastFolder.FolderName
        })
      );
      if (res) {
        // Overwrite displayed data for a fresh load
        setDisplayedFolders(res.subfolders || []);
        setDisplayedFiles(res.files || []);
        setOffset(res.pagination?.next_offset || 0);
        setHasMore(res.pagination?.next_offset !== null);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching subfolders/files:', error);
    }
  };

  /*---------------------------------------------
       loadMore for infinite scrolling
       Only if pathStack != 0 (we're in subfolder),
       not recommended, not searching, hasMore == true
  ---------------------------------------------*/
  const loadMore = async () => {
    if (recommendedView || searchTerm || !hasMore || pathStack.length === 0) return;

    const { sortBy, sortOrder } = getSortParams(sortValue);
    try {
      const lastFolder = pathStack[pathStack.length - 1];
      const res = await dispatch(
        fetchSubFolderAndFiles({
          folderID: lastFolder.FolderID,
          filters,
          sortBy,
          sortOrder,
          limit,
          offset
        })
      );
      if (res) {
        // Append new data
        setDisplayedFolders((prev) => [...prev, ...(res.subfolders || [])]);
        setDisplayedFiles((prev) => [...prev, ...(res.files || [])]);
        setOffset(res.pagination?.next_offset || 0);
        setHasMore(res.pagination?.next_offset !== null);
      }
    } catch (err) {
      console.error('Error loading more subfolder data:', err);
    }
  };

  useEffect(() => {
    // Reset offset & hasMore, then fetch
    setOffset(0);
    setHasMore(false);
    setDisplayedFolders([]);
    setDisplayedFiles([]);
    fetchData();
  }, [recommendedView, pathStack, filters, sortValue, dispatch]);

  useEffect(() => {
    if (pathStack.length === 0) {
      setDisplayedFolders(structure)
    }
  }, [recommendedView, pathStack, filters, sortValue, structure])

  /*---------------------------------------------
      Search (Debounced)
  ---------------------------------------------*/
  useEffect(() => {
    if (!searchTerm) return;
    if (debounceRef.current) clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(async () => {
      setIsLoading(true);
      const { sortBy, sortOrder } = getSortParams(sortValue);

      // If recommended
      if (recommendedView) {
        await dispatch(fetchRecommendedDocuments());
        setIsLoading(false);
        return;
      }

      // If root
      if (pathStack.length === 0) {
        await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
        setIsLoading(false);
        return;
      }

      // Otherwise subfolder
      const lastFolder = pathStack[pathStack.length - 1];
      try {
        const res = await dispatch(
          fetchSubFolderAndFiles({
            folderID: lastFolder.FolderID,
            searchTerm,
            filters,
            sortBy,
            sortOrder
          })
        );
        if (res) {
          setDisplayedFolders(res.subfolders || []);
          setDisplayedFiles(res.files || []);
          setIsLoading(false);
        }
      } catch (err) {
        console.error('Error searching subfolders/files:', err);
      }
    }, 900);

    return () => {
      if (debounceRef.current) clearTimeout(debounceRef.current);
    };
  }, [searchTerm, filters, sortValue, recommendedView, pathStack, dispatch]);

  /*---------------------------------------------
      If recommended => no subfolders
      If root => show structure
  ---------------------------------------------*/
  useEffect(() => {
    if (recommendedView) {
      setDisplayedFolders([]);
      setDisplayedFiles(recommendedFiles || []);
      return;
    }
    if (pathStack.length === 0) {
      setDisplayedFolders(structure || []);
      setDisplayedFiles([]);
      return;
    }
  }, [recommendedView, recommendedFiles, pathStack, structure]);


  useEffect(() => {
    if (newlyCreatedFile) {
      setSelectedFile(newlyCreatedFile);
      fetchData();
      setEditorOpen(true);
      setNewlyCreatedFile(null);
    }
  }, [newlyCreatedFile]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (contextMenu.type) {
        if (!document.getElementById('context-menu')?.contains(e.target as Node)) {
          setContextMenu({ x: 0, y: 0, type: undefined });
        }
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [contextMenu.type]);


  useEffect(() => {
    setIsContentScrollable(true);
    return () => setIsContentScrollable(true);
  }, [setIsContentScrollable]);

  const handleContextMenu = (e: React.MouseEvent, item?: FolderProps | FileProps) => {
    e.preventDefault();
    e.stopPropagation();
    const rect = containerRef.current?.getBoundingClientRect();
    const offsetX = rect ? e.clientX - rect.left : e.clientX;
    const offsetY = rect ? e.clientY - rect.top : e.clientY;
    if (item) {
      let type: 'file' | 'folder' = 'file';
      if ('FolderID' in item && !('FileID' in item)) type = 'folder';
      setContextMenu({ x: offsetX, y: offsetY, target: item, type });
    } else {
      setContextMenu({ x: offsetX, y: offsetY, type: 'empty' });
    }
  };


  const handleBreadcrumbClick = (index: number) => {
    const newPath = pathStack.slice(0, index + 1);
    setPathStack(newPath);
  };

  const handleGoBack = async () => {
    if (pathStack.length === 0) {
      setPathStack([]);
      return;
    }
    const newStack = [...pathStack];
    newStack.pop();
    setPathStack(newStack);
  };

  const handleNavigateFolder = (folder: FolderProps) => {
    setPathStack([...pathStack, folder]);
  };


  const [newFolderNameState, setNewFolderNameState] = useState('');

  const handleCreateFolder = async (folderName: string) => {
    if (!folderName.trim()) return;
    setIsLoading(true);
    await dispatch(createFolder(folderName));
    await fetchData();
  };

  const handleCreateSubFolder = async (folderName: string, parentFolderId: string) => {
    if (!folderName.trim()) return;
    setIsLoading(true);
    await dispatch(createSubFolder(folderName, parentFolderId));
    await fetchData();
    setIsLoading(false);
  };

  const handleRenameFolder = (folder: FolderProps) => {
    dispatch(
      showModal({
        type: 'custom',
        message: t('Rename Folder'),
        showModal: true,
        content: (
          <div className="p-4 space-y-4">
            <Input
              type="text"
              label={t('New Folder Name')}
              onChange={(e) => setNewFolderNameState(e.target.value)}
            />
            <Button
              variant="primary"
              onClick={async () => {
                if (newFolderNameState.trim()) {
                  await dispatch(renameFolder(folder.FolderID, newFolderNameState.trim()));
                  await fetchData();
                  dispatch(hideModal());
                }
              }}
            >
              {t('Rename')}
            </Button>
          </div>
        )
      })
    );
  };

  const handleDeleteFolder = async (folder: FolderProps) => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this folder?'),
        onConfirm: async () => {
          setIsLoading(true);
          await dispatch(
            deleteFolder(folder.FolderID, async () => {
              await fetchData();
            })
          );
          const folderIndex = pathStack.findIndex((f) => f.FolderID === folder.FolderID);
          if (folderIndex !== -1) {
            const newStack = pathStack.slice(0, folderIndex);
            setPathStack(newStack);
          }
          setIsLoading(false);
          dispatch(hideModal());
        },
        onCancel: () => { },
        showModal: true
      })
    );
  };

  const handleDeleteFile = async (file: FileProps) => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this file?'),
        onConfirm: async () => {
          setIsLoading(true);
          await dispatch(
            deleteFile(file.FileID, async () => {
              await fetchData();
            })
          );
          if (selectedFile && selectedFile.FileID === file.FileID) {
            setEditorOpen(false);
            setSelectedFile(null);
          }
          setIsLoading(false);
          dispatch(hideModal());
        },
        onCancel: () => { },
        showModal: true
      })
    );
  };

  const handleCreateFolderClick = () => dispatch(showCreateFolderModal(handleCreateFolder));
  const handleCreateSubFolderClick = () => {
    const currentFolder = pathStack[pathStack.length - 1];
    if (!currentFolder) return;
    dispatch(showCreateFolderModal(undefined, currentFolder.FolderID, handleCreateSubFolder, true));
  };
  const handleAddFile = () => setIsCreatingFile(true);
  const handleCloseFileCreation = () => setIsCreatingFile(false);


  const onFileClick = (file: FileProps) => {
    setSelectedFile(file);
    setEditorOpen(true);
  };

  const onFolderClick = (folder: FolderProps) => {
    handleNavigateFolder(folder);
  };


  const onDragEnd = async (result: DropResult) => {
    if (!result.destination || recommendedView) return;
    const { source, destination, draggableId } = result;

    // Folders reorder
    if (draggableId.startsWith('folder-')) {
      const reordered = Array.from(displayedFolders);
      const [moved] = reordered.splice(source.index, 1);
      reordered.splice(destination.index, 0, moved);

      const dataToUpdate = reordered.map((f: any, idx) => ({
        FolderID: f.FolderID,
        newOrder: idx
      }));
      await dispatch(updateFolderOrder(dataToUpdate));
    }
    // Files reorder
    else if (draggableId.startsWith('file-')) {
      const reordered = Array.from(displayedFiles);
      const folderCount = displayedFolders.length;
      const [moved] = reordered.splice(source.index - folderCount, 1);
      reordered.splice(destination.index - folderCount, 0, moved);

      const dataToUpdate = reordered.map((f: any, idx) => ({
        FileID: f.FileID,
        newOrder: idx
      }));
      await dispatch(updateDocumentOrder(dataToUpdate));
    }
  };


  const toggleRecommendedView = () => {
    setPathStack([]);  // Reset path
    setRecommendedView(!recommendedView);
  };

  const toggleView = () => {
    setCurrentView(currentView === 'grid' ? 'table' : 'grid');
  };


  const renderGrid = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-grid" direction="horizontal" type="ITEM">
        {(provided) => (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            useWindow={false}
            loader={
              <div key="loader" className="col-span-full text-center p-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {Array.from({ length: 3 }).map((_, i) => <SkeletonGridItem key={`skeleton-grid-${i}`} />)}
              </div>
            }
          >
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="p-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4"
            >
              {isLoading ? (
                <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800 bg-opacity-50 dark:bg-opacity-50 z-50">
                  <ApiLoader />
                </div>
              ) : (
                <>
                  {displayedFolders.map((folder, index) => (
                    <Draggable key={folder.FolderID} draggableId={`folder-${folder.FolderID}`} index={index}>
                      {(prov) => (
                        <Tooltip title={`${folder.FolderName} (Modified: ${new Date(folder.ModifiedAt || Date.now()).toLocaleDateString()})`}>
                          <div
                            ref={prov.innerRef}
                            {...prov.draggableProps}
                            {...prov.dragHandleProps}
                            onClick={() => onFolderClick(folder)}
                            onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); handleContextMenu(e, folder); }}
                            className="relative flex flex-col p-3 bg-primary-50 dark:bg-primary-900 
                                       rounded-lg shadow-sm hover:shadow-md transition-all duration-200 cursor-pointer 
                                       border border-gray-200 dark:border-gray-700"
                          >
                            <div className="flex justify-between">
                              {getFolderIcon(folder, 'w-6', 'h-6')}
                            </div>
                            <div className="mt-4 text-sm text-gray-700 dark:text-gray-200 truncate">
                              {folder.FolderName}
                            </div>
                          </div>
                        </Tooltip>
                      )}
                    </Draggable>
                  ))}
                  {displayedFiles.map((file, index) => (
                    <Draggable key={file.FileID} draggableId={`file-${file.FileID}`} index={displayedFolders.length + index}>
                      {(prov) => (
                        <Tooltip title={`${file.FileName} (${file.FileCategory}, Last Modified: ${new Date(file.ModifiedAt || Date.now()).toLocaleDateString()})`}>
                          <div
                            ref={prov.innerRef}
                            {...prov.draggableProps}
                            {...prov.dragHandleProps}
                            onClick={() => onFileClick(file)}
                            onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); handleContextMenu(e, file); }}
                            className="relative flex flex-col p-3 bg-primary-50 dark:bg-primary-900 
                                       rounded-lg shadow-sm hover:shadow-md transition-all duration-200 cursor-pointer 
                                       border border-gray-200 dark:border-gray-700"
                          >
                            <div className="absolute top-2 left-2">
                              {getFileIconForGrid(file, 'w-6', 'h-6')}
                            </div>
                            <div className="mt-8 ml-1 text-sm text-gray-700 dark:text-gray-200 truncate">
                              {file.FileName}
                            </div>
                            <Badge
                              color={file.FileCategory === 'General' ? 'green' : 'blue'}
                              className="absolute top-2 right-2 text-xs"
                            >
                              {file.FileCategory}
                            </Badge>
                          </div>
                        </Tooltip>
                      )}
                    </Draggable>
                  ))}

                  {displayedFolders.length === 0 && displayedFiles.length === 0 && !isLoading && (
                    <div className="absolute flex items-center justify-center w-[95%]">
                      <Badge color="gray" className="text-lg">{t('No items found')}</Badge>
                    </div>
                  )}
                </>
              )}
              {provided.placeholder}
            </div>
          </InfiniteScroll>
        )}
      </Droppable>
    </DragDropContext>
  );


  const columns = ['Name', 'Last Modified', 'Info'];
  const tableData = [
    ...displayedFolders.map((folder, index) => ({
      id: folder.FolderID,
      doc: folder,
      Name: (
        <div className="flex items-center space-x-2">
          {getFolderIcon(folder, 'w-6', 'h-6')}
          <span className="truncate max-w-xs">{folder.FolderName}</span>
          <Badge color={folder.FolderType === 'Locked' ? 'blue' : 'green'} className="ml-2">
            {getRecursiveFileCount(folder)}
          </Badge>
        </div>
      ),
      'Last Modified': convertLongDateWithTranslation(new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(folder.ModifiedAt))),
      Info: folder.FolderType,
      isFolder: true,
      index
    })),
    ...displayedFiles.map((file, index) => ({
      id: file.FileID,
      doc: file,
      Name: (
        <div className="flex items-center space-x-2">
          {getFileIconForGrid(file, 'w-6', 'h-6')}
          <span className="truncate max-w-xs">{file.FileName}</span>
          <Badge color={file.FileCategory === 'General' ? 'green' : 'blue'} className="ml-2">
            {file.FileCategory}
          </Badge>
        </div>
      ),
      'Last Modified': convertLongDateWithTranslation(new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(file.ModifiedAt))),
      Info: file.FileCategory,
      isFolder: false,
      index: displayedFolders.length + index
    }))
  ];

  const handleRowClick = (row: any) => {
    if (row.isFolder) onFolderClick(row.doc as FolderProps);
    else onFileClick(row.doc as FileProps);
  };

  const renderTable = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-table" direction="vertical" type="FILE">
        {(provided) => (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore && pathStack.length !== 0 && !recommendedView && !searchTerm}
            useWindow={false}
            loader={
              <tr key="loader" className="w-full col-span-full text-center p-4">
                <SkeletonTableRow />
              </tr>
            }
          >
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="flex-1 overflow-x-auto overflow-y-auto -mx-4 mt-4 ring-1 
                         ring-gray-300 dark:ring-gray-700 sm:mx-0 shadow-md 
                         dark:shadow-dark-md"
            >
              {/* Updated table styling */}
              <table className="w-full border border-gray-200 dark:border-gray-700 text-left 
                               border-collapse shadow-sm">
                <thead className="bg-gray-100 dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
                  <tr>
                    {columns.map((col) => (
                      <th
                        key={col}
                        className="px-4 py-3 text-gray-700 dark:text-gray-300 font-semibold text-sm"
                      >
                        {t(col)}
                      </th>
                    ))}
                  </tr>
                </thead>

                {/* Optional: add `.divide-y` for separated rows */}
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {isLoading ? (
                    // If loading & no items => hide or show a skeleton
                    <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800 bg-opacity-50 dark:bg-opacity-50 z-50">
                      <ApiLoader />
                    </div>
                  ) : (
                    <>
                      {tableData.map((row: any, index: number) => (
                        <Draggable key={row.id} draggableId={`table-item-${row.id}`} index={index}>
                          {(prov) => (
                            <tr
                              ref={prov.innerRef}
                              {...prov.draggableProps}
                              {...prov.dragHandleProps}
                              onClick={() => handleRowClick(row)}
                              onContextMenu={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleContextMenu(e, row.doc);
                              }}
                              className="cursor-pointer transition-colors duration-200 
                                         hover:bg-gray-50 dark:hover:bg-gray-600 
                                         bg-primary-50 dark:bg-primary-900"
                            >
                              {columns.map((col) => (
                                <td
                                  key={col}
                                  className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200 truncate"
                                >
                                  {row[col]}
                                </td>
                              ))}
                            </tr>
                          )}
                        </Draggable>
                      ))}

                      {displayedFolders.length === 0 && displayedFiles.length === 0 && !isLoading && (
                        <tr>
                          <td colSpan={columns.length} className="text-center py-4">
                            <Badge color="gray">{t('No items found')}</Badge>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {provided.placeholder}
            </div>
          </InfiniteScroll>
        )}
      </Droppable>
    </DragDropContext>
  );


  const isFilteredMode = flatFiles && flatFiles.length > 0;
  const currentFolder = pathStack.length > 0 ? pathStack[pathStack.length - 1] : null;

  return (
    <div
      className="flex flex-1 flex-col min-h-0 overflow-auto relative bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300"
      ref={containerRef}
      onContextMenu={(e) => handleContextMenu(e)}
    >
      {isCreatingFile ? (
        <NewFile
          folders={structure}
          preSelectedFolderId={currentFolder?.FolderID}
          onFileCreated={(newFile) => {
            setNewlyCreatedFile(newFile);
            handleCloseFileCreation();
          }}
          onClose={handleCloseFileCreation}
        />
      ) : editorOpen && selectedFile ? (
        <WordEditor
          file={selectedFile.FileID}
          onClose={() => {
            setEditorOpen(false);
            setSelectedFile(null);
          }}
          setEditorOpen={setEditorOpen}
          onFileDeleted={() => setSelectedFile(null)}
        />
      ) : (
        <>
          {isLoading && displayedFolders.length === 0 && displayedFiles.length === 0 && (
            <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800 bg-opacity-50 dark:bg-opacity-50 z-50">
              {/* <ApiLoader /> */}
            </div>
          )}

          {/* Top Bar (Breadcrumbs + Create Menu) */}
          <div className="flex items-center justify-between px-4 py-2 border-b border-gray-300 dark:border-gray-700">
            {(() => {
              if (recommendedView && flatFiles.length === 0) {
                return <span className="text-gray-500 text-xs">{t('Recommended Files')}</span>;
              }
              if (isFilteredMode) {
                return <span className="text-gray-500 text-xs">{t('All Matching Files')}</span>;
              }
              return (
                <div className="flex items-center space-x-2">
                  {pathStack.length > 0 && (
                    <button onClick={handleGoBack} className="text-primary-500 hover:underline flex items-center">
                      <ChevronLeftIcon className="h-5 w-5" />
                    </button>
                  )}
                  <span
                    className="text-xs text-gray-500 cursor-pointer hover:underline"
                    onClick={() => setPathStack([])}
                  >
                    <PiFolderOpenLight size={20} />
                  </span>
                  {pathStack.map((folder, index) => (
                    <div key={folder.FolderID} className="flex items-center space-x-2">
                      <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                      <button
                        onClick={() => {
                          const newPath = pathStack.slice(0, index + 1);
                          setPathStack(newPath);
                        }}
                        className="text-gray-500 hover:underline text-xs truncate max-w-xs"
                      >
                        {index === pathStack.length - 1 ? (
                          <PiFolderOpenLight size={16} className="inline mr-1" />
                        ) : (
                          <PiFolderLight size={16} className="inline mr-1" />
                        )}
                        {folder.FolderName}
                      </button>
                    </div>
                  ))}
                </div>
              );
            })()}
            <div className="flex items-center space-x-2">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="inline-flex text-white items-center space-x-1 px-2 py-1 bg-primary-500 rounded hover:bg-primary-600">
                  <PiPlusLight size={16} />
                </Menu.Button>
                <Transition as={Fragment}>
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => dispatch(showCreateFolderModal(handleCreateFolder))}
                          className={clsx(
                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                            'flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                          )}
                        >
                          <PiFolderPlusLight className="h-5 w-5 text-primary-500 mr-2" />
                          {t('Create Folder')}
                        </button>
                      )}
                    </Menu.Item>
                    {!recommendedView && currentFolder && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => dispatch(showCreateFolderModal(undefined, currentFolder.FolderID, handleCreateSubFolder, true))}
                            className={clsx(
                              active ? 'bg-gray-100 dark:bg-gray-600' : '',
                              'flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                            )}
                          >
                            <PiFolderPlusLight className="h-5 w-5 text-primary-500 mr-2" />
                            {t('Create Subfolder')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {!recommendedView && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleAddFile}
                            className={clsx(
                              active ? 'bg-gray-100 dark:bg-gray-600' : '',
                              'flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                            )}
                          >
                            <PiFilePlusLight className="h-5 w-5 text-primary-500 mr-2" />
                            {t('Create File')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>

          {/* Search & Filters */}
          <div className="border-b border-gray-300 dark:border-gray-700">
            <FilterSearchBar
              filters={[
                {
                  id: 'FileCategory',
                  name: t('File Category'),
                  options: [
                    { value: 'Contract', label: t('Contract') },
                    { value: 'Legal Document', label: t('Legal Document') },
                    { value: 'General', label: t('General') },
                    { value: 'Template', label: t('Template') }
                  ]
                }
              ]}
              sortOptions={[
                { value: 'default', label: t('Default'), current: sortValue === 'default' },
                { value: 'name_asc', label: t('Name Ascending'), current: sortValue === 'name_asc' },
                { value: 'name_desc', label: t('Name Descending'), current: sortValue === 'name_desc' },
                { value: 'date_asc', label: t('Date Ascending'), current: sortValue === 'date_asc' },
                { value: 'date_desc', label: t('Date Descending'), current: sortValue === 'date_desc' }
              ]}
              selectedFilters={filters}
              onFilterChange={(filterName, selectedValues) => setFilters((prev) => ({ ...prev, [filterName]: selectedValues }))}
              onSortChange={(value) => setSortValue(value)}
              onSearchChange={(term) => setSearchTerm(term)}
              sortValue={sortValue}
              showSearchBar={true}
              extraControls={
                <div className="mr-2 flex flex-row gap-2">
                  <Button onClick={toggleRecommendedView} variant="tertiary" className="mr-2" tooltip={`${recommendedView ? t('Default') : t('Recommended Files')}`} size="small">
                    {recommendedView ? <PiFilesLight size={18} /> : <PiMagicWandLight size={18} />}
                    <span className="ml-1">{recommendedView ? t('All Files') : t('Recommended Files')}</span>
                  </Button>
                  <Button onClick={toggleView} variant="neutral" size="small">
                    {currentView === 'grid' ? (
                      <PiTableLight className="h-5 w-5 text-gray-700 dark:text-gray-200" />
                    ) : (
                      <PiGridNineLight className="h-5 w-5 text-gray-700 dark:text-gray-200" />
                    )}
                  </Button>
                </div>
              }
            />
          </div>

          {/* MAIN CONTENT AREA */}
          <div className="flex-1 min-h-0 overflow-auto relative" ref={containerRef}>
            {currentView === 'grid' ? renderGrid() : renderTable()}
          </div>

          {/* CONTEXT MENU */}
          {contextMenu.type && (
            <div
              id="context-menu"
              className="fixed z-50 bg-white dark:bg-gray-800 rounded shadow-lg border border-gray-300 dark:border-gray-700 text-sm"
              style={{ top: contextMenu.y, left: contextMenu.x }}
            >
              {contextMenu.type === 'empty' && (
                <div className="flex flex-col py-1">
                  {!recommendedView && (
                    <>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                        onClick={handleCreateFolderClick}
                      >
                        {t('Create Folder')}
                      </button>
                      {pathStack.length > 0 && (
                        <button
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                          onClick={handleCreateSubFolderClick}
                        >
                          {t('Create Subfolder')}
                        </button>
                      )}
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                        onClick={handleAddFile}
                      >
                        {t('Create File')}
                      </button>
                    </>
                  )}
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={toggleView}
                  >
                    {currentView === 'grid'
                      ? t('Switch to Table View')
                      : t('Switch to Grid View')}
                  </button>
                </div>
              )}
              {contextMenu.type === 'folder' && contextMenu.target && !recommendedView && (
                <div className="flex flex-col py-1">
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={() => handleRenameFolder(contextMenu.target as FolderProps)}
                  >
                    {t('Rename Folder')}
                  </button>
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={() => handleDeleteFolder(contextMenu.target as FolderProps)}
                  >
                    {t('Delete Folder')}
                  </button>
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={handleCreateSubFolderClick}
                  >
                    {t('Create Subfolder')}
                  </button>
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={handleAddFile}
                  >
                    {t('Create File')}
                  </button>
                </div>
              )}
              {contextMenu.type === 'file' && contextMenu.target && (
                <div className="flex flex-col py-1">
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={() => handleDeleteFile(contextMenu.target as FileProps)}
                  >
                    {t('Delete File')}
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FilesExplorer;
