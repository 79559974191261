import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import {
  chatGroupApi,
  ChatGroupData,
  getUserID,
  getUserType,
  setChatGroupIDStore,
} from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { fetchStructure } from '../../store/actions/FolderAction';
import { RootState, useAppDispatch } from '../../store';
import {
  showAddFileSidePanel,
  showCreateProjectSidePanel,
} from '../../store/actions/sidePanelActions';
import AISettingsComponent from '../Settings/AccountSettings/AISettings';
import ApiLoader from '../ApiLoader';
import { FileProps } from '../Documents/types';
import AIFolderList from './AIFolderList';
import { showCreateFolderModal } from '../../store/actions/modalActions';
import { Tooltip } from '@mui/material';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { AiOutlineDelete, AiOutlineSetting } from 'react-icons/ai';
import { usePermission } from '../../hooks/userPermissions';
import { fetchProjects } from '../../store/actions/ProjectActions';
import Badge from '../shared/TailwindComponents/Badge';
import Input from '../shared/TailwindComponents/Input';

const groupChatsByTime = (chats: ChatGroupData[], today: Date) => {
  const groups: { [key: string]: ChatGroupData[] } = {
    "Today": [],
    "This Week": [],
    "Last Week": [],
    "Last 30 Days": [],
    "Older": [],
  };

  chats.forEach((chat) => {
    const date = new Date(chat.LastModified);
    const diffMs = today.getTime() - date.getTime();
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      groups["Today"].push(chat);
    } else if (diffDays <= 7) {
      groups["This Week"].push(chat);
    } else if (diffDays <= 14) {
      groups["Last Week"].push(chat);
    } else if (diffDays <= 30) {
      groups["Last 30 Days"].push(chat);
    } else {
      groups["Older"].push(chat);
    }
  });

  return groups;
};

interface AISidebarProps {
  onFileSelect: (file: FileProps) => void;
  currentView: 'Folders' | 'Projects' | 'ChatHistory' | 'AISettings';
  setCurrentView: React.Dispatch<
    React.SetStateAction<'Folders' | 'Projects' | 'ChatHistory' | 'AISettings'>
  >;
  onSelectChatGroup: (chatGroupId: string) => void;
  handleDeleteChat: (chatGroupId: string) => void;
  removeSpaces: (str: string) => string;
  onProjectSelect: (projectId: string | null) => void; 
  onNewChat: () => void;
}

const AISidebar: React.FC<AISidebarProps> = ({
  onFileSelect,
  currentView,
  setCurrentView,
  onSelectChatGroup,
  handleDeleteChat,
  removeSpaces,
  onProjectSelect,
  onNewChat
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const userType = getUserType();
  const userId = getUserID();
  const structure = useSelector((state: RootState) => state.folders.structure);
  const chatGroups = useSelector((state: RootState) => state.model.chatGroups);
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const isFoldersLoading = useSelector((state: RootState) => state.folders.loading);
  const selectedChatGroupId = useSelector((state: RootState) => state.model.chatGroupID);
  const [searchQuery, setSearchQuery] = useState('');
  const { hasAccess, isReadOnly, loading } = usePermission('documents');
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const today = new Date(); 

  useEffect(() => {
    if (currentView === 'Folders') {
      dispatch(fetchStructure());
    } else if (currentView === 'ChatHistory' && chatGroups.length === 0) {
      dispatch(chatGroupApi('Orchestrator'));
    }
    // Add other data fetching as needed
  }, [currentView, dispatch, userId, userType, selectedModel, chatGroups.length]);

  const allProjects = useSelector((state: RootState) => Object.values(state.projects.projects));

  useEffect(() => {
    if (currentView === 'Projects') {
      dispatch(fetchProjects());
    }
  }, [currentView, dispatch]);


  const handleOpenCreateProject = () => {
    dispatch(showCreateProjectSidePanel());
  };

  const handleSelectFolder = (folderId: string) => {
    // Handle folder selection if needed
  };

  const handleSelectChatGroup = (chatGroupId: string) => {
    onSelectChatGroup(chatGroupId);
  };

  const handleAddFolder = () => {
    dispatch(showCreateFolderModal());
  };

  const handleAddFile = () => {
    dispatch(showAddFileSidePanel(structure));
  };

  // Filtered Data
  const filteredChatGroups = chatGroups.filter((chatGroup: ChatGroupData) =>
    chatGroup.ChatTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const searchFoldersAndFiles = (folders: any, query: string) => {
    const filteredFolders: any[] = [];
  
    folders.forEach((folder: any) => {
      const folderNameMatches = folder.FolderName.toLowerCase().includes(query.toLowerCase());
      const subFolders = folder.SubFolders ? searchFoldersAndFiles(folder.SubFolders, query) : [];
      const files = folder.Files
        ? folder.Files.filter((file: any) =>
            file.FileName.toLowerCase().includes(query.toLowerCase())
          )
        : [];
  
      const shouldOpen = folderNameMatches || subFolders.some((sf) => sf.shouldOpen) || files.length > 0;
  
      if (shouldOpen) {
        filteredFolders.push({
          ...folder,
          SubFolders: subFolders,
          Files: files,
          shouldOpen,
        });
      }
    });
  
    return filteredFolders;
  };

  const handleProjectSelect = (projectId: string) => {
    setSelectedProjectId((prevProjectId) => (prevProjectId === projectId ? null : projectId));
    onProjectSelect(selectedProjectId === projectId ? null : projectId); 
  };

  const handleOpenAISettings = () => {
    setCurrentView('AISettings');
  };

  const filteredStructure = searchFoldersAndFiles(structure, searchQuery);
  const groupedChats = groupChatsByTime(filteredChatGroups, today);

  const formatDate = (lastModified: string, today: Date) => {
    const date = new Date(lastModified);
    const diffMs = today.getTime() - date.getTime();
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return t('Today');
    if (diffDays === 1) return t('Yesterday');
    if (diffDays <= 7) return date.toLocaleDateString(undefined, { weekday: 'long' });
    return date.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' });
  };

  return (
    <aside className="w-64 h-full shadow-md dark:shadow-dark-md bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 flex flex-col">
      {/* Header Section */}
      <div className="flex-shrink-0">
        {/* <div className='flex justify-between m-2'>
                <Tooltip title={t('New Chat')} placement="top">
                  <button
                    className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                    onClick={onNewChat}
                  >
                    <BsPlus size={20} />
                  </button>
                </Tooltip>
        <div className='flex gap-2 items-center justify-end'>
              <Tooltip title={t('AI Settings')} placement="top">
                <button
                  onClick={handleOpenAISettings}
                  className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                >
                  <AiOutlineSetting size={20} />
                </button>
              </Tooltip>
                      <Tooltip title={t('History')} placement="top">
                        <button
                          className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                          onClick={() => setCurrentView('ChatHistory')}
                          >
                            <GoHistory size={20} title={t('History')} />
                              </button>
                      </Tooltip>
                      </div>
                      </div> */}
        {/* Search Bar */}
        {currentView === "ChatHistory" && (
          <div className="px-2 my-2">
          <Input
            type="text"
            placeholder={t('Search...')}
            className="w-full px-3 py-2 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white rounded"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            />
        </div>
          )}
        {/* Navigation Buttons */}
        {/* <div className="mt-2 w-full bg-gray-100 dark:bg-gray-700">
          <div className="py-1">
            <button
              onClick={() => setCurrentView('Folders')}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 flex justify-between items-center"
            >
              <span>{t('Vault')}</span>
              {currentView === "Folders" ? <CiUnlock size={20} color='#000'/> :  <CiLock size={20} color='#000'/>}
              
            </button>
            {(userType === 'BusinessAdmin' || userType === 'BusinessEmployee') && (
              <button
                onClick={() => setCurrentView('Projects')}
                className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600"
              >
                {t('Projects')}
              </button>
            )}
            {/* <button
              onClick={() => setCurrentView('ChatHistory')}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 flex justify-between items-center"
            >
              <span>{t('Chats')}</span>
              <BsChatLeftText size={20} color='#000'/>
            </button> 
          </div>
        </div> */}
      </div>

      {/* Content Section (Scrollable) */}
      <div className={`flex-1 ${currentView === "AISettings" ? 'overflow-y-visible' : 'overflow-y-auto'}`}>
        {currentView === 'Folders' && (
          <div className="p-2">
            {/* Render folders and files */}
            {isFoldersLoading ? (
              <div className="flex justify-center items-center h-full">
                <ApiLoader />
              </div>
            ) : (
              <AIFolderList
                folders={filteredStructure}
                onSelectFolder={handleSelectFolder}
                selectedFolderId={null}
                isLoading={false}
                handleCreateFolder={(folderName) => {
                  /* Handle folder creation */
                }}
                onFileSelect={onFileSelect}
              />
            )}
          </div>
        )}
      {currentView === 'ChatHistory' && (
          <div className="p-2">
            {isChatLoading ? (
              <div className="flex justify-center items-center h-full">
                <ApiLoader />
              </div>
            ) : filteredChatGroups.length > 0 ? (
              <ul className="space-y-2">
                {Object.entries(groupedChats)
                  .filter(([_, chats]) => chats.length > 0)
                  .map(([period, chats]) => (
                    <Fragment key={period}>
                      <Transition
                        appear={true}
                        show={true}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-2"
                        enterTo="opacity-100 translate-y-0"
                        // leave="transition ease-out duration-200"
                        // leaveFrom="opacity-0 translate-y-0"
                        // leaveTo="opacity-100 translate-y-2"
                      >
                        <li className="px-2 py-1 text-md font-semibold text-gray-500 dark:text-gray-400 sticky top-0 z-10 bg-white dark:bg-gray-800">
                          {t(period)}
                        </li>
                      </Transition>
                      {chats.map((chatGroup: ChatGroupData) => (
                        <Transition
                          key={chatGroup.ChatGroupID}
                          appear={true}
                          show={true}
                          enter="transition ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-[-4]"
                        >
                          <li
                            className={`flex items-center justify-between p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer ${
                              selectedChatGroupId === chatGroup.ChatGroupID
                                ? 'bg-gray-200 dark:bg-gray-600'
                                : ''
                            }`}
                          >
                            <div
                              onClick={() => handleSelectChatGroup(chatGroup.ChatGroupID)}
                              className="flex-1 min-w-0"
                            >
                              <p className="text-xs font-medium text-gray-900 dark:text-gray-100 truncate">
                                {chatGroup.ChatTitle}
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                {formatDate(chatGroup.LastModified, today)}
                              </p>
                            </div>
                            <Tooltip title={t('Delete Chat')} placement="left">
                              <button
                                className="ml-2 text-gray-500 hover:text-red-500 flex-shrink-0"
                                onClick={() => handleDeleteChat(chatGroup.ChatGroupID)}
                              >
                                <AiOutlineDelete size={16} />
                              </button>
                            </Tooltip>
                          </li>
                        </Transition>
                      ))}
                    </Fragment>
                  ))}
              </ul>
            ) : (
              <div className="h-[calc(100vh-450px)] flex items-center justify-center">
                <Badge color="gray" className="h-fit">
                  {t('No Chat History')}
                </Badge>
              </div>
            )}
          </div>
        )}
        {currentView === 'AISettings' && (
          <div className="px-2">
            {/* Render AI Settings */}
            <AISettingsComponent
              style={{ flexDirection: 'column', alignItems: 'flex-start', gap: 10 }}
              backgroundColor={'transparent'}
            />
          </div>
        )}

        {currentView === 'Projects' && (
            <div className="p-2">
              {allProjects.length === 0 ? (
                <div className='h-[calc(100vh-450px)] flex align-center justify-center'>
                <Badge color='gray' className='h-fit self-center'>{t('No projects available')}</Badge>
                </div>
              ) : (
                <ul>
                  {allProjects.map((project) => (
                    <li
                      key={project.ProjectID}
                      className={`p-2 cursor-pointer flex items-center ${
                        selectedProjectId === project.ProjectID ? 'bg-blue-200' : ''
                      }`}
                      onClick={() => handleProjectSelect(project.ProjectID)}
                    >
                      <i className="fas fa-briefcase mr-2 text-blue-500"></i>
                      <span>{project.ProjectName}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

      </div>

      {/* Footer Section (Static Buttons) */}
      {/* <div className="flex-shrink-0 p-2 border-t border-gray-200 dark:border-gray-700 flex flex-col space-y-1">
        {/* Place your action buttons here */}
        {/* Example: 
        {currentView === 'Projects' &&
          (userType === 'BusinessAdmin' || userType === 'BusinessEmployee') && (
            <Button
              variant="primary"
              onClick={handleOpenCreateProject}
              className="w-full justify-between"
            >
              <span>{t('Create new project')}</span>
              <i className="fas fa-file-plus"></i>
            </Button>
          )}
        <Button 
        disabled={isReadOnly}
        variant="primary" onClick={handleAddFolder} className="w-full justify-between">
          <span>{t('Create new folder')}</span>
          <PiFolderSimplePlusLight size={20} />
          </Button>
        <Button 
        disabled={isReadOnly}
        variant="primary" onClick={handleAddFile} className="w-full justify-between">
          <span>{t('Create new file')}</span>
          <PiFilePlusLight size={20}  />
          </Button>
      </div> */}
    </aside>
  );
};

function addSpacesBeforeCaps(str: string): string {
  return str.replace(/([A-Z])/g, ' $1').trim();
}

export default AISidebar;
