import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchIndexes } from '../../../store/actions/AIStudioAction';
import Button from '../../shared/TailwindComponents/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table from '../../shared/TailwindComponents/Table';
import Badge from '../../shared/TailwindComponents/Badge';
import CreateAgentForm from './CreateAgentForm';
import Header from '../../shared/TailwindComponents/Header';
import FilterSearchBar from '../../shared/TailwindComponents/FilterSearchBar';
import ListItem from '../../shared/TailwindComponents/ListItem';
import { PiBuildingLight, PiGridFourLight, PiTableLight } from 'react-icons/pi';
import ApiLoader from '../../ApiLoader';
import { BadgeItem } from '../../shared/TailwindComponents/types/badgeTypes';
import { GoDependabot } from 'react-icons/go';

const AIStudioIndexListPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const indexes = useSelector((state: RootState) => state.aistudio.indexes);

  const [isCreatingAgent, setIsCreatingAgent] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('name_asc');
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');
  const [isIndexesLoading, setIsIndexesLoading] = useState(false);

  const indexListRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);

  // Handle scroll for sticky header
  const handleScroll = () => {
    const scrollPosition = indexListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    const listElem = indexListRef.current;
    if (listElem) {
      listElem.addEventListener('scroll', handleScroll);
      return () => listElem.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // Fetch indexes with filters, search, and sort
  useEffect(() => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (sortValue === 'name_asc') {
      sortBy = 'IndexName';
      sortOrder = 'asc';
    } else if (sortValue === 'name_desc') {
      sortBy = 'IndexName';
      sortOrder = 'desc';
    } else if (sortValue === 'created_at_asc') {
      sortBy = 'CreatedAt';
      sortOrder = 'asc';
    } else if (sortValue === 'created_at_desc') {
      sortBy = 'CreatedAt';
      sortOrder = 'desc';
    }

    setIsIndexesLoading(true);
    dispatch(
      fetchIndexes({
        searchTerm: searchQuery,
        filters: selectedFilters,
        sortBy,
        sortOrder,
      }) as any
    ).finally(() => {
      setIsIndexesLoading(false);
    });
  }, [searchQuery, selectedFilters, sortValue, dispatch]);

  // Toggle between grid and table views
  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'grid' ? 'table' : 'grid'));
  };

  const handleAddAgentClick = () => setIsCreatingAgent(true);
  const handleAddAgentClose = () => setIsCreatingAgent(false);
  const handleIndexClick = (index: any) => history.push(`/ai-studio/${encodeURIComponent(index.IndexName)}`);
  const handleSearchChange = (query: string) => setSearchQuery(query);
  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };
  const handleSortChange = (value: string) => setSortValue(value);

  // Define filters and sort options
  const filters = [
    {
      id: 'AgentType',
      name: 'Agent Type',
      options: [
        { value: 'CustomAgent', label: 'Custom Agent' },
        { value: 'CompanyAgent', label: 'Company Agent' },
      ],
    },
  ];

  const sortOptions = [
    { value: 'name_asc', label: 'Name Ascending', current: true },
    { value: 'name_desc', label: 'Name Descending', current: false },
    { value: 'created_at_asc', label: 'Created At Ascending', current: false },
    { value: 'created_at_desc', label: 'Created At Descending', current: false },
  ];

  const shrinkThreshold = 150;
  const isShrunk = scrollTop >= shrinkThreshold;

  const formatDate = (dateString: string) => {
    if (!dateString) return t('N/A');
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(date);
  };

  // Table data
  const columns = [
    t('Agent Name'),
    t('Type'),
    t('Created At'),
    t('Documents'),
    t('Size'),
    t('Last Indexed'),
    // t('Creator'),
    t('Usage'),
    // t('Status'),
  ];
  const tableData = indexes.map((idx: any) => ({
    id: idx.id,
    [t('Agent Name')]: idx.IndexName,
    [t('Type')]: idx.AgentType === 'CompanyAgent' ? t('Company Agent') : t('Custom Agent'),
    [t('Created At')]: formatDate(idx.CreatedAt),
    [t('Documents')]: idx.document_count.toString(),
    [t('Size')]: idx.total_storage_size,
    [t('Last Indexed')]: idx.last_index_run ? formatDate(idx.last_index_run) : 'N/A',
    // [t('Creator')]: idx.creator,
    [t('Usage')]: `${idx.usage_count} queries`,
    // [t('Status')]: idx.indexing_status,
  }));

  // Grid data with ListItem
  const gridData = useMemo(() => {
    return indexes.map((idx: any) => {
      const badges: BadgeItem[] = [
        { label: idx.AgentType === 'CompanyAgent' ? t('Company Agent') : t('Custom Agent'), color: 'blue' },
      ];
      const details = [
        { label: 'Created At', value: formatDate(idx.CreatedAt) },
        { label: 'Documents', value: idx.document_count.toString() },
        { label: 'Size', value: idx.total_storage_size },
        { label: 'Last Indexed', value: idx.last_index_run ? formatDate(idx.last_index_run) : 'N/A' },
        { label: 'Usage', value: `${idx.usage_count} queries` },
      ];
      const agentIcon =
        idx.AgentType === 'CompanyAgent' ? (
          <PiBuildingLight className="text-primary-500 dark:text-primary-300" size={18} />
        ) : (
          <GoDependabot className="text-primary-500 dark:text-primary-300" size={18} />
        );
      return (
        <ListItem
          key={idx.id}
          title={idx.IndexName}
          subtitle={idx.system_instruction_snippet}
          details={details}
          badges={badges}
          onClick={() => handleIndexClick(idx)}
          link={`/ai-studio/${encodeURIComponent(idx.IndexName)}`}
          icon={agentIcon} // Pass the icon here
        />
      );
    });
  }, [indexes, t]);

  return (
    <div className="bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      <div
        className={`${
          isShrunk && !isCreatingAgent ? 'sticky top-[80px] z-20' : ''
        } transition-all duration-200 bg-background-light dark:bg-background-dark`}
      >
        {!isCreatingAgent && (
          <div>
            <Header
              title={isCreatingAgent ? t('Add New Agent') : t('Agents')}
              subtitle={
                isCreatingAgent
                  ? t('Fill in the details below to add a new agent.')
                  : t('Manage your platform agents.')
              }
              actionLabel={isCreatingAgent ? t('Cancel') : t('Create Agent')}
              onActionClick={isCreatingAgent ? handleAddAgentClose : handleAddAgentClick}
            />
            <FilterSearchBar
              filters={filters}
              sortOptions={sortOptions}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              onSortChange={handleSortChange}
              onSearchChange={handleSearchChange}
              sortValue={sortValue}
              extraControls={
                <Button
                  variant="tertiary"
                  onClick={toggleViewMode}
                  size="small"
                  tooltip={viewMode === 'grid' ? t('Switch to Table View') : t('Switch to Grid View')}
                >
                  {viewMode === 'grid' ? <PiTableLight className="h-5 w-5" /> : <PiGridFourLight className="h-5 w-5" />}
                </Button>
              }
            />
          </div>
        )}
      </div>

      <div className="flex-grow overflow-y-auto h-full" ref={indexListRef}>
        {isCreatingAgent ? (
          <div className="w-2/3 mx-auto">
            <CreateAgentForm onCancel={handleAddAgentClose} />
          </div>
        ) : isIndexesLoading ? (
          <ApiLoader />
        ) : (
          <>
            {viewMode === 'grid' ? (
              <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                {gridData}
              </div>
            ) : (
              <div className="p-4">
                {indexes.length > 0 ? (
                  <Table
                    columns={columns}
                    data={tableData}
                    onRowClick={(row) => history.push(`/ai-studio/${encodeURIComponent(row[t('Agent Name')])}`)}
                  />
                ) : (
                  <div className="flex justify-center items-center h-[calc(100vh-200px)]">
                    <Badge color="gray">{t('No agents found. Click "Create New Agent" to get started.')}</Badge>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AIStudioIndexListPage;