import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import BankForm from "./BankForm";
const sripeKey = process.env.REACT_APP_STRIPE_KEY || ''
const stripePromise = loadStripe(sripeKey);

const BankFormItems = () => {

    const [stripe, setStripe] = useState<Stripe | null>(null);
  
    useEffect(() => {
      const loadStripeAsync = async () => {
        const stripe = await stripePromise;
        setStripe(stripe);
      };
  
      loadStripeAsync();
    }, []);

    if (!stripe) {
        return null;
      }
  
  
    return (
      <Elements stripe={stripe}>
          <BankForm />
      </Elements>
    )
  }
  export default BankFormItems;