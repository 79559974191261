import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { RootState, useAppDispatch } from '../../../../store';
import { getUserType } from '../../../../utils/authUtils';
import { EditUser, userInformation } from '../../../../store/actions/DataAction';
import { showFeedbackModal } from '../../../../store/actions/UserFeedbackActions';
import { showPasswordProfileModal } from '../../../../store/actions/modalActions';
import Input from '../../../shared/TailwindComponents/Input';
import Button from '../../../shared/TailwindComponents/Button';
import { FaSpinner } from 'react-icons/fa';
import { PiEnvelopeLight, PiNumpadLight, PiUserLight } from 'react-icons/pi';

const PersonalTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userInfo) || {};
  const userType = getUserType();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    FirstName: yup.string().required(t('Required')),
    LastName: yup.string().required(t('Required')),
    PhoneNumber: yup.string().required(t('Required')),
  });

  const formik = useFormik({
    initialValues: {
      FirstName: userData.FirstName || '',
      LastName: userData.LastName || '',
      PhoneNumber:
        userType === 'LawFirmAdmin' || userType === 'BusinessAdmin'
          ? userData.PrimaryContact
          : userData.PhoneNumber || '',
      StreetAddress: userData.StreetAddress || '',
      Email: userData.Email || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('UserID', userData.UserID);
      formData.append('UserType', userType);
      Object.keys(values).forEach((key) => formData.append(key, (values as any)[key] ?? ''));
      await dispatch(EditUser(formData, onSuccess));
      setIsLoading(false);
    },
  });

  const onSuccess = () => {
    dispatch(userInformation());
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: t('Account updated'),
        modalType: 'success',
        duration: 3000,
      })
    );
  };

  const handleEditSubmit = () => {
    dispatch(showPasswordProfileModal(formik.values, userData.UserID, userType, onSuccess));
  };

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3 animate-fade-in-up">
      <div className="px-4 sm:px-6 md:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
          {t('Personal Information')}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
          {t('Set your personal details and profile information.')}
        </p>
      </div>
      <div className="md:col-span-2">
        <form onSubmit={formik.handleSubmit} className="space-y-8">
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
            <div className="sm:col-span-3">
              <Input
                label={t('First Name')}
                id="FirstName"
                name="FirstName"
                type="text"
                placeholder={t('Enter your first name')}
                value={formik.values.FirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.FirstName && Boolean(formik.errors.FirstName)}
                helperText={formik.touched.FirstName && formik.errors.FirstName as string}
                italicTxt={t('Your first name')}
                className="focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-300"
                icon={<PiUserLight />}
              />
            </div>
            <div className="sm:col-span-3">
              <Input
                label={t('Last Name')}
                id="LastName"
                name="LastName"
                type="text"
                placeholder={t('Enter your last name')}
                value={formik.values.LastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.LastName && Boolean(formik.errors.LastName)}
                helperText={formik.touched.LastName && formik.errors.LastName as string}
                italicTxt={t('Your last name')}
                className="focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-300"
                icon={<PiUserLight />}
              />
            </div>
            <Tooltip
              title={t('You cannot change this field. If you need to change it please contact support.')}
              placement="bottom"
            >
              <div className="col-span-full">
                <Input
                  label={t('Email')}
                  id="Email"
                  name="Email"
                  type="email"
                  placeholder={t('Enter your email')}
                  value={formik.values.Email}
                  disabled
                  italicTxt={t('Your registered email address')}
                  className="bg-gray-200 dark:bg-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-300"
                  icon={<PiEnvelopeLight />}
                />
              </div>
            </Tooltip>
            <div className="col-span-full">
              <Input
                label={t('Phone Number')}
                id="PhoneNumber"
                name="PhoneNumber"
                type="text"
                placeholder={t('Enter your phone number')}
                value={formik.values.PhoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.PhoneNumber && Boolean(formik.errors.PhoneNumber)}
                helperText={formik.touched.PhoneNumber && formik.errors.PhoneNumber as string}
                italicTxt={t('Your contact phone number')}
                className="focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-300"
                icon={<PiNumpadLight />}
              />
            </div>
          </div>
          <div className="mt-8 flex gap-x-4 justify-end">
            <Button
              variant="primary"
              size="small"
              onClick={handleEditSubmit}
              disabled={!formik.dirty || isLoading}
            >
              {isLoading ? (
                <span className="flex items-center">
                  <FaSpinner className="animate-spin mr-2" />
                  {t('Saving...')}
                </span>
              ) : (
                t('Save')
              )}
            </Button>
            {formik.dirty && (
              <Button
                size="small"
                variant="destructive"
                onClick={() => formik.resetForm()}
              >
                {t('Cancel')}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalTab;