import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import Table from '../shared/TailwindComponents/Table';
import { fetchProjects } from '../../store/actions/ProjectActions';
import { BadgeColor, BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';
import ProjectCreate from './ProjectCreate';
import { usePermission } from '../../hooks/userPermissions';
import ApiLoader from '../ApiLoader';
import { PiBriefcase, PiGridFourLight, PiTableLight } from "react-icons/pi";
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import { convertShortDateWithTranslation } from '../shared/DateConverter';

// Define status to color mapping for badges
const statusToColorMap: Record<string, BadgeColor> = {
  'Active': 'green',
  'Pending Approval': 'yellow',
  'Approved': 'blue',
  'Completed': 'gray',
  'Overdue': 'red',
  'Closed': 'gray',
};

const Projects: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  // Redux store
  const allProjects = useSelector((state: RootState) => Object.values(state.projects.projects || {}));
  const isProjectsLoading = useSelector((state: RootState) => state.projects.loading);

  // Local state
  const [isAddingProject, setIsAddingProject] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('creation_desc');

  // Scroll handling for sticky header
  const projectListRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const shrinkThreshold = 150;
  const isShrunk = scrollTop >= shrinkThreshold;

  // Permission hook
  const { hasAccess, isReadOnly } = usePermission('projects');

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  // Handle scrolling for the shrinking header effect
  const handleScroll = () => {
    const scrollPosition = projectListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    const projectList = projectListRef.current;
    if (projectList) {
      projectList.addEventListener('scroll', handleScroll);
      return () => projectList.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // FilterSearchBar callbacks
  const handleSearchChange = (query: string) => setSearchQuery(query);

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({ ...prev, [filterName]: selectedValues }));
  };

  const handleSortChange = (value: string) => setSortValue(value);

  const toggleViewMode = () => setViewMode((prev) => (prev === 'grid' ? 'table' : 'grid'));

  const handleAddProjectClick = () => setIsAddingProject(true);
  const handleAddProjectClose = () => setIsAddingProject(false);
  const handleProjectCreateSuccess = () => {
    setIsAddingProject(false);
    dispatch(fetchProjects());
  };
  const handleProjectClick = (project: any) => history.push(`/projects/${project.ProjectID}`);

  const isValidDate = (dateString: string | null | undefined): boolean => {
    if (!dateString) return false;
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  // Define filters and sort options
  const filters = [
    {
      id: 'Status',
      name: 'Project Status',
      options: [
        { value: 'Active', label: 'Active' },
        { value: 'Pending Approval', label: 'Pending Approval' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Overdue', label: 'Overdue' },
        { value: 'Closed', label: 'Closed' },
      ],
    },
    {
      id: 'PriorityLevel',
      name: 'Priority Level',
      options: [
        { value: '1', label: 'Low' },
        { value: '2', label: 'Medium' },
        { value: '3', label: 'High' },
      ],
    },
  ];

  const sortOptions = [
    { value: 'creation_asc', label: 'Creation (Oldest First)', current: false },
    { value: 'creation_desc', label: 'Creation (Newest First)', current: true },
    { value: 'name_asc', label: 'Name (A-Z)', current: false },
    { value: 'name_desc', label: 'Name (Z-A)', current: false },
    { value: 'start_date_asc', label: 'Start Date (Earliest First)', current: false },
    { value: 'start_date_desc', label: 'Start Date (Latest First)', current: false },
    { value: 'end_date_asc', label: 'End Date (Earliest First)', current: false },
    { value: 'end_date_desc', label: 'End Date (Latest First)', current: false },
  ];

  // Filter and sort projects
  const filteredProjects = useMemo(() => {
    return allProjects.filter((project: any) => {
      let matches = true;
      if (selectedFilters['Status']?.length > 0) {
        matches = matches && selectedFilters['Status'].includes(project.Status);
      }
      if (selectedFilters['PriorityLevel']?.length > 0) {
        matches = matches && selectedFilters['PriorityLevel'].includes(project.PriorityLevel);
      }
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        matches = matches && (
          (project.ProjectName || '').toLowerCase().includes(searchLower) ||
          (project.ProjectDescription || '').toLowerCase().includes(searchLower)
        );
      }
      return matches;
    });
  }, [allProjects, selectedFilters, searchQuery]);

  const sortedProjects = useMemo(() => {
    return [...filteredProjects].sort((a: any, b: any) => {
      if (sortValue === 'creation_asc' || sortValue === 'creation_desc') {
        const aDate = isValidDate(a.CreatedAt) ? new Date(a.CreatedAt).getTime() : 0;
        const bDate = isValidDate(b.CreatedAt) ? new Date(b.CreatedAt).getTime() : 0;
        return sortValue === 'creation_asc' ? aDate - bDate : bDate - aDate;
      }
      if (sortValue === 'name_asc') return (a.ProjectName || '').localeCompare(b.ProjectName || '');
      if (sortValue === 'name_desc') return (b.ProjectName || '').localeCompare(a.ProjectName || '');
      if (sortValue === 'start_date_asc' || sortValue === 'start_date_desc') {
        const aDate = isValidDate(a.StartDate) ? new Date(a.StartDate).getTime() : 0;
        const bDate = isValidDate(b.StartDate) ? new Date(b.StartDate).getTime() : 0;
        return sortValue === 'start_date_asc' ? aDate - bDate : bDate - aDate;
      }
      if (sortValue === 'end_date_asc' || sortValue === 'end_date_desc') {
        const aDate = isValidDate(a.EndDate) ? new Date(a.EndDate).getTime() : 0;
        const bDate = isValidDate(b.EndDate) ? new Date(b.EndDate).getTime() : 0;
        return sortValue === 'end_date_asc' ? aDate - bDate : bDate - aDate;
      }
      return 0;
    });
  }, [filteredProjects, sortValue]);
  // Define table columns
  const columns = ['Project Name', 'Status', 'Priority', 'Lead', 'Members', 'Services', 'End Date'];

  // Prepare table data
  const tableData = useMemo(() => {
    return sortedProjects.map((project: any) => {
      return {
        id: project.ProjectID,
        'Project Name': project.ProjectName || `Project ${project.ProjectID}`,
        'Status': t(project.Status),
        'Priority': project.PriorityLevel === '1' ? t('Low') : project.PriorityLevel === '2' ? t('Medium') : t('High'),
        'Lead': project.ProjectLeadName || 'N/A',
        'Members': project.ProjectMembers?.length || 0,
        'Services': project.ServiceAllocations?.length || 0,
        'End Date': isValidDate(project.EndDate)
          ? convertShortDateWithTranslation(new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(project.EndDate)))
          : 'N/A',
      };
    });
  }, [sortedProjects, t]);

  // Prepare grid data
  const gridData = useMemo(() => {
    return sortedProjects.map((project: any) => {
      const badges: BadgeItem[] = [
        { label: project.Status, color: statusToColorMap[project.Status] || 'gray' },
        {
          label: project.PriorityLevel === '1' ? t('Low') : project.PriorityLevel === '2' ? t('Medium') : t('High'),
          color: project.PriorityLevel === '1' ? 'green' : project.PriorityLevel === '2' ? 'yellow' : 'red',
        },
      ];

      const details = [
        { label: 'Lead', value: project.ProjectLeadName || 'N/A' },
        { label: 'Members', value: project.ProjectMembers?.length || 0 },
        { label: 'Services', value: project.ServiceAllocations?.map((s: any) => s.ServiceName).join(', ') || 'None' },
        {
          label: 'End Date',
          value: isValidDate(project.EndDate)
            ?  convertShortDateWithTranslation(new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(project.EndDate)))
            : 'N/A',
        },
      ];

      return (
        <ListItem
          key={project.ProjectID}
          title={project.ProjectName || `Project ${project.ProjectID}`}
          subtitle={project.ProjectDescription || ''}
          details={details}
          badges={badges}
          onClick={() => handleProjectClick(project)}
          link={`/projects/${project.ProjectID}`}
        />
      );
    });
  }, [sortedProjects, t]);

  return (
    <div className="h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      <div className={isShrunk && !isAddingProject ? 'sticky top-[80px] z-20' : ''}>
        {!isAddingProject && (
          <Header
            title={t('Projects')}
            subtitle={t('Manage your business projects and collaborate with your team.')}
            icon={<PiBriefcase className="text-primary-500" size={24} />}
            actionLabel={t('Add Project')}
            onActionClick={handleAddProjectClick}
          />
        )}
        {!isAddingProject && (
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
            sortValue={sortValue}
            extraControls={
              <Button
                variant="tertiary"
                size="small"
                onClick={toggleViewMode}
                tooltip={viewMode === 'grid' ? t('Switch to Table View') : t('Switch to Grid View')}
                className="hover:bg-primary-100 dark:hover:bg-primary-700 transition-all duration-200"
              >
                {viewMode === 'grid' ? <PiTableLight className="h-5 w-5" /> : <PiGridFourLight className="h-5 w-5" />}
              </Button>
            }
          />
        )}
      </div>
      <div className="flex-grow overflow-y-auto h-full transition-all duration-300" ref={projectListRef}>
        {isAddingProject ? (
          <ProjectCreate
            onCancel={handleAddProjectClose}
            onSuccess={handleProjectCreateSuccess}
          />
        ) : isProjectsLoading && !sortedProjects.length ? (
          <div className="flex justify-center items-center h-full">
            <ApiLoader />
          </div>
        ) : (
          <div className="p-4 relative">
            {isProjectsLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60 dark:bg-gray-800 dark:bg-opacity-60 z-50">
                <ApiLoader />
              </div>
            )}
            {viewMode === 'grid' ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">{gridData}</div>
            ) : sortedProjects.length > 0 ? (
              <Table columns={columns} data={tableData} onRowClick={(row) => history.push(`/projects/${row.id}`)} />
            ) : (
              <div className="flex justify-center items-center h-full">
                <Badge color="gray" className="text-lg">{t('No projects available')}</Badge>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;