import React, { useEffect, useState } from 'react';
import { fetchFeedbackTypes, sendFeedback } from '../../store/actions/FeedbackAction';
import { getUserType } from '../../store/actions/ChatAction';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { FeedbackType } from '../../store/reducer/feedbackReducer';
import { useTranslation } from 'react-i18next';
import ApiLoader from '../ApiLoader';
import { fetchCategories } from '../../store/actions/HelpAction';
import Button from '../shared/TailwindComponents/Button';
import Textarea from '../shared/TailwindComponents/Textarea';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import {
  PiUserFocusLight,
  PiGearLight,
  PiSpeedometerLight,
  PiLockKeyLight,
  PiScalesLight,
  PiLifebuoyLight,
  PiQuestionLight
} from 'react-icons/pi';
import clsx from 'clsx';

const MAX_WORD_LIMIT = 500;

const Feedback: React.FC = () => {
  const [feedback, setFeedback] = useState('');
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackType | null>(null);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const userType = getUserType();
  const { t } = useTranslation();

  const feedbackTypes = useSelector((state: RootState) => state.feedback.feedbackTypes || []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(fetchFeedbackTypes());
      await dispatch(fetchCategories());
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  // Optionally auto-select the first feedback type (remove if not desired)
  useEffect(() => {
    if (feedbackTypes.length > 0 && !selectedFeedback) {
      setSelectedFeedback(feedbackTypes[0]);
    }
  }, [feedbackTypes, selectedFeedback]);

  const handleSubmitFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isOverLimit) {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Your feedback exceeds the 500-word limit.'),
          duration: 3000,
          showModal: true,
        })
      );
      return;
    }
    if (selectedFeedback && feedback.trim()) {
      try {
        await dispatch(
          sendFeedback({
            FeedbackContent: feedback,
            UserType: userType,
            FeedbackTypeID: selectedFeedback.FeedbackTypeID,
          })
        );
        setFeedback('');
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: t('Feedback sent successfully!'),
            duration: 3000,
            showModal: true,
          })
        );
      } catch (error) {
        console.error('Failed to send feedback:', error);
        dispatch(
          showFeedbackModal({
            modalType: 'error',
            message: t('Failed to send feedback. Please try again.'),
            duration: 3000,
            showModal: true,
          })
        );
      }
    }
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    const wordCount = text.trim() ? text.trim().split(/\s+/).length : 0;
    setFeedback(text);
    setIsOverLimit(wordCount > MAX_WORD_LIMIT);
  };

  const getIconForType = (name: string) => {
    switch (name) {
      case 'Usability & User Experience':
        return <PiUserFocusLight className="h-6 w-6 text-primary-500" />;
      case 'Features & Functionality':
        return <PiGearLight className="h-6 w-6 text-primary-500" />;
      case 'Performance & Reliability':
        return <PiSpeedometerLight className="h-6 w-6 text-primary-500" />;
      case 'Security & Privacy':
        return <PiLockKeyLight className="h-6 w-6 text-primary-500" />;
      case 'Legal & Compliance':
        return <PiScalesLight className="h-6 w-6 text-primary-500" />;
      case 'Support':
        return <PiLifebuoyLight className="h-6 w-6 text-primary-500" />;
      default:
        return <PiQuestionLight className="h-6 w-6 text-primary-500" />;
    }
  };

  const wordCount = feedback.trim() ? feedback.trim().split(/\s+/).length : 0;

  return (
    <div className="flex flex-col items-center justify-center w-full h-full max-w-4xl mx-auto px-4">
      {isLoading ? (
        <ApiLoader />
      ) : (
        <>
          {/* Feedback Type Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8 w-full">
            {feedbackTypes.map((type) => (
              <div
                key={type.FeedbackTypeID}
                className={clsx(
                  'p-4 border rounded-lg cursor-pointer transition-all duration-300',
                  selectedFeedback?.FeedbackTypeID === type.FeedbackTypeID
                    ? 'border-primary-500 bg-primary-50 dark:bg-primary-900 shadow-md'
                    : 'border-gray-300 dark:border-gray-600 hover:border-primary-500 hover:shadow-md'
                )}
                onClick={() => setSelectedFeedback(type)}
              >
                <div className="flex items-center space-x-3">
                  {getIconForType(type.Name)}
                  <h3 className="text-md font-semibold text-gray-900 dark:text-white">{t(type.Name)}</h3>
                </div>
                <p className="mt-2 text-xs text-gray-600 dark:text-gray-300">{t(type.Description)}</p>
              </div>
            ))}
          </div>

          {/* Feedback Form */}
          <form className="w-full" onSubmit={handleSubmitFeedback}>
            <Textarea
                id="feedback-input"
                placeholder={t('Enter your feedback here...')}
                value={feedback}
                onChange={handleFeedbackChange}
                rows={6}
                disabled={!selectedFeedback}
                className={clsx(
                  'w-full',
                  !selectedFeedback && 'opacity-50 cursor-not-allowed'
                )} label={''}            />
            <div className="flex justify-between items-center mt-2">
              <span className={clsx('text-sm', isOverLimit ? 'text-red-500' : 'text-gray-500')}>
                {wordCount} / {MAX_WORD_LIMIT}
              </span>
              {isOverLimit && (
                <p className="text-sm text-red-500">{t('You have exceeded the 500-word limit')}</p>
              )}
              <Button
                type="submit"
                size="small"
                variant="primary"
                disabled={!selectedFeedback || !feedback.trim() || isOverLimit}
              >
                {t('Send')}
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Feedback;