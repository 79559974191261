import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/24/solid';
import Badge from '../../shared/TailwindComponents/Badge';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import { fetchEmployeeDetails } from '../../../store/actions/organization/employeeActions';
import Tabs from '../../shared/TailwindComponents/Tabs';
import { showFileReaderSidePanel } from '../../../store/actions/sidePanelActions';
import { showSidePanel } from '../../../store/actions/sidePanelActions';
import apis from '../../../utils/apis';
import InfiniteScroll from 'react-infinite-scroller';

import {
  PiBriefcaseLight,
  PiUsersLight,
  PiEnvelopeLight,
  PiPhoneLight,
  PiCalendarLight,
  PiClockLight,
  PiCertificateLight,
  PiBookOpenLight,
  PiTrophyLight,
  PiTranslateLight,
  PiTagLight,
  PiCurrencyCircleDollarLight,
  PiFileTextLight,
} from 'react-icons/pi';
import Table from '../../shared/TailwindComponents/Table';
import SkeletonLoader from './SkeletonLoader';
import TableSkeletonLoader from '../../shared/TailwindComponents/TableSkeletonLoader';
import { LayoutContext } from '../../../contexts/LayoutContext';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/TailwindComponents/Button';
import AssignTaskForm from './AssignTaskForm';

// Helper to parse arrays, JSON strings, or comma-separated
function parseToItemsArray(field: any): Array<{ title: string; fileUrl?: string }> {
  if (!field) return [];
  if (Array.isArray(field)) {
    return field.map((item: any, idx: number) => ({
      title: typeof item.title === 'string' ? item.title : `Item (${idx + 1})`,
      fileUrl: typeof item.fileUrl === 'string' ? item.fileUrl : undefined,
    }));
  }
  if (typeof field === 'string') {
    try {
      const parsed = JSON.parse(field);
      if (Array.isArray(parsed)) {
        return parsed.map((item: any, idx: number) => ({
          title: typeof item.title === 'string' ? item.title : `Item (${idx + 1})`,
          fileUrl: typeof item.fileUrl === 'string' ? item.fileUrl : undefined,
        }));
      }
    } catch {
      // fallback to comma-separated
      const items = field.split(',').map((x: string) => x.trim()).filter(Boolean);
      return items.map((val, i) => ({ title: val }));
    }
  }
  return [];
}

// Types
interface LawFirmProfile {
  ProfilePicture?: string;
  Designation?: string;
  Bio?: string;
  ExperienceYears?: number;
  HourlyRate?: number;
  LanguagesSpoken?: string;
  Specializations?: string;
  Awards?: any;
  Certifications?: any;
  Publications?: any;
  Department?: string;
  JoinDate?: string;
  Role?: string;
  Status?: string;
}

interface BusinessProfile {
  Designation?: string;
  Status?: string;
  Department?: string;
  JoinDate?: string;
  Role?: string;
  ProfilePicture?: string;
}

interface Employee {
  UserID: string;
  FirstName: string;
  LastName: string;
  Email?: string;
  PhoneNumber?: string;
  is_deleted?: boolean;
  ProfilePicture?: string | null;
  LawFirmProfile?: LawFirmProfile;
  BusinessProfile?: BusinessProfile;
  RatingsAverage?: number;
  RatingsCount?: number;
  Tasks?: any[];
  Case?: any[];
}

interface EmployeeDetailParams {
  employeeId: string;
}

////////////////////////////////////////////////////////////////////////////////
// EmployeeDetail Component
const EmployeeDetail: React.FC = () => {
  const { t } = useTranslation();
  const { employeeId } = useParams<EmployeeDetailParams>();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();
  const employeesState = useSelector((state: RootState) => state.employees);
  const { error } = employeesState;

  const [employee, setEmployee] = useState<Employee | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'info' | 'tasks' | 'cases' | 'timekeeping'>('info');
  const [tasks, setTasks] = useState<any[]>([]);
  const [tasksLoading, setTasksLoading] = useState<boolean>(false);
  const [tasksError, setTasksError] = useState<string>('');
  const [cases, setCases] = useState<any[]>([]);
  const [casesLoading, setCasesLoading] = useState<boolean>(false);
  const [casesError, setCasesError] = useState<string>('');
  const [tasksPage, setTasksPage] = useState(1);
  const [tasksHasMore, setTasksHasMore] = useState(true);
  const [casesPage, setCasesPage] = useState(1);
  const [casesHasMore, setCasesHasMore] = useState(true);
  const { setIsContentScrollable } = useContext(LayoutContext);
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [previousTab, setPreviousTab] = useState(activeTab);

  useEffect(() => {
    setIsContentScrollable(false);
    return () => setIsContentScrollable(false);
  }, [setIsContentScrollable]);

  const loadDetails = useCallback(async () => {
    setLoading(true);
    try {
      const details: any = await dispatch(fetchEmployeeDetails(employeeId));
      if (details) setEmployee(details);
      else
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('could_not_load_employee_details'),
            modalType: 'error',
            duration: 3000,
          })
        );
    } finally {
      setLoading(false);
    }
  }, [dispatch, employeeId, t]);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  useEffect(() => {
    if (!employee || !employee.UserID) return;
    if (activeTab === 'tasks' && tasks.length === 0) {
      setTasks([]);
      setTasksPage(1);
      setTasksHasMore(true);
      fetchEmployeeTasks(1);
    } else if (activeTab === 'cases' && cases.length === 0) {
      setCases([]);
      setCasesPage(1);
      setCasesHasMore(true);
      fetchEmployeeCases(1);
    }
  }, [activeTab, employee]);

  const fetchEmployeeTasks = async (page: number) => {
    setTasksLoading(true);
    try {
      const resp = await apis.post('/tasks/get_assigned_tasks', {
        UserEmail: employee?.Email,
        page,
        limit: 10,
      });
      if (resp?.status === 200) {
        const newTasks = resp.data.Tasks;
        setTasks((prev) => [...prev, ...newTasks]);
        setTasksHasMore(page * 10 < resp.data.total);
      } else {
        setTasksError(t('could_not_fetch_tasks'));
      }
    } catch (err) {
      console.error('Error fetching tasks', err);
      setTasksError(t('failed_to_fetch_tasks'));
    } finally {
      setTasksLoading(false);
    }
  };

  const fetchEmployeeCases = async (page: number) => {
    setCasesLoading(true);
    try {
      const resp = await apis.post('/cases/get_assigned_cases', {
        UserEmail: employee?.Email,
        page,
        limit: 10,
      });
      if (resp?.status === 200) {
        const newCases = resp.data.cases;
        setCases((prev) => [...prev, ...newCases]);
        setCasesHasMore(page * 10 < resp.data.total);
      } else {
        setCasesError(t('could_not_fetch_cases'));
      }
    } catch (err) {
      console.error('Error fetching cases', err);
      setCasesError(t('failed_to_fetch_cases'));
    } finally {
      setCasesLoading(false);
    }
  };

  useEffect(() => {
    if (employee) {
      const empName = `${employee.FirstName || t('Unknown')} ${employee.LastName || t('Employee')}`;
      setBreadcrumbs([
        { name: t('Management'), href: '/management', current: false },
        { name: t('Employees'), href: '/management/employees', current: false },
        { name: empName, href: `/management/employees/${employeeId}`, current: true },
      ]);
    } else {
      setBreadcrumbs([
        { name: t('Management'), href: '/management', current: false },
        { name: t('Employees'), href: '/management/employees', current: false },
        { name: t('Loading'), href: `/management/employees/${employeeId}`, current: true },
      ]);
    }
    return () => setBreadcrumbs([]);
  }, [employee, employeeId, setBreadcrumbs, t]);

  if (loading) return <SkeletonLoader />;

  if (error || !employee) {
    return <div className="text-red-600 p-4">{error || t('employee_not_found')}</div>;
  }

  const employeeStatus = employee.is_deleted ? t('inactive') : t('active');
  const lawFirmPic = employee.LawFirmProfile?.ProfilePicture;
  const finalProfilePic = lawFirmPic || employee.ProfilePicture;
  const ratingStars = [0, 1, 2, 3, 4];
  const avgRating = employee.RatingsAverage || 0;
  const totalReviews = employee.RatingsCount || 0;
  const isLawyer = !!employee.LawFirmProfile && !employee.BusinessProfile;
  const profile = isLawyer ? employee.LawFirmProfile || {} : employee.BusinessProfile || {};
  const {
    Bio,
    LanguagesSpoken,
    Specializations,
    Awards,
    Certifications,
    Publications,
  } = employee.LawFirmProfile || {};

  const specializationsArr = Specializations ? Specializations.split(',').map((s) => s.trim()) : [];
  const languagesArr = LanguagesSpoken ? LanguagesSpoken.split(',').map((l) => l.trim()) : [];
  const awardsList = parseToItemsArray(Awards);
  const certsList = parseToItemsArray(Certifications);
  const publicationsList = parseToItemsArray(Publications);

  const handleOpenFile = (fileUrl?: string) => {
    if (fileUrl) dispatch(showFileReaderSidePanel(fileUrl));
  };

  const handleOpenTaskDetail = (taskObj: any) => {
    dispatch(
      showSidePanel({
        type: 'employeeTaskDetail',
        showSidePanel: true,
        width: '50%',
        taskData: taskObj,
      })
    );
  };

  const handleOpenCaseDetail = (caseObj: any) => {
    dispatch(
      showSidePanel({
        type: 'employeeCaseDetail',
        showSidePanel: true,
        width: '50%',
        caseData: caseObj,
      })
    );
  };

  const handleShowTaskForm = () => {
    setPreviousTab(activeTab);
    setShowTaskForm(true);
  };

  // Function to handle task creation and redirection
  const handleTaskCreated = () => {
    setShowTaskForm(false);
    setActiveTab(previousTab); // Redirect to previous tab
    fetchEmployeeTasks(1); // Refresh tasks
  };

  console.log(activeTab)

  const tabs = [
    { label: 'Info', value: 'info' },
    { label: 'Tasks', value: 'tasks' },
    { label: 'Cases', value: 'cases' },
    { label: 'Timekeeping', value: 'timekeeping', rightAligned: true },
  ];

  const renderTasksTab = () => {
    if (tasksError) return <div className="text-red-600 p-4 text-center">{tasksError}</div>;
    if (tasks.length === 0 && !tasksHasMore && !tasksLoading)
      return (
        <div className="flex justify-center mt-5">
          <Badge color="gray">{t('no_tasks_available')}</Badge>
        </div>
      );

    const taskColumns = ['task name', 'status', 'due date'];
    const taskData = tasks.map((task) => ({
      id: task.TaskID || task.id,
      'task name': task.Title || t('None'),
      'status': t(task.Status) || t('None'),
      'due date': task.TaskDeadline ? new Date(task.TaskDeadline).toLocaleDateString() : t('None'),
    }));

    return (
      <div className="overflow-y-auto max-h-[450px]">
        <div className="mt-4 flex justify-end">
          <Button variant="primary" size="small" onClick={handleShowTaskForm}>
            {t("Assign Task")}
          </Button>
        </div>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!tasksLoading) {
              setTasksPage((prev) => prev + 1);
              fetchEmployeeTasks(tasksPage + 1);
            }
          }}
          hasMore={tasksHasMore}
          loader={<TableSkeletonLoader columns={3} rows={1} key="loader" />}
          useWindow={false}
        >
          <Table
            columns={taskColumns}
            data={taskData}
            onRowClick={(row) => handleOpenTaskDetail(tasks.find((t) => t.TaskID === row.id || t.id === row.id))}
          />
        </InfiniteScroll>
      </div>
    );
  };

  const renderCasesTab = () => {
    if (casesError) return <div className="text-red-600 p-4 text-center">{casesError}</div>;
    if (cases.length === 0 && !casesHasMore && !casesLoading)
      return (
        <div className="flex justify-center mt-5">
          <Badge color="gray">{t('No cases')}</Badge>
        </div>
      );

    const caseColumns = ['case name', 'client', 'status', 'created'];
    const caseData = cases.map((caseItem) => ({
      id: caseItem.CaseID || caseItem.id,
      'case name': caseItem.CaseName || `${t('case')} ${caseItem.CaseID}`,
      'client': caseItem.ClientName || t('None'),
      'status': caseItem.case_status || t('None'),
      'created': caseItem.created_at ? new Date(caseItem.created_at).toLocaleDateString() : t('None'),
    }));

    return (
      <div className="overflow-y-auto max-h-[450px]">
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!casesLoading) {
              setCasesPage((prev) => prev + 1);
              fetchEmployeeCases(casesPage + 1);
            }
          }}
          hasMore={casesHasMore}
          loader={<TableSkeletonLoader columns={4} rows={1} key="loader" />}
          useWindow={false}
        >
          <Table
            columns={caseColumns}
            data={caseData}
            onRowClick={(row) => handleOpenCaseDetail(cases.find((c) => c.CaseID === row.id || c.id === row.id))}
          />
        </InfiniteScroll>
      </div>
    );
  };

  const renderInfoTab = () => {
    return (
      <div className="mt-2 transition-opacity duration-300">
        {/* About Section */}
        <div className="mt-2 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <PiFileTextLight className="text-gray-500 w-5 h-5" />
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {t('About')}
            </h3>
          </div>
          <p className="text-sm text-gray-700 dark:text-gray-300 leading-relaxed">
            {Bio ? Bio : t('No information available.')}
          </p>
        </div>

        {/* Specializations Section */}
        <div className="mt-6">
          <div className="flex items-center space-x-2 mb-2">
            <PiTagLight className="text-gray-500 w-5 h-5" />
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {t('Specializations')}
            </h3>
          </div>
          {specializationsArr.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {specializationsArr.map((spec) => (
                <span key={spec} className="inline-flex items-center px-2 py-1 text-sm font-medium text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-300 rounded">
                  {spec}
                </span>
              ))}
            </div>
          ) : (
            <Badge color="gray">{t('No specializations available.')}</Badge>
          )}
        </div>

        {/* Languages Section */}
        <div className="mt-6">
          <div className="flex items-center space-x-2 mb-2">
            <PiTranslateLight className="text-gray-500 w-5 h-5" />
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {t('Languages Spoken')}
            </h3>
          </div>
          {languagesArr.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {languagesArr.map((lang) => (
                <span key={lang} className="inline-flex items-center px-2 py-1 text-sm font-medium text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-300 rounded">
                  {lang}
                </span>
              ))}
            </div>
          ) : (
            <Badge color="gray">{t('No languages specified.')}</Badge>
          )}
        </div>

        {/* Awards, Certifications, and Publications */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Awards Section */}
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <PiTrophyLight className="text-gray-500 w-5 h-5" />
              <h4 className="text-sm font-semibold text-gray-600 dark:text-gray-300">
                {t('Awards')}
              </h4>
            </div>
            {awardsList.length > 0 ? (
              <ul className="space-y-1">
                {awardsList.map((awardItem, idx) => (
                  <li key={idx} className="text-sm text-gray-700 dark:text-gray-300">
                    {awardItem.fileUrl ? (
                      <button
                        type="button"
                        onClick={() => handleOpenFile(awardItem.fileUrl)}
                        className="text-blue-600 hover:underline transition-colors duration-200"
                      >
                        {awardItem.title || t('Untitled')}
                      </button>
                    ) : (
                      <span>{awardItem.title || t('Untitled')}</span>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <Badge color="gray">{t('No awards received.')}</Badge>
            )}
          </div>

          {/* Certifications Section */}
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <PiCertificateLight className="text-gray-500 w-5 h-5" />
              <h4 className="text-sm font-semibold text-gray-600 dark:text-gray-300">
                {t('Certifications')}
              </h4>
            </div>
            {certsList.length > 0 ? (
              <ul className="space-y-1">
                {certsList.map((cert, idx) => (
                  <li key={idx} className="text-sm text-gray-700 dark:text-gray-300">
                    {cert.fileUrl ? (
                      <button
                        type="button"
                        onClick={() => handleOpenFile(cert.fileUrl)}
                        className="text-blue-600 hover:underline transition-colors duration-200"
                      >
                        {cert.title || t('Untitled')}
                      </button>
                    ) : (
                      <span>{cert.title || t('Untitled')}</span>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <Badge color="gray">{t('No certifications available.')}</Badge>
            )}
          </div>

          {/* Publications Section */}
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <PiBookOpenLight className="text-gray-500 w-5 h-5" />
              <h4 className="text-sm font-semibold text-gray-600 dark:text-gray-300">
                {t('Publications')}
              </h4>
            </div>
            {publicationsList.length > 0 ? (
              <ul className="space-y-1">
                {publicationsList.map((pub, idx) => (
                  <li key={idx} className="text-sm text-gray-700 dark:text-gray-300">
                    {pub.fileUrl ? (
                      <a
                        href={pub.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline transition-colors duration-200"
                      >
                        {pub.title || t('Untitled')}
                      </a>
                    ) : (
                      <span>{pub.title || t('Untitled')}</span>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <Badge color="gray">{t('No publications available.')}</Badge>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'info':
        return renderInfoTab()
      case 'tasks':
        return renderTasksTab();
      case 'cases':
        return renderCasesTab();
      case 'timekeeping':
        return (
          <div className="flex justify-center mt-5">
            <Badge color="gray">{t('Coming soon')}</Badge>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mx-4 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg transition-all duration-300">
      <div className="flex flex-col md:flex-row md:items-center md:space-x-6">
        <div className="flex-shrink-0 mb-4 md:mb-0">
          {finalProfilePic ? (
            <img
              src={finalProfilePic}
              alt={`${employee.FirstName || t('Unknown')} ${employee.LastName || t('Employee')}`}
              className="w-32 h-32 md:w-40 md:h-40 rounded-full object-cover border-2 border-gray-200 dark:border-gray-700 transition-transform duration-200 hover:scale-105"
            />
          ) : (
            <div className="flex items-center justify-center w-32 h-32 md:w-40 md:h-40 bg-gray-200 dark:bg-gray-700 rounded-full text-4xl font-bold text-gray-600 dark:text-gray-400 transition-transform duration-200 hover:scale-105">
              {(employee.FirstName?.[0] || 'U') + (employee.LastName?.[0] || 'E')}
            </div>
          )}
        </div>
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-gray-100 transition-colors duration-200">
              {employee.FirstName || t('Unknown')} {employee.LastName || t('Employee')}
            </h2>
            <span
              className={`text-sm font-medium px-2 py-1 rounded capitalize  ${employeeStatus === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                }`}
            >
              {t(employeeStatus)}
            </span>
          </div>
          <div className="mt-3 flex items-center space-x-2">
            {ratingStars.map((_, idx) => (
              <StarIcon
                key={idx}
                className={avgRating > idx ? 'text-yellow-400 h-5 w-5' : 'text-gray-300 h-5 w-5'}
              />
            ))}
            <p className="text-gray-600 dark:text-gray-300 text-sm">
              {avgRating.toFixed(1)} / 5.0 ({totalReviews} {t('Reviews')})
            </p>
          </div>
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {employee.Email && (
              <div className="flex items-center space-x-2">
                <PiEnvelopeLight className="text-gray-500 w-5 h-5" />
                <span className="text-sm text-gray-700 dark:text-gray-300 truncate">
                  {employee.Email || t('None')}
                </span>
              </div>
            )}
            {employee.PhoneNumber && (
              <div className="flex items-center space-x-2">
                <PiPhoneLight className="text-gray-500 w-5 h-5" />
                <span className="text-sm text-gray-700 dark:text-gray-300">
                  {employee.PhoneNumber || t('None')}
                </span>
              </div>
            )}
            {isLawyer && (profile as LawFirmProfile).ExperienceYears !== undefined && (
              <div className="flex items-center space-x-2">
                <PiClockLight className="text-gray-500 w-5 h-5" />
                <span className="text-sm text-gray-700 dark:text-gray-300">
                  {(profile as LawFirmProfile).ExperienceYears !== null
                    ? `${(profile as LawFirmProfile).ExperienceYears} ${t('years')}`
                    : t('0')}
                </span>
              </div>
            )}
            {isLawyer && (profile as LawFirmProfile).HourlyRate !== undefined && (
              <div className="flex items-center space-x-2">
                <PiCurrencyCircleDollarLight className="text-gray-500 w-5 h-5" />
                <span className="text-sm text-gray-700 dark:text-gray-300">
                  {(profile as LawFirmProfile).HourlyRate !== null
                    ? `$${(profile as LawFirmProfile).HourlyRate!.toFixed(2)}/${t('hour')}`
                    : t('0')}
                </span>
              </div>
            )}
            {profile.Role && (
              <div className="flex items-center space-x-2">
                <PiBriefcaseLight className="text-gray-500 w-5 h-5" />
                <span className="text-sm text-gray-700 dark:text-gray-300">
                  {profile.Role || t('None')}
                </span>
              </div>
            )}
            {profile.JoinDate && (
              <div className="flex items-center space-x-2">
                <PiCalendarLight className="text-gray-500 w-5 h-5" />
                <span className="text-sm text-gray-700 dark:text-gray-300">
                  {profile.JoinDate ? new Date(profile.JoinDate).toLocaleDateString() : t('None')}
                </span>
              </div>
            )}
            {profile.Department && (
              <div className="flex items-center space-x-2">
                <PiUsersLight className="text-gray-500 w-5 h-5" />
                <span className="text-sm text-gray-700 dark:text-gray-300">
                  {profile.Department || t('None')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-8">

        {showTaskForm ? (
          <AssignTaskForm
            employeeEmail={employee.Email as string}
            onCancel={() => setShowTaskForm(false)}
            onAdd={handleTaskCreated}
          />
        ) : (
          <>
            <Tabs tabs={tabs} activeTab={activeTab} onTabClick={(val) => setActiveTab(val as any)} />
            <div className="mt-4 overflow-y-auto">{renderTabContent()}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeDetail;
