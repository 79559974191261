import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store';
import { AnyAction } from 'redux';
import { userInformation } from '../../../store/actions/DataAction';
import { getActiveSessions } from '../../../store/actions/SecurityActions';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ApiLoader from '../../ApiLoader';
import Tabs from '../../shared/TailwindComponents/Tabs'; 
import PersonalTab from './tabs/PersonalTab';
import PasswordTab from './tabs/PasswordTab';
import DeviceManagementTab from './tabs/DeviceManagementTab';
import BillingTab from './tabs/BillingTab';
import DeleteAccountTab from './tabs/DeleteAccountTab';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';

const AccountSettings: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userInfo);
  const history = useHistory();
  const location = useLocation();

  const userCred = localStorage.getItem('token') || sessionStorage.getItem('token');
  let userId = getUserID();
  let userType = getUserType();
  if (userCred) {
    const parsedData = JSON.parse(userCred);
    userId = parsedData.userId;
    userType = parsedData.userType;
  }

  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'personal';
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        dispatch(userInformation()),
        dispatch(getActiveSessions()),
      ]);
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, userId, userType]);

  useEffect(() => {
    history.push(`?tab=${activeTab}`);
  }, [activeTab, history]);

  const tabs = [
    { label: t('Personal'), value: 'personal' },
    { label: t('Password'), value: 'password' },
    { label: t('Device Management'), value: 'device-management' },
    { label: t('Billing'), value: 'billing' },
    { label: t('Delete Account'), value: 'delete-account' },
  ];

  const handleTabChange = (tabValue: string) => {
    setActiveTab(tabValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'personal':
        return <PersonalTab />;
      case 'password':
        return <PasswordTab />;
      case 'device-management':
        return <DeviceManagementTab />;
      case 'billing':
        return <BillingTab />;
      case 'delete-account':
        return <DeleteAccountTab />;
      default:
        return <PersonalTab />;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center flex-1 min-h-screen">
        <ApiLoader />
      </div>
    );
  }

  return (
    <div className="flex flex-col p-4 bg-background-light dark:bg-background-dark transition-colors duration-300">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-6 animate-fade-in">
        {t('Account Settings')}
      </h1>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabChange} />
      <div className="flex-grow">{renderTabContent()}</div>
    </div>
  );
};

export default AccountSettings;