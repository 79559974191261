import React from 'react';
import clsx from 'clsx';

const ChatSkeleton: React.FC = () => {
  return (
    <div className="flex-1 overflow-y-auto space-y-4 max-w-2/3 w-2/3 self-center relative">
      {[1, 2, 3].map((_, index) => (
        <div
          key={index}
          className={clsx('flex mb-4 relative w-full', index % 2 === 0 ? 'justify-start' : 'justify-end')}
        >
          <div className="flex items-start space-x-2 w-full relative">
            {index % 2 !== 0 && (
              <div className="w-8 h-8 bg-gray-100 dark:bg-gray-700 rounded-full animate-fadePulse" />
            )}
            <div
              className={clsx(
                'rounded-lg py-1 px-2 animate-fadePulse w-full relative',
                index % 2 === 0
                  ? 'text-gray-900 dark:text-gray-100 rounded-bl-none w-3xl max-w-3xl'
                  : 'max-w-2xl bg-gray-100 dark:bg-gray-800 px-3 text-black rounded-br-none shadow-sm dark:shadow-dark-sm'
              )}
            >
              {index % 2 === 0 && (
                <div className="progress-container mb-3 relative w-full min-w-80">
                  <div className="flex items-center mb-2 sticky top-0 bg-white dark:bg-gray-800 z-10 p-2 animate-fadePulse">
                    <div className="w-4 h-4 bg-gray-300 dark:bg-gray-600 rounded-full" />
                    <div className="ml-2 w-20 h-4 bg-gray-300 dark:bg-gray-600 rounded" />
                    <div className="ml-auto w-16 h-3 bg-gray-300 dark:bg-gray-600 rounded" />
                  </div>
                  <div className="max-h-32 overflow-y-auto space-y-1">
                    {[1, 2].map((_, idx) => (
                      <div key={idx} className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-full animate-fadePulse" />
                    ))}
                  </div>
                </div>
              )}
              <div className="space-y-2">
                {[1, 2].map((_, idx) => (
                  <div key={idx} className="mb-2">
                    <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-full animate-fadePulse" />
                  </div>
                ))}
              </div>
            </div>
            {index % 2 === 0 && (
              <div className="ml-2 w-8 h-8 bg-gray-300 dark:bg-gray-600 rounded-full animate-fadePulse" />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatSkeleton;