import apis from "../../../utils/apis";
import { showFeedbackModal } from "../UserFeedbackActions";

export const fetchWorkflows = () => async (dispatch: any) => {
  try {
    const response = await apis({
        url: 'workflows'
        });
    return response.data;
  } catch (error) {
    console.error('Error fetching workflows:', error);
    throw error; 
  }
};

export const fetchCaseWorkflows = (caseId: string) => async (dispatch: any) => {
  try {
    const response = await apis.get(`/cases/${caseId}/workflows`);
    return response.data;
  } catch (error) {
    console.error('Error fetching case workflows:', error);
    throw error;
  }
};

export const updateCaseWorkflows = (caseId: string, workflows: number[]) => async (dispatch: any) => {
  try {
    const response = await apis.post(`/cases/${caseId}/workflows`, { workflows });
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Workflows updated successfully!',
      modalType: 'success',
      duration: 3000,
    }));
    return response.data;
  } catch (error) {
    console.error('Error updating case workflows:', error);
    throw error;
  }
};

export const suggestWorkflow = (suggestion: { name: string; description: string }) => async (dispatch: any) => {
  try {
    const response = await apis.post('/workflows/suggest', suggestion);
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Suggestion submitted successfully!',
      modalType: 'success',
      duration: 3000,
    }));
    return response.data;
  } catch (error) {
    console.error('Error suggesting workflow:', error);
    throw error;
  }
};