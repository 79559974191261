import React from 'react';
import Badge from '../../shared/TailwindComponents/Badge';
import { formatCamelCase, formatDate } from '../../../utils/functions';
import { useTranslation } from 'react-i18next';
import { convertLongDateWithTranslation } from '../../shared/DateConverter';
import { t } from 'i18next';

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

interface CaseInformationProps {
  engagement: any;
}

const CaseInformation: React.FC<CaseInformationProps> = ({ engagement }) => {
  const { t } = useTranslation();

  // Build an array of key fields to display.
  const topFields: { label: string; value: any }[] = [
    { label: 'Case Type', value: engagement.CaseType },
    { label: 'Status', value: engagement.Status },
    { label: 'Client Name', value: engagement.ClientName },
    { label: 'Created At', value: convertLongDateWithTranslation(formatDate(engagement.CreatedAt)) },
    { label: 'Updated At', value: convertLongDateWithTranslation(formatDate(engagement.UpdatedAt)) },
    {
      label: 'Budget Range',
      value:
        engagement.BudgetRange && engagement.BudgetRange.Min != null && engagement.BudgetRange.Max != null
          ? `${formatCurrency(engagement.BudgetRange.Min)} – ${formatCurrency(engagement.BudgetRange.Max)}`
          : '-',
    },
    { label: 'Deadline', value: convertLongDateWithTranslation(formatDate(engagement.Deadline)) },
    { label: 'Desired Outcome', value: t(engagement.DesiredOutcome) },
  ];

  // Optionally include Estimated Timeline.
  if (engagement.EstimatedTimeline) {
    topFields.push({ label: 'Estimated Timeline', value: engagement.EstimatedTimeline });
  }

  // Optionally include Additional Terms or Additional Notes.
  // if (engagement.AdditionalTerms) {
  //   topFields.push({ label: 'Additional Terms', value: engagement.AdditionalTerms });
  // } else if (engagement.AdditionalNotes) {
  //   topFields.push({ label: 'Additional Notes', value: engagement.AdditionalNotes });
  // }

  // Optionally display the initial lawyer message if available.
  const initialMessage =
    engagement.Messages && engagement.Messages.length > 0 ? engagement.Messages[0].Message : '';

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <h3 className="text-2xl font-semibold mb-6">{t('Case Terms Information')}</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {topFields.map(({ label, value }) => (
          <div key={label} className="flex flex-col">
            <span className="text-sm font-medium text-gray-500">{t(label)}</span>
            {label === 'Status' ? (
              <Badge className='w-fit' color={value === 'Active' ? 'green' : 'yellow'}>{t(formatCamelCase(value)) || '-'}</Badge>
            ) : (
              <span className="mt-1 text-base text-gray-700 dark:text-gray-200">{value || '-'}</span>
            )}
          </div>
        ))}
      </div>
      {/* {initialMessage && (
        <div className="mt-8">
          <h4 className="text-lg font-medium text-gray-500">{t('Initial Message')}</h4>
          <p className="mt-2 text-base text-gray-700 dark:text-gray-200">{initialMessage}</p>
        </div>
      )} */}
    </div>
  );
};

export default CaseInformation;
