import React from 'react';
import { useGoogleDrive } from '../contexts/GoogleContext';
import clsx from 'clsx';
import { PiGoogleDriveLogoLight } from "react-icons/pi";

const baseBtnClasses = "flex items-center justify-center w-32 px-3 py-1.5 text-xs font-medium rounded-md transition-colors";
const googleBtnClasses = "bg-green-100 hover:bg-green-200 text-green-800";

interface GoogleDrivePickerButtonProps {
  onFilePicked: (file: any) => void;
}

const GoogleDrivePickerButton: React.FC<GoogleDrivePickerButtonProps> = ({ onFilePicked }) => {
  const { handleOpenPicker, pickerLoaded } = useGoogleDrive();

  return (
    <button
      onClick={() => handleOpenPicker(onFilePicked)}
      disabled={!pickerLoaded}
      type="button"
      className={clsx(baseBtnClasses, googleBtnClasses)}
    >
      {('Google Drive')}
      <PiGoogleDriveLogoLight className="ml-2" size={16} />
    </button>
  );
};

export default GoogleDrivePickerButton;