import React, { useEffect } from 'react';
import { Section, Description } from './types';
import { useAppDispatch, RootState } from '../../store';
import { showHelpContentSidePanel } from '../../store/actions/sidePanelActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserType } from '../../store/actions/ChatAction';
import { getUserManual } from '../../store/actions/DataAction';
import Badge from '../shared/TailwindComponents/Badge';
import Button from '../shared/TailwindComponents/Button';

// Import Heroicons
import {
  RocketLaunchIcon,
  LockClosedIcon,
  WrenchScrewdriverIcon,
  Cog6ToothIcon,
  Bars3Icon,
  TagIcon,
  Squares2X2Icon,
  LightBulbIcon,
  FolderIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline';
import ApiLoader from '../ApiLoader';
import { isBetaEnv } from '../../utils/authUtils';


type HeroIcon = React.ComponentType<React.SVGProps<SVGSVGElement> & { title?: string; titleId?: string }>;

const Help: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();

  const helpDataState = useSelector((state: RootState) => state.lists);

  useEffect(() => {
    if (userType) {
      dispatch(getUserManual(userType, 'en'));
    }
  }, [dispatch, userType]);

  const handleDescriptionClick = (section: Section, selectedDescription: Description) => {
    dispatch(showHelpContentSidePanel(section, selectedDescription));
  };

  if (helpDataState.loading) {
    return (
        <ApiLoader />
    );
  }

  if (helpDataState.error) {
    return (
      <div className="flex items-center justify-center w-full h-full text-center">
        {t('Help content is coming soon.')}
      </div>
    );
  }

  if (!helpDataState.data) {
    return (
      <div className="flex items-center justify-center w-full h-full text-center">
        {t('Help content is coming soon.')}
      </div>
    );
  }

  if (isBetaEnv) {
    return (
      <div className="flex items-center justify-center w-full h-64 text-center">
        <Badge color='gray'>{t('Help content is coming soon.')}</Badge>
      </div>
    );
  }

  const currentHelpData = helpDataState.data;

  const gettingStartedSection = currentHelpData.sections.find(
    (section: Section) => section.main_component === 'Get Started'
  );

  const otherSections = currentHelpData.sections.filter(
    (section: Section) => section.main_component !== 'Get Started'
  );

  // Map section names to Heroicons components
  const iconMap: { [key: string]: HeroIcon } = {
    'Get Started': RocketLaunchIcon as HeroIcon,
    'General Info & Tips': LightBulbIcon as HeroIcon,
    'Vault': LockClosedIcon as HeroIcon,
    'Tools': WrenchScrewdriverIcon as HeroIcon,
    'Operations': Cog6ToothIcon as HeroIcon,
    'Utility Bar': Bars3Icon as HeroIcon,
    'Pricing Information': TagIcon as HeroIcon,
    'Dashboard': Squares2X2Icon as HeroIcon,
    'Documents': FolderIcon as HeroIcon,
    'Message Center': ChatBubbleLeftRightIcon as HeroIcon,
    // Add more mappings as needed
  };

  return (
    <div className="px-2">
      {gettingStartedSection && (
        <div className="flex flex-col md:flex-row justify-between items-start md:items-end mb-6">
          <div className="flex-1">
            <h2 className="text-2xl font-semibold mb-2">{t('What is PONS?')}</h2>
            <p className="text-gray-600 dark:text-gray-300 whitespace-pre-line">
              {currentHelpData.message}
            </p>
          </div>
          <Button
            variant="primary"
            size='small'
            onClick={() =>
              handleDescriptionClick(
                gettingStartedSection,
                gettingStartedSection.descriptions[0]
              )
            }
            className="mt-4 md:mt-0 md:ml-4 flex items-center"
          >
            <RocketLaunchIcon className="h-5 w-5 mr-2" />
            {t('Get Started')}
          </Button>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {otherSections.map((section: Section, index: number) => {
          const IconComponent = iconMap[section.main_title] || LockClosedIcon;
          return (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-light-md dark:shadow-dark-md transition duration-300"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-4">
                  <IconComponent className="h-8 w-8 text-primary-500" />
                  <h3 className="text-xl font-semibold">{t(section.main_title)}</h3>
                </div>
                {section.status && (
                  <Badge color={section.status === 'Coming soon' ? 'yellow' : 'green'}>
                    {t(section.status)}
                  </Badge>
                )}
              </div>
              <ul className="space-y-4">
                {section.descriptions.map((desc: Description, idx: number) => (
                  <li
                    key={idx}
                    className="p-3 bg-gray-100 dark:bg-gray-700 rounded cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                    onClick={() => handleDescriptionClick(section, desc)}
                  >
                    {t(desc.title)}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Help;
