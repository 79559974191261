import React from 'react';

const SkeletonLoader: React.FC = () => {
  return (
    <div className="animate-pulseFade space-y-6 p-6">
      {/* Header Section: Profile Picture and Name */}
      <div className="flex flex-col md:flex-row md:items-center md:space-x-6">
        <div className="w-32 h-32 md:w-40 md:h-40 bg-gray-300 rounded-full"></div>
        <div className="flex-1 space-y-4 mt-4 md:mt-0">
          <div className="h-8 bg-gray-300 rounded w-1/2"></div>
          <div className="flex space-x-2">
            <div className="h-5 bg-gray-300 rounded w-20"></div>
            <div className="h-5 bg-gray-300 rounded w-20"></div>
            <div className="h-5 bg-gray-300 rounded w-20"></div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
          </div>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="mt-8">
        <div className="flex space-x-4">
          <div className="h-8 bg-gray-300 rounded w-24"></div>
          <div className="h-8 bg-gray-300 rounded w-24"></div>
          <div className="h-8 bg-gray-300 rounded w-24"></div>
          <div className="h-8 bg-gray-300 rounded w-24"></div>
        </div>
        <div className="mt-4 h-64 bg-gray-300 rounded"></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;