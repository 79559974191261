import React, {
  useEffect,
  useState,
  useRef,
  MouseEvent,
  useCallback,
} from 'react';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { fetchStructure, fetchSubFolderAndFiles } from '../../../store/actions/FolderAction';
import {
  PiCaretLeftLight,
  PiCaretRightLight,
  PiFolderLight,
  PiFileLight,
} from 'react-icons/pi';
import { FileProps, FolderProps } from '../../Documents/types';
import Button from './Button';

// This is what we'll return to the parent once the user hits 'Attach Selected'
export interface VaultFile {
  id: string;
  name: string;
  url?: string;
}

interface VaultPickerProps {
  /** Called when the user is done selecting (clicking "Attach Selected"). */
  onFilesSelected: (files: VaultFile[]) => void;
}

type VaultItem = {
  id: string;
  name: string;
  type: 'folder' | 'file';
  folderData?: FolderProps;
  fileData?: FileProps;
};

const VaultPicker: React.FC<VaultPickerProps> = ({ onFilesSelected }) => {
  const dispatch = useAppDispatch();

  // Root-level folder structure from Redux
  const structure = useSelector((state: RootState) => state.folders.structure || []);

  // For folder navigation
  const [pathStack, setPathStack] = useState<FolderProps[]>([]);
  const [currentItems, setCurrentItems] = useState<VaultItem[]>([]);

  // Loading spinner
  const [isLoading, setIsLoading] = useState(false);

  // Multi-select
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());
  const itemRefs = useRef<Record<string, HTMLDivElement | null>>({}); // to track each item’s DOM element

  // Drag-select
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState<{ x: number; y: number } | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<{ x: number; y: number } | null>(null);

  /*───────────────────────────────────────────────────────────────────────────────
    Fetch Root
  ───────────────────────────────────────────────────────────────────────────────*/
  useEffect(() => {
    // Fetch root structure on mount
    setIsLoading(true);
    dispatch(fetchStructure()).finally(() => setIsLoading(false));
  }, [dispatch]);

  /*───────────────────────────────────────────────────────────────────────────────
    Display root folders if we're at the root
  ───────────────────────────────────────────────────────────────────────────────*/
  useEffect(() => {
    if (pathStack.length === 0 && structure.length > 0) {
      const rootItems: VaultItem[] = structure.map((folder: FolderProps) => ({
        id: folder.FolderID,
        name: folder.FolderName,
        type: 'folder',
        folderData: folder,
      }));
      setCurrentItems(rootItems);
    }
  }, [structure, pathStack]);

  /*───────────────────────────────────────────────────────────────────────────────
    Helper: load subfolders & files for the given folder
  ───────────────────────────────────────────────────────────────────────────────*/
  const loadFolderContent = async (folder: FolderProps) => {
    try {
      setIsLoading(true);
      const result: any = await dispatch(fetchSubFolderAndFiles({ folderID: folder.FolderID }));
      const { subfolders = [], files = [] } = result;
      const items: VaultItem[] = [];

      subfolders.forEach((sf: FolderProps) => {
        items.push({
          id: sf.FolderID,
          name: sf.FolderName,
          type: 'folder',
          folderData: sf,
        });
      });

      files.forEach((file: FileProps) => {
        items.push({
          id: file.FileID,
          name: file.FileName,
          type: 'file',
          fileData: file,
        });
      });

      setCurrentItems(items);
    } catch (error) {
      console.error('Error loading folder content:', error);
    } finally {
      setIsLoading(false);
      // Clear selection whenever we navigate into a new folder
      setSelectedIds(new Set());
    }
  };

  /*───────────────────────────────────────────────────────────────────────────────
    Navigation
  ───────────────────────────────────────────────────────────────────────────────*/
  const handleBack = () => {
    if (pathStack.length === 0) return;
    const newStack = [...pathStack];
    newStack.pop();
    setPathStack(newStack);

    if (newStack.length === 0) {
      // Return to root
      const rootItems: VaultItem[] = structure.map((folder: FolderProps) => ({
        id: folder.FolderID,
        name: folder.FolderName,
        type: 'folder',
        folderData: folder,
      }));
      setCurrentItems(rootItems);
      setSelectedIds(new Set());
    } else {
      // Load parent's content
      loadFolderContent(newStack[newStack.length - 1]);
    }
  };

  const handleBreadcrumbClick = (index: number) => {
    // index < 0 => user clicked "Vault"
    if (index < 0) {
      setPathStack([]);
      const rootItems: VaultItem[] = structure.map((folder: FolderProps) => ({
        id: folder.FolderID,
        name: folder.FolderName,
        type: 'folder',
        folderData: folder,
      }));
      setCurrentItems(rootItems);
      setSelectedIds(new Set());
    } else {
      const newStack = pathStack.slice(0, index + 1);
      setPathStack(newStack);
      loadFolderContent(newStack[newStack.length - 1]);
    }
  };

  /*───────────────────────────────────────────────────────────────────────────────
    Single Click Multi-Select
  ───────────────────────────────────────────────────────────────────────────────*/
  const handleItemClick = (e: MouseEvent, item: VaultItem) => {
    if (item.type === 'folder' && item.folderData) {
      // Navigate into folder
      setSelectedIds(new Set());
      const newStack = [...pathStack, item.folderData];
      setPathStack(newStack);
      loadFolderContent(item.folderData);
      return;
    }

    // File multi-select logic
    if (item.type === 'file') {
      const ctrlOrCmd = e.metaKey || e.ctrlKey;
      setSelectedIds((prev) => {
        const newSet = new Set(prev);
        if (ctrlOrCmd) {
          // Toggle
          if (newSet.has(item.id)) newSet.delete(item.id);
          else newSet.add(item.id);
        } else {
          // Normal click => single select
          newSet.clear();
          newSet.add(item.id);
        }
        return newSet;
      });
    }
  };

  /*───────────────────────────────────────────────────────────────────────────────
    Drag Selection Logic
  ───────────────────────────────────────────────────────────────────────────────*/

  /**
   * Convert a mouse event (clientX, clientY) to coordinates
   * relative to the container's top-left, factoring in scroll.
   */
  const getContainerCoords = (e: React.MouseEvent) => {
    if (!containerRef.current) return { x: e.clientX, y: e.clientY };
    const rect = containerRef.current.getBoundingClientRect();
    return {
      x: e.clientX - rect.left + containerRef.current.scrollLeft,
      y: e.clientY - rect.top + containerRef.current.scrollTop,
    };
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    // Only start selection if user clicked on empty space (not on an item)
    if (e.target instanceof HTMLElement && e.target.closest('.vault-item')) {
      return;
    }
    if (e.button !== 0) return; // left-click only

    setIsSelecting(true);
    const coords = getContainerCoords(e);
    setSelectionStart(coords);
    setSelectionEnd(coords);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isSelecting || !selectionStart) return;
    const coords = getContainerCoords(e);
    setSelectionEnd(coords);
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isSelecting || !selectionStart || !selectionEnd) {
      setIsSelecting(false);
      return;
    }
    setIsSelecting(false);

    const ctrlOrCmd = e.metaKey || e.ctrlKey;
    const newSelection = new Set(selectedIds);

    // bounding box in container coords
    const minX = Math.min(selectionStart.x, selectionEnd.x);
    const maxX = Math.max(selectionStart.x, selectionEnd.x);
    const minY = Math.min(selectionStart.y, selectionEnd.y);
    const maxY = Math.max(selectionStart.y, selectionEnd.y);

    // For each item, convert its boundingRect to container coords as well
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();

      currentItems.forEach((item) => {
        if (item.type === 'file') {
          const el = itemRefs.current[item.id];
          if (!el) return;
          const rect = el.getBoundingClientRect();

          // Convert to container-based coords
          const itemLeft = rect.left - containerRect.left + containerRef.current!.scrollLeft;
          const itemRight = rect.right - containerRect.left + containerRef.current!.scrollLeft;
          const itemTop = rect.top - containerRect.top + containerRef.current!.scrollTop;
          const itemBottom = rect.bottom - containerRect.top + containerRef.current!.scrollTop;

          const overlap =
            itemLeft < maxX && itemRight > minX && itemTop < maxY && itemBottom > minY;

          if (overlap) {
            // If ctrl/cmd => toggle; else add
            if (ctrlOrCmd) {
              if (newSelection.has(item.id)) newSelection.delete(item.id);
              else newSelection.add(item.id);
            } else {
              // Replace entire selection with just items in the box
              newSelection.add(item.id);
            }
          } else if (!ctrlOrCmd) {
            // If not overlapped and not ctrl => remove it
            newSelection.delete(item.id);
          }
        }
      });
    }

    setSelectedIds(newSelection);
    setSelectionStart(null);
    setSelectionEnd(null);
  };

  // Attach ref for each item
  const setItemRef = useCallback(
    (id: string) => (el: HTMLDivElement | null) => {
      itemRefs.current[id] = el;
    },
    []
  );

  /*───────────────────────────────────────────────────────────────────────────────
    Render UI
  ───────────────────────────────────────────────────────────────────────────────*/
  const getIcon = (type: 'folder' | 'file') => {
    return type === 'folder'
      ? <PiFolderLight className="h-8 w-8 text-blue-500" />
      : <PiFileLight className="h-8 w-8 text-green-500" />;
  };

  const renderBreadcrumbs = () => (
    <div className="flex items-center space-x-2 mb-4">
      {pathStack.length > 0 && (
        <button
          onClick={handleBack}
          className="text-primary-500 hover:underline flex items-center"
        >
          <PiCaretLeftLight className="h-5 w-5" />
        </button>
      )}
      <span
        className="text-gray-500 text-xs cursor-pointer hover:underline"
        onClick={() => handleBreadcrumbClick(-1)}
      >
        Vault
      </span>

      {pathStack.map((folder, idx) => (
        <div key={folder.FolderID} className="flex items-center space-x-1">
          <PiCaretRightLight className="h-5 w-5 text-gray-400" />
          <span
            className="text-gray-500 hover:underline cursor-pointer text-xs truncate max-w-52"
            onClick={() => handleBreadcrumbClick(idx)}
          >
            {folder.FolderName}
          </span>
        </div>
      ))}
    </div>
  );

  const renderSkeletons = () => (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {Array.from({ length: 4 }).map((_, i) => (
        <div
          key={i}
          className="animate-pulseFade flex flex-col items-center p-4 bg-white rounded-lg 
                     shadow border border-gray-200"
        >
          <div className="w-8 h-8 mb-2 bg-gray-300 rounded" />
          <div className="w-3/4 h-3 bg-gray-200 rounded" />
        </div>
      ))}
    </div>
  );

  // Final array of selected file items to pass back
  const selectedFiles: VaultFile[] = currentItems
    .filter((item) => item.type === 'file' && selectedIds.has(item.id))
    .map((item) => ({
      id: item.fileData?.FileID || '',
      name: item.fileData?.FileName || '',
      url: item.fileData?.FileUrl || '',
    }));

  const handleAttachFiles = () => {
    onFilesSelected(selectedFiles);
  };

  // Draw selection box in container coordinates
  let selectionBox: JSX.Element | null = null;
  if (isSelecting && selectionStart && selectionEnd) {
    const left = Math.min(selectionStart.x, selectionEnd.x);
    const top = Math.min(selectionStart.y, selectionEnd.y);
    const width = Math.abs(selectionEnd.x - selectionStart.x);
    const height = Math.abs(selectionEnd.y - selectionStart.y);

    selectionBox = (
      <div
        style={{
          position: 'absolute',
          zIndex: 9999,
          pointerEvents: 'none',
          left,
          top,
          width,
          height,
          backgroundColor: 'rgba(100, 149, 237, 0.2)', // translucent
          border: '1px solid rgba(100, 149, 237, 0.6)',
        }}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      className="relative p-4 sm:w-full md:w-[600px] max-w-screen-lg mx-auto h-[70vh] overflow-y-auto"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {/* Breadcrumbs */}
      <div className="flex items-center justify-between mb-2">
        {renderBreadcrumbs()}
      </div>

      {/* If loading, show skeleton */}
      {isLoading ? (
        renderSkeletons()
      ) : currentItems.length === 0 ? (
        <div className="text-center text-gray-400 mt-10">
          No folders or files here
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {currentItems.map((item) => {
            const isSelected = selectedIds.has(item.id) && item.type === 'file';
            return (
              <div
                key={item.id}
                ref={setItemRef(item.id)}
                onClick={(e) => handleItemClick(e, item)}
                className={`vault-item flex flex-col items-center p-4 bg-white rounded-lg 
                  shadow border border-gray-100 transition-shadow
                  cursor-pointer hover:shadow-md
                  ${isSelected ? 'ring-2 ring-blue-400 bg-blue-50' : ''}
                `}
              >
                {getIcon(item.type)}
                <p className="mt-2 text-sm text-center text-gray-700 truncate w-full">
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      )}
        {/* Only show attach button if there's something selected */}
        {selectedFiles.length > 0 && (
          <div className='flex justify-end mt-5'>
          <Button
            onClick={handleAttachFiles}
            variant="primary"
            size="small"
          >
            Attach {selectedFiles.length} File{selectedFiles.length > 1 ? 's' : ''}
          </Button>
          </div>
        )}
      {/* The drag-selection box */}
      {selectionBox}
    </div>
  );
};

export default VaultPicker;
