
import React, { useState } from 'react';
import { useAppDispatch } from '../../../../store';
import { addChronologyData, getChronology } from '../../../../store/actions/LegalServices/chronologyActions';
import { hideSidePanel } from '../../../../store/actions/sidePanelActions';
import Button from '../../../shared/TailwindComponents/Button';
import { useTranslation } from 'react-i18next';
import TextOrFileInput from '../../../shared/TailwindComponents/TextOrFileInput';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import AdditionalDataList from './AdditionalDataList';

interface AddChronologyDataFormProps {
  caseId: string;
  onSuccess?: () => void;
}

const AddChronologyDataForm: React.FC<AddChronologyDataFormProps> = ({ caseId, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [isAdditionalDataOpen, setIsAdditionalDataOpen] = useState<boolean>(true);

  const handleSave = async () => {
    if (!textValue && files.length === 0) {
      alert(t('Please provide either text or files'));
      return;
    }

    await dispatch(addChronologyData(caseId, textValue, files));
    // Re-fetch the "Chronology" doc for the new "GeneratedChronology" state, if needed
    await dispatch(getChronology(caseId));

    if (onSuccess) {
      onSuccess();
    }

    // Clear
    setTextValue('');
    setFiles([]);
    dispatch(hideSidePanel());
  };

  return (
    <div className="p-6 flex flex-col">
      <h3 className="text-2xl font-semibold mb-4">{t('Add Data')}</h3>
      <div className="space-y-4 flex-1 overflow-auto px-2">
        <TextOrFileInput
          id="freeFormData"
          label={t('Free-Form Data')}
          description={t('Provide any text or upload files.')}
          value={textValue}
          files={files}
          onTextChange={(e) => setTextValue(e.target.value)}
          onFilesChange={(newFiles) => setFiles(newFiles)}
          layout="column"
        />
      </div>

      <div className="flex justify-end mt-4">
        <Button variant="primary" size='small' onClick={handleSave}>
          {t('Save')}
        </Button>
      </div>
      <Disclosure>
          <div className="rounded-lg p-4">
              <div className="flex items-center space-x-2">
                <h3 className="text-xl font-semibold">{t('Data')}</h3>
              </div>
              <AdditionalDataList caseId={caseId} />
          </div>
      </Disclosure>
    </div>
  );
};

export default AddChronologyDataForm;
