// import * as SpeechSDK from 'microsoft-cognitives-services-speech-sdk';
import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineSend, AiOutlineStop } from 'react-icons/ai';
import { PiFileMagnifyingGlassLight, PiListMagnifyingGlass, PiMicrophoneLight, PiPaperclipLight, PiStopCircleLight, PiUploadSimple } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import Waveform from './services/Waveform';
import useMicrophone from './services/useMicrophone';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { Transition } from '@headlessui/react';
import Badge from '../shared/TailwindComponents/Badge';
import { TbWorldSearch } from 'react-icons/tb';

interface PromptBarProps {
  onSend: (text: string) => void;
  speechToken: string | null;
  serviceRegion: string | null;
  aiSettings: any;

  selectedService: 'research' | 'fileAnalysis' | 'myAgents';
  setSelectedService: React.Dispatch<React.SetStateAction<'research' | 'fileAnalysis' | 'myAgents'>>;
  myCustomIndexes: any[];
  selectedIndexModelId: string | null;
  setSelectedIndexModelId: React.Dispatch<React.SetStateAction<string | null>>;
  setFiles: React.Dispatch<React.SetStateAction<(File | null)[]>>;
  chatGroupID: string;
  files: (File | null)[];
  onFileSelect: (index: number, file: File | null) => void;
  hasSentFiles: boolean;
  setHasSentFiles: React.Dispatch<React.SetStateAction<boolean>>; // Added setter
  className?: string;
  isTyping: boolean; 
  onStop: () => void; 
}

const PromptBar: React.FC<PromptBarProps> = ({
  onSend,
  speechToken,
  serviceRegion,
  aiSettings,
  selectedService,
  setSelectedService,
  myCustomIndexes,
  selectedIndexModelId,
  setSelectedIndexModelId,
  chatGroupID,
  files,
  onFileSelect,
  hasSentFiles,
  setHasSentFiles, // Added to props
  className,
  setFiles,
  isTyping: isAiTyping, // Rename to avoid conflict with local isTyping
  onStop
}) => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [isTyping, setIsTyping] = useState(false);
  const [showMyAgents, setShowMyAgents] = useState(false);
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); 

  // Microphone logic
  const {
    finalTranscript,
    interimTranscript,
    isListening,
    startListening,
    stopListening,
    error
  } = useMicrophone({
    speechToken: speechToken,  // 🔄 Pass the dynamically fetched token
    serviceEndpoint: serviceRegion,
    language: "en-US",
  });

  // File handling
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  // Check if user typed anything => show send or mic
  useEffect(() => {
    setIsTyping(input.trim().length > 0);
  }, [input]);

  // Microphone updates input
  useEffect(() => {
    if (isListening) {
      setInput((prev) => prev + ' ' + interimTranscript);
    } else if (finalTranscript) {
      setInput((prev) => prev + ' ' + finalTranscript);
    }
  }, [finalTranscript, interimTranscript, isListening]);

  // Auto-expand the textarea
  useEffect(() => {
    if (!textareaRef.current) return;
    const maxHeight = 200; // px
    textareaRef.current.style.height = 'auto';
    const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
    textareaRef.current.style.height = `${newHeight}px`;
  }, [input]);

  const handleSend = () => {
    const trimmed = input.trim();
    if (trimmed) {
      onSend(trimmed);
      setInput('');
      if (selectedService === 'fileAnalysis' && !hasSentFiles) {
        setHasSentFiles(true); // Now accessible
      }
    }
  };

  const handleVoiceClick = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  // Service selection
  const canSwitchService = !chatGroupID;

  const handleSelectService = (srv: 'research' | 'fileAnalysis') => {
    if (!canSwitchService && srv !== selectedService) return;
    setSelectedService(srv);
  };

  const handleToggleMyAgents = () => {
    if (!canSwitchService && selectedService !== 'myAgents') return;
    setSelectedService('myAgents');
    setShowMyAgents((prev) => !prev);
  };

  const handleSelectAgent = (agentId: string) => {
    setSelectedIndexModelId(agentId);
    setShowMyAgents(false);
  };

  // File upload handling
  const isFileUploadActive = selectedService === 'research' && !hasSentFiles;

  const handleFileUploadClick = () => {
    if (isFileUploadActive) {
      setShowUploadOptions((prev) => !prev);
    }
  };

  const handleLocalUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    setShowUploadOptions(false);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);
    newFiles.forEach((f, idx) => onFileSelect(idx, f));
    e.target.value = ''; // Reset input for re-upload
  };

  const handleVaultUpload = () => {
    console.log('Vault upload not implemented');
    setShowUploadOptions(false);
  };

  const handleOneDriveUpload = () => {
    console.log('OneDrive upload not implemented');
    setShowUploadOptions(false);
  };

  const handleGoogleDriveUpload = () => {
    console.log('Google Drive upload not implemented');
    setShowUploadOptions(false);
  };

  // Drag and drop handling
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isFileUploadActive) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isFileUploadActive) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      droppedFiles.forEach((f, idx) => onFileSelect(idx, f));
      setIsDragging(false);
    }
  };

  const handleRemoveFile = (indexToRemove: number) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[indexToRemove] = null; // Remove by setting to null
      // Optionally shift array if you don't want null gaps
      return newFiles.filter((f) => f !== null);
    });
  };

  const validFiles = files.filter((f) => f !== null) as File[];
  const fileCount = validFiles.length;

  return (
    <div
      className={clsx(
        'px-3 bg-transparent rounded-full flex-shrink-0',
        className
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {/* Display uploaded files as badges */}
      {selectedService === 'research' && fileCount > 0 && (
        <div className="mb-2 flex flex-wrap gap-2">
          {validFiles.slice(0, 2).map((file, idx) => (
            <Badge
              key={idx}
              color="indigo"
              className="text-xs"
              onClose={() => handleRemoveFile(idx)}
              tooltip={file.name}
            >
              {file.name.length > 20 ? `${file.name.slice(0, 17)}...` : file.name}
            </Badge>
          ))}
          {fileCount > 2 && (
            <Badge color="gray" className="text-xs">
              +{fileCount - 2} more
            </Badge>
          )}
        </div>
      )}

      {/* Text area and mic/send */}
      <div
        className={clsx(
          'flex items-end rounded-xl px-3 py-3 bg-gray-100 dark:bg-[#2d2d2d] transition duration-300 relative',
          isFocused && 'ring-1 ring-primary-500',
          isDragging && 'ring-2 ring-dashed ring-blue-500 bg-blue-50 dark:bg-blue-900'
        )}
      >
        <textarea
          ref={textareaRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={t('Type your message...') || ''}
          className="flex-1 bg-transparent text-gray-900 dark:text-gray-100 resize-none focus:outline-none border-0 min-h-20"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <div className="flex items-center gap-2">
          {/* File Upload Icon */}
          <Tooltip
            title={t('Upload files')}
            placement="top"
          >
            <button
              onClick={handleFileUploadClick}
              disabled={!isFileUploadActive}
              className={clsx(
                'p-2 transition-colors relative rounded-full transition-all bg-gray-200 text-primary-500 hover:text-primary-600 hover:bg-gray-300',
                isFileUploadActive
                  ? 'text-primary-500 hover:text-primary-600 hover:bg-gray-300'
                  : 'text-gray-400 cursor-not-allowed'
              )}
            >
              <PiPaperclipLight size={22} />
                    {/* Upload Options Dropdown */}
      {showUploadOptions && isFileUploadActive && (
        <Transition
          show={true}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-2"
        >
          <div className="absolute bottom-10 right-2 w-48 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded shadow-lg z-20 p-2">
            <button
              onClick={handleLocalUpload}
              className="block w-full text-left px-2 py-1 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 rounded"
            >
              {t('Local Upload')}
            </button>
            <button
              onClick={handleVaultUpload}
              className="block w-full text-left px-2 py-1 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 rounded"
            >
              {t('Vault Upload')}
            </button>
            <button
              onClick={handleOneDriveUpload}
              className="block w-full text-left px-2 py-1 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 rounded"
            >
              {t('Microsoft OneDrive')}
            </button>
            <button
              onClick={handleGoogleDriveUpload}
              className="block w-full text-left px-2 py-1 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 rounded"
            >
              {t('Google Drive')}
            </button>
          </div>
        </Transition>
      )}
            </button>
          </Tooltip>
          <input
            type="file"
            ref={fileInputRef}
            multiple
            onChange={handleFileInputChange}
            className="hidden"
          />

          {/* Microphone/Send Button */}
          {isAiTyping ? (
            <button
                onClick={onStop}
                className="group relative p-2 text-primary-400 hover:text-primary-200 dark:hover:text-primary-200 transition-all duration-300"
                title={t('Stop process')}
              >
                <AiOutlineStop size={24} className="transform group-hover:scale-110 transition-transform duration-300" />
                {/* <span className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full animate-pulse group-hover:animate-none"></span> */}
                <span className="absolute inset-0 rounded-full bg-red-500 opacity-0 group-hover:opacity-20 transition-opacity duration-300"></span>
              </button>
          ) : isTyping ? (
            <button
              onClick={handleSend}
              className="p-2 text-primary-500 hover:text-primary-600"
            >
              <AiOutlineSend size={22} />
            </button>
            ) : isListening ? (
            <button
              onClick={handleVoiceClick}
              className="p-2 text-red-500 hover:text-red-600"
            >
              <PiStopCircleLight size={22} />
            </button>
              ) : (
            <button
              onClick={handleVoiceClick}
              className="p-2 text-primary-500 hover:text-primary-600 hover:bg-gray-300 bg-gray-200 rounded-full transition-all"
            >
              <PiMicrophoneLight size={22} />
            </button>
          )}
        </div>
      </div>

      {isListening && (
        <div className="mt-1">
          <Waveform />
        </div>
      )}

      {/* Service Selection Row */}
      {/* <div className="flex items-center mt-4 gap-3 flex-wrap">
        {/* RESEARCH 
        <Tooltip
          title={!canSwitchService && selectedService !== 'research' ? t('Service locked after chat starts') : ''}
          placement="top"
        >
          <button
            onClick={() => handleSelectService('research')}
            disabled={!canSwitchService && selectedService !== 'research'}
            className={clsx(
              'px-3 py-1 rounded-full border text-sm transition-colors flex items-center gap-1',
              selectedService === 'research'
                ? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-200 border-indigo-300 dark:border-indigo-700'
                : 'bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600 text-gray-700 dark:text-gray-200',
              !canSwitchService && selectedService !== 'research' && 'opacity-50 cursor-not-allowed'
            )}
          >
            <TbWorldSearch />
            <span>{t('Research')}</span>
          </button>
        </Tooltip>

        {/* FILE ANALYSIS */}
        {/* <Tooltip
          title={!canSwitchService && selectedService !== 'fileAnalysis' ? t('Service locked after chat starts') : ''}
          placement="top"
        >
          <button
            onClick={() => handleSelectService('fileAnalysis')}
            disabled={!canSwitchService && selectedService !== 'fileAnalysis'}
            className={clsx(
              'px-3 py-1 rounded-full border text-sm transition-colors flex items-center gap-1',
              selectedService === 'fileAnalysis'
                ? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-200 border-indigo-300 dark:border-indigo-700'
                : 'bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600 text-gray-700 dark:text-gray-200',
              !canSwitchService && selectedService !== 'fileAnalysis' && 'opacity-50 cursor-not-allowed'
            )}
          >
            <PiFileMagnifyingGlassLight />
            <span>{t('File Analysis')}</span>
          </button>
        </Tooltip> */}

        {/* MY AGENTS 
        <div className="relative">
          <Tooltip
            title={!canSwitchService && selectedService !== 'myAgents' ? t('Service locked after chat starts') : ''}
            placement="top"
          >
            <button
              onClick={handleToggleMyAgents}
              disabled={!canSwitchService && selectedService !== 'myAgents'}
              className={clsx(
                'px-3 py-1 rounded-full border text-sm transition-colors flex items-center gap-1',
                selectedService === 'myAgents'
                  ? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-200 border-indigo-300 dark:border-indigo-700'
                  : 'bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600 text-gray-700 dark:text-gray-200',
                !canSwitchService && selectedService !== 'myAgents' && 'opacity-50 cursor-not-allowed'
              )}
            >
              <PiListMagnifyingGlass />
              <span>{t('My Agents')}</span>
            </button>
          </Tooltip>
          {showMyAgents && selectedService === 'myAgents' && (
            <div className="absolute bottom-10 left-0 w-48 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded shadow p-2 z-20">
              {myCustomIndexes.length === 0 && (
                <div className="text-sm text-gray-600 dark:text-gray-200">
                  {t('No custom agents found.')}
                </div>
              )}
              {myCustomIndexes.map((agent) => (
                <button
                  key={agent.model_id}
                  onClick={() => handleSelectAgent(agent.model_id)}
                  className={clsx(
                    'block w-full text-left px-2 py-1 text-sm rounded hover:bg-gray-100 dark:hover:bg-gray-600',
                    selectedIndexModelId === agent.model_id && 'bg-gray-200 dark:bg-gray-600'
                  )}
                >
                  {agent.name}
                </button>
              ))}
            </div>
          )}
        </div>
      </div> */}

      {/* Drag and Drop Overlay */}
      {isDragging && isFileUploadActive && (
        <div className="absolute inset-0 flex items-center justify-center bg-blue-100 dark:bg-blue-800 bg-opacity-75 rounded-xl z-10">
          <p className="text-blue-600 dark:text-blue-300 font-medium">{t('Drop files here')}</p>
        </div>
      )}
    </div>
  );
};

export default PromptBar;