import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { getIndexInfo, listFilesInIndex, addDataToIndex, chatWithIndex, deleteIndex, updateSystemInstruction, deleteFileFromIndex } from '../../../store/actions/AIStudioAction';
import Tabs from '../../shared/TailwindComponents/Tabs';
import Button from '../../shared/TailwindComponents/Button';
import Input from '../../shared/TailwindComponents/Input';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Textarea from '../../shared/TailwindComponents/Textarea';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import FileUploader from '../../shared/TailwindComponents/FileUploader2';
import { PiFile, PiChatCircle, PiGear, PiClockLight, PiDatabaseLight, PiFileLight, PiTrash, PiPaperPlaneLight } from 'react-icons/pi';
import { GoDependabot } from 'react-icons/go';
import { Tooltip } from '@mui/material';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import Badge from '../../shared/TailwindComponents/Badge';
import { isBetaEnv } from '../../../utils/authUtils';

const AIStudioIndexDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const { indexName } = useParams<{ indexName: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('files');
  const [indexInfo, setIndexInfo] = useState<any>(null);
  const [filesInIndex, setFilesInIndex] = useState<string[]>([]);
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  const [systemInstruction, setSystemInstruction] = useState('');
  const [updatingInstruction, setUpdatingInstruction] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [deletingFiles, setDeletingFiles] = useState<string[]>([]);
  const [isFilesLoading, setIsFilesLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState<{ role: 'user' | 'agent'; content: string }[]>([]);
  const [chatInput, setChatInput] = useState('');
  const [isChatLoading, setIsChatLoading] = useState(false);
  const chatEndRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const infoBadgesRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  const tabs = [
    { label: t('Files'), value: 'files', icon: <PiFile className="text-primary-500" /> },
    { label: t('Test Agent'), value: 'test', icon: <PiChatCircle className="text-primary-500" /> },
    { label: t('Agent Context'), value: 'system', icon: <PiGear className="text-primary-500" /> },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchIndexDetails(), fetchIndexFiles()]);
      setIsLoading(false);
    };
    fetchData();
  }, [indexName]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const fetchIndexDetails = async () => {
    const info = await dispatch(getIndexInfo(indexName) as any);
    if (info?.status === 'success') {
      setIndexInfo(info.data);
      setSystemInstruction(info.data.SystemInstruction || '');
    } else if (info?.status === 'error') {
      dispatch(showFeedbackModal({ showModal: true, message: info.message, modalType: 'error', duration: 3000 }));
    }
  };

  const fetchIndexFiles = async () => {
    const data = await dispatch(listFilesInIndex(indexName) as any);
    if (data?.status === 'success') {
      setFilesInIndex(data.data.files);
    } else if (data?.status === 'error') {
      dispatch(showFeedbackModal({ showModal: true, message: data.message, modalType: 'error', duration: 3000 }));
    }
  };

  const handleAddData = async () => {
    if (!indexName || filesToAdd.length === 0) return;
    setIsFilesLoading(true);
    const resp = await dispatch(addDataToIndex(indexName, filesToAdd) as any);
    if (resp?.status === 'success') {
      setFilesToAdd([]);
      fetchIndexFiles();
      fetchIndexDetails();
    }
    setIsFilesLoading(false);
  };

  const handleDeleteIndex = async () => {
    const resp = await dispatch(deleteIndex(indexName) as any);
    if (resp?.status === 'success') {
      history.push('/ai-studio');
    }
  };

  const handleChatSend = async () => {
    if (!chatInput.trim()) return;
    const userMessage = { role: 'user', content: chatInput };
    setChatHistory((prev: any) => [...prev, userMessage]);
    setChatInput('');
    setIsChatLoading(true);

    const response = await dispatch(chatWithIndex(indexName, chatInput) as any);
    setIsChatLoading(false);

    if (response) {
      const agentMessage = { role: 'agent', content: response };
      setChatHistory((prev: any) => [...prev, agentMessage]);
    } else {
      dispatch(showFeedbackModal({ showModal: true, message: t('Failed to get a response from the agent.'), modalType: 'error', duration: 3000 }));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleChatSend();
    }
  };

  const handleUpdateSystemInstruction = async () => {
    setUpdatingInstruction(true);
    const resp = await dispatch(updateSystemInstruction(indexName, systemInstruction) as any);
    if (resp?.status === 'success') {
      dispatch(showFeedbackModal({ showModal: true, message: t('Context updated successfully.'), modalType: 'success', duration: 3000 }));
    }
    setUpdatingInstruction(false);
  };

  const handleDeleteFile = (fileName: string) => {
    dispatch(showModal({
      type: 'confirmation',
      message: t('Are you sure you want to delete this file?'),
      onConfirm: async () => {
        setDeletingFiles(prev => [...prev, fileName]);
        await dispatch(deleteFileFromIndex(indexName, fileName) as any);
        dispatch(hideModal());
        await fetchIndexFiles();
        await fetchIndexDetails();
        setDeletingFiles(prev => prev.filter(f => f !== fileName));
      },
      onCancel: () => {},
      showModal: true,
    }));
  };

  const formatAgentType = (type: string) => type === 'CompanyAgent' ? t('Company Agent') : t('Custom Agent');
  const formatDate = (dateString: string | null) => dateString ? new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(dateString)) : t('N/A');

  const infoBadges = indexInfo && (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4 bg-primary-50 dark:bg-primary-900 rounded-lg shadow-md mb-3">
      <Tooltip title={t('Agent type')} placement='left'>
        <div className="flex items-center space-x-2 text-gray-700 dark:text-gray-200">
          <GoDependabot size={20} className="text-primary-500" />
          <span className='text-sm'>{t(formatAgentType(indexInfo.AgentType))}</span>
        </div>
      </Tooltip>
      <Tooltip title={t('Number of files')} placement='left'>
        <div className="flex items-center space-x-2 text-gray-700 dark:text-gray-200">
          <PiFileLight size={20} className="text-primary-500" />
          <span className='text-sm'>{indexInfo.document_count}</span>
        </div>
      </Tooltip>
      <Tooltip title={t('Last run')} placement='left'>
        <div className="flex items-center space-x-2 text-gray-700 dark:text-gray-200">
          <PiClockLight size={20} className="text-primary-500" />
          <span className='text-sm'>{formatDate(indexInfo.last_index_run)}</span>
        </div>
      </Tooltip>
      <Tooltip title={t('Size')} placement='left'>
        <div className="flex items-center space-x-2 text-gray-700 dark:text-gray-200">
          <PiDatabaseLight size={20} className="text-primary-500" />
          <span>{indexInfo.total_storage_size}</span>
        </div>
      </Tooltip>
    </div>
  );

  const renderSkeleton = () => (
    <div className="space-y-6 animate-fadePulse">
      <div className="h-8 w-1/3 bg-gray-300 dark:bg-gray-600 rounded"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="h-6 w-3/4 bg-gray-300 dark:bg-gray-600 rounded"></div>
        ))}
      </div>
      <div className="h-10 w-full bg-gray-300 dark:bg-gray-600 rounded"></div>
      <div className="space-y-2">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="h-4 w-full bg-gray-300 dark:bg-gray-600 rounded"></div>
        ))}
      </div>
    </div>
  );

  const renderFiles = () => {
    if (filesInIndex.length === 0) {
      return <p className="text-gray-500 dark:text-gray-400">{t('No files in this agent yet.')}</p>;
    }
    const filteredFiles = filesInIndex.filter(file => 
      file.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
      <div className="space-y-3">
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {t('Showing')} {filteredFiles.length} {t('of')} {filesInIndex.length} {t('files')}
        </p>
        {filteredFiles.length === 0 ? (
          <p className="text-gray-500 dark:text-gray-400">{t('No files found.')}</p>
        ) : (
          <div className="max-h-64 overflow-y-auto p-3 bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700">
            {filteredFiles.map((f, i) => (
              <div
                key={i}
                className="flex items-center justify-between py-2 px-4 bg-white/80 dark:bg-gray-700/80 rounded-lg shadow-sm hover:shadow-lg transition-all duration-200 mb-2 backdrop-blur-sm border border-gray-100 dark:border-gray-600"
              >
                <div className="flex items-center space-x-3">
                  <PiFile className="text-primary-500 flex-shrink-0" size={18} />
                  <span className="truncate text-sm text-gray-800 dark:text-gray-200" title={f}>{f}</span>
                </div>
                {deletingFiles.includes(f) ? (
                  <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-red-500"></div>
                ) : (
                  <button
                    onClick={() => handleDeleteFile(f)}
                    className="text-red-500 hover:text-red-700 transition-colors duration-150"
                  >
                    <PiTrash size={18} />
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mx-4 p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg animate-fade-in-up h-full flex flex-col">
      {isLoading ? renderSkeleton() : (
        <>
          <div ref={headerRef} className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-2">
              <button onClick={() => history.goBack()} className="text-primary-500 hover:text-primary-600">
                <ChevronLeftIcon className="h-6 w-6 text-primary-500" />
              </button>
              <h1 className="text-2xl font-bold text-gray-800 dark:text-white bg-clip-text bg-gradient-to-r from-primary-500 to-primary-700">{indexName}</h1>
            </div>
            <Button variant="neutral" size="small" onClick={handleDeleteIndex} className="border-red-500 text-red-500 hover:bg-red-500 hover:text-white transition-all">
              {t('Delete Agent')}
            </Button>
          </div>

          {infoBadges && <div ref={infoBadgesRef}>{infoBadges}</div>}

          <Tabs ref={tabsRef} tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />

          <div className="flex-1 overflow-hidden">
            {activeTab === 'files' && (
              <div className="p-6 space-y-4 transition-all duration-300 flex gap-4">
                <div className="w-full">
                  <h2 className="text-xl mb-5 font-semibold text-gray-800 dark:text-white">{t('Files in this Agent')}</h2>
                  <Input
                    type="text"
                    placeholder={t('Search files...')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="mb-4 w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 transition-all duration-200"
                  />
                  {renderFiles()}
                </div>
                <div className="w-full">
                  <h3 className="text-lg font-semibold mt-4 text-gray-800 dark:text-white">{t('Add Data')}</h3>
                  <FileUploader files={filesToAdd} onFilesChange={setFilesToAdd} />
                  <div className="flex justify-end">
                    <Button
                      variant="primary"
                      size="small"
                      onClick={handleAddData}
                      className={isFilesLoading ? 'opacity-60' : ''}
                      disabled={isFilesLoading}
                    >
                      {isFilesLoading ? <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div> : t('Add Data')}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'test' && (
              <div className="relative flex flex-col flex-1 h-full overflow-hidden">
                {/* Overlay for beta environment */}
                {isBetaEnv && (
                  <div className="absolute inset-0 bg-gray-200 bg-opacity-75 flex items-center justify-center z-10">
                    <Badge color='gray'>{t('Coming soon')}</Badge>
                  </div>
                )}
                <div className="flex flex-col h-full border border-gray-200 dark:border-gray-600 rounded-lg overflow-hidden">
                  <div className="flex-1 p-4 overflow-y-auto space-y-4 bg-gray-50 dark:bg-gray-800 flex-1">
                    {chatHistory.length === 0 && !isChatLoading ? (
                      <div className="text-center text-gray-500 dark:text-gray-400 flex-1 self-center flex items-center justify-center h-4/5">
                        {t('Start the conversation by entering a prompt below.')}
                      </div>
                    ) : (
                      chatHistory.map((msg, index) => (
                        <div key={index} className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'} flex-1`}>
                          <div className={`p-3 rounded-lg ${msg.role === 'user' ? 'bg-gray-200 dark:bg-gray-700 dark:text-white text-black w-fit max-w-lg' : 'text-black dark:text-white'}`}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose dark:prose-invert">{msg.content}</ReactMarkdown>
                          </div>
                        </div>
                      ))
                    )}
                    {isChatLoading && (
                      <div className="flex justify-start">
                        <div className="max-w-xs flex items-center gap-2 p-3 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white">
                          <div className="animate-spin rounded-full w-4 h-4 border-t-2 border-b-2 border-primary-500"></div>
                          <div className="text-xs animate-fadePulse">{t("Thinking...")}</div>
                        </div>
                      </div>
                    )}
                    <div ref={chatEndRef} />
                  </div>
                  <div className="p-4 border-t border-gray-200 dark:border-gray-600 shrink-0">
                    <div className="flex items-center space-x-2">
                      <Input
                        type="text"
                        placeholder={t('Enter your prompt...')}
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="flex-1 border-gray-300 dark:border-gray-600 focus:ring-primary-500"
                      />
                      <Button
                        variant="primary"
                        disabled={chatInput === ''}
                        size="small"
                        onClick={handleChatSend}
                        className={`${chatInput === '' && 'opacity-50 cursor-not-allowed'}`}
                      >
                        <PiPaperPlaneLight size={18} />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'system' && (
              <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-md space-y-4 transition-all duration-300">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t('Agent Context')}</h2>
                <p className="text-sm text-gray-600 dark:text-gray-300">{t('This is the context that guides the Agent’s behavior. Modify it to control the Agent’s responses.')}</p>
                <Textarea
                  id="system-instruction"
                  placeholder={t('Enter your context')}
                  value={systemInstruction}
                  onChange={(e) => setSystemInstruction(e.target.value)}
                  rows={6}
                  label={''}
                />
                <div className="flex justify-end">
                  <Button
                    variant="primary"
                    size="small"
                    onClick={handleUpdateSystemInstruction}
                    disabled={updatingInstruction}
                    className="bg-primary-500 hover:bg-primary-600 transition-colors"
                  >
                    {updatingInstruction ? t('Updating...') : t('Update context')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AIStudioIndexDetailPage;