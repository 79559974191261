import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { getUserType } from '../../utils/authUtils';
// import { usePermission } from '../../hooks/userPermissions';
import { fetchAllClients } from '../../store/actions/ClientAction';
import { createCase } from '../../store/actions/marketplace2/marketplaceActions';
import { fetchCases, fetchCaseSummary } from '../../store/actions/marketplace2/caseActions';
import { showModal, hideModal } from '../../store/actions/modalActions';
import { showAddClientSidePanel } from '../../store/actions/sidePanelActions';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Button from '../shared/TailwindComponents/Button';
import Input from '../shared/TailwindComponents/Input';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import TextOrFileInput from '../shared/TailwindComponents/TextOrFileInput';
import ToggleWide from '../shared/TailwindComponents/ToggleWide';
import FileUploader from '../shared/TailwindComponents/FileUploader2';
import {
  PiBriefcaseLight,
  PiUsersLight,
  PiNotePencilLight,
  PiChatTextLight,
  PiEnvelopeLight,
  PiScalesLight,
  PiBuildingsLight,
  PiFileTextLight,
  PiCurrencyCircleDollarLight,
  PiHandshakeLight,
  PiTrashLight,
  PiCloudArrowUpLight,
  PiPlusLight,
  PiUserLight,
  PiMagicWandLight,
  PiPencilCircleLight,
} from 'react-icons/pi';

interface AddCaseFormProps {
  clientId?: string;
  clientUserType?: string;
  onClose: () => void;
  setIsAddingCase: (isAddingCase: boolean) => void;
  setIsCaseLoading: (isCaseLoading: boolean) => void;
}

type IndividualFieldName = typeof INDIVIDUAL_OPTIONAL_FIELDS[number]['name'];
type BusinessFieldName = typeof BUSINESS_OPTIONAL_FIELDS[number]['name'];
type OptionalFieldName = IndividualFieldName | BusinessFieldName;

// interface OptionalField {
//   name: OptionalFieldName;
//   label: string;
//   description: string;
//   removable: boolean;
//   defaultShown: boolean;
// }

const INDIVIDUAL_OPTIONAL_FIELDS = [
  { name: 'witness_statements', label: 'Witness Statements', description: 'Include any witness statements.', removable: true, defaultShown: true },
  { name: 'communications', label: 'Communications', description: 'Provide relevant communications.', removable: true, defaultShown: true },
  { name: 'evidence', label: 'Evidence', description: 'Provide any evidence related to the case.', removable: true, defaultShown: true },
];

const BUSINESS_OPTIONAL_FIELDS = [
  { name: 'company_size', label: 'Company Size', description: 'Provide details on the company size.', removable: true, defaultShown: true },
  { name: 'industry', label: 'Industry', description: 'Which industry sector does your business operate in?', removable: true, defaultShown: true },
  { name: 'internal_policies', label: 'Internal Policies', description: 'Attach or describe relevant internal policies.', removable: true, defaultShown: true },
  { name: 'stakeholder_info', label: 'Stakeholder Info', description: 'Details of relevant stakeholders, etc.', removable: true, defaultShown: true },
  { name: 'financial_implications', label: 'Financial Implications', description: 'Describe potential financial impact.', removable: true, defaultShown: true },
  { name: 'third_party_relationships', label: 'Third-Party Relationships', description: 'Info about third-party relationships or vendors.', removable: true, defaultShown: true },
];

const AddCaseForm: React.FC<AddCaseFormProps> = ({
  clientId,
  clientUserType,
  onClose,
  setIsAddingCase,
  setIsCaseLoading,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userType = getUserType();
  // const { hasAccess, isReadOnly } = usePermission('case_management');
  const clients = useSelector((state: RootState) => state.client.allClients);
  const history = useHistory();

  const [uploadMode, setUploadMode] = useState<'structured' | 'bulk'>('structured');
  const [selectedClientID, setSelectedClientID] = useState(clientId || '');
  const [selectedClientUserType, setSelectedClientUserType] = useState(clientUserType || '');
  const [formData, setFormData] = useState<any>({ issue_description: { text: '', file: [] }, custom_fields: [] });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isDirty, setIsDirty] = useState(false);
  const [bulkFiles, setBulkFiles] = useState<File[]>([]);
  const [optionalFields, setOptionalFields] = useState(INDIVIDUAL_OPTIONAL_FIELDS);

  // Field icons mapping for optional fields with a default icon
  const DEFAULT_ICON = <PiFileTextLight size={20} className="text-primary-500" />;
  const fieldIcons: Record<OptionalFieldName, React.ReactNode> = {
    witness_statements: <PiChatTextLight size={20} className="text-primary-500" />,
    communications: <PiEnvelopeLight size={20} className="text-primary-500" />,
    evidence: <PiScalesLight size={20} className="text-primary-500" />,
    company_size: <PiBuildingsLight size={20} className="text-primary-500" />,
    industry: <PiBriefcaseLight size={20} className="text-primary-500" />,
    internal_policies: <PiFileTextLight size={20} className="text-primary-500" />,
    stakeholder_info: <PiUsersLight size={20} className="text-primary-500" />,
    financial_implications: <PiCurrencyCircleDollarLight size={20} className="text-primary-500" />,
    third_party_relationships: <PiHandshakeLight size={20} className="text-primary-500" />,
  };

  // Function to get the icon for a field, falling back to the default
  const getFieldIcon = (fieldName: string): React.ReactNode => {
    return fieldIcons[fieldName as OptionalFieldName] || DEFAULT_ICON;
  };

  // Fetch clients for lawyers if not loaded
  useEffect(() => {
    if (clients.length === 0 && ['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType)) {
      dispatch(fetchAllClients());
    }
  }, [dispatch, clients.length, userType]);

  // Set client ID and user type from props
  useEffect(() => {
    if (clientId) setSelectedClientID(clientId);
    if (clientUserType) setSelectedClientUserType(clientUserType);
  }, [clientId, clientUserType]);

  // Determine optional fields based on client type
  useEffect(() => {
    if (selectedClientUserType === 'BusinessAdmin' || selectedClientUserType === 'BusinessEmployee') {
      setOptionalFields(BUSINESS_OPTIONAL_FIELDS);
    } else if (selectedClientUserType === 'IndividualClient') {
      setOptionalFields(INDIVIDUAL_OPTIONAL_FIELDS);
    }
  }, [selectedClientUserType]);

  // Check if form is dirty
  useEffect(() => {
    let dirty = false;
    if (uploadMode !== 'bulk') {
      const desc = formData.issue_description;
      if (desc?.text?.trim() || (desc?.file && desc.file.length > 0)) dirty = true;
    }
    if (uploadMode === 'structured') {
      optionalFields.forEach((field) => {
        const fVal = formData[field.name];
        if (fVal && (fVal.text?.trim() || (fVal.file && fVal.file.length > 0))) dirty = true;
      });
      if (formData.custom_fields?.length) {
        const anyCustomDirty = formData.custom_fields.some((cf: any) => cf.text?.trim() || (cf.file && cf.file.length > 0));
        if (anyCustomDirty) dirty = true;
      }
    }
    if (uploadMode === 'bulk' && bulkFiles.length > 0) dirty = true;
    setIsDirty(dirty);
  }, [formData, optionalFields, bulkFiles, uploadMode]);

  const validateForm = () => {
    const newErrs: { [key: string]: string } = {};
    if (uploadMode !== 'bulk') {
      const desc = formData.issue_description || {};
      if ((!desc?.text || desc?.text?.trim() === '') && (!desc?.file || desc.file.length === 0)) {
        newErrs.issue_description = t('Issue description is required');
      }
    }
    setErrors(newErrs);
    return Object.keys(newErrs).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: prev[name] && 'text' in prev[name] ? { ...prev[name], text: value } : value,
    }));
  };

  const handleOptionalFieldChange = (fieldName: OptionalFieldName, e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((prev: any) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], text: e.target.value },
    }));
  };

  const handleFileChange = (files: File[], fieldName: OptionalFieldName | string) => {
    setFormData((prev: any) => ({
      ...prev,
      [fieldName]: { ...(typeof prev[fieldName] === 'object' ? prev[fieldName] : {}), file: files },
    }));
  };

  const handleRemoveOptionalField = (fieldName: OptionalFieldName) => {
    setOptionalFields((prev) => prev.filter((f) => f.name !== fieldName));
    setFormData((prev: any) => ({ ...prev, [fieldName]: { text: '', file: [] } }));
  };

  const handleAddClientClick = () => dispatch(showAddClientSidePanel());

  const handleAddCustomField = () => {
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: [...(prev.custom_fields || []), { label: '', text: '', file: [] }],
    }));
  };

  const handleCustomFieldChange = (index: number, field: string, value: any) => {
    const updated = [...(formData.custom_fields || [])];
    updated[index] = { ...updated[index], [field]: value };
    setFormData((prev: any) => ({ ...prev, custom_fields: updated }));
  };

  const handleRemoveCustomField = (index: number) => {
    const updated = [...(formData.custom_fields || [])];
    updated.splice(index, 1);
    setFormData((prev: any) => ({ ...prev, custom_fields: updated }));
  };

  const handleBulkFilesChange = (newFiles: File[]) => setBulkFiles(newFiles);

  const handleCancel = () => {
    if (isDirty) {
      dispatch(
        showModal({
          showModal: true,
          type: 'confirmation',
          message: t('Are you sure you want to discard your changes?'),
          onConfirm: () => {
            dispatch(hideModal());
            onClose();
          },
          onCancel: () => dispatch(hideModal()),
        })
      );
    } else {
      onClose();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsCaseLoading(true);
    const payload = new FormData();
    if (uploadMode !== 'bulk') {
      const desc = formData.issue_description;
      if (desc?.text) payload.append('issue_description', desc.text);
      if (desc?.file?.length) desc.file.forEach((f: File) => payload.append('issue_description', f));
      optionalFields.forEach((fld) => {
        const fVal = formData[fld.name];
        if (fVal?.text) payload.append(fld.name, fVal.text);
        if (fVal?.file?.length) fVal.file.forEach((file: File) => payload.append(fld.name, file));
      });
      (formData.custom_fields || []).forEach((field: any, idx: number) => {
        const customLabel = field.label?.trim().toLowerCase().replace(/\s+/g, '_') || `custom_field_${idx}`;
        if (field.text) payload.append(customLabel, field.text);
        if (field.file?.length) field.file.forEach((file: File) => payload.append(customLabel, file));
      });
    }
    if (uploadMode === 'bulk' && bulkFiles.length > 0) {
      bulkFiles.forEach((file) => payload.append('bulk_files', file));
    }
    if (selectedClientID) payload.append('ClientID', selectedClientID);
    if (selectedClientUserType) payload.append('ClientUserType', selectedClientUserType);

    try {
      setIsAddingCase(false);
      const newCase = await dispatch(createCase(payload));
      dispatch(fetchCases());
      setIsCaseLoading(false);
      if (newCase?.case_id) {
        dispatch(fetchCaseSummary(newCase.case_id));
        history.push(`/cases/${newCase.case_id}`);
      }
    } catch (err) {
      console.error('Error creating case', err);
      setIsCaseLoading(false);
    }
  };

  const toggleOptions = [
    { value: 'structured', label: 'Set Manual Structure', icon: <PiPencilCircleLight size={16} /> },
    { value: 'bulk', label: 'Let PONS handle it', icon: <PiMagicWandLight size={16} /> },
  ];

  return (
    <div className="px-6 pb-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-4xl mx-auto mt-5 h-[calc(100vh-125px)] mb-2 flex flex-col overflow-y-auto">
      {/* Header */}
      <div className="sticky top-0 z-10 pt-6 bg-white dark:bg-gray-900 pb-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center gap-2">
          <PiBriefcaseLight size={28} className="text-primary-500" />
          {t('Add New Case')}
        </h2>
        <div className="flex gap-2">
          <Button variant="neutral" size="small" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" type="submit" form="add-case-form">
            {t('Create Case')}
          </Button>
        </div>
      </div>

      {/* Form Body */}
      <form id="add-case-form" onSubmit={handleSubmit} className="space-y-6 pt-6">
        {/* Client Selection for Lawyers */}
        {['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex items-center gap-2">
              <PiUsersLight size={20} className="text-primary-500" />
              {t('Select Client')}
            </label>
            <div className="flex items-center gap-4">
              <Dropdown
                options={clients.map((c) => ({
                  value: c.ClientID || '',
                  label: c.BusinessName || `${c.FirstName} ${c.LastName}`.trim() || c.Email || 'Unknown Client',
                }))}
                placeholder={t('Select a client')}
                onSelect={(option) => {
                  setSelectedClientID(option.value);
                  const selectedClient = clients.find((cli) => cli.ClientID === option.value);
                  if (selectedClient) {
                    setSelectedClientUserType(selectedClient.UserTypeName);
                  } else {
                    setSelectedClientUserType('');
                  }
                }}
                value={selectedClientID}
                className="w-full"
              />
              <Button variant="tertiary" size="small" onClick={handleAddClientClick} type="button">
                <PiPlusLight />
              </Button>
            </div>

            {/* Client Type Selection (shown only when no client is selected) */}
            {!selectedClientID && (
              <div className="space-y-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  {t('Select Client Type')}
                </label>
                <div className="flex items-center space-x-4">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="clientType"
                      value="IndividualClient"
                      checked={selectedClientUserType === 'IndividualClient'}
                      onChange={() => setSelectedClientUserType('IndividualClient')}
                      className="form-radio"
                    />
                    <PiUserLight size={20} className="text-primary-500" />
                    <span>{t('Individual')}</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="clientType"
                      value="BusinessAdmin"
                      checked={selectedClientUserType === 'BusinessAdmin'}
                      onChange={() => setSelectedClientUserType('BusinessAdmin')}
                      className="form-radio"
                    />
                    <PiBuildingsLight size={20} className="text-primary-500" />
                    <span>{t('Business')}</span>
                  </label>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Upload Mode Toggle */}
        <div>
          <ToggleWide
            options={toggleOptions}
            value={uploadMode}
            onChange={(val) => setUploadMode(val as 'structured' | 'bulk')}
          />
        </div>

        {/* Issue Description (Structured Mode) */}
        {uploadMode !== 'bulk' && (
          <div className="border border-gray-200 dark:border-gray-700 p-4 rounded-md">
            <TextOrFileInput
              id="issue_description"
              label={t('Issue Description')}
              description={t('Provide a detailed description of the issue.')}
              value={formData.issue_description?.text || ''}
              files={formData.issue_description?.file || []}
              onTextChange={(e) => handleChange({ target: { name: 'issue_description', value: e.target.value } } as any)}
              onFilesChange={(files) => handleFileChange(files, 'issue_description')}
              error={errors.issue_description}
              icon={<PiNotePencilLight size={20} className="text-primary-500" />}
            />
          </div>
        )}

        {/* Optional Fields (Structured Mode) */}
        {uploadMode === 'structured' && (
          <>
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{t('Additional Information')}</h3>
              {optionalFields.map((field) => {
                const valObj = formData[field.name] || { text: '', file: [] };
                return (
                  <div key={field.name} className="border border-gray-200 dark:border-gray-700 p-4 rounded-md relative">
                    <TextOrFileInput
                      id={field.name}
                      label={t(field.label)}
                      description={t(field.description)}
                      value={valObj.text || ''}
                      files={valObj.file || []}
                      onTextChange={(e) => handleOptionalFieldChange(field.name, e)}
                      onFilesChange={(files) => handleFileChange(files, field.name)}
                      icon={getFieldIcon(field.name)}
                    />
                    {field.removable && (
                      <Tooltip title={t('Remove field')}>
                        <button
                          type="button"
                          className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                          onClick={() => handleRemoveOptionalField(field.name)}
                        >
                          <PiTrashLight size={20} />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                );
              })}
            </div>

            {/* Custom Fields */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{t('Custom Fields')}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-300">{t('Add any additional information with custom labels.')}</p>
              {(formData.custom_fields || []).map((field: any, idx: number) => (
                <div key={idx} className="border border-gray-200 dark:border-gray-700 p-4 rounded-md relative">
                  <Input
                    name={`custom_field_label_${idx}`}
                    placeholder={t('Label')}
                    value={field.label}
                    onChange={(e) => handleCustomFieldChange(idx, 'label', e.target.value)}
                    type="text"
                    className="mb-2"
                  />
                  <TextOrFileInput
                    id={`custom_field_value_${idx}`}
                    label={field.label || t('Value')}
                    value={field.text}
                    files={field.file}
                    onTextChange={(e) => handleCustomFieldChange(idx, 'text', e.target.value)}
                    onFilesChange={(files) => handleFileChange(files, `custom_field_value_${idx}`)}
                    icon={getFieldIcon(`custom_field_value_${idx}`)} // Use default icon for custom fields
                  />
                  <Tooltip title={t('Remove field')}>
                    <button
                      type="button"
                      className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                      onClick={() => handleRemoveCustomField(idx)}
                    >
                      <PiTrashLight size={20} />
                    </button>
                  </Tooltip>
                </div>
              ))}
              <Button variant="tertiary" size="small" type="button" onClick={handleAddCustomField}>
                {t('Add Custom Field')}
              </Button>
            </div>
          </>
        )}

        {/* Bulk Files (Bulk Mode) */}
        {uploadMode === 'bulk' && (
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <PiCloudArrowUpLight size={20} className="text-primary-500" />
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">{t('Upload Case Files')}</label>
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">{t('Upload all relevant files for the case. PONS will handle the structuring.')}</p>
            <FileUploader files={bulkFiles} onFilesChange={handleBulkFilesChange} />
          </div>
        )}

        {/* Submit Button */}
        <div className="flex justify-end gap-4">
          <Button type="submit" size="small" variant="primary">
            {t('Create Case')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCaseForm;