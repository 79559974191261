import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../store';
import { updatePreferences } from '../store/actions/DataAction';
import { getUserID, getUserType } from '../store/actions/ChatAction';
import { Dispatch } from 'redux'; // Ensure Dispatch is imported

interface ThemeContextProps {
  theme: string;
  isThemeLoading: boolean;
  setTheme: (theme: string) => void;
  toggleTheme: () => Promise<void>;
}

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext<ThemeContextProps>({
  theme: 'light',
  isThemeLoading: false,
  setTheme: () => {},
  toggleTheme: async () => {},
});

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const savedTheme = localStorage.getItem('theme') as 'light' | 'dark' | null;
  const [theme, setThemeState] = useState<string>(savedTheme || 'light');
  const [isThemeLoading, setIsThemeLoading] = useState<boolean>(false);
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const userType = getUserType();

  useEffect(() => {
    if (preferences && preferences.ThemeColor && preferences.ThemeColor !== theme) {
      setThemeState(preferences.ThemeColor);
      const root = window.document.documentElement;
      root.classList.remove('light', 'dark');
      root.classList.add(preferences.ThemeColor);
      localStorage.setItem('theme', preferences.ThemeColor);
    }
  }, [preferences]);

  const setTheme = (newTheme: string) => {
    setThemeState(newTheme);
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');
    root.classList.add(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const toggleTheme = async () => {
    setIsThemeLoading(true);
    const newTheme = theme === 'light' ? 'dark' : 'light';
    const updatedPreferences = {
      UserID: userId,
      UserType: userType,
      Communication: preferences?.Communication || 'Email',
      Language: preferences?.Language || 'en',
      Currency: preferences?.Currency || '',
      CurrencyName: preferences?.CurrencyName || '',
      ThemeColor: newTheme,
    };

    try {
      await dispatch(updatePreferences(updatedPreferences)); // Wait for API success
      setTheme(newTheme); // Only update after API confirms
    } catch (error) {
      console.error('Failed to update theme:', error);
      setTheme(theme); // Revert to current theme if API fails
    } finally {
      setIsThemeLoading(false);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, isThemeLoading, setTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};