import { useEffect, useRef, useState } from 'react';
import AuthPage from '../components/Auth/AuthPage';
import { useHistory } from 'react-router-dom';

const FirstPage = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  // const isDevEnvironment = window.location.hostname.includes('dev.pons.io');
  const isDemoEnvironment = window.location.hostname.includes('demo.pons.io')
    || window.location.hostname.includes('beta.pons.io');
    const userCred = localStorage.getItem('token') || sessionStorage.getItem('token');
  const accessTokenRef = useRef<string | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (userCred) {
      let data = JSON.parse(userCred);
      accessTokenRef.current = data?.token;
    }
    if (accessTokenRef.current) {
        history.push("/tasks")
    }else {
      setLoading(false);
    }
  }, [userCred, history]);


  if (loading) {
    return <div></div>; // Optionally, replace with a spinner component
  }
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', transition: 'all 0.3s ease' }} >
      {isExpanded && !isDemoEnvironment && (
        <i onClick={() => setIsExpanded(false)} className='fas fa-chevron-right icon-hover-effect'></i>
      )}
      <div className='w-full justify-center'>
        <AuthPage />
      </div>
      
    </div>
  );
};

export default FirstPage;