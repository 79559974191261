import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListItem from '../shared/TailwindComponents/ListItem';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import Tabs from '../shared/TailwindComponents/Tabs';
import { LayoutContext } from '../../contexts/LayoutContext';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import apis from '../../utils/apis';
import { fetchCaseById } from '../../store/actions/marketplace2/caseActions';
import { useAppDispatch } from '../../store';
import { showModal, hideModal, handleAttachExistingCaseClick } from '../../store/actions/modalActions';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { deleteClient } from '../../store/actions/ClientAction';
import SkeletonLoader from './SkeletonLoader'; 
import {
  PiUserLight,
  PiEnvelopeLight,
  PiPhoneLight,
  PiMapPinLight,
  PiCheckCircleLight,
  PiHourglassLight,
  PiXCircleLight,
  PiFilePlusLight,
  PiTrashLight,
  PiBriefcaseLight,
  PiIdentificationCardLight,
  PiCreditCardLight,
} from 'react-icons/pi';
import { Tooltip } from '@mui/material';
import { convertLongDateWithTranslation } from '../shared/DateConverter';

interface RouteParams {
  clientId: string;
}

const ClientDetails: React.FC = () => {
  const { clientId } = useParams<RouteParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { setIsContentScrollable } = useContext(LayoutContext);
  const dispatch = useAppDispatch();

  const [client, setClient] = useState<any | null>(null);
  const [clientCases, setClientCases] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('cases');
  const [isEditing, setIsEditing] = useState(false);
  const [editedClient, setEditedClient] = useState<any>(null);

  // Mock financial data for Stripe integration
  const [invoices, setInvoices] = useState([
    { id: 'inv_001', number: 'INV-2025-001', amount: 1500, status: 'open', dueDate: '2025-04-01' },
    { id: 'inv_002', number: 'INV-2025-002', amount: 800, status: 'paid', dueDate: '2025-03-15' },
    { id: 'inv_003', number: 'INV-2025-003', amount: 2000, status: 'overdue', dueDate: '2025-02-28' },
  ]);

  const fetchClientDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await apis({
        method: 'POST',
        url: '/clients/get_client_details',
        data: { ClientID: clientId },
      });

      if (resp && resp.status === 200 && resp.data && resp.data.client) {
        setClient(resp.data.client);
        setClientCases(resp.data.cases || []);
        setEditedClient(resp.data.client);
      } else {
        console.error('Failed to fetch client details', resp.data);
      }
    } catch (err) {
      console.error('Error fetching client details', err);
    } finally {
      setIsLoading(false);
    }
  }, [clientId]);

  useEffect(() => {
    setIsContentScrollable(false);
    return () => setIsContentScrollable(true);
  }, [setIsContentScrollable]);

  useEffect(() => {
    if (clientId) fetchClientDetails();
  }, [clientId, fetchClientDetails]);

  useEffect(() => {
    if (client) {
      const clientName =
        client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee'
          ? client.BusinessName || 'Business Client'
          : `${client.FirstName} ${client.LastName}`;
      setBreadcrumbs([
        { name: 'My Clients', href: 'my-clients', current: false },
        { name: clientName, href: `my-clients/${clientId}`, current: true },
      ]);
    }
    return () => setBreadcrumbs([]);
  }, [client, clientId, setBreadcrumbs]);

  const handleDeleteClient = () => {
    if (!client) return;
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to delete this client?'),
        onConfirm: async () => {
          const success = await dispatch(deleteClient(client.ClientID));
          if (success) history.push('/my-clients');
          dispatch(hideModal());
        },
        onCancel: () => dispatch(hideModal()),
      })
    );
  };

  const handleAddCaseClick = () => {
    if (client) {
      history.push({
        pathname: '/cases',
        state: { isAddingCase: true, clientId: client.ClientID, clientUserType: client.UserTypeName },
      });
    }
  };

  const handleCaseClick = (caseItem: any) => {
    dispatch(fetchCaseById(caseItem.CaseID));
    history.push(`/cases/${caseItem.CaseID}`, { caseItem });
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      setClient({ ...editedClient });
    }
  };


  const handleSendReminder = (invoiceId: string) => {
    console.log(`Sending reminder for invoice ${invoiceId}`);
    alert(`Reminder sent for invoice ${invoiceId}`);
  };

  if (isLoading || !client) return <SkeletonLoader />;

  const clientType =
    client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee'
      ? 'Business'
      : 'Individual';
  const tabs = [
    { label: 'Cases', value: 'cases' },
    { label: 'Financial Data', value: 'financial-data', rightAligned: true },
  ];

  const additionalFields = [
    {
      icon: <PiEnvelopeLight className="h-5 w-5 text-gray-500" />,
      value: client.Email || t('N/A'),
      label: t('Email'),
    },
    {
      icon: <PiPhoneLight className="h-5 w-5 text-gray-500" />,
      value: client.PhoneNumber || t('N/A'),
      label: t('Phone'),
    },
    {
      icon: <PiMapPinLight className="h-5 w-5 text-gray-500" />,
      value: client.Address || t('N/A'),
      label: t('Address'),
    },
  ];
  
  if (clientType === 'Business') {
    additionalFields.push({
      icon: <PiIdentificationCardLight className="h-5 w-5 text-gray-500" />,
      value: client.BusinessIdentityNumber || t('N/A'),
      label: t('Business ID'),
    });
  }

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 p-6">
      {/* Header */}
      <div className="py-4 mx-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => history.goBack()}
              className="text-primary-500 hover:text-primary-600 transition-colors"
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
            <div>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {clientType === 'Business'
                  ? client?.BusinessName || t('No business name')
                  : `${client?.FirstName} ${client?.LastName}`}
              </h1>
              <div className="flex items-center space-x-4 mt-2">
                {/* Client Type Badge */}
                <Badge color={clientType === 'Business' ? 'blue' : 'gray'} className="flex items-center gap-1">
                  {clientType === 'Business' ? (
                    <PiBriefcaseLight className="h-5 w-5" />
                  ) : (
                    <PiUserLight className="h-5 w-5" />
                  )}
                  {clientType === 'Business' ? 'Business Client' : 'Individual Client'}
                </Badge>

                {/* Verification Status Badge */}
                <Badge
                  color={
                    client.IDVerificationStatus === 'Verified'
                      ? 'green'
                      : client.IDVerificationStatus === 'Pending'
                      ? 'yellow'
                      : 'red'
                  }
                  className="flex items-center gap-1"
                >
                  {client.IDVerificationStatus === 'Verified' ? (
                    <PiCheckCircleLight className="h-5 w-5" />
                  ) : client.IDVerificationStatus === 'Pending' ? (
                    <PiHourglassLight className="h-5 w-5" />
                  ) : (
                    <PiXCircleLight className="h-5 w-5" />
                  )}
                  {client.IDVerificationStatus === 'Verified'
                    ? t('Verified')
                    : client.IDVerificationStatus === 'Pending'
                    ? t('Pending Verification')
                    : t('Not Verified')}
                </Badge>

                {/* Additional Fields */}
                {additionalFields.map((field, index) => (
                  <Tooltip key={index} title={field.value} placement="top">
                    <div className="flex items-center space-x-1 max-w-[200px]">
                      {field.icon}
                      <span className="text-sm text-gray-700 dark:text-gray-300 truncate">
                        {field.value}
                      </span>
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Menu as="div" className="relative inline-block text-left ml-4">
              <Menu.Button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors">
                <EllipsisVerticalIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={toggleEdit}
                          className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 flex items-center gap-3`}
                        >
                          {isEditing ? (
                            <PiCheckCircleLight className="h-5 w-5 text-gray-500 dark:text-gray-300" />
                          ) : (
                            <PiIdentificationCardLight className="h-5 w-5 text-gray-500 dark:text-gray-300" />
                          )}
                          {isEditing ? t('Save') : t('Edit')}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleAddCaseClick}
                          className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 flex items-center gap-3`}
                        >
                          <PlusIcon className="h-5 w-5 text-gray-500 dark:text-gray-300" />
                          {t('Add Case')}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => dispatch(handleAttachExistingCaseClick(client.ClientID))}
                          className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 flex items-center gap-3`}
                        >
                          <PiFilePlusLight className="h-5 w-5 text-gray-500 dark:text-gray-300" />
                          {t('Attach Case')}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleDeleteClient}
                          className={`${active ? 'bg-danger-50 dark:bg-danger-700' : ''} w-full text-left px-4 py-2 text-sm text-red-700 dark:text-red-400 flex items-center gap-3`}
                        >
                          <PiTrashLight className="h-5 w-5" />
                          {t('Delete Client')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />

      {/* Cases Tab */}
      {activeTab === 'cases' && (
        <section className="mb-8 transition-all duration-300 animate-fade-in-up">
          <h3 className="text-xl font-semibold mb-4">{t('Client Cases')}</h3>
          {clientCases && clientCases.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-2 overflow-y-auto">
              {clientCases.map((clientCase: any) => (
                <ListItem
                  key={clientCase.CaseID}
                  title={clientCase.CaseName || 'Case'}
                  subtitle={`Status: ${clientCase.case_status || t('Unknown')}`}
                  details={[
                    {
                      label: 'Created At',
                      value: convertLongDateWithTranslation(new Intl.DateTimeFormat('en-GB', {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                      }).format(new Date(clientCase.created_at))),
                    },
                  ]}
                  onClick={() => handleCaseClick(clientCase)}
                  link={''}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-32">
              <p className="text-center text-gray-500">{t('No cases available for this client')}</p>
            </div>
          )}
        </section>
      )}

      {/* Financial Data Tab */}
      {activeTab === 'financial-data' && (
        <section className="mb-8 transition-all duration-300 animate-fade-in-up">
          <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
            <PiCreditCardLight className="text-primary-500" /> {t('Financial Data')}
          </h3>
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <p className="text-sm text-gray-500 mb-4">
              {t('View and manage invoices related to this client.')}
            </p>
            {invoices.length > 0 ? (
              <div className="space-y-4">
                {invoices.map((invoice) => (
                  <div
                    key={invoice.id}
                    className="flex justify-between items-center p-4 border rounded-lg dark:border-gray-700 transition-all hover:shadow-md"
                  >
                    <div className="flex items-center gap-3">
                      <PiCreditCardLight className="text-gray-500" />
                      <div>
                        <p className="text-sm font-medium">{invoice.number}</p>
                        <p className="text-xs text-gray-500">
                          {t('Due')}: {invoice.dueDate}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <Badge
                        color={
                          invoice.status === 'paid'
                            ? 'green'
                            : invoice.status === 'overdue'
                            ? 'red'
                            : 'yellow'
                        }
                      >
                        {t(invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1))}
                      </Badge>
                      <p className="text-sm font-semibold">${invoice.amount.toLocaleString()}</p>
                      <Button
                        onClick={() => handleSendReminder(invoice.id)}
                        variant="tertiary"
                        size="small"
                        className={`${invoice.status === 'paid' ? 'hidden' : ''} flex items-center gap-2`}
                      >
                        <PiEnvelopeLight className="mr-2" /> {t('Send Reminder')}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 italic">{t('No invoices available yet.')}</p>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default ClientDetails;