import apis from "../../utils/apis";
import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { showFeedbackModal } from "./UserFeedbackActions";
import { userInformation, getPreferences } from './DataAction';
import SocketServices from "../../utils/SocketServices";
import { CLEAR_TOKEN, FORGOT_PASSWORD, SET_QR_CODE, SET_TOKEN } from "../types";
import { handleApiError } from "./utils/utils";
import { getAiModels } from "./ChatAction";



interface OtpResponse {
  OTPVerified: string;
  message: string;
}
export const setToken = (token: string | null, userId: string | null) => {
  return {
    type: SET_TOKEN,
    token,
    userId
  };
};

export const clearToken = () => {
  return {
    type: CLEAR_TOKEN,
  };
};

export const newPasswordToken = (uuid: string | null) => {
  return {
    type: FORGOT_PASSWORD,
    uuid
  }
}

export const setQrCode = (qr: string | null) => {
  return {
    type: SET_QR_CODE,
    qr
  }
}
export interface ErrorResponse {
  message: string;
  error?: string
}

export const setOtpErrorMessage = (message: string) => ({
  type: 'SET_OTP_ERROR_MESSAGE',
  payload: message,
});


export const SignIn = (credentials: { Email: string; Password: string; RememberMe: number }) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: 'user/signin',
        data: credentials,
      });

      if (response && response.status === 200) {
        if (response.data.Enable2FA === 'NO') {
          const token = response.data?.AccessToken;
          const userType = response.data?.UserTypeName;
          const refreshToken = response.data?.RefreshToken;
          const isAdmin = response.data?.IsAdmin;
          const roles = response.data?.Roles;
          const tutorialCompleted = response.data?.TutorialCompleted;

          const userData = {
            token,
            userType,
            refreshToken,
            isAdmin,
            roles,
            tutorialCompleted, 
          };

          if (credentials.RememberMe === 0) {
            sessionStorage.setItem('token', JSON.stringify(userData));
          } else {
            localStorage.setItem('token', JSON.stringify(userData));
          }

          dispatch({
            type: 'STORE_USER_INFO',
            payload: {
              ...userData,
            },
          });
          dispatch(getAiModels())
          dispatch(getPreferences());
          await dispatch(userInformation());

          dispatch(
            showFeedbackModal({
              showModal: true,
              message: 'Logged in',
              modalType: 'success',
              duration: 3000,
            })
          );
        }
        return response.data;
      } else {
        handleApiError(response, dispatch)
      }
    } catch (error) {
      handleApiError(error, dispatch);
    }
  };
};

export const signOut = () => {

  return async (dispatch: Dispatch<any>) => {
    try {
      const session_id = sessionStorage.getItem('session_id')
      localStorage.clear()
      sessionStorage.clear()
      if (session_id) {
        SocketServices.emit('logout_session', { "session_id": session_id })
      }
      dispatch(showFeedbackModal({
        showModal: true,
        message: ("Logged out"),
        modalType: 'success',
        duration: 3000
      }));
    } catch (err) {
      handleApiError(err, dispatch)
    }
  }

}

export const forgotPassword = (email: string | File) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      let response: any = await apis({
        method: 'POST',
        url: "user/forgotPassword",
        data: { Email: email }
      })

      if (response && response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: (response.data.message),
          modalType: 'success',
          duration: 3000
        }));
        return response.data
      } else {
        handleApiError(response, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }
  }
}

export const resetPassword = (values: { UID: string; Password: string }) => {
  return async (dispatch: any) => {
    try {
      let response = await apis({
        method: 'POST',
        url: "user/resetPassword",
        data: values
      })

      if (response && response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: ("Password reset"),
          modalType: 'success',
          duration: 3000
        }));
        return response
      } else {
        handleApiError(response, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }
  }
}



export const loginGoogle = (token: Blob) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      let response = await apis({
        "method": 'POST',
        url: "sso/google",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: token,
      })

      if (response && response.status === 200) {
        const token = response?.data?.accessToken;
        const userId = response?.data?.UserID;
        localStorage.setItem('token', JSON.stringify({ token: token, userId: userId }));
        dispatch(showFeedbackModal({
          showModal: true,
          message: ("Logged in"),
          modalType: 'success',
          duration: 3000
        }));
        return token
      } else {
        handleApiError(response, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }
  }
}


export const loginMsal = (token: string) => {
  return async (dispatch: any) => {
    try {
      let response = await apis({
        "method": 'POST',
        url: "auth/msal",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: { token: token },
      })


      if (response && response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: ("Logged in"),
          modalType: 'success',
          duration: 3000
        }));
      } else {
        handleApiError(response, dispatch)
      }

    } catch (err) {
      handleApiError(err, dispatch)

    }
  }
}

export const check2fa = (Email: string, Password: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const response = await apis({
      method: "POST",
      url: "/security/check_for_2fa",
      data: {
        Email,
        Password,
      }
    });
    if (response && response.status === 200) {
      return response.data
    } else {
      handleApiError(response, dispatch)
    }
  } catch (err) {
    handleApiError(err, dispatch)
  }
}

export const generateQr = (UserID: string, UserType: string, Email: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const response = await apis({
      method: "POST",
      url: "/security/initiate_2fa",
      data: {
        UserID,
        UserType,
        Email
      }
    });
    if (response) {
      return response.data
    } else {
      handleApiError(response, dispatch)
    }
  } catch (err) {
    handleApiError(err, dispatch)

  }
}


export const verify2faQr = (
  OTP: string | File,
  UserID?: string,
  UserType?: string,
  Email?: string,
  Password?: string,
  RememberMe?: number
): ThunkAction<Promise<OtpResponse>, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const response = await apis({
      method: "POST",
      url: "/security/verify_2fa",
      data: Email && Password ? { Email, Password, RememberMe, OTP } : { UserID, UserType, OTP }
    });


    if (response) {
      return response.data;
    } else {
     handleApiError(response, dispatch)
    }
  } catch (err) {
    handleApiError(err, dispatch)
    return { OTPVerified: 'NO', message: 'Error occurred' };
  }
};


export const disable2FA = (UserID: string, UserType: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const response = await apis({
      method: "POST",
      url: "/security/disable_2fa",
      data: {
        UserID,
        UserType,
      }
    });
    if (response) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: ("2FA disabled"),
        modalType: 'success',
        duration: 3000
      }));

      return response.data
    } else {
      handleApiError(response, dispatch)
    }
  } catch (err) {
    handleApiError(err, dispatch)
  }
}



export const checkSessionValidation = (UID: string) => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: "POST",
        url: "user/checkValidSession",
        data: {
          UID
        }
      });

      if (response && response.status === 200) {
        return response.data
      }else{
      handleApiError(response, dispatch)
      }
    } catch (e) {
      handleApiError(e, dispatch)
    }
  }
}


export const signIn2Fa = (
  OTP: string,
  Email?: string,
  Password?: string,
  RememberMe?: number
) => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: "POST",
        url: "/user/signin_verify_otp",
        data: {
          OTP,
          Email,
          Password
        }
      });


      if (response && response.status === 200) {
        const token = response.data?.AccessToken;
        const userId = response.data?.UserID;
        const userType = response.data?.UserTypeName;
        const refreshToken = response.data?.RefreshToken;
        const isAdmin = response.data?.IsAdmin;
        const roles = response.data?.Roles;

        const userData = {
          token,
          userType,
          userId,
          refreshToken,
          isAdmin,
          roles,
        };

        if (RememberMe === 0) {
          sessionStorage.setItem('token', JSON.stringify(userData));
        } else {
          localStorage.setItem('token', JSON.stringify(userData));
        }

        await dispatch(userInformation());
        dispatch(getPreferences());
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Logged in',
            modalType: 'success',
            duration: 3000,
          })
        );

        return response;
      } else {
        handleApiError(response, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
      return { OTPVerified: 'NO', message: 'Error occurred' };
    }
  };
}


export const setTutorialCompletedAction = (completed: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: '/user/tutorialCompleted',
        data: { tutorialCompleted: completed },
      });

      if (response.status === 200) {
        // Update Redux
        dispatch({
          type: 'SET_TUTORIAL_COMPLETED',
          payload: completed,
        });

        let tokenString = sessionStorage.getItem('token') || localStorage.getItem('token');
        if (tokenString) {
          const userData = JSON.parse(tokenString);
          userData.tutorialCompleted = completed;
          // rewrite it
          sessionStorage.getItem('token')
            ? sessionStorage.setItem('token', JSON.stringify(userData))
            : localStorage.setItem('token', JSON.stringify(userData));
        }
      }else{
        handleApiError(response, dispatch)
      }
    } catch (error) {
      handleApiError(error, dispatch)
    }
  };
};
