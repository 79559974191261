import React from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  PiFileTextLight,
  PiClockLight,
  PiMagnifyingGlassLight,
  PiNoteLight,
  PiScalesLight,
  PiCurrencyCircleDollarLight,
  PiLightningLight,
} from 'react-icons/pi';

interface TabItem {
  label: string;
  value: string;
  status: string;
  tooltip?: string;
  rightAligned?: boolean;
}

interface TabStatusNavProps {
  tabs: TabItem[];
  activeTab: string;
  onTabClick: (tabValue: string) => void;
}

const TabStatusNav: React.FC<TabStatusNavProps> = ({ tabs, activeTab, onTabClick }) => {
  const { t } = useTranslation();

  const getTabIcon = (tab: TabItem) => {
    const iconClass = tab.status === 'Completed' ? 'text-green-500' : 'text-yellow-500';
    switch (tab.value) {
      case 'Case Information':
        return <PiFileTextLight className={`h-5 w-5 ${iconClass}`} />;
      case 'Chronology':
        return <PiClockLight className={`h-5 w-5 ${iconClass}`} />;
      case 'Case Analysis':
        return <PiMagnifyingGlassLight className={`h-5 w-5 ${iconClass}`} />;
      case 'Memo':
        return <PiNoteLight className={`h-5 w-5 ${iconClass}`} />;
      case 'Legal Outline':
        return <PiScalesLight className={`h-5 w-5 ${iconClass}`} />;
      case 'Financial Data':
        return <PiCurrencyCircleDollarLight className={`h-5 w-5 ${iconClass}`} />;
      case 'Cycle':
        return <PiLightningLight className={`h-5 w-5 ${iconClass}`} />;
      default:
        return <PiFileTextLight className={`h-5 w-5 ${iconClass}`} />;
    }
  };

  return (
    <div className="mb-6 overflow-x-auto">
      <div className="flex border-b border-gray-200 dark:border-gray-700 pb-2 w-full">
        {/* Left-aligned tabs */}
        <div className="flex space-x-6">
          {tabs.filter(tab => !tab.rightAligned).map(tab => {
            const isActive = activeTab === tab.value;
            const tabClasses = isActive
              ? 'text-primary-500 dark:text-primary-300 bg-primary-50 dark:bg-primary-900'
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800';
            return (
              <button
                key={tab.value}
                onClick={() => onTabClick(tab.value)}
                className={`relative flex items-center space-x-2 text-sm font-medium transition-all duration-300 px-2 py-1 rounded-md ${tabClasses}`}
              >
                <Tooltip title={tab.tooltip || ''} placement="top">
                  <div className="flex items-center">{getTabIcon(tab)}</div>
                </Tooltip>
                <span>{t(tab.label)}</span>
              </button>
            );
          })}
        </div>
        {/* Right-aligned tabs */}
        <div className="ml-auto flex space-x-6">
          {tabs.filter(tab => tab.rightAligned).map(tab => {
            const isActive = activeTab === tab.value;
            const tabClasses = isActive
              ? 'text-primary-500 dark:text-primary-300 bg-primary-50 dark:bg-primary-900'
              : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800';
            return (
              <button
                key={tab.value}
                onClick={() => onTabClick(tab.value)}
                className={`relative flex items-center space-x-2 text-sm font-medium transition-all duration-300 px-2 py-1 rounded-md ${tabClasses}`}
              >
                <Tooltip title={tab.tooltip || ''} placement="top">
                  <div className="flex items-center">{getTabIcon(tab)}</div>
                </Tooltip>
                <span>{t(tab.label)}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TabStatusNav;