import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../../store';
import apis from '../../../../utils/apis';
import { showFeedbackModal } from '../../../../store/actions/UserFeedbackActions';
import Input from '../../../shared/TailwindComponents/Input';
import Button from '../../../shared/TailwindComponents/Button';
import { FaSpinner } from 'react-icons/fa';
import { PiLockKeyLight, PiCheckCircleLight, PiXCircleLight } from 'react-icons/pi';

const PasswordTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userInfo) || {};
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  // Define password requirements
  const requirements = [
    { key: 'minLength', label: t('At least 8 characters'), test: (value: string) => value.length >= 8 },
    { key: 'uppercase', label: t('At least one uppercase letter'), test: (value: string) => /[A-Z]/.test(value) },
    { key: 'lowercase', label: t('At least one lowercase letter'), test: (value: string) => /[a-z]/.test(value) },
    { key: 'number', label: t('At least one number'), test: (value: string) => /[0-9]/.test(value) },
    { key: 'specialChar', label: t('At least one special character'), test: (value: string) => /[^A-Za-z0-9]/.test(value) },
  ];

  // State for tracking requirements and password match
  const [metRequirements, setMetRequirements] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  // Validation schema with password requirements
  const validationSchema = yup.object({
    currentPassword: yup.string().required(t('Required')),
    newPassword: yup
      .string()
      .required(t('Required'))
      .min(8, t('Must be at least 8 characters'))
      .matches(/[A-Z]/, t('Must contain at least one uppercase letter'))
      .matches(/[a-z]/, t('Must contain at least one lowercase letter'))
      .matches(/[0-9]/, t('Must contain at least one number'))
      .matches(/[^A-Za-z0-9]/, t('Must contain at least one special character')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], t('Passwords must match'))
      .required(t('Required')),
  });

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsChangingPassword(true);
      try {
        const response = await apis({
          method: 'POST',
          url: '/account/change_password',
          data: {
            CurrentPassword: values.currentPassword,
            NewPassword: values.newPassword,
          },
        });
        if (response && response.status === 200) {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: t('Password changed successfully'),
              modalType: 'success',
              duration: 3000,
            })
          );
          formik.resetForm();
        }
      } catch (error) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to change password'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
      setIsChangingPassword(false);
    },
  });

  // Update met requirements in real-time
  useEffect(() => {
    const value = formik.values.newPassword;
    setMetRequirements({
      minLength: requirements[0].test(value),
      uppercase: requirements[1].test(value),
      lowercase: requirements[2].test(value),
      number: requirements[3].test(value),
      specialChar: requirements[4].test(value),
    });
  }, [formik.values.newPassword]);

  // Check if passwords match in real-time
  useEffect(() => {
    setPasswordsMatch(formik.values.newPassword === formik.values.confirmPassword);
  }, [formik.values.newPassword, formik.values.confirmPassword]);

  // Calculate progress for the strength bar
  const metCount = Object.values(metRequirements).filter(Boolean).length;
  const progress = (metCount / 5) * 100;

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3 animate-fade-in-up">
      <div className="px-4 sm:px-6 md:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
          {t('Change Password')}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
          {t('Set a new password for your account.')}
        </p>
      </div>
      <div className="md:col-span-2">
        <form onSubmit={formik.handleSubmit} className="space-y-8">
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
            {/* Current Password */}
            <div className="col-span-full">
              <Input
                label={t('Current Password')}
                id="currentPassword"
                name="currentPassword"
                type="password"
                italicTxt={t('Enter your current password')}
                placeholder="********"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                className="focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-300"
                prefix={<PiLockKeyLight className="text-gray-500" />}
              />
            </div>

            {/* New Password */}
            <div className="col-span-full">
              <Input
                label={t('New Password')}
                id="newPassword"
                name="newPassword"
                type="password"
                italicTxt={t('Enter your new password')}
                placeholder="********"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
                className="focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-300"
              />
              {/* Progress Bar */}
              <div className="mt-2">
                <div className="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
                  <div
                    className={`h-1 rounded-full transition-all duration-300 ${
                      metCount < 3 ? 'bg-danger-500' : metCount < 5 ? 'bg-warning-500' : 'bg-success-500'
                    }`}
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>
              {/* Requirements List */}
              <div className="mt-2 space-y-1">
                {requirements.map((req) => (
                  <div key={req.key} className="flex items-center">
                    {(metRequirements as any)[req.key] ? (
                      <PiCheckCircleLight className="text-success-500 mr-2" />
                    ) : (
                      <PiXCircleLight className="text-danger-500 mr-2" />
                    )}
                    <span className={`text-sm ${(metRequirements as any)[req.key] ? 'text-success-500' : 'text-gray-500'}`}>
                      {req.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Confirm Password */}
            <div className="col-span-full">
              <Input
                label={t('Confirm Password')}
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                italicTxt={t('Enter your new password again')}
                placeholder="********"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                className="focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-300"
                suffix={
                  formik.values.confirmPassword ? (
                    passwordsMatch ? (
                      <PiCheckCircleLight className="text-success-500" />
                    ) : (
                      <PiXCircleLight className="text-danger-500" />
                    )
                  ) : null
                }
              />
            </div>
          </div>

          {/* Save Button */}
          <div className="mt-8 flex justify-end">
            <Button
              variant="primary"
              size="small"
              type="submit"
              disabled={!formik.isValid || isChangingPassword}
            >
              {isChangingPassword ? (
                <span className="flex items-center">
                  <FaSpinner className="animate-spin mr-2" />
                  {t('Changing...')}
                </span>
              ) : (
                t('Save')
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordTab;