import React, { useState, useRef } from 'react';
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { PiCameraLight, PiFile, PiFileTextLight, PiPaperclipLight } from "react-icons/pi";
import { motion, AnimatePresence } from 'framer-motion';

interface FileInputWithPreviewProps {
  files: File[];
  onFilesChange: (files: File[]) => void;
}

const getFileIcon = (fileType: string) => {
  if (!fileType) return <PiFile className="h-5 w-5 text-gray-500" />;
  if (fileType.includes('pdf')) return <PiFileTextLight className="h-5 w-5 text-red-500" />;
  if (fileType.includes('word') || fileType.includes('msword')) return <PiFileTextLight className="h-5 w-5 text-blue-500" />;
  if (fileType.includes('image')) return <PiCameraLight className="h-5 w-5 text-green-500" />;
  if (fileType.includes('text')) return <PiFileTextLight className="h-5 w-5 text-gray-500" />;
  if (fileType.includes('zip')) return <PiFile className="h-5 w-5 text-yellow-500" />;
  return <PiFile className="h-5 w-5 text-gray-500" />;
};

const FileInputWithPreview: React.FC<FileInputWithPreviewProps> = ({ files, onFilesChange }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [showAllFiles, setShowAllFiles] = useState(false);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      onFilesChange([...files, ...newFiles]);
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    onFilesChange(updatedFiles);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      onFilesChange([...files, ...droppedFiles]);
    }
  };

  const handleOpenFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const fileVariants = {
    hidden: { opacity: 0, x: -30, rotate: -10 },
    visible: { opacity: 1, x: 0, rotate: 0, transition: { type: 'spring', stiffness: 300, damping: 20 } },
    exit: { opacity: 0, x: 30, rotate: 10 },
  };

  const displayedFiles = files.slice(0, 8);
  const moreFilesCount = files.length - 8;

  return (
    <div className="relative">
      <label
        className={`block w-full p-4 rounded-md text-center cursor-pointer transition-all duration-300 ${
          isDragOver ? 'bg-primary-100 border-primary-500' : 'bg-gray-50 dark:bg-gray-800'
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {files.length === 0 ? (
          <div className="flex flex-col items-center">
            <PiPaperclipLight className="h-8 w-8 text-gray-400 dark:text-gray-200" />
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
              {t('Drag & drop files here, or click to select')}
            </p>
            <p className="text-xs text-gray-500">{t('Supports PDFs, DOC, TXT, ZIP...')}</p>
          </div>
        ) : (
          <motion.div
            className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <AnimatePresence>
              {displayedFiles.map((file, index) => (
                <motion.div
                  key={index}
                  variants={fileVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="relative p-2 bg-white dark:bg-gray-700 rounded-md shadow-sm hover:shadow-lg transition-shadow"
                >
                  <div className="flex items-center space-x-2">
                    {getFileIcon(file.type)}
                    <span className="text-sm text-gray-700 dark:text-gray-200 truncate">{file.name}</span>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    className="absolute top-1 right-1 text-gray-500 hover:text-red-500 transition-colors"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </motion.div>
              ))}
            </AnimatePresence>
            {moreFilesCount > 0 && (
              <button
                type="button"
                onClick={() => setShowAllFiles(true)}
                className="flex items-center justify-center p-2 bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
              >
                <span className="text-sm text-gray-500">+{moreFilesCount} more</span>
              </button>
            )}
            <button
              type="button"
              onClick={handleOpenFileDialog}
              className="flex items-center justify-center p-2 bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
            >
              <PlusIcon className="h-6 w-6 text-gray-500" />
            </button>
          </motion.div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          multiple
          className="hidden"
          onChange={handleFileChange}
        />
      </label>

      {/* Animated Overlay for Full File List */}
      {showAllFiles && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="absolute top-0 left-0 w-full bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 p-4"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">{t('All Files')}</h3>
            <button
              type="button"
              onClick={() => setShowAllFiles(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="max-h-64 overflow-y-auto space-y-2">
            {files.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-2 bg-gray-100 dark:bg-gray-700 rounded-md"
              >
                <div className="flex items-center space-x-2">
                  {getFileIcon(file.type)}
                  <span className="text-xs text-gray-700 dark:text-gray-200">{file.name}</span>
                </div>
                <button
                  type="button"
                  onClick={() => handleRemoveFile(index)}
                  className="text-gray-500 hover:text-red-500"
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default FileInputWithPreview;