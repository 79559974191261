import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store";
import { fetchTeamDetails } from "../../../store/actions/organization/teamActions";
import Badge from "../../shared/TailwindComponents/Badge";
import { useBreadcrumbs } from "../../../contexts/BreadcrumbsContext";
import { useTranslation } from "react-i18next";

interface TeamDetailParams {
  teamId: string;
}

const TeamDetail: React.FC = () => {
  const { teamId } = useParams<TeamDetailParams>();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation();

  const team = useSelector((state: RootState) =>
    state.teams.teams.find((t) => t.LegalTeamID === teamId)
  );

  useEffect(() => {
    if (!team) {
      dispatch(fetchTeamDetails(teamId));
    }
  }, [dispatch, teamId, team]);

  useEffect(() => {
    if (team) {
      setBreadcrumbs([
        { name: t("Management"), href: "/management", current: false },
        { name: t("Teams"), href: "/management", current: false },
        { name: team.TeamName, href: `/management/teams/${teamId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [team, teamId, setBreadcrumbs, t]);

  if (!team) {
    return (
      <div className="flex justify-center items-center h-96">
        <p className="text-gray-600 dark:text-gray-300">{t("Loading...")}</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      {/* Team Header */}
      <div className="mb-6 border-b pb-4">
        <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-200">{team.TeamName}</h2>
        <p className="text-sm text-gray-500">
          {t("Created on")}: {new Date(team.CreationDate).toLocaleDateString()}
        </p>
      </div>

      {/* Team Lead Section */}
      <div className="mb-6 bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
          {t("Team Lead")}
        </h3>
        <p className="mt-1 text-gray-600 dark:text-gray-400">
          {team.TeamLeadFirstName} {team.TeamLeadLastName} ({team.TeamLeadEmail})
        </p>
      </div>

      {/* Legal Subjects Section */}
      <div className="mb-6 bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
          {t("Legal Subjects")}
        </h3>
        {team.LegalSubjects.length > 0 ? (
          <div className="flex flex-wrap gap-2 mt-2">
            {team.LegalSubjects.map((subject: any) => (
              <Badge key={subject.LegalSubjectID} color="blue">
                {t(subject.SubCategory)}
              </Badge>
            ))}
          </div>
        ) : (
          <p className="text-sm text-gray-500">{t("No legal subjects assigned.")}</p>
        )}
      </div>

      {/* Team Members Section */}
      <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
          {t("Team Members")}
        </h3>
        {team.Members.length > 0 ? (
          <ul className="mt-3 space-y-4">
            {team.Members.map((member: any) => (
              <li
                key={member.MemberID}
                className="p-4 border rounded-md shadow-sm bg-white dark:bg-gray-800"
              >
                <p className="text-lg font-medium text-gray-800 dark:text-gray-200">
                  {member.FirstName} {member.LastName}
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-400">{member.Email}</p>
                <p className="text-sm text-gray-600 dark:text-gray-400">{member.PhoneNumber}</p>
                <Badge color="green">{t(member.MemberRole)}</Badge>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-sm text-gray-500 mt-2">{t("No team members assigned.")}</p>
        )}
      </div>
    </div>
  );
};

export default TeamDetail;
