import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaUserShield, FaUserFriends, FaTimesCircle, FaGlobeAmericas, FaMoneyBillWave, FaBuilding } from "react-icons/fa";
import { AiOutlineBank, AiOutlineCreditCard, AiOutlineHourglass, AiOutlineInfoCircle } from "react-icons/ai";
import { BsCurrencyExchange, BsShieldCheck, BsExclamationCircle, BsClockHistory, BsListCheck } from "react-icons/bs";
import { FiLoader, FiRefreshCw } from "react-icons/fi";
import { MdAccountBalance, MdCancel } from "react-icons/md";
import paymentApis from "../../../utils/paymentApis";
import { useAppDispatch } from "../../../store";
import { createConnectedAccount } from "../../../store/actions/StripeActions";
import { hideModal, showModal, ShowReceiverOptions } from "../../../store/actions/modalActions";
import { useTranslation } from "react-i18next";

interface ExternalAccount {
    id: string;
    type: string;
    bank_name?: string;
    last4: string;
    currency: string;
    country: string;
    status: string;
}

interface AccountDetails {
    Stripe_connect_id: string;
    Charges_enabled: boolean;
    Payouts_enabled: boolean;
    Details_submitted: boolean;
    Country: string;
    Default_currency: string;
    Business_profile_name?: string;
    Business_profile_url?: string;
    Business_type?: string;
    Capabilities?: Record<string, string>;
    External_accounts?: ExternalAccount[];
    Balance_info?: any;
    Payout_info?: any;
    Verification_status?: any;
    Requirements_due?: any;
}

const ConnectedAccountInfo: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(null);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isAccountActive, setIsAccountActive] = useState(true);
    const [onboardingStarted, setOnboardingStarted] = useState(false);

    useEffect(() => {
        fetchConnectedAccountInfo();
    }, []);

    const handleCreateConnectAccountLink = async () => {
        setOnboardingStarted(true);
        const resp = await dispatch(createConnectedAccount());
        if (resp.url) {
            dispatch(showModal({
                type: "redirect",
                message: t("You will be redirected to Stripe to complete the onboarding."),
                onConfirm: () => { window.location.href = resp.url; },
                onCancel: () => hideModal(),
                showModal: true,
                inputData: resp.url
            }));
        }
        setOnboardingStarted(false);
    };

    async function fetchConnectedAccountInfo() {
        setLoading(true);
        setMessage("");
        try {
            const response = await paymentApis({
                method: "POST",
                url: "stripe/connectedAccount/info",
                data: {}
            });

            if (response.status === 200) {
                setMessage(response.data.message);
                if (response.data.accountDetails.Details_submitted && response.data.accountDetails.Charges_enabled && response.data.accountDetails.Payouts_enabled) {
                    setIsAccountActive(false);
                }

                setAccountDetails(response.data.accountDetails || null);
            }
        } catch (error: any) {
            console.error("Error fetching account info:", error);
            setMessage("Failed to retrieve account info.");
            setAccountDetails(null);
        } finally {
            setLoading(false);
        }
    }

    const handleAddNewBank = async () => {
        dispatch(ShowReceiverOptions());
    };

    return (
        <div className="w-full flex justify-center items-center bg-gray-100 dark:bg-gray-900 p-6">
            <div className="w-full max-w-7xl bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 border border-gray-300 dark:border-gray-700">
                {/* Header Section */}
                <div className="flex items-center justify-between border-b pb-4 mb-4">
                    <h2 className="text-2xl font-semibold text-gray-900 dark:text-white flex items-center gap-2">
                        <FaUserShield className="text-blue-600 dark:text-blue-400" /> {t("Stripe Connected Account")}
                    </h2>
                    <button
                        onClick={fetchConnectedAccountInfo}
                        className="flex items-center gap-2 bg-blue-600 dark:bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 dark:hover:bg-blue-400 transition"
                    >
                        <FiRefreshCw className="w-4 h-4" /> {t("Refresh")}
                    </button>
                </div>

                {/* Loader */}
                {loading ? (
                    <div className="flex justify-center items-center py-6">
                        <FiLoader className="w-8 h-8 animate-spin text-blue-600 dark:text-blue-400" />
                    </div>
                ) : accountDetails ? (
                    <div className="space-y-4">
                        {/* Stripe Account ID */}
                        <div className="flex justify-between py-2 border-b dark:border-gray-700">
                            <span className="font-medium text-gray-700 dark:text-gray-300 flex items-center gap-2">
                                <AiOutlineInfoCircle className="text-gray-500" /> {t("Account ID")}:
                            </span>
                            <span className="text-gray-900 dark:text-white">{accountDetails.Stripe_connect_id}</span>
                        </div>

                        {/* Status Indicators */}
                        {[
                            { label: t("Charges Enabled"), value: accountDetails.Charges_enabled },
                            { label: t("Payouts Enabled"), value: accountDetails.Payouts_enabled },
                            { label: t("Details Submitted"), value: accountDetails.Details_submitted }
                        ].map(({ label, value }, index) => (
                            <div key={index} className="flex justify-between py-2 border-b dark:border-gray-700">
                                <span className="font-medium text-gray-700 dark:text-gray-300">{label}:</span>
                                <span className="flex items-center gap-2">
                                    {value ? <FaCheckCircle className="w-5 h-5 text-green-500" /> : <MdCancel className="w-5 h-5 text-red-500" />}
                                </span>
                            </div>
                        ))}

                        {/* Country & Currency */}
                        {[
                            { label: t("Country"), value: accountDetails.Country, icon: <FaGlobeAmericas className="text-blue-500" /> },
                            { label: t("Currency"), value: accountDetails.Default_currency?.toUpperCase(), icon: <FaMoneyBillWave className="text-green-500" /> }
                        ].map(({ label, value, icon }, index) => (
                            <div key={index} className="flex justify-between py-2 border-b dark:border-gray-700">
                                <span className="font-medium text-gray-700 dark:text-gray-300 flex items-center gap-2">{icon} {label}:</span>
                                <span className="text-gray-900 dark:text-white ">{value}</span>
                            </div>
                        ))}

                        {/* Onboarding Button */}
                        {isAccountActive && (
                            <div className="text-center py-2">
                                <button
                                    className={`mt-4 text-white px-4 py-2 rounded-md text-sm font-medium transition flex gap-2 ${onboardingStarted ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-600"}`}
                                    disabled={onboardingStarted}
                                    onClick={handleCreateConnectAccountLink}>
                                    {onboardingStarted ? <FiLoader className="animate-spin" /> : ""}
                                    {onboardingStarted ? t("Processing...") : t("Complete Onboarding")}
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="text-center py-6">
                        <p className="text-gray-600 dark:text-gray-300">{message || t("No connected account found.")}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConnectedAccountInfo;
