import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../store';
import { fetchFileCategorys, fetchStructure } from '../../store/actions/FolderAction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { hideModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createNewDocument } from '../../store/actions/DocumentsAction';
import EmailTagsInput from '../Documents/EmailTagInput';
import dayjs, { Dayjs } from 'dayjs';
import { hideSidePanel } from '../../store/actions/sidePanelActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { getUserType } from '../../store/actions/ChatAction';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import { FileProps, FolderProps } from '../Documents/types';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { PiFile, PiPlusCircle, PiTrash, PiCloudArrowUp } from 'react-icons/pi';
import FileUploader from '../shared/TailwindComponents/FileUploader2';

export interface NewFileData {
  folderId: string;
  fileName: string;
  fileType: 'Contract' | 'Legal Document' | 'General' | 'Template';
  uploadedFile: File | undefined;
  additionalFields: {
    PartiesInvolved?: string[];
    StartDate?: string;
    EndDate?: string;
    Approvers?: string[];
    Signatories?: string[];
    SharedWith?: { email: string; permission: string }[];
    LegalType?: string;
    CaseID?: string;
    Expiry?: string;
  };
}

interface NewFileProps {
  folders: FolderProps[] | undefined;
  preSelectedFolderId?: string;
  onFileCreated?: (newFile: FileProps) => void;
  onClose: () => void; // Added for navigation back
}

const NewFile: React.FC<NewFileProps> = ({ folders, preSelectedFolderId, onFileCreated, onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const fileTypes = useSelector((state: RootState) => state.folders.fileTypes);
  const structure = useSelector((state: RootState) => state.folders.structure);
  const [selectedFileCategory, setSelectedFileCategory] = useState<'Contract' | 'Legal Document' | 'General' | 'Template'>('General');
  const caseList = useSelector((root: RootState) => root.casesM.cases || []);
  const [files, setFiles] = useState<File[]>([]);
  const userType = getUserType();

  useEffect(() => {
    dispatch(fetchFileCategorys());
    dispatch(fetchCases());
  }, [dispatch]);

  const formattedCaseOptions = caseList.map((caseItem: any) => ({
    label: `${caseItem.CaseName}`,
    value: caseItem.CaseID,
  }));

  const getValidationSchema = () => {
    let schemaFields = {
      FolderID: Yup.string().required(t('Please select a folder')),
      Title: Yup.string().required(t('File title/name required')),
      FileCategory: Yup.string().required(t('File category is required')),
      CaseID: Yup.string().when('FileCategory', (FileCategory, schema) => {
        if (typeof FileCategory === 'string' && FileCategory === 'Legal Document') {
          return schema.required(t('Case ID is required for legal documents'));
        }
        return schema;
      }),
    };
    return Yup.object().shape(schemaFields);
  };

  const formik = useFormik({
    initialValues: {
      FolderID: '',
      FolderName: '',
      Title: '',
      Content: '',
      FileCategory: 'General' as 'Contract' | 'Legal Document' | 'General' | 'Template',
      PartiesInvolved: [],
      StartDate: '',
      EndDate: '',
      Approvers: [],
      Signatories: [],
      SharedWith: [] as { email: string; permission: string }[],
      LegalType: '',
      CaseID: '',
      Expiry: '',
    },
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const additionalFields = {
        PartiesInvolved: values.PartiesInvolved || [],
        StartDate: values.StartDate,
        EndDate: values.EndDate,
        Approvers: values.Approvers || [],
        Signatories: values.Signatories || [],
        SharedWith: values.SharedWith || [],
        LegalType: values.LegalType,
        CaseID: values.CaseID,
        Expiry: values.Expiry || '',
      };

      const newFileData: NewFileData = {
        folderId: preSelectedFolderId ? preSelectedFolderId : values.FolderID,
        fileName: values.Title,
        fileType: values.FileCategory,
        uploadedFile: files[0] || undefined,
        additionalFields,
      };
      await handleConfirmCreateFile(newFileData);
      formik.resetForm();
      setFiles([]);
      dispatch(hideModal());
      dispatch(hideSidePanel());
      onClose(); // Navigate back after creation
    },
  });

  useEffect(() => {
    if (preSelectedFolderId && folders) {
      formik.setFieldValue('FolderID', preSelectedFolderId);
      const selectedFolder = folders.find((folder) => folder.FolderID.toString() === preSelectedFolderId);
      if (selectedFolder) {
        formik.setFieldValue('FolderName', selectedFolder.FolderName);
      }
    }
  }, [preSelectedFolderId, folders]);

  const buildFolderOptions = (folders: FolderProps[]): DropdownOption[] => {
    return folders.map((folder) => ({
      value: folder.FolderID.toString(),
      label: folder.FolderName,
      options: folder.SubFolders && folder.SubFolders.length > 0 ? buildFolderOptions(folder.SubFolders) : undefined,
    }));
  };

  const folderOptions: DropdownOption[] = buildFolderOptions(structure);

  const handleFolderChange = (selectedOption: DropdownOption) => {
    formik.setFieldValue('FolderID', selectedOption.value);
    formik.setFieldValue('FolderName', selectedOption.label);
  };

  const handleFileCategorySelect = (fileType: 'Contract' | 'Legal Document' | 'General' | 'Template') => {
    setSelectedFileCategory(fileType);
    formik.setFieldValue('FileCategory', fileType);
  };

  const handleConfirmCreateFile = async (newFileData: NewFileData) => {
    const { folderId, fileName, fileType, uploadedFile, additionalFields } = newFileData;

    if (!userType) {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Something went wrong, please try again.'),
          showModal: true,
          duration: 3000,
        })
      );
      return;
    }

    let additionalFieldsData: { [key: string]: string | string[] | { email: string; permission: string }[] } = {};
    switch (fileType) {
      case 'Contract':
        additionalFieldsData = {
          FileName: fileName,
          PartiesInvolved: additionalFields.PartiesInvolved || [],
          ContractStart: additionalFields.StartDate || '',
          Approvers: additionalFields.Approvers || [],
          Signatories: additionalFields.Signatories || [],
          Expiry: additionalFields.Expiry || '',
          SharedWith: additionalFields.SharedWith || [],
        };
        break;
      case 'Legal Document':
        additionalFieldsData = {
          FileName: fileName,
          LegalType: additionalFields.LegalType || '',
          CaseID: additionalFields.CaseID || '',
          Expiry: additionalFields.Expiry || '',
          Approvers: additionalFields.Approvers || [],
          Signatories: additionalFields.Signatories || [],
          SharedWith: additionalFields.SharedWith || [],
        };
        break;
      case 'General':
      case 'Template':
        additionalFieldsData = {
          FileName: fileName,
          Expiry: additionalFields.Expiry || '',
          Approvers: additionalFields.Approvers || [],
          Signatories: additionalFields.Signatories || [],
          SharedWith: additionalFields.SharedWith || [],
        };
        break;
      default:
        console.error('Unknown file type:', fileType);
        return;
    }

    try {
      const newFile: any = await dispatch(createNewDocument(fileType, folderId.toString(), uploadedFile, additionalFieldsData));
      if (newFile) {
        dispatch(fetchStructure());
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('File created successfully'),
            modalType: 'success',
            duration: 3000,
          })
        );
        if (onFileCreated) onFileCreated(newFile);
      }
    } catch (error) {
      console.error('Error creating document:', error);
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Failed to create file'),
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  };

  const handleAddSharedWith = () => {
    formik.setFieldValue('SharedWith', [...formik.values.SharedWith, { email: '', permission: 'read' }]);
  };

  const handleRemoveSharedWith = (index: number) => {
    const updatedSharedWith = [...formik.values.SharedWith];
    updatedSharedWith.splice(index, 1);
    formik.setFieldValue('SharedWith', updatedSharedWith);
  };

  return (
    <div className="px-6 pb-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-3xl w-full mx-auto mt-5 h-[calc(100vh-125px)] flex flex-col overflow-y-auto">
      {/* Sticky Header */}
      <div className="sticky top-0 z-10 pt-6 bg-white dark:bg-gray-900 pb-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center gap-2">
          <PiFile size={28} className="text-primary-500" />
          {t('Create New File')}
        </h2>
        <div className="flex gap-2">
          <Button variant="neutral" size="small" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" type="submit" form="new-file-form">
            {t('Create')}
          </Button>
        </div>
      </div>

      {/* Form */}
      <form id="new-file-form" onSubmit={formik.handleSubmit} className="space-y-6 mt-4 flex-1">
        <Input
          label={t('File Name')}
          type="text"
          id="Title"
          name="Title"
          value={formik.values.Title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={t('Enter file name')}
          error={formik.touched.Title && formik.errors.Title ? formik.errors.Title : undefined}
          required
          className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
        />

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
            {t('Folder')} <span className="text-red-400 ml-1">*</span>
          </label>
          {preSelectedFolderId ? (
            <div className="bg-gray-100 dark:bg-gray-800 p-3 rounded-lg text-gray-700 dark:text-gray-200">
              {t(formik.values.FolderName)}
            </div>
          ) : (
            <Dropdown
              options={folderOptions}
              onSelect={handleFolderChange}
              value={formik.values.FolderID}
              placeholder={t('Select a folder')}
              className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
            />
          )}
          {formik.touched.FolderID && formik.errors.FolderID && (
            <span className="text-red-500 text-sm">{t(formik.errors.FolderID)}</span>
          )}
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {t('File Type')} <span className="text-red-400 ml-1">*</span>
          </label>
          <Dropdown
            options={fileTypes?.map((type: any) => ({
              value: type.FileTypeName,
              label: type.FileTypeName,
              active: true,
            })) || []}
            onSelect={(option) => handleFileCategorySelect(option.value as 'Contract' | 'Legal Document' | 'General' | 'Template')}
            value={formik.values.FileCategory}
            placeholder={t('Select file category')}
            className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
          />
          {formik.touched.FileCategory && formik.errors.FileCategory && (
            <span className="text-red-500 text-sm">{t(formik.errors.FileCategory)}</span>
          )}
        </div>

        {formik.values.FileCategory === 'Contract' && (
          <div className="space-y-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
            <h3 className="text-md text-gray-900 dark:text-gray-100">{t('Contract Details')}</h3>
            <EmailTagsInput
              label={t('Parties Involved')}
              value={formik.values.PartiesInvolved}
              placeholder={t('Enter parties involved')}
              onChange={(tags) => formik.setFieldValue('PartiesInvolved', tags)}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <CustomDateTimePicker
                label={t('Start Date')}
                value={formik.values.StartDate ? dayjs(formik.values.StartDate) : null}
                onChange={(newValue: Dayjs | null) =>
                  formik.setFieldValue('StartDate', newValue?.format('YYYY-MM-DD HH:mm:ss') || '')
                }
                minDateTime={dayjs()}
              />
              <CustomDateTimePicker
                label={t('End Date')}
                value={formik.values.EndDate ? dayjs(formik.values.EndDate) : null}
                onChange={(newValue: Dayjs | null) =>
                  formik.setFieldValue('EndDate', newValue?.format('YYYY-MM-DD HH:mm:ss') || '')
                }
                minDateTime={dayjs()}
              />
            </div>
          </div>
        )}

        {formik.values.FileCategory === 'Legal Document' && (
          <div className="space-y-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
            <h3 className="text-md text-gray-900 dark:text-gray-100">{t('Legal Document Details')}</h3>
            <Input
              label={t('Legal Type')}
              type="text"
              value={formik.values.LegalType}
              onChange={formik.handleChange('LegalType')}
              placeholder={t('Enter legal type')}
              id="LegalType"
              className="bg-white dark:bg-gray-800 rounded-lg"
            />
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t('Case')} <span className="text-red-400 ml-1">*</span>
              </label>
              <Dropdown
                options={formattedCaseOptions}
                onSelect={(selectedOption: any) => formik.setFieldValue('CaseID', selectedOption.value)}
                value={formik.values.CaseID}
                placeholder={t('Select a case')}
                className="bg-white dark:bg-gray-800 rounded-lg"
              />
            </div>
          </div>
        )}

        <CustomDateTimePicker
          label={t('Deadline')}
          value={formik.values.Expiry ? dayjs(formik.values.Expiry) : null}
          onChange={(newValue: Dayjs | null) =>
            formik.setFieldValue('Expiry', newValue?.format('YYYY-MM-DD HH:mm:ss') || '')
          }
          minDateTime={dayjs()}
        />

        <EmailTagsInput
          label={t('Signatories')}
          value={formik.values.Signatories}
          placeholder={t('Enter signatories')}
          onChange={(tags) => formik.setFieldValue('Signatories', tags)}
        />

        <div className="space-y-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
          <h3 className="text-md text-gray-900 dark:text-gray-100">{t('Share Access')}</h3>
          {formik.values.SharedWith.map((item, index) => (
            <div key={index} className="flex items-center space-x-3">
              <Input
                type="email"
                name={`SharedWith[${index}].email`}
                value={item.email}
                onChange={formik.handleChange}
                placeholder={t('Enter email')}
                className="flex-1 bg-white dark:bg-gray-800 rounded-lg"
              />
              <Dropdown
                options={[
                  { value: 'read', label: 'Read' },
                  { value: 'write', label: 'Write' },
                ]}
                onSelect={(option) => formik.setFieldValue(`SharedWith[${index}].permission`, option.value)}
                value={item.permission}
                placeholder={t('Select permission')}
                className="w-32 bg-white dark:bg-gray-800 rounded-lg"
              />
              <button
                type="button"
                onClick={() => handleRemoveSharedWith(index)}
                className="text-red-500 hover:text-red-700"
              >
                <PiTrash size={20} />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddSharedWith}
            className="flex items-center space-x-2 text-primary-500 hover:text-primary-700"
          >
            <PiPlusCircle size={16} />
            <span className='text-xs'>{t('Add')}</span>
          </button>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
            <PiCloudArrowUp className="mr-2" size={20} />
            {t('Upload File')}
          </label>
          <FileUploader files={files} onFilesChange={setFiles}  />
        </div>
        <div className='flex justify-end'>
        <Button variant="primary" size="small" type="submit" form="new-file-form">
            {t('Create')}
          </Button>
          </div>
      </form>
    </div>
  );
};

export default NewFile;