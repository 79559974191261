import React from 'react';
import clsx from 'clsx';
import Badge from './Badge';
import { BadgeColor } from './types/badgeTypes';
import {
  PiUserLight,
  PiBuildingLight,
  PiEnvelopeLight,
  PiCheckCircleLight,
  PiBriefcaseLight,
  PiTagLight,
  PiCalendarLight,
  PiClockLight,
  PiDatabaseLight,
  PiGaugeLight,
  PiFileTextLight,
  PiTagSimpleLight,
  PiCurrencyDollarLight,
} from 'react-icons/pi';
import { GoHistory, GoDependabot } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

interface TableProps {
  columns: string[];
  data: Record<string, any>[];
  hiddenColumns?: Set<string>;
  fixedHeader?: boolean;
  onRowClick?: (row: any) => void;
  renderRow?: (row: any, rowIndex: number) => React.ReactNode;
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  hiddenColumns = new Set(),
  fixedHeader = false,
  onRowClick,
  renderRow,
}) => {
  const { t } = useTranslation();

  const verificationBadgeMap: Record<string, { label: string; color: string; tooltip?: string }> = {
    'Verified': { label: 'Verified', color: 'green', tooltip: 'Client is verified' },
    'Pending Verification': { label: 'Pending', color: 'yellow', tooltip: 'Verification pending' },
    'Not Verified': { label: 'Not Verified', color: 'red', tooltip: 'Client must verify' },
  };

  const getClientTypeBadge = (type: string) => ({
    label: type === 'Business' ? 'Business Client' : 'Individual Client',
    color: type === 'Business' ? 'blue' : 'purple',
  });

  const getTicketStatusBadgeColor = (status: string): BadgeColor => {
    switch (status) {
      case 'Open':
        return 'yellow';
      case 'In Progress':
        return 'blue';
      case 'Resolved':
        return 'green';
      case 'Closed':
        return 'gray';
      default:
        return 'gray';
    }
  };

  const getResolvedBadgeColor = (resolved: string): BadgeColor => {
    return resolved === t('Yes') ? 'green' : 'red';
  };

  const columnIconMap: Record<string, React.ReactNode> = {
    'Name': <PiUserLight className="text-gray-500" size={16} />,
    'Email': <PiEnvelopeLight className="text-gray-500" size={16} />,
    'Designation': <PiBriefcaseLight className="text-gray-500" size={16} />,
    'Status': <PiTagLight className="text-gray-500" size={16} />,
    'Department': <PiBuildingLight className="text-gray-500" size={16} />,
    'Join Date': <PiCalendarLight className="text-gray-500" size={16} />,
    'Case Name': <PiBriefcaseLight className="text-gray-500" size={16} />,
    'Verification': <PiCheckCircleLight className="text-gray-500" size={16} />,
    'Client Type': <PiBuildingLight className="text-gray-500" size={16} />,
    'Case Count': <PiBriefcaseLight className="text-gray-500" size={16} />,
    'Task Name': <PiBriefcaseLight className="text-gray-500" size={16} />,
    'Assignee': <PiUserLight className="text-gray-500" size={16} />,
    'Due Date': <PiClockLight className="text-gray-500" size={16} />,
    'Agent Name': <GoDependabot className="text-gray-500" size={16} />,
    'Documents': <PiFileTextLight className="text-gray-500" size={16} />,
    'Size': <PiDatabaseLight className="text-gray-500" size={16} />,
    'Last Indexed': <GoHistory className="text-gray-500" size={16} />,
    'Usage': <PiGaugeLight className="text-gray-500" size={16} />,
    'Subject': <PiEnvelopeLight className="text-gray-500" size={16} />,
    'Category': <PiTagLight className="text-gray-500" size={16} />,
    'Resolved': <PiCheckCircleLight className="text-gray-500" size={16} />,
    'Created': <PiCalendarLight className="text-gray-500" size={16} />,
    // Add 'Created At' if your support ticket table uses it
    'Created At': <PiCalendarLight className="text-gray-500" size={16} />,
    'Ticket ID': <PiTagSimpleLight className="text-gray-500" size={16} />, 
    'Service Name': <PiTagLight className="text-gray-500" size={16} />,
    'Base Query Cost': <PiCurrencyDollarLight className="text-gray-500" size={16} />,
    'Usage Count': <PiGaugeLight className="text-gray-500" size={16} />,
    'Total Cost': <PiCurrencyDollarLight className="text-gray-500" size={16} />,
  };

  return (
    <div className="overflow-x-auto mt-4 rounded-xl shadow-md dark:shadow-lg animate-fade-in-up">
      <table className="min-w-full divide-y divide-primary-200 dark:divide-primary-700">
        <thead className="bg-primary-50 dark:bg-primary-900 sticky top-0 z-10">
          <tr>
            {columns.map((col) => (
              <th
                key={col}
                scope="col"
                className={clsx(
                  'px-6 py-4 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider',
                  hiddenColumns.has(col) && 'hidden lg:table-cell'
                )}
              >
                <div className="flex items-center space-x-2">
                  {columnIconMap[col] || <PiUserLight className="text-gray-500" size={16} />}
                  <span>{t(col)}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-primary-200 dark:divide-primary-700">
          {data.map((row, rowIdx) => {
            if (renderRow) return renderRow(row, rowIdx);
            return (
              <tr
                key={row.id || rowIdx}
                className={clsx(
                  'cursor-pointer transition-all duration-300',
                  rowIdx % 2 === 0
                    ? 'bg-primary-50 dark:bg-primary-900'
                    : 'bg-primary-100 dark:bg-primary-800',
                  'hover:bg-primary-200',
                  'dark:hover:bg-primary-700'
                )}
                onClick={() => onRowClick && onRowClick(row)}
              >
                {columns.map((col) => {
                  let cellContent = row[col];
                  if (col === 'Verification') {
                    const mapItem = verificationBadgeMap[row[col]] || {
                      label: row[col] || 'N/A',
                      color: 'gray',
                      tooltip: '',
                    };
                    cellContent = (
                      <Badge color={mapItem.color as BadgeColor} tooltip={t(`${mapItem.tooltip}`)}>
                        {t(mapItem.label)}
                      </Badge>
                    );
                  } else if (col === 'Client Type') {
                    const { label, color } = getClientTypeBadge(row[col]);
                    cellContent = <Badge color={color as BadgeColor}>{t(label)}</Badge>;
                  } else if (col === 'Status') {
                    cellContent = row[col] ? (
                      <Badge color={getTicketStatusBadgeColor(row[col])}>
                        {row[col]}
                      </Badge>
                    ) : (
                      'N/A'
                    );
                  } else if (col === 'Resolved') {
                    cellContent = row[col] ? (
                      <Badge color={getResolvedBadgeColor(row[col])}>
                        {row[col]}
                      </Badge>
                    ) : (
                      'N/A'
                    );
                  }
                  return (
                    <td
                      key={col}
                      className={clsx(
                        'px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200',
                        hiddenColumns.has(col) && 'hidden lg:table-cell'
                      )}
                    >
                      {cellContent}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;