import React from 'react';

const TicketDetailsSkeleton: React.FC = () => {
  return (
    <div className="animate-fadePulse space-y-6 mx-auto px-4 mt-6">
      <div className="h-8 bg-gray-300 dark:bg-gray-700 rounded w-3/4"></div>
      <div className="flex flex-wrap gap-4">
        <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-24"></div>
        <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-24"></div>
        <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-24"></div>
      </div>
      <div className="h-32 bg-gray-300 dark:bg-gray-700 rounded"></div>
      <div className="h-6 bg-gray-300 dark:bg-gray-700 rounded w-1/2"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="h-48 bg-gray-300 dark:bg-gray-700 rounded"></div>
        <div className="h-48 bg-gray-300 dark:bg-gray-700 rounded"></div>
        <div className="h-48 bg-gray-300 dark:bg-gray-700 rounded"></div>
      </div>
    </div>
  );
};

export default TicketDetailsSkeleton;