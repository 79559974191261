import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { RootState, useAppDispatch } from '../../../store';
import * as Yup from 'yup';
import { getUserType } from '../../../utils/authUtils';
import { fetchAllEmployees, onboardEmployee } from '../../../store/actions/organization/employeeActions';
import { fetchLawfirmDepartments, fetchBusinessDepartments } from '../../../store/actions/DropdownActions';
import Input from '../../shared/TailwindComponents/Input';
import Button from '../../shared/TailwindComponents/Button';
import { showPasswordModal } from '../../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import { PiUserLight, PiEnvelopeLight, PiPhoneLight, PiBuildingsLight, PiClockLight, PiBriefcaseLight, PiUserPlusLight, PiUsersLight } from 'react-icons/pi';
import Toggle from '../Toggle';
import { useSelector } from 'react-redux';
import DepartmentInput from './DepartmentInput'; 

interface OnboardingProps {
  onClose: () => void;
}

const Onboarding: React.FC<OnboardingProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const { t } = useTranslation();

  useEffect(() => {
    if (userType === 'LawFirmAdmin') {
      dispatch(fetchLawfirmDepartments());
    } else if (userType === 'BusinessAdmin') {
      dispatch(fetchBusinessDepartments());
    }
  }, [dispatch, userType]);

  const departments = useSelector((state: RootState) =>
    userType === 'LawFirmAdmin' ? state.dropdown.lawfirmDepartments : state.dropdown.businessDepartments
  );

  const formik = useFormik({
    initialValues: {
      FirstName: '',
      LastName: '',
      Email: '',
      PhoneNumber: '',
      CompanyRole: '',
      FixedWorkingHours: '',
      Designation: '',
      DisplayLawfirmName: false,
      Department: '',
      JoinDate: '',
      Role: '',
    },
    validationSchema: Yup.object({
      FirstName: Yup.string().required('First name is required'),
      LastName: Yup.string().required('Last name is required'),
      Email: Yup.string().email('Invalid email address').required('Email is required'),
      PhoneNumber: Yup.string().required('Phone number is required'),
      JoinDate: Yup.string()
        .matches(/^\d{2}-\d{2}-\d{4}$/, 'Join date must be in DD-MM-YYYY format')
        .optional(),
      Department: Yup.string().required('Department is required'), // Now mandatory
      Role: Yup.string().optional(),
    }),
    onSubmit: async (values) => {
      dispatch(
        showPasswordModal(async (password: string) => {
          try {
            const employeeData = {
              ...values,
              AdminPassword: password,
              AgreeToTerms: 'Yes',
              DisplayLawfirmName: values.DisplayLawfirmName ? 'Yes' : 'No',
            };
            await dispatch(onboardEmployee(employeeData));
            await dispatch(fetchAllEmployees());
            onClose();
          } catch (error) {
            console.error('Error onboarding employee:', error);
          }
        })
      );
    },
  });

  const handleCancel = () => {
    onClose();
  };

  const departmentsWithIcons = departments.map((dept) => ({
    ...dept,
    icon: <PiUsersLight size={16} className="text-gray-500" />,
  }));

  if (userType !== 'LawFirmAdmin' && userType !== 'BusinessAdmin') {
    return <div className="p-6 text-gray-800 dark:text-white">{t('You do not have permission to onboard employees.')}</div>;
  }

  return (
    <div className="px-6 pb-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-2xl mx-auto mt-5 h-[calc(100vh-125px)] flex flex-col overflow-y-auto">
      <div className="sticky top-0 z-10 pt-6 bg-white dark:bg-gray-900 pb-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center gap-2">
          <PiUserPlusLight size={28} className="text-primary-500" />
          {t('Onboard New Employee')}
        </h2>
        <div className="flex gap-2">
          <Button variant="neutral" size="small" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" type="submit" form="onboard-employee-form" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? t('Onboarding...') : t('Onboard Employee')}
          </Button>
        </div>
      </div>
      <form id="onboard-employee-form" onSubmit={formik.handleSubmit} className="space-y-6 mt-4 flex-1">
        <Input
          label={t('First Name')}
          name="FirstName"
          value={formik.values.FirstName}
          onChange={formik.handleChange}
          error={formik.touched.FirstName ? t(`${formik.errors.FirstName}`) : undefined}
          description={t("Employee's first name.")}
          placeholder={t('John')}
          type="text"
          icon={<PiUserLight size={20} className="text-gray-500" />}
        />
        <Input
          label={t('Last Name')}
          name="LastName"
          value={formik.values.LastName}
          onChange={formik.handleChange}
          error={formik.touched.LastName ? t(`${formik.errors.LastName}`) : undefined}
          description={t("Employee's last name.")}
          placeholder={t('Doe')}
          type="text"
          icon={<PiUserLight size={20} className="text-gray-500" />}
        />
        <Input
          label={t('Email')}
          name="Email"
          type="email"
          value={formik.values.Email}
          onChange={formik.handleChange}
          error={formik.touched.Email ? t(`${formik.errors.Email}`) : undefined}
          description={t('Official email address for account setup.')}
          placeholder={t('johndoe@mail.com')}
          icon={<PiEnvelopeLight size={20} className="text-gray-500" />}
        />
        <Input
          label={t('Phone Number')}
          name="PhoneNumber"
          value={formik.values.PhoneNumber}
          onChange={formik.handleChange}
          error={formik.touched.PhoneNumber ? formik.errors.PhoneNumber : undefined}
          description={t('Contact number for communication.')}
          placeholder={t('#####')}
          type="text"
          icon={<PiPhoneLight size={20} className="text-gray-500" />}
        />
          <DepartmentInput
            label={t('Department')}
            departments={departmentsWithIcons} // Updated to use departments with icons
            value={formik.values.Department}
            onChange={(value) => formik.setFieldValue('Department', value)}
            description={t('Department the employee belongs to.')}
            placeholder={t('Select or enter department')}
            error={formik.touched.Department ? t(`${formik.errors.Department}`) : undefined}
          />
        <Input
          label={t('Role (Optional)')}
          name="Role"
          value={formik.values.Role}
          onChange={formik.handleChange}
          description={t('Role or job function within the department.')}
          placeholder={t('Senior Counsel')}
          type="text"
          icon={<PiBriefcaseLight size={20} className="text-gray-500" />}
        />
        <Input
          label={t('Fixed Working Hours (Optional)')}
          name="FixedWorkingHours"
          value={formik.values.FixedWorkingHours}
          onChange={formik.handleChange}
          description={t('Standard working hours.')}
          placeholder={t('8')}
          type="number"
          icon={<PiClockLight size={20} className="text-gray-500" />}
        />
        <Input
          label={t('Designation (Optional)')}
          name="Designation"
          value={formik.values.Designation}
          onChange={formik.handleChange}
          description={t('Job title or position.')}
          placeholder={t('Lawyer')}
          type="text"
          icon={<PiBriefcaseLight size={20} className="text-gray-500" />}
        />
        {userType === 'LawFirmAdmin' && (
          <Toggle
            label={t('Display Law Firm Name')}
            description={t('Toggle to show or hide the law firm name.')}
            checked={formik.values.DisplayLawfirmName}
            onChange={(checked) => formik.setFieldValue('DisplayLawfirmName', checked)}
          />
        )}
        <div className="flex justify-end">
          <Button variant="primary" size="small" type="submit" form="onboard-employee-form" loaderColor="white" isLoading={formik.isSubmitting} disabled={formik.isSubmitting}>
            {formik.isSubmitting ? t('Onboarding...') : t('Onboard Employee')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Onboarding;