import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PiBriefcase } from 'react-icons/pi';
import { createTask } from '../../../store/actions/TasksActions';
import Input from '../../shared/TailwindComponents/Input';
import { useAppDispatch } from '../../../store';
import Button from '../../shared/TailwindComponents/Button';
import { useTranslation } from 'react-i18next';

interface AssignTaskFormProps {
  employeeEmail: string;
  onCancel: () => void;
  onAdd: () => void;
}

const AssignTaskForm: React.FC<AssignTaskFormProps> = ({ employeeEmail, onCancel, onAdd }) => {
  const dispatch = useAppDispatch();
  const {t}  = useTranslation()

  const formik = useFormik({
    initialValues: {
      TaskTitle: '',
      TaskDescription: '',
      TaskDeadline: '',
    },
    validationSchema: Yup.object().shape({
      TaskTitle: Yup.string().required('Title is required'),
      TaskDescription: Yup.string().required('Description is required'),
      TaskDeadline: Yup.date().required('Deadline is required'),
    }),
    onSubmit: async (values) => {
      try {
        const taskData = {
          TaskTitle: values.TaskTitle,
          TaskDescription: values.TaskDescription,
          TaskDeadline: values.TaskDeadline,
          AssigneeEmail: employeeEmail,
        };
        await dispatch(createTask(taskData));
        onAdd(); // Redirects back to previous component/tab
      } catch (error) {
        console.error('Error creating task', error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6">
      <Input
        label="Task Title"
        type="text"
        name="TaskTitle"
        value={formik.values.TaskTitle}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={t("Enter task title")}
        error={formik.touched.TaskTitle && formik.errors.TaskTitle ? t(formik.errors.TaskTitle) : undefined}
        required
        className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
        icon={<PiBriefcase className="text-gray-500" size={18} />}
      />
      <Input
        label="Task Description"
        type="text"
        name="TaskDescription"
        value={formik.values.TaskDescription}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={t("Enter task description")}
        error={formik.touched.TaskDescription && formik.errors.TaskDescription ? t(formik.errors.TaskDescription) : undefined}
        required
        className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
        icon={<PiBriefcase className="text-gray-500" size={18} />}
      />
      <Input
        label="Task Deadline"
        type="date"
        name="TaskDeadline"
        value={formik.values.TaskDeadline}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={t("Select deadline")}
        error={formik.touched.TaskDeadline && formik.errors.TaskDeadline ? t(formik.errors.TaskDeadline) : undefined}
        required
        className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
        icon={<PiBriefcase className="text-gray-500" size={18} />}
      />
      <div className="flex justify-end space-x-2">
        <Button variant="neutral" size="small" onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" size="small" type="submit">
          {t("Assign Task")}
        </Button>
      </div>
    </form>
  );
};

export default AssignTaskForm;