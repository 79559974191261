import React from 'react';

const SkeletonLoader: React.FC = () => {
  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark p-6 space-y-6 animate-fadePulse">
      {/* Header Skeleton */}
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className="h-6 w-6 bg-gray-300 dark:bg-gray-600 rounded"></div>
          <div className="h-8 w-1/3 bg-gray-300 dark:bg-gray-600 rounded"></div>
        </div>
        <div className="h-8 w-8 bg-gray-300 dark:bg-gray-600 rounded"></div>
      </div>

      {/* Badges Skeleton */}
      <div className="flex space-x-2">
        <div className="h-6 w-24 bg-gray-300 dark:bg-gray-600 rounded"></div>
        <div className="h-6 w-24 bg-gray-300 dark:bg-gray-600 rounded"></div>
      </div>

      {/* Client Information Skeleton */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="flex items-start space-x-3">
              <div className="h-5 w-5 bg-gray-300 dark:bg-gray-600 rounded"></div>
              <div className="w-full">
                <div className="h-4 w-1/2 bg-gray-300 dark:bg-gray-600 rounded mb-2"></div>
                <div className="h-4 w-full bg-gray-300 dark:bg-gray-600 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Tabs Skeleton */}
      <div className="border-b border-gray-200 dark:border-gray-700 mb-4">
        <nav className="flex space-x-8">
          {[...Array(2)].map((_, i) => (
            <div key={i} className="h-6 w-24 bg-gray-300 dark:bg-gray-600 rounded"></div>
          ))}
        </nav>
      </div>

      {/* Content Skeleton (Cases or Financial Data) */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <div className="h-8 w-1/3 bg-gray-300 dark:bg-gray-600 rounded mb-4"></div>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="flex justify-between items-center p-4 border rounded-lg dark:border-gray-700"
            >
              <div className="flex items-center gap-3">
                <div className="h-5 w-5 bg-gray-300 dark:bg-gray-600 rounded"></div>
                <div>
                  <div className="h-4 w-24 bg-gray-300 dark:bg-gray-600 rounded mb-2"></div>
                  <div className="h-3 w-16 bg-gray-300 dark:bg-gray-600 rounded"></div>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="h-6 w-16 bg-gray-300 dark:bg-gray-600 rounded"></div>
                <div className="h-4 w-12 bg-gray-300 dark:bg-gray-600 rounded"></div>
                <div className="h-8 w-24 bg-gray-300 dark:bg-gray-600 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;