
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import apis from '../../utils/apis'; 
import { RootState } from '..';      
import { showFeedbackModal } from './UserFeedbackActions';

export interface LinkPreviewResponse {
  url: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  error?: string;
}

export const fetchLinkPreview = (url: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {

      const response = await apis.get<LinkPreviewResponse>('/api/linkPreview', {
        params: { url },
      });

      if (!response || !response.data) {
        throw new Error('No preview data returned.');
      }
      if (response.data.error) {
        throw new Error(response.data.error);
      }

      const previewData = response.data; 

      return previewData;
    } catch (error: any) {
      // dispatch(
      //   showFeedbackModal({
      //     showModal: true,
      //     message: error.message || 'Failed to fetch link preview',
      //     modalType: 'error',
      //     duration: 3000,
      //   })
      // );
      throw error;
    }
  };
};
