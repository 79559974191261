// actions/caseAnalysisActions.ts

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..'; // Adjust the path as needed
import { getUserType } from '../../../utils/authUtils';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';
import { fetchCaseSummary } from './caseActions';
import { handleApiError } from '../utils/utils';
import { ProgressStep } from '../../reducer/marketplace2/caseAnalysisReducer';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { config } from '../../../utils/config';

// Action Types
export const RUN_ANALYSIS_REQUEST = 'RUN_ANALYSIS_REQUEST';
export const RUN_ANALYSIS_SUCCESS = 'RUN_ANALYSIS_SUCCESS';
export const RUN_ANALYSIS_FAILURE = 'RUN_ANALYSIS_FAILURE';
export const FETCH_ANALYSIS_SUCCESS = 'FETCH_ANALYSIS_SUCCESS';
export const FETCH_ANALYSIS_FAILURE = 'FETCH_ANALYSIS_FAILURE';
export const SET_ANALYSIS_PROGRESS = 'SET_ANALYSIS_PROGRESS';
export const FETCH_PROGRESS_REQUEST = 'FETCH_PROGRESS_REQUEST';
export const FETCH_PROGRESS_SUCCESS = 'FETCH_PROGRESS_SUCCESS';
export const FETCH_PROGRESS_FAILURE = 'FETCH_PROGRESS_FAILURE';
export const SHOW_ANALYSIS_DONE_BANNER = 'SHOW_ANALYSIS_DONE_BANNER';
export const SSE_DONE = 'SSE_DONE';

export const SET_ANALYSIS_FINALIZING = 'SET_ANALYSIS_FINALIZING';

export const setSseDone = (done: boolean) => ({
  type: SSE_DONE,
  payload: done,
});
// Action Creators
export const runAnalysisRequest = (): AnyAction => ({
  type: RUN_ANALYSIS_REQUEST,
});

export const runAnalysisSuccess = (analysisData: any): AnyAction => ({
  type: RUN_ANALYSIS_SUCCESS,
  payload: analysisData,
});

export const runAnalysisFailure = (error: string): AnyAction => ({
  type: RUN_ANALYSIS_FAILURE,
  payload: error,
});

export const fetchAnalysisSuccess = (analysisData: any): AnyAction => ({
  type: FETCH_ANALYSIS_SUCCESS,
  payload: analysisData,
});

export const fetchAnalysisFailure = (error: string): AnyAction => ({
  type: FETCH_ANALYSIS_FAILURE,
  payload: error,
});

export const setAnalysisProgress = (progress: any): AnyAction => ({
  type: SET_ANALYSIS_PROGRESS,
  payload: progress,
});

export const fetchProgressRequest = (): AnyAction => ({
  type: FETCH_PROGRESS_REQUEST,
});

export const fetchProgressSuccess = (data: any): AnyAction => ({
  type: FETCH_PROGRESS_SUCCESS,
  payload: data,
});

export const fetchProgressFailure = (error: string): AnyAction => ({
  type: FETCH_PROGRESS_FAILURE,
  payload: error,
});

export const setAnalysisFinalizing = (isFinalizing: boolean) => ({
  type: SET_ANALYSIS_FINALIZING,
  payload: isFinalizing,
});

// Thunk Action to Run Analysis
export const runCaseAnalysis = (caseId: string, userIndexes?: Record<string, string>) => {
  return (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(runAnalysisRequest());

    const userType = getUserType();
    let endpoint = determineEndpoint(userType);

    const data: any = { CaseID: caseId };
    if (userIndexes && Object.keys(userIndexes).length > 0) {
      data.UserIndexes = userIndexes;
    }

    return apis({
      method: 'POST',
      url: endpoint,
      data
    })
      .then(() => {
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: 'Analysis completed',
            duration: 3000,
          })
        );
        dispatch(fetchCaseSummary(caseId))
      })
      .catch((error: any) => {
        dispatch(runAnalysisFailure(error.toString()));
        handleApiError(error, dispatch);
      });
  };
};

export const runCaseAnalysisSSE = (caseId: string, userIndexes?: Record<string, string>) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(runAnalysisRequest());

    try {
      const baseUrl = `${config.baseUrl}/ai/run_full_case_analysis_lawyer_sse`;
      // const baseUrl = 'http://127.0.0.1:8000/ai/run_full_case_analysis_lawyer_sse';
      const params = new URLSearchParams({ CaseID: caseId });
      const sseUrl = `${baseUrl}?${params.toString()}`;

      let bearerToken = '';
      const rawToken = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (rawToken) {
        bearerToken = JSON.parse(rawToken).token;
      }

      const evtSource = new EventSourcePolyfill(sseUrl, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      evtSource.onmessage = async (event) => {
        if (!event.data) return;

        let parsed: any;
        try {
          parsed = JSON.parse(event.data);
        } catch (err) {
          console.error('Failed to parse SSE data:', event.data);
          return;
        }

        // If partial progress, store it
        if (parsed.progress) {
          dispatch(fetchProgressSuccess({
            progress: parsed.progress,
            analysis_results: null
          }));

          // Check if all "lawyer steps" are completed
          if (areAllLawyerStepsComplete(parsed.progress)) {
            finalizeAnalysisFlow(dispatch, caseId, evtSource);
          }
        }

        // If the server actually sends { final: true }
        if (parsed.final) {
          finalizeAnalysisFlow(dispatch, caseId, evtSource);
        }

        // If error
        if (parsed.error) {
          dispatch(runAnalysisFailure(parsed.error));
          evtSource.close();
        }
      };

      // If SSE sends event named "done" for some reason
      evtSource.addEventListener('done', () => {
        evtSource.close();
      });

      evtSource.onerror = (err) => {
        console.error('SSE onerror triggered:', err);
        dispatch(runAnalysisFailure('SSE error - check console.'));
        evtSource.close();
      };

    } catch (err: any) {
      console.error('[runCaseAnalysisSSE] error:', err);
      dispatch(runAnalysisFailure(err.toString()));
    }
  };
};


function areAllLawyerStepsComplete(progressArray: any[]): boolean {
  if (!Array.isArray(progressArray)) return false;

  // The 7 steps for lawyers
  const needed = [
    'Initial Overview',
    'applicable_laws',
    'relevant_cases',
    'adversarial_debate',
    'legal_analysis',
    'risk_assessment',
    'legal_strategy',
  ];

  // for each needed step => find in progress array => must be status=Completed
  for (const stepName of needed) {
    const found = progressArray.find((p) => p.step === stepName);
    if (!found || found.status !== 'Completed') {
      return false;  // not done
    }
  }
  return true; // all completed
}

async function finalizeAnalysisFlow(
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
  caseId: string,
  evtSource: EventSourcePolyfill
) {
  dispatch({ type: RUN_ANALYSIS_SUCCESS }); 

  dispatch(setAnalysisFinalizing(true));

  try {
    // await dispatch(fetchCaseSummary(caseId));
    await dispatch(fetchCaseAnalysisData(caseId));

    await new Promise((res) => setTimeout(res, 1000));

    dispatch(setAnalysisFinalizing(false));
  } catch (err: any) {
    console.error('Error finishing analysis:', err);
    dispatch(runAnalysisFailure(err.toString()));
  }

  // optionally show a “complete” banner
  dispatch({ type: SHOW_ANALYSIS_DONE_BANNER, payload: true });
  // close the SSE
  evtSource.close();
  dispatch(setSseDone(true));
}

const determineEndpoint = (userType: string) => {
  switch (userType) {
    case 'IndividualClient':
      return '/ai/run_full_case_analysis_client';
    case 'LawFirmAdmin':
    case 'LawFirmEmployee':
    case 'IndependentLawyer':
      return '/ai/run_full_case_analysis_lawyer';
    case 'BusinessAdmin':
    case 'BusinessEmployee':
      return '/ai/run_full_case_analysis_business';
    default:
      return '/ai/run_full_case_analysis_client';
  }
};

export const fetchAnalysisProgress = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchProgressRequest());
    try {
      const response = await apis({
        method: "POST",
        url: "/cases/get_progress",
        data: { CaseID: caseId },
      });
      // Extract the progress arrays.
      const clientProgress: ProgressStep[] = response.data.clientProgress || [];
      const lawyerProgress: ProgressStep[] = response.data.lawyerProgress || [];
      
      // Unify them.
      const unifiedProgress = unifyProgressSteps(clientProgress, lawyerProgress);
      const analysis_results = response.data; // additional analysis data if any
      
      // Dispatch the progress update immediately.
      dispatch(fetchProgressSuccess({ progress: unifiedProgress, analysis_results }));
      
      // Only mark as complete if there is at least one step and every step is "Completed"
      const isCompleted =
        unifiedProgress.length > 0 && unifiedProgress.every((step) => step.status === "Completed");
      
      if (isCompleted) {
        // Analysis is complete; dispatch success and polling should stop.
        dispatch(runAnalysisSuccess(analysis_results));
      }
    } catch (error: any) {
      dispatch(fetchProgressFailure(error.toString()));
      dispatch(runAnalysisFailure(error.toString()));
      handleApiError(error, dispatch);
    }
  };
};

export const CLEAR_ANALYSIS_DATA = 'CLEAR_ANALYSIS_DATA';

export const clearAnalysisData = (): AnyAction => ({
  type: CLEAR_ANALYSIS_DATA,
});

export const fetchCaseAnalysisData = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis.post('/cases/get_analysis', { CaseID: caseId });
      if (response && response.status === 200) {
        const analysis = response.data.analysis; 
        dispatch(runAnalysisSuccess(analysis));
      }else{
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
      dispatch(runAnalysisFailure(error.toString()));
    }
  };
};


/**
 * Unify the progress arrays from the client and lawyer.
 * For steps that exist on both sides, we mark overall status as:
 * - "Completed" if both are Completed
 * - "Pending" if one side is not complete (or if the lawyer’s side is missing when the case is marketplace)
 * - Otherwise, use the value from whichever exists.
 */
export function unifyProgressSteps(
  clientProgress: ProgressStep[] = [],
  lawyerProgress: ProgressStep[] = []
): ProgressStep[] {
  const progressMap: { [step: string]: { client?: ProgressStep; lawyer?: ProgressStep } } = {};

  // Build a map from the client progress.
  clientProgress.forEach((step) => {
    progressMap[step.step] = { client: step };
  });
  // Build (or merge) the lawyer progress.
  lawyerProgress.forEach((step) => {
    if (progressMap[step.step]) {
      progressMap[step.step].lawyer = step;
    } else {
      progressMap[step.step] = { lawyer: step };
    }
  });

  // Now produce a unified list.
  const unified: ProgressStep[] = [];
  Object.keys(progressMap).forEach((stepKey) => {
    const { client, lawyer } = progressMap[stepKey];
    let status = "Not Started";
    let messages: string[] = [];

    if (client && lawyer) {
      // If both exist, set Completed only if both are completed.
      if (client.status === "Completed" && lawyer.status === "Completed") {
        status = "Completed";
      } else {
        status = "Pending";
      }
      messages = [...(client.messages || []), ...(lawyer.messages || [])];
    } else if (client) {
      status = client.status;
      messages = client.messages || [];
    } else if (lawyer) {
      status = lawyer.status;
      messages = lawyer.messages || [];
    }

    unified.push({
      step: stepKey,
      status,
      messages,
    });
  });

  // Optionally, sort steps in a predefined order.
  const order = [
    "Initial Overview",
    "applicable_laws",
    "relevant_cases",
    "interpretation", // exists only for client analysis
    "legal_analysis",  // exists only for lawyer analysis
    "risk_assessment",
    "legal_strategy",  // lawyer
    "recommended_strategy", // client
  ];
  unified.sort((a, b) => {
    const idxA = order.indexOf(a.step);
    const idxB = order.indexOf(b.step);
    return idxA - idxB;
  });

  return unified;
}

export function mergeSteps(existing: ProgressStep[], incoming: ProgressStep[]): ProgressStep[] {
  // clone the array
  const updated = [...existing];

  for (const inc of incoming) {
    const idx = updated.findIndex(x => x.step === inc.step);
    if (idx === -1) {
      // new step => push
      updated.push(inc);
    } else {
      // merge
      updated[idx] = {
        ...updated[idx],
        status: inc.status,
        messages: mergeMessages(updated[idx].messages, inc.messages),
      };
    }
  }
  return updated;
}

function mergeMessages(oldMsgs: string[] = [], newMsgs: string[] = []): string[] {
  return [...oldMsgs, ...newMsgs];
}
