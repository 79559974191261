// src/components/settings/tabs/DeviceManagementTab.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PiDesktopLight, PiDeviceMobileLight, PiSignOutLight } from 'react-icons/pi';
import { RootState, useAppDispatch } from '../../../../store';
import { showModal } from '../../../../store/actions/modalActions';
import SocketServices from '../../../../utils/SocketServices';
import { getActiveSessions } from '../../../../store/actions/SecurityActions';
import Button from '../../../shared/TailwindComponents/Button';
import Badge from '../../../shared/TailwindComponents/Badge';

const DeviceManagementTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const devices = useSelector((state: RootState) => state.security.sessions) || [];
  const ownSessionId = sessionStorage.getItem('session_id');

  const handleSignOutConfirm = (sessionId: string) => {
    dispatch(
      showModal({
        type: 'alert',
        message: t('Are you sure you want to sign out from this device?'),
        onConfirm: () => {
          SocketServices.emit('logout_session', { session_id: sessionId });
          if (ownSessionId !== sessionId) {
            dispatch(getActiveSessions());
          }
        },
        inputData: sessionId,
        showModal: true,
      })
    );
  };

  const handleSignOutAllAccounts = () => {
    const userId = JSON.parse(localStorage.getItem('token') || sessionStorage.getItem('token') || '{}').userId;
    dispatch(
      showModal({
        type: 'alert',
        message: t('Are you sure you want to sign out from all devices?'),
        onConfirm: () => {
          SocketServices.emit('sign_out_all_accounts', { userId });
          dispatch(getActiveSessions());
        },
        showModal: true,
      })
    );
  };

  return (
    <div className="space-y-6 animate-fade-in-up">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
        {t('Device Management')}
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4">
        {devices.length > 0 ? (
          devices.map((device: any, index: number) => (
            <div
              key={index}
              className="flex justify-between items-center bg-white dark:bg-gray-800 p-4 rounded-lg shadow-light-md dark:shadow-dark-md transition-all duration-300 hover:shadow-light-lg hover:dark:shadow-dark-lg"
            >
              <div className="flex items-center space-x-4">
                {device.os?.includes('Windows') || device.os?.includes('Mac') ? (
                  <PiDesktopLight className="text-2xl text-primary-500" />
                ) : (
                  <PiDeviceMobileLight className="text-2xl text-primary-500" />
                )}
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">
                    {device.os || device.mobileModel || 'Unknown Device'}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">{device.browser || 'Unknown Browser'}</p>
                  <Badge color={device.status === 'active' ? 'green' : 'gray'}>
                    {device.status || 'Unknown'}
                  </Badge>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {t('Last active')}: {device.lastActive || 'N/A'}
                  </p>
                </div>
              </div>
              <Button
                variant="danger"
                size="small"
                onClick={() => handleSignOutConfirm(device.session_id)}
                disabled={ownSessionId === device.session_id}
                className="flex items-center"
              >
                <PiSignOutLight className="mr-2" />
                {t('Sign out')}
              </Button>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500 dark:text-gray-400 animate-fade-in">
            {t('No devices currently connected.')}
          </p>
        )}
      </div>
      <Button
        variant="danger"
        size="small"
        onClick={handleSignOutAllAccounts}
      >
        {t('Sign out of all devices')}
      </Button>
    </div>
  );
};

export default DeviceManagementTab;