import React from 'react';
import { useTranslation } from 'react-i18next';

interface ToggleOption {
  value: string;
  label: string;
  icon?: React.ReactNode;
}

interface ToggleProps {
  options: ToggleOption[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const ToggleWide: React.FC<ToggleProps> = ({ options, value, onChange, className }) => {
  const {t} = useTranslation()
  return (
    <div
      className={`
        flex w-full rounded-lg overflow-hidden p-1 gap-2 
        bg-gray-100 dark:bg-gray-800 shadow-sm 
        transition-colors duration-300
        ${className}
      `}
    >
      {options.map((option) => {
        const isActive = option.value === value;
        return (
          <button
            key={option.value}
            type="button"
            className={`
              w-1/2 py-2 text-center text-xs font-medium rounded-md 
              transition-all duration-300 hover:scale-101 flex items-center justify-center gap-2
              ${
                isActive
                  ? 'bg-primary-500 text-white shadow-md'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
              }
            `}
            onClick={() => onChange(option.value)}
          >
            {option.icon}
            {t(option.label)}
          </button>
        );
      })}
    </div>
  );
};

export default ToggleWide;