import React, { useState } from 'react';
import { useAppDispatch } from '../../store';
import {  fetchAllClients, onboardClient } from '../../store/actions/ClientAction';
import { ClientData } from '../../store/reducer/ClientReducer';
import { useTranslation } from 'react-i18next';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { Transition } from '@headlessui/react';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import ToggleWide from '../shared/TailwindComponents/ToggleWide';
import { PiIdentificationCardLight, PiPhoneLight, PiMapPinLight, PiBuildingsLight, PiUserLight, PiEnvelopeLight, PiUserPlusLight } from "react-icons/pi";

interface AddClientFormProps {
  onClose: () => void;
  onClientCreated?: (clientId: string) => void;
}

const AddClientForm: React.FC<AddClientFormProps> = ({ onClose, onClientCreated }) => {
  const [clientType, setClientType] = useState<'individual' | 'business'>('individual');
  const [formData, setFormData] = useState<Record<string, any>>({});
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let clientData: ClientData;

    if (clientType === 'individual') {
      clientData = {
        ClientID: '',
        UserTypeName: 'IndividualClient',
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        Email: formData.Email,
        PhoneNumber: formData.PhoneNumber,
        Address: formData.Address,
      };
    } else {
      clientData = {
        ClientID: '',
        UserTypeName: 'BusinessAdmin',
        BusinessName: formData.CompanyName,
        FirstName: formData.ContactFirstName,
        LastName: formData.ContactLastName,
        Email: formData.ContactEmail,
        PhoneNumber: formData.ContactPhoneNumber,
        Address: formData.Address,
        BusinessID: formData.OrganizationNumber || '',
      };
    }

    const newClientId = await dispatch(onboardClient(clientData));
    if (newClientId) {
      await dispatch(fetchAllClients());
      if (onClientCreated) onClientCreated(newClientId);
      onClose();
      dispatch(
        showFeedbackModal({
          modalType: 'success',
          message: t('Client onboarded'),
          duration: 3000,
          showModal: true,
        })
      );
    } else {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Could not add a new client. Please contact support.'),
          duration: 3000,
          showModal: true,
        })
      );
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-3xl w-full mx-auto mt-5">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white dark:bg-gray-900 pb-4 my-6 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center gap-2">
          <PiUserPlusLight size={28} className="text-primary-500" />
          {t('Add New Client')}
        </h2>
        <div className="flex gap-2">
          <Button variant="neutral" size="small" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" type="submit" form="add-case-form">
            {t('Create Case')}
          </Button>
        </div>
      </div>

      {/* Toggle */}
      <div className="mb-6">
        <ToggleWide
          options={[
            { value: 'individual', label: t('Individual'), icon: <PiUserLight size={16} /> },
            { value: 'business', label: t('Business'), icon: <PiBuildingsLight size={16} /> },
          ]}
          value={clientType}
          onChange={(val) => setClientType(val as 'individual' | 'business')}
        />
      </div>

      {/* Form with Transitions */}
      <form onSubmit={handleSubmit} className="space-y-6">
        <Transition
          show={clientType === 'individual'}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-2"
        >
          <div>
            {clientType === 'individual' && (
              <div className="space-y-4">
                <Input
                  type="text"
                  name="FirstName"
                  label={t('First Name')}
                  onChange={handleInputChange}
                  required
                  placeholder={t('First Name')}
                  icon={<PiUserLight size={20} className="text-gray-500" />}
                />
                <Input
                  type="text"
                  name="LastName"
                  label={t('Last Name')}
                  onChange={handleInputChange}
                  required
                  placeholder={t('Last Name')}
                  icon={<PiUserLight size={20} className="text-gray-500" />}
                />
                <Input
                  type="email"
                  name="Email"
                  label={t('Email')}
                  onChange={handleInputChange}
                  required
                  placeholder={t('Email')}
                  icon={<PiEnvelopeLight size={20} className="text-gray-500" />}
                />
                <Input
                  type="tel"
                  name="PhoneNumber"
                  label={t('Phone Number')}
                  onChange={handleInputChange}
                  placeholder={t('Phone Number')}
                  icon={<PiPhoneLight size={20} className="text-gray-500" />}
                />
                <Textarea
                  id="Address"
                  name="Address"
                  label={t('Address')}
                  onChange={handleInputChange}
                  placeholder={t('Address')}
                  icon={<PiMapPinLight size={20} className="text-gray-500" />}
                />
              </div>
            )}
          </div>
        </Transition>

        <Transition
          show={clientType === 'business'}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-2"
        >
          <div>
            {clientType === 'business' && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Left Column: Business Details */}
                <div className="space-y-4">
                  <Input
                    type="text"
                    name="CompanyName"
                    label={t('Business Name')}
                    onChange={handleInputChange}
                    required
                    placeholder={t('Company Name')}
                    icon={<PiBuildingsLight size={20} className="text-gray-500" />}
                  />
                  <Input
                    type="text"
                    name="OrganizationNumber"
                    label={t('Organization Number')}
                    onChange={handleInputChange}
                    placeholder={t('Organization Number')}
                    description={t('Your business registration number')}
                    icon={<PiIdentificationCardLight size={20} className="text-gray-500" />}
                  />
                  <Textarea
                    id="Address"
                    name="Address"
                    label={t('Business Address')}
                    onChange={handleInputChange}
                    placeholder={t('Address')}
                    icon={<PiMapPinLight size={20} className="text-gray-500" />}
                  />
                </div>
                {/* Right Column: Contact Details */}
                <div className="space-y-4">
                  <Input
                    type="text"
                    name="ContactFirstName"
                    label={t('Contact First Name')}
                    onChange={handleInputChange}
                    required
                    placeholder={t('First Name')}
                    icon={<PiUserLight size={20} className="text-gray-500" />}
                  />
                  <Input
                    type="text"
                    name="ContactLastName"
                    label={t('Contact Last Name')}
                    onChange={handleInputChange}
                    required
                    placeholder={t('Last Name')}
                    icon={<PiUserLight size={20} className="text-gray-500" />}
                  />
                  <Input
                    type="email"
                    name="ContactEmail"
                    label={t('Contact Email')}
                    onChange={handleInputChange}
                    required
                    placeholder={t('Email')}
                    icon={<PiEnvelopeLight size={20} className="text-gray-500" />}
                  />
                  <Input
                    type="tel"
                    name="ContactPhoneNumber"
                    label={t('Contact Phone Number')}
                    onChange={handleInputChange}
                    placeholder={t('Phone Number')}
                    icon={<PiPhoneLight size={20} className="text-gray-500" />}
                  />
                </div>
              </div>
            )}
          </div>
        </Transition>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-6">
          <Button type="submit" variant="primary" size="small">
            {t('Add Client')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddClientForm;