import { Dispatch } from "react"
import apis from "../../utils/apis"
import { AnyAction } from "redux"
import { setFontSize } from "./FontSizeAction"
import { setVoice } from "./SpeechAction"
import { AISettings } from "../reducer/DataReducer"
import { showFeedbackModal } from "./UserFeedbackActions"
import { getUserID } from "./ChatAction"
import { GET_AI_SETTINGS_SUCCESS, GET_PREFERENCES_SUCCESS, GET_USER_MANUAL_FAILURE, GET_USER_MANUAL_REQUEST, GET_USER_MANUAL_SUCCESS, SET_AI_SETTINGS, SET_AI_VOICES, SET_CHAT_LIST, SET_CITY, SET_COUNTRY, SET_CURRENCY, SET_MESSAGE_LIST, SET_PREFERENCES, SET_STATE, SET_USER_TYPES } from "../types"
import { HelpData } from "../../components/Help/types"
import { decryptMessage, getPrivateKey } from "../../components/MessageCenter/E2EEHelpers"
import SocketServices from "../../utils/SocketServices"
import { handleApiError } from "./utils/utils"



export interface UserInfo {
    City: string;
    Country: string;
    DateOfBirth: string;
    Email: string;
    Employer: string;
    FirstName: string;
    KYCStatus: string;
    LastName: string;
    LawfirmID?: string;
    Nationality: string;
    Occupation: string;
    PhoneNumber: string;
    ProfilePicture: File | string;
    State: string;
    StreetAddress: string;
    UserID: string;
    UserType: string;
    ZipCode: string;
}

interface GetUserManualRequestAction {
    type: typeof GET_USER_MANUAL_REQUEST;
}

interface GetUserManualSuccessAction {
    type: typeof GET_USER_MANUAL_SUCCESS;
    payload: HelpData;
}

interface GetUserManualFailureAction {
    type: typeof GET_USER_MANUAL_FAILURE;
    payload: string;
}

export type HelpActionTypes =
    | GetUserManualRequestAction
    | GetUserManualSuccessAction
    | GetUserManualFailureAction;

export const setCountry = (countries: string) => {
    return {
        type: SET_COUNTRY,
        countries
    }

}

export const setCurrency = (currencies: any) => {
    return {
        type: SET_CURRENCY,
        currencies
    };
};
export const setState = (states: string) => {
    return {
        type: SET_STATE,
        states
    }
}

export const setCity = (cities: string) => {
    return {
        type: SET_CITY,
        cities
    }
}

export const setPreferences = (preferences: any) => {
    return {
        type: SET_PREFERENCES,
        preferences,
    };
};

export const getPreferencesSuccess = (preferences: any) => {
    return {
        type: GET_PREFERENCES_SUCCESS,
        preferences,
    };
};

export const setAISettings = (aiSettings: any) => ({
    type: SET_AI_SETTINGS,
    aiSettings,
});

export const getAISettingsSuccess = (aiSettings: any) => ({
    type: GET_AI_SETTINGS_SUCCESS,
    aiSettings,
});


export const setChatList = (chat_list: any[]) => ({
    type: SET_CHAT_LIST,
    chat_list
})

export const setUserTypes = (userTypes: any[]) => ({
    type: SET_USER_TYPES,
    userTypes
})

export const setMessageList = (message_list: any[]) => ({
    type: SET_MESSAGE_LIST,
    message_list
})

interface UserState {
    userInfo: any;
    tutorialCompleted: boolean;
    // ...
}

const initialState: UserState = {
    userInfo: {},
    // ...
    tutorialCompleted: false, // default
    // ...
};

export const userReducer = (state = initialState, action: AnyAction): UserState => {
    switch (action.type) {
        case 'STORE_USER_INFO':
            return {
                ...state,
                userInfo: action.payload,
                // If the payload includes tutorialCompleted, set it
                tutorialCompleted: action.payload?.TutorialCompleted ?? state.tutorialCompleted
            };

        case 'SET_TUTORIAL_COMPLETED':
            return {
                ...state,
                tutorialCompleted: action.payload,
            };

        // ...other cases...
        default:
            return state;
    }
};


export const countryList = (name?: string | null) => {
    return async (dispatch: any) => {
        try {
            let response = await apis({
                method: "GET",
                url: "territory/getAllCountries",
                data: {
                    country_name: name ? name : "",
                }
            })
            if (response && response.data) {
                dispatch(setCountry(response.data))
            }
        } catch (err) {
            dispatch(showFeedbackModal({
                modalType: 'error',
                showModal: true,
                message: (`${err}`),
                duration: 3000
            }))
        }
    }
}

export const currencyList = () => {
    return async (dispatch: any) => {
        try {
            let response = await apis({
                method: "GET",
                url: "/getCurrencyList"
            });
            if (response && response.data) {
                dispatch(setCurrency(response.data));
            }
        } catch (err) {
            dispatch(showFeedbackModal({
                modalType: 'error',
                showModal: true,
                message: (`${err}`),
                duration: 3000
            }))
        }
    };
};


export const userInformation = () => {
    return async (dispatch: Dispatch<any>) => {
        try {
            let response = await apis({
                method: "GET",
                url: "user/userinfo",
            });
            if (response && response.status === 200) {
                let userData = response.data;
                sessionStorage.setItem("userInfo", JSON.stringify({ userId: userData.UserID, userType: userData.UserType }));
                SocketServices.emit("user_join", { 'username': userData.UserUID, 'caseid': "" });
                dispatch({ type: 'STORE_USER_INFO', payload: userData });
                return userData;
            } else {
                handleApiError(response, dispatch)
            }
        } catch (err) {
            handleApiError(err, dispatch)
        }
    }
};

export const EditUser = (formdata: FormData, onSuccess: () => void) => {

    return async (dispatch: Dispatch<any>) => {
        try {
            let response = await apis({
                method: 'POST',
                url: "account/update_details",
                data: formdata,
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (response && response.status === 200) {
                onSuccess()
                return response
            } else {
                handleApiError(response, dispatch)
            }
        } catch (err) {
            handleApiError(err, dispatch)
        }
    }
}


export const getPreferences = () => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await apis({
                method: 'GET',
                url: '/get_preferences',
            });
            if (response.status === 200) {
                dispatch(getPreferencesSuccess(response.data.PreferenceSettings));
            } else {
                handleApiError(response, dispatch)
            }
        } catch (error) {
            handleApiError(error, dispatch)
        }
    };
};

export const updatePreferences = (preferences: any) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await apis({
                method: 'POST',
                url: '/edit_preferences',
                data: preferences,
            });
            if (response.status === 200) {
                dispatch(setPreferences(response.data.PreferenceSettings));
                // dispatch(
                //     showFeedbackModal({
                //         modalType: 'success',
                //         showModal: true,
                //         message: 'Preferences updated successfully.',
                //         duration: 3000,
                //     })
                // );
            } else {
                handleApiError(response, dispatch)
            }
        } catch (error) {
            handleApiError(error, dispatch)
        }
    };
};

export const getAISettings = () => async (dispatch: Dispatch<any>) => {
    try {
        const response = await apis({
            method: 'GET',
            url: '/get_ai_settings',
        });
        if (response.status === 200) {
            const settings: AISettings = response.data;
            localStorage.setItem('aiSettings', JSON.stringify(settings));
            dispatch(setAISettings(settings));
            dispatch(setFontSize(settings.FontSize));
            dispatch(setVoice(settings.AIVoice));
        } else {
            handleApiError(response, dispatch)
        }
    } catch (error) {
        handleApiError(error, dispatch)
    }
};

export const getAIVoices = () => async (dispatch: Dispatch<any>) => {
    const userId = getUserID();
    try {
        const response = await apis({
            method: 'POST',
            url: 'settings/getAIVoices',
            data: { UserID: userId },
        });
        if (response.status === 200) {
            dispatch({
                type: SET_AI_VOICES,
                voices: response.data.voices,
            });

        } else {
            handleApiError(response, dispatch)
        }
    } catch (error) {
        handleApiError(error, dispatch)
    }
};


export const updateAISettings = (aiSettings: AISettings) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await apis({
                method: 'POST',
                url: '/edit_ai_settings',
                data: aiSettings,
            });
            if (response.status === 200) {
                localStorage.setItem('aiSettings', JSON.stringify(aiSettings));
                dispatch(setAISettings(aiSettings));
                dispatch(setFontSize(aiSettings.FontSize));
                dispatch(setVoice(aiSettings.AIVoice));
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: 'AI settings updated',
                    modalType: 'success',
                    duration: 3000
                }));
            } else {
                handleApiError(response, dispatch)
            }
        } catch (error) {
            handleApiError(error, dispatch)
        }
    };
};

export const fetchCHatList = () => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "GET",
                url: "chat/get_chat_list",
            })

            if (resp && resp.status === 200) {
                dispatch(setChatList(resp.data.chats_list))
            } else {
                handleApiError(resp, dispatch)
            }
        } catch (e) {
            handleApiError(e, dispatch)
        }
    }


}

export const fetchMessageList = (chat_id: string | number) => {
    return async (dispatch: any, getState: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "chat/get_message_list",
                data: { ChatID: chat_id }
            });

            if (resp && resp.status === 200) {
                const rawMessages = resp.data.message_list;

                // 1) Current user's private key from IndexedDB
                const { userInfo } = getState().user;
                const userUID = userInfo.UserUID;
                const myPrivateKey = await getPrivateKey(userUID);

                // 2) Decrypt each ephemeral message
                const decryptedMessages = [];
                for (const msg of rawMessages) {
                    let decryptedContent = msg.content;

                    try {
                        // If .content is ephemeral JSON => parse + decrypt
                        const parsed = JSON.parse(msg.content);
                        if (parsed.iv && parsed.ct && parsed.ephemeralPubKey) {
                            if (myPrivateKey) {
                                decryptedContent = await decryptMessage(
                                    parsed.ephemeralPubKey,
                                    myPrivateKey,
                                    parsed.iv,
                                    parsed.ct
                                );
                            } else {
                                decryptedContent = '🔒 (No private key available)';
                            }
                        }
                    } catch (err) {
                        // If parse fails => old plaintext => leave as is
                    }

                    decryptedMessages.push({
                        ...msg,
                        content: decryptedContent
                    });
                }

                // 3) Store plaintext messages in Redux
                dispatch(setMessageList(decryptedMessages));
                return decryptedMessages;
            } else {
                handleApiError(resp, dispatch)
            }
        } catch (err) {
            handleApiError(err, dispatch)
        }
    };
};


export const deleteMesageChat = (chat_id: string | number) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "chat/delete_chat",
                data: {
                    ChatID: chat_id
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: resp.data.message,
                    modalType: 'success',
                    duration: 3000,
                }))
                return resp.status
            } else {
                handleApiError(resp, dispatch)
            }
        } catch (e) {
            handleApiError(e, dispatch)
        }
    }

}

export const getUserManual = (userType: string, language: string) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_USER_MANUAL_REQUEST });
    try {
        const response = await apis({
            method: 'POST',
            url: '/help/get_user_manual',
            data: { UserType: userType, Language: language },
        });
        if (response.status === 200) {
            dispatch({
                type: GET_USER_MANUAL_SUCCESS,
                payload: response.data, // Ensure the API response matches HelpData
            });
        } else {
            handleApiError(response, dispatch)
        }
    } catch (error) {
        handleApiError(error, dispatch)
    }
};


export const fetchUserTypes = () => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "GET",
                url: "/getUserTypes",
            })

            if (resp && resp.status === 200) {
                dispatch(setUserTypes(resp.data))
            } else {
                handleApiError(resp, dispatch)
            }
        } catch (e) {
            handleApiError(e, dispatch)
        }
    }


}