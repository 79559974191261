import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { LawyerProfile } from '../shared/types';
import { StarIcon } from '@heroicons/react/24/solid';
import { useOptionalBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import {useAppDispatch } from '../../../store';
import { getLawyerProfile } from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';
import { Tab } from '@headlessui/react';
import { showFileReaderSidePanel, showProposeCaseSidePanel } from '../../../store/actions/sidePanelActions';
import Button from '../../shared/TailwindComponents/Button';
import { getUserType } from '../../../utils/authUtils';
import { useTranslation } from 'react-i18next';
import Badge from '../../shared/TailwindComponents/Badge';
import { showModal } from '../../../store/actions/modalActions';
import LawFirmProfileComponent from '../Lawyer/LawFirmProfileComponent';
import ApiLoader from '../../ApiLoader';

interface RouteParams {
  lawyerId: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface LawyerProfileComponentProps {
  lawyerProfile?: LawyerProfile; // Make it optional
  suppressBreadcrumbs?: boolean;
}

const LawyerProfileComponent: React.FC<LawyerProfileComponentProps> = ({ lawyerProfile: propLawyerProfile, suppressBreadcrumbs = false  }) => {
  const { lawyerId } = useParams<RouteParams>();
  const location = useLocation();
  const breadcrumbsContext = useOptionalBreadcrumbs();
  const setBreadcrumbs = breadcrumbsContext ? breadcrumbsContext.setBreadcrumbs : undefined;
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const [lawyerProfile, setLawyerProfile] = useState<LawyerProfile | null>(
    propLawyerProfile || (location.state as any)?.lawyer || null
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // const error = useSelector((state: RootState) => state.marketplaceReceiver.error);

  const fetchDetails = useCallback(async () => {
    if (!propLawyerProfile && lawyerId) {
      setLoading(true);
      const profile = await dispatch(getLawyerProfile(lawyerId));
      setLawyerProfile(profile);
      setLoading(false);
    }
  }, [dispatch, lawyerId, propLawyerProfile]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  useEffect(() => {
    if (!suppressBreadcrumbs && lawyerProfile && lawyerProfile.User && !userType.includes('Law') && setBreadcrumbs) {
      const fullName = `${lawyerProfile.User.FirstName} ${lawyerProfile.User.LastName}`;
      setBreadcrumbs([
        { name: 'Marketplace', href: '/marketplace', current: false },
        { name: fullName, href: `/marketplace/lawyer/${lawyerId}`, current: true },
      ]);
    }
    return () => {
      if (!suppressBreadcrumbs && setBreadcrumbs) {
        setBreadcrumbs([]);
      }
    };
  }, [lawyerProfile, lawyerId, setBreadcrumbs, userType, suppressBreadcrumbs]);

  const openProposeCase = () => {
    if (lawyerId) {
      dispatch(showProposeCaseSidePanel(lawyerId));
    }
  }

  const handlePreviewProfile = () => {
    // if (!lawFirmProfile) {
    //   return;
    // }

    dispatch(
      showModal({
        type: 'custom',
        message: '',
        showModal: true,
        content: <LawFirmProfileComponent lawFirmProfile={Organization} />,
        width: '80%',
      })
    );
  };

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  if (loading || !lawyerProfile || !lawyerProfile.User) {
    return (
    <ApiLoader />
    );
  }

  const {
    Bio,
    Specializations,
    HourlyRate,
    TermsOfServiceURL,
    CompanyRole,
    RatingsAverage,
    RatingsCount,
    ExperienceYears,
    Organization,
    User,
    LanguagesSpoken,
    Education,
    Awards,
    Certifications,
    Publications,
    ProfessionalMemberships,
    Portfolio,
    Ratings,
  } = lawyerProfile;

  const fullName = `${User.FirstName} ${User.LastName}`;

  const tabs = [
    { label: 'Feedback', value: 'Feedback' },
    { label: 'Education & Licenses', value: 'Education' },
    { label: 'Professional Memberships', value: 'Memberships' },
    { label: 'Portfolio', value: 'Portfolio' },
  ];

  return (
    <div className="bg-white dark:bg-gray-800 mx-4 rounded shadow-md dark:shadow-dark-md">
      <div className="mx-auto px-4 py-10 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
        {/* Profile */}
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 xl:gap-x-16">
          {/* Profile image */}
          <div className="lg:col-span-4 lg:row-end-1">
            {User.ProfilePicture ? (
              <img
                alt={fullName}
                src={User.ProfilePicture}
                className="aspect-[1] w-40 rounded-lg bg-primary-50 object-cover"
              />
            ) : (
              <div className="aspect-[1] w-40 rounded-lg bg-primary-50 flex items-center justify-center">
                <span className="text-4xl text-sky-300">
                  {User.FirstName[0]}
                  {User.LastName[0]}
                </span>
              </div>
            )}
          </div>

          {/* Profile details */}
          <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-3xl">
                  {fullName}
                </h1>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                  {ExperienceYears
                    ? `${ExperienceYears} ${t("years experience")}`
                    : t('Experience not specified')}
                </p>
              </div>

              <div>
                <h3 className="sr-only">{t('Reviews')}</h3>
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        RatingsAverage && RatingsAverage > rating
                          ? 'text-yellow-400'
                          : 'text-gray-300',
                        'h-5 w-5'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                  <p className="sr-only">{RatingsAverage} {t('out of 5 stars')}</p>
                  <span className="ml-3 text-sm font-medium text-primary-600 hover:text-primary-500">
                    {RatingsCount || 0} {t('reviews')}
                  </span>
                </div>
              </div>
            </div>

            <p className="mt-6 text-gray-500 dark:text-gray-300">{Bio || t('No bio available')}</p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
              {propLawyerProfile ? null : (
                <Button onClick={openProposeCase} variant="primary" size="medium">
                  {t('Propose a Case')}
                </Button>
              )}
              {TermsOfServiceURL && (
                <Button
                  onClick={() => dispatch(showFileReaderSidePanel(TermsOfServiceURL))}
                  variant="tertiary"
                  size="medium"
                >
                  {t('Terms of Service')}
                </Button>
              )}
            </div>

            {/* Specializations */}
            {Specializations && Specializations.length > 0 && (
              <div className="mt-10">
                <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">{t('Specializations')}</h3>
                <div className="mt-4">
                  <ul className="list-disc space-y-1 pl-5 text-sm text-gray-500 dark:text-gray-300">
                    {Specializations.map((spec) => (
                      <li key={spec}>{spec}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Languages Spoken */}
            {LanguagesSpoken && LanguagesSpoken.length > 0 && (
              <div className="mt-10">
                <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">{t('Languages Spoken')}</h3>
                <div className="mt-4">
                  <ul className="list-disc space-y-1 pl-5 text-sm text-gray-500 dark:text-gray-300">
                    {LanguagesSpoken.map((lang) => (
                      <li key={lang}>{lang}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {/* Organization */}
            {Organization && (
              <div className="mt-10">
                <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">{t('Organization')}</h3>
                <div 
                className="mt-4 flex items-center p-2 rounded bg-gray-50 dark:bg-gray-700 cursor-pointer transition shadow-md hover:bg-gray-100 dark:hover:bg-gray-600"
                onClick={handlePreviewProfile}
                >
                  {Organization.Logo ? (
                    <img
                      src={Organization.Logo}
                      alt={Organization.OrganizationName}
                      className="h-10 w-10 rounded-full mr-4"
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-gray-200 mr-4" />
                  )}
                  <div>
                    <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100">
                      {Organization.OrganizationName}
                    </h4>
                    <p className="text-sm text-gray-500 dark:text-gray-300">{Organization.Description}</p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Tabs Section */}
          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
            <Tab.Group>
              <div className="border-b border-gray-200">
                <Tab.List className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? 'border-primary-600 text-primary-600'
                            : 'border-transparent text-gray-700 dark:text-gray-200 hover:dark:text-gray-100 hover:text-gray-800 hover:border-gray-300 ',
                          'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                        )
                      }
                    >
                      {t(tab.label)}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                {/* Feedback Tab */}
                <Tab.Panel className="-mb-10">
                  <h3 className="sr-only">{t('Customer Reviews')}</h3>
                  {Ratings && Ratings.length > 0 ? (
                    Ratings.map((review: any, index: number) => (
                      <div key={index} className="flex space-x-4 text-sm text-gray-500">
                        <div className="flex-none py-10">
                          <img
                            alt=""
                            src={
                              review.UserProfilePicture ||
                              `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                review.UserFirstName + ' ' + review.UserLastName
                              )}&background=random`
                            }
                            className="h-10 w-10 rounded-full bg-gray-100"
                          />
                        </div>
                        <div
                          className={classNames(
                            index === 0 ? '' : 'border-t border-gray-200',
                            'py-10'
                          )}
                        >
                          <h3 className="font-medium text-gray-900">
                            {review.UserFirstName} {review.UserLastName}
                          </h3>
                          <p>
                            <time dateTime={review.CreatedAt}>
                              {new Date(review.CreatedAt).toLocaleDateString()}
                            </time>
                          </p>

                          <div className="mt-4 flex items-center">
                            {[0, 1, 2, 3, 4].map((rating) => (
                              <StarIcon
                                key={rating}
                                className={classNames(
                                  review.RatingValue > rating
                                    ? 'text-yellow-400'
                                    : 'text-gray-300',
                                  'h-5 w-5'
                                )}
                                aria-hidden="true"
                              />
                            ))}
                          </div>
                          <p className="sr-only">{review.RatingValue} {t('out of 5 stars')}</p>

                          <div className="mt-4 text-sm text-gray-500">
                            <p>{review.ReviewText}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center mt-5"><Badge color='gray'>{('No feedback available')}</Badge></div>
                  )}
                </Tab.Panel>
                {/* Education & Licenses Tab */}
                <Tab.Panel className="text-sm text-gray-500">
                  <h3 className="sr-only">{t('Education & Licenses')}</h3>
                  {/* Education */}
                  {Education && (
                    <div className="mt-10">
                      <h4 className="font-medium text-gray-900 dark:text-gray-100">{t('Education')}</h4>
                      <p className="mt-2 dark:text-gray-300">{Education}</p>
                    </div>
                  )}
                  {/* Certifications */}
                  {Certifications && Certifications.length > 0 && (
                    <div className="mt-10">
                      <h4 className="font-medium text-gray-900 dark:text-gray-100">{t('Certifications')}</h4>
                      <ul className="mt-2 list-disc space-y-1 pl-5">
                        {Certifications.map((cert) => (
                          <li key={cert}>{cert}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Tab.Panel>
                {/* Professional Memberships Tab */}
                <Tab.Panel className="text-sm text-gray-500">
                  <h3 className="sr-only">{t('Professional Memberships')}</h3>
                  {ProfessionalMemberships && ProfessionalMemberships.length > 0 ? (
                    <ul className="mt-4 list-disc space-y-1 pl-5">
                      {ProfessionalMemberships.map((membership) => (
                        <li className='dark:text-gray-300' key={membership}>{membership}</li>
                      ))}
                    </ul>
                  ) : (
                    <div className="flex justify-center mt-5"><Badge color='gray'>{('No memberships available')}</Badge></div>
                  )}
                </Tab.Panel>
                {/* Portfolio Tab */}
                <Tab.Panel className="text-sm text-gray-500">
                  <h3 className="sr-only">{t('Portfolio')}</h3>
                  {Portfolio && Portfolio.length > 0 ? (
                    <ul className="mt-4 list-disc space-y-1 pl-5">
                      {Portfolio.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <div className="flex justify-center mt-5"><Badge color='gray'>{('No portfolio items available')}</Badge></div>
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawyerProfileComponent;
