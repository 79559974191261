import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { RootState, useAppDispatch } from '../../store';
import { getUserType } from '../../store/actions/ChatAction';
import { assignTask, createTask, fetchAllTasks } from '../../store/actions/TasksActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import Input from '../shared/TailwindComponents/Input';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { Employee } from '../Management/Employees/types';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';
import { fetchProjects } from '../../store/actions/ProjectActions';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { fetchAllTeams } from '../../store/actions/organization/teamActions';
import { PiBriefcase, PiClock, PiEnvelopeSimple, PiGridFourLight, PiUser } from 'react-icons/pi';

interface AddTaskProps {
  onCancel?: () => void;
  onAdd?: () => void;
  selectedEmployeeEmail?: string;
}

const AddTask: React.FC<AddTaskProps> = ({ onCancel, onAdd }) => {
  const userType = getUserType();
  const dispatch = useAppDispatch();

  const [caseOptions, setCaseOptions] = useState<DropdownOption[]>([]);
  const [projectOptions, setProjectOptions] = useState<DropdownOption[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<DropdownOption[]>([]);
  const [teamOptions, setTeamOptions] = useState<DropdownOption[]>([]);

  const employees = useSelector((state: RootState) => state.employees.employees || []);
  const teams = useSelector((state: RootState) => state.teams.teams || []);
  const cases = useSelector((state: RootState) => state.casesM.cases || []);
  const projects = useSelector((state: RootState) => Object.values(state.projects.projects || {}));

  // Fetch data based on user type
  useEffect(() => {
    if (['LawFirmAdmin', 'LawFirmEmployee', 'IndependentLawyer'].includes(userType)) {
      dispatch(fetchCases());
    } else if (['BusinessAdmin', 'BusinessEmployee'].includes(userType)) {
      dispatch(fetchProjects());
    }
    if (['LawFirmAdmin', 'BusinessAdmin'].includes(userType)) {
      dispatch(fetchAllEmployees());
      dispatch(fetchAllTeams());
    }
  }, [userType, dispatch]);

  // Update options when data changes
  useEffect(() => {
    if (['LawFirmAdmin', 'LawFirmEmployee', 'IndependentLawyer'].includes(userType)) {
      const formattedCases = cases.map((caseItem: any) => ({
        value: caseItem.CaseID,
        label: `${caseItem.CaseName}`,
      }));
      setCaseOptions(formattedCases);
    } else if (['BusinessAdmin', 'BusinessEmployee'].includes(userType)) {
      const formattedProjects = projects.map((projectItem: any) => ({
        value: projectItem.ProjectID,
        label: `${projectItem.ProjectName}`,
      }));
      setProjectOptions(formattedProjects);
    }
  }, [cases, projects, userType]);

  useEffect(() => {
    if (['LawFirmAdmin', 'BusinessAdmin'].includes(userType)) {
      const formattedEmployees = employees.map((employee: Employee) => {
        const email = employee?.User?.Email || '';
        const firstName = employee?.User?.FirstName || '';
        const lastName = employee?.User?.LastName || '';
        return {
          value: email,
          label: `${firstName} ${lastName}`.trim() || 'No Name',
        };
      });
      setEmployeeOptions(formattedEmployees);

      const formattedTeams = teams.map((team: any) => ({
        value: team.TeamID,
        label: team.TeamName,
      }));
      setTeamOptions(formattedTeams);
    }
  }, [employees, teams, userType]);

  const validationSchema = Yup.object().shape({
    TaskTitle: Yup.string().required(t('Title is required')),
    TaskDescription: Yup.string().required(t('Description is required')),
    TaskDeadline: Yup.date().required(t('Deadline is required')),
    ...(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin'
      ? {
          AssigneeType: Yup.string(),
          AssigneeID: Yup.string().when('AssigneeType', {
            is: (AssigneeType: any) => AssigneeType === 'employee' || AssigneeType === 'team',
            then: (schema) => schema.required(t('Please select an assignee')),
          }),
        }
      : {}),
  });

  const formik = useFormik({
    initialValues: {
      TaskTitle: '',
      TaskDescription: '',
      TaskDeadline: '',
      CaseID: '',
      ProjectID: '',
      AssigneeType: '', // '', 'employee', 'team'
      AssigneeID: '', // UserID or TeamID
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const taskData: any = {
          TaskTitle: values.TaskTitle,
          TaskDescription: values.TaskDescription,
          TaskDeadline: values.TaskDeadline,
          ...(values.CaseID && { CaseID: values.CaseID }),
          ...(values.ProjectID && { ProjectID: values.ProjectID }),
          ...(values.AssigneeType === 'team' && { TeamID: values.AssigneeID }),
        };

        const createTaskResponse = await dispatch(createTask(taskData));
        const taskId = createTaskResponse?.TaskID;

        if (values.AssigneeType === 'employee' && taskId) {
          await dispatch(assignTask(taskId, [values.AssigneeID]));
        }

        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: t('Task created successfully'),
            showModal: true,
            duration: 3000,
          })
        );

        if (onAdd) onAdd();
        await dispatch(fetchAllTasks());
        resetForm();
        if (onCancel) onCancel();
      } catch (error) {
        dispatch(
          showFeedbackModal({
            modalType: 'error',
            duration: 3000,
            message: t('Could not add task. Please contact support.'),
            showModal: true,
          })
        );
      }
    },
  });

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <div className="px-6 pb-6 bg-white dark:bg-gray-900 rounded-xl shadow-lg max-w-3xl w-full mx-auto mt-5 h-[calc(100vh-125px)] flex flex-col overflow-y-auto">
      {/* Sticky Header */}
      <div className="sticky top-0 z-10 pt-6 bg-white dark:bg-gray-900 pb-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center gap-2">
          <PiBriefcase size={28} className="text-primary-500" />
          {t('Create New Task')}
        </h2>
        <div className="flex gap-2">
          <Button variant="neutral" size="small" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="small" type="submit" form="add-task-form">
            {t('Add Task')}
          </Button>
        </div>
      </div>

      {/* Form */}
      <form id="add-task-form" onSubmit={formik.handleSubmit} className="space-y-6 mt-4 flex-1">
        {/* Task Title */}
        <Input
          label={t('Task Title')}
          type="text"
          name="TaskTitle"
          value={formik.values.TaskTitle}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={t('Enter task title')}
          error={formik.touched.TaskTitle && formik.errors.TaskTitle ? formik.errors.TaskTitle : undefined}
          required
          className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
          description={t('Provide a clear and concise title for the task.')}
          icon={<PiBriefcase className="text-gray-500" size={18} />}
        />

        {/* Task Description */}
        <Textarea
          id="TaskDescription"
          name="TaskDescription"
          label={t('Task Description')}
          value={formik.values.TaskDescription}
          onChange={formik.handleChange}
          placeholder={t('Enter task description')}
          error={formik.touched.TaskDescription && formik.errors.TaskDescription ? formik.errors.TaskDescription : undefined}
          description={t('Add details or instructions for the task.')}
          icon={<PiEnvelopeSimple className="text-gray-500" size={18} />}
        />

        {/* Task Deadline */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
            <PiClock className="mr-2 text-gray-500" size={18} />
            {t('Deadline')} <span className="text-red-400 ml-1">*</span>
          </label>
          <p className="text-sm text-gray-500 dark:text-gray-400">{t('Set a due date and time for task completion.')}</p>
          <CustomDateTimePicker
            value={formik.values.TaskDeadline ? dayjs(formik.values.TaskDeadline) : null}
            onChange={(newValue: Dayjs | null) => {
              formik.setFieldValue('TaskDeadline', newValue ? newValue.toISOString() : '');
            }}
            minDateTime={dayjs()}
          />
          {formik.touched.TaskDeadline && formik.errors.TaskDeadline && (
            <span className="text-red-500 text-sm">{t(formik.errors.TaskDeadline)}</span>
          )}
        </div>

        {/* Case or Project Selection */}
        {['LawFirmAdmin', 'LawFirmEmployee', 'IndependentLawyer'].includes(userType) && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
              <PiGridFourLight className="mr-2 text-gray-500" size={18} />
              {t('Select Case')}
            </label>
            <p className="text-sm text-gray-500 dark:text-gray-400">{t('Link the task to a specific case for context.')}</p>
            <Dropdown
              options={caseOptions}
              onSelect={(option) => formik.setFieldValue('CaseID', option.value)}
              value={formik.values.CaseID}
              placeholder={t('Select a case')}
              className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
            />
          </div>
        )}
        {['BusinessAdmin', 'BusinessEmployee'].includes(userType) && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
              <PiGridFourLight className="mr-2 text-gray-500" size={18} />
              {t('Select Project')}
            </label>
            <p className="text-sm text-gray-500 dark:text-gray-400">{t('Link the task to a specific project for context.')}</p>
            <Dropdown
              options={projectOptions}
              onSelect={(option) => formik.setFieldValue('ProjectID', option.value)}
              value={formik.values.ProjectID}
              placeholder={t('Select a project')}
              className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
            />
          </div>
        )}

        {/* Assignment Options for Admins */}
        {['LawFirmAdmin', 'BusinessAdmin'].includes(userType) && (
          <>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
                <PiUser className="mr-2 text-gray-500" size={18} />
                {t('Assign To')}
              </label>
              <p className="text-sm text-gray-500 dark:text-gray-400">{t('Choose who will be responsible for this task.')}</p>
              <Dropdown
                options={[
                  { value: 'self', label: t('Assign to Self') },
                  { value: 'employee', label: t('Assign to Employee') },
                  { value: 'team', label: t('Assign to Team') },
                ]}
                onSelect={(option) => {
                  formik.setFieldValue('AssigneeType', option.value);
                  formik.setFieldValue('AssigneeID', '');
                }}
                value={formik.values.AssigneeType}
                placeholder={t('Select assignment type')}
                className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
              />
            </div>

            {formik.values.AssigneeType === 'employee' && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
                  <PiUser className="mr-2 text-gray-500" size={18} />
                  {t('Select Employee')} <span className="text-red-400 ml-1">*</span>
                </label>
                <Dropdown
                  options={employeeOptions}
                  onSelect={(option) => formik.setFieldValue('AssigneeID', option.value)}
                  value={formik.values.AssigneeID}
                  placeholder={t('Select an employee')}
                  className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
                />
                {formik.touched.AssigneeID && formik.errors.AssigneeID && (
                  <span className="text-red-500 text-sm">{t(formik.errors.AssigneeID)}</span>
                )}
              </div>
            )}

            {formik.values.AssigneeType === 'team' && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
                  <PiUser className="mr-2 text-gray-500" size={18} />
                  {t('Select Team')} <span className="text-red-400 ml-1">*</span>
                </label>
                <Dropdown
                  options={teamOptions}
                  onSelect={(option) => formik.setFieldValue('AssigneeID', option.value)}
                  value={formik.values.AssigneeID}
                  placeholder={t('Select a team')}
                  className="bg-white dark:bg-gray-800 rounded-lg border-gray-300 dark:border-gray-600"
                />
                {formik.touched.AssigneeID && formik.errors.AssigneeID && (
                  <span className="text-red-500 text-sm">{t(formik.errors.AssigneeID)}</span>
                )}
              </div>
            )}
          </>
        )}
        <div className='flex justify-end'>
        <Button variant="primary" size="small" type="submit" form="add-task-form">
            {t('Add Task')}
          </Button>
          </div>
      </form>
    </div>
  );
};

export default AddTask;