import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { getUserID } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  getOrganizationSettings,
  updateOrganizationSettings,
} from '../../store/actions/organization/CompanyAction';
import SingleFileInput from '../shared/TailwindComponents/SingleFileInput';
import { getWhiteLabelSettings, updateWhiteLabelSettings } from '../../store/actions/organization/WhiteLabelActions';
import ColorInput from '../shared/TailwindComponents/ColorInput';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { updatePreferences } from '../../store/actions/DataAction';
import Badge from '../shared/TailwindComponents/Badge'; // Assuming you have a Badge component

const CompanySettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const organizationSettings = useSelector((state: RootState) => state.company.settings);
  const whitelabelSettings = useSelector((state: RootState) => state.whiteLabel.settings);
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const [isBrandingEnabled, setIsBrandingEnabled] = useState<boolean>(preferences.WhiteLableEnabled);
  const [isWhitelabelRestricted] = useState<boolean>(true); // Restriction flag for whitelabel section

  useEffect(() => {
    setIsBrandingEnabled(preferences.WhiteLableEnabled);
  }, [preferences]);

  const userId = getUserID();

  useEffect(() => {
    dispatch(getOrganizationSettings());
    dispatch(getWhiteLabelSettings(userId));
  }, [dispatch, userId]);

  // Organization Information Formik
  const orgInfoFormik = useFormik({
    initialValues: {
      CompanyName: '',
      PhoneNumber: '',
      OfficeAddress: '',
      Website: '',
      ContactEmail: '',
      Description: '',
    },
    validationSchema: yup.object({
      CompanyName: yup.string().required(t('Company Name is required')),
      ContactEmail: yup.string().email(t('Invalid email')).required(t('Contact Email is required')),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('CompanyName', values.CompanyName);
      formData.append('PhoneNumber', values.PhoneNumber);
      formData.append('OfficeAddress', values.OfficeAddress);
      formData.append('Website', values.Website);
      formData.append('ContactEmail', values.ContactEmail);
      formData.append('Description', values.Description);
      await dispatch(updateOrganizationSettings(formData));
      dispatch(getOrganizationSettings());
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (organizationSettings) {
      orgInfoFormik.setValues({
        CompanyName: organizationSettings.OrganizationName || '',
        PhoneNumber: organizationSettings.PhoneNumber || '',
        OfficeAddress: organizationSettings.OfficeAddress || '',
        Website: organizationSettings.Website || '',
        ContactEmail: organizationSettings.ContactEmail || '',
        Description: organizationSettings.Description || '',
      });
    }
  }, [organizationSettings]);

  // Platform Whitelabel Settings Formik
  const platformWhitelabelFormik = useFormik({
    initialValues: {
      CustomColors: {
        primaryColor: '',
        secondaryColor: '',
        accentColor: '',
      },
      SidePanelLogoLight: null as File | null,
      SidePanelLogoDark: null as File | null,
    },
    onSubmit: async (values) => {
      if (isWhitelabelRestricted) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Whitelabel settings are coming soon.'),
            modalType: 'info',
            duration: 3000,
          })
        );
        return;
      }
      const formData = new FormData();
      if (values.CustomColors) {
        formData.append('CustomColors', JSON.stringify(values.CustomColors));
      }
      if (values.SidePanelLogoLight) {
        formData.append('SidePanelLogoLight', values.SidePanelLogoLight);
      }
      if (values.SidePanelLogoDark) {
        formData.append('SidePanelLogoDark', values.SidePanelLogoDark);
      }
      await dispatch(updateWhiteLabelSettings(formData));
      dispatch(getWhiteLabelSettings(userId));
      platformWhitelabelFormik.resetForm();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (whitelabelSettings) {
      platformWhitelabelFormik.setValues({
        CustomColors: whitelabelSettings.CustomColors || {
          primaryColor: '',
          secondaryColor: '',
          accentColor: '',
        },
        SidePanelLogoLight: null,
        SidePanelLogoDark: null,
      });
    }
  }, [whitelabelSettings]);

  const togglePreference = async () => {
    if (isWhitelabelRestricted) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Branding settings are coming soon.'),
          modalType: 'info',
          duration: 3000,
        })
      );
      return;
    }
    const hasNull = Object.values(whitelabelSettings).some((value) => value === null);
    if (hasNull) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Please update White Label settings first to enable branding....'),
          modalType: 'error',
          duration: 3000,
        })
      );
      return;
    }
    setIsBrandingEnabled(!isBrandingEnabled);
    const updatedPreferences = {
      WhiteLableEnabled: !isBrandingEnabled,
    };
    await dispatch(updatePreferences(updatedPreferences));
  };

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700 px-2 w-full">
      {/* Organization Information Section */}
      <div className="grid w-full min-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Organization Information')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t("Manage and update your organization's essential details and contact information.")}
          </p>
        </div>
        <div className="md:col-span-2">
          <form onSubmit={orgInfoFormik.handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
              <div className="sm:col-span-3">
                <Input
                  label={t('Company Name')}
                  italicTxt={t('Set the official name of your organization.')}
                  id="CompanyName"
                  name="CompanyName"
                  type="text"
                  value={orgInfoFormik.values.CompanyName}
                  onChange={orgInfoFormik.handleChange}
                  onBlur={orgInfoFormik.handleBlur}
                  error={
                    orgInfoFormik.touched.CompanyName && Boolean(orgInfoFormik.errors.CompanyName)
                  }
                  helperText={orgInfoFormik.touched.CompanyName && orgInfoFormik.errors.CompanyName}
                />
              </div>
              <div className="sm:col-span-3">
                <Input
                  label={t('Contact Email')}
                  italicTxt={t('Provide the primary email address for communication.')}
                  id="ContactEmail"
                  name="ContactEmail"
                  type="email"
                  value={orgInfoFormik.values.ContactEmail}
                  onChange={orgInfoFormik.handleChange}
                  onBlur={orgInfoFormik.handleBlur}
                  error={
                    orgInfoFormik.touched.ContactEmail && Boolean(orgInfoFormik.errors.ContactEmail)
                  }
                  helperText={
                    orgInfoFormik.touched.ContactEmail && orgInfoFormik.errors.ContactEmail
                  }
                />
              </div>
              <div className="sm:col-span-3">
                <Input
                  label={t('Phone Number')}
                  italicTxt={t("Enter your organization's contact phone number.")}
                  id="PhoneNumber"
                  name="PhoneNumber"
                  type="text"
                  value={orgInfoFormik.values.PhoneNumber}
                  onChange={orgInfoFormik.handleChange}
                />
              </div>
              <div className="sm:col-span-3">
                <Input
                  label={t('Office Address')}
                  italicTxt={t('Specify the physical address of your office.')}
                  id="OfficeAddress"
                  name="OfficeAddress"
                  type="text"
                  value={orgInfoFormik.values.OfficeAddress}
                  onChange={orgInfoFormik.handleChange}
                />
              </div>
              <div className="col-span-full">
                <Input
                  label={t('Website')}
                  italicTxt={t("Add your organization's website link.")}
                  id="Website"
                  name="Website"
                  type="text"
                  value={orgInfoFormik.values.Website}
                  onChange={orgInfoFormik.handleChange}
                />
              </div>
              <div className="col-span-full">
                <Textarea
                  label={t('Description')}
                  italicTxt={t('Write a brief overview of your organization and its services.')}
                  id="Description"
                  name="Description"
                  value={orgInfoFormik.values.Description}
                  onChange={orgInfoFormik.handleChange}
                />
              </div>
            </div>
            <div className="mt-8 flex gap-x-4 justify-end">
              <Button size="small" variant="primary" type="submit" disabled={!orgInfoFormik.dirty}>
                {t('Save')}
              </Button>
            </div>
          </form>
        </div>
      </div>

      {/* Platform Whitelabel Settings Section - Restricted */}
      {/* <div
        className={`grid w-full min-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3 ${
          isWhitelabelRestricted ? 'opacity-50 pointer-events-none' : ''
        }`}
      >
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Platform Whitelabel Settings')}
            {isWhitelabelRestricted && (
              <Badge color="gray" className="ml-2">
                {t('Coming Soon')}
              </Badge>
            )}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Customize the branding of your platform.')}
          </p>
        </div>
        <div className="md:col-span-2">
          <form onSubmit={platformWhitelabelFormik.handleSubmit} className="space-y-6">
            <section className="mb-8">
              <h3 className="text-xl font-semibold mb-2">{t('Platform Logos')}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                {t('Upload logos for the platform interface.')}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <SingleFileInput
                  label={t('Side Panel Logo (Light Mode)')}
                  accept="image/*"
                  file={platformWhitelabelFormik.values.SidePanelLogoLight}
                  setFile={(file) =>
                    platformWhitelabelFormik.setFieldValue('SidePanelLogoLight', file)
                  }
                  existingFileUrl={whitelabelSettings?.SidePanelLogoLight}
                  desc={t('Upload or change the logo for the light mode interface.')}
                  disabled={isWhitelabelRestricted}
                />
                <SingleFileInput
                  label={t('Side Panel Logo (Dark Mode)')}
                  accept="image/*"
                  file={platformWhitelabelFormik.values.SidePanelLogoDark}
                  setFile={(file) =>
                    platformWhitelabelFormik.setFieldValue('SidePanelLogoDark', file)
                  }
                  existingFileUrl={whitelabelSettings?.SidePanelLogoDark}
                  desc={t('Upload or change the logo for the dark mode interface.')}
                  disabled={isWhitelabelRestricted}
                />
              </div>
            </section>

            <section className="mb-8">
              <h3 className="text-xl font-semibold mb-2">{t('Custom Colors')}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
                {t('Set the color scheme for the platform.')}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <ColorInput
                  id="primaryColor"
                  label={t('Primary Color')}
                  name="primaryColor"
                  value={platformWhitelabelFormik.values.CustomColors.primaryColor || '#000000'}
                  onChange={(e) =>
                    platformWhitelabelFormik.setFieldValue('CustomColors.primaryColor', e.target.value)
                  }
                  disabled={isWhitelabelRestricted}
                />
                <ColorInput
                  id="secondaryColor"
                  label={t('Secondary Color')}
                  name="secondaryColor"
                  value={platformWhitelabelFormik.values.CustomColors.secondaryColor || '#000000'}
                  onChange={(e) =>
                    platformWhitelabelFormik.setFieldValue('CustomColors.secondaryColor', e.target.value)
                  }
                  disabled={isWhitelabelRestricted}
                />
                <ColorInput
                  id="accentColor"
                  label={t('Accent Color')}
                  name="accentColor"
                  value={platformWhitelabelFormik.values.CustomColors.accentColor || '#000000'}
                  onChange={(e) =>
                    platformWhitelabelFormik.setFieldValue('CustomColors.accentColor', e.target.value)
                  }
                  disabled={isWhitelabelRestricted}
                />
              </div>
            </section>

            <section className="mb-8 w-full grid grid-cols-1 md:grid-cols-2 items-center gap-10">
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('Enable Branding')}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-600 mb-4">
                  {t('If not enabled, default branding will be added to all documents.')}
                </p>
              </div>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={isBrandingEnabled}
                  onChange={togglePreference}
                  disabled={isWhitelabelRestricted}
                />
                <div className="relative">
                  <div
                    className={`w-12 h-6 bg-gray-300 rounded-full shadow-inner dark:bg-gray-600 ${
                      isBrandingEnabled ? 'bg-blue-800 dark:bg-blue-600' : ''
                    }`}
                  ></div>
                  <div
                    className={`dot absolute w-6 h-6 bg-white rounded-full shadow left-0 top-0 transition ${
                      isBrandingEnabled ? 'transform translate-x-full bg-blue-800' : ''
                    }`}
                  ></div>
                </div>
              </label>
            </section>

            <div className="mt-8 flex gap-x-4 justify-end">
              <Button
                size="small"
                variant="primary"
                type="submit"
                disabled={isWhitelabelRestricted || !platformWhitelabelFormik.dirty}
              >
                {t('Save Platform Settings')}
              </Button>
            </div>
          </form>
        </div>
      </div> */}
    </div>
  );
};

export default CompanySettings;