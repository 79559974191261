import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Lawyer } from '../shared/types';
import LawyerCard from '../../shared/TailwindComponents/LawyerCard';
import FilterSearchBar from '../../shared/TailwindComponents/FilterSearchBar';
import Pagination from '../../shared/TailwindComponents/Pagination';
import { RootState, useAppDispatch } from '../../../store';
import { fetchPracticeAreas } from '../../../store/actions/DropdownActions';
import { useSelector } from 'react-redux';
import {
  getAllLawyers,
  getRecommendedLawyers,
} from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';
import { fetchCases } from '../../../store/actions/marketplace2/caseActions';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';
import ApiLoader from '../../ApiLoader';
import { useTranslation } from 'react-i18next';

const LawyersList: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [sortedLawyers, setSortedLawyers] = useState<Lawyer[]>([]);
  const [sortValue, setSortValue] = useState<string>('RatingsAverage');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [selectedCaseId, setSelectedCaseId] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const practiceAreas = useSelector((state: RootState) => state.dropdown.practiceAreas || []);
  const lawyers = useSelector((state: RootState) => state.marketplaceReceiver.lawyers);
  const cases = useSelector((state: RootState) => state.casesM.cases || []);
  const error = useSelector((state: RootState) => state.marketplaceReceiver.error);
  const isLoading = useSelector((state: RootState) => state.marketplaceReceiver.isLoading);

  // Fetch practice areas and cases
  useEffect(() => {
    dispatch(fetchPracticeAreas());
    dispatch(fetchCases());
  }, [dispatch]);

  // Fetch lawyers based on selected case
  useEffect(() => {
    if (selectedCaseId) {
      dispatch(getRecommendedLawyers(selectedCaseId));
    } else {
      dispatch(getAllLawyers());
    }
  }, [dispatch, selectedCaseId]);

  // Filter and sort lawyers
  useEffect(() => {
    let filteredLawyers = [...lawyers];

    // Filter by search term
    if (searchTerm) {
      filteredLawyers = filteredLawyers.filter((lawyer) =>
        `${lawyer.FirstName} ${lawyer.LastName}`.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply selected filters
    if (Object.keys(selectedFilters).length > 0) {
      Object.entries(selectedFilters).forEach(([filterName, values]) => {
        if (filterName === 'Specialization') {
          filteredLawyers = filteredLawyers.filter((lawyer) =>
            lawyer.Specializations?.some((spec: any) => values.includes(spec))
          );
        }
        if (filterName === 'Affiliation') {
          filteredLawyers = filteredLawyers.filter((lawyer) => {
            const isAffiliated = lawyer.CompanyRole ? 'Affiliated' : 'Independent';
            return values.includes(isAffiliated);
          });
        }
      });
    }

    // Sort the lawyers
    filteredLawyers.sort((a: any, b: any) => {
      let aValue: any = a[sortValue] || 0;
      let bValue: any = b[sortValue] || 0;

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      // For RatingsAverage and ExperienceYears, sort in descending order
      if (sortValue === 'RatingsAverage' || sortValue === 'ExperienceYears') {
        return bValue - aValue;
      }

      // For other values, sort in ascending order
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });

    setSortedLawyers(filteredLawyers);
  }, [lawyers, searchTerm, sortValue, selectedFilters]);

  const handleFilterChange = (filterName: string, values: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: values,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
  };

  const handleSearchChange = (query: string) => {
    setSearchTerm(query);
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({});
    setSearchTerm('');
    setSortValue('RatingsAverage');
    setSelectedCaseId('');
  };

  const handleCaseChange = (caseId: string) => {
    setSelectedCaseId(caseId);
  };

  const caseOptions: DropdownOption[] = [
    { value: '', label: t('All Cases') },
    ...cases.map((caseItem) => ({
      value: caseItem.CaseID,
      label: caseItem.Title || caseItem.CaseName || `${t('Case')} ${caseItem.CaseID}`,
    })),
  ];

  const sortOptions = [
    { value: 'RatingsAverage', label: t('Ratings'), current: sortValue === 'RatingsAverage' },
    { value: 'ExperienceYears', label: t('Experience'), current: sortValue === 'ExperienceYears' },
    { value: 'HourlyRate', label: t('Hourly Rate'), current: sortValue === 'HourlyRate' },
  ];

  const filters = [
    {
      id: 'Specialization',
      name: t('Specialization'),
      options: practiceAreas,
    },
    {
      id: 'Affiliation',
      name: t('Affiliation'),
      options: [
        { value: 'Affiliated', label: t('Law Firm Affiliated') },
        { value: 'Independent', label: t('Independent Lawyer') },
      ],
    },
  ];

  return (
    <div className="px-4 pb-3">
      {/* Filter, Sort, and Search Bar */}
      <div className="mb-4">
        <FilterSearchBar
          filters={filters}
          sortOptions={sortOptions}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
          onSearchChange={handleSearchChange}
          sortValue={sortValue}
          onClearAllFilters={handleClearAllFilters}
          extraControls={
            <Dropdown
              options={caseOptions}
              onChange={(value) => handleCaseChange(value as string)}
              value={selectedCaseId}
            />
          }
        />
      </div>

      {/* Lawyers List */}
      {isLoading ? (
        <ApiLoader />
      ) : (
        <>
          {sortedLawyers.length === 0 ? (
            <div className="text-center text-gray-500">{t('No lawyers found.')}</div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
              {sortedLawyers.map((lawyer) => (
                <LawyerCard
                  key={lawyer.UserID}
                  lawyer={lawyer}
                  onClick={() => history.push(`/marketplace/lawyer/${lawyer.UserID}`, { lawyer })}
                />
              ))}
            </div>
          )}

          {/* Pagination Controls */}
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(newPage) => setPage(newPage)}
          />
        </>
      )}
    </div>
  );
};

export default LawyersList;
