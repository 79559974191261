import React, { useState, useEffect } from 'react';
import md5 from 'crypto-js/md5';

interface ProfileImageProps {
  profilePicture: string | null;
  email: string | null;
  initials: string;
}

const ProfileImage: React.FC<ProfileImageProps> = ({ profilePicture, email, initials }) => {
  const [useGravatar, setUseGravatar] = useState(false);
  const [gravatarLoaded, setGravatarLoaded] = useState(false);

  useEffect(() => {
    if (email && !profilePicture) {
      const gravatarUrl = `https://www.gravatar.com/avatar/${md5(email.toLowerCase().trim())}?s=40&d=404`;
      const img = new Image();
      img.onload = () => {
        // Image loaded successfully (status 200), so it’s a custom Gravatar
        setUseGravatar(true);
        setGravatarLoaded(true);
      };
      img.onerror = () => {
        // Image failed to load (status 404), so no custom image exists
        setUseGravatar(false);
        setGravatarLoaded(true);
      };
      img.src = gravatarUrl;
    } else {
      // No email or profilePicture exists, skip Gravatar check
      setGravatarLoaded(true);
    }
  }, [email, profilePicture]);

  // While checking, return nothing or a loading state
  if (!gravatarLoaded) {
    return null; // Optionally, replace with a loading spinner
  }

  if (profilePicture) {
    // Use the uploaded profile picture
    return (
      <img
        src={profilePicture}
        alt="Profile"
        className="w-10 h-10 rounded-full object-cover"
      />
    );
  } else if (useGravatar && email) {
    // Use the Gravatar if a custom image exists
    const gravatarUrl = `https://www.gravatar.com/avatar/${md5(email.toLowerCase().trim())}?s=40&d=404`;
    return (
      <img
        src={gravatarUrl}
        alt="Gravatar"
        className="w-10 h-10 rounded-full object-cover"
      />
    );
  } else {
    // Fall back to initials if no custom image or no email
    return (
      <span className="text-md font-medium text-primary-500 dark:text-primary-100">
        {initials}
      </span>
    );
  }
};

export default ProfileImage;