import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppDispatch } from './store';
import { RootState } from './store';
import { useSelector } from 'react-redux';
import { changeLanguage } from './i18n/i18n';
import { isTokenExpired } from './utils/config';
import { signOut } from './store/actions/SignInAction';
import { getUserType } from './store/actions/ChatAction';


interface PrivateRouteProps extends RouteProps {
  // children?: React.ReactNode;
  component?: React.ComponentType<any>;
  render?: () => React.ReactNode;
  subscriptionId: string;
  restricted: boolean;
  allowedUserTypes?: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  render: Render,
  subscriptionId,
  restricted,
  allowedUserTypes,
  ...rest
}) => {
  // Move hooks to the top
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const dispatch = useAppDispatch();
  const userType = getUserType();

  // Access token logic
  let userCred = localStorage.getItem('token') || sessionStorage.getItem('token');
  const accessToken = userCred ? JSON.parse(userCred).token : '';

  const isAuthenticated = accessToken && !isTokenExpired(accessToken);

  // useEffect hooks
  useEffect(() => {
    if (preferences && preferences.Language) {
      changeLanguage(preferences.Language);
    }
  }, [preferences]);

  useEffect(() => {
    const fetchData = () => {
      if (accessToken && isTokenExpired(accessToken)) {
        dispatch(signOut());
      }
    };
    const intervalId = setInterval(fetchData, 10 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [dispatch, accessToken]);

  if (allowedUserTypes && !allowedUserTypes.includes(userType)) {
    return <Redirect to="/not-found" />;
  }

  const renderComponentOrRedirect = (props: any) =>
    Component ? <Component {...props} /> : Render ? Render() : null;

  const checkRedirects = (props: any) => {
    const isResetPasswordRoute = props.match.path === '/reset-password/:token';

    if (isResetPasswordRoute || subscriptionId) {
      return renderComponentOrRedirect(props);
    }

    if (!isAuthenticated) {
      return <Redirect to="/" />;
    }

    if (!subscriptionId && restricted) {
      return <Redirect to="/plans" />;
    }

    
    
    return renderComponentOrRedirect(props);

  };


  return (
      <Route {...rest} render={checkRedirects} />
  );
}

export default PrivateRoute;
